import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

import * as Utility from '../../../scripts/utility';

const FileInput = ({ name, onChange, limit, children, ...props }) => {
	const [value, setValue] = useState(null);

	const onfileChange = ev => {
		if (!ev.currentTarget.files || !ev.currentTarget.files.length) {
			return;
		}

		const newFile = ev.currentTarget.files[0];

		if (!limit || newFile.size <= limit) {
			setValue(newFile);
			if (typeof onChange === 'function') onChange({ target: { name, value: newFile } });
		}
	};

	const onFileClear = ev => {
		setValue(null);
		if (typeof onChange === 'function') onChange({ target: { name, value: null } });
	};

	const formatFilesize = file => {
		const size = Utility.getFromObj(file, 'size') || 0;

		if (size > 1000000) return `${(file.size / 1000000).toFixed(2)}MB`;
		if (size > 1000) return `${(file.size / 1000).toFixed(2)}KB`;
		if (!size) return '';

		return `${file.size}B`;
	};

	return (
		<>
			<input type="file" id="fileUpload" name={name} onChange={onfileChange} {...props} />

			<Typography variant="body2">
				{Utility.getFromObj(value, 'name')}{' '}
				<small className="color-muted">{formatFilesize(value)}</small>
				{value ? (
					<IconButton onClick={onFileClear} style={{ marginLeft: '1rem', zIndex: 1000 }}>
						<Icons.Close />
					</IconButton>
				) : null}
				<Button
					variant="text"
					color="primary"
					startIcon={<Icons.Link />}
					onClick={() => document.getElementById('fileUpload').click()}
					style={{ marginLeft: 0 }} 
				>
					{children}
				</Button>
			</Typography>
		</>
	);
};

FileInput.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,
	limit: PropTypes.number,
	children: PropTypes.node,
	dropzone: PropTypes.bool,
};

FileInput.defaultProps = {
	name: '',
	onChange: () => {},
	limit: 99999999,
	children: null,
	dropzone: false
};

export default FileInput;

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const MatchFitRankingReasonsDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return <Dropdown multiple data={data.matchFitRankingReasons} {...props} />;
};

export default MatchFitRankingReasonsDropdown;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import GetTheJobSkillsForm from './GetTheJobSkillsForm';
import GetTheJobSkillsExperienceForm from './GetTheJobSkillsExperienceForm';
import EnjoySkillsForm from './EnjoySkillsForm';
import ImproveSkillsForm from './ImproveSkillsForm';
import LearnSkillsForm from './LearnSkillsForm';
import { ActionCreators as SiteActions } from '../../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../../scripts/candidates';

const SkillStoryForm = ({ setCandidateProfile, getCandidateResumes }) => {
	const pageNum = 4;
	const [isLoading, setIsLoading] = useState(false);
	const [storyStep, setStoryStep] = useState(1);
	const store = useStore();
	const { id: candidateID } = useParams();
	const { candidate } = useSelector(state => state.assessment, shallowEqual);

	const prevStep = () =>
		storyStep === 1 ? store.dispatch(AssessmentActions.setPage(pageNum - 0.5)) : setStoryStep(s => s - 1);

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setCandidate(response.data.data));
			setIsLoading(false);
		});
	}, []);

	if (isLoading || !candidate)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			{storyStep === 1 && <GetTheJobSkillsForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 2 && <GetTheJobSkillsExperienceForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 3 && <EnjoySkillsForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 4 && <ImproveSkillsForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 5 && (
				<LearnSkillsForm
					prevStep={prevStep}
					pageNum={pageNum}
					setCandidateProfile={setCandidateProfile}
					getCandidateResumes={getCandidateResumes}
				/>
			)}
		</>
	);
};

SkillStoryForm.propTypes = {
	setCandidateProfile: PropTypes.func,
	getCandidateResumes: PropTypes.func,
};

SkillStoryForm.defaultProps = {
	setCandidateProfile: () => null,
	getCandidateResumes: () => null,
};

export default SkillStoryForm;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 19, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 76 76"
			style={style}
			fill="none"
			className={className}
		>
			<g clipPath="url(#clip0_1363_14142)">
				<path
					d="M0 5.46193C0 2.4462 2.50671 -3.3975e-06 5.59906 -3.3975e-06H70.1682C73.2605 -3.3975e-06 75.7673 2.4462 75.7673 5.46193V70.7861C75.7673 73.8028 73.2605 76.248 70.1682 76.248H5.59906C2.50671 76.248 0 73.8028 0 70.7861V5.46193V5.46193Z"
					fill="#006699"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M22.9674 63.8275V29.3972H11.5234V63.8275H22.9674V63.8275ZM17.2454 24.6964C21.2361 24.6964 23.72 22.0525 23.72 18.7485C23.6456 15.37 21.2361 12.7995 17.3211 12.7995C13.4064 12.7995 10.8469 15.37 10.8469 18.7485C10.8469 22.0525 13.3303 24.6964 17.1708 24.6964H17.2451H17.2454Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M29.2997 63.8275H40.7436V44.6002C40.7436 43.5712 40.818 42.5432 41.1202 41.8076C41.9475 39.7516 43.8305 37.6222 46.9919 37.6222C51.1329 37.6222 52.7894 40.7796 52.7894 45.4081V63.8275H64.2324V44.0857C64.2324 33.5103 58.5865 28.5893 51.0573 28.5893C44.8836 28.5893 42.1733 32.0401 40.6675 34.3905H40.7439V29.3972H29.3001C29.4502 32.6279 29.3001 63.8275 29.3001 63.8275H29.2997Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1363_14142">
					<rect width="76" height="76" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

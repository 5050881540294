import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, NavLink } from 'react-router-dom';
import * as Icons from '@material-ui/icons';

const BackArrow = ({ to, onClick, ...props }) => {
	const history = useHistory();

	if (to) {
		return (
			<NavLink to={to} onClick={onClick}>
				<Icons.ArrowBackIos style={{ fontSize: 14, color: 'var(--primaryTextColor)', marginRight: '1.5rem' }} />
			</NavLink>
		);
	}
	return (
		<a href="#0" onClick={onClick || history.goBack}>
			<Icons.ArrowBackIos style={{ fontSize: 14, color: 'var(--primaryTextColor)', marginRight: '1.5rem' }} />
		</a>
	);
};

BackArrow.propTypes = {
	to: PropTypes.string,
};

BackArrow.defaultProps = {
	to: '',
};

export default BackArrow;

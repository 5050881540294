import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import PhoneInput from '../../../components/form/inputs/PhoneInput';
import LoadingButton from '../../../components/form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as ContactsAPI from '../../../scripts/contacts';
import * as UsersAPI from '../../../scripts/users';

const EditContactInfoModal = ({ listing, setListing }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const listingContact = listing?.contacts[0];
	const [contact, setContact] = useState({});

	const updateContact = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setContact({ ...contact, ...newValue });
	};

	const saveListing = () => {
		setIsSaving(true);
		const updatedUser = {
			...contact.user,
			first_name: contact.first_name,
			last_name: contact.last_name,
			phone: contact.phone,
		};
		UsersAPI.updateUser(contact.user.id, updatedUser, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const updatedListingContacts = listing.contacts.map((c, idx) => {
				return idx === 0 ? { ...contact } : c;
			});
			setListing({
				...listing,
				contacts: updatedListingContacts,
			});
			dispatch(SiteActions.hideModal());
		});
	};

	// Get page data on load
	useEffect(() => {
		if (listingContact.id) {
			setIsLoading(true);
			ContactsAPI.getContact(listingContact.id, (response, err) => {
				if (!response) {
					dispatch(SiteActions.showAlert('An error ocurred when loading the contact. Please try again.', 'error'));
					setIsLoading(false);
					return;
				}
				setContact(response.data.data);
				setIsLoading(false);
			});
		}
	}, [listingContact.id]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={5}>
					<LabeledField label="First Name" removeMargin>
						<TextField
							variant="outlined"
							name="first_name"
							value={contact.first_name || ''}
							onChange={updateContact}
							error={!contact.first_name}
							fullWidth
							placeholder="Enter first name"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={5}>
					<LabeledField label="Last Name" removeMargin>
						<TextField
							variant="outlined"
							name="last_name"
							value={contact?.last_name || ''}
							onChange={updateContact}
							error={!contact.last_name}
							fullWidth
							placeholder="Enter last name"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={5}>
					<LabeledField label="Primary E-mail" removeMargin>
						<TextField
							variant="outlined"
							name="email"
							value={contact.email || ''}
							onChange={updateContact}
							error={!contact.email}
							disabled
							fullWidth
							placeholder="Enter email address"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={5}>
					<LabeledField label="Primary Phone Number" removeMargin>
						<PhoneInput
							variant="outlined"
							name="phone"
							value={contact?.phone}
							onChange={updateContact}
							error={!contact.phone}
							fullWidth
							placeholder="Enter phone number"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={5}>
					<LabeledField label="Company">
						<TextField
							variant="outlined"
							name="company"
							value={contact.company || ''}
							onChange={contact}
							// error={!updatedListing.company}
							fullWidth
							placeholder="Enter company"
						/>
					</LabeledField>
				</Grid>
			</Grid>
			<div className="text-right">
				<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
					Cancel
				</Button>
				<LoadingButton
					loading={isSaving || isLoading}
					variant="contained"
					color="primary"
					value={2}
					onClick={saveListing}
				>
					Save
				</LoadingButton>
			</div>
		</>
	);
};

EditContactInfoModal.propTypes = {
	listing: PropTypes.shape({
		id: PropTypes.string,
		contacts: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	setListing: PropTypes.func,
};

EditContactInfoModal.defaultProps = {
	listing: { id: null },
	setListing: () => null,
};

export default EditContactInfoModal;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 18 19"
			style={style}
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.4697 5.96973L15.5304 7.03039L9.00006 13.5607L2.46973 7.03039L3.53039 5.96973L9.00006 11.4394L14.4697 5.96973Z"
				fill={color}
			/>
		</svg>
	);
}

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import ViewResumeWindow from './CandidateBlocks/ViewResumeWindow';

const ResumeWindow = () => {
	// takes in candidateId from URL to display resume in a separate window

	const params = useParams();
	const candidateId = params.id;

	const SPACER_WIDTH = 12;

	return (
		<div style={{ display: 'flex' }}>
			<div
				style={{
					width: `calc(33% - ${SPACER_WIDTH / 3}px)`,
				}}
			>
				<h3 style={{ height: 100 }}>GCP-hosted: direct iframe</h3>
				<object
					style={{ marginTop: 20 }}
					title="resume"
					height={820}
					width="100%"
					data="https://files.logoscapes.ai/test-uploads/resume.pdf"
					type="application/pdf"
				/>
				{/* <iframe
					style={{ marginTop: 20 }}
					title="resume"
					height={820}
					width="100%"
					src="https://files.logoscapes.ai/test-uploads/resume.pdf"
				/> */}
			</div>
			<div
				style={{
					width: `calc(33% - ${SPACER_WIDTH / 3}px)`,
				}}
			>
				<h3 style={{ height: 100 }}>Nauwork-hosted: Google/Microsoft Viewers</h3>
				<ViewResumeWindow candidateId={candidateId} />
			</div>
			<div style={{ width: SPACER_WIDTH }} />
			<div
				style={{
					width: `calc(33% - ${SPACER_WIDTH / 3}px)`,
				}}
			>
				<h3 style={{ height: 100 }}>GCS-hosted: Google/Microsoft Viewers</h3>
				<ViewResumeWindow
					overrideUrl="https://files.logoscapes.ai/test-uploads/resume.pdf"
					overrideFilename={'resume.pdf'}
				/>
			</div>
		</div>
	);
};

export default ResumeWindow;

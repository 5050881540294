import React, { useCallback, useState, useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import * as Icons from '@material-ui/icons';
import { Box } from '@material-ui/core';
import Heading from '@tiptap/extension-heading';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Bold from '@tiptap/extension-bold';
import Link from '@tiptap/extension-link';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';

const DynamicTextEditor = ({ content, onChange, fieldName }) => {
	const editor = useEditor({
		editable: true,
		extensions: [
			Heading.configure({ levels: [1, 2, 3] }),
			Document,
			Paragraph,
			Code,
			Text,
			Bold,
			Strike,
			Italic,
			ListItem,
			Underline,
			BulletList,
			OrderedList,
			Link.configure({
				openOnClick: false,
				autolink: true,
				defaultProtocol: 'https',
			}),
		],
		content,
	});

	const handleContentChange = useCallback(() => {
		if (editor) {
			const newContent = editor.getHTML();
			onChange({ target: { name: fieldName, value: newContent } });
		}
	}, [editor, onChange, fieldName]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (editor) {
			editor.on('update', handleContentChange);
			return () => {
				editor.off('update', handleContentChange);
			};
		}
	}, [editor, handleContentChange]);

	const setLink = useCallback(() => {
		const previousUrl = editor.getAttributes('link').href;
		const url = window.prompt('URL', previousUrl);

		if (url === null) {
			return;
		}

		if (url === '') {
			editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.unsetLink()
				.run();
			return;
		}

		editor
			.chain()
			.focus()
			.extendMarkRange('link')
			.setLink({ href: url })
			.run();
	}, [editor]);

	if (!editor) {
		return null;
	}

	function HoverButton({ children, style = {}, ...props }) {
		const [isHovered, setIsHovered] = useState(false);

		const combinedStyle = {
			background: isHovered ? 'rgb(189, 195, 199)' : 'transparent',
			color: '#212E33',
			border: 'none',
			cursor: 'pointer',
			borderRadius: '3px',
			marginLeft: '2px',
			marginRight: '2px',
			...style,
		};

		return (
			<button
				type="button"
				style={combinedStyle}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				{...props}
			>
				{children}
			</button>
		);
	}

	return (
		<>
			<div className="control-group">
				<div className="button-group">
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleHeading({ level: 1 })
								.run();
						}}
						className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
					>
						<Icons.Title fontSize="large" />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleHeading({ level: 2 })
								.run();
						}}
						className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
					>
						<Icons.Title fontSize="medium" />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleHeading({ level: 3 })
								.run();
						}}
						className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
					>
						<Icons.Title fontSize="small" />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleBulletList()
								.run();
						}}
						disabled={!editor.can().toggleBulletList()}
						className={editor.isActive('bulletList') ? 'is-active' : ''}
					>
						<Icons.FormatListBulleted />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleOrderedList()
								.run();
						}}
						disabled={!editor.can().toggleOrderedList()}
						className={editor.isActive('orderedList') ? 'is-active' : ''}
					>
						<Icons.FormatListNumbered />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleCode()
								.run();
						}}
						disabled={!editor.can().toggleCode()}
						className={editor.isActive('code') ? 'is-active' : ''}
					>
						<Icons.Code />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleBold()
								.run();
						}}
						disabled={!editor.can().toggleBold()}
						className={editor.isActive('bold') ? 'is-active' : ''}
					>
						<Icons.FormatBold />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleUnderline()
								.run();
						}}
						disabled={!editor.can().toggleUnderline()}
						className={editor.isActive('underline') ? 'is-active' : ''}
					>
						<Icons.FormatUnderlined />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleItalic()
								.run();
						}}
						disabled={!editor.can().toggleItalic()}
						className={editor.isActive('italic') ? 'is-active' : ''}
					>
						<Icons.FormatItalic />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.toggleStrike()
								.run();
						}}
						disabled={!editor.can().toggleStrike()}
						className={editor.isActive('strike') ? 'is-active' : ''}
					>
						<Icons.StrikethroughS />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							setLink();
						}}
						className={editor.isActive('link') ? 'is-active' : ''}
					>
						<Icons.Link />
					</HoverButton>
					<HoverButton
						onMouseDown={event => {
							event.preventDefault();
							editor
								.chain()
								.focus()
								.unsetLink()
								.run();
						}}
						disabled={!editor.isActive('link')}
					>
						<Icons.LinkOff />
					</HoverButton>
				</div>
			</div>
			<Box
				sx={{
					'& .ProseMirror': {
						minHeight: '200px',
						border: '1px, solid, black',
						marginTop: '5px',
						borderRadius: '3px',
						paddingLeft: '5px',
						paddingRight: '5px',
					},
				}}
			>
				<EditorContent editor={editor} />
			</Box>
		</>
	);
};

export default DynamicTextEditor;

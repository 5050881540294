/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function LinkedinCircleIcon({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			style={style}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g transform="translate(-255 -216)">
					<g transform="translate(255 216)">
						<circle cx="12" cy="12" r="12" fill="#52A2FF" />
						<g
							stroke="#FFF"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.25"
							transform="translate(6 6)"
						>
							<g>
								<path d="M3 0h6.003A2.997 2.997 0 0112 2.997v6.006A2.997 2.997 0 019.003 12H2.997A2.997 2.997 0 010 9.003V3a3 3 0 013-3z" />
								<path d="M3.412 5.4L3.412 9" />
								<path d="M5.812 9V6.9a1.5 1.5 0 011.5-1.5h0a1.5 1.5 0 011.5 1.5V9" />
								<path d="M3.412 3.225a.15.15 0 100 0" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import API from './api';

// API ENDPOINTS

/**
 * Gets a paginated list of sovren skills
 * @param {Object} parameters - Filters passed as query parameters
 * @param {*} callback - The function called once AJAX call is finished
 */
export const getSovrenSkills = (params, callback) => {
	API.get(`/sovren`, { params })
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Updates a sovren skill record
 * @param {Number} id - The ID of the sovren skill
 * @param {Object} parameters - The parameters for the sovren skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const updateSovrenSkill = (id, params, callback) => {
	const skill = params;

	API.patch(`/sovren/${id}`, skill)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Creates a new sovren skill record
 * @param {Object} parameters - The parameters for the sovren skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const addSovrenSkill = (params, callback) => {
	const skill = params;

	if (skill.label && !skill.name) skill.name = skill.label;
	if (skill.name && !skill.label) skill.label = skill.name;

	API.post(`/sovren`, skill)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Deletes a sovren skill record
 * @param {Number} id - The ID of the sovren skill
 * @param {*} callback - The function called once AJAX call is finished
 */
export const deleteSovrenSkills = (id, callback) => {
	API.delete(`/sovren/${id}`)
		.then(callback)
		.catch(err => callback(false));
};

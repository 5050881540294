/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function PlusIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.5" transform="translate(-491 -518)">
					<g transform="translate(492 519) rotate(45 9 9)">
						<path d="M9 18a9 9 0 01-9-9 9 9 0 019-9 9 9 0 019 9 9 9 0 01-9 9z" />
						<path d="M11.828 6.171L6.171 11.829"/>
						<path d="M11.829 11.828L6.171 6.171"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCreators as SiteActions } from '../../../store/Site';
import MenuLink from './MenuLink';
import DropdownMenu from './DropdownMenu';
import VerticalSpacer from '../../VerticalSpacer';

import AdminSVG from '../../../img/sidebar/AdminSVG';
import CandidatesSVG from '../../../img/sidebar/CandidatesSVG';
import DashboardSVG from '../../../img/sidebar/DashboardSVG';
import JobsSVG from '../../../img/sidebar/JobsSVG';

const Sidebar = props => {
	const dispatch = useDispatch();
	const sidebarWidth = useSelector(state => state.site.sidebarWidth);
	const [width, setWidth] = useState(sidebarWidth);
	const [prevWidth, setPrevWidth] = useState(sidebarWidth);
	const sidebarRef = useRef(null);

	const closedWidth = 80;
	const openedWidth = 180;
	let animationFrameId = null;

	const saveClosed = () => {
		setWidth(closedWidth);
		setPrevWidth(closedWidth);
		dispatch(SiteActions.setSidebarWidth(closedWidth));
	};

	const saveOpen = () => {
		setWidth(openedWidth);
		setPrevWidth(openedWidth);
		dispatch(SiteActions.setSidebarWidth(openedWidth));
	};

	const handleMouseDrag = e => {
		if (animationFrameId !== null) {
			window.cancelAnimationFrame(animationFrameId);
		}

		animationFrameId = window.requestAnimationFrame(() => {
			let newWidth = e.clientX - sidebarRef.current.getBoundingClientRect().left;
			if (newWidth < closedWidth) {
				newWidth = closedWidth;
			} else if (newWidth > openedWidth) {
				newWidth = openedWidth;
			}
			setWidth(newWidth);
		});
	};

	const handleMouseUp = () => {
		if (animationFrameId !== null) {
			window.cancelAnimationFrame(animationFrameId);
		}
		document.body.style.cursor = 'default';
		if (prevWidth === openedWidth) saveClosed();
		else if (prevWidth === closedWidth) saveOpen();
		window.removeEventListener('mousemove', handleMouseDrag);
		window.removeEventListener('mouseup', handleMouseUp);
	};

	const handleMouseDown = e => {
		e.preventDefault();
		document.body.style.cursor = prevWidth === closedWidth ? 'e-resize' : 'w-resize';
		window.addEventListener('mousemove', handleMouseDrag);
		window.addEventListener('mouseup', handleMouseUp);
	};

	return (
		<nav
			className="sidebar"
			style={{ minWidth: `${width}px`, width: `${width}px`, maxWidth: `${width}px` }}
			ref={sidebarRef}
		>
			<div
				className={`handle handle-${prevWidth === closedWidth ? 'open' : 'closed'}`}
				role="button"
				tabIndex={0}
				aria-label="Resize sidebar"
				onMouseDown={handleMouseDown}
			/>
			<MenuLink to="/" exact icon={<DashboardSVG />} width={width}>
				Dashboard
			</MenuLink>

			<MenuLink to="/candidates" icon={<CandidatesSVG />} width={width}>
				Candidates
			</MenuLink>

			<MenuLink to="/jobs" icon={<JobsSVG />} width={width}>
				Jobs
			</MenuLink>

			<VerticalSpacer height={2.5} />

			<DropdownMenu label="Admin" icon={<AdminSVG />} width={width} saveOpen={saveOpen}>
				<MenuLink to="/admin/users" width={width}>
					Users
				</MenuLink>
				<MenuLink to="/admin/manager" width={width}>
					Data Manager
				</MenuLink>
				<MenuLink to="/pdl" width={width}>
					Data Acquisition
				</MenuLink>
				{/* <MenuLink to="/bullhorn" width={width}>
					Bullhorn Sync
				</MenuLink> */}

				{process.env.NODE_ENV === 'development' ? (
					<>
						<MenuLink to="/styleguide" width={width}>
							Styleguide
						</MenuLink>
					</>
				) : null}
			</DropdownMenu>

			<VerticalSpacer height={2.5} />

			{/* <MenuLink to="/iogin" icon={<AdminSVG />} width={width}>
				Logout
			</MenuLink> */}
		</nav>
	);
};

export default Sidebar;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="24 / file / clipboard-text">
				<path
					id="icon"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.99728 1.33366C6.4988 0.908975 7.22609 0.666992 8 0.666992C8.77391 0.666992 9.5012 0.908975 10.0027 1.33366H10.6667C11.403 1.33366 12 1.93061 12 2.66699H12.6667C13.403 2.66699 14 3.26395 14 4.00033V14.0003C14 14.7367 13.403 15.3337 12.6667 15.3337H3.33333C2.59695 15.3337 2 14.7367 2 14.0003V4.00033C2 3.26395 2.59695 2.66699 3.33333 2.66699H4C4 1.93061 4.59695 1.33366 5.33333 1.33366H5.99728ZM10.6667 4.66699C11.1602 4.66699 11.5911 4.39886 11.8216 4.00033H12.6667V14.0003H3.33333V4.00033H4.17838C4.40892 4.39886 4.83981 4.66699 5.33333 4.66699H10.6667ZM5.33333 10.667V9.33366H9.33333V10.667H5.33333ZM5.33333 6.66699V8.00033H10.6667V6.66699H5.33333ZM6.57175 2.66699L6.7711 2.43724C6.99013 2.18482 7.45961 2.00033 8 2.00033C8.54039 2.00033 9.00987 2.18482 9.2289 2.43724L9.42825 2.66699H10.6667V3.33366H5.33333V2.66699H6.26756H6.57175Z"
					fill={color}
				/>
			</g>
		</svg>
	);
}

import React, { useState, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';

export default function SalaryFormat({ name, inputRef, onChange, ...other }) {
	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			thousandsGroupStyle="thousand"
			// isNumericString
		/>
	);
}

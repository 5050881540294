import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';

const WelcomeForm = () => {
	const pageNum = 0;
	const [isLoading, setIsLoading] = useState(false);
	const store = useStore();
	const { id: listingID } = useParams();
	const { listing } = useSelector(state => state.assessment, shallowEqual);

	const nextStep = () => {
		store.dispatch(AssessmentActions.addCompletedPage(pageNum));
		store.dispatch(AssessmentActions.setPage(pageNum + 1));
	};

	// Initialize the page data
	useEffect(() => {
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setListing(response.data.data));
			setIsLoading(false);
		});
	}, []);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	return (
		<Fence centered width="44" className="text-center">
			<VerticalSpacer height={3} />
			<Typography variant="h1" className="font-lato-bold" gutterBottom>
				Welcome{listing?.contacts?.length >= 1 ? ` ${listing.contacts[0].first_name}` : ''}!
			</Typography>
			<Typography variant="subtitle1" component="p" gutterBottom>
				Please complete the job profile for <strong>{listing?.position_title}</strong>. The detail you provide will help
				our team more accurately match candidates.
			</Typography>
			<VerticalSpacer height={3.2} />
			<Button variant="contained" color="primary" onClick={nextStep}>
				Let&rsquo;s Get Started
			</Button>
			<VerticalSpacer height={6} />
		</Fence>
	);
};

WelcomeForm.propTypes = {};

WelcomeForm.defaultProps = {};

export default WelcomeForm;

import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import WelcomeForm from './WelcomeForm';
import JobDescriptionForm from './JobDescriptionForm';
import SkillsForm from './SkillsForm';
import SkillRankingsForm from './SkillRankingsForm';
import SkillStoryForm from './SkillStoryForm';
import VerticalSpacer from '../../../VerticalSpacer';

const JobAssessmentModal = ({ setJobProfile, getJobDescriptions }) => {
	const { progress } = useSelector(state => state.assessment, shallowEqual);
	const getStepIcon = state => {
		const cn = `step-icon ${state.active ? 'step-icon-active' : ''} ${state.completed ? 'step-icon-completed' : ''}`;
		return <span className={cn} />;
	};
	return (
		<div className="assessment">
			<Stepper activeStep={progress.active - 1} alternativeLabel>
				<Step>
					<StepLabel StepIconComponent={getStepIcon}>Job Description</StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={getStepIcon}>Job Skills</StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={getStepIcon}>Job Skill Rankings</StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={getStepIcon}>Skill Story</StepLabel>
				</Step>
			</Stepper>
			<VerticalSpacer height={3} />
			{progress.active === 0 && <WelcomeForm />}
			{progress.active === 1 && <JobDescriptionForm />}
			{progress.active === 2 && <SkillsForm />}
			{progress.active === 3 && <SkillRankingsForm />}
			{progress.active === 4 && (
				<SkillStoryForm setJobProfile={setJobProfile} getJobDescriptions={getJobDescriptions} />
			)}
		</div>
	);
};

JobAssessmentModal.propTypes = {
	setJobProfile: PropTypes.func,
	getJobDescriptions: PropTypes.func,
};

JobAssessmentModal.defaultProps = {
	setJobProfile: () => null,
	getJobDescriptions: () => null,
};

export default JobAssessmentModal;

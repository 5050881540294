import React from 'react';
import { Grid } from '@material-ui/core';
import LabeledField from '../LabeledField';
import * as Utility from '../../../scripts/utility';
import WorkLocationDropdown from './WorkLocationDropdown';
import TimezonesDropdown from './TimezonesDropdown';

const LocationTimezonesDropdown = ({ onChange, model, isErrorEnabled }) => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<div
					style={{
						borderRadius: 8,
						backgroundColor: 'transparent',
					}}
				>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<LabeledField label="Work Location Preferences" labelStyle={{ marginTop: 1 }}>
								<WorkLocationDropdown
									variant="outlined"
									name="work_location_preferences"
									value={(Utility.getFromObj(model, 'work_location_preferences') || []).map(p => (p.id ? p.id : p))}
									onChange={onChange}
									error={isErrorEnabled ? !Utility.getFromObj(model, 'work_location_preferences').length : null}
									placeholder={model?.work_location_preferences?.length > 0 ? '' : 'Select all that apply'}
									fullWidth
									multiple
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField
								label="US Timezone (remote only)"
								info="Please select all of the time zones you can work as a remote employee."
								required={
									(model.work_location_preferences || []).length > 0 &&
									(model.work_location_preferences || []).map(p => (p.id ? p.id : p)).includes(1)
								}
							>
								<TimezonesDropdown
									variant="outlined"
									name="timezones"
									value={
										!(model.work_location_preferences || []).map(p => (p.id ? p.id : p)).includes(1)
											? null
											: (Utility.getFromObj(model, 'timezones') || []).length === 6
											? [0]
											: (Utility.getFromObj(model, 'timezones') || []).map(tz => (tz.id ? tz.id : tz))
									}
									onChange={onChange}
									error={
										isErrorEnabled
											? !(
													(model.work_location_preferences || []).length === 0 ||
													!(model.work_location_preferences || []).map(p => (p.id ? p.id : p)).includes(1) ||
													(model.timezones || []).length > 0
											  )
											: null
									}
									disabled={
										(model.work_location_preferences || []).length === 0 ||
										!(model.work_location_preferences || []).map(p => (p.id ? p.id : p)).includes(1)
									}
									placeholder={
										Utility.getFromObj(model, 'timezones').length > 0 &&
										(model.work_location_preferences || []).map(p => (p.id ? p.id : p)).includes(1)
											? ''
											: 'Select all that apply'
									}
									fullWidth
									multiple
								/>
							</LabeledField>
						</Grid>
					</Grid>
				</div>
			</Grid>
		</Grid>
	);
};

export default LocationTimezonesDropdown;

import React, { useState } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, TextField, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import BackArrow from '../../../components/page/BackArrow';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CategoriesAPI from '../../../scripts/categories';
import * as SiteAPI from '../../../scripts/site';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const CategoryAdd = () => {
	useDocumentTitle('Data Manager - Categories');
	const store = useStore();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [category, setCategory] = useState({});
	const { alert } = useSelector(state => state.site, shallowEqual);

	const updateCategory = ev => {
		setCategory({ ...category, [ev.target.name]: ev.target.value });
	};

	const onCategoryAdd = (response, id) => {
		setIsLoading(false);

		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred creating the category. Please try again.', 'error'));
			return;
		}

		SiteAPI.updateCategories(store);
		history.push(`/admin/categories`);
	};

	const addCategory = ev => {
		setIsLoading(true);
		CategoriesAPI.addCategory(category, onCategoryAdd);
	};

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to="/admin/categories" />
					<Typography variant="h1">Add New Category</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<LoadingButton loading={isLoading} width={10} variant="contained" color="primary" onClick={addCategory}>
						Save Category
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Grid container spacing={8}>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom />
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<LabeledField label="Category Name">
								<TextField variant="outlined" name="label" value={category.label} onChange={updateCategory} fullWidth />
							</LabeledField>

							<LabeledField label="Bullhorn ID">
								<TextField
									variant="outlined"
									name="vendor_category_id"
									value={category.vendor_category_id}
									onChange={updateCategory}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6} />
					</Grid>
				</Grid>
			</Grid>
		</Page>
	);
};

export default CategoryAdd;

import React, { useState } from 'react';
import { shallowEqual, useSelector, useStore } from 'react-redux';
import { Button, Grid, Switch, TextField, Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import { Panel, PanelContent, PanelHeader } from '../../components/Panel';
import Section from '../../components/Section';
import LabeledField from '../../components/form/LabeledField';
import Dropdown from '../../components/form/dropdowns/Dropdown';
import LoadingButton from '../../components/form/LoadingButton';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';

const BullhornTest = () => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const [data, setData] = useState({
		id: '',
		dryRun: true,
		endpoint: 'candidates',
		direction: 'from',
		response: '',
		loading: false,
	});

	const endpoints = {
		candidates: {
			from: CandidatesAPI.syncFromBullhorn,
			to: CandidatesAPI.syncToBullhorn,
		},
		listings: {
			from: ListingsAPI.syncFromBullhorn,
			to: ListingsAPI.syncToBullhorn,
		},
	};

	const onResponse = (response, err) => {
		setData({ ...data, loading: false });

		if (!response) {
			store.dispatch(SiteActions.showAlert(`${err.response.status}: ${err.response.statusText}`, 'error'));

			setData({
				...data,
				response: JSON.stringify(err.response.data, null, 4),
			});

			return;
		}

		store.dispatch(SiteActions.showAlert(`${response.status}: ${response.statusText}`, 'success'));

		setData({
			...data,
			response: JSON.stringify(response.data, null, 4),
		});
	};

	const sendRequest = () => {
		setData({ ...data, loading: true });
		endpoints[data.endpoint][data.direction](data.id, { dry_run: data.dryRun }, onResponse);
	};

	const resetData = () => {
		store.dispatch(SiteActions.hideAlert());
		setData({
			...data,
			id: '',
			dryRun: true,
			endpoint: 'candidates',
			direction: 'from',
			response: '',
		});
	};

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Bullhorn Test</Typography>
				</PageHeader.Left>
				<PageHeader.Right>
					<Button variant="outlined" color="primary" onClick={resetData}>
						Reset
					</Button>
					<LoadingButton loading={data.loading} variant="contained" color="primary" width={7} onClick={sendRequest}>
						Run Test
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			<Section>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3} lg={2}>
						<LabeledField label="Endpoint">
							<Dropdown
								variant="outlined"
								data={[
									{ id: 'candidates', label: 'Candidates' },
									{ id: 'listings', label: 'Listings' },
								]}
								name="endpoint"
								value={data.endpoint}
								onChange={ev => setData({ ...data, endpoint: ev.target.value })}
								fullWidth
							/>
						</LabeledField>
					</Grid>
					<Grid item xs={12} md={3} lg={2}>
						<LabeledField label="Direction">
							<Dropdown
								variant="outlined"
								data={[
									{ id: 'from', label: 'From' },
									{ id: 'to', label: 'To' },
								]}
								name="direction"
								value={data.direction}
								onChange={ev => setData({ ...data, direction: ev.target.value })}
								fullWidth
							/>
						</LabeledField>
					</Grid>
					<Grid item xs={6} md={3} lg={2}>
						<LabeledField label="Entity ID">
							<TextField
								type="number"
								variant="outlined"
								value={data.id}
								onChange={ev => setData({ ...data, id: ev.target.value })}
								fullWidth
							/>
						</LabeledField>
					</Grid>
					<Grid item xs={6} md={3} lg={2}>
						<LabeledField label="Dry Run">
							<Switch
								checked={data.dryRun}
								onChange={ev => setData({ ...data, dryRun: ev.target.checked })}
								name="dryRun"
							/>
						</LabeledField>
					</Grid>
				</Grid>
			</Section>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Section>
				<Panel>
					<PanelHeader>
						<Typography variant="body2" component="h2" className="text-bold">
							<Icons.BugReport className="color-muted" /> Response
						</Typography>
					</PanelHeader>
					<PanelContent>
						<pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{data.response}</pre>
					</PanelContent>
				</Panel>
			</Section>
		</Page>
	);
};

export default BullhornTest;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_325_11179)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.33341 15V4.16667H13.3334V15C13.3334 15.2922 13.3835 15.5727 13.4757 15.8333H4.16675C3.70651 15.8333 3.33341 15.4602 3.33341 15ZM15.8334 17.5H4.16675C2.78604 17.5 1.66675 16.3807 1.66675 15V2.5H13.3334H14.1667H15.0001L15.0001 6.66667H18.3334V7.5V8.33333V15C18.3334 16.3807 17.2141 17.5 15.8334 17.5ZM15.0001 8.33333H16.6667V15C16.6667 15.4602 16.2937 15.8333 15.8334 15.8333C15.3732 15.8333 15.0001 15.4602 15.0001 15L15.0001 8.33333ZM8.33341 5.83333V9.16667H5.00008V5.83333H8.33341ZM11.6667 8.33333V6.66667H9.16675V8.33333H11.6667ZM11.6667 10V11.6667H5.00008V10H11.6667ZM11.6667 14.1667V12.5H5.00008V14.1667H11.6667Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_325_11179">
					<rect width="17.9231" height="18.64" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

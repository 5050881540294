/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Reaction({ style, color = '#D2D7DE', size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 40 40"
			style={style}
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 38.3337C9.87474 38.3337 1.66663 30.1255 1.66663 20.0003C1.66663 9.8751 9.87474 1.66699 20 1.66699C30.1252 1.66699 38.3333 9.8751 38.3333 20.0003C38.3333 30.1255 30.1252 38.3337 20 38.3337ZM20 35.0003C28.2842 35.0003 35 28.2846 35 20.0003C35 11.7161 28.2842 5.00033 20 5.00033C11.7157 5.00033 4.99996 11.7161 4.99996 20.0003C4.99996 28.2846 11.7157 35.0003 20 35.0003ZM20 27.1634C22.1991 27.1634 24.2155 28.234 25.4577 30.0003L28.1842 28.0827C26.3242 25.4381 23.2959 23.83 20 23.83C16.704 23.83 13.6757 25.4381 11.8157 28.0827L14.5422 30.0003C15.7845 28.234 17.8008 27.1634 20 27.1634ZM23.3333 20.0003V16.667H26.6666V20.0003H23.3333ZM13.3333 16.667V20.0003H16.6666V16.667H13.3333Z"
				fill={color}
			/>
		</svg>
	);
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Typography, Switch, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

import LoadingButton from '../form/LoadingButton';
import SharedCandidateModal from './SharedCandidateModal';
import SharedJobModal from './SharedJobModal';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import LabeledField from '../form/LabeledField';

function SharePermissionsModal({ job, candidate, onSave, isSharable }) {
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.site);
	const model = job || candidate;

	const [isLoading, setLoading] = useState(false);
	const [isChecked, setIsChecked] = useState(isSharable);
	const [hasChanged, setHasChanged] = useState(false);

	useEffect(() => {
		setIsChecked(isSharable);
		setHasChanged(false);
	}, [isSharable]);

	useEffect(() => {
		setHasChanged(isChecked !== isSharable);
	}, [isChecked, isSharable]);

	const approvedUser = model?.sharable_allowed_by_user || user;
	const approvedDate = model?.sharable_allowed_at ? moment(model?.sharable_allowed_at) : moment();

	const savePermissions = async () => {
		let response;

		if (job) {
			if (isChecked) {
				response = await ListingsAPI.allowShare({
					listingId: job.id,
				});
			} else {
				response = await ListingsAPI.denyShare({
					listingId: job.id,
				});
			}
		} else if (candidate) {
			if (isChecked) {
				response = await CandidatesAPI.allowShare({
					candidateId: candidate.id,
				});
			} else {
				response = await CandidatesAPI.denyShare({
					candidateId: candidate.id,
				});
			}
		}
		if (response) {
			await onSave?.();
		}
	};

	return (
		<div style={{ textAlign: 'center' }}>
			<Typography variant="h2" style={{ marginBottom: 5 }}>
				Share Settings
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 15 }}>
				Use the toggle to change the share state of this record.
			</Typography>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<LabeledField
					label={isChecked ? 'This record may be shared' : 'This record is not shareable'}
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'flex-end',
						justifyContent: 'flex-end',
					}}
				>
					<Switch
						checked={isChecked}
						disabled={isLoading}
						color="primary"
						onChange={e => setIsChecked(e.target.checked)}
						name="dryRun"
					/>
				</LabeledField>

				<div style={{ paddingTop: 30 }}>
					<Button
						variant="outlined"
						color="primary"
						size="small"
						startIcon={<Icons.Visibility style={{ width: 20 }} />}
						onClick={() => {
							const ModalComponent = candidate ? SharedCandidateModal : SharedJobModal;
							const props = candidate
								? { candidateId: candidate?.id, overrideCandidate: candidate }
								: { jobId: job?.id, overrideListing: job };

							dispatch(
								SiteActions.showModal(<ModalComponent {...props} refetchMatches={() => {}} hideRating adminView />, {
									className: 'modal-medium match-share-modal',
								}),
							);
						}}
					>
						Preview
					</Button>
				</div>
			</div>

			<Typography
				variant="caption"
				style={{
					fontStyle: 'italic',
					width: '100%',
					display: 'block',
					textAlign: 'left',
					visibility: isChecked ? 'visible' : 'hidden',
				}}
			>
				{isChecked &&
					`Approved by ${approvedUser?.first_name} ${approvedUser?.last_name} on ${approvedDate.format(
						'MMMM Do YYYY',
					)}`}
			</Typography>

			<div style={{ height: 25 }} />
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 20 }}>
				<LoadingButton
					disabled={isLoading}
					variant="outlined"
					color="primary"
					onClick={() => dispatch(SiteActions.hideModal())}
					style={{ flex: 1, margin: 0 }}
				>
					Cancel
				</LoadingButton>
				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					disabled={isLoading || !hasChanged}
					onClick={async () => {
						setLoading(true);
						try {
							await savePermissions();
							dispatch(SiteActions.hideModal());
						} catch (e) {
							console.error(e);
						} finally {
							setLoading(false);
						}
						setHasChanged(false);
					}}
					style={{ flex: 1, margin: 0 }}
				>
					Save
				</LoadingButton>
			</div>
		</div>
	);
}

export default SharePermissionsModal;

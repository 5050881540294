import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LoadingButton from '../form/LoadingButton';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';

const CandidateDeleteModal = ({ candidate, onDelete, ...props }) => {
	const store = useStore();
	const [isLoading, setLoading] = useState(false);

	const onCandidateDelete = (response, err) => {
		setLoading(false);
		store.dispatch(SiteActions.hideModal());

		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred when deleting the candidate. Please try again.', 'error'));
			return;
		}

		if (typeof onDelete === 'function') onDelete();
	};

	const deleteCandidate = ev => {
		setLoading(true);
		CandidatesAPI.deleteCandidate(candidate.id, onCandidateDelete);
	};

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Delete Record?
			</Typography>
			<Icons.Delete
				color="secondary"
				style={{
					color: 'var(--secondaryColor)',
					background: 'var(--secondaryLightColor)',
					padding: 15,
					borderRadius: 100,
					fontSize: 80,
					marginBottom: '2.5rem',
				}}
			/>
			<Typography variant="body2" style={{ marginBottom: 32 }}>
				Please confirm that you want to permanently delete the candidate record
				<br />
				<strong>{`${candidate.first_name} ${candidate.last_name}`}</strong>
			</Typography>
			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="secondary"
				onClick={deleteCandidate}
				style={{ minWidth: 185 }}
			>
				Delete Candidate
			</LoadingButton>
		</div>
	);
};

CandidateDeleteModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	candidate: PropTypes.object.isRequired,
	onDelete: PropTypes.func,
};

CandidateDeleteModal.defaultProps = {
	onDelete: () => {},
};

export default CandidateDeleteModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LabeledField from '../../../components/form/LabeledField';
import EditJobAndCompanyBlockModal from './EditJobAndCompanyBlockModal';
import MoreDetailsIcon from '../../../components/icons/SectionIcons/MoreDetailsIcon';
import DynamicTextEditor from '../../../components/TextEditor';
import AddAnotherButton from '../../../components/form/AddAnotherButton';
import VerticalSpacer from '../../../components/VerticalSpacer';

const EditModal = ({ job, setJob, company, setCompany }) => {
	return (
		<EditJobAndCompanyBlockModal
			job={job}
			setJob={setJob}
			company={company}
			setCompany={setCompany}
			title="Recruiter Resources"
			icon={MoreDetailsIcon}
			renderContents={({ updateJob, updatedJob, updateCompany, updatedCompany }) => {
				const initialCompany = JSON.parse(JSON.stringify(updatedCompany));
				const [localLinks, setLocalLinks] = useState(
					initialCompany?.client_content_links?.length > 0
						? initialCompany?.client_content_links
						: [{ name: '', url: '' }],
				);

				const handleLinkChange = (ev, index, field) => {
					const newLinks = [...localLinks];
					newLinks[index][field] = ev.target.value;
					setLocalLinks(newLinks);
					updateCompany({
						target: {
							name: 'client_content_links',
							value: newLinks,
						},
					});
				};

				const addEmptyLink = () => {
					const newLinks = [...localLinks, { name: '', url: '' }];
					setLocalLinks(newLinks);
					updateCompany({
						target: {
							name: 'client_content_links',
							value: newLinks,
						},
					});
				};

				const removeLink = index => {
					let newLinks = localLinks.filter((link, i) => i !== index);

					// If no links are left, reset to one empty pair
					if (newLinks.length === 0) {
						newLinks = [{ name: '', url: '' }];
					}

					setLocalLinks(newLinks);
					updateCompany({
						target: {
							name: 'client_content_links',
							value: newLinks,
						},
					});
				};

				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
									Job Highlights
								</Typography>
								<VerticalSpacer height={1} />
								<DynamicTextEditor
									fieldName="job_highlights"
									content={updatedJob.job_highlights || ''}
									onChange={updateJob}
								/>
								<VerticalSpacer height={1} />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
									Job Notes
								</Typography>
								<VerticalSpacer height={1} />
								<DynamicTextEditor fieldName="job_notes" content={updatedJob.job_notes || ''} onChange={updateJob} />
								<VerticalSpacer height={1} />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
									Sourcing Guide
								</Typography>
								<VerticalSpacer height={1} />
								<DynamicTextEditor
									content={updatedJob.sourcing_guidance || ''}
									onChange={updateJob}
									fieldName={'sourcing_guidance'}
								/>
								<VerticalSpacer height={1} />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
									Client Content Links
								</Typography>
								<VerticalSpacer height={1} />
								{localLinks.map((link, index) => (
									<div
										key={index}
										style={{ width: '100%', marginBottom: '10px', display: 'flex', alignItems: 'center' }}
									>
										<div style={{ flex: 1 }}>
											<LabeledField label="Link Name">
												<TextField
													variant="outlined"
													name={`client_content_links_${index}`}
													value={link.name}
													onChange={ev => handleLinkChange(ev, index, 'name')}
													error={!link.name}
													fullWidth
													placeholder="Enter link name"
													style={{ marginBottom: '-10px' }}
												/>
											</LabeledField>
											<LabeledField label="Link URL">
												<TextField
													variant="outlined"
													name={`client_content_links_url_${index}`}
													value={link.url}
													onChange={ev => handleLinkChange(ev, index, 'url')}
													error={!link.url}
													fullWidth
													placeholder="Enter link URL"
												/>
											</LabeledField>
										</div>
										<div style={{ width: 40, paddingLeft: 10, marginBottom: 7 }}>
											<Button
												variant="text"
												color="#000"
												size="large"
												style={{
													margin: 0,
													padding: 4,
													minWidth: 0,
													borderRadius: 4,
													fontWeight: 400,
												}}
												onClick={() => removeLink(index)}
											>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<Icons.Remove color="gray" size={20} />
												</div>
											</Button>
										</div>
									</div>
								))}
								<AddAnotherButton onClick={addEmptyLink} />
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
	company: PropTypes.shape({ id: PropTypes.string }),
	setCompany: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
	company: {},
	setCompany: () => null,
};

export default EditModal;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import EditCandidateBlockModal from './EditCandidateBlockModal';
import PreferencesIcon from '../../../components/icons/PreferencesIcon';
import EmploymentPreferencesDropdown from '../../../components/form/dropdowns/EmploymentPreferencesDropdown';
import SalaryDropdown from '../../../components/form/dropdowns/SalaryDropdown';
import ValuePropositionsDropdown from '../../../components/form/dropdowns/ValuePropositionsDropdown';
import RoleLevelPreferencesDropdown from '../../../components/form/dropdowns/RoleLevelPreferencersDropdown';
import * as Utility from '../../../scripts/utility';
import SpecialtyCategoryDropdown from '../../../components/form/dropdowns/SpecialtyCategorySearchDropdown';
import IndustryPreferenceDropdown from '../../../components/form/dropdowns/IndustryPreferenceDropdown';
import { BusinessSizePreferencesDropdown } from '../../../components/form/dropdowns/BusinessSizeDropdown';

const EditPreferencesModal = ({ candidate, setCandidate }) => {
	const isParentChildSalaryMatch = useMemo(() => {
		if (!candidate?.salary_expectation_type?.id || !candidate?.child_salary_expectation_type?.id) {
			return false;
		}
		return candidate.salary_expectation_type.id === candidate.child_salary_expectation_type.id;
	}, [candidate.salary_expectation_type, candidate.child_salary_expectation_type]);
	return (
		<EditCandidateBlockModal
			candidate={candidate}
			setCandidate={setCandidate}
			title="Preferences"
			icon={PreferencesIcon}
			renderContents={({ updateCandidate, updatedCandidate }) => {
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<LabeledField label="Reason You're Seeking a New Role">
									<TextField
										placeholder="Briefly summarize what you are looking for in your next role"
										variant="outlined"
										name="reason_seeking_new_role"
										value={updatedCandidate.reason_seeking_new_role || ''}
										onChange={updateCandidate}
										fullWidth
										multiline
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Desired Role" required>
									<TextField
										variant="outlined"
										name="desired_position"
										value={updatedCandidate.desired_position}
										onChange={updateCandidate}
										error={!updatedCandidate.desired_position}
										fullWidth
										placeholder="Enter Position Title (e.g. Data Engineer)"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Role Levels" required info="What level of roles would you like to see? (max 2)">
									<RoleLevelPreferencesDropdown
										variant="outlined"
										name="role_level_preferences"
										value={updatedCandidate?.role_level_preferences?.map(item => (item.id ? item.id : item))}
										// TODO: This is not saving correctly
										onChange={updateCandidate}
										error={!updatedCandidate?.role_level_preferences?.length}
										placeholder={
											updatedCandidate?.role_level_preferences?.length > 0 ? '' : 'Select all that apply (max 2)'
										}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField
									label="Minimum Salary"
									required
									labelAddendum={
										!isParentChildSalaryMatch && candidate.child_salary_expectation_type
											? candidate.child_salary_expectation_type.label
											: null
									}
									labelAddendumTooltip="Candidate's salary expectation"
								>
									<SalaryDropdown
										variant="outlined"
										name="salary_expectation_type_id"
										value={updatedCandidate.salary_expectation_type_id}
										placeholder="Choose from our list"
										onChange={updateCandidate}
										error={!updatedCandidate.salary_expectation_type_id}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Role Types" required>
									<EmploymentPreferencesDropdown
										variant="outlined"
										name="employment_preferences"
										value={updatedCandidate?.employment_preferences?.map(item => (item.id ? item.id : item))}
										onChange={updateCandidate}
										error={!updatedCandidate?.employment_preferences?.length}
										placeholder={updatedCandidate?.employment_preferences?.length > 0 ? '' : 'Select all that apply'}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Next Role Priorities" required>
									<ValuePropositionsDropdown
										variant="outlined"
										name="job_priority_types"
										value={updatedCandidate?.job_priority_types?.map(t =>
											t.job_priority_type_id ? t.job_priority_type_id : t,
										)}
										placeholder="Select your top priorities"
										onChange={e => {
											updateCandidate({
												target: {
													name: 'job_priority_types',
													value: e?.target?.value?.map((id, i) => ({
														priority: i,
														job_priority_type_id: id,
													})),
												},
											});
										}}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<SpecialtyCategoryDropdown onChange={updateCandidate} model={updatedCandidate} />
							</Grid>
							<Grid item xs={12} style={{ marginTop: -10 }}>
								<LabeledField
									label="Business Sizes"
									info="If you want to work for a particular size company, please select them."
									required
								>
									<BusinessSizePreferencesDropdown
										variant="outlined"
										name="business_size_preferences"
										value={
											(Utility.getFromObj(updatedCandidate, 'business_size_preferences') || []).length === 8
												? [0]
												: (Utility.getFromObj(updatedCandidate, 'business_size_preferences') || []).map(item =>
														item.id ? item.id : item,
												  )
										}
										onChange={updateCandidate}
										placeholder={
											Utility.getFromObj(updatedCandidate, 'business_size_preferences').length > 0
												? ''
												: 'Select all that apply'
										}
										error={!updatedCandidate?.business_size_preferences?.length}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<IndustryPreferenceDropdown onChange={updateCandidate} model={updatedCandidate} />
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditPreferencesModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditPreferencesModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditPreferencesModal;

import React from 'react';
import Dropdown from './Dropdown';

const USWorkAuthorizationDropdown = props => {
	const USWorkAuthorizationOptions = [
		{ id: '1', label: 'Yes', value: '1' },
		{ id: '2', label: 'No', value: '2' },
	];
	return <Dropdown data={USWorkAuthorizationOptions} {...props} />;
};

export default USWorkAuthorizationDropdown;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import EditCandidateBlockModal from './EditCandidateBlockModal';
import LocationIcon from '../../../components/icons/LocationIcon';
import TravelWillingnessDropdown from '../../../components/form/dropdowns/TravelWillingnessDropdown';
import WillingToRelocateDropdown from '../../../components/form/dropdowns/WillingToRelocateDropdown';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import LocationTimezonesDropdown from '../../../components/form/dropdowns/LocationTimezonesDropdown';

const EditLocationPreferencesModal = ({ candidate, setCandidate }) => {
	return (
		<EditCandidateBlockModal
			candidate={candidate}
			setCandidate={setCandidate}
			title="Work Location Preferences"
			icon={LocationIcon}
			renderContents={({ updateCandidate, updatedCandidate }) => {
				const workLocationPreferenceValue = useMemo(
					() => updatedCandidate?.work_location_preferences?.map(p => (p.id ? p.id : p)),
					[updatedCandidate.work_location_preferences],
				);

				const hasLocalSelection = useMemo(() => workLocationPreferenceValue?.some(pid => [2, 3, 4, 5].includes(pid)), [
					workLocationPreferenceValue,
				]);
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Current Location">
									<TextField
										variant="outlined"
										name="city"
										placeholder="City"
										value={Utility.getFromObj(updatedCandidate, 'city')}
										onChange={updateCandidate}
										error={!Utility.getFromObj(updatedCandidate, 'city')}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={2}>
								<LabeledField label="State">
									<StatesDropdown
										variant="outlined"
										name="state"
										placeholder="State"
										value={Utility.getFromObj(updatedCandidate, 'state')}
										onChange={updateCandidate}
										error={!Utility.getFromObj(updatedCandidate, 'state')}
										fullWidth
										useAbbreviation
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={2}>
								<LabeledField label="Zip">
									<TextField
										variant="outlined"
										name="zip"
										placeholder="Zipcode"
										value={Utility.getFromObj(updatedCandidate, 'zip')}
										error={!Utility.getFromObj(updatedCandidate, 'zip')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LocationTimezonesDropdown onChange={updateCandidate} model={updatedCandidate} isErrorEnabled />
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Willing to Relocate" required={hasLocalSelection}>
									<WillingToRelocateDropdown
										variant="outlined"
										name="willing_to_relocate"
										value={hasLocalSelection ? updatedCandidate?.willing_to_relocate : null}
										error={hasLocalSelection && !updatedCandidate.willing_to_relocate}
										disabled={!hasLocalSelection}
										placeholder="Choose from our list"
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Desired Work States" required={hasLocalSelection}>
									<StatesDropdown
										multiple
										useID
										variant="outlined"
										name="desired_locations"
										value={
											hasLocalSelection
												? updatedCandidate?.desired_locations?.map(item => (item.state_id ? item.state_id : item))
												: null
										}
										disabled={!hasLocalSelection}
										onChange={updateCandidate}
										placeholder={updatedCandidate?.desired_locations?.length > 0 ? '' : 'Select all that apply'}
										error={hasLocalSelection && !updatedCandidate.desired_locations.length}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Travel" required>
									<TravelWillingnessDropdown
										variant="outlined"
										name="travel_willingness_type_id"
										value={updatedCandidate.travel_willingness_type_id}
										error={!updatedCandidate.travel_willingness_type_id}
										placeholder="Choose from our list"
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditLocationPreferencesModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditLocationPreferencesModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditLocationPreferencesModal;

import React from 'react';

const CandidatesSVG = props => (
	<svg width={22} height={18} fill="none" {...props}>
		<path
			d="M10.7 9.22A4.92 4.92 0 019 5.5a5 5 0 1110 0 4.92 
      4.92 0 01-1.7 3.72A8 8 0 0122 16.5a1 1 0 01-2 0 6 6 
      0 10-12 0 1 1 0 11-2 0 8 8 0 014.7-7.28zM14 8.5a3 3 0 
      100-6 3 3 0 000 6zm-9.74.32A5 5 0 018 .5a1 1 0 010 2 3 3 0 
      00-3 3 3 3 0 001.5 2.59 1 1 0 01.05 1.7l-.39.26-.13.07a7 7 0 
      00-4 6.38 1 1 0 11-2 0 9 9 0 014.23-7.68z"
			fill="#A5AEBC"
		/>
	</svg>
);

export default CandidatesSVG;

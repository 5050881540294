import React, { useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { Typography, TextField, Grid } from '@material-ui/core';
import useLogin from '../hooks/useLogin';
import Page from '../components/page/Page';
import Alert from '../components/Alert';
import LabeledField from '../components/form/LabeledField';
import VerticalSpacer from '../components/VerticalSpacer';
import { Panel, PanelHeader, PanelContent } from '../components/Panel';
import SubmitButton from '../components/form/SubmitButton';
import Fence from '../components/Fence';
import LoginImage from '../img/culture.png';
import { ActionCreators as SiteActions } from '../store/Site';
import * as UserAPI from '../scripts/user';
import '../css/login.css';
import { palette } from '../BobcatTheme';

const PrivacyPolicyBlock = () => {
	return (
		<p
			style={{
				textAlign: 'left',
				marginBottom: -10,
				marginTop: 30,
				opacity: 0.7,
			}}
		>
			<a
				target="_blank"
				rel="noreferrer"
				className="color-inherit"
				style={{
					display: 'inline-block',
					fontWeight: 300,
					textDecoration: 'underline',
				}}
				href="https://nauwork.com/privacy"
			>
				Privacy Notice
			</a>{' '}
			and{' '}
			<a
				target="_blank"
				className="color-inherit"
				rel="noreferrer"
				style={{
					display: 'inline-block',
					fontWeight: 300,
					textDecoration: 'underline',
				}}
				href="https://nauwork.com/terms"
			>
				Terms of Use
			</a>
		</p>
	);
};

const LoginPage = () => {
	const store = useStore();
	const history = useHistory();
	const { loading, login, username, setUsername, password, setPassword } = useLogin();

	const submitLogin = e => {
		e.preventDefault();
		login(response => {
			// const historyRedirect = Utility.getFromObj(history, 'location', 'state', 'redirect');
			// if (historyRedirect) {
			// 	console.log('historyRedirect?');
			// 	history.push(historyRedirect);
			// } else {

			UserAPI.getCurrentUser(res => {
				const currentUser = res.data;

				if (currentUser?.roles?.find(x => x?.name === 'admin' || x?.name === 'recruitment-manager')) {
					history.push('/');
				} else if (currentUser?.roles?.find(x => x?.name === 'contact')) {
					history.push(`/contact/${currentUser.contact_id}/profile`);
				} else if (currentUser?.roles?.find(x => x?.name === 'candidate')) {
					history.push(`/candidate/${currentUser.candidate_id}/profile`);
				} else {
					store.dispatch(SiteActions.showAlert('There was an error logging you in. Please try again.', 'error'));
				}
			});
			// }
		});
	};

	return (
		<Page className="login-page login-page-external" topbar="center" sidebar="none" skipAuth skipData>
			<div style={{ position: 'relative' }}>
				<div style={{ position: 'absolute', opacity: 0.4, top: -100, left: 0, width: '100%' }}>
					<Fence width={71.429} minWidth={71.429}>
						<VerticalSpacer height={2.5} />
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Panel
									id="ContactInfo"
									style={{
										height: 'calc(100% - 2rem)',
										background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%)`,
									}}
								>
									<PanelHeader className="spread" style={{ alignItems: 'flex-start' }}>
										<VerticalSpacer height={5} />
									</PanelHeader>
									<PanelContent>
										<VerticalSpacer height={10} />
									</PanelContent>
								</Panel>
							</Grid>
							<Grid item xs={6}>
								<Panel
									id="RecruiterInfo"
									style={{
										height: 'calc(100% - 2rem)',
										background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%)`,
									}}
								>
									<PanelHeader>
										<VerticalSpacer height={5} />
									</PanelHeader>
									<PanelContent>
										<VerticalSpacer height={10} />
									</PanelContent>
								</Panel>
							</Grid>
							<Grid item xs={12}>
								<Panel
									id="BlockPlaceholder"
									style={{
										height: 'calc(100% - 2rem)',
										background: `linear-gradient(180deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.25) 100%)`,
									}}
								>
									<PanelHeader>
										<VerticalSpacer height={5} />
									</PanelHeader>
									<PanelContent>
										<VerticalSpacer height={10} />
									</PanelContent>
								</Panel>
							</Grid>
							<Grid item xs={12}>
								<Panel
									id="BlockPlaceholder"
									style={{
										height: 'calc(100% - 2rem)',
										background: `linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.1) 100%)`,
									}}
								>
									<PanelHeader>
										<VerticalSpacer height={5} />
									</PanelHeader>
									<PanelContent>
										<VerticalSpacer height={10} />
									</PanelContent>
								</Panel>
							</Grid>
						</Grid>
					</Fence>
				</div>
				<Grid container spacing={0} style={{ position: 'relative' }}>
					<Grid item xs={3} />
					<Grid item xs={6}>
						<form onSubmit={e => submitLogin(e)}>
							<Typography variant="h2" className="font-lato-bold">
								Login to your NauMatch portal
							</Typography>
							<VerticalSpacer height={3} />
							<Alert />
							<LabeledField label="E-mail">
								<TextField
									name={username}
									fullWidth
									variant="outlined"
									placeholder="Your e-mail"
									value={username}
									onChange={ev => setUsername(ev.target.value)}
								/>
							</LabeledField>
							<LabeledField label="Password">
								<TextField
									type="password"
									fullWidth
									variant="outlined"
									value={password}
									placeholder="Your password"
									onChange={ev => setPassword(ev.target.value)}
								/>
							</LabeledField>
							<VerticalSpacer height={3} />
							<SubmitButton
								loading={loading}
								onClick={e => submitLogin(e)}
								disabled={!username || !password}
								className="login-button"
							>
								Log In
							</SubmitButton>

							<p
								style={{
									textAlign: 'left',
									marginBottom: -10,
									marginTop: 30,
									opacity: 0.7,
								}}
							>
								<NavLink
									to={`/forgot-password?email=${username}`}
									className="color-inherit"
									style={{
										display: 'inline-block',
										fontWeight: 300,
										textDecoration: 'underline',
									}}
								>
									Forgot Password?
								</NavLink>
							</p>
							<PrivacyPolicyBlock />
						</form>
					</Grid>
					<Grid item xs={3} />
				</Grid>
			</div>
		</Page>
	);
};

export default LoginPage;

import React from 'react';
import { Chart } from 'react-charts';
import { Grid, Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

import Page from '../components/page/Page';
import * as PageHeader from '../components/page/PageHeader';
import { Panel, PanelContent } from '../components/Panel';
import Section from '../components/Section';
import DateInput from '../components/form/inputs/DateInput';
import HorizontalSpacer from '../components/HorizontalSpacer';

const Home = () => {
	const data = React.useMemo(
		() => [
			{
				label: 'Candidates',
				color: 'var(--secondaryColor)',
				opacity: '0.5',
				data: [
					{ primary: 1, secondary: 100 },
					{ primary: 2, secondary: 50 },
					{ primary: 3, secondary: 80 },
					{ primary: 4, secondary: 45 },
					{ primary: 5, secondary: 60 },
					{ primary: 6, secondary: 30 },
					{ primary: 7, secondary: 75 },
					{ primary: 8, secondary: 70 },
					{ primary: 9, secondary: 90 },
					{ primary: 10, secondary: 35 },
					{ primary: 11, secondary: 100 },
					{ primary: 12, secondary: 70 },
				],
			},
			{
				label: 'Jobs',
				color: 'var(--primaryColor)',
				opacity: 0.5,
				data: [
					{ primary: 1, secondary: 50 },
					{ primary: 2, secondary: 90 },
					{ primary: 3, secondary: 60 },
					{ primary: 4, secondary: 85 },
					{ primary: 5, secondary: 75 },
					{ primary: 6, secondary: 85 },
					{ primary: 7, secondary: 80 },
					{ primary: 8, secondary: 82 },
					{ primary: 9, secondary: 75 },
					{ primary: 10, secondary: 45 },
					{ primary: 11, secondary: 85 },
					{ primary: 12, secondary: 75 },
				],
			},
		],
		[],
	);

	const series = React.useMemo(() => ({ type: 'area' }), []);

	const axes = React.useMemo(
		() => [
			{ primary: true, type: 'time', position: 'bottom', show: false },
			{ type: 'linear', position: 'left', show: false, hardMin: 0 },
		],
		[],
	);

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Dashboard</Typography>
				</PageHeader.Left>
			</PageHeader.Header>

			<Section>
				<Grid container spacing={3}>
					<Grid item xs={3}>
						<Panel>
							<PanelContent>
								<Section>
									<Typography variant="subtitle1" component="h2">
										Jobs
									</Typography>
								</Section>
								<Icons.Work color="primary" style={{ fontSize: 32, verticalAlign: 'text-bottom', marginRight: 20 }} />
								<Typography variant="h1" component="span">
									6
								</Typography>
							</PanelContent>
						</Panel>
					</Grid>
					<Grid item xs={3}>
						<Panel>
							<PanelContent>
								<Section>
									<Typography variant="subtitle1" component="h2">
										Job Assessments
									</Typography>
								</Section>
								<Icons.Assignment
									color="primary"
									style={{ fontSize: 32, verticalAlign: 'text-bottom', marginRight: 20 }}
								/>
								<Typography variant="h1" component="span">
									81
								</Typography>
							</PanelContent>
						</Panel>
					</Grid>
					<Grid item xs={3}>
						<Panel>
							<PanelContent>
								<Section>
									<Typography variant="subtitle1" component="h2">
										Candidates
									</Typography>
								</Section>
								<Icons.People color="primary" style={{ fontSize: 32, verticalAlign: 'text-bottom', marginRight: 20 }} />
								<Typography variant="h1" component="span">
									33
								</Typography>
							</PanelContent>
						</Panel>
					</Grid>
					<Grid item xs={3}>
						<Panel>
							<PanelContent>
								<Section>
									<Typography variant="subtitle1" component="h2">
										Candidate Assessments
									</Typography>
								</Section>
								<Icons.Assignment
									color="primary"
									style={{ fontSize: 32, verticalAlign: 'text-bottom', marginRight: 20 }}
								/>
								<Typography variant="h1" component="span">
									13
								</Typography>
							</PanelContent>
						</Panel>
					</Grid>
				</Grid>
			</Section>

			<Section>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<Typography variant="h3" component="h2" gutterBottom>
							Assessments Collected
						</Typography>
						<Panel>
							<PanelContent className="vertical-center-align">
								<DateInput size="small" onChange={() => {}} />
								<HorizontalSpacer />
								to
								<HorizontalSpacer />
								<DateInput size="small" onChange={() => {}} />
							</PanelContent>
							<div style={{ width: '100%', height: '350px' }}>
								<Chart data={data} series={series} axes={axes} tooltip />
							</div>
						</Panel>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="h3" component="h2" gutterBottom>
							Activity History
						</Typography>
						<Panel>
							<PanelContent className="spread">
								<div>
									<Typography variant="h3">Guy Miles</Typography>
									<Typography variant="body2">Recruitment Manager</Typography>
								</div>
								<div>
									<Typography variant="body2">
										<b>Sent 13 assessments</b>
										<br />
										14 min. ago
									</Typography>
								</div>
							</PanelContent>
						</Panel>
						<Panel>
							<PanelContent className="spread">
								<div>
									<Typography variant="h3">Larry Fitzgerald</Typography>
									<Typography variant="body2">Recruitment Manager</Typography>
								</div>
								<div>
									<Typography variant="body2">
										<b>Sent 13 assessments</b>
										<br />
										14 min. ago
									</Typography>
								</div>
							</PanelContent>
						</Panel>
						<Panel>
							<PanelContent className="spread">
								<div>
									<Typography variant="h3">Hank Aaron</Typography>
									<Typography variant="body2">Recruitment Manager</Typography>
								</div>
								<div>
									<Typography variant="body2">
										<b>Sent 13 assessments</b>
										<br />
										14 min. ago
									</Typography>
								</div>
							</PanelContent>
						</Panel>
						<Panel>
							<PanelContent className="spread">
								<div>
									<Typography variant="h3">Josh Wilton</Typography>
									<Typography variant="body2">Recruitment Manager</Typography>
								</div>
								<div>
									<Typography variant="body2">
										<b>Sent 13 assessments</b>
										<br />
										14 min. ago
									</Typography>
								</div>
							</PanelContent>
						</Panel>
						<Panel>
							<PanelContent className="spread">
								<div>
									<Typography variant="h3">Millie Henderson</Typography>
									<Typography variant="body2">Recruitment Manager</Typography>
								</div>
								<div>
									<Typography variant="body2">
										<b>Sent 13 assessments</b>
										<br />
										14 min. ago
									</Typography>
								</div>
							</PanelContent>
						</Panel>
					</Grid>
				</Grid>
			</Section>
		</Page>
	);
};

export default Home;

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const TimezonesDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const { value } = props;
	const dataWithAnyValue = [
		{
			id: 0,
			name: 'any',
			label: 'Any',
		},
		...data.timeZones,
	];
	const dataToShow = value?.includes(0) ? [dataWithAnyValue[0]] : dataWithAnyValue;
	return <Dropdown data={dataToShow} {...props} />;
};

export default TimezonesDropdown;

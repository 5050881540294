import API from './api';
import * as Utility from './utility';

// API ENDPOINTS

/**
 * Gets a paginated list of specialties
 * @param {Object} parameters - Filters passed as query parameters
 * @param {*} callback - The function called once AJAX call is finished
 * @param {*} store - The Redux data store
 */
export const getSpecialties = (parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/specialties${query}`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Gets a full list of specialties
 * @param {Object} parameters - Filters passed as query parameters
 * @param {*} callback - The function called once AJAX call is finished
 * @param {*} store - The Redux data store
 */
export const getAllSpecialties = (parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/specialties/tree${query}`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Gets the data for a specific specialty
 * @param {Number} id - The ID of the specialty
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const getSpecialty = (id, callback) => {
	API.get(`/specialties/${id}/tree`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Updates a specialty record
 * @param {Number} id - The ID of the specialty
 * @param {Object} parameters - The parameters for the specialty
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const updateSpecialty = (id, parameters, callback) => {
	const specialty = parameters;

	if (specialty.label && !specialty.name) specialty.name = specialty.label;

	API.patch(`/specialties/${id}`, specialty)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Creates a new specialty record
 * @param {Object} parameters - The parameters for the specialty
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const addSpecialty = (parameters, callback) => {
	const specialty = parameters;

	if (specialty.label && !specialty.name) specialty.name = specialty.label;

	API.post(`/specialties`, specialty)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Deletes a specialty record
 * @param {Number} id - The ID of the specialty
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const deleteSpecialty = (id, callback) => {
	API.delete(`/specialties/${id}`)
		.then(callback)
		.catch(err => callback(false, err));
};

// FUNCTIONS

export const getCategory = (categories, id) => {
	if (!categories || !id) return {};

	return categories.find(category => category.specialties.some(specialty => specialty.id === id)) || {};
};

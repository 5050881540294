import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { TextField, Typography } from '@material-ui/core';
import LabeledField from '../form/LabeledField';
import LoadingButton from '../form/LoadingButton';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as SovrenAPI from '../../scripts/sovren';
import * as Utility from '../../scripts/utility';

const SovrenSkillAddModal = ({ skill, onAdd, ...props }) => {
	const store = useStore();
	const [newSkill, setNewSkill] = useState({ skill_id: skill.id });
	const [isLoading, setLoading] = useState(false);

	const onSkillAdd = response => {
		setLoading(false);
		store.dispatch(SiteActions.hideModal());

		if (!response) {
			store.dispatch(
				SiteActions.showAlert('An error ocurred when adding the sovren skill. Please try again.', 'error'),
			);
			return;
		}

		if (typeof onAdd === 'function') onAdd();
	};

	const addSkill = ev => {
		if (newSkill.label && newSkill.sovren_original_skill_id && newSkill.taxonomy_id && newSkill.subtaxonomy_id) {
			setLoading(true);
			SovrenAPI.addSovrenSkill(newSkill, onSkillAdd);
		} else if (!Object.prototype.hasOwnProperty.call(newSkill, 'label')) {
			setNewSkill({ ...newSkill, label: '' });
		} else if (!Object.prototype.hasOwnProperty.call(newSkill, 'sovren_original_skill_id')) {
			setNewSkill({ ...newSkill, sovren_original_skill_id: '' });
		} else if (!Object.prototype.hasOwnProperty.call(newSkill, 'taxonomy_id')) {
			setNewSkill({ ...newSkill, taxonomy_id: '' });
		} else if (!Object.prototype.hasOwnProperty.call(newSkill, 'subtaxonomy_id')) {
			setNewSkill({ ...newSkill, subtaxonomy_id: '' });
		}
	};

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Add Sovren Skill
			</Typography>

			{/* <Typography variant="body2" style={{ marginBottom: 32 }}>
				Please confirm that you want to permanently delete the sovren record
			</Typography> */}

			<LabeledField label="Name*">
				<TextField
					error={Object.prototype.hasOwnProperty.call(newSkill, 'label') && !newSkill.label.length}
					variant="outlined"
					name="label"
					value={newSkill.label}
					onChange={ev => Utility.updateState(ev, newSkill, setNewSkill)}
					fullWidth
				/>
			</LabeledField>

			<LabeledField label="Sovren Skill ID*">
				<TextField
					error={
						Object.prototype.hasOwnProperty.call(newSkill, 'sovren_original_skill_id') &&
						!newSkill.sovren_original_skill_id.length
					}
					variant="outlined"
					name="sovren_original_skill_id"
					value={newSkill.sovren_original_skill_id}
					onChange={ev => Utility.updateState(ev, newSkill, setNewSkill)}
					fullWidth
				/>
			</LabeledField>

			<LabeledField label="Taxonomy ID*">
				<TextField
					error={Object.prototype.hasOwnProperty.call(newSkill, 'taxonomy_id') && !newSkill.taxonomy_id.length}
					variant="outlined"
					name="taxonomy_id"
					value={newSkill.taxonomy_id}
					onChange={ev => Utility.updateState(ev, newSkill, setNewSkill)}
					fullWidth
				/>
			</LabeledField>

			<LabeledField label="Subtaxonomy ID*">
				<TextField
					error={Object.prototype.hasOwnProperty.call(newSkill, 'subtaxonomy_id') && !newSkill.subtaxonomy_id.length}
					variant="outlined"
					name="subtaxonomy_id"
					value={newSkill.subtaxonomy_id}
					onChange={ev => Utility.updateState(ev, newSkill, setNewSkill)}
					fullWidth
				/>
			</LabeledField>

			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="primary"
				onClick={addSkill}
				style={{ minWidth: 185 }}
			>
				Add Sovren Skill
			</LoadingButton>
		</div>
	);
};

SovrenSkillAddModal.propTypes = {
	skill: PropTypes.shape({ id: PropTypes.number }).isRequired,
	onAdd: PropTypes.func,
};

SovrenSkillAddModal.defaultProps = {
	onAdd: () => {},
};

export default SovrenSkillAddModal;

import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useStore } from 'react-redux';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import WelcomeForm from './WelcomeForm';
import ExperienceForm from './ExperienceForm/index';
import SkillsForm from './Skills/SkillsForm';
import SkillRankingsForm from './Skills/SkillRankingsForm';
import SkillStoryForm from './Skills/SkillStoryForm';
import VerticalSpacer from '../../../VerticalSpacer';
import PreferenceForms from './PreferenceForm';
import ResumeForm from './ResumeForm';
import * as Utililty from '../../../../scripts/utility';

const CandidateAssessmentModal = ({ setCandidateProfile, getCandidateResumes }) => {
	const { progress } = useSelector(state => state.assessment, shallowEqual);
	const store = useStore();

	const getStepIcon = state => {
		const cn = `step-icon ${state.active ? 'step-icon-active' : ''} ${state.completed ? 'step-icon-completed' : ''}`;
		return <span className={cn} />;
	};

	const isNotComplete = step => {
		if (!progress.completed.includes(step)) {
			return true;
		}
		return false;
	};

	return (
		<div className="assessment">
			<Stepper activeStep={progress.active - 1} alternativeLabel>
				<Step>
					<StepLabel
						onClick={() => {
							if (isNotComplete(0)) return;
							Utililty.setLocalStorageItems({ PS: 1 });
							store.dispatch(AssessmentActions.setPage(1));
						}}
						style={{ cursor: isNotComplete(0) ? 'default' : 'pointer' }}
						StepIconComponent={getStepIcon}
					>
						Preferences
					</StepLabel>
				</Step>
				<Step>
					<StepLabel
						onClick={() => {
							if (isNotComplete(1)) return;
							Utililty.setLocalStorageItems({ ES: 1 });
							store.dispatch(AssessmentActions.setPage(2));
						}}
						style={{ cursor: isNotComplete(1) ? 'default' : 'pointer' }}
						StepIconComponent={getStepIcon}
					>
						Experience
					</StepLabel>
				</Step>
				<Step>
					<StepLabel
						onClick={() => {
							if (isNotComplete(2) || isNotComplete(2.5)) return;
							store.dispatch(AssessmentActions.setPage(3));
						}}
						style={{ cursor: isNotComplete(2) || isNotComplete(2.5) ? 'default' : 'pointer' }}
						StepIconComponent={getStepIcon}
					>
						Skills
					</StepLabel>
				</Step>
				<Step>
					<StepLabel
						onClick={() => {
							if (isNotComplete(3)) return;
							store.dispatch(AssessmentActions.setPage(4));
						}}
						style={{ cursor: isNotComplete(3) ? 'default' : 'pointer' }}
						StepIconComponent={getStepIcon}
					>
						Skill Story
					</StepLabel>
				</Step>
			</Stepper>
			<VerticalSpacer height={3} />
			{progress.active === 0 && <WelcomeForm />}
			{progress.active === 1 && <PreferenceForms />}
			{progress.active === 2 && <ResumeForm />}
			{progress.active === 2.5 && <ExperienceForm />}
			{progress.active === 3 && <SkillsForm />}
			{progress.active === 3.5 && <SkillRankingsForm />}
			{progress.active === 4 && (
				<SkillStoryForm setCandidateProfile={setCandidateProfile} getCandidateResumes={getCandidateResumes} />
			)}
		</div>
	);
};

CandidateAssessmentModal.propTypes = {
	setCandidateProfile: PropTypes.func,
	getCandidateResumes: PropTypes.func,
};

CandidateAssessmentModal.defaultProps = {
	setCandidateProfile: () => null,
	getCandidateResumes: () => null,
};

export default CandidateAssessmentModal;

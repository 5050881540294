import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import * as ListingsAPI from '../scripts/listings';
import { ActionCreators as SiteActions } from '../store/Site';

const useListing = ({ id, lazy, onComplete }) => {
	const store = useStore();
	const [listing, setListing] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const fetch = lazyId => {
		setLoading(true);
		ListingsAPI.getListing(id, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the listing. Refresh the page to try again.', 'error'));
				setLoading(false);
				setError(true);
				return;
			}
			setListing(response.data.data);
			setLoading(false);
			if (onComplete) onComplete(response);
		});
	};

	useEffect(() => {
		if (id && !lazy) fetch(id);
	}, [id, lazy]);

	return { loading, listing, error, fetch };
};

export default useListing;

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const IndustryExperienceDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return <Dropdown data={data.industries} {...props} />;
};

export default IndustryExperienceDropdown;

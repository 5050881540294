import React, { useMemo } from 'react';
import { Grid, Typography, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import ViewBlockWrapper from './ViewBlockWrapper';
import PreferencesIcon from '../../../components/icons/PreferencesIcon';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditPreferencesModal from './EditPreferencesModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import ExpandableTypography from '../../../components/form/ExpandableTypography';
import VerticalSpacer from '../../../components/VerticalSpacer';

const Block = ({
	candidate,
	setCandidate,
	data,
	minimized,
	setMinimized,
	comparisonJob,
	disableEdit,
	displayMatchValidation,
	isLimited,
	graphMatch,
	hideLabelAddendums,
}) => {
	const dispatch = useDispatch();

	const isParentChildSalaryMatch = useMemo(() => {
		if (!candidate?.salary_expectation_type?.id || !candidate?.child_salary_expectation_type?.id) return false;
		return candidate?.salary_expectation_type.id === candidate?.child_salary_expectation_type.id;
	}, [candidate?.salary_expectation_type, candidate?.child_salary_expectation_type]);

	return (
		<>
			<ViewBlockWrapper
				icon={PreferencesIcon}
				title="Preferences"
				secondaryTitle={
					graphMatch?.total_preference_score
						? `Score: ${Math.round(graphMatch?.total_preference_score * 100)}% (${Math.round(
								graphMatch?.completeness_score_preference * 100,
						  )}%)`
						: null
				}
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditPreferencesModal candidate={candidate} setCandidate={setCandidate} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{candidate?.reason_seeking_new_role && (
							<LabeledField label="Reason For Seeking a New Role" className="color-muted" removeMargin close>
								<ExpandableTypography numCharacters={300} text={candidate?.reason_seeking_new_role} />
							</LabeledField>
						)}
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Desired Role" className="color-muted" removeMargin close>
							<Typography variant="body2">{candidate?.desired_position || Utility.nb}</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Role Levels" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									// TODO: compare to job field?
									propertyName: 'role_level',
								})}
								column
							>
								{candidate?.role_level_preferences?.map(
									({ id }) =>
										<Typography>{Utility.getItemLabel(data.roleLevelPreferences, id)}</Typography> || Utility.nb,
								)}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField
							label="Minimum Salary"
							className="color-muted"
							removeMargin
							close
							labelAddendum={
								!hideLabelAddendums && !isParentChildSalaryMatch && candidate?.child_salary_expectation_type
									? candidate?.child_salary_expectation_type.label
									: null
							}
							labelAddendumTooltip="Candidate's salary expectation"
						>
							<ValidationFieldWrapper
								column
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'salary',
								})}
							>
								{isLimited ? (
									<Typography
										variant="body2"
										style={{
											filter: 'blur(5px)',
											webkitFilter: 'blur(5px)',
											userSelect: 'none',
											WebkitUserSelect: 'none',
											MozUserSelect: 'none',
											msUserSelect: 'none',
										}}
										onCopy={e => e.preventDefault()}
									>
										$hid,den
									</Typography>
								) : (
									<Typography variant="body2">{candidate?.salary_expectation_type?.label || Utility.nb}</Typography>
								)}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Role Types" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'role_type',
								})}
								column
							>
								{candidate?.employment_preferences?.map(
									({ id }) =>
										<Typography>{Utility.getItemLabel(data.employmentPreferences, id)}</Typography> || Utility.nb,
								)}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Next Role Priorities" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									// TODO: compare to job field?
									propertyName: 'value_proposition_type',
								})}
								column
							>
								{candidate?.job_priority_types?.length > 0 && (
									<Typography>
										<VerticalSpacer height={0.5} />
										{candidate?.job_priority_types.map(priority => (
											<Chip
												size="small"
												key={priority.id}
												label={priority.value_proposition_type.label}
												color="secondary"
											/>
										))}
									</Typography>
								)}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					{/* TODO: compare to job field? */}
					<Grid item xs={6}>
						<LabeledField label="Category" className="color-muted" removeMargin close>
							<Typography>
								{candidate?.specialties?.map(specialty => {
									const categoryId = specialty?.category_id || specialty?.category?.id;
									return (
										<Typography variant="body2">
											{Utility.getItemLabel(data.categories, categoryId) || Utility.nb}
										</Typography>
									);
								})}
							</Typography>
						</LabeledField>
					</Grid>
					{/* TODO: compare to job field? */}
					<Grid item xs={6}>
						<LabeledField label="Specialty" className="color-muted" removeMargin close>
							<Typography>
								{candidate?.specialties?.map(
									({ id: specialtyId }) =>
										<Typography>{Utility.getItemLabel(data.specialties, specialtyId)}</Typography> || Utility.nb,
								)}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Business Sizes" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									// TODO: compare to job field?
									propertyName: 'business_size',
								})}
								column
							>
								{candidate?.business_size_preferences?.length === 8 &&
								candidate?.business_size_preferences
									?.map(s => s.id)
									.sort()
									.every((value, index) => value === [1, 2, 3, 4, 5, 6, 7, 8][index]) ? (
									<Typography>Any</Typography>
								) : (
									candidate.business_size_preferences
										.sort((a, b) => a.id - b.id)
										.map(({ id }) => <Typography>{Utility.getItemLabel(data.businessSizePreferences, id)}</Typography>)
								)}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Industries" className="color-muted" removeMargin close>
							<VerticalSpacer height={0.5} />
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									// TODO: compare to job field?
									propertyName: 'industry',
								})}
							>
								<div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
									{candidate?.industry_preferences?.map(({ id }) => {
										return <Chip key={id} label={Utility.getItemLabel(data.industries, id)} color="gray" />;
									})}
								</div>
							</ValidationFieldWrapper>
						</LabeledField>
						<VerticalSpacer height={1} />
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

import React, { useEffect, useState, useMemo } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid, CircularProgress, ButtonBase } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ElasticSkillDropdown from '../../../../../form/dropdowns/ElasticSkillDropdown';
import PlusIcon from '../../../../../icons/PlusIcon';
import Fence from '../../../../../Fence';
import VerticalSpacer from '../../../../../VerticalSpacer';
import LabeledField from '../../../../../form/LabeledField';
import SkillDropdown from '../../../../../form/dropdowns/SkillDropdown';
import LoadingButton from '../../../../../form/LoadingButton';
import SuggestSkillForm from '../../../SuggestSkillForm';
import { ActionCreators as SiteActions } from '../../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../../store/Assessment';
import * as SiteAPI from '../../../../../../scripts/site';
import * as CandidatesAPI from '../../../../../../scripts/candidates';
import * as Utility from '../../../../../../scripts/utility';

const SkillsForm = () => {
	const pageNum = 3;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const [showSuggestSkillForm, setShowSuggestSkillForm] = useState(false);
	const [selectedMissingSkills, setSelectedMissingSkills] = useState([]);
	const [currentMoreSkillsPage, setCurrentMoreSkillsPage] = useState(1);
	const [latestSkillData, setLatestSkillData] = useState(null);
	// const [validSkills, setValidSkills] = useState([]);
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: candidateID } = useParams();
	const { candidate, progress, matchedSkills, suggestedSkills, missingSkills } = useSelector(
		state => state.assessment,
		shallowEqual,
	);

	const paginatedMoreSkills = useMemo(() => {
		const pageSize = 20;
		return suggestedSkills.slice(0, pageSize * currentMoreSkillsPage);
	}, [suggestedSkills, currentMoreSkillsPage]);

	const addMissingSkills = () => {
		store.dispatch(AssessmentActions.addMissingSkills(selectedMissingSkills));
		setSelectedMissingSkills([]);
	};

	const nextStep = () => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));

		const removedSkills = Utility.uniqueIDs(
			candidate.skills.filter(s => !matchedSkills.find(ms => ms.id === s.skill_id)),
		).map(s => ({
			skill_id: s.id,
			skill_experience_id: null,
			experience_years: 0,
		}));
		const newSkills = Utility.uniqueIDs(
			matchedSkills.filter(s => !candidate.skills.find(cs => cs.skill_id === s.id)),
		).map(s => ({
			skill_id: s.id,
			skill_experience_id: 1,
			experience_years: 0,
		}));
		const retainedSkills = Utility.uniqueIDs(
			candidate.skills.filter(s => matchedSkills.find(ms => ms.id === s.skill_id)),
		)
			.map(s => ({
				skill_id: s.id,
				skill_experience_id: 1,
				experience_years: 0,
			}))
			.map(s => {
				const rankedSkill = candidate.skills.find(rs => rs.skill_id === s.skill_id && rs.skill_experience_id);
				const newSkill = rankedSkill ? { ...s, skill_experience_id: rankedSkill.skill_experience_id } : s;
				return newSkill;
			});

		CandidatesAPI.addCandidateSkills(candidateID, [...removedSkills, ...newSkills, ...retainedSkills], response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateCandidateSkills(response.data.data));
			store.dispatch(AssessmentActions.addCompletedPage(pageNum));
			SiteAPI.updateSkills(store);
			store.dispatch(AssessmentActions.setPage(pageNum + 0.5));
		});

		// CandidatesAPI.syncCandidateSkills(candidateID, skills, response => {
		// 	if (!response) {
		// 		SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
		// 		store.dispatch(AssessmentActions.setIsSaving(false));
		// 		return;
		// 	}
		// 	store.dispatch(AssessmentActions.updateCandidateSkills(response.data.data));
		// 	store.dispatch(AssessmentActions.addCompletedPage(pageNum));
		// 	SiteAPI.updateSkills(store);
		// 	store.dispatch(AssessmentActions.setPage(pageNum + 1));
		// });
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setCandidate(response.data.data));
			// store.dispatch(
			// 	AssessmentActions.setMatchedSkills(
			// 		Utility.uniqueIDs(matchedSkills.concat((response.data.data.skills || []).map(skill => skill.skill))),
			// 	),
			// );
			setIsLoading(false);
		});
		// if (!validSkills.length) {
		// 	CandidatesAPI.getValidSkills(candidateID, response => {
		// 		if (response) setValidSkills(response.data.data);
		// 	});
		// }
	}, []);

	// Check if can proceed
	useEffect(() => {
		const canProceed = matchedSkills.length >= 1;
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [matchedSkills]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Skills
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Build your comprehensive skill list by adding or removing skills to the <strong>My Skills</strong> section.
			</Typography>
			<VerticalSpacer height={3} />
			<LabeledField label="My Skills" />
			<Fence>
				{matchedSkills.map(skill => (
					<Chip
						key={skill.id}
						label={skill.label}
						onDelete={ev => store.dispatch(AssessmentActions.removeMatchedSkill(skill.id))}
						color="primary"
					/>
				))}
				{matchedSkills.length === 0 ? 'None' : null}
			</Fence>
			<VerticalSpacer height={3} />
			<LabeledField label="Suggested Skills" />
			<Fence>
				{paginatedMoreSkills.length > 0
					? paginatedMoreSkills.map(skill => (
							<Chip
								key={skill.id}
								label={skill.label}
								onClick={() => store.dispatch(AssessmentActions.addSuggestedSkill(skill.id))}
							/>
					  ))
					: 'None'}
				{suggestedSkills.length > paginatedMoreSkills.length && (
					<Typography variant="h2" style={{ display: 'inline' }}>
						...
					</Typography>
				)}
			</Fence>
			{suggestedSkills.length > paginatedMoreSkills.length ? (
				<ButtonBase className="button-show-more" onClick={() => setCurrentMoreSkillsPage(p => p + 1)}>
					<PlusIcon />
					VIEW MORE
				</ButtonBase>
			) : null}
			<VerticalSpacer height={3} />
			<LabeledField label="Add Missing Skills">
				<Grid container spacing={2}>
					<Grid item xs={7}>
						<ElasticSkillDropdown
							filterCandidateId={candidateID}
							tags
							variant="outlined"
							color="primary"
							name="skills_expert"
							filter={skillId => {
								return (
									!candidate.skills.find(s => s.skill_id === skillId) || !matchedSkills.find(s => s.id === skillId)
								);
							}}
							value={selectedMissingSkills?.map(x => x.id || x)}
							onChange={(ev, skillData) => {
								if (skillData) {
									setLatestSkillData(skillData);
								}
								const newSkills = ev.target.value?.map(x => (skillData || latestSkillData)[x])?.filter(x => x);

								setSelectedMissingSkills(newSkills);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							color="primary"
							className="no-margins"
							disabled={!selectedMissingSkills.length}
							onClick={() => addMissingSkills()}
						>
							Add
						</Button>
					</Grid>
				</Grid>
				<VerticalSpacer height={0.5} />
				<ButtonBase onClick={() => setShowSuggestSkillForm(s => !s)} className="button-text-muted">
					Don&lsquo;t See a Skill You&lsquo;re Looking For?
				</ButtonBase>
			</LabeledField>
			{showSuggestSkillForm && (
				<SuggestSkillForm candidateID={candidateID} onClose={() => setShowSuggestSkillForm(false)} />
			)}
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button
					variant="outlined"
					color="primary"
					size="large"
					onClick={() => {
						Utility.setLocalStorageItems({ ES: 4 });
						store.dispatch(AssessmentActions.setPage(pageNum - 0.5));
					}}
				>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next Step
				</LoadingButton>
			</Fence>
		</>
	);
};

SkillsForm.propTypes = {};

SkillsForm.defaultProps = {};

export default SkillsForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { Typography } from '@material-ui/core';
import LabeledField from '../form/LabeledField';
import LoadingButton from '../form/LoadingButton';
import Dropdown from '../form/dropdowns/Dropdown';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as SkillsAPI from '../../scripts/skills';
import * as Utility from '../../scripts/utility';

const SovrenSkillBulkStatusModal = ({ skills, onEdit, ...props }) => {
	const store = useStore();
	const [isLoading, setLoading] = useState(false);
	const [status, setStatus] = useState('pending');

	const onSkillsUpdate = response => {
		setLoading(false);
		store.dispatch(SiteActions.hideModal());

		if (!response) {
			store.dispatch(
				SiteActions.showAlert('An error ocurred when updating skill statuses. Please try again.', 'error'),
			);
			return;
		}

		if (typeof onEdit === 'function') onEdit();
	};

	const updateSkills = ev => {
		if (!status) return;

		setLoading(true);

		const needsReview = status === 'pending';
		const deletedAt = status === 'inactive' ? Utility.formatAPIDateTime(new Date()) : null;

		const updates = skills.map(skill => ({
			skill_id: skill.id,
			needs_review: needsReview,
			deleted_at: deletedAt,
		}));

		SkillsAPI.updateBulkSkills(updates, onSkillsUpdate);
	};

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Skill Status Update
			</Typography>

			<Typography variant="body2" style={{ marginBottom: 32 }}>
				{`Updating ${skills.length} records`}
			</Typography>

			<LabeledField label="Status">
				<Dropdown
					variant="outlined"
					name="status"
					value={status}
					data={[
						{ id: 'pending', label: 'Pending' },
						{ id: 'active', label: 'Active' },
						{ id: 'inactive', label: 'Inactive' },
					]}
					onChange={ev => setStatus(ev.target.value)}
					fullWidth
				/>
			</LabeledField>

			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="primary"
				onClick={updateSkills}
				style={{ minWidth: 185 }}
			>
				Update Skill Status
			</LoadingButton>
		</div>
	);
};

SovrenSkillBulkStatusModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	skills: PropTypes.arrayOf(PropTypes.object).isRequired,
	onEdit: PropTypes.func,
};

SovrenSkillBulkStatusModal.defaultProps = {
	onEdit: () => {},
};

export default SovrenSkillBulkStatusModal;

import React, { useState, useEffect, useMemo } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	Typography,
	TextField,
	Grid,
	RadioGroup,
	Radio,
	FormControlLabel,
	CircularProgress,
	ButtonBase,
	Chip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import LabeledField from '../../../form/LabeledField';
import Dropdown from '../../../form/dropdowns/Dropdown';
import LeadershipAllocationDropdown from '../../../form/dropdowns/LeadershipAllocationDropdown';
import CategoryDropdown from '../../../form/dropdowns/CategoryDropdown';
import IndustryExperienceDropdown from '../../../form/dropdowns/IndustryExperienceDropdown';
import SpecialtyDropdown from '../../../form/dropdowns/SpecialtyDropdown';
import StatesDropdown from '../../../form/dropdowns/StatesDropdown';
import YearsDropdown from '../../../form/dropdowns/YearsDropdown';
import WorkLocationDropdown from '../../../form/dropdowns/WorkLocationDropdown';
import LoadingButton from '../../../form/LoadingButton';
import PlusIcon from '../../../icons/PlusIcon';
import UploadIcon from '../../../icons/UploadIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import * as Utility from '../../../../scripts/utility';
import SingleSpecialtyCategoryDropdown from '../../../form/dropdowns/SingleCategorySpecialtyDropdown';
import MultiInputIndustryExperienceDropdown from '../../../form/dropdowns/MultiInputIndustryExperienceDropdown';
import IndustryPreferenceDropdown from '../../../form/dropdowns/IndustryPreferenceDropdown';

const JobDescriptionForm = () => {
	const pageNum = 1;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, progress } = useSelector(state => state.assessment, shallowEqual);
	const [jobDescriptions, setJobDescriptions] = useState([]);
	const [uploadOrPasteJobOrder, setUploadOrPasteJobOrder] = useState('1');

	const hasLocalSelection = useMemo(() => {
		const preferenceIds = listing?.work_location_preferences?.map(p => (p.id ? p.id : p));
		const hasLocal = preferenceIds?.some(pid => [2, 3, 4, 5].includes(pid));
		return hasLocal;
	}, [listing]);

	const setListing = ev => {
		store.dispatch(AssessmentActions.updateListing(ev.target.name, ev.target.value));
		// Reset leadership years & title if leadership role is none
		if (ev.target.name === 'recent_leadership_role_id' && (ev.target.value === 1 || ev.target.value === null)) {
			store.dispatch(AssessmentActions.updateListing('total_leadership_years', 0));
			store.dispatch(AssessmentActions.updateListing('leadership_title', ''));
		}
		if (ev.target.name === 'descriptionFile' && ev.target.value)
			store.dispatch(AssessmentActions.updateListing('description', ''));
	};

	const setListingLocations = (index, name, value) => {
		const updatedOfficeLocations = [...listing.office_locations];
		updatedOfficeLocations[index] = { ...(listing.office_locations || [])[index], [name]: value };
		store.dispatch(AssessmentActions.updateListing('office_locations', updatedOfficeLocations));
	};

	const addOfficeLocation = () => {
		const updatedOfficeLocations = [...(listing.office_locations || []), { city: null, state_id: null }];
		store.dispatch(AssessmentActions.updateListing('office_locations', updatedOfficeLocations));
	};

	const onJobOrderUpload = (response, err) => {
		if (!response) {
			store.dispatch(AssessmentActions.setIsSaving(false));
			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'file')) {
				store.dispatch(
					SiteActions.showAlert(
						`An error ocurred saving the description.
						${Utility.getFromObj(err, 'response', 'data', 'errors', 'file', 0)}`,
						'error',
					),
				);
			} else {
				store.dispatch(SiteActions.showAlert('An error ocurred saving the description. Please try again.', 'error'));
			}
			return;
		}
		const moreSkills = [
			...(response?.data?.more_skills || []),
			...(response?.data?.view_more_1 || []),
			...(response?.data?.view_more_2 || []),
			...(response?.data?.view_more_3 || []),
		];
		// Store in local storage in case we refresh the page
		localStorage.setItem(
			'assessment-skills',
			JSON.stringify({
				priority_skills: response.data.priority_skills,
				more_skills: moreSkills,
				expires: Date.now() + 3600000,
			}),
		);
		if (Utility.getFromObj(response, 'data', 'priority_skills')) {
			store.dispatch(AssessmentActions.setMatchedSkills(response.data.priority_skills));
		}
		if (Utility.getFromObj(response, 'data', 'more_skills')) {
			store.dispatch(AssessmentActions.setSuggestedSkills(moreSkills));
		}
		store.dispatch(AssessmentActions.addCompletedPage(pageNum));
		store.dispatch(AssessmentActions.setPage(pageNum + 1));
	};

	const nextStep = ev => {
		if (!progress.canProceed) {
			store.dispatch(SiteActions.showAlert('Please fill out all required fields.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		const forgedListing = ListingsAPI.getForgedListing(listing);
		ListingsAPI.updateListing(listingID, forgedListing, response => {
			if (!response) {
				store.dispatch(AssessmentActions.setIsSaving(false));
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			if (listing.descriptionFile) {
				ListingsAPI.uploadListingJobOrder(listingID, listing.descriptionFile, onJobOrderUpload);
			} else if (listing.description) {
				ListingsAPI.uploadListingTextJobOrder(listingID, listing.description, onJobOrderUpload);
			} else onJobOrderUpload(true);
		});
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			const forgedListing = {
				...response.data.data,
				office_locations: response.data.data.office_locations?.length
					? response.data.data.office_locations
					: [{ city: null, state_id: null }],
			};
			store.dispatch(AssessmentActions.setListing(forgedListing));
			setIsLoading(false);
		});
		ListingsAPI.getListingDescriptions(
			listingID,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) {
					store.dispatch(SiteActions.showAlert('Could not load the listing. Refresh the page to try again.', 'error'));
					return;
				}
				setJobDescriptions(response.data.data);
			},
		);
	}, []);

	// Check if can proceed
	useEffect(() => {
		const canProceed = Boolean(
			listing?.leadership_allocation_type_id &&
				listing?.professional_category_id &&
				(listing?.specialty_id || [3, 4].includes(listing.leadership_allocation_type_id)) &&
				listing?.professional_experience_years &&
				listing?.recent_leadership_role_id &&
				(listing?.total_leadership_years || listing?.recent_leadership_role_id === 1) &&
				listing?.work_location_preferences?.length &&
				(listing?.offer_relocation || listing?.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)) &&
				(listing?.remote_states?.length ||
					!listing?.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)) &&
				(listing?.office_locations?.every(l => l.city && l.state_id) ||
					(listing.work_location_preferences || []).map(p => (p.id ? p.id : p)).every(p => p === 1)) &&
				(listing.descriptionFile || listing.description),
		);
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [listing]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				{listing.position_title}
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				How much time do you anticipate this role will spend on leadership responsibilities?
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField label="Leadership Time Allocation">
					<LeadershipAllocationDropdown
						variant="outlined"
						name="leadership_allocation_type_id"
						value={Utility.getFromObj(listing, 'leadership_allocation_type_id')}
						placeholder="Select the most accurate allocation"
						onChange={setListing}
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<hr />
			<VerticalSpacer height={4.5} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Professional Experience Preferences
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Please select the desired area of expertise and experience for this role.
			</Typography>
			<VerticalSpacer height={3} />

			<Grid item xs={9}>
				<SingleSpecialtyCategoryDropdown onChange={setListing} model={listing} />
			</Grid>
			<Fence width={25}>
				{/* <LabeledField label="Professional Category">
					<CategoryDropdown
						variant="outlined"
						name="professional_category_id"
						value={Utility.getFromObj(listing, 'professional_category_id')}
						onChange={setListing}
						fullWidth
						// error={!Utility.getFromObj(listing, 'professional_category_id')}
					/>
				</LabeledField>
				<LabeledField label="Specialty">
					<SpecialtyDropdown
						variant="outlined"
						name="specialty_id"
						value={
							listing.leadership_allocation_type_id === 3 || listing.leadership_allocation_type_id === 4
								? null
								: listing.specialty_id
						}
						placeholder="Choose from our list"
						category={listing.professional_category_id}
						onChange={setListing}
						disabled={
							!listing.professional_category_id ||
							listing.leadership_allocation_type_id === 3 ||
							listing.leadership_allocation_type_id === 4
						}
						fullWidth
					/>
				</LabeledField> */}

				<LabeledField label="Minimum Required Years of Professional Experience">
					<YearsDropdown
						variant="outlined"
						name="professional_experience_years"
						value={Utility.getFromObj(listing, 'professional_experience_years')}
						placeholder="Choose from our list"
						onChange={setListing}
						fullWidth
						// error={!Utility.getFromObj(listing, 'professional_experience_years')}
					/>
				</LabeledField>
			</Fence>
			<Fence width={100}>
				<IndustryPreferenceDropdown onChange={setListing} intakeModal model={listing} />
			</Fence>
			<VerticalSpacer height={3} />
			<hr />
			<VerticalSpacer height={4.5} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Leadership Experience
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField label="Minimun Required Leadership Experience">
					<Dropdown
						variant="outlined"
						name="recent_leadership_role_id"
						value={Utility.getFromObj(listing, 'recent_leadership_role_id')}
						placeholder="Choose from our list"
						data={data.leadershipExperienceRoles}
						onChange={setListing}
						fullWidth
					/>
				</LabeledField>
				<LabeledField label="Minimum Years of Leadership Experience">
					<YearsDropdown
						variant="outlined"
						name="total_leadership_years"
						value={Utility.getFromObj(listing, 'total_leadership_years')}
						placeholder="Choose from our list"
						onChange={setListing}
						disabled={listing.recent_leadership_role_id === 1 || listing.recent_leadership_role_id === null}
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<hr />
			<VerticalSpacer height={4.5} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Work Location Preferences
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Select the work location options that are applicable to this role.
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item xs={5}>
						<LabeledField label="Work Locations">
							<WorkLocationDropdown
								variant="outlined"
								name="work_location_preferences"
								value={listing?.work_location_preferences?.map(item => (item.id ? item.id : item))}
								placeholder={listing?.work_location_preferences?.length > 0 ? '' : 'Select all that apply'}
								onChange={setListing}
								fullWidth
								multiple
								renderOption={(option, { selected }) => {
									return (
										<div>
											<Typography bold className="text-bold grey-300">
												{option.label}
											</Typography>
											<Typography bold className="grey-300">
												{option.description}
											</Typography>
										</div>
									);
								}}
							/>
						</LabeledField>
						<LabeledField label="Offer Candidate Relocation">
							<Dropdown
								variant="outlined"
								name="offer_relocation"
								data={data.offerRelocation}
								value={
									listing.work_location_preferences?.map(p => (p.id ? p.id : p)).every(v => v === 1)
										? null
										: listing.offer_relocation
								}
								placeholder="Choose from our list"
								onChange={setListing}
								disabled={
									!listing.work_location_preferences?.length ||
									listing.work_location_preferences?.map(p => (p.id ? p.id : p)).every(v => v === 1)
								}
								fullWidth
							/>
						</LabeledField>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={5}>
						<LabeledField label="States Approved For Remote Workers">
							<StatesDropdown
								variant="outlined"
								name="remote_states"
								placeholder={listing?.remote_states?.length > 0 ? '' : 'Select all that apply'}
								value={listing?.remote_states?.map(item => (item.state_id ? item.state_id : item))}
								onChange={setListing}
								// disabled unless remote is selected in work_location_preferences
								disabled={!listing.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)}
								fullWidth
								multiple
								useID
								autoComplete="off"
							/>
						</LabeledField>
						<LabeledField label="Eligible Office Sites">
							{(listing.office_locations?.length ? listing.office_locations : [{ city: null, state_id: null }]).map(
								(location, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<Grid container spacing={2} key={`listing-location-fields-${index}`}>
										<Grid item xs={8}>
											<TextField
												variant="outlined"
												name="city"
												placeholder="City"
												value={Utility.getFromObj(listing, 'office_locations')[index]?.city || ''}
												onChange={e => setListingLocations(index, e.target.name, e.target.value)}
												disabled={!hasLocalSelection}
												fullWidth
											/>
										</Grid>
										<Grid item xs={4}>
											<StatesDropdown
												variant="outlined"
												name="state_id"
												placeholder="State"
												value={Utility.getFromObj(listing, 'office_locations')[index]?.state_id}
												onChange={e => setListingLocations(index, e.target.name, e.target.value)}
												// disabled unless any local preference is selected
												disabled={!hasLocalSelection}
												fullWidth
												useAbbreviation
												useID
											/>
										</Grid>
									</Grid>
								),
							)}
							<VerticalSpacer height={0.5} />
							<ButtonBase
								className="button-show-more"
								onClick={() => addOfficeLocation()}
								disabled={!hasLocalSelection}
							>
								<PlusIcon />
								ADD LOCATION
							</ButtonBase>
						</LabeledField>
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			<hr />
			<VerticalSpacer height={4.5} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Job Description - {listing.position_title}
			</Typography>
			<Typography variant="subtitle1" component="p" gutterBottom>
				Please upload the job description file or select the copy/paste option to enter the job description text.
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={55}>
				<RadioGroup
					row
					aria-label="Upload or paste text?"
					name="upload_or_paste_job_order"
					onChange={e => setUploadOrPasteJobOrder(e.currentTarget.value)}
					value={uploadOrPasteJobOrder}
				>
					<FormControlLabel value="1" control={<Radio color="primary" />} label="Upload Job Description" />
					<FormControlLabel value="2" control={<Radio color="primary" />} label="Copy &amp; Paste Job Description" />
				</RadioGroup>
				<VerticalSpacer height={1} />
				{uploadOrPasteJobOrder === '1' ? (
					<>
						<LabeledField label="Upload Job Description">
							<DropzoneAreaBase
								name="descriptionFile"
								dropzoneText="UPLOAD FILE"
								Icon={UploadIcon}
								dropzoneClass="file-upload-dropzone"
								onAdd={files => store.dispatch(AssessmentActions.updateListing('descriptionFile', files[0].file))}
								filesLimit={1}
								showAlerts={false}
								showPreviewsInDropzone={false}
							/>
							{listing.descriptionFile && (
								<Chip
									label={listing.descriptionFile.name}
									onDelete={() => setListing({ target: { name: 'descriptionFile', value: null } })}
								/>
							)}
						</LabeledField>
					</>
				) : (
					<LabeledField label="Paste Job Description">
						<TextField
							variant="outlined"
							name="description"
							value={Utility.getFromObj(listing, 'description')}
							onChange={setListing}
							rows={10}
							multiline
							fullWidth
						/>
					</LabeledField>
				)}
				{jobDescriptions.map(description => (
					<Typography>
						Job Description <i>{description.file_name}</i> was uploaded{' '}
						<i>{Utility.formatDate(description.updated_at, false, 'MMMM D, YYYY')}</i>
					</Typography>
				))}
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

JobDescriptionForm.propTypes = {};

JobDescriptionForm.defaultProps = {};

export default JobDescriptionForm;

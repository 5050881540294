import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Button, Typography, RadioGroup, Radio, FormControlLabel, Chip, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import Fence from '../../../components/Fence';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import ResumeIcon from '../../../components/icons/ResumeIcon';
import UploadIcon from '../../../components/icons/UploadIcon';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';

const EditResumeModal = ({ candidate, setCandidate, setResumes }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [uploadOrPasteResume, setUploadOrPasteResume] = useState('1');
	const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

	const updateCandidate = ev => {
		const newValue = {
			[ev.target.name]: ev.target.value,
		};
		setUpdatedCandidate({
			...updatedCandidate,
			...newValue,
		});
	};

	const onResumeUpload = (response, err) => {
		setIsSaving(false);
		if (!response) {
			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'file')) {
				store.dispatch(
					SiteActions.showAlert(
						`An error ocurred saving the resume. ${Utility.getFromObj(err, 'response', 'data', 'errors', 'file', 0)}`,
						'error',
					),
				);
			} else {
				store.dispatch(SiteActions.showAlert('An error ocurred saving the resume. Please try again.', 'error'));
			}
		}

		CandidatesAPI.getCandidateResumes(
			updatedCandidate.id,
			{
				per_page: 1,
				orderBy: 'sovren_parsed_docs.created_at',
				direction: 'desc',
			},
			res => {
				setResumes(res.data.data);
				setCandidate(updatedCandidate);
				dispatch(SiteActions.hideModal());
			},
		);
	};

	const saveCandidate = () => {
		setIsSaving(true);
		if (updatedCandidate.resumeFile) {
			CandidatesAPI.uploadCandidateResume(updatedCandidate.id, updatedCandidate.resumeFile, onResumeUpload);
		} else if (updatedCandidate.resume) {
			CandidatesAPI.uploadCandidateTextResume(updatedCandidate.id, updatedCandidate.resume, onResumeUpload);
		}
	};

	return (
		<>
			<Panel>
				<PanelHeader className="spread">
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ResumeIcon size={26} className="teal-100" />
						<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
							Resume
						</Typography>
					</div>
				</PanelHeader>
				<PanelContent>
					<Fence width={55}>
						<RadioGroup
							row
							aria-label="Upload or paste text?"
							name="upload_or_paste_resume"
							onChange={e => setUploadOrPasteResume(e.currentTarget.value)}
							value={uploadOrPasteResume}
						>
							<FormControlLabel value="1" control={<Radio color="primary" />} label="Upload Resume" />
							<FormControlLabel value="2" control={<Radio color="primary" />} label="Copy &amp; Paste Resume" />
						</RadioGroup>
						<VerticalSpacer height={1} />
						{uploadOrPasteResume === '1' ? (
							<>
								<LabeledField label="Upload Resume">
									<DropzoneAreaBase
										name="resumeFile"
										dropzoneText="UPLOAD FILE"
										Icon={UploadIcon}
										dropzoneClass="file-upload-dropzone"
										onAdd={files =>
											setUpdatedCandidate(c => ({
												...c,
												resumeFile: files[0].file,
												resume: '',
											}))
										}
										filesLimit={1}
										showAlerts={false}
										showPreviewsInDropzone={false}
									/>
									{updatedCandidate.resumeFile && (
										<Chip
											label={updatedCandidate.resumeFile.name}
											onDelete={() =>
												setUpdatedCandidate({
													target: {
														name: 'resumeFile',
														value: null,
													},
												})
											}
										/>
									)}
								</LabeledField>
							</>
						) : (
							<LabeledField label="Resume / CV">
								<TextField
									variant="outlined"
									name="resume"
									value={Utility.getFromObj(updatedCandidate, 'resume')}
									onChange={updateCandidate}
									rows={10}
									multiline
									fullWidth
								/>
							</LabeledField>
						)}
					</Fence>
					<VerticalSpacer height={3} />
					{alert.show ? (
						<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
							{alert.message}
						</Alert>
					) : null}
					<div className="text-right">
						<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
							Cancel
						</Button>
						<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveCandidate}>
							Save
						</LoadingButton>
					</div>
				</PanelContent>
			</Panel>
		</>
	);
};

EditResumeModal.propTypes = {
	candidate: PropTypes.shape({}),
	setCandidate: PropTypes.func,
	setResumes: PropTypes.func,
};

EditResumeModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
	setResumes: () => null,
};

export default EditResumeModal;

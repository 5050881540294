import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import EditCandidateBlockModal from './EditCandidateBlockModal';
import Icon from '../../../components/icons/SectionIcons/DocumentIcon';

const EditModal = ({ candidate, setCandidate }) => {
	return (
		<EditCandidateBlockModal
			candidate={candidate}
			setCandidate={setCandidate}
			title="Summary"
			icon={Icon}
			renderContents={({ updateCandidate, updatedCandidate }) => (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<LabeledField label="Candidate Summary" removeMargin>
								<TextField
									placeholder="Enter candidate summary"
									variant="outlined"
									name="candidate_summary"
									value={updatedCandidate.candidate_summary || ''}
									onChange={updateCandidate}
									fullWidth
									multiline
								/>
							</LabeledField>
						</Grid>
					</Grid>
					<br />
				</>
			)}
		/>
	);
};

EditModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditModal;

import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ removeMargin, className, children, ...props }) => {
	return (
		<div className={`section ${removeMargin ? 'no-margins' : ''} ${className}`}>{children}</div>
	);
};

Section.propTypes = {
	removeMargin: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node
}

Section.defaultProps = {
	removeMargin: false,
	className: "",
	children: null,
}

export default Section;

import Axios from 'axios';
import API from './api';
import * as Utility from './utility';

/**
 * Gets a list of users
 * @param {Object} parameters - Filters passed as query parameters
 * @param {*} callback - The function called once AJAX call is finished
 * @param {*} store - The Redux data store
 */
export const getUsers = (parameters, callback, store, duplicateArrayParams) => {
	const query = Utility.propsToURL(parameters, duplicateArrayParams);

	API.get(`/users${query}`)
		.then(callback)
		.catch(err => {
			callback(false);
		});
};

/**
 * Gets the data for a specific user
 * @param {Number} id - The ID of the user
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const getUser = (id, callback, store) => {
	API.get(`/users/${id}`)
		.then(callback)
		.catch(err => {
			callback(false);
		});
};

/**
 * Updates a user record
 * @param {Number} id - The ID of the user
 * @param {Object} parameters - The parameters for the user
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const updateUser = (id, parameters, callback, store) => {
	const user = parameters;

	if (user.birth_date) user.birth_date = Utility.formatAPIDate(user.birth_date);

	if (typeof user.password !== 'undefined' && !user.password) user.password = undefined;

	API.patch(`/users/${id}`, user)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Updates a user record
 * @param {Number} id - The ID of the user
 * @param {Object} parameters - The parameters for the user
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const updateUserAccount = (id, parameters, callback, store) => {
	const user = parameters;

	if (user.birth_date) user.birth_date = Utility.formatAPIDate(user.birth_date);

	if (typeof user.password !== 'undefined' && !user.password) user.password = undefined;

	API.patch(`/user-account`, {
		first_name: user?.first_name,
		last_name: user?.last_name,
		phone: user?.phone,
		birth_date: user?.birth_date,
	})
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Creates a new user record
 * @param {Object} parameters - The parameters for the user
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const addUser = (parameters, callback, store) => {
	const user = parameters;

	API.post(`/users`, user)
		.then(callback)
		.catch(err => {
			callback(false, err);
		});
};

/**
 * Deletes a user record
 * @param {Number} id - The ID of the user
 * @param {*} callback - The function called once AJAX call is finished
 */
export const deleteUser = (id, callback) => {
	API.delete(`/users/${id}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Invite a new user
 * @param {Object} parameters - The parameters for the user
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const inviteUser = (parameters, callback, store) => {
	const user = parameters;

	API.post(`/users/invite-admin`, user)
		.then(callback)
		.catch(err => {
			callback(false, err);
		});
};

/**
 * Invite a new user
 * @param {Object} parameters - The parameters for the user
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const resendInvite = (id, callback, store) => {
	API.post(`/users/${id}/resend-invite`)
		.then(callback)
		.catch(err => {
			callback(false, err);
		});
};

export const signup = (parameters, callback, store) => {
	Axios.post(`${process.env.REACT_APP_API_ADR}/signup`, parameters)
		.then(callback)
		.catch(err => {
			callback(false, err);
		});
};

export const contactSignup = (parameters, callback, store) => {
	Axios.post(`${process.env.REACT_APP_API_ADR}/contact-signup`, parameters)
		.then(callback)
		.catch(err => {
			callback(false, err);
		});
};

/**
 * Authenticate a candidate/contact
 * @param {*} url - Authentication URL
 * @param {*} callback - The callback function
 */
export const jwtLogin = (url, callback) => {
	console.log({ url });

	Axios.get(url)
		.then(callback)
		.catch(err => callback(false, err));
};

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import useVerifyAuthorization from '../../hooks/useVerifyAuthorization';
import Page from '../page/Page';
import PageLoading from '../page/PageLoading';

const Internal = ({ children }) => {
	const location = useLocation();
	const { loading, isAuthorized } = useVerifyAuthorization({ role: 'admin', redirectTo: '/login', from: location });
	if (loading)
		return (
			<Page topbar="center" sidebar="none" skipAuth skipData>
				<PageLoading />
			</Page>
		);
	return !isAuthorized ? <></> : children;
};

Internal.propTypes = {
	children: PropTypes.node,
};

Internal.defaultProps = {
	children: null,
};

export default Internal;

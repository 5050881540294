import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import Dropdown from './Dropdown';

/** Display a dropdown with a list of states */
const CandidateStatusDropdown = ({ useID, useAbbreviation, value, onChange, ...props }) => {
	const data = useSelector(state => state.site.data, shallowEqual);

	return <Dropdown data={data.candidateStatuses} value={value} multiple={false} onChange={onChange} {...props} />;
};

CandidateStatusDropdown.propTypes = {
	// If the id field should be used instead of the abbreviation
	useID: PropTypes.bool,
};

CandidateStatusDropdown.defaultProps = {
	useID: false,
};

export default CandidateStatusDropdown;

import React from 'react';
import { Typography } from '@material-ui/core';
import Page from './page/Page';
import Fence from './Fence';
import ProfileAccessErrorIcon from './icons/ProfileAccessError';
import VerticalSpacer from './VerticalSpacer';

const ExpiredLinkMessage = () => {
	return (
		<Page sidebar="none" key="expired-link-message" centered>
			<Fence
				width={50}
				style={{
					background: '#fff',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingRight: 20,
					paddingLeft: 20,
					maxHeight: 600,
				}}
			>
				<VerticalSpacer height={50} />
				<Typography variant="h2">For your security, we&apos;ve sent you a new link.</Typography>
				<div style={{ height: 30 }} />
				<ProfileAccessErrorIcon style={{ width: 150 }} />
				<div style={{ height: 30 }} />

				<Typography className="grey-250" style={{ maxWidth: 500, textAlign: 'center' }}>
					Protecting your data is a top priority. This link has expired. To keep your NauMatch account secure,
					we&apos;ve emailed you a new link.
				</Typography>
				<VerticalSpacer height={50} />
			</Fence>
		</Page>
	);
};

export default ExpiredLinkMessage;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function BasicPlusIcon({ style, color = 'currentColor', size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size + 1}
			viewBox="0 0 24 24"
			style={style}
			className={className}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			stroke={color}
		>
			<line x1="12" y1="5" x2="12" y2="19" />
			<line x1="5" y1="12" x2="19" y2="12" />
		</svg>
	);
}

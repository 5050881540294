export const InitialState = {
	authentication: {
		accessToken: '',
		expires: null,
		refreshToken: '',
		tokenType: 'Bearer',
	},
	user: {},
	data: {
		businessSizeExperiences: [],
		candidateStatusTypes: [],
		candidateStatuses: [],
		categories: [],
		compensationTypes: [],
		currentEmploymentTypes: [],
		desiredSalaries: [],
		domains: [],
		educationTypes: [],
		employmentPreferences: [],
		genderIdentities: [],
		industries: [],
		interestLevels: [],
		jobSearchStages: [],
		jobStatuses: [],
		languages: [],
		leadershipAllocationTypes: [],
		leadershipExperienceRequirements: [],
		leadershipExperienceRoles: [],
		leadershipPreferences: [],
		linkTypes: [],
		listingPriorities: [],
		matchFitRankingReasons: [],
		matchFitRankingStatuses: [],
		offerRelocation: [],
		owners: [],
		preferredLeadershipAllocations: [],
		pronounTypes: [],
		qaQuestions: [],
		races: [],
		roleLevelPreferences: [],
		roleTimeline: [],
		salaryExpectationTypes: [],
		sharedMatchAccessLevels: [],
		skills: [],
		skillExperienceLevels: [],
		skillStoryTypes: [],
		sources: [],
		specialties: [],
		states: [],
		studyTypes: [],
		timeZones: [],
		travelWillingnessTypes: [],
		userRoles: [],
		userStatuses: [],
		valuePropositionTypes: [],
		workLocationPreferences: [],
		workVisaTypes: [],
		willingToRelocate: {
			1: 'Yes',
			2: 'No',
		},
	},
	reports: {},
	urlHistory: [],
	alert: {
		show: false,
		message: '',
		color: 'info',
	},
	modal: {
		show: false,
		content: null,
		className: '',
		disableCloseButton: false,
		disableBackdropClick: false,
		disableEscapeKey: false,
		isDraggable: false,
	},
	apiDateFormat: 'YYYY-MM-DD',
	apiDateTimeFormat: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
	displayDateFormat: 'M/DD/YYYY',
	displayTimeFormat: 'hh:mm A',
	sidebarWidth: parseInt(localStorage.getItem('sidebarWidth'), 10) || 80,
	// TODO: Remove this after the draggable tip is no longer needed 2/13/2024
	hasSeenTip: localStorage.getItem('draggableTip') || false,
};

// Action Creators
export const ActionCreators = {
	updateAuthentication: value => ({ type: 'UPDATE_AUTH', value }),
	updateUser: value => ({ type: 'UPDATE_USER', value }),
	updateSiteData: value => ({ type: 'UPDATE_SITE_DATA', value }),
	updateCategories: value => ({ type: 'UPDATE_CATEGORIES', value }),
	updateSpecialties: value => ({ type: 'UPDATE_SPECIALTIES', value }),
	updateSkills: value => ({ type: 'UPDATE_SKILLS', value }),
	setReport: (report, filters) => ({ type: 'SET_REPORT', report, filters }),
	resetReport: report => ({ type: 'RESET_REPORT', report }),
	addHistory: url => ({ type: 'ADD_HISTORY', url }),
	showAlert: (message, color) => ({ type: 'SHOW_ALERT', value: { message, color } }),
	hideAlert: () => ({ type: 'HIDE_ALERT' }),
	showModal: (
		content,
		{ className, disableCloseButton, disableBackdropClick, disableEscapeKey, isDraggable = false } = {},
	) => ({
		type: 'SHOW_MODAL',
		value: { content, className, disableCloseButton, disableBackdropClick, disableEscapeKey, isDraggable },
	}),
	hideModal: () => ({ type: 'HIDE_MODAL' }),
	setSidebarWidth: width => {
		localStorage.setItem('sidebarWidth', width);
		return {
			type: 'SET_SIDEBAR_WIDTH',
			value: width,
		};
	},
	setSeenTip: () => {
		localStorage.setItem('draggableTip', true);
		return {
			type: 'HAS_SEEN_TIP',
		};
	},
};

// Reducer
export function reducer(state = InitialState, action) {
	switch (action.type) {
		case 'UPDATE_AUTH':
			return { ...state, authentication: action.value };
		case 'UPDATE_USER':
			return { ...state, user: action.value };
		case 'UPDATE_SITE_DATA':
			return { ...state, data: { ...state.data, ...action.value } };
		case 'UPDATE_CATEGORIES':
			return { ...state, data: { ...state.data, categories: [...action.value] } };
		case 'UPDATE_SPECIALTIES':
			return { ...state, data: { ...state.data, specialties: [...action.value] } };
		case 'UPDATE_SKILLS':
			return { ...state, data: { ...state.data, skills: [...action.value] } };
		case 'SET_REPORT':
			return { ...state, reports: { ...state.reports, [action.report]: action.filters } };
		case 'RESET_REPORT':
			return { ...state, reports: { ...state.reports, [action.report]: null } };
		case 'ADD_HISTORY':
			return { ...state, urlHistory: state.urlHistory.concat(action.url) };
		case 'SHOW_ALERT':
			return { ...state, alert: { ...state.alert, show: true, ...action.value } };
		case 'HIDE_ALERT':
			return { ...state, alert: { ...state.alert, show: false } };
		case 'SHOW_MODAL':
			return { ...state, modal: { ...state.modal, show: true, ...action.value } };
		case 'HIDE_MODAL':
			return { ...state, modal: { ...state.modal, show: false } };
		case 'SET_SIDEBAR_WIDTH':
			return {
				...state,
				sidebarWidth: action.value,
			};
		case 'HAS_SEEN_TIP':
			return {
				...state,
				hasSeenTip: true,
			};
		default:
			return state;
	}
}

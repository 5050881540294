import configureStore from './configureStore';

const store = configureStore();

export default store;

// import { createBrowserHistory } from 'history';
// import * as Site from './Site';
// import * as Assessment from './Assessment';

// // The top-level state object
// const ApplicationState = {
// 	site: Site.InitialState || undefined,
// 	assessment: Assessment.InitialState || undefined,
// };

// export default ApplicationState;

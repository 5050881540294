import React, { useState, useEffect } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../scripts/candidates';
import * as Utility from '../../../../../scripts/utility';
import ProfessionalForm from './Professional';
import LeadershipForm from './Leadership';
import EducationForm from './Education';
import USWorkAuthorizationForm from './USWorkAuthorization';
import ExperienceIcon from '../../../../icons/ExperienceIcon';
import LoadingButton from '../../../../form/LoadingButton';

const ExperienceForm = () => {
	const pageNum = 2.5;
	const [isLoading, setIsLoading] = useState(false);
	const [experienceStep, setExperienceStep] = useState(() => {
		const savedStep = localStorage.getItem('ES');
		return savedStep ? JSON.parse(savedStep) : 1;
	});
	const store = useStore();
	const { id: candidateID } = useParams();

	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);
	const [candidateBeforeUpdate, setCandidateBeforeUpdate] = useState({});

	const setCandidate = ev => {
		const { name, value } = ev.target;

		// Reset leadership years, title, & time if leadership role is none
		if (name === 'recent_leadership_role_id' && (value === 1 || value === null)) {
			store.dispatch(AssessmentActions.updateCandidate('total_leadership_years', 0));
			store.dispatch(AssessmentActions.updateCandidate('leadership_title', ''));
			store.dispatch(AssessmentActions.updateCandidate('leadership_allocation_type_id', null));
		}
		if (name === 'education_type_id' && value === 6) {
			store.dispatch(AssessmentActions.updateCandidate('field_of_study', ''));
			store.dispatch(AssessmentActions.updateCandidate('study_types', []));
		}
		if (name === 'education_type_id' && (value === 1 || value === 9 || value === 10)) {
			store.dispatch(AssessmentActions.updateCandidate('study_types', []));
		}
		if (name === 'business_size_experiences' && value.some(item => item === 0)) {
			store.dispatch(AssessmentActions.updateCandidate(name, [1, 2, 3, 4, 5, 6, 7, 8, 9]));
			return;
		}

		store.dispatch(AssessmentActions.updateCandidate(name, value));
	};
	const prevStep = () => {
		if (experienceStep === 1) {
			Utility.setLocalStorageItems({ ES: 1, PS: 4 });
			store.dispatch(AssessmentActions.setPage(pageNum - 0.5));
			return;
		}
		Utility.setLocalStorageItems({ ES: experienceStep - 1 });
		setExperienceStep(s => s - 1);
	};

	const nextStep = () => {
		if (!progress.canProceed) {
			store.dispatch(SiteActions.showAlert('Please fill out all required fields.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));

		const forgedOriginalCandidate = CandidatesAPI.getForgedCandidate(candidateBeforeUpdate);
		const forgedCurrentCandidate = CandidatesAPI.getForgedCandidate(candidate);

		const differencesToSubmit = Utility.getEntityDifferences(forgedOriginalCandidate, forgedCurrentCandidate);

		CandidatesAPI.updateCandidate(candidateID, differencesToSubmit, response => {
			if (!response) {
				store.dispatch(AssessmentActions.setIsSaving(false));
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			store.dispatch(AssessmentActions.setIsSaving(false));
			if (experienceStep === 4) {
				Utility.setLocalStorageItems({ ES: 4 });
				store.dispatch(AssessmentActions.addCompletedPage(pageNum));
				store.dispatch(AssessmentActions.setPage(pageNum + 0.5));
			} else {
				Utility.setLocalStorageItems({ ES: experienceStep + 1 });
				setExperienceStep(s => s + 1);
			}
		});
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setCandidate(response.data.data));
			setCandidateBeforeUpdate(response.data.data);
			setIsLoading(false);
		});
	}, []);

	// Check if can proceed
	useEffect(() => {
		let canProceed;
		switch (experienceStep) {
			// Professional
			case 1:
				canProceed = Boolean(
					candidate?.professional_experience_years &&
						candidate?.current_title &&
						candidate?.current_employer &&
						candidate?.current_employment_type_id &&
						candidate?.industries?.length &&
						candidate?.business_size_experiences?.length,
				);
				break;
			// Leadership
			case 2:
				canProceed = Boolean(
					candidate?.recent_leadership_role_id &&
						(candidate?.total_leadership_years || candidate?.recent_leadership_role_id === 1) &&
						(candidate?.leadership_title || candidate?.recent_leadership_role_id === 1) &&
						(candidate?.leadership_allocation_type_id || candidate?.recent_leadership_role_id === 1),
				);
				break;
			// Education
			case 3:
				// don't require field of study and study types for highschool education type id
				canProceed = Boolean(
					candidate?.education_type_id === 6 ||
						((candidate?.education_type_id === 1 ||
							candidate?.education_type_id === 9 ||
							candidate?.education_type_id === 10) &&
							candidate?.field_of_study) ||
						(candidate?.education_type_id && candidate?.field_of_study && candidate?.study_types?.length),
				);
				break;
			// US Work Authorization
			default:
				canProceed = Boolean(candidate?.work_visa_type_id && candidate?.us_work_authorization != null);
		}

		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [candidate, experienceStep]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExperienceIcon size={32} className="teal-100" />
				<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
					Experience <span className="color-muted">{experienceStep + 1} of 5</span>
				</Typography>
			</div>
			<VerticalSpacer height={3} />
			{experienceStep === 1 && <ProfessionalForm candidate={candidate} setCandidate={setCandidate} data={data} />}
			{experienceStep === 2 && <LeadershipForm candidate={candidate} setCandidate={setCandidate} data={data} />}
			{experienceStep === 3 && <EducationForm candidate={candidate} setCandidate={setCandidate} />}
			{experienceStep === 4 && <USWorkAuthorizationForm candidate={candidate} setCandidate={setCandidate} />}
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

export default ExperienceForm;

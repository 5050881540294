import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import LeadershipRolesDropdown from '../../../components/form/dropdowns/LeadershipRolesDropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';
import EditJobBlockModal from './EditJobBlockModal';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import LeadershipAllocationDropdown from '../../../components/form/dropdowns/LeadershipAllocationDropdown';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import SpecialtyDropdown from '../../../components/form/dropdowns/SpecialtyDropdown';
import WorkVisaDropdown from '../../../components/form/dropdowns/WorkVisaDropdown';
import TravelWillingnessDropdown from '../../../components/form/dropdowns/TravelWillingnessDropdown';
import EmploymentPreferencesDropdown from '../../../components/form/dropdowns/EmploymentPreferencesDropdown';
import WorkLocationDropdown from '../../../components/form/dropdowns/WorkLocationDropdown';
import WillingToRelocateDropdown from '../../../components/form/dropdowns/WillingToRelocateDropdown';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import CompensationTypesDropdown from '../../../components/form/dropdowns/CompensationTypesDropdown';
import SalaryDropdown from '../../../components/form/dropdowns/SalaryDropdown';
import ValuePropositionsDropdown from '../../../components/form/dropdowns/ValuePropositionsDropdown';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';

const EditModal = ({ job, setJob }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="Candidate Preparation"
			icon={CaseIcon}
			renderContents={({ updateJob, updatedJob }) => {
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<LabeledField label="Team Details">
									<TextField
										variant="outlined"
										name="team_details"
										value={updatedJob.team_details || ''}
										onChange={updateJob}
										error={!updatedJob.team_details}
										fullWidth
										multiline
										rows={4}
										placeholder="Enter team details"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Submission Process">
									<TextField
										variant="outlined"
										name="submission_process"
										value={updatedJob.submission_process || ''}
										onChange={updateJob}
										error={!updatedJob.submission_process}
										fullWidth
										multiline
										rows={4}
										placeholder="Enter submission process"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Interview Process">
									<TextField
										variant="outlined"
										name="interview_process"
										value={updatedJob.interview_process || ''}
										onChange={updateJob}
										error={!updatedJob.interview_process}
										fullWidth
										multiline
										rows={4}
										placeholder="Enter interview process"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Interview Preparation (Link)">
									<TextField
										variant="outlined"
										name="interview_preparation"
										value={updatedJob.interview_preparation || ''}
										onChange={updateJob}
										error={!updatedJob.interview_preparation}
										fullWidth
										placeholder="Enter interview preparation"
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;

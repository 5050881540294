import React, { useState, useEffect, useCallback } from 'react';
import { useStore } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { Typography, TextField, Grid } from '@material-ui/core';
import useLogin from '../../hooks/useLogin';
import Page from '../../components/page/Page';
import Alert from '../../components/Alert';
import LabeledField from '../../components/form/LabeledField';
import VerticalSpacer from '../../components/VerticalSpacer';
import SubmitButton from '../../components/form/SubmitButton';
import LoginImage from '../../img/culture.png';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as UserAPI from '../../scripts/user';
import '../../css/login.css';
import { palette } from '../../BobcatTheme';
import * as AuthenticationAPI from '../../scripts/authentication';
import CheckmarkCircleIcon from '../../components/icons/CheckmarkCircleIcon';

const ForgotPassword = props => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState('');
	const [error, setError] = useState(null);
	const [sent, setSent] = useState(false);

	// Set the email passed from the login page..
	useEffect(() => {
		const { location } = props;
		const newEmail = new URLSearchParams(location?.search).get('email');
		setEmail(newEmail);
	}, []);

	const submitReset = useCallback(
		e => {
			e.preventDefault();
			if (!loading && !sent) {
				setError(null);
				setLoading(true);

				AuthenticationAPI.forgotPassword(email)
					.then(x => {
						console.log({ results: x });
						setSent(true);
					})
					.catch(err => {
						console.log(err);
						setError('There was an error sending your password reset email. Please try again.');
					})
					.finally(() => {
						setLoading(false);
					});
			}
		},
		[email],
	);

	return (
		<Page className="login-page login-page-external" topbar="center" sidebar="none" skipAuth skipData>
			<Grid container spacing={0}>
				<Grid item xs={5}>
					{sent ? (
						<div
							style={{
								textAlign: 'center',
								background: "#fff",
								padding: 45,
								borderRadius: 6
							}}
						>
							<CheckmarkCircleIcon size={48} className="teal-100" />
							<VerticalSpacer height={1} />

							<Typography variant="body2" style={{ marginTop: 20}}>
								If the provided email address matched an active NauMatch portal account, a password reset email was
								sent. Follow the instructions provided in the email to reset your password.
							</Typography>
							<div style={{height: 50}}/>
							<NavLink to="/login" className="font-lato-bold">
								Return to Login page ›
							</NavLink>
						</div>
					) : (
						<form onSubmit={e => submitReset(e)}>
							<Typography variant="h2" className="font-lato-bold">
								Reset Password
							</Typography>
							<VerticalSpacer height={1} />
							<Typography variant="body1">
								Forgot your password? Enter your email address below, and we&apos;ll send you a link to reset it.
							</Typography>
							<VerticalSpacer height={2} />
							<Alert />
							<LabeledField label="Email">
								<TextField
									name={email}
									fullWidth
									variant="outlined"
									placeholder="Your email address"
									value={email}
									onChange={ev => setEmail(ev.target.value)}
								/>
							</LabeledField>

							<VerticalSpacer height={1} />
							<SubmitButton loading={loading} onClick={e => submitReset(e)} disabled={!email || loading}>
								{loading ? 'Sending...' : 'Send Password Reset Email'}
							</SubmitButton>
							{error && (
								<>
									<VerticalSpacer height={1} />

									<Typography variant="body1">{error}</Typography>
								</>
							)}
						</form>
					)}
				</Grid>
				<Grid item xs={7}>
					<img src={LoginImage} alt="" style={{ marginLeft: '3rem', maxWidth: '100%' }} />
				</Grid>
			</Grid>
		</Page>
	);
};

export default ForgotPassword;

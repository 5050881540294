/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function SmoothStarIcon({ color = "#687180" }) {
    return (
        <svg width="24" height="24" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99916 11.8452L10.6732 13.2505C11.1938 13.5238 11.8018 13.0818 11.7025 12.5025L11.1918 9.52516L13.3558 7.41783C13.7772 7.00716 13.5452 6.29183 12.9625 6.20716L9.97316 5.77249L8.6365 3.06249C8.3765 2.53516 7.62383 2.53516 7.36383 3.06249L6.0265 5.77249L3.03716 6.20716C2.45516 6.29183 2.2225 7.00716 2.64383 7.41783L4.80783 9.52516L4.29716 12.5025C4.19783 13.0818 4.80583 13.5245 5.3265 13.2505L8.0005 11.8452H7.99916Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
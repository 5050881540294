import React from 'react';
import PropTypes from 'prop-types';

import * as Utility from '../../scripts/utility';

const FormattedText = ({ height, children, style, ...props }) => {
	return (
		<div className="formatted-text" style={{ maxHeight: height ? `${height}rem` : 'auto', ...style }} {...props}>
			{typeof children === 'string' ? Utility.formatDisplayText(children) : children}
		</div>
	);
};

FormattedText.propTypes = {
	height: PropTypes.number,
	children: PropTypes.node,
};

FormattedText.defaultProps = {
	height: null,
	children: null,
};

export default FormattedText;

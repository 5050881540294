import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Typography,
	Button,
	Chip,
	IconButton,
	Checkbox,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import CheckboxInput from '../../components/form/inputs/CheckboxInput';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import Table from '../../components/Table';
import JobDeleteModal from '../../components/modals/JobDeleteModal';
import JobFilters from '../../components/filters/JobFilters';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import JobSyncModal from '../../components/modals/JobSyncModal';
import { palette } from '../../BobcatTheme';

const Jobs = () => {
	const store = useStore();
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const [selectedItems, setSelectedItems] = useState([]);
	const [menu, setMenu] = useState({ anchor: null, listing: {} });
	const [filter, setFilter] = Utility.useStickyState({ isOpen: false, filters: {} }, 'admin-listings-filter');
	const table = useRef();

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const filterIntakeCompleted = filter?.filters?.assessment_completed_min && filter?.filters?.assessment_completed_max;
	const setFilterIntakeCompleted = newFilterIntakeCompleted => {
		setFilter(currentFilter => ({
			...currentFilter,
			filters: {
				...currentFilter.filters,
				assessment_completed_min: newFilterIntakeCompleted ? new Date('01/01/1970').toISOString() : undefined,
				assessment_completed_max: newFilterIntakeCompleted ? new Date('12/31/2099').toISOString() : undefined,
			},
		}));
	};

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const updateFilters = useCallback(
		(ev, name) => {
			if (typeof ev === 'object' && typeof name === 'string') {
				setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
			} else if (ev.target.type === 'checkbox') {
				setFilter({
					...filter,
					filters: { ...filter.filters, [ev.target.name]: ev.target.checked ? ev.target.value : undefined },
				});
			} else if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value !== '') {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: ev.target.value } });
			} else {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
			}
		},
		[filter, setFilter],
	);

	const sendAssessment = ev => {
		setMenu({ ...menu, anchor: null });

		ListingsAPI.sendAssessment(menu.listing.id, (response, err) => {
			if (!response) {
				let message = 'The job assessment could not be sent. Please try again.';
				if (err === 'contact') message = 'A contact could not be found for this job. The assessment was not sent.';

				store.dispatch(SiteActions.showAlert(message, 'error'));
				return;
			}

			store.dispatch(SiteActions.showAlert('The job assessment was sent.', 'success'));
		});
	};

	// const mockAssessment = ev => {
	// 	setMenu({ ...menu, anchor: null });
	// 	const auth = JSON.parse(localStorage.getItem('authentication'));
	// 	localStorage.setItem(
	// 		'authentication',
	// 		JSON.stringify({
	// 			...auth,
	// 			listing_id: menu.listing.id,
	// 			role: 'job',
	// 			contact_id: menu.listing.owner.id,
	// 		}),
	// 	);
	// 	const win = window.open(`/job/${menu.listing.id}/profile`, '_blank');
	// 	win.focus();
	// };

	const headers = [
		{ id: '', value: '', noSort: true },
		{ id: 'select', value: '', noSort: true },
		{ id: 'listings.vendor_job_id', value: 'ID', noSort: false },
		{ id: 'listings.position_title', value: 'Job' },
		{ id: 'specialties.label', value: 'Specialty' },
		{ id: 'owner', value: 'Owner', noSort: true },
		{ id: 'contact', value: 'Contact', noSort: true },
		{ id: 'listings.company', value: 'Company' },
		{ id: 'listings.city', value: 'Work Location', noSort: true },
		{ id: 'hero_skills', value: 'Hero Skills', noSort: true },
		{ id: 'listings.last_profile_update', value: 'Last Updated' },
		{ id: 'listings.assessment_completed_at', value: 'Intake Completed' },
		{ id: 'job_statuses.name', value: 'Job Status', noSort: true },
		{ id: 'shared_match_count', value: 'Shared Matches', noSort: true },
	];
	// if (process.env.REACT_APP_FEATURE_MATCHING) {
	// 	headers.push({ id: 'job.matches', value: 'Matches', noSort: true });
	// }

	const formatData = rows => {
		return rows.map(job => {
			const contact = job?.contacts?.[0];
			const nameObj = contact?.user || contact;
			const contactName = `${nameObj?.first_name || ''} ${nameObj?.last_name || ''}`.trim();

			const jobRow = [
				<IconButton onClick={ev => setMenu({ anchor: ev.target, listing: job })}>
					<Icons.MoreVert color="primary" />
				</IconButton>,

				<>
					<CheckboxInput
						style={{ marginRight: -15 }}
						color="primary"
						checked={selectedItems.includes(job.id)}
						// onChange={() => {}}
						onClick={() => {
							if (selectedItems.includes(job.id)) {
								setSelectedItems(selectedItems.filter(x => x !== job.id));
							} else {
								setSelectedItems([...selectedItems, job.id]);
							}
						}}
					/>
				</>,
				<>
					<span>{job?.vendor_job_id}</span>
				</>,
				<>
					<div>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<NavLink
								to={`/job/${job.id}`}
								className="color-inherit"
								style={{ display: 'block', minWidth: 'max-content' }}
							>
								{job.position_title}
							</NavLink>
							<div style={{ marginLeft: 10 }}>
								<Icons.Share style={{ width: 14, color: job?.is_sharable ? '#00bb00' : '#ff2222' }} />
							</div>
						</div>
					</div>
				</>,
				<>
					{Utility.getItemLabel(data.specialties, job.specialty_id) || Utility.na}
					<br />
					<span className="color-muted">
						{Utility.getItemLabel(data.categories, job.professional_category_id) || null}
					</span>
				</>,

				<>
					<span style={{ display: 'block', minWidth: 150 }}>{job?.owner?.name}</span>
				</>,
				<>
					<span style={{ display: 'block', minWidth: 150 }}>{contactName || ''}</span>
					{job?.contacts?.[0]?.user?.claimed_at ? (
						<span className="color-muted">Account: {Utility.formatDate(job?.contacts?.[0]?.user?.claimed_at)}</span>
					) : null}
				</>,
				<>
					<span style={{ display: 'block', minWidth: 150 }}>
						{job.company || Utility.na}
						<br />
						<span className="color-muted">
							{Utility.getItemName(data.owners, job.owner, 'vendor_owner_id') || null}
						</span>
					</span>
				</>,
				<>
					<span className="color-muted">
						{job.state
							? `${job.city}, ${Utility.getFromObj(
									Utility.getIDFromObj(data.states, job.state, 'name'),
									'abbreviation',
							  )}`
							: Utility.na}
					</span>
					{/* {job?.office_locations?.length ? (
						<span className="color-muted">
							Offices: {job?.office_locations?.map(x => `${x?.city}, ${x?.abbreviation}`)?.join(', ')}
						</span>
					) : null}
					*/}
					{job?.work_location_preferences?.length ? (
						// <span>{job?.work_location_preferences?.map(x => x.label).join(', ')}</span>
						<span>{Utility.getConsolidatedLocationPreferences(job?.work_location_preferences)?.join(', ') || ''}</span>
					) : null}
				</>,
				<>
					{job?.skills
						?.filter(s => s.hero_skill)
						.map((s, i, a) => (
							<span key={s}>
								<Tooltip
									className="tooltip"
									title={`${s?.experience_years || 0} Year${s?.experience_years !== 1 ? 's' : ''}`}
									placement="top"
								>
									<span>{s?.skill?.label}</span>
								</Tooltip>
								{i < a.length - 1 ? ', ' : null}
							</span>
						))}
				</>,
				<>
					{job?.last_profile_update ? Utility.formatDate(moment(job?.last_profile_update)) : ''}
					<br />
					<span className="color-muted">{Utility.formatTime(job?.last_profile_update)}</span>
				</>,

				<>{job?.assessment_completed_at ? Utility.formatDate(moment(job?.assessment_completed_at)) : ''}</>,

				<Chip
					size="small"
					label={Utility.getFromObj(job, 'listing_status', 'label')}
					className={`color-${Utility.getListingStatusColor(Utility.getFromObj(job, 'listing_status', 'name'))}`}
				/>,
				<>
					<span style={{ display: 'block' }}>{job.shared_match_count || 0}</span>
				</>,
			];

			// if (process.env.REACT_APP_FEATURE_MATCHING) {
			// 	jobRow.push(
			// 		<>
			// 			<NavLink to={`/job/${job.id}/matches`}>
			// 				<Button
			// 					variant="outlined"
			// 					size="small"
			// 					style={{
			// 						whiteSpace: 'nowrap',
			// 						marginLeft: 0,
			// 						paddingRight: 8,
			// 						paddingLeft: 8,
			// 					}}
			// 					color="primary"
			// 				>
			// 					<Icons.Search style={{ marginRight: 5 }} /> View Matches
			// 				</Button>
			// 			</NavLink>
			// 		</>,
			// 	);
			// }

			return jobRow;
		});
	};

	const [clearMenuAnchorEl, setClearMenuAnchorEl] = React.useState(null);
	const [tableKey, setTableKey] = React.useState(0);

	const handleClearMenuClick = event => {
		setClearMenuAnchorEl(event.currentTarget);
	};

	const handleClearMenuClose = () => {
		setClearMenuAnchorEl(null);
	};

	const handleClearSort = () => {
		// window.localStorage.setItem('jobs-table', null);
		setTableKey(x => x + 1);
		handleClearMenuClose();
	};
	const handleClearFilter = () => {
		handleClearMenuClose();
		setFilter({ ...filter, filters: {} });
	};
	const handleClearAll = () => {
		handleClearMenuClose();
		handleClearSort();
		setTimeout(() => {
			handleClearFilter();
		}, 250);
	};

	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Jobs</Typography>
				</PageHeader.Left>

				{/* <PageHeader.Middle>
					<TextField
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icons.Search style={{ color: 'var(--primaryColor)' }} />
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				</PageHeader.Middle> */}

				<PageHeader.Right>
					{selectedItems.length ? (
						<NavLink to={`/jobs/details?jobs=${selectedItems?.join(',')}`} className="color-inherit">
							<Button
								variant="contained"
								color="primary"
								// onClick={openSelectedItemOverview}
								startIcon={<Icons.Fullscreen />}
							>
								Open Overview ({selectedItems.length})
							</Button>
						</NavLink>
					) : null}
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClearMenuClick}
						variant="outlined"
						color="primary"
						endIcon={<Icons.ArrowDropDown />}
					>
						Clear
					</Button>
					<Menu
						id="clear-menu"
						anchorEl={clearMenuAnchorEl}
						keepMounted
						open={Boolean(clearMenuAnchorEl)}
						onClose={handleClearMenuClose}
					>
						<MenuItem onClick={handleClearSort}>Clear Sort</MenuItem>
						<MenuItem onClick={handleClearFilter} disabled={!Object.keys(filter?.filters || {}).length}>
							Clear Filter
						</MenuItem>
						<MenuItem onClick={handleClearAll}>Clear All</MenuItem>
					</Menu>
					<Tooltip className="tooltip" title={`${numFilters} Filter${numFilters > 1 ? 's' : ''}`} placement="top">
						<Button
							style={{
								backgroundColor: numFilters > 0 ? 'rgba(76, 159, 255,.3)' : 'transparent',
							}}
							variant="outlined"
							color="primary"
							onClick={filter.isOpen ? closeFilter : openFilter}
							startIcon={<Icons.FilterList />}
						>
							Filter
						</Button>
					</Tooltip>

					{/* <Button
						variant="text"
						color="primary"
						onClick={ev => Utility.notImplemented(store)}
						startIcon={<Icons.GetApp />}
					>
						Export to XLS
					</Button> */}

					<Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(
								SiteActions.showModal(<JobSyncModal category={menu.category} onSync={table.current.refreshData} />),
							)
						}
						startIcon={<Icons.Sync />}
					>
						Sync Job
					</Button>
				</PageHeader.Right>
			</PageHeader.Header>

			<div
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 15, marginTop: -20 }}
			>
				<Checkbox checked={!!filterIntakeCompleted} onChange={ev => setFilterIntakeCompleted(ev.target.checked)} />
				<Typography variant="body2" style={{ marginBottom: 0 }}>
					Intake Completed
				</Typography>
			</div>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<JobFilters
				onClose={closeFilter}
				onReset={() => setFilter({ ...filter, filters: {} })}
				data={data}
				updateFilters={updateFilters}
				filter={filter}
				setFilter={setFilter}
				displayFilters={[
					'owner',
					'company',
					'contact',
					'position_title',
					'vendor_job_id',
					'is_open',
					'status_id',
					'professional_experience_years_range',
					'salary_range',
					'city',
					'state',
					'approved_remote_locations',
					'description',
					'professional_category_id',
					'specialty_id',
					'skills',
					'hero_skills',
					'industries',
					'offer_relocation',
					'work_visa_type_id',
					'recent_leadership_role_id',
					'recent_leadership_years_range',
					'role_type',
					'last_profile_update_range',
					'assessment_completed_range',
					'source'
				]}
			/>

			<Table
				stickyStateKey="jobs-table-v9"
				onUpdate={ListingsAPI.getListings}
				filters={filter.filters}
				format={formatData}
				defaultOrderBy="listings.created_at"
				defaultSortDirection="desc"
				headers={headers}
				ref={table}
				// key={tableKey}
				resetStateKey={tableKey}
			/>

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/job/${menu.listing.id}`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.WorkOutline color="primary" />
						</ListItemIcon>
						View Job
					</MenuItem>
				</NavLink>

				<NavLink to={`/job/${menu.listing.id}/matches`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.Search color="primary" />
						</ListItemIcon>
						View Suggested Matches
					</MenuItem>
				</NavLink>
				{/* <NavLink to={`/job/${menu.listing.id}/match-testing`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.TrendingUp color="primary" />
						</ListItemIcon>
						Testing
					</MenuItem>
				</NavLink> */}
				{/* <NavLink to={`/job/${menu.listing.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Job
					</MenuItem>
				</NavLink> */}
				<MenuItem disabled={menu.listing?.contacts?.[0]?.user?.claimed_at} onClick={sendAssessment}>
					<ListItemIcon>
						<Icons.Send color="primary" />
					</ListItemIcon>
					Portal Invitation
				</MenuItem>
				<MenuItem
					disabled={
						!menu.listing?.contacts?.[0]?.user?.claimed_at ||
						menu.listing?.bypass_intake ||
						menu.listing?.assessment_completed_at
					}
					onClick={sendAssessment}
				>
					<ListItemIcon>
						<Icons.Send color="primary" />
					</ListItemIcon>
					Send Job Notification
				</MenuItem>
				{/* <MenuItem  onClick={()=>{
					console.log("Sending job notification...")
				}}>
					<ListItemIcon>
						<Icons.Send color="primary" />
					</ListItemIcon>
					Send Job Notification
				</MenuItem> */}
				{/* <MenuItem onClick={mockAssessment}>
					<ListItemIcon>
						<Icons.Assignment color="primary" />
					</ListItemIcon>
					Mock Assessment
				</MenuItem> */}
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(
							SiteActions.showModal(<JobDeleteModal job={menu.listing} onDelete={() => table.current.refreshData()} />),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Job
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Jobs;

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const ProgressCircle = ({value, size, thickness}) => {
	const finalSize = size || 112
	return (
		<div style={{width: finalSize, height: finalSize}}>
			<CircularProgress
				variant="determinate"
				color="inherit"
				value={value || 0}
				thickness={thickness || 2}
				style={{ position: "absolute", height: finalSize, width: finalSize }}
			/>
			<CircularProgress
				variant="determinate"
				color="inherit"
				value={100}
				thickness={thickness || 2}
				style={{ position: "absolute", height: finalSize, width: finalSize, opacity: 0.2 }}
			/>
		</div>
	)
}

export default ProgressCircle

ProgressCircle.propTypes = {
	value: PropTypes.number,
	size: PropTypes.number,
	thickness: PropTypes.number,
}

ProgressCircle.defaultProps = {
	value: null,
	size: null,
	thickness: null
}
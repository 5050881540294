import React from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';
import Fence from '../../../../Fence';
import HorizontalSpacer from '../../../../HorizontalSpacer';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import * as Utility from '../../../../../scripts/utility';
import StatesDropdown from '../../../../form/dropdowns/StatesDropdown';
import WillingToRelocateDropdown from '../../../../form/dropdowns/WillingToRelocateDropdown';
import TravelWillingnessDropdown from '../../../../form/dropdowns/TravelWillingnessDropdown';
import LocationTimezonesDropdown from '../../../../form/dropdowns/LocationTimezonesDropdown';

const WorkLocationForm = ({ candidate, setCandidate }) => {
	const isRemoteOnly =
		Boolean(candidate.work_location_preferences?.length) &&
		candidate.work_location_preferences?.map(p => (p.id ? p.id : p)).every(val => val === 1);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Work Location Preferences
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={51}>
				<LabeledField label="Current Location" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<Grid container spacing={0}>
						<Grid item xs={6}>
							<TextField
								variant="outlined"
								name="city"
								placeholder="City"
								value={Utility.getFromObj(candidate, 'city')}
								onChange={setCandidate}
								fullWidth
							/>
						</Grid>
						<HorizontalSpacer width={1.5} />
						<Grid item xs={2}>
							<StatesDropdown
								variant="outlined"
								name="state"
								placeholder="State"
								value={Utility.getFromObj(candidate, 'state')}
								onChange={setCandidate}
								fullWidth
								useAbbreviation
							/>
						</Grid>
						<HorizontalSpacer width={1} />
						<Grid item xs={2}>
							<TextField
								variant="outlined"
								name="zip"
								placeholder="Zipcode"
								value={Utility.getFromObj(candidate, 'zip')}
								onChange={setCandidate}
								fullWidth
							/>
						</Grid>
					</Grid>
				</LabeledField>
			</Fence>
			<Grid item xs={9}>
				<LocationTimezonesDropdown onChange={setCandidate} model={candidate} />
			</Grid>
			<Fence width={25.5}>
				<LabeledField
					label="Willing to Relocate"
					required={
						!(Utility.getFromObj(candidate, 'work_location_preferences') || []).map(p => (p.id ? p.id : p)).includes(1)
					}
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<WillingToRelocateDropdown
						variant="outlined"
						name="willing_to_relocate"
						value={isRemoteOnly ? null : Utility.getFromObj(candidate, 'willing_to_relocate')}
						placeholder="Choose from our list"
						disabled={isRemoteOnly}
						onChange={setCandidate}
						fullWidth
					/>
				</LabeledField>
				<LabeledField
					label="Desired Work States"
					required={!isRemoteOnly && candidate?.willing_to_relocate !== '2'}
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<StatesDropdown
						variant="outlined"
						name="desired_locations"
						placeholder="Select all that apply"
						value={
							isRemoteOnly || candidate.willing_to_relocate === '2'
								? null
								: (Utility.getFromObj(candidate, 'desired_locations') || []).map(item =>
										item.state_id ? item.state_id : item,
								  )
						}
						onChange={setCandidate}
						disabled={isRemoteOnly || candidate.willing_to_relocate === '2'}
						fullWidth
						multiple
						useID
					/>
				</LabeledField>
				<LabeledField
					label="Willingness to Travel"
					info="How much work travel would you consider in your next role?"
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<TravelWillingnessDropdown
						variant="outlined"
						name="travel_willingness_type_id"
						value={candidate?.travel_willingness_type_id}
						placeholder="Choose from our list"
						onChange={setCandidate}
						fullWidth
					/>
				</LabeledField>
			</Fence>
		</>
	);
};

export default WorkLocationForm;

import React, { useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Button, IconButton, Menu, MenuItem, ListItemIcon, Chip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import Table from '../../../components/Table';
import UserAddModal from '../../../components/modals/UserAddModal';
import UserDeleteModal from '../../../components/modals/UserDeleteModal';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as UsersAPI from '../../../scripts/users';
import * as Utility from '../../../scripts/utility';

const Users = () => {
	const dispatch = useDispatch();
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const filters = { 'roles[]': [3, 4] };
	const [menu, setMenu] = useState({ anchor: null, user: {} });
	const table = useRef();

	const headers = [
		{ id: 'users.first_name', value: "User's Name" },
		{ id: 'users.email', value: 'E-mail' },
		{ id: 'roles.name', value: 'Role' },
		{ id: 'users.user_status', value: 'Status', noSort: true },
		{ id: 'users.last_login_at', value: 'Last Activity' },
		{ id: 'users.created_at', value: 'Added' },
		{ id: 'options', value: '', noSort: true },
	];

	const formatData = rows => {
		return rows.map(user => {
			return [
				<>
					<NavLink to={`/admin/user/${user.id}`} className="color-inherit">
						{`${user.first_name || ''} ${user.last_name || ''}` || Utility.na}
					</NavLink>
				</>,
				<>{user.email || Utility.na}</>,
				<>{Utility.getFromObj(user, 'roles', 0, 'label') || Utility.na}</>,
				<>
					<Chip
						label={Utility.getItemLabel(data.userStatuses, +user.user_status) || Utility.na}
						className={+user.user_status === 1 ? 'color-success' : ''}
					/>
				</>,
				<>{Utility.formatDateTime(user.last_login_at) || Utility.na}</>,
				<>{Utility.formatDateTime(user.created_at) || Utility.na}</>,
				<IconButton onClick={ev => setMenu({ anchor: ev.target, user })}>
					<Icons.MoreVert color="primary" />
				</IconButton>,
			];
		});
	};

	const sendAccountEmail = () => {
		setMenu({ ...menu, anchor: null });

		UsersAPI.resendInvite(menu.user.id, response => {
			if (!response) {
				dispatch(SiteActions.showAlert('The account email could not be sent. Please try again.', 'error'));
				return;
			}

			dispatch(SiteActions.showAlert('The account email was sent.', 'success'));
		});
	};

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Users</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<Button
						variant="contained"
						color="primary"
						onClick={ev => {
							dispatch(
								SiteActions.showModal(
									<UserAddModal
										onAdd={() => {
											table.current.refreshData();
										}}
									/>,
								),
							);
						}}
					>
						Add New User
					</Button>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Table onUpdate={(params, callback, store) => 
				 UsersAPI.getUsers(params, callback, store, true)
			} filters={filters} format={formatData} headers={headers} ref={table} />

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/admin/user/${menu.user.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit User
					</MenuItem>
				</NavLink>
				<MenuItem onClick={sendAccountEmail}>
					<ListItemIcon>
						<Icons.Email color="primary" />
					</ListItemIcon>
					Resend Account Email
				</MenuItem>
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						dispatch(
							SiteActions.showModal(<UserDeleteModal user={menu.user} onDelete={() => table.current.refreshData()} />),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete User
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Users;

import API from './api';

/**
 * Gets a contact record
 * @param {Number} id - The ID of the contact
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const getContact = (id, callback) => {
	API.get(`/contacts/${id}/profile`)
		.then(callback)
		.catch(err => {
			callback(false, err);
		});
};

/**
 * Gets the listings for a contact
 * @param {Number} id - The ID of the candidate
 * @param params - Filter parameters
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const getListings = (id, params, callback) => {
	API.get(`/contacts/${id}/listings`, { params })
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Gets the closed assignments for a contact
 * @param {Number} id - The ID of the candidate
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const getClosedAssessments = (id, callback) => {
	API.get(`/contacts/${id}/closed-assessments`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Resets the claimed_at for a user
 * @param {Number} id - The ID of the Contact we are resetting
 * @param {Object} parameters - The parameters for the user
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const resetClaimedAt = id => API.patch(`/contacts/${id}/reset-claimed-at`);

import React from 'react';
import Dropdown from './Dropdown';

const years = Array.from(Array(51).keys()).map(year => {
	return { id: `+${year}`, label: `${year} year${year === 1 ? '' : 's'}` };
});

const YearsDropdown = props => {
	const { value, onChange, disableSkillRemoval, ...otherProps } = props;

	// The MUI dropdown component has issues with values of 0, so we need to transform the value
	// coming in by adding a + to the beginning, and then remove the plus on the way out.
	const transformedValue = value === undefined || value === null ? null : `+${value}`;

	// add the ability for the user to select a null value
	// the backend reads the " " value as null, which clears the field
	const yearsWithNull = [{ id: ' ', label: '-' }, ...years];
	return (
		<Dropdown
			data={disableSkillRemoval ? years : yearsWithNull}
			value={transformedValue}
			onChange={e => {
				// e.preventDefault();
				onChange({
					target: {
						name: e.target.name,
						value: e.target.value.replace('+', ''),
					},
				});
			}}
			{...otherProps}
		/>
	);
};

export default YearsDropdown;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import {
	Typography,
	Button,
	TextField,
	Fab,
	CircularProgress,
	Box,
	RadioGroup,
	FormControlLabel,
	Radio,
	Chip,
} from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import * as Icons from '@material-ui/icons';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import FileInput from '../../../components/form/inputs/FileInput';
import UploadIcon from '../../../components/icons/UploadIcon';
import Fence from '../../../components/Fence';
import LabeledField from '../../../components/form/LabeledField';
import VerticalSpacer from '../../../components/VerticalSpacer';

const UploadDocumentModal = ({ onUpload, shareLink, document, context, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const [file, setFile] = useState(null);
	const [textFile, setTextFile] = useState(null);
	const [type, setType] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const store = useStore();
	const [uploadOrPasteResume, setUploadOrPasteResume] = useState('1');

	const height = 'calc(100vh - 125px)';

	const typeSupportsPlainText = [2, 3].includes(type);

	const isPlainText = typeSupportsPlainText && uploadOrPasteResume && uploadOrPasteResume === '2';

	return (
		<div style={{ textAlign: 'center' }}>
			<Typography variant="h5" style={{ marginBottom: 20 }}>
				Upload New Document
			</Typography>

			{/* <input
				type="file"
				name="test"
				value={testFile}
				onChange={e => {
					setTestFile(e.target.files[0]);
				}}
			/> */}
			{/* <div
				style={{
					display: 'flex',
				}}
			>
				<FileInput
					name="resumeFile"
					onChange={e => {
						setFile(e.target.value);
					}}
				>
					Select Document
				</FileInput>
			</div> */}

			<Fence width={55}>
				<RadioGroup
					row
					aria-label="Upload or paste text?"
					name="upload_or_paste_resume"
					onChange={e => {
						if (typeSupportsPlainText) {
							setUploadOrPasteResume(e.currentTarget.value);
						}
					}}
					disabled={!typeSupportsPlainText}
					value={isPlainText ? '2' : '1'}
				>
					<FormControlLabel value="1" control={<Radio color="primary" />} label="Upload File" />
					<FormControlLabel
						style={{
							opacity: !typeSupportsPlainText ? 0.5 : 1,
						}}
						value="2"
						control={<Radio color="primary" />}
						label="Copy &amp; Paste Text"
					/>
				</RadioGroup>
				<VerticalSpacer height={1} />
				{!isPlainText ? (
					<>
						<LabeledField>
							<DropzoneAreaBase
								name="file"
								dropzoneText="SELECT FILE"
								Icon={UploadIcon}
								dropzoneClass="file-upload-dropzone"
								onAdd={files => {
									console.log({ files });
									setFile(files[0].file);
									// store.dispatch(AssessmentActions.updateCandidate('resumeFile', files[0].file))
								}}
								filesLimit={1}
								showAlerts
								maxFileSize={300000000}
								showPreviewsInDropzone={false}
							/>
							{file && <Chip label={file?.name} onDelete={() => setFile(null)} />}
						</LabeledField>
					</>
				) : (
					<LabeledField label="Text">
						<TextField
							variant="outlined"
							name="resume"
							value={textFile}
							onChange={e => setTextFile(e.target.value)}
							rows={10}
							multiline
							fullWidth
						/>
					</LabeledField>
				)}
			</Fence>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					paddingTop: 15,
				}}
			>
				<Dropdown
					variant="outlined"
					name="offer_relocation"
					value={type}
					data={
						context === 'candidate'
							? [
									{
										id: 1,
										label: 'Profile',
									},
									{
										id: 2,
										label: 'Resume',
									},
							  ]
							: context === 'job'
							? [
									{
										id: 3,
										label: 'Description',
									},
							  ]
							: null
					}
					onChange={e => {
						setType(e.target.value);
					}}
					filterSelectedOptions={false}
					disableClearable={false}
					placeholder="Select option..."
					fullWidth
				/>

				<Button
					onClick={async e => {
						e.preventDefault();
						if (!isLoading) {
							setIsLoading(true);
							try {
								if (isPlainText) {
									await onUpload(textFile, type, isPlainText);
								} else {
									await onUpload(file, type, isPlainText);
								}
							} catch (err) {
								console.log(err);
							} finally {
								setIsLoading(false);
							}
						}
					}}
					variant="contained"
					color="primary"
					// size="small"
					disabled={isLoading || !type || (isPlainText && !textFile) || (!isPlainText && !file)}
					style={{ whiteSpace: 'nowrap' }}
				>
					{isLoading ? 'Uploading...' : 'Upload'}
				</Button>
			</div>
		</div>
	);
};

export default UploadDocumentModal;

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../form/LoadingButton';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import StarBadgeIcon from '../../../icons/StarBadgeIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';

const HeroSkillsForm = ({ prevStep, setStoryStep }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, progress } = useSelector(state => state.assessment, shallowEqual);
	const skillStoryName = 'hero_skill';
	const requiredSkills = listing?.skills?.filter(s => s.required_skill);
	const heroSkills = useMemo(() => listing?.skills?.filter(s => s[skillStoryName]), [listing.skills]);

	const handleSelectSkill = skill => {
		const alreadySelectedSkill = listing.skills.find(s => s.skill_id === skill.skill_id && s[skillStoryName]);
		if (heroSkills.length >= 3 && !alreadySelectedSkill) return;
		const updatedSkills = listing.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...s, [skillStoryName]: !s[skillStoryName] } : s,
		);
		store.dispatch(AssessmentActions.updateListingSkills(updatedSkills));
		if (heroSkills.length > 0) {
			store.dispatch(AssessmentActions.setCanProceed(true));
		}
	};

	const nextStep = ev => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		ListingsAPI.addListingSkills(listingID, listing.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateListingSkills(response.data.data));
			store.dispatch(AssessmentActions.setIsSaving(false));
			setStoryStep(s => s + 1);
		});
	};

	// Check if can proceed
	useEffect(() => {
		const canProceed = heroSkills?.length >= 1;
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [heroSkills]);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">2 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Let&rsquo;s further refine the job skills into categories that will help generate the most precise candidate
				matches - <strong>Hero</strong> skills.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<StarBadgeIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Hero Skills
						</Typography>
						<Typography>
							Please select up to <span className="text-underline">three</span> (3) skills that are viewed as the most
							critical. These are the skills that are most important to successful performance in this role.
						</Typography>
						<VerticalSpacer height={2} />
						{requiredSkills
							?.filter(x => x.experience)
							.map(skill => (
								<Chip
									key={skill.skill_id}
									label={skill.skill.label}
									color={heroSkills.find(s => s.skill_id === skill.skill_id) ? 'primary' : 'default'}
									disabled={heroSkills.length >= 3 && !heroSkills.find(s => s.skill_id === skill.skill_id)}
									onClick={() => handleSelectSkill(skill)}
								/>
							))}
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={() => prevStep()}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

HeroSkillsForm.propTypes = {
	prevStep: PropTypes.func,
	setStoryStep: PropTypes.func,
};

HeroSkillsForm.defaultProps = {
	prevStep: () => null,
	setStoryStep: () => null,
};

export default HeroSkillsForm;

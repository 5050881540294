import Axios from 'axios';
import API from './api';
import * as Utility from './utility';

// API ENDPOINTS

/**
 * Gets a paginated list of skills
 * @param {Object} parameters - Filters passed as query parameters
 * @param {*} callback - The function called once AJAX call is finished
 */
export const getSkills = (parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/skills${query}`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Gets a full list of skills
 * @param {Object} parameters - Filters passed as query parameters
 * @param {*} callback - The function called once AJAX call is finished
 */
export const getAllSkills = (parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/skills/tree${query}`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Gets the data for a specific skill
 * @param {Number} id - The ID of the skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const getSkill = (id, callback) => {
	API.get(`/skills/${id}/tree`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Dea specific skill
 * @param {Number} id - The ID of the skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const deprecateSkill = (id, replacementSkillId, callback) => {
	API.post(`/skills/deprecate/${id}`, {
		replacement_skill_id: replacementSkillId,
	})
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Updates a skill record
 * @param {Number} id - The ID of the skill
 * @param {Object} parameters - The parameters for the skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const updateSkill = (id, parameters, callback) => {
	const skill = parameters;

	if (skill.label && !skill.name) skill.name = skill.label;
	if (!skill.specialty) skill.specialty = skill.id;

	API.patch(`/skills/${id}`, skill)
		.then(callback)
		.catch(err => callback(false));
};

export const updateBulkSkills = (parameters, callback) => {
	const skills = parameters;

	API.patch(`/skills/bulk`, skills)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Creates a new skill record
 * @param {Object} parameters - The parameters for the skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const addSkill = (parameters, callback) => {
	const skill = { ...parameters };

	if (skill.label && !skill.name) skill.name = skill.label;

	API.post(`/skills`, skill)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Create one or more new skills
 * @param {Array.<String>} skills - An array of new skill names
 * @param {Function} callback - A callback function
 * @returns {Array.<{id: Number, name: String, label: String, suggested: Boolean}>} - New skills
 */
export const addSkills = (skills, callback) => {
	Axios.all(skills.map(s => API.post('/skills', s))).then(
		// eslint-disable-next-line func-names
		Axios.spread(function() {
			// eslint-disable-next-line prefer-rest-params
			let newSkills = Array.from(arguments);
			newSkills = newSkills.map(response => response.data.data);
			if (typeof callback === 'function') callback(newSkills);
		}),
	);
};

/**
 * Deletes a skill
 * @param {Number} id - The ID of the skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const deleteSkill = (id, parameters, callback) => {
	API.delete(`/skills/${id}`, { params: parameters })
		.then(callback)
		.catch(err => callback(false, err));
};

export const syncSkillSpecialties = (id, specialties, callback) => {
	API.patch(`/skills/${id}/specialties/sync`, { specialties })
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Get a paginated list of sovren skills for a skill
 * @param {Number} id - The ID of the skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const getSovrenSkills = (id, parameters, callback) => {
	const query = ''; // Utility.propsToURL(parameters);

	API.get(`/skills/${id}/sovren-skills${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Get a paginated list of PDL skills for a skill
 * @param {Number} id - The ID of the skill
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const getPDLSkills = (id, parameters, callback) => {
	const query = ''; // Utility.propsToURL(parameters);

	API.get(`/skills/${id}/pdl-skills${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Send a skill suggestion
 * @param {String} body - The user's suggestion
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const suggestSkills = ({ body, listingID, candidateID }, callback) => {
	API.post(`/skills/suggest-skills`, {
		body,
		listing_id: listingID || undefined,
		candidate_id: candidateID || undefined,
	})
		.then(callback)
		.catch(err => callback(false, err));
};

// FUNCTIONS

/**
 * Split the candidate/job skill list into categories of IDs for the dropdowns
 * @param {Array.<{Object}>} levels - The list of all skill levels from data.skillExperienceLevels
 * @param {Array.<Object>} skills - The skills object from a candidate or job
 * @returns {Object} categorizedSkills - An object of skill levels with an array of IDs
 */
export const splitSkills = (levels, skills) => {
	const categorizedSkills = {};

	levels.forEach(level => {
		categorizedSkills[`skills_${level.name}`] = (skills || [])
			.filter(skill => skill.experience?.name === level.name)
			.map(skill => skill.skill_id);
	});

	return categorizedSkills;
};

/**
 * Convert the seperate lists of IDs to a skills object for the skills sync endpoint
 * @param {Array.<{Object}>} levels - The list of all skill levels from data.skillExperienceLevels
 * @param {Array.<Object>} skills - The list of all skills from data.skills
 * @param {Object} obj - The candidate or job object
 * @returns {Array.<Object>} - The skills array in the accepted API format
 */
export const joinSkills = (levels, skills, obj) => {
	let joinedSkills = [];

	// hydrate the skills
	levels.forEach(level => {
		joinedSkills = joinedSkills.concat(
			...skills
				.filter(s => Utility.getFromObj(obj, `skills_${level.name}`).includes(s.id))
				.map(s => ({ ...s, experience_id: level.id })),
		);
	});

	// include any new skills as uncategorized
	joinedSkills = joinedSkills.concat(
		...skills.filter(s => Utility.getFromObj(obj, 'skills_new').includes(s.id)).map(s => ({ ...s, experience_id: 1 })),
	);

	// Make sure they're not duplicated
	joinedSkills = Utility.uniqueIDs(joinedSkills);

	// Convert to the API format
	joinedSkills = joinedSkills.map(s => ({
		skill_id: s.id,
		skill_experience_id: s.experience_id,
		experience_years: s.experience_years || 0,
	}));

	return joinedSkills;
};

/**
 * Parse out new suggested skills from predefined skills
 * @param {Array.<Number|String>} skills - The list of all skills
 * @returns {Array.<{name: String, label: String, suggested: Boolean}>} - Array of new skill objects
 */
export const getNewSkills = (levels, obj) => {
	// if (!skills || !skills.length) return [];

	let newSkills = [];

	levels.concat([{ name: 'new' }]).forEach(level => {
		newSkills = newSkills.concat((obj[`skills_${level.name}`] || []).filter(s => typeof s === 'string'));
	});

	newSkills = newSkills.map(s => ({ name: s, label: s, suggested: true }));

	return newSkills;
};

/**
 * Calculate the status of a skills based on flags
 * @param {Object} skill - The full skill object
 * @returns {String} - The skill status label
 */
export const calculateSkillStatus = skill => {
	if (skill.deleted_at) return 'Inactive';
	if (skill.needs_review) return 'Pending';
	return 'Active';
};

/**
 * Calculate the color class of a skills Chip
 * @param {Object} status - The skill status from calculateSkillStatus()
 * @returns {String} - The skill color class
 */
export const calculateSkillColor = status => {
	if (status === 'Pending') return 'color-warning';
	if (status === 'Active') return 'color-success';
	return '';
};

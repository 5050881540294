/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 40 36"
			style={style}
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M31.6673 25.4247L33.7886 23.3034L29.9853 19.5001L39.0919 19.5001L39.0919 16.5001L29.9853 16.5001L33.7886 12.6968L31.6673 10.5755L24.2426 18.0001L31.6673 25.4247ZM20 7.39348L14.6967 12.6968L12.5754 10.5755L20 3.15084L27.4246 10.5755L25.3033 12.6968L20 7.39348ZM25.3033 23.3034L20 28.6067L14.6967 23.3034L12.5754 25.4247L20 32.8493L27.4246 25.4247L25.3033 23.3034ZM0.908115 19.5001L10.0147 19.5001L6.21142 23.3034L8.33274 25.4247L15.7574 18.0001L8.33274 10.5755L6.21142 12.6968L10.0147 16.5001L0.908116 16.5001L0.908115 19.5001Z"
				fill={color || '#08C6B8'}
			/>
		</svg>
	);
}

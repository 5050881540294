import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, Tooltip, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useStore, useSelector, shallowEqual } from 'react-redux';

import * as Icons from '@material-ui/icons';
import LoadingButton from '../form/LoadingButton';
import * as Utility from '../../scripts/utility';

import Table from '../Table';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as ContactsAPI from '../../scripts/contacts';
import { isLocalAPI, isStagingAPI } from '../../scripts/api';
import LabeledField from '../form/LabeledField';
import * as AuthenticationAPI from '../../scripts/authentication';

import BiUser from '../icons/BiUser';

const ContactJobsModal = ({ job, sendAssessment, contact }) => {
	const store = useStore();
	const { data } = useSelector(state => state.site, shallowEqual);
	const table = useRef();

	const [isPortalInvitationLoading, setIsPortalInvitationLoading] = useState(false);
	const [displayInvitationSuccess, setDisplayInvitationSuccess] = useState(false);

	const [isPasswordResetLoading, setIsPasswordResetLoading] = useState(false);
	const [displayPasswordResetSuccess, setDisplayPasswordResetSuccess] = useState(false);

	const [isPortalCompletionResetLoading, setIsPortalCompletionResetLoading] = useState(false);
	const [displayPortalCompletionReset, setDisplayPortalCompletionReset] = useState(false);
	const [portalResetResultButtonMessage, setPortalResetResultButtonMessage] = useState('');

	const headers = [
		// { id: '', value: '', noSort: true },
		{ id: 'vendor_id', value: 'ID' },
		{ id: 'listings.position_title', value: 'Job' },
		// { id: 'specialties.label', value: 'Specialty' },
		// { id: 'owner', value: 'Owner', noSort: true },
		{ id: 'contact', value: 'Contact', noSort: true },
		// { id: 'listings.company', value: 'Company' },
		{ id: 'listings.city', value: 'Work Location', noSort: true },
		{ id: 'hero_skills', value: 'Hero Skills', noSort: true },
		// { id: 'listings.last_profile_update', value: 'Last Updated' },
		// { id: 'listings.assessment_completed_at', value: 'Intake Completed' },
		{ id: 'job_statuses.name', value: 'Job Status', noSort: true },
	];
	const formatData = rows => {
		return rows.map(listing => {
			return [
				<>
					<span>{listing?.vendor_job_id}</span>
				</>,
				<>
					<NavLink
						to={`/job/${listing.id}`}
						onClick={() => store.dispatch(SiteActions.hideModal())}
						className="color-inherit"
					>
						{listing.position_title}
					</NavLink>
				</>,
				<>
					<span style={{ display: 'block', minWidth: 150 }}>
						{listing.company || Utility.na}
						<br />
						<span className="color-muted">
							{Utility.getItemName(data.owners, listing.owner, 'vendor_owner_id') || null}
						</span>
					</span>
				</>,
				<>
					<span className="color-muted">
						{listing.state
							? `${listing.city}, ${Utility.getFromObj(
									Utility.getIDFromObj(data.states, listing.state, 'name'),
									'abbreviation',
							  )}`
							: Utility.na}
					</span>
					{listing?.work_location_preferences?.length ? (
						<span>{listing?.work_location_preferences?.map(x => x.label).join(', ')}</span>
					) : null}
				</>,
				<>
					{listing?.skills
						?.filter(s => s.hero_skill)
						.map((s, i, a) => (
							<span key={s}>
								<Tooltip
									className="tooltip"
									title={`${s?.experience_years || 0} Year${s?.experience_years !== 1 ? 's' : ''}`}
									placement="top"
								>
									<span>{s?.skill?.label}</span>
								</Tooltip>
								{i < a.length - 1 ? ', ' : null}
							</span>
						))}
				</>,
				<Chip
					size="small"
					label={Utility.getItemLabel(data.jobStatuses, listing.status_id, 'id')}
					className={`color-${Utility.getListingStatusColor(
						Utility.getItemName(data.jobStatuses, listing.status_id, 'id'),
					)}`}
				/>,
			];
		});
	};

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="h3" style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 5 }}>
							{contact?.user?.claimed_at ? (
								<>
									<BiUser color="rgb(0, 187, 0)" size={26} />
									{/* <BiUserCheck color="rgb(0, 187, 0)" size={26} /> */}
								</>
							) : (
								<>
									<BiUser color="#FA5152" size={26} />
									{/* <BiUserX color="#FA5152" size={26} /> */}
								</>
							)}
						</div>
						Contact User Account
					</Typography>
				</div>
			</div>

			<Grid container spacing={2} style={{ marginTop: 10 }}>
				<Grid item xs={3}>
					<LabeledField label="Name" className="color-muted" removeMargin close>
						<Typography variant="body2">
							{`${Utility.getJobContactField(job.contacts, 'first_name')} ${Utility.getJobContactField(
								job.contacts,
								'last_name',
							)}` || Utility.nb}
						</Typography>
					</LabeledField>
				</Grid>
				<Grid item xs={3}>
					<LabeledField label="Username" className="color-muted" removeMargin close>
						{Utility.getJobContactField(job.contacts, 'email') ? (
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`mailto:${Utility.getJobContactField(job.contacts, 'email')}`}
								className="color-inherit text-underline text-normal"
							>
								{Utility.getJobContactField(job.contacts, 'email')}
							</a>
						) : (
							Utility.nb
						)}{' '}
					</LabeledField>
				</Grid>
				<Grid item xs={!contact?.user?.claimed_at ? 2 : 3}>
					<LabeledField label="Phone" className="color-muted" removeMargin close>
						{contact?.phone2 ? Utility.formatDate(contact?.phone2) : 'N/A'}
					</LabeledField>
				</Grid>
				<Grid item xs={!contact?.user?.claimed_at ? 2 : 3}>
					<LabeledField label="Account Created" className="color-muted" removeMargin close>
						{contact?.user?.claimed_at ? Utility.formatDate(contact?.user?.claimed_at) : 'N/A'}
					</LabeledField>
				</Grid>
				{/* Only show if Account Created is null */}
				{!contact?.user?.claimed_at && (
					<Grid item xs={2}>
						<LabeledField label="Invite Last Sent" className="color-muted" removeMargin close>
							{job?.assessment_last_sent_at
								? `${Utility.formatDate(job.assessment_last_sent_at, true, 'M/DD/YY')} by
							${job.assessment_last_sent_by_user.first_name} ${job.assessment_last_sent_by_user.last_name}`
								: 'N/A'}
						</LabeledField>
					</Grid>
				)}
				<div style={{ display: 'flex', marginTop: 20 }}>
					<LoadingButton
						loading={isPortalInvitationLoading}
						color="primary"
						size="small"
						variant="outlined"
						style={{ marginLeft: 0 }}
						disabled={contact?.user?.claimed_at}
						onClick={e => {
							setIsPortalInvitationLoading(true);
							sendAssessment(e, success => {
								setIsPortalInvitationLoading(false);

								if (success) {
									setDisplayInvitationSuccess(true);
									setTimeout(() => {
										setDisplayInvitationSuccess(false);
									}, 2000);
								}
							});
						}}
					>
						{displayInvitationSuccess ? (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Icons.Check style={{ width: 16, height: 16, marginRight: 5 }} />
								Sent
							</div>
						) : (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Icons.Send style={{ width: 16, height: 16, marginRight: 5 }} />
								Portal Invitation
							</div>
						)}
					</LoadingButton>
					{!!job.contacts?.[0] && (
						<LoadingButton
							loading={isPasswordResetLoading}
							disabled={!contact?.user?.claimed_at}
							isLoading
							color="primary"
							style={{ marginLeft: 10 }}
							variant="outlined"
							size="small"
							onClick={() => {
								setIsPasswordResetLoading(true);
								AuthenticationAPI.forgotPassword(contact?.user?.email)
									.then(x => {
										console.log({ results: x });
										// store.dispatch(SiteActions.showAlert('The email was sent.', 'success'));
										setDisplayPasswordResetSuccess(true);
										setTimeout(() => {
											setDisplayPasswordResetSuccess(false);
										}, 2000);
									})
									.catch(err => {
										console.log(err);
									})
									.finally(() => {
										setIsPasswordResetLoading(false);
									});
							}}
						>
							{displayPasswordResetSuccess ? (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Icons.Check style={{ width: 16, height: 16, marginRight: 5 }} />
									Sent
								</div>
							) : (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Icons.Send style={{ width: 16, height: 16, marginRight: 5 }} />
									Password Reset
								</div>
							)}
						</LoadingButton>
					)}
					{(isStagingAPI || isLocalAPI) && (
						<LoadingButton
							loading={isPortalCompletionResetLoading}
							disabled={!contact?.user?.claimed_at || !!portalResetResultButtonMessage}
							isLoading
							color="primary"
							style={{ marginLeft: 10 }}
							variant="outlined"
							size="small"
							onClick={() => {
								setIsPortalCompletionResetLoading(true);
								ContactsAPI.resetClaimedAt(contact?.id)
									.then(_ => {
										setDisplayPortalCompletionReset(true);
										setPortalResetResultButtonMessage('Success');
										setIsPortalCompletionResetLoading(false);

										setTimeout(() => {
											setDisplayPortalCompletionReset(false);
										}, 2000);
									})
									.catch(err => {
										setPortalResetResultButtonMessage('Error');
										setDisplayPortalCompletionReset(true);
										setIsPortalCompletionResetLoading(false);

										console.log({ err });
									});
							}}
						>
							{displayPortalCompletionReset ? (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{portalResetResultButtonMessage === 'Success' ? (
										<>
											<Icons.Check style={{ width: 16, height: 16, marginRight: 5 }} />
											{portalResetResultButtonMessage}{' '}
										</>
									) : (
										<>
											<Icons.Error style={{ width: 16, height: 16, marginRight: 5 }} />
											{portalResetResultButtonMessage}
										</>
									)}
								</div>
							) : (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Icons.Send style={{ width: 16, height: 16, marginRight: 5 }} />
									Reset Portal Completion
								</div>
							)}
						</LoadingButton>
					)}
				</div>
			</Grid>

			<div style={{ height: 50 }} />
			<hr />
			<div style={{ height: 50 }} />
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
				<Icons.FormatListBulleted color="gray" style={{ marginRight: 10, width: 26, height: 26 }} />

				<Typography variant="h3" style={{ marginBottom: 0 }}>
					Jobs
				</Typography>
			</div>
			<Table
				onUpdate={(params, callback) => ContactsAPI.getListings(contact?.id, params, callback)}
				// filters={{
				// 	company: company?.name,
				// }}
				format={formatData}
				defaultOrderBy="listings.created_at"
				defaultSortDirection="desc"
				headers={headers}
				ref={table}
			/>
		</div>
	);
};

ContactJobsModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	contact: PropTypes.object,
};

ContactJobsModal.defaultProps = {
	contact: null,
};

export default ContactJobsModal;

// import Axios from 'axios';
import API from './api';

// const getAuditLog = ({ id, modelName, page = 1, resultsPerPage = 10, userId,
// dateFilter, fieldFilter, callback }) => {
// 	API.get(
// 		`audit/${modelName}/${id}?page=${page}
// 		&results_per_page=${resultsPerPage}
// 		${userId ? `&user_id=${userId}` : ''}
// 		${dateFilter ? `&date_filter=${dateFilter}` : ''}
// 		${fieldFilter && fieldFilter.length ? `&field_filters=${fieldFilter.join(',')}` : ''}`,
// 	)
// 		.then(callback)
// 		.catch(err => callback(false, err));
// };

const getAuditLog = ({
	id,
	modelName,
	page = 1,
	resultsPerPage = 10,
	userId,
	dateFilter,
	fieldFilter,
	sortColumn = 'created_at',
	sortDirection = 'desc',
	callback,
}) => {
	API.get(
		`audit/${modelName}/${id}?page=${page}
		&results_per_page=${resultsPerPage}&sort_column=${sortColumn}&sort_direction=${sortDirection}
		${userId ? `&user_id=${userId}` : ''}
		${dateFilter ? `&date_filter=${dateFilter}` : ''}
		${fieldFilter && fieldFilter.length ? `&field_filters=${fieldFilter.join(',')}` : ''}`,
	)
		.then(callback)
		.catch(err => callback(false, err));
};

export default getAuditLog;

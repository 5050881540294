import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Switch, Route, useLocation } from 'react-router-dom';
import { IconButton, Modal } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import Draggable from 'react-draggable';
import Topbar from './Topbar';
import CenteredTopbar from './CenteredTopbar';
import Sidebar from './sidebar/Sidebar';
import SignupSidebar from './sidebar/SignupSidebar';
import DetailsModal from '../matching/DetailsModal/index';
import CandidateAssessmentSidebar from './sidebar/CandidateAssessmentSidebar';
import JobAssessmentSidebar from './sidebar/JobAssessmentSidebar';
import useCompatibility from '../../hooks/useCampatibility';
import useLoadSiteData from '../../hooks/useLoadSiteData';
import { ActionCreators as SiteActions } from '../../store/Site';

const getTopbar = (option, context) => {
	if (option === 'none') return null;
	if (option === 'center') return <CenteredTopbar context={context} />;
	return <Topbar context={context} />;
};

const getSidebar = option => {
	if (option === 'none') return null;
	if (option === 'signup') return <SignupSidebar />;
	if (option === 'candidate') return <CandidateAssessmentSidebar />;
	if (option === 'job') return <JobAssessmentSidebar />;
	return <Sidebar />;
};

const Page = ({
	topbar,
	topbarContext,
	sidebar,
	skipData,
	centered,
	filterPadding,
	className,
	children,
	header,
	...props
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const compatibility = useCompatibility();
	const loadSiteData = useLoadSiteData({ skip: skipData });
	const { modal } = useSelector(state => state.site, shallowEqual);
	const location = useLocation();

	// Hide the alert when we enter the page
	useEffect(() => {
		dispatch(SiteActions.addHistory(history.location.pathname));
		dispatch(SiteActions.hideAlert());
	}, []);

	// Reset modal position when a static modal opens while a draggable modal is open
	const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
	useEffect(() => {
		setModalPosition({ x: 0, y: 0 });
	}, [modal.isDraggable]);

	return (
		<div className={`page-container ${className}`} {...props}>
			{getTopbar(topbar, topbarContext)}
			<div className="page-body">
				{getSidebar(sidebar)}
				<div className={`${header ? 'header' : 'page-body'}`}>
					{header}
					<main className={`${centered ? 'centered' : ''} ${filterPadding ? 'filter-visible' : ''}`}>
						{children}

						<Switch>
							<Route path="/:rootPath*/details" component={DetailsModal} />
						</Switch>
						<Modal
							className={`modal ${modal.className}`}
							open={modal.show}
							onClose={(_, reason) => {
								if (
									(reason === 'backdropClick' && !modal.disableBackdropClick) ||
									(reason === 'escapeKeyDown' && !modal.disableEscapeKey)
								) {
									dispatch(SiteActions.hideModal());
								}
							}}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
							BackdropProps={{
								style: {
									backgroundColor: modal.isDraggable ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 15, 50, 0.3)',
								},
							}}
							style={{ pointerEvents: modal.isDraggable ? 'none' : 'auto' }}
						>
							{modal.isDraggable ? (
								<Draggable
									bounds="parent"
									disabled={!modal.isDraggable}
									position={modal.isDraggable ? undefined : modalPosition}
								>
									<div
										className="modal-body"
										style={{
											pointerEvents: 'auto',
											cursor: 'grab',
										}}
									>
										{!modal.disableCloseButton && (
											<IconButton className="modal-close" onClick={() => dispatch(SiteActions.hideModal())}>
												<Icons.Close />
											</IconButton>
										)}
										{modal.content || null}
									</div>
								</Draggable>
							) : (
								<div className="modal-body">
									{!modal.disableCloseButton && (
										<IconButton className="modal-close" onClick={() => dispatch(SiteActions.hideModal())}>
											<Icons.Close />
										</IconButton>
									)}
									{modal.content || null}
								</div>
							)}
						</Modal>
					</main>
				</div>
			</div>
		</div>
	);
};

Page.propTypes = {
	topbar: PropTypes.string,
	topbarContext: PropTypes.string,
	sidebar: PropTypes.string,
	skipData: PropTypes.bool,
	centered: PropTypes.bool,
	filterPadding: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
};

Page.defaultProps = {
	topbar: '',
	topbarContext: '',
	sidebar: '',
	skipData: false,
	centered: false,
	filterPadding: false,
	className: '',
	children: null,
};

export default Page;

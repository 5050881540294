import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

export const BusinessSizePreferencesDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const { value } = props;

	// 1 'any' value, 8 others values, 9 total
	const dataWithAnyValue = [
		{
			id: 0,
			name: 'any',
			label: 'Any',
			rank_value: '0',
		},
		...data.businessSizePreferences,
	];
	const dataToShow = value.includes(0) ? [dataWithAnyValue[0]] : dataWithAnyValue;
	return <Dropdown data={dataToShow} {...props} />;
};

export const BusinessSizeExperienceDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const { value, isJob } = props;

	// 1 'any' value, 9 others values, 10 total
	const dataWithAnyValue = [
		{
			id: 0,
			name: 'all',
			label: 'All',
			rank_value: '0',
		},
		...data.businessSizeExperiences,
	];
	const dataToShow = value.includes(0) ? [dataWithAnyValue[0]] : dataWithAnyValue;
	return <Dropdown data={isJob ? data.businessSizeExperiences : dataToShow} {...props} />;
};

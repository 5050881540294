import React, { useRef, useState } from 'react';
import { Typography, Button, IconButton, Menu, MenuItem, ListItemIcon, Tabs, Tab } from '@material-ui/core';
import * as CandidatesAPI from '../../scripts/candidates';

const SyncCatSpecButton = () => {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Button
			variant="contained"
			color="primary"
			disabled={isLoading}
			onClick={async () => {
				if (!isLoading) {
					setIsLoading(true);
					try {
						await CandidatesAPI.syncCatSpec();
					} catch (e) {
						console.log(e);
					} finally {
						setIsLoading(false);
					}
				}
			}}
		>
			{isLoading ? 'Loading...' : 'Sync Categories & Specialties'}
		</Button>
	);
};

export default SyncCatSpecButton;

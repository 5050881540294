/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function ResumeIcon({ style, color = "currentColor", size = 18, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size + 2}
			viewBox="0 0 20 22"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-567 -745)">
					<g transform="translate(567 746)">
						<g transform="translate(1)">
							<path d="M4 10L12 10"/>
							<path d="M4 14L12 14"/>
							<g>
								<path d="M2 0h9.172a2 2 0 011.414.586l2.828 2.828A2 2 0 0116 4.828V16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"/>
								<path d="M16 5h-4a1 1 0 01-1-1V0"/>
								<path d="M4 6L7 6"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
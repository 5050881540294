import React from 'react';
import { Typography } from '@material-ui/core';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import * as Utility from '../../../../../scripts/utility';
import { BusinessSizePreferencesDropdown } from '../../../../form/dropdowns/BusinessSizeDropdown';
import ValuePropositionsDropdown from '../../../../form/dropdowns/ValuePropositionsDropdown';
import IndustryPreferenceDropdown from '../../../../form/dropdowns/IndustryPreferenceDropdown';

const Companyform = ({ candidate, setCandidate }) => {
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Company Preferences
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Select the industries and business sizes you&apos;re interested in.
			</Typography>
			<VerticalSpacer height={3} />
			<Fence style={{ marginBottom: -10 }} width={100}>
				<LabeledField
					label="Industries"
					info="If you are interested in particular industries, please select them."
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<IndustryPreferenceDropdown labeledField onChange={setCandidate} model={candidate} />
				</LabeledField>
			</Fence>
			<Fence width={25}>
				<LabeledField
					label="Company Size(s)"
					info="If you want to work for a particular size company, please select them."
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<BusinessSizePreferencesDropdown
						variant="outlined"
						name="business_size_preferences"
						value={
							(Utility.getFromObj(candidate, 'business_size_preferences') || []).length === 8
								? [0]
								: (Utility.getFromObj(candidate, 'business_size_preferences') || []).map(item =>
										item.id ? item.id : item,
								  )
						}
						onChange={setCandidate}
						placeholder={
							Utility.getFromObj(candidate, 'business_size_preferences')?.length > 0 ? '' : 'Select all that apply'
						}
						fullWidth
						multiple
						isIntake
					/>
				</LabeledField>
				<LabeledField
					label="Next Employer and Employment Priorities (max 4)"
					removeMargin
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<ValuePropositionsDropdown
						variant="outlined"
						name="job_priority_types"
						value={candidate?.job_priority_types?.map(t => (t.job_priority_type_id ? t.job_priority_type_id : t))}
						placeholder="Select your top priorities"
						onChange={e => {
							setCandidate({
								target: {
									name: 'job_priority_types',
									value: e?.target?.value?.map((id, i) => ({
										priority: i,
										job_priority_type_id: id,
									})),
								},
							});
						}}
						fullWidth
						multiple
					/>
				</LabeledField>
			</Fence>
		</>
	);
};

export default Companyform;

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../form/LoadingButton';
import Fence from '../../../Fence';
import LabeledField from '../../../form/LabeledField';
import VerticalSpacer from '../../../VerticalSpacer';
import ElasticSkillDropdown from '../../../form/dropdowns/ElasticSkillDropdown';
import LightningIcon from '../../../icons/LightningIcon';
import VerifyAccountModal from '../../VerifyAccountModal';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import JobAssessmentSidebar from '../../../page/sidebar/JobAssessmentSidebar';

const BonusSkillsForm = ({ prevStep, setJobProfile, getJobDescriptions }) => {
	const store = useStore();
	const { data, alert, user: authUser } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, progress } = useSelector(state => state.assessment, shallowEqual);
	const [validSkills, setValidSkills] = useState([]);
	const [preSelectedSkillIds, setPreselectedSkillIds] = useState([]);
	const [preSelectedSkills, setPreselectedSkills] = useState([]);
	const skillStoryName = 'bonus_skill';
	// const skillOptions = useMemo(
	// 	() => validSkills.filter(option => !listing.skills.find(s => s.skill_id === option.id)),
	// 	[listing.skills, validSkills],
	// );
	const bonusSkills = useMemo(() => listing?.skills?.filter(s => s[skillStoryName]), [listing.skills]);

	const addPreselectedSkills = () => {
		const newSkills = preSelectedSkills.map(skill => ({
			skill_id: skill.id,
			listing_id: listingID,
			skill,
			[skillStoryName]: true,
			skill_experience_id: 1,
			experience_years: 0,
		}));
		const updatedListingSkills = listing.skills.concat(newSkills);
		store.dispatch(AssessmentActions.updateListingSkills(updatedListingSkills));
		setPreselectedSkillIds([]);
		if (bonusSkills.length > 0) {
			store.dispatch(AssessmentActions.setCanProceed(true));
		}
	};

	const handleSelectSkill = skill => {
		const updatedSkills = listing.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...s, [skillStoryName]: !s[skillStoryName] } : s,
		);
		store.dispatch(AssessmentActions.updateListingSkills(updatedSkills));
	};

	const nextStep = ev => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		ListingsAPI.addListingSkills(listingID, listing.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateListingSkills(response.data.data));
			ListingsAPI.markAssessmentComplete(listingID, res => {
				if (!res) {
					SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
					store.dispatch(AssessmentActions.setIsSaving(false));
				}
				setJobProfile({
					...listing,
					...res.data.data,
				});
				getJobDescriptions();
				store.dispatch(AssessmentActions.resetAssessment());
				store.dispatch(AssessmentActions.setIsSaving(false));
				store.dispatch(SiteActions.hideModal());
			});
		});
	};

	useEffect(() => {
		if (!validSkills.length) {
			ListingsAPI.getValidSkills(listingID, response => {
				if (response) setValidSkills(response.data.data);
			});
		}
	}, []);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">4 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Let&rsquo;s further refine the job skills into categories that will help generate the most precise candidate
				matches - <strong>Bonus</strong> skills.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<LightningIcon className="teal-100" size={26} style={{ marginTop: 3, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Bonus Skills
						</Typography>
						<Typography>What skills, in or outside of the role scope, do you view as bonus skills?</Typography>
						<VerticalSpacer height={1} />
						{listing?.skills
							?.filter(s => s.skill_experience_id)
							?.filter(s => !(s.required_skill || s.preferred_skill))
							?.map(skill => (
								<Chip
									key={skill.skill_id}
									label={skill.skill.label}
									color={bonusSkills.find(s => s.skill_id === skill.skill_id) ? 'primary' : 'default'}
									onClick={() => handleSelectSkill(skill)}
								/>
							))}
						<VerticalSpacer height={4} />
						<LabeledField label="Add Skills">
							<Grid container spacing={2}>
								<Grid item xs={7}>
									<ElasticSkillDropdown
										filterListingId={listingID}
										tags
										variant="outlined"
										color="primary"
										name="skills_expert"
										value={preSelectedSkillIds}
										// override={skillOptions}
										filter={skillId => {
											return !listing.skills.find(s => s.skill_id === skillId);
										}}
										onChange={(e, skillCache) => {
											setPreselectedSkillIds(e.target.value);
											setPreselectedSkills(e.target.value?.map(x => skillCache[x]) || []);
										}}
										fullWidth
										placeholder="Type to find skills to add"
									/>
								</Grid>
								<Grid item xs={2}>
									<Button
										variant="contained"
										color="primary"
										className="no-margins"
										disabled={!preSelectedSkillIds.length}
										onClick={() => addPreselectedSkills()}
									>
										Add
									</Button>
								</Grid>
							</Grid>
						</LabeledField>
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={() => prevStep()}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Save Profile
				</LoadingButton>
			</Fence>
		</>
	);
};

BonusSkillsForm.propTypes = {
	prevStep: PropTypes.func,
	setJobProfile: PropTypes.func,
	getJobDescriptions: PropTypes.func,
};

BonusSkillsForm.defaultProps = {
	prevStep: () => null,
	setJobProfile: () => null,
	getJobDescriptions: () => null,
};

export default BonusSkillsForm;

import React, { useState, useEffect } from 'react';
import { Button, Typography, TextField, CircularProgress, Grid } from '@material-ui/core';
import * as Utility from '../../scripts/utility';

const ExpandableTypography = ({ text, numCharacters = 150 }) => {
	const [expanded, setExpanded] = useState(false);
	if (!text) return null;
	return (
		<Typography
			style={{
				whiteSpace: 'pre-wrap',
			}}
			variant="body2"
			// style={{
			// 	...(companySummaryExpanded
			// 		? {}
			// 		: {
			// 				overflow: 'hidden',
			// 				display: '-webkit-box',
			// 				WebkitLineClamp: 2,
			// 				WebkitBoxOrient: 'vertical',
			// 		  }),
			// }}
		>
			{(text.length > numCharacters ? (
				<>
					{expanded ? text : <>{text.slice(0, numCharacters)}...</>}{' '}
					<Typography
						variant="body2"
						style={{
							color: 'var(--blue-600)',
							cursor: 'pointer',
							display: 'inline-block',
							paddingLeft: 5,
							textDecoration: 'underline',
						}}
						onClick={() => setExpanded(!expanded)}
					>
						View {expanded ? 'Less' : 'More'}
					</Typography>
				</>
			) : (
				text
			)) || Utility.nb}
		</Typography>
	);
};

export default ExpandableTypography;

import React, { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import { useStore, useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Grid, Typography, Button, Box, Chip, IconButton, ButtonBase } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';
import { useLocation, useParams, useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
import { ActionCreators as SiteActions } from '../../store/Site';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import * as CandidatesAPI from '../../scripts/candidates';
import * as Utility from '../../scripts/utility';
import * as ListingsAPI from '../../scripts/listings';
import { listings as mockListings } from '../../scripts/mock';
import * as UserAPI from '../../scripts/users';
import * as AuthenticationAPI from '../../scripts/authentication';

import { useContextStore } from '../../store/ContextStore';
import useCurrentUser from '../../hooks/useCurrentUser';

const CandidateStatusChip = ({ candidate }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	console.log({ data, candidate });

	const chip =
		candidate?.status_type?.name === 'active'
			? {
					label: 'Available to interview',
					color: 'primary',
			  }
			: {
					label: 'No longer available',
					color: 'gray',
			  };

	if (!candidate) {
		return null;
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<>
			{chip ? (
				<Chip
					label={chip.label}
					color={chip.color}
					className={`color-${chip.color}`}
					style={{ margin: 0, marginLeft: '1.5rem' }}
				/>
			) : null}
		</>
	);
};
export default CandidateStatusChip;

import React from 'react';
import { CircularProgress } from '@material-ui/core';

const PageLoading = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
			<CircularProgress />
		</div>
	);
};

export default PageLoading;

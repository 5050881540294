/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Reaction({ style, color = '#D2D7DE', size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 40 40"
			style={style}
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.66663 20.0003C1.66663 30.1255 9.87474 38.3337 20 38.3337C30.1252 38.3337 38.3333 30.1255 38.3333 20.0003C38.3333 9.8751 30.1252 1.66699 20 1.66699C9.87474 1.66699 1.66663 9.8751 1.66663 20.0003ZM35 20.0003C35 28.2846 28.2842 35.0003 20 35.0003C11.7157 35.0003 4.99996 28.2846 4.99996 20.0003C4.99996 11.7161 11.7157 5.00033 20 5.00033C28.2842 5.00033 35 11.7161 35 20.0003ZM20.0534 31.667C14.4576 31.667 11.344 28.7197 11.344 23.3337V21.667H28.5263L28.5724 23.2862C28.7263 28.6911 25.6672 31.667 20.0534 31.667ZM25.1459 25.0003C24.7543 27.3262 23.1815 28.3337 20.0534 28.3337C16.9271 28.3337 15.3029 27.3174 14.8279 25.0003H25.1459ZM23.3333 13.3337H28.3333V16.667H23.3333V13.3337ZM16.6666 13.3337H11.6666V16.667H16.6666V13.3337Z"
				fill={color}
			/>
		</svg>
	);
}

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function EmailCircleIcon({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			style={style}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g transform="translate(-177 -216)">
					<g transform="translate(177 216)">
						<circle cx="12" cy="12" r="12" fill="#52A2FF" />
						<g
							stroke="#FFF"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.25"
							transform="translate(6 6)"
						>
							<g transform="translate(0 1.333)">
								<path d="M10.667 9.309H1.333A1.333 1.333 0 010 7.975V1.333C0 .597.597 0 1.333 0h9.334C11.403 0 12 .597 12 1.333v6.642c0 .737-.597 1.334-1.333 1.334z" />
								<path d="M9.33333333 2.65446667L6 4.65446667 2.66666667 2.65446667" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Reaction({ style, color = '#D2D7DE', size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 40 40"
			style={style}
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 38.3337C9.87474 38.3337 1.66663 30.1255 1.66663 20.0003C1.66663 9.8751 9.87474 1.66699 20 1.66699C30.1252 1.66699 38.3333 9.8751 38.3333 20.0003C38.3333 30.1255 30.1252 38.3337 20 38.3337ZM20 35.0003C28.2842 35.0003 35 28.2846 35 20.0003C35 11.7161 28.2842 5.00033 20 5.00033C11.7157 5.00033 4.99996 11.7161 4.99996 20.0003C4.99996 28.2846 11.7157 35.0003 20 35.0003ZM13.3333 23.3337V26.667H26.6666V23.3337H13.3333ZM23.3333 18.3337V15.0003H28.3333V18.3337H23.3333ZM11.6666 15.0003V18.3337H16.6666V15.0003H11.6666Z"
				fill={color}
			/>
		</svg>
	);
}

/* eslint-disable prefer-const */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import LoadingButton from '../../../components/form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';

const EditCandidateBlockModal = ({
	candidate,
	setCandidate,
	title,
	icon,
	renderContents,
	overrideSaveCandidate,
	...props
}) => {
	const IconComponent = icon;

	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [updatedCandidate, setUpdatedCandidate] = useState(JSON.parse(JSON.stringify(candidate)));
	const states = useSelector(state => state.site.data.states, shallowEqual);

	const updateCandidate = ev => {
		const { name, value } = ev.target;
		if (name === 'work_location_preferences' && Boolean(value.length) && value.every(val => val === 1)) {
			const selectedState = states.find(state => state.name === candidate.state);
			if (selectedState) {
				setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, desired_locations: [selectedState.id] }));
			}
			setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, willing_to_relocate: '2' }));
		}
		if (name === 'education_type_id' && value === 6) {
			setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, field_of_study: '' }));
			setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, study_types: [] }));
		}
		if (name === 'education_type_id' && (value === 1 || value === 9 || value === 10)) {
			setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, study_types: [] }));
		}
		if (name === 'role_level_preferences' && value.length > 2) {
			// Ignore the update if more than 2 options are selected
			return;
		}
		if (name === 'timezones' && value.some(item => item === 0)) {
			setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, timezones: [1, 2, 3, 4, 5, 6] }));
			return;
		}
		if (name === 'business_size_experiences' && value.some(item => item === 0)) {
			setUpdatedCandidate(existingCandidate => ({
				...existingCandidate,
				business_size_experiences: [1, 2, 3, 4, 5, 6, 7, 8, 9],
			}));
			return;
		}
		if (name === 'business_size_preferences' && value.some(item => item === 0)) {
			setUpdatedCandidate(existingCandidate => ({
				...existingCandidate,
				business_size_preferences: [1, 2, 3, 4, 5, 6, 7, 8],
			}));
			return;
		}
		if (name === 'industry_preferences') {
			const preferenceIds = value.map(item => item.id);
			setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, industry_preferences: preferenceIds }));
		}
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedCandidate(existingCandidate => ({ ...existingCandidate, ...newValue }));
	};
	const saveCandidate = () => {
		if (overrideSaveCandidate) {
			overrideSaveCandidate({ setIsSaving, store, updatedCandidate, dispatch });
		} else {
			setIsSaving(true);
			const forgedOriginalCandidate = CandidatesAPI.getForgedCandidate(candidate);
			const forgedUpdatedCandidate = CandidatesAPI.getForgedCandidate(updatedCandidate);
			// TODO: refactor this
			let differencesToSubmit;
			differencesToSubmit = Utility.getEntityDifferences(forgedOriginalCandidate, forgedUpdatedCandidate);
			if (differencesToSubmit?.industry_preferences) {
				differencesToSubmit.industry_preferences = differencesToSubmit.industry_preferences.map(item => item.id);
			}
			CandidatesAPI.updateCandidate(candidate.id, differencesToSubmit, response => {
				if (!response) {
					setIsSaving(false);
					store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
					return;
				}
				const newCandidate = response.data.data;
				setCandidate(newCandidate);
				dispatch(SiteActions.hideModal());
				setIsSaving(false);
			});
		}
	};
	const Contents = renderContents;

	return (
		<Panel>
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<IconComponent size={26} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						{title}
					</Typography>
				</div>
			</PanelHeader>
			<PanelContent style={{ paddingBottom: 0, marginBottom: -8 }}>
				<Contents
					updateCandidate={updateCandidate}
					updatedCandidate={updatedCandidate}
					setUpdatedCandidate={setUpdatedCandidate}
				/>
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}
				<div className="text-right">
					<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveCandidate}>
						Save
					</LoadingButton>
				</div>
			</PanelContent>
		</Panel>
	);
};

EditCandidateBlockModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
	icon: PropTypes.element,
	title: PropTypes.string,
	children: PropTypes.node,
};

EditCandidateBlockModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
	icon: null,
	title: 'Edit Candidate',
	children: null,
};

export default EditCandidateBlockModal;

import React, { useState } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, TextField, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import BackArrow from '../../../components/page/BackArrow';
import LabeledField from '../../../components/form/LabeledField';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import LoadingButton from '../../../components/form/LoadingButton';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as SpecialtiesAPI from '../../../scripts/specialties';
import * as SiteAPI from '../../../scripts/site';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const SpecialtyAdd = () => {
	useDocumentTitle('Data Manager - Specialties');
	const store = useStore();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [specialty, setSpecialty] = useState({});
	const { alert } = useSelector(state => state.site, shallowEqual);

	const updateSpecialty = ev => {
		setSpecialty({ ...specialty, [ev.target.name]: ev.target.value });
	};

	const onSpecialtyAdd = (response, id) => {
		setIsLoading(false);

		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred creating the specialty. Please try again.', 'error'));
			return;
		}

		SiteAPI.updateSpecialties(store);
		history.push(`/admin/specialties`);
	};

	const addSpecialty = ev => {
		setIsLoading(true);
		SpecialtiesAPI.addSpecialty(specialty, onSpecialtyAdd);
	};

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to="/admin/specialties" />
					<Typography variant="h1">Add New Specialty</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<LoadingButton loading={isLoading} width={10} variant="contained" color="primary" onClick={addSpecialty}>
						Save Specialty
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Grid container spacing={8}>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom />
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<LabeledField label="Specialty Name">
								<TextField
									variant="outlined"
									name="label"
									value={specialty.label || ''}
									onChange={updateSpecialty}
									fullWidth
								/>
							</LabeledField>

							<LabeledField label="Category">
								<CategoryDropdown
									variant="outlined"
									name="category_id"
									value={specialty.category_id}
									onChange={updateSpecialty}
									fullWidth
								/>
							</LabeledField>

							<LabeledField label="Bullhorn ID">
								<TextField
									variant="outlined"
									name="vendor_specialty_id"
									value={specialty.vendor_specialty_id}
									onChange={updateSpecialty}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6} />
					</Grid>
				</Grid>
			</Grid>
		</Page>
	);
};

export default SpecialtyAdd;

import React from 'react';
import { Grid, Typography, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import ViewBlockWrapper from './ViewBlockWrapper';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditExperienceModal from './EditExperienceModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import VerticalSpacer from '../../../components/VerticalSpacer';

const Block = ({
	candidate,
	setCandidate,
	data,
	minimized,
	setMinimized,
	comparisonJob,
	disableEdit,
	displayMatchValidation,
	graphMatch,
}) => {
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={ExperienceIcon}
				title="Experience"
				secondaryTitle={
					graphMatch?.total_experience_score
						? `Score: ${Math.round(graphMatch?.total_experience_score * 100)}% (${Math.round(
								graphMatch?.completeness_score_experience * 100,
						  )}%)`
						: null
				}
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditExperienceModal candidate={candidate} setCandidate={setCandidate} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Work Experience
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Current Title" className="color-muted" removeMargin close>
							<Typography variant="body2">{candidate?.current_title || Utility.nb}</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Current Employer" className="color-muted" removeMargin close>
							<Typography variant="body2">{candidate?.current_employer || Utility.nb}</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Professional Experience" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'professional_experience_years',
								})}
							>
								<Typography variant="body2">
									{candidate?.professional_experience_years
										? `${candidate?.professional_experience_years} years`
										: Utility.na}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Employment Type" className="color-muted" removeMargin close>
							<Typography variant="body2">{candidate?.current_employment_type?.label || Utility.nb}</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Business Size Experience" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								column
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'business_size_experiences',
								})}
							>
								{candidate?.business_size_experiences?.length === 9 &&
								candidate?.business_size_experiences
									?.map(s => s.id)
									.sort()
									.every((value, index) => value === [1, 2, 3, 4, 5, 6, 7, 8, 9][index]) ? (
									<Typography>All</Typography>
								) : (
									candidate?.business_size_experiences?.map(({ id }) => (
										<Typography>{Utility.getItemLabel(data.businessSizeExperiences, id) || Utility.nb}</Typography>
									))
								)}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Industry Sector Experience" className="color-muted" removeMargin close>
							<VerticalSpacer height={0.5} />
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'industries',
								})}
							>
								{candidate?.industries?.map(({ id }) => {
									return <Chip key={id} label={Utility.getItemLabel(data.industries, id)} color="gray" />;
								})}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Leadership
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Leadership Level" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'leadership_role_id',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.leadershipExperienceRoles, candidate?.recent_leadership_role_id) ||
										Utility.na}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Current or Most Recent Leadership Title" className="color-muted" removeMargin close>
							<Typography variant="body2">{candidate?.leadership_title || Utility.nb}</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Leadership Experience" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'total_leadership_years',
								})}
							>
								<Typography variant="body2">
									{candidate?.total_leadership_years !== null && candidate?.total_leadership_years !== undefined
										? `${candidate?.total_leadership_years} year${candidate?.total_leadership_years === 1 ? '' : 's'}`
										: Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Leadership Time Allocation" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'leadership_allocation_type',
								})}
							>
								<Typography variant="body2">{candidate?.leadership_allocation_type?.label || Utility.nb}</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Education
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Highest Education Achieved" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{Utility.getItemLabel(data.educationTypes, candidate?.education_type_id) || Utility.nb}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Major(s) / Field of Study" className="color-muted" removeMargin close>
							<Typography variant="body2">{candidate?.field_of_study}</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Area(s) of Study" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								column
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'area_of_study',
								})}
							>
								{candidate?.study_types?.map(({ id }) => (
									<Typography variant="body2">{Utility.getItemLabel(data.studyTypes, id) || Utility.nb}</Typography>
								))}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function MinusIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.5" transform="translate(-179 -1579)">
					<g transform="translate(180 1553)">
						<g transform="translate(0 27) rotate(45 8 8)">
							<path d="M8 16a8 8 0 01-8-8 8 8 0 018-8 8 8 0 018 8 8 8 0 01-8 8z"/>
							<path d="M10.514 5.486L5.486 10.514"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
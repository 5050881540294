import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Checkbox,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TablePagination,
	TableSortLabel,
} from '@material-ui/core';
import MaterialTable from '@material-ui/core/Table';

import { ActionCreators as SiteActions } from '../store/Site';
import * as Utility from '../scripts/utility';

const Table = forwardRef(
	(
		{
			stickyStateKey,
			name,
			onUpdate,
			parameters,
			filters,
			format,
			headers,
			rows,
			checkbox,
			defaultOrderBy,
			defaultSortDirection,
			noPagination,
			resetStateKey,
		},
		ref,
	) => {
		const dispatch = useDispatch();
		const { reports } = useSelector(state => state.site, shallowEqual);
		const [isLoading, setIsLoading] = useState(true);
		const initialStateValue = {
			headers: headers || [],
			data: rows || [],
			page: Utility.getFromObj(reports, name, 'pagination', 'page') || 1,
			lastPage: Utility.getFromObj(reports, name, 'pagination', 'lastPage') || 1,
			rowsPerPage: Utility.getFromObj(reports, name, 'pagination', 'rowsPerPage') || 15,
			from: Utility.getFromObj(reports, name, 'pagination', 'from') || 0,
			to: Utility.getFromObj(reports, name, 'pagination', 'to') || 0,
			totalRows: Utility.getFromObj(reports, name, 'pagination', 'totalRows') || 0,
			orderBy: Utility.getFromObj(reports, name, 'pagination', 'orderBy') || defaultOrderBy || undefined,
			sortDirection: Utility.getFromObj(reports, name, 'pagination', 'sortDirection') || defaultSortDirection || 'asc',
			// isLoading: true,
			checkAll: false,
			rowsSelected: [],
		};
		const [state, setState] = stickyStateKey
			? Utility.useStickyState(initialStateValue, stickyStateKey)
			: useState(initialStateValue);

		const setTableData = request => {
			if (!request) {
				setIsLoading(false);
				// setState({ ...state, isLoading: false });
				return;
			}

			setState({
				...state,
				data: Utility.getFromObj(request, 'data', 'data') || {},
				page: Utility.getFromObj(request, 'data', 'meta', 'from')
					? Utility.getFromObj(request, 'data', 'meta', 'current_page') || 1
					: 1,
				lastPage: Utility.getFromObj(request, 'data', 'meta', 'last_page') || 1,
				rowsPerPage: Number(Utility.getFromObj(request, 'data', 'meta', 'per_page') || 15),
				from: Utility.getFromObj(request, 'data', 'meta', 'from') || 1,
				to:
					Utility.getFromObj(request, 'data', 'meta', 'to') ||
					Number(Utility.getFromObj(request, 'data', 'meta', 'per_page')) ||
					15,
				totalRows: Utility.getFromObj(request, 'data', 'meta', 'total') || 0,
				// isLoading: false,
			});
			setIsLoading(false);
		};

		const loadData = () => {
			console.log('loadData');

			if (typeof onUpdate !== 'function') return;

			// setState(state => ({ ...state, isLoading: true, rowsSelected: [] }));
			setIsLoading(true);

			const query = {
				per_page: state.rowsPerPage,
				page: state.page,
				orderBy: state.orderBy,
				direction: state.sortDirection,
				...filters,
			};

			onUpdate.apply(this, [...parameters, query, setTableData]);
		};

		useEffect(() => {
			if (resetStateKey) {
				setState(initialStateValue);
			}
		}, [resetStateKey]);

		const toggleAllCheckboxes = checked => {
			setState({
				...state,
				checkAll: checked,
				rowsSelected: checked ? (rows || state.data || []).map((row, i) => i) : [],
			});
		};

		const toggleRow = (row, checked) => {
			if (checked) {
				setState({ ...state, rowsSelected: [...state.rowsSelected, row] });
			} else if (state.rowsSelected.includes(row)) {
				const index = state.rowsSelected.indexOf(row);

				setState({
					...state,
					checkAll: false,
					rowsSelected: [...state.rowsSelected.slice(0, index), ...state.rowsSelected.slice(index + 1)],
				});
			}
		};

		const changePage = (ev, page) => {
			setState({ ...state, page: page + 1 });
		};

		const changeRowsPerPage = (ev, params) => {
			setState({ ...state, rowsPerPage: params.props.value });
		};

		const changeSortOrder = (ev, column) => {
			if (column === state.orderBy)
				setState({ ...state, sortDirection: state.sortDirection === 'asc' ? 'desc' : 'asc' });
			else setState({ ...state, orderBy: column });
		};

		const getSelectedRecords = () => {
			return (rows || state.data || []).filter((record, i) => state.rowsSelected.includes(i));
		};

		const updateReportState = () => {
			if (name) {
				dispatch(
					SiteActions.setReport(name, {
						filters,
						pagination: {
							page: state.page,
							lastPage: state.lastPage,
							rowsPerPage: state.rowsPerPage,
							from: state.from,
							to: state.to,
							totalRows: state.totalRows,
							orderBy: state.orderBy,
							sortDirection: state.sortDirection,
						},
					}),
				);
			}
		};

		useImperativeHandle(ref, () => ({
			refreshData: () => loadData(),
			getSelectedRecords,
			resetCheckboxes: () => setState({ ...state, rowsSelected: [] }),
		}));

		// Reload table on table change
		useEffect(loadData, [
			state?.onUpdate,
			state?.page,
			state?.rowsPerPage,
			state?.orderBy,
			state?.sortDirection,
			filters,
		]);

		// Update the report state
		useEffect(updateReportState, [filters, state]);

		// if( state.isLoading && typeof onUpdate === 'function' ) return (
		//   <center>
		//     <CircularProgress size={100} style={{marginTop:100, marginBottom:100}} />
		//   </center>
		// );

		return (
			<TableContainer style={{ overflowX: 'auto' }}>
				<MaterialTable className={`${isLoading ? 'table-loading' : ''} ${checkbox ? 'table-select' : ''}`}>
					<TableHead>
						<TableRow>
							{checkbox ? (
								<TableCell padding="checkbox">
									<Checkbox checked={state.checkAll} onChange={ev => toggleAllCheckboxes(ev.target.checked)} />
								</TableCell>
							) : null}
							{state.headers && state.headers.length
								? state.headers.map((header, i) => {
										return header.noSort ? (
											<TableCell key={header.id}>{header.value}</TableCell>
										) : (
											<TableCell
												key={header.id}
												sortDirection={state.orderBy === header.id ? state.sortDirection : false}
											>
												<TableSortLabel
													active={state.orderBy === header.id}
													direction={state.orderBy === header.id ? state.sortDirection : 'asc'}
													onClick={isLoading ? () => {} : ev => changeSortOrder(ev, header.id)}
												>
													{header.value}
												</TableSortLabel>
											</TableCell>
										);
								  })
								: null}
						</TableRow>
					</TableHead>
					<TableBody>
						{state.data && state.data.length
							? format(state.data).map((row, i) => (
									// eslint-disable-next-line react/no-array-index-key
									<TableRow key={i}>
										{checkbox ? (
											<TableCell padding="checkbox">
												<Checkbox
													checked={state.rowsSelected.includes(i)}
													onChange={ev => toggleRow(i, ev.target.checked)}
												/>
											</TableCell>
										) : null}
										{row && row.length
											? // eslint-disable-next-line react/no-array-index-key
											  row.map((cell, j) => <TableCell key={`${i}:${j}`}>{cell}</TableCell>)
											: null}
									</TableRow>
							  ))
							: null}
					</TableBody>
				</MaterialTable>
				{noPagination ? null : (
					<TablePagination
						rowsPerPageOptions={[15, 25, 50]}
						component="div"
						count={state.totalRows}
						rowsPerPage={state.rowsPerPage}
						page={state.page - 1}
						onChangePage={isLoading ? () => {} : changePage}
						onChangeRowsPerPage={isLoading ? () => {} : changeRowsPerPage}
					/>
				)}
			</TableContainer>
		);
	},
);

Table.propTypes = {
	name: PropTypes.string,
	parameters: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.func]),
	),
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			noSort: PropTypes.bool,
		}),
	).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	rows: PropTypes.arrayOf(PropTypes.object),
	filters: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
		]),
	),
	format: PropTypes.func.isRequired,
	checkbox: PropTypes.bool,
	defaultOrderBy: PropTypes.string,
	defaultSortDirection: PropTypes.string,
	noPagination: PropTypes.bool,
	onUpdate: PropTypes.func.isRequired,
};

Table.defaultProps = {
	name: '',
	parameters: [],
	rows: undefined,
	filters: {},
	checkbox: false,
	defaultOrderBy: '',
	defaultSortDirection: 'asc',
	noPagination: false,
};

export default Table;

import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import LightbulbIcon from '../../../components/icons/SectionIcons/LightbulbIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditModal from './EditProductsServicesBlock';

import ExpandableTypography from '../../../components/form/ExpandableTypography';

const Block = ({ company, setCompany, disableEdit }) => {
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={LightbulbIcon}
				title="Products & Services"
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditModal company={company} setCompany={setCompany} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<LabeledField label="" className="color-muted" removeMargin close>
							<ExpandableTypography numCharacters={450} text={company?.products_services || 'N/A'} />
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

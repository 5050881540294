import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';
import WorkVisaDropdown from '../../../components/form/dropdowns/WorkVisaDropdown';
import USWorkAuthorizationDropdown from '../../../components/form/dropdowns/USWorkAuthorizationDropdown';
import CheckmarkIcon from '../../../components/icons/CheckmarkIcon';

const EditWorkAuthorizationModal = ({ candidate, setCandidate }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

	const updateCandidate = ev => {
		const newValue = {
			[ev.target.name]: ev.target.value,
		};
		setUpdatedCandidate({
			...updatedCandidate,
			...newValue,
		});
	};

	const saveCandidate = () => {
		setIsSaving(true);
		const forgedCandidate = CandidatesAPI.getForgedCandidate(updatedCandidate);
		const forgedCurrentCandidate = CandidatesAPI.getForgedCandidate(candidate);

		const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentCandidate, forgedCandidate);

		CandidatesAPI.updateCandidate(candidate.id, differencesToSubmit, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const newCandidate = response.data.data;
			setCandidate(newCandidate);
			dispatch(SiteActions.hideModal());
		});
	};

	return (
		<Panel>
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<CheckmarkIcon size={26} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						US Work Authorization
					</Typography>
				</div>
			</PanelHeader>
			<PanelContent>
				<Grid container spacing={2} style={{ marginBottom: 40 }}>
					<Grid item xs={6} style={{ marginTop: 4 }}>
						<LabeledField label="Work Visa Sponsorship" removeMargin>
							<WorkVisaDropdown
								variant="outlined"
								name="work_visa_type_id"
								value={updatedCandidate?.work_visa_type_id}
								onChange={updateCandidate}
								placeholder="Choose from our list"
								error={!updatedCandidate.work_visa_type_id}
								fullWidth
							/>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField
							label="Legally authorized to work in the United States?"
							info="Are you currently legally authorized to work in the United States?"
						>
							<USWorkAuthorizationDropdown
								variant="outlined"
								name="us_work_authorization"
								value={
									updatedCandidate.us_work_authorization === null ||
									updatedCandidate.us_work_authorization === undefined
										? null
										: updatedCandidate.us_work_authorization === true
										? '1'
										: '2'
								}
								onChange={ev => {
									setUpdatedCandidate(prevCandidate => ({
										...prevCandidate,
										us_work_authorization: ev.target.value === '1',
									}));
								}}
								placeholder="Choose from our list"
								error={updatedCandidate.us_work_authorization == null}
								fullWidth
							/>
						</LabeledField>
					</Grid>
				</Grid>
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}
				<div className="text-right">
					<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} variant="contained" color="primary" value={2} onClick={saveCandidate}>
						Save
					</LoadingButton>
				</div>
			</PanelContent>
		</Panel>
	);
};

EditWorkAuthorizationModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditWorkAuthorizationModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditWorkAuthorizationModal;

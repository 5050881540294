import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import AreasOfStudyDropdown from '../../../components/form/dropdowns/AreasOfStudyDropdown';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import EducationDropdown from '../../../components/form/dropdowns/EducationDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';

const EditExperienceModal = ({ listing, setListing }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [updatedListing, setUpdatedListing] = useState(listing);
	const { data } = useSelector(state => state.site, shallowEqual);

	const updateListing = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		if (ev.target.name === 'recent_leadership_role_id' && (ev.target.value === 1 || ev.target.value === null)) {
			newValue.total_leadership_years = 0;
			newValue.leadership_title = '';
		}

		setUpdatedListing({ ...updatedListing, ...newValue });
	};

	const saveListing = () => {
		setIsSaving(true);
		const forgedListing = ListingsAPI.getForgedListing(updatedListing);
		const forgedCurrentListing = ListingsAPI.getForgedListing(listing);
		const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentListing, forgedListing);

		ListingsAPI.updateListing(listing.id, differencesToSubmit, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const newListing = response.data.data;
			setListing(newListing);
			dispatch(SiteActions.hideModal());
		});
	};

	return (
		<>
			<Panel Icon={<ExperienceIcon size={32} className="teal-100" />}>
				<PanelHeader className="spread">
					<Typography variant="body2" component="h2" className="text-bold" style={{ paddingTop: 4 }}>
						Preferred Experience
					</Typography>
				</PanelHeader>
				<PanelContent>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<LabeledField label="Professional Experience (Years)" required>
								<YearsDropdown
									variant="outlined"
									name="professional_experience_years"
									value={updatedListing.professional_experience_years}
									onChange={updateListing}
									error={!updatedListing.professional_experience_years}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField>

							<LabeledField label="Leadership Scope">
								<Dropdown
									variant="outlined"
									name="recent_leadership_role_id"
									value={updatedListing.recent_leadership_role_id}
									data={data.leadershipExperienceRoles}
									onChange={updateListing}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Leadership Experience">
								<YearsDropdown
									variant="outlined"
									name="total_leadership_years"
									value={updatedListing.total_leadership_years}
									disabled={
										updatedListing.recent_leadership_role_id === 1 || updatedListing?.recent_leadership_role_id === null
									}
									onChange={updateListing}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField>
							<LabeledField label="Education" required>
								<EducationDropdown
									variant="outlined"
									name="education_type_id"
									value={updatedListing.education_type_id}
									onChange={updateListing}
									fullWidth
									placeholder="Choose from our list"
									error={!updatedListing.education_type_id}
								/>
							</LabeledField>
							<LabeledField label="Preferred Area(s) of Study" required>
								<AreasOfStudyDropdown
									variant="outlined"
									name="study_types"
									value={updatedListing?.study_types?.map(t => (t.id ? t.id : t))}
									onChange={updateListing}
									fullWidth
									placeholder={updatedListing?.study_types?.length > 0 ? '' : 'Select all that apply'}
									multiple
									error={!updatedListing?.study_types?.length > 0}
								/>
							</LabeledField>
						</Grid>

						<Grid item xs={12}>
							<div style={{ marginTop: -10 }} />
							<AddAdditionalInfo
								additionalInfo={updatedListing.additional_experience_details}
								setAdditionalInfo={newAdditionalInfo => {
									updateListing({
										target: {
											name: 'additional_experience_details',
											value: newAdditionalInfo,
										},
									});
								}}
							/>
						</Grid>
					</Grid>
					<div className="text-right">
						<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
							Cancel
						</Button>
						<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveListing}>
							Save
						</LoadingButton>
					</div>
				</PanelContent>
			</Panel>
		</>
	);
};

EditExperienceModal.propTypes = {
	listing: PropTypes.shape({ id: PropTypes.string }),
	setListing: PropTypes.func,
};

EditExperienceModal.defaultProps = {
	listing: { id: null },
	setListing: () => null,
};

export default EditExperienceModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, TextField, Typography, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LoadingButton from '../../form/LoadingButton';
import Fence from '../../Fence';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as SkillsAPI from '../../../scripts/skills';

const SuggestSkillForm = ({ onSubmit, onClose, candidateID, listingID, ...props }) => {
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const [suggestion, setSuggestion] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);
	const onSuggest = response => {
		dispatch(SiteActions.hideAlert());
		setLoading(false);
		if (!response) {
			dispatch(SiteActions.showAlert('An error ocurred submitting your suggestion. Please try again.', 'error'));
			return;
		}
		dispatch(SiteActions.hideAlert());
		setShowSuccess(true);
		if (typeof onSubmit === 'function') onSubmit();
	};

	const suggest = () => {
		setLoading(true);
		SkillsAPI.suggestSkills({ body: suggestion, listingID, candidateID }, onSuggest);
	};

	return (
		<Fence
			width={32}
			style={{
				background: '#E6F1FF',
				borderRadius: 6,
				padding: '46px 30px 28px 30px',
				position: 'relative',
			}}
		>
			<IconButton
				className="modal-close modal-close-primary"
				onClick={onClose}
				style={{ marginTop: '-15px', marginRight: '-15px' }}
			>
				<Icons.Close />
			</IconButton>
			{showSuccess ? (
				<div className="text-center">
					<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
						Successful Submission
					</Typography>
					<Typography variant="body2" style={{ marginBottom: 32 }} align="left">
						We appreciate your input! Your skill suggestions will be reviewed. Any skills we add to our database based
						on your suggestion will be posted to your skills profile.
					</Typography>
				</div>
			) : (
				<>
					<Typography variant="body2">Skill Suggestions</Typography>
					<Typography variant="body1" style={{ marginBottom: 32 }}>
						You can suggest skills for us to add to our skills database. Simply enter skills below separated by commas
						and click submit.
					</Typography>
					<Box>
						<TextField
							variant="outlined"
							name="label"
							value={suggestion}
							rows={2}
							onChange={ev => setSuggestion(ev.target.value)}
							multiline
							fullWidth
						/>
					</Box>
					<Fence className="text-right">
						<LoadingButton
							loading={isLoading}
							variant="text"
							color="primary"
							onClick={suggest}
							style={{ margin: 0, padding: '10px 0' }}
						>
							SUBMIT
						</LoadingButton>
					</Fence>
				</>
			)}
		</Fence>
	);
};

SuggestSkillForm.propTypes = {
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	candidateID: PropTypes.string,
	listingID: PropTypes.string,
};

SuggestSkillForm.defaultProps = {
	onSubmit: () => null,
	onClose: () => null,
	candidateID: null,
	listingID: null,
};

export default SuggestSkillForm;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_325_11194)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.02959 1.58691H16.0136C16.8104 1.58691 17.4563 2.37636 17.4563 3.35018L17.4563 8.72814V10.3151L17.4563 15.693C17.4563 16.6669 16.8104 17.4563 16.0136 17.4563H3.02959C2.23282 17.4563 1.58691 16.6669 1.58691 15.693V3.35018C1.58691 2.37636 2.23282 1.58691 3.02959 1.58691ZM15.8694 8.72814V3.17385H3.17385V8.72814H5.88293L7.66702 10.5122L10.1264 4.7737L13.0922 8.72814H15.8694ZM3.17385 10.3151H5.2256L8.20232 13.2918L10.5038 7.92176L12.2988 10.3151H15.8694V15.8694H3.17385V10.3151Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_325_11194">
					<rect width="17.9231" height="18.64" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

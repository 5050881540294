import React from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import * as Utility from '../../../../../scripts/utility';
import SpecialtyCategoryDropdown from '../../../../form/dropdowns/SpecialtyCategorySearchDropdown';
import EmploymentPreferencesDropdown from '../../../../form/dropdowns/EmploymentPreferencesDropdown';
import SalaryDropdown from '../../../../form/dropdowns/SalaryDropdown';
import RoleLevelPreferencesDropdown from '../../../../form/dropdowns/RoleLevelPreferencersDropdown';
import LeadershipRolesAllocationDropdown from '../../../../form/dropdowns/LeadershipRolesAllocationPreferenceDropdown';

const RoleForm = ({ candidate, setCandidate }) => {
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Role Preferences
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Enter your desired role and select the work domain(s), industries, types of roles, and role levels you are
				interested in.
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25.5}>
				<LabeledField
					label="Desired Role"
					removeMargin
					info="Enter the role title you desire"
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<TextField
						variant="outlined"
						name="desired_position"
						value={Utility.getFromObj(candidate, 'desired_position')}
						onChange={setCandidate}
						placeholder="Enter Position Title (e.g. Data Engineer)"
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<Grid item xs={9}>
				<SpecialtyCategoryDropdown onChange={setCandidate} model={candidate} />
			</Grid>
			<VerticalSpacer height={1} />
			<Fence width={25.5}>
				<LabeledField label="Role Type(s)" removeMargin labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<EmploymentPreferencesDropdown
						variant="outlined"
						name="employment_preferences"
						value={candidate?.employment_preferences?.map(item => (item.id ? item.id : item))}
						onChange={setCandidate}
						placeholder={candidate?.employment_preferences?.length > 0 ? '' : 'Select all that apply'}
						fullWidth
						multiple
					/>
				</LabeledField>
				<VerticalSpacer height={2.5} />
				<LabeledField
					label="Role Levels"
					info="What level of roles would you like to see?"
					removeMargin
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<RoleLevelPreferencesDropdown
						variant="outlined"
						name="role_level_preferences"
						value={candidate?.role_level_preferences?.map(item => (item.id ? item.id : item))}
						onChange={setCandidate}
						placeholder={candidate?.role_level_preferences?.length > 0 ? '' : 'Select all that apply (max 2)'}
						fullWidth
						multiple
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<Grid item xs={9}>
				<LeadershipRolesAllocationDropdown model={candidate} onChange={setCandidate} />
			</Grid>
			<VerticalSpacer height={1} />
			<Fence width={25.5}>
				<LabeledField
					label="Minimum Salary Expectations"
					info="If you're unsure, we recommend choosing a
		 			lower amount so you don't miss out on roles that might be great."
					removeMargin
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<SalaryDropdown
						variant="outlined"
						name="salary_expectation_type_id"
						value={candidate.salary_expectation_type_id}
						onChange={setCandidate}
						placeholder="Choose from our list"
						fullWidth
					/>
				</LabeledField>
			</Fence>
		</>
	);
};

export default RoleForm;

import React from 'react';
import PropTypes from 'prop-types';

const HorizontalSpacer = ({ width, ...props }) => {
	return <div className="horizontal-divider" style={{ width: `${width || 1}rem`, display: 'inline-block' }} />;
};

HorizontalSpacer.propTypes = {
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
HorizontalSpacer.defaultProps = {
	width: null
}

export default HorizontalSpacer;
import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import {
	MenuItem,
	MenuList,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	Chip,
	Switch,
	Typography,
	Box,
} from '@material-ui/core';
import HorizontalSpacer from '../HorizontalSpacer';
import Logo from '../../img/Logo';
import AvatarFilledIcon from '../icons/AvatarFilledIcon';
import ContextualMenu from './ContextualMenu';
import * as AuthenticationAPI from '../../scripts/authentication';
import { isStagingAPI } from '../../scripts/api';
import { useContextStore } from '../../store/ContextStore';

const Topbar = ({ context }) => {
	const { user } = useSelector(state => state.site, shallowEqual);
	const { role, setRole, enableDynamicFilters, setEnableDynamicFilters } = useContextStore();
	const [open, setOpen] = useState(false);
	const prevOpen = useRef(open);
	const anchorRef = useRef(null);

	const isAdmin = user?.roles?.find(x => x.name === 'admin');

	const handleToggle = () => {
		setOpen(isOpen => !isOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = event => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<div className="topbar">
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div className="brand">
					<Logo />
				</div>
				{window?.location?.host?.startsWith('portal.nauwork.net') ? null : (
					<Chip
						size="small"
						label={`${window.location.origin.includes('localhost') ? 'Local' : 'Deployed'} | ${
							isStagingAPI ? 'Staging' : 'Prod'
						}`}
						color="primary"
						style={{
							color: '#fff',
							margin: 0,
							marginLeft: 10,
							marginBottom: 4,
							backgroundColor: 'rgba(210,210,255,0.2)',
							padding: '0px 2px',
						}}
					/>
				)}
			</div>
			<div className="topbar-menu-right">
				<ContextualMenu />
				<div
					className="user-menu"
					ref={anchorRef}
					aria-controls={open ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					onKeyPress={handleToggle}
					role="button"
					tabIndex={0}
				>
					<span className="username">{user?.name || ''}</span>
					<HorizontalSpacer />
					<AvatarFilledIcon size="35" />
				</div>
				<Popper open={open} anchorEl={anchorRef.current} role={undefined} disablePortal style={{ zIndex: 1 }}>
					{({ TransitionProps, placement }) => (
						<Box style={{ position: 'absolute', top: 5, right: -30 }}>
							<Paper color="primary" style={{ width: 230 }}>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
										{isAdmin ? (
											<>
												<MenuItem onClick={() => {}} style={{ width: 190 }}>
													<div
														style={{
															width: 200,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
														}}
													>
														<Typography variant="body2">
															View As: {role === 'recruiter' ? 'Recruiter' : 'AE'}
														</Typography>
														<Switch
															color="primary"
															checked={role === 'recruiter'}
															onChange={e => setRole(x => (x === 'recruiter' ? 'account-executive' : 'recruiter'))}
														/>
													</div>
												</MenuItem>
												<MenuItem onClick={() => {}} style={{ width: 190 }}>
													<div
														style={{
															width: 200,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
														}}
													>
														<Typography variant="body2">Dynamic Filters</Typography>
														<Switch
															color="primary"
															checked={enableDynamicFilters}
															onChange={e => setEnableDynamicFilters(x => !x)}
														/>
													</div>
												</MenuItem>
											</>
										) : null}
										<MenuItem onClick={AuthenticationAPI.logout}>
											<Typography variant="body2">Logout</Typography>
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Box>
					)}
				</Popper>
			</div>
		</div>
	);
};

Topbar.propTypes = {
	context: PropTypes.string,
};

Topbar.defaultProps = {
	context: '',
};

export default Topbar;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import {
	TableContainer,
	Typography,
	Table,
	TableCell,
	TableBody,
	TablePagination,
	TableHead,
	TableRow,
	CircularProgress,
	makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { Panel, PanelHeader, PanelContent } from '../../Panel';
import getAuditLog from '../../../scripts/audit';

import AuditEmptyView from './AuditEmptyView';
import AuditTableRow from './AuditTableRow';
import { AuditDateDropdown, AuditFieldsDropdown, AuditUserDropdown, FromColumn, ToColumn } from './AuditTableFilters';
import { FIELD_SPACING } from './utilities';

const styles = makeStyles(() => ({
	loading: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: FIELD_SPACING,
	},
	dropdownOption: {
		display: 'block',
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 14,
		fontWeight: 500,
	},
	panelTitleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	panelTitle: {
		paddingLeft: 10,
	},
	panelContent: {
		paddingBottom: 0,
		marginBottom: -8,
	},
	panelCloseIcon: {
		cursor: 'pointer',
		color: 'var(--secondaryColor)',
		fontSize: 30,
	},
	fromToHeader: {
		verticalAlign: 'top',
		paddingTop: 3,
		height: '52px',
		color: '#969696',
		fontWeight: 300,
	},
	resizeHandle: {
		cursor: 'col-resize',
		padding: '0 3px',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
	},
}));

const AuditLogModal = ({ model, closeModalCallback }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	// State for the audit log
	const [isInitLoading, setInitIsLoading] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [auditLog, setAuditLog] = useState([]);

	const [resultsPerPage, setResultsPerPage] = useState(15);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [totalItems, setTotalItems] = useState(0);

	const [usersWhoHaveEdited, setUsersWhoHaveEdited] = useState([]);
	const [selectedFilterUser, setSelectedFilterUser] = useState('');

	const [selectedFilterDate, setSelectedFilterDate] = useState('');

	const [possibleFields, setPossibleFields] = useState([]);
	const [selectedFieldFilter, setSelectedFieldFilter] = useState([]);

	const [selectedSortDirection, setSelectedSortDirection] = useState();
	const [selectedSortColumn, setSelectedSortColumn] = useState();

	const [columnWidths, setColumnWidths] = useState({
		date: 95,
		user: 120,
		field: 150,
		from: 250,
		to: 250,
	});

	const handleResize = (columnName, newWidth) => {
		setColumnWidths(prevWidths => ({
			...prevWidths,
			[columnName]: newWidth,
		}));
	};

	/**
	 * Calls the API to fetch audit logs.
	 *
	 * @param {Object} params Object containing all parameters for the API call.
	 * @param {number} params.page The current page number to fetch.
	 * @param {number|null} params.resultCount Number of results per page.
	 * @param {number|null} params.userId The ID of the user to filter by, or null for no filter.
	 * @param {string|null} params.dateFilter The date filter criteria, or null for no filter.
	 * @param {string[]|null} params.fieldFilter The field filter criteria
	 * @param {string} params.sortColumn The column to sort by
	 * @param {string} params.sortDirection The direction to sort by
	 */
	function handleCallApi({ page, resultCount, userId, dateFilter, fieldFilter, sortColumn, sortDirection }) {
		const validPage = Math.max(page, 1);

		setIsLoading(true);
		getAuditLog({
			id: model.id,
			modelName: 'candidate',
			page: validPage,
			resultsPerPage: resultCount,
			userId,
			dateFilter,
			fieldFilter,
			sortColumn,
			sortDirection,
			callback: response => {
				if (!response || response === false) {
					setIsLoading(false);
					setInitIsLoading(false);
					return;
				}
				setAuditLog(response.data.audit_logs.data);
				setUsersWhoHaveEdited(response.data.users);
				setPossibleFields(response.data.field_names);

				setCurrentPage(response.data.audit_logs.current_page || 1);
				setTotalPages(response.data.audit_logs.last_page || 1);
				setTotalItems(response.data.audit_logs.total || 1);
				setIsLoading(false);
				setInitIsLoading(false);
			},
		});
	}

	// Fetch the audit log on initial render
	useEffect(() => {
		handleCallApi({
			page: 1,
			resultCount: resultsPerPage,
		});
	}, [model]);

	const classes = styles();

	/** Generic loading spinner with full width container */
	const loadingView = (
		<div className={classes.loading}>
			<CircularProgress />
		</div>
	);

	/** Panel header with close button */
	const panelHeader = (
		<PanelHeader className="spread">
			<div className={classes.panelTitleContainer}>
				<Typography variant="h2" className={classes.panelTitle}>
					Audit log for {model.first_name} {model.last_name}
				</Typography>
			</div>
			<CloseIcon onClick={closeModalCallback} className={classes.panelCloseIcon} />
		</PanelHeader>
	);

	/**
	 * View to display when fetching results.
	 * If no results are found, an empty view is displayed that allows the user to reset filters.
	 */
	const fetchingResultsView = isLoading ? (
		loadingView
	) : auditLog.length === 0 ? (
		<AuditEmptyView
			displayResetFilters={selectedFilterUser !== '' || selectedFilterDate !== '' || selectedFieldFilter.length > 0}
			handleCallApi={() => {
				// reset the state and refetch data
				setSelectedFilterUser('');
				setSelectedFilterDate('');
				setSelectedFieldFilter([]);
				handleCallApi({
					page: 1,
					resultCount: resultsPerPage,
				});
			}}
		/>
	) : null;

	/*
	 *
	 * TABLE COMPONENTS
	 *
	 */

	const tableHead = (
		<TableHead>
			<TableRow>
				<AuditDateDropdown
					selectedFilterDate={selectedFilterDate}
					setSelectedFilterDate={setSelectedFilterDate}
					handleCallApi={value =>
						handleCallApi({
							page: 1,
							resultCount: resultsPerPage,
							userId: selectedFilterUser,
							dateFilter: value === 'All' ? undefined : value,
							fieldFilter: selectedFieldFilter,
							sortColumn: selectedSortColumn,
							sortDirection: selectedSortDirection,
						})
					}
					isSorted={selectedSortColumn === 'created_at'}
					sortDirection={selectedSortDirection}
					isSearching={isLoading}
					handleSetSortDirection={direction => {
						setSelectedSortDirection(direction);
						setSelectedSortColumn('created_at');
						handleCallApi({
							sortColumn: 'created_at',
							sortDirection: direction,
							page: 1,
							resultCount: resultsPerPage,
							userId: selectedFilterUser,
							dateFilter: selectedFilterDate,
							fieldFilter: selectedFieldFilter,
						});
					}}
					resultsPerPage={resultsPerPage}
					selectedFilterUser={selectedFilterUser}
					selectedFieldFilter={selectedFieldFilter}
					handleResize={handleResize}
					colWidth={columnWidths.date}
				/>
				<AuditUserDropdown
					usersWhoHaveEdited={usersWhoHaveEdited}
					selectedFilterUser={selectedFilterUser}
					handleResize={handleResize}
					colWidth={columnWidths.user}
					setSelectedFilterUser={setSelectedFilterUser}
					handleCallApi={value =>
						handleCallApi({
							page: 1,
							resultCount: resultsPerPage,
							userId: value === 'All' ? undefined : value,
							dateFilter: selectedFilterDate,
							fieldFilter: selectedFieldFilter,
							sortColumn: selectedSortColumn,
							sortDirection: selectedSortDirection,
						})
					}
				/>
				<AuditFieldsDropdown
					selectedFieldFilter={selectedFieldFilter}
					setSelectedFieldFilter={setSelectedFieldFilter}
					handleResize={handleResize}
					colWidth={columnWidths.field}
					handleCallApi={value =>
						handleCallApi({
							page: 1,
							resultCount: resultsPerPage,
							userId: selectedFilterUser,
							dateFilter: selectedFilterDate,
							fieldFilter: value,
							sortColumn: selectedSortColumn,
							sortDirection: selectedSortDirection,
						})
					}
					possibleFields={possibleFields}
				/>
				<FromColumn handleResize={handleResize} colWidth={columnWidths.from} />
				<ToColumn handleResize={handleResize} colWidth={columnWidths.to} />
			</TableRow>
		</TableHead>
	);

	const tableBody = !isLoading && auditLog?.length > 0 && (
		<TableBody>
			{auditLog?.map((log, index) => (
				<AuditTableRow
					log={log}
					key={`${index + 1}`}
					rowIndex={index}
					data={data}
					selectedFieldFilter={selectedFieldFilter}
					widths={columnWidths}
				/>
			))}
		</TableBody>
	);

	const tablePagination = (
		<TablePagination
			rowsPerPageOptions={[5, 10, 15, 25, 50]}
			component="div"
			count={totalItems}
			rowsPerPage={resultsPerPage}
			totalPages={totalPages}
			page={currentPage - 1}
			onPageChange={(_, page) => {
				// Adjust for zero-based indexing and prevent navigation to "page 0"
				const adjustedPage = page + 1;
				handleCallApi({
					page: Math.max(adjustedPage, 1),
					resultCount: resultsPerPage,
					userId: selectedFilterUser,
					dateFilter: selectedFilterDate,
					fieldFilter: selectedFieldFilter,
				});
			}}
			onRowsPerPageChange={e => {
				setResultsPerPage(e.target.value);
				handleCallApi({
					page: 1,
					resultCount: e.target.value,
					userId: selectedFilterUser,
					dateFilter: selectedFilterDate,
					fieldFilter: selectedFieldFilter,
				});
			}}
		/>
	);

	return (
		<Panel>
			{panelHeader}
			<PanelContent className={classes.panelContent}>
				{isInitLoading ? (
					loadingView
				) : (
					<TableContainer>
						{isLoading ? (
							fetchingResultsView
						) : (
							<Table>
								{tableHead}
								{tableBody}
							</Table>
						)}
						{tablePagination}
					</TableContainer>
				)}
			</PanelContent>
		</Panel>
	);
};

AuditLogModal.propTypes = {
	model: PropTypes.number.isRequired,
	closeModalCallback: PropTypes.func.isRequired,
};

export default AuditLogModal;

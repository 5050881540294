import React from 'react';
import PropTypes from 'prop-types';

const VerticalSpacer = ({ height, ...props }) => {
	return <div className="vertical-divider" style={{ height: `${height || 1.5}rem` }} />;
};

VerticalSpacer.propTypes = {
	height: PropTypes.number,
};

VerticalSpacer.defaultProps = {
	height: 1.5,
};

export default VerticalSpacer;

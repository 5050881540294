/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function ImproveIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20	20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-803 -666)">
					<g transform="translate(804 667)">
						<path d="M3.276 11.467l3.87-3.87a1 1 0 011.414 0l2.141 2.142a1 1 0 001.414 0l5.96-5.96"/>
						<path d="M15.4083 3.7787L18.0753 3.7787 18.0753 6.4457"/>
						<path d="M18.075 17.708L8.6038468e-14 17.708 8.6038468e-14 0"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_325_11204)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 11.9362V13.5C12 15.1569 10.6569 16.5 9 16.5C7.34315 16.5 6 15.1569 6 13.5V11.9362C6 11.7898 5.94812 11.63 5.8256 11.4196C5.74687 11.2845 5.64388 11.136 5.4885 10.9305L5.43623 10.8623L5.43617 10.8622C5.33239 10.7269 5.10587 10.4317 5.02223 10.3187C4.17483 9.17417 3.75 8.1524 3.75 6.75C3.75 3.8505 6.10051 1.5 9 1.5C11.8995 1.5 14.25 3.8505 14.25 6.75C14.25 8.18307 13.8194 9.24362 12.9792 10.386C12.8901 10.5071 12.6654 10.7991 12.5324 10.972L12.5323 10.9722C12.4803 11.0397 12.4423 11.0891 12.4319 11.1029C12.2621 11.3287 12.1567 11.4889 12.0881 11.6279C12.026 11.7538 12 11.851 12 11.9362ZM10.5328 11.5795C10.571 11.372 10.6422 11.1685 10.7429 10.9643C10.8613 10.7243 11.0118 10.4957 11.2329 10.2016C11.248 10.1814 11.2969 10.1179 11.3601 10.0357L11.3602 10.0357C11.4952 9.86006 11.6955 9.5997 11.7708 9.49726C12.4373 8.59109 12.75 7.82093 12.75 6.75C12.75 4.67893 11.0711 3 9 3C6.92893 3 5.25 4.67893 5.25 6.75C5.25 7.78391 5.555 8.51746 6.22777 9.42616C6.30001 9.52373 6.50403 9.78973 6.61419 9.93336L6.61422 9.93339L6.68511 10.026C6.87356 10.2753 7.00688 10.4675 7.12175 10.6647C7.30066 10.9718 7.41944 11.2712 7.4709 11.5817C7.93826 11.8622 8.4435 12 9 12C9.55796 12 10.0644 11.8615 10.5328 11.5795ZM10.5 13.5V13.2468C10.0256 13.4152 9.52451 13.5 9 13.5C8.47549 13.5 7.97435 13.4152 7.5 13.2468V13.5C7.5 14.3284 8.17157 15 9 15C9.82843 15 10.5 14.3284 10.5 13.5Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_325_11204">
					<rect width="16" height="16.64" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditPreparationModal from './EditPreparationModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import { SkillChip } from '../../candidates/CandidateBlocks/ViewSkillsBlock';
import ExpandableTypography from '../../../components/form/ExpandableTypography';

const Block = ({ job, setJob, data, minimized, setMinimized, comparisonCandidate, disableEdit }) => {
	const { states } = data;
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={CaseIcon}
				iconColor="var(--blue-500)"
				title="Candidate Preparation"
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditPreparationModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<LabeledField label="Team Details" className="color-muted" removeMargin close>
							<ExpandableTypography numCharacters={250} variant="body2" text={job?.team_details || Utility.nb} />
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Submission Process" className="color-muted" removeMargin close>
							<ExpandableTypography numCharacters={250} variant="body2" text={job?.submission_process || Utility.nb} />
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Interview Process" className="color-muted" removeMargin close>
							<ExpandableTypography numCharacters={250} variant="body2" text={job?.interview_process || Utility.nb} />
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Interview Preparation" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{job?.interview_preparation?.includes('http') ? (
									<a
										href={job?.interview_preparation}
										target="_blank"
										rel="noreferrer"
										className="color-inherit text-underline text-normal"
									>
										Link: Interview Preparation
									</a>
								) : (
									<>{job?.interview_preparation || 'N/A'}</>
								)}
							</Typography>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

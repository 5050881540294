import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import ViewBlockWrapper from './ViewBlockWrapper';
import CheckmarkIcon from '../../../components/icons/CheckmarkIcon';
import { ActionCreators as SiteActions } from '../../../store/Site';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import EditWorkAuthorizationModal from '../EditCandidateProfile/EditWorkAuthorizationModal';

const Block = ({
	candidate,
	setCandidate,
	comparisonJob,
	minimized,
	setMinimized,
	data,
	disableEdit,
	displayMatchValidation,
}) => {
	const dispatch = useDispatch();
	return (
		<>
			<ViewBlockWrapper
				icon={CheckmarkIcon}
				minimized={minimized}
				setMinimized={setMinimized}
				title="US Work Authorization"
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(
										<EditWorkAuthorizationModal candidate={candidate} setCandidate={setCandidate} />,
										{
											className: 'modal-medium modal-no-padding modal-no-shadow',
										},
									),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<LabeledField label="Work Visa Sponsorship" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'work_visa',
								})}
							>
								<Typography variant="body2">{candidate.work_visa_type?.label || Utility.nb}</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField
							label="Legally authorized to work in the United States?"
							className="color-muted"
							removeMargin
							close
						>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'us_work_authorization',
								})}
							>
								<Typography variant="body2">
									{candidate?.us_work_authorization === true
										? 'Yes'
										: candidate?.us_work_authorization === false
										? 'No'
										: Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

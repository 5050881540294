import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { Typography, TextField } from '@material-ui/core';

import LabeledField from '../form/LabeledField';
import Dropdown from '../form/dropdowns/Dropdown';
import LoadingButton from '../form/LoadingButton';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as UsersAPI from '../../scripts/users';

const UserAddModal = ({ onAdd, ...props }) => {
	const store = useStore();
	const [isLoading, setLoading] = useState(false);
	const [user, setUser] = useState({});
	const { data } = useSelector(state => state.site, shallowEqual);

	const updateUser = ev => {
		setUser({ ...user, [ev.target.name]: ev.target.value });
	};

	const onUserInvite = response => {
		setLoading(false);
		store.dispatch(SiteActions.hideModal());

		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred adding a new user. Please try again.', 'error'));
			return;
		}

		store.dispatch(SiteActions.showAlert('The user was added successfully', 'success'));
		if (typeof onAdd === 'function') onAdd();
	};

	const inviteUser = ev => {
		setLoading(true);
		UsersAPI.inviteUser(user, onUserInvite);
	};

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Add New user
			</Typography>

			<LabeledField label="First Name">
				<TextField variant="outlined" name="first_name" value={user.first_name} onChange={updateUser} fullWidth />
			</LabeledField>

			<LabeledField label="Last Name">
				<TextField variant="outlined" name="last_name" value={user.last_name} onChange={updateUser} fullWidth />
			</LabeledField>

			<LabeledField label="E-mail">
				<TextField variant="outlined" name="email" value={user.email} onChange={updateUser} fullWidth />
			</LabeledField>

			<LabeledField label="Select a Role">
				<Dropdown
					variant="outlined"
					name="role"
					value={user.role}
					data={data.userRoles.map(r => ({ ...r, label: r.name }))}
					onChange={updateUser}
					fullWidth
				/>
			</LabeledField>

			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="primary"
				onClick={inviteUser}
				style={{ minWidth: 185 }}
			>
				Send an Invite Link
			</LoadingButton>
		</div>
	);
};

UserAddModal.propTypes = {
	onAdd: PropTypes.func,
};

UserAddModal.defaultProps = {
	onAdd: () => {},
};

export default UserAddModal;

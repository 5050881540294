import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import LightbulbIcon from '../../../components/icons/SectionIcons/LightbulbIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditQuestionsModal from './EditScreeningQuestionsModal';

const Block = ({ job, setJob, minimized, setMinimized }) => {
	const dispatch = useDispatch();
	return (
		<>
			<ViewBlockWrapper
				icon={LightbulbIcon}
				iconColor="var(--blue-500)"
				title="Screening Questions"
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={() => {
					dispatch(
						SiteActions.showModal(<EditQuestionsModal job={job} setJob={setJob} />, {
							className: 'modal-medium modal-no-padding modal-no-shadow',
						}),
					);
				}}
			>
				<>
					<Grid item xs={12}>
						{job?.screening_questions?.map(group => {
							return (
								<>
									<Typography variant="h6">{group?.group_label || 'Unnamed'}</Typography>
									<ul
										style={{
											paddingLeft: 15,
										}}
									>
										{group?.group_questions?.map(question => {
											return (
												<li>
													<Typography variant="body2">{question?.label}</Typography>
												</li>
											);
										})}
									</ul>
								</>
							);
						})}
					</Grid>
				</>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

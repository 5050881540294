import React from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import * as Utility from '../../../../../scripts/utility';
import YearsDropdown from '../../../../form/dropdowns/YearsDropdown';
import Dropdown from '../../../../form/dropdowns/Dropdown';
// eslint-disable-next-line max-len
import MultiInputIndustryExperienceDropdown from '../../../../form/dropdowns/MultiInputIndustryExperienceDropdown';
import { BusinessSizeExperienceDropdown } from '../../../../form/dropdowns/BusinessSizeDropdown';

const ProfessionalForm = ({ candidate, setCandidate, data }) => {
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Professional Experience
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Select your primary domain expertise, industry sector(s) experience, and enter your total years of relevant
				professional experience.
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField label="Professional Experience (Yrs)" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<YearsDropdown
						variant="outlined"
						name="professional_experience_years"
						value={Utility.getFromObj(candidate, 'professional_experience_years')}
						onChange={setCandidate}
						placeholder="Choose from our list"
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<Grid item container xs={9}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<LabeledField label="Current Title" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
									<TextField
										variant="outlined"
										name="current_title"
										value={Utility.getFromObj(candidate, 'current_title')}
										onChange={setCandidate}
										placeholder="Enter current job title"
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Current Employer" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
									<TextField
										variant="outlined"
										name="current_employer"
										value={Utility.getFromObj(candidate, 'current_employer')}
										placeholder="Enter current or most recent employer"
										onChange={setCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Fence width={25}>
				<LabeledField label="Current Employment Type" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<Dropdown
						variant="outlined"
						name="current_employment_type_id"
						value={Utility.getFromObj(candidate, 'current_employment_type_id')}
						onChange={setCandidate}
						data={data.currentEmploymentTypes}
						placeholder="Choose from our list"
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<Fence width={100}>
				<MultiInputIndustryExperienceDropdown labeledField onChange={setCandidate} model={candidate} />
			</Fence>
			<Fence width={25}>
				<LabeledField label="Business Size Experience" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<BusinessSizeExperienceDropdown
						variant="outlined"
						name="business_size_experiences"
						value={
							(Utility.getFromObj(candidate, 'business_size_experiences') || []).length === 9
								? [0]
								: (Utility.getFromObj(candidate, 'business_size_experiences') || []).map(item =>
										item.id ? item.id : item,
								  )
						}
						onChange={setCandidate}
						placeholder={
							Utility.getFromObj(candidate, 'business_size_experiences').length > 0 ? '' : 'Select all that apply'
						}
						fullWidth
						multiple
					/>
				</LabeledField>
			</Fence>
		</>
	);
};

export default ProfessionalForm;

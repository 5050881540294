import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../Panel';
import ExperienceIcon from '../../icons/ExperienceIcon';
import LabeledField from '../LabeledField';
import LoadingButton from '../LoadingButton';
import LeadershipRolesDropdown from './LeadershipRolesDropdown';
import YearsDropdown from './YearsDropdown';
import IndustryExperienceDropdown from './IndustryExperienceDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as Utility from '../../../scripts/utility';
import CategoryDropdown from './CategoryDropdown';
import Dropdown from './Dropdown';
import SpecialtyDropdown from './SpecialtyDropdown';

const SpecialtyCategoryDropdown = ({
	onChange,
	model,
	vertical,
	columnSize,
	horizontalSpacer,
	specialtyLabel,
	specialtyTooltip,
	categoryLabel,
	categoryTooltip,
}) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const [minimized, setMinimized] = useState(false);

	const leadershipSpecialtyLocked = [3, 4].includes(model.leadership_allocation_type_id);

	return (
		<>
			<Grid container spacing={3}>
				{/* <Grid item xs={12}>
					<LabeledField 
						label="Specialty"
						removeMargin
						labelAddendum={specialtyLabel}
						labelAddendumTooltip={specialtyTooltip}
					>
						{!leadershipSpecialtyLocked ? (
							<SpecialtyDropdown
								variant="outlined"
								name="specialty_id"
								value={leadershipSpecialtyLocked ? null : model.specialty_id}
								placeholder="Type to search specialties"
								// category={model.professional_category_id}
								onChange={e => {
									const { value: specialtyId } = e.target;

									const specialty = data.specialties.find(x => x.id === specialtyId);
									const categoryId = specialty?.category?.id;

									onChange({
										target: {
											name: 'specialty_id',
											value: specialtyId,
										},
									});
									onChange({
										target: {
											name: 'professional_category_id',
											value: categoryId,
										},
									});
								}}
								disabled={leadershipSpecialtyLocked}
								fullWidth
							/>
						) : null}
					</LabeledField>
				</Grid> */}
				{/* {!leadershipSpecialtyLocked ? (
					<Grid item xs={12}>
						<Typography
							onClick={() => {
								// setMinimized(!minimized);
							}}
							variant="body1"
							style={{ cursor: 'pointer', userSelect: 'none', marginTop: -10 }}
							className=""
							gutterBottom
						>
							 {minimized ? '▸' : '▾'} Category and specialty lists: 
						</Typography>
					</Grid>
				) : null} */}
				{!minimized || leadershipSpecialtyLocked ? (
					<Grid item xs={12}>
						<div
							style={{ marginTop: -20, borderRadius: 8, backgroundColor: 'transparent' /* '#f7f7f7', padding: 16, */ }}
						>
							<Grid container spacing={3}>
								<Grid item xs={columnSize || (vertical ? 12 : 6)}>
									<LabeledField
										label="Professional Category"
										removeMargin
										labelAddendum={categoryLabel}
										labelAddendumTooltip={categoryTooltip}
									>
										<CategoryDropdown
											variant="outlined"
											name="professional_category_id"
											value={Utility.getFromObj(model, 'professional_category_id')}
											onChange={onChange}
											// error={!Utility.getFromObj(model, 'professional_category_id')}
											fullWidth
											placeholder="Choose from our list"
										/>
									</LabeledField>
								</Grid>
								{horizontalSpacer || null}
								<Grid item xs={columnSize || (vertical ? 12 : 6)}>
									<LabeledField
										label="Specialty"
										removeMargin
										labelAddendum={specialtyLabel}
										labelAddendumTooltip={specialtyTooltip}
									>
										{/* TODO: if leadership allocation >= 75% then specialty should be "management" */}
										<SpecialtyDropdown
											variant="outlined"
											name="specialty_id"
											value={[3, 4].includes(model.leadership_allocation_type_id) ? null : model.specialty_id}
											placeholder={leadershipSpecialtyLocked ? 'Leadership' : 'Choose from our list'}
											category={model.professional_category_id}
											onChange={onChange}
											disabled={
												// Disabled if the leadership type locks this field...
												model.leadership_allocation_type_id === 3 ||
												model.leadership_allocation_type_id === 4 ||
												// ...or the category hasn't been selected yet
												(!model.specialty_id && !model.professional_category_id)
											}
											fullWidth
										/>
									</LabeledField>
								</Grid>
							</Grid>
						</div>
					</Grid>
				) : null}
			</Grid>
			<br />
		</>
	);
};

export default SpecialtyCategoryDropdown;

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, Button, Typography, ButtonBase, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import Fence from '../../../components/Fence';
import LoadingButton from '../../../components/form/LoadingButton';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import ElasticSkillDropdown from '../../../components/form/dropdowns/ElasticSkillDropdown';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as ListingsAPI from '../../../scripts/listings';
import SkillStoryIcon from '../../../components/icons/SkillStoryIcon';

const EditSkillsModal = ({ listing, setListing }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const { data } = useSelector(state => state.site, shallowEqual);
	const [validSkills, setValidSkills] = useState([]);
	const [isSaving, setIsSaving] = useState(false);
	const [showHelp, setShowHelp] = useState(false);
	const [updatedListing, setUpdatedListing] = useState(listing);

	// const validHeroSkills = useMemo(
	// 	() =>
	// 		// return expert or advanced skills
	// 		updatedListing?.skills?.filter(s => s.required_skill).map(s => ({ ...s, id: s.skill_id })),
	// 	[updatedListing.skills],
	// );

	const heroSkills = useMemo(() => updatedListing?.skills?.filter(skill => skill.hero_skill), [updatedListing.skills]);
	const requiredSkills = useMemo(() => updatedListing?.skills?.filter(skill => skill.required_skill), [
		updatedListing.skills,
	]);
	const preferredSkills = useMemo(() => updatedListing?.skills?.filter(skill => skill.preferred_skill), [
		updatedListing.skills,
	]);
	const bonusSkills = useMemo(() => updatedListing?.skills?.filter(skill => skill.bonus_skill), [
		updatedListing.skills,
	]);

	const validPreferredSkills = updatedListing?.skills?.filter(x => x.experience && !x.required_skill && !x.bonus_skill);

	const validRequiredSkills = updatedListing?.skills?.filter(x => x.experience && !x.preferred_skill && !x.bonus_skill);

	const validHeroSkills = updatedListing?.skills?.map(s => ({ ...s, id: s.skill_id }));
	// ?.filter(x => x.experience && x.required_skill);

	const selectHeroSkill = (skillId, replacedSkillId) => {
		const updatedSkills = updatedListing.skills?.map(s => {
			if (s.skill_id === replacedSkillId) return { ...s, hero_skill: false };
			if (s.skill_id === skillId) return { ...s, hero_skill: true };
			return s;
		});
		setUpdatedListing(s => ({ ...s, skills: updatedSkills }));
	};

	const setSkillExperience = (skill, experienceYears) => {
		const updatedSkills = updatedListing.skills?.map(s =>
			s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
		);
		setUpdatedListing(c => ({
			...c,
			skills: updatedSkills,
		}));
	};

	const selectSkill = (skillStoryName, skillIds) => {
		// create an array of skills that are new; not already in updatedListing.skills
		const newSkills = skillIds
			.filter(sid => !updatedListing.skills?.find(s => s.skill_id === sid))
			?.map(sid => {
				return {
					skill_id: sid,
					[skillStoryName]: true,
					skill_experience_id: 1,
					experience_years: 0,
					skill: validSkills.find(s => s.id === sid),
				};
			});
		// for each input skill (skillIds) set the listing.skill story name to true
		const updatedSkills = updatedListing.skills
			?.map(s => ({ ...s, [skillStoryName]: skillIds.includes(s.skill_id) ? true : undefined }))
			.concat(newSkills);
		setUpdatedListing(c => ({ ...c, skills: updatedSkills }));
	};

	const saveListing = () => {
		setIsSaving(true);
		ListingsAPI.addListingSkills(listing.id, updatedListing.skills, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			setListing({
				...updatedListing,
				skills: response.data.data,
			});
			dispatch(SiteActions.hideModal());
		});
	};

	useEffect(() => {
		if (!validSkills.length) {
			ListingsAPI.getValidSkills(listing.id, response => {
				if (response) setValidSkills(response.data.data);
			});
		}
	}, []);

	return (
		<>
			<Panel Icon={<SkillStoryIcon size={32} className="teal-100" />}>
				<PanelHeader className="spread">
					<Typography variant="body2" component="h2" className="text-bold" style={{ paddingTop: 4 }}>
						Skill Story
					</Typography>
				</PanelHeader>
				<PanelContent>
					<Typography className="text-bold">Required Skills</Typography>
					<Typography>
						From the skill pool you provided, please select the skills that a candidate MUST possess to be considered
						for this role.
					</Typography>
					<VerticalSpacer height={1} />
					<ElasticSkillDropdown
						filterListingId={listing.id}
						tags
						variant="outlined"
						color="primary"
						name="required_skill"
						value={requiredSkills?.map(s => (s.skill_id ? s.skill_id : s))}
						override={validRequiredSkills}
						onChange={ev => selectSkill(ev.target.name, ev.target.value)}
						fullWidth
					/>
					<VerticalSpacer height={3} />
					<Typography className="text-bold">Hero Skills</Typography>
					<Typography>
						Please select up to three skills that are viewed as the most critical. These are the skills that are most
						important to successful performance in this role.
					</Typography>
					<VerticalSpacer height={1} />
					{[1, 2, 3].map((v, i) => {
						return (
							<Grid container spacing={2} key={`heroSkill-${heroSkills[i]?.skill_id || v}`}>
								<Grid item xs={7}>
									<ElasticSkillDropdown
										filterListingId={listing.id}
										variant="outlined"
										name="hero_skill"
										value={heroSkills[i]?.skill_id}
										override={validHeroSkills}
										onChange={ev => selectHeroSkill(ev.target.value, heroSkills[i]?.skill_id)}
										fullWidth
									/>
								</Grid>
								<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
									<Typography variant="body1" className="text-right text-medium">
										Experience
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<YearsDropdown
										variant="outlined"
										name="hero_skill_years"
										value={heroSkills[i]?.experience_years}
										onChange={ev => setSkillExperience(heroSkills[i], ev.target.value)}
										disableClearable={false}
										fullWidth
									/>
								</Grid>
							</Grid>
						);
					})}
					<VerticalSpacer height={0.5} />
					<ButtonBase onClick={() => setShowHelp(s => !s)} className="button-text-muted">
						Don&lsquo;t See a Skill You&lsquo;re Looking For?
					</ButtonBase>
					{showHelp && (
						<Fence
							style={{
								background: '#E6F1FF',
								borderRadius: 6,
								padding: '46px 30px 28px 30px',
								position: 'relative',
							}}
						>
							<IconButton
								className="modal-close modal-close-primary"
								onClick={() => setShowHelp(false)}
								style={{ marginTop: '-15px', marginRight: '-15px' }}
							>
								<Icons.Close />
							</IconButton>
							<Typography className="text-bold" style={{ marginTop: -18 }}>
								Not seeing a Hero Skill?
							</Typography>
							<Typography className="grey-300">
								Skills must be ranked Best or Advanced (in the Skills section) before they can be selected as Hero
								skills.
								<br />
								In order to add a new Hero skill, you must first rank it accordingly.
							</Typography>
						</Fence>
					)}
					<VerticalSpacer height={3} />
					<Typography className="text-bold">Preferred Skills</Typography>
					<Typography>
						What are the preferred skills that will help separate top-tier candidates from mid-level candidates?
					</Typography>
					<VerticalSpacer height={1} />
					<ElasticSkillDropdown
						filterListingId={listing.id}
						tags
						variant="outlined"
						color="primary"
						name="preferred_skill"
						value={preferredSkills?.map(s => (s.skill_id ? s.skill_id : s))}
						override={validPreferredSkills}
						onChange={ev => selectSkill(ev.target.name, ev.target.value)}
						fullWidth
					/>
					<VerticalSpacer height={3} />
					<Typography className="text-bold">Bonus Skills</Typography>
					<Typography>What skills, in or outside of the role scope, do you view as bonus skills?</Typography>
					<VerticalSpacer height={1} />
					<ElasticSkillDropdown
						filterListingId={listing.id}
						tags
						variant="outlined"
						color="primary"
						name="bonus_skill"
						value={bonusSkills?.map(s => (s.skill_id ? s.skill_id : s))}
						// override={validBonusSkills}
						filter={skillId => {
							// Valid bonus skills are any skill that doesn't already have a skill story for this job.
							const skillOnJob = updatedListing.skills.find(s => s.skill_id === skillId);
							if (skillOnJob) {
								// If the skill has another skill story, it's not a valid bonus skill.
								if (skillOnJob.preferred_skill || skillOnJob.required_skill || skillOnJob.hero_skill) {
									return false;
								}
							}

							return true;
						}}
						getCategoryLabel={skillId => {
							return updatedListing.skills.find(s => s.skill_id === skillId) ? 'Suggested' : 'All';
						}}
						onChange={ev => selectSkill(ev.target.name, ev.target.value)}
						fullWidth
					/>
					<VerticalSpacer height={3} />
					<div className="text-right">
						<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
							Cancel
						</Button>
						<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveListing}>
							Save
						</LoadingButton>
					</div>
				</PanelContent>
			</Panel>
		</>
	);
};

EditSkillsModal.propTypes = {
	listing: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
	setListing: PropTypes.func,
};

EditSkillsModal.defaultProps = {
	listing: { id: null },
	setListing: () => null,
};

export default EditSkillsModal;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function ExperienceIcon({ style, color = "currentColor", size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 21 21"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-257 -666)">
					<g transform="translate(258 667)">
						<path d="M8 17H2a2 2 0 01-2-2V5.5a2 2 0 012-2h14a2 2 0 012 2V9"/>
						<path d="M12.6 3.5v-2A1.5 1.5 0 0011.1 0H6.9a1.5 1.5 0 00-1.5 1.5v2"/>
						<path d="M18 7l-4.472 1.039a19.984 19.984 0 01-9.056 0L0 7"/>
						<path d="M15 11a4 4 0 110 8 4 4 0 010-8z"/>
						<path d="M16.345 14.208L14.665 15.887 13.655 14.88"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { IconButton, Modal, Button, Grid, Typography, Menu, MenuItem, Chip, ListItemIcon } from '@material-ui/core';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as Icons from '@material-ui/icons';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as ListingsAPI from '../../../scripts/listings';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as Utility from '../../../scripts/utility';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import RateInterestModal from '../../modals/RateJobClientInterestModal';
import JobLogoLinks from '../JobLogoLinks';
import ShareMatchModal from '../../modals/ShareMatchModal';

import CandidateViewPreferencesBlock from '../../../pages/candidates/CandidateBlocks/ViewPreferencesBlock';
import CandidateViewExperienceBlock from '../../../pages/candidates/CandidateBlocks/ViewExperienceBlock';
import CandidateViewLocationBlock from '../../../pages/candidates/CandidateBlocks/ViewLocationPreferencesBlock';
import CandidateViewSkillsBlock from '../../../pages/candidates/CandidateBlocks/ViewSkillsBlock';
import CandidateViewResumeBlock from '../../../pages/candidates/CandidateBlocks/ViewResumeBlock';
import CandidateViewSummaryBlock from '../../../pages/candidates/CandidateBlocks/ViewSummaryBlock';

import CompanyViewDetailsBlock from '../../../pages/jobs/CompanyBlocks/ViewDetailsBlock';
import CompanyViewSummaryBlock from '../../../pages/jobs/CompanyBlocks/ViewSummaryBlock';
import CompanyViewProductsServicesBlock from '../../../pages/jobs/CompanyBlocks/ViewProductsServicesBlock';
import CompanyViewFinancialBlock from '../../../pages/jobs/CompanyBlocks/ViewFinancialBlock';
import CompanyViewBenefitsBlock from '../../../pages/jobs/CompanyBlocks/ViewBenefitsBlock';
import CompanyViewSkillsBlock from '../../../pages/jobs/CompanyBlocks/ViewSkillsBlock';

import JobEditHeaderModal from '../../../pages/jobs/JobBlocks/EditHeaderModal';
import JobViewDetailsBlock from '../../../pages/jobs/JobBlocks/ViewDetailsBlock';
import JobViewPreparationBlock from '../../../pages/jobs/JobBlocks/ViewPreparationBlock';
import JobViewExperienceBlock from '../../../pages/jobs/JobBlocks/ViewExperienceBlock';
import JobViewLocationBlock from '../../../pages/jobs/JobBlocks/ViewLocationBlock';
import JobViewSkillsBlock from '../../../pages/jobs/JobBlocks/ViewSkillsBlock';
import JobViewDescriptionBlock from '../../../pages/jobs/JobBlocks/ViewDescriptionBlock';
import JobViewScreeningQuestionsPanel from '../../../pages/jobs/JobBlocks/ViewScreeningQuestionsPanel';
import { Panel } from '../../Panel';
import CustomTabBar from '../CustomTabBar';

import ExperienceTab from '../MatchViewTabs/ExperienceTab';
import SkillsTab from '../MatchViewTabs/SkillsTab';
import PreferencesTab from '../MatchViewTabs/PreferencesTab';

import { useContextStore } from '../../../store/ContextStore';
import PlusIcon from '../../icons/PlusIcon';
import MinusIcon from '../../icons/MinusIcon';
import ClipboardIcon from '../../icons/SectionIcons/ClipboardIcon';
import MeterIcon from '../../icons/SectionIcons/MeterIcon';
import SlidersIcon from '../../icons/SectionIcons/SlidersIcon';
import CandidateViewHeaderBlock from '../../../pages/candidates/CandidateBlocks/ViewHeaderBlock';

import { getJob, getCandidate, getJobs, getCandidates, getCompany } from './utils';

const DetailsModal = () => {
	const location = useLocation();
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();

	const { rootPath } = params;
	const { data } = useSelector(state => state.site, shallowEqual);

	const itemId = rootPath?.split('/')?.[1];

	const search = location?.search;
	const candidateQuery = new URLSearchParams(search).get('candidates');
	const jobQuery = new URLSearchParams(search).get('jobs');

	const candidateIds = candidateQuery?.split(',');
	const jobIds = jobQuery?.split(',');

	const [job, setJob] = useState(null);
	const [candidate, setCandidate] = useState(null);

	const [candidates, setCandidates] = useState([]);
	const [jobs, setJobs] = useState([]);

	const closeModal = () => {
		history.push(`/${rootPath}`);
	};

	const [currentItemIndex, setCurrentItemIndex] = useState(0);

	// Types of candidate modals:

	// 1. Candidate details modal on the candidate page: /candidate/:id/details
	// 2. Candidates overview modal on the candidates page: /candidates/details?candidates=[ids]

	// 3. Candidate match modal on a job page: /job/:id/matches?candidates=[ids]
	// 4. Match testing modal on a /match-testing or /matches page

	const isCandidateModal = location?.pathname?.includes('/candidate/');
	const isCandidatesPageOverviewModal = location?.pathname?.includes('/candidates');
	const isJobModal = location?.pathname?.includes('/job/');
	const isJobsPageOverviewModal = location?.pathname?.includes('/jobs');
	const isMatchTesting = location?.pathname?.includes('match-testing');
	const isSuggestedMatch = location?.pathname?.includes('matches');
	const isSavedCandidateMatch = !location?.pathname?.includes('matches') && location?.pathname?.includes('/candidate/');
	const isSavedJobMatch = !location?.pathname?.includes('matches') && location?.pathname?.includes('/job/');

	const isCandidateOverviewMode = isCandidateModal && !candidateIds?.length && !jobIds?.length;
	const isJobOverviewMode = isJobModal && !jobIds?.length && !candidateIds?.length;

	// tabs: "match" | "candidate" | "job"
	const [activeTab, setActiveTab] = useState(
		isCandidateOverviewMode || isCandidatesPageOverviewModal
			? 'candidate'
			: isJobOverviewMode || isJobsPageOverviewModal
			? 'company'
			: jobIds?.length || candidateIds?.length
			? 'match'
			: itemId
			? isCandidateModal
				? 'candidate'
				: 'job'
			: null,
	);

	// match view tabs: "Experience" | "Skills" | "Preferences"
	const [activeMatchViewTab, setActiveMatchViewTab] = useState('Experience');

	// Processed data
	const [currentCompany, setCurrentCompany] = useState(null);
	const currentCandidate = isCandidateModal ? candidate : candidates[currentItemIndex];
	const currentJob = isJobModal ? job : jobs[currentItemIndex];

	const numItems =
		isCandidateOverviewMode || isCandidatesPageOverviewModal
			? candidateIds?.length
			: isJobOverviewMode || isJobsPageOverviewModal
			? jobIds?.length
			: isCandidateModal
			? jobIds?.length
			: candidateIds?.length;

	const {
		candidateMatches: currentCandidateMatches,
		jobMatches: currentJobMatches,
		refetchMatches: refetchContextMatches,
		graphMatches,
		setGraphMatches,
	} = useContextStore();

	// TODO: handle saved match fit_rankings list

	const currentGraphMatch =
		(isSuggestedMatch &&
			graphMatches?.find(x => x?.candidate_id === currentCandidate?.id && x?.listing_id === currentJob?.id)) ||
		(isSavedCandidateMatch &&
			currentCandidateMatches?.find(x => x?.candidate_id === currentCandidate?.id && x?.listing_id === currentJob?.id)
				?.match_ranking) ||
		(isSavedJobMatch &&
			currentJobMatches?.find(x => x?.candidate_id === currentCandidate?.id && x?.listing_id === currentJob?.id)
				?.match_ranking) ||
		null;

	const setCurrentJob = newJob => {
		if (isJobModal) {
			setJob(newJob);
		} else {
			const newJobs = [...jobs];
			newJobs[currentItemIndex] = newJob;
			setJobs(newJobs);
		}
	};

	const setCurrentCandidate = newCandidate => {
		if (isCandidateModal) {
			setCandidate(newCandidate);
		} else {
			const newCandidates = [...candidates];
			newCandidates[currentItemIndex] = newCandidate;

			setCandidates(newCandidates);
		}
	};

	// Load the matches for the current candidate and job
	// const [currentCandidateMatches, setCurrentCandidateMatches] = useState([]);
	// const [currentJobMatches, setCurrentJobMatches] = useState([]);

	const refetchMatches = async () => {
		await refetchContextMatches({
			candidateId: currentCandidate?.id,
			jobId: currentJob?.id,
		});
	};

	useEffect(() => {
		if (currentCandidate && currentJob) {
			refetchMatches();
			ListingsAPI.viewMatchFit({
				listingId: currentJob?.id,
				candidateId: currentCandidate?.id,
			}).then(viewResponse => {
				const viewObject = viewResponse?.data?.data;
				console.log('Post-view data:');
				console.log({ viewObject, currentGraphMatch });

				if (viewObject) {
					setGraphMatches(currentGraphMatches => {
						const newGraphMatches = currentGraphMatches?.map(x => {
							if (
								x?.candidate_id === currentGraphMatch?.candidate_id &&
								x?.listing_id === currentGraphMatch?.listing_id
							) {
								return {
									...x,
									views: [...(x?.views || []), viewObject],
								};
							}
							return x;
						});
						return newGraphMatches;
					});
				}
			});
		}
	}, [currentCandidate?.id, currentJob?.id]);

	useEffect(() => {
		if (currentJob) {
			getCompany(currentJob?.client_corporation?.id, setCurrentCompany);
		}
	}, [currentJob?.id]);

	useEffect(() => {
		// Load the primary candidate or job we're comparing against.
		if (itemId) {
			if (isJobModal) {
				// Load the job
				getJob(itemId, setJob);
			} else if (isCandidateModal) {
				// Load the candidate
				getCandidate(itemId, setCandidate);
			}
		}

		// Load the multiple jobs or candidates we're comparing to.
		getCandidates(candidateIds, setCandidates);
		getJobs(jobIds, setJobs);
	}, []);

	const modalTabs = [];
	if (currentJob && currentCandidate) {
		modalTabs.push({
			id: 'match',
			label: 'Match View',
		});
	}
	if (currentCompany || (isJobsPageOverviewModal && !isMatchTesting)) {
		modalTabs.push({
			id: 'company',
			label: 'Company Overview',
		});
	}
	if (currentJob || (isJobsPageOverviewModal && !isMatchTesting)) {
		modalTabs.push({
			id: 'job',
			label: 'Job Overview',
		});
	}
	if (currentCandidate || (isCandidatesPageOverviewModal && !isMatchTesting)) {
		modalTabs.push({
			id: 'candidate',
			label: 'Candidate Overview',
		});
	}

	const [detailsMinimized, setDetailsMinimized] = useState(false);
	const [experienceMinimized, setExperienceMinimized] = useState(false);
	const [locationMinimized, setLocationMinimized] = useState(false);
	const [skillsMinimized, setSkillsMinimized] = useState(false);

	const modalRef = useRef();
	const darkHeader = useRef();
	const jobHeader = useRef();
	const modalTabsHeader = useRef();

	const modalHeight = modalRef?.current?.offsetHeight || 0;
	const darkHeaderHeight = darkHeader?.current?.offsetHeight || 0;
	const jobHeaderHeight = jobHeader?.current?.offsetHeight || 0;
	const tabsHeaderHeight = modalTabsHeader?.current?.offsetHeight || 0;
	const scrollViewHeight = modalHeight - darkHeaderHeight - jobHeaderHeight - tabsHeaderHeight - 40;

	// If we don't have either a primary job or primary candidate,
	// and this isn't a candidates/jobs page overview modal,
	// return null
	if (
		!job &&
		!candidate &&
		!isCandidateOverviewMode &&
		!isJobOverviewMode &&
		!isCandidatesPageOverviewModal &&
		!isJobsPageOverviewModal
	) {
		return null;
	}

	return (
		<Modal
			ref={modalRef}
			className="modal modal-xl"
			open
			onClose={(_, reason) => {
				if (reason !== 'backdropClick') {
					// console.log({reason})
					closeModal();
				}
			}}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			BackdropProps={{
				style: {
					backgroundColor: 'rgba(0, 15, 50, 0.3)',
				},
			}}
		>
			<div
				className="modal-body"
				style={{
					backgroundColor: `#F3F6F9`,
					padding: 0,
				}}
			>
				{/* Header/Nav block */}
				<div
					ref={darkHeader}
					style={{
						backgroundColor: '#34414E',
						// marginTop: -75,
						// marginLeft: -67,
						// marginRight: -67,
						color: '#fff',
						padding: '15px 40px',
					}}
				>
					<Grid container spacing={2} style={{ alignItems: 'center' }}>
						<Grid item xs={6}>
							<>
								{isMatchTesting ? (
									<h4>Match Test: {currentCandidate?.desired_position || 'Unknown position'}</h4>
								) : isJobModal ? (
									<h4>
										{currentJob?.id} | {currentJob?.position_title}
										{' at '}
										{currentJob?.company}
										{currentCandidate && (
											<>
												{' | '}
												<span style={{ color: 'var(--teal-100)', fontWeight: '800' }}>
													{currentCandidate?.first_name} {currentCandidate?.last_name}
												</span>
											</>
										)}
									</h4>
								) : (
									<h4>
										{currentCandidate?.id ? `${currentCandidate?.id} | ` : null}
										{currentCandidate?.first_name
											? `${currentCandidate?.first_name} ${currentCandidate?.last_name}`
											: null}{' '}
										{currentCandidate && currentJob ? ' | ' : null}
										{currentJob?.position_title}
										{currentJob ? ' at ' : null}
										{currentJob?.company}
									</h4>
								)}
							</>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							{numItems && (
								<>
									<Typography variant="body1" color="inherit" style={{ marginLeft: 10 }}>
										viewing {currentItemIndex + 1} of {numItems}
									</Typography>
									<Button
										variant="contained"
										color="primary"
										endIcon={<Icons.ArrowBack />}
										disabled={currentItemIndex === 0}
										onClick={() => setCurrentItemIndex(x => x - 1)}
										style={{ paddingLeft: 0, paddingRight: 13 }}
									/>
									<Button
										variant="contained"
										color="primary"
										endIcon={<Icons.ArrowForward />}
										disabled={currentItemIndex >= numItems - 1}
										onClick={() => setCurrentItemIndex(x => x + 1)}
										style={{ paddingLeft: 0, paddingRight: 13 }}
									/>
								</>
							)}
							<IconButton
								className="modal-close modal-close-static"
								onClick={() => {
									const baseUrl = window.location?.pathname?.split('/details')[0];
									history.replace(baseUrl);
									dispatch(SiteActions.hideModal());
								}}
								style={{
									marginLeft: '1.5rem',
									zIndex: 1000,
									color: '#fff',
									borderColor: '#fff',
								}}
							>
								<Icons.Close />
							</IconButton>
						</Grid>
					</Grid>
				</div>
				{currentJob && (
					<div
						ref={jobHeader}
						style={{
							backgroundColor: '#fff',
							borderBottom: '1px solid #ddd',
							padding: '15px 40px',
						}}
					>
						<JobHeaderViewBlock
							job={currentJob}
							setJob={setJob}
							data={data}
							graphMatch={currentGraphMatch}
							onUpdate={isJobModal ? () => getJob(currentJob?.id, setJob) : () => getJobs(jobIds, setJobs)}
						/>
					</div>
				)}
				<div>
					{!isMatchTesting && !isCandidateOverviewMode && (
						<div ref={modalTabsHeader}>
							<CustomTabBar
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								tabs={modalTabs}
								style={{
									backgroundColor: '#fff',
									boxShadow: '0px 3px 6px #00000015',
									paddingTop: 0,
								}}
							/>
						</div>
					)}
					{!isMatchTesting && (
						<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
							<div style={{ marginTop: isMatchTesting ? 20 : -40, marginRight: 40 }}>
								<>
									{!isCandidateOverviewMode && !isJobOverviewMode ? (
										<MatchActionsMenu
											candidateId={currentCandidate?.id}
											listingId={currentJob?.id}
											currentCandidateMatches={currentCandidateMatches}
											currentJobMatches={currentJobMatches}
											refetchMatches={refetchMatches}
										/>
									) : null}
								</>
							</div>
						</div>
					)}
					<div
						style={{
							height: scrollViewHeight,
							overflowY: 'auto',
						}}
					>
						{/* {isMatchTesting ? (
							<Grid container spacing={3}>
								<Grid item xs={12} sm={3} />
								<Grid item xs={12} sm={6}>
									<div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20 }}>
										<Panel topBarColor="blue" style={{ width: '100%' }}>
											<div style={{ padding: 20, width: '100%' }}>
												<Typography variant="h3">Rate Match</Typography>
												<RateMatchInterestBlock
													key={`${currentCandidate?.id}:${currentJob?.id}`}
													candidateId={currentCandidate?.id}
													listingId={currentJob?.id}
													fitRankingId={myFitRanking?.match_fit_ranking_status_id}
												/>
											</div>
										</Panel>
									</div>
								</Grid>
								<Grid item xs={12} sm={3} />
							</Grid>
						) : null} */}
						{activeTab === 'match' && currentCandidate && currentJob ? (
							<div style={{ padding: 40, paddingTop: 30 }}>
								<Grid container spacing={1} style={{ paddingBottom: 20 }}>
									<Grid item xs={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
										{activeMatchViewTab === 'Experience' ? (
											<SlidersIcon color="#0076FF" size={20} />
										) : activeMatchViewTab === 'Skills' ? (
											<MeterIcon color="#0076FF" size={20} />
										) : activeMatchViewTab === 'Preferences' ? (
											<ClipboardIcon color="#0076FF" size={20} />
										) : null}
										<Typography variant="h3" style={{ marginLeft: 10 }}>
											{activeMatchViewTab}:
										</Typography>
										{activeMatchViewTab === 'Experience' && (
											<div style={{ display: 'flex' }}>
												<Typography variant="h3" color="primary" style={{ marginInline: 5 }}>
													{currentGraphMatch?.total_experience_score !== null &&
													currentGraphMatch?.total_experience_score !== undefined
														? `${Math.round(currentGraphMatch?.total_experience_score * 100)}%`
														: 'N/A'}
												</Typography>
												<Typography variant="h3" color="textSecondary">
													{!!currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_experience &&
													currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_experience !== 0
														? `(${Math.round(
																currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_experience *
																	100,
														  )}% complete)`
														: null}
												</Typography>
											</div>
										)}
										{activeMatchViewTab === 'Skills' && (
											<div style={{ display: 'flex' }}>
												<Typography variant="h3" color="primary" style={{ marginInline: 5 }}>
													{currentGraphMatch?.total_skill_score !== null &&
													currentGraphMatch?.total_skill_score !== undefined
														? `${Math.round(currentGraphMatch?.total_skill_score * 100)}%`
														: 'N/A'}
												</Typography>
												<Typography variant="h3" color="textSecondary">
													{!!currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_skills &&
													currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_skills !== 0
														? `(${Math.round(
																currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_skills * 100,
														  )}% complete)`
														: null}
												</Typography>
											</div>
										)}
										{activeMatchViewTab === 'Preferences' && (
											<div style={{ display: 'flex' }}>
												<Typography variant="h3" color="primary" style={{ marginInline: 5 }}>
													{currentGraphMatch?.total_preference_score !== null &&
													currentGraphMatch?.total_preference_score !== undefined
														? `${Math.round(currentGraphMatch?.total_preference_score * 100)}%`
														: 'N/A'}
												</Typography>
												<Typography variant="h3" color="textSecondary">
													{!!currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_preference &&
													currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_preference !== 0
														? `(${Math.round(
																currentGraphMatch?.all_match_data?.overall_match_data?.completeness_score_preference *
																	100,
														  )}% complete)`
														: null}
												</Typography>
											</div>
										)}
									</Grid>
									<Grid item xs={2} />
									<Grid
										item
										xs={5}
										style={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<Grid container direction="row" spacing={1} alignItems="stretch" justifyContent="flex-end">
											{currentGraphMatch && (
												<Grid item xs={3} style={{ display: 'flex', minHeigth: '100%' }}>
													<Button
														style={{
															backgroundColor: '#fff',
															display: 'flex',
															flexDirection: 'column',
															cursor: 'default',
														}}
														fullWidth
														variant="text"
													>
														<div style={{ display: 'flex', flexDirection: 'column' }}>
															<Typography variant="h2" color="inherit">
																{currentGraphMatch?.overall_match_score
																	? `${Math.round(currentGraphMatch?.overall_match_score * 100)}%`
																	: null}
															</Typography>
															<Typography variant="caption" color="inherit">
																Total Match
															</Typography>
														</div>
													</Button>
												</Grid>
											)}
											<Grid item xs={3} style={{ display: 'flex', minHeigth: '100%' }}>
												<Button
													color="primary"
													fullWidth
													variant={activeMatchViewTab === 'Experience' ? 'contained' : 'outlined'}
													onClick={() => setActiveMatchViewTab('Experience')}
												>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<Typography variant="h2" color="inherit">
															{currentGraphMatch?.total_experience_score !== null &&
															currentGraphMatch?.total_experience_score !== undefined
																? `${Math.round(currentGraphMatch?.total_experience_score * 100)}%`
																: null}
														</Typography>
														<Typography variant="caption" color="inherit">
															Experience
														</Typography>
													</div>
												</Button>
											</Grid>
											<Grid item xs={3} style={{ display: 'flex', minHeigth: '100%' }}>
												<Button
													color="primary"
													fullWidth
													variant={activeMatchViewTab === 'Skills' ? 'contained' : 'outlined'}
													onClick={() => setActiveMatchViewTab('Skills')}
												>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<Typography variant="h2" color="inherit">
															{currentGraphMatch?.total_skill_score !== null &&
															currentGraphMatch?.total_skill_score !== undefined
																? `${Math.round(currentGraphMatch?.total_skill_score * 100)}%`
																: null}
														</Typography>
														<Typography variant="caption" color="inherit">
															Skills
														</Typography>
													</div>
												</Button>
											</Grid>
											<Grid item xs={3} style={{ display: 'flex', minHeigth: '100%' }}>
												<Button
													color="primary"
													fullWidth
													variant={activeMatchViewTab === 'Preferences' ? 'contained' : 'outlined'}
													onClick={() => setActiveMatchViewTab('Preferences')}
												>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<Typography variant="h2" color="inherit">
															{currentGraphMatch?.total_preference_score !== null &&
															currentGraphMatch?.total_preference_score !== undefined
																? `${Math.round(currentGraphMatch?.total_preference_score * 100)}%`
																: null}
														</Typography>
														<Typography variant="caption" color="inherit">
															Preferences
														</Typography>
													</div>
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								{activeMatchViewTab === 'Experience' && (
									<ExperienceTab
										currentJob={currentJob}
										currentCandidate={currentCandidate}
										graphMatch={currentGraphMatch}
										data={data}
										onUpdate={
											isCandidateModal
												? () => getCandidate(itemId, setCandidate)
												: () => getCandidates(candidateIds, setCandidates)
										}
									/>
								)}
								{activeMatchViewTab === 'Skills' && (
									<SkillsTab
										currentCandidate={currentCandidate}
										setCandidate={setCandidate}
										currentJob={currentJob}
										setCurrentJob={setCurrentJob}
										graphMatch={currentGraphMatch}
										data={data}
										onUpdate={
											isCandidateModal
												? () => getCandidate(itemId, setCandidate)
												: () => getCandidates(candidateIds, setCandidates)
										}
									/>
								)}
								{activeMatchViewTab === 'Preferences' && (
									<PreferencesTab
										currentCandidate={currentCandidate}
										currentJob={currentJob}
										graphMatch={currentGraphMatch}
										data={data}
										onUpdate={
											isCandidateModal
												? () => getCandidate(itemId, setCandidate)
												: () => getCandidates(candidateIds, setCandidates)
										}
									/>
								)}
							</div>
						) : activeTab === 'candidate' && currentCandidate ? (
							<div style={{ padding: 40, paddingTop: 30 }}>
								<CandidateViewHeaderBlock
									candidate={currentCandidate}
									setCandidate={setCandidate}
									displayBackArrow={false}
									data={data}
									isModalHeader
									onUpdate={
										isCandidateModal
											? () => getCandidate(itemId, setCandidate)
											: () => getCandidates(candidateIds, setCandidates)
									}
								/>
								<div style={{ height: 20 }} />
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} style={{ marginBottom: -16, paddingBottom: 0 }}>
										<Panel topBarColor="teal">
											<CandidateViewSummaryBlock
												candidate={currentCandidate}
												minimized={detailsMinimized}
												setMinimized={setDetailsMinimized}
												setCandidate={setCurrentCandidate}
												comparisonJob={currentJob}
												data={data}
											/>
										</Panel>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Panel topBarColor="teal">
											<CandidateViewPreferencesBlock
												candidate={currentCandidate}
												minimized={detailsMinimized}
												setMinimized={setDetailsMinimized}
												setCandidate={setCurrentCandidate}
												comparisonJob={currentJob}
												data={data}
											/>
										</Panel>
										<Panel topBarColor="teal">
											<CandidateViewExperienceBlock
												minimized={experienceMinimized}
												setMinimized={setExperienceMinimized}
												candidate={currentCandidate}
												setCandidate={setCurrentCandidate}
												data={data}
												comparisonJob={currentJob}
											/>
										</Panel>
										<Panel topBarColor="teal">
											<CandidateViewLocationBlock
												minimized={locationMinimized}
												setMinimized={setLocationMinimized}
												candidate={currentCandidate}
												setCandidate={setCurrentCandidate}
												data={data}
											/>
										</Panel>
									</Grid>
									<Grid item xs={12} sm={6} style={{ flex: 1 }}>
										<Panel topBarColor="teal">
											<CandidateViewSkillsBlock
												minimized={skillsMinimized}
												setMinimized={setSkillsMinimized}
												candidate={currentCandidate}
												setCandidate={setCurrentCandidate}
												data={data}
												displaySkillsWithoutExperience
											/>
										</Panel>
										<Panel topBarColor="teal">
											<CandidateViewResumeBlock
												displayDocumentManagement
												candidate={currentCandidate}
												setCandidate={setCurrentCandidate}
												data={data}
												expandFullHeight
											/>
										</Panel>
									</Grid>
								</Grid>
							</div>
						) : activeTab === 'job' && currentJob ? (
							<div style={{ padding: 40, paddingTop: 30 }}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6}>
										<Panel topBarColor="blue">
											<JobViewDetailsBlock
												minimized={detailsMinimized}
												setMinimized={setDetailsMinimized}
												job={currentJob}
												setJob={setCurrentJob}
												data={data}
												comparisonCandidate={currentCandidate}
											/>
										</Panel>
										<Panel topBarColor="blue">
											<JobViewExperienceBlock
												minimized={experienceMinimized}
												setMinimized={setExperienceMinimized}
												job={currentJob}
												setJob={setCurrentJob}
												data={data}
												comparisonCandidate={currentCandidate}
											/>
										</Panel>
										<Panel topBarColor="blue">
											<JobViewLocationBlock
												minimized={locationMinimized}
												setMinimized={setLocationMinimized}
												job={currentJob}
												setJob={setCurrentJob}
												data={data}
											/>
										</Panel>
										<Panel topBarColor="blue">
											<JobViewScreeningQuestionsPanel job={currentJob} setJob={setCurrentJob} data={data} />
										</Panel>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Panel topBarColor="blue">
											<JobViewDescriptionBlock
												displayDocumentManagement
												job={currentJob}
												setJob={setCurrentJob}
												data={data}
											/>
										</Panel>
										<Panel topBarColor="blue">
											<JobViewSkillsBlock
												minimized={skillsMinimized}
												setMinimized={setSkillsMinimized}
												job={currentJob}
												setJob={setCurrentJob}
												data={data}
												displaySkillsWithoutExperience
												// comparisonCandidate={currentCandidate}
											/>
										</Panel>
										<Panel topBarColor="blue">
											<JobViewPreparationBlock job={currentJob} setJob={setCurrentJob} data={data} />
										</Panel>
									</Grid>
								</Grid>
							</div>
						) : activeTab === 'company' && currentCompany ? (
							<>
								<div style={{ padding: 40, paddingTop: 30 }}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<CompanyHeaderViewBlock company={currentCompany} />
										</Grid>
										<Grid item xs={12}>
											<Panel topBarColor="blue">
												<CompanyViewDetailsBlock company={currentCompany} setCompany={setCurrentCompany} />
												<CompanyViewSummaryBlock company={currentCompany} setCompany={setCurrentCompany} disableEdit />
											</Panel>
										</Grid>
										<Grid item xs={12}>
											<Panel topBarColor="blue">
												<CompanyViewProductsServicesBlock company={currentCompany} setCompany={setCurrentCompany} />
											</Panel>
										</Grid>
										<Grid item xs={6}>
											<Panel topBarColor="blue">
												<CompanyViewFinancialBlock company={currentCompany} setCompany={setCurrentCompany} />
											</Panel>
										</Grid>
										<Grid item xs={6}>
											<Panel topBarColor="blue">
												<CompanyViewBenefitsBlock company={currentCompany} setCompany={setCurrentCompany} />
											</Panel>
										</Grid>
										<Grid item xs={12}>
											<Panel topBarColor="blue">
												<CompanyViewSkillsBlock company={currentCompany} setCompany={setCurrentCompany} />
											</Panel>
										</Grid>
									</Grid>
									<div style={{ height: 20 }} />
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
		</Modal>
	);
};

const CompanyHeaderViewBlock = ({ company }) => {
	if (!company) {
		return null;
	}

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div>
					<Typography variant="h2" style={{ fontWeight: 700 }}>
						{company.name || `company #${company.id}`}
					</Typography>
					{company.company_url && (
						<a
							target="_blank"
							rel="noreferrer"
							className="color-inherit"
							style={{
								marginTop: 5,
								display: 'inline-block',
								fontWeight: 300,
								textDecoration: 'underline',
							}}
							href={company.company_url}
						>
							{company.company_url}
						</a>
					)}
					{company.careers_url && (
						<p style={{ marginTop: 5 }}>
							<a
								target="_blank"
								rel="noreferrer"
								className="color-inherit contains-nomargin-html"
								style={{
									display: 'inline-block',
									fontWeight: 300,
									textDecoration: 'underline',
								}}
								href={company.careers_url}
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{ __html: company.careers_url }}
							/>
						</p>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end',
					}}
				>
					{company.status && (
						<Chip
							size="small"
							label={company?.status}
							className={`color-${Utility.getListingStatusColor(company?.status)}`}
							style={{ margin: 0, marginLeft: '1.5rem' }}
						/>
					)}
					{company.relationship_types?.length > 0 && (
						<>
							{company?.relationship_types?.map(relationship => (
								<p style={{ fontStyle: 'italic', margin: 0, marginTop: 5 }}>Relationship: {relationship?.label}</p>
							))}
						</>
					)}
					{company.vendors?.length > 0 && (
						<>
							{company.vendors.map(vendor => (
								<p style={{ fontStyle: 'italic', margin: 0, marginTop: 5 }}>Vendor: {vendor?.vendor_name}</p>
							))}
						</>
					)}
				</div>
			</div>
		</>
	);
};

const JobHeaderViewBlock = ({ job, setJob, disableEdit, graphMatch, onUpdate }) => {
	const dispatch = useDispatch();
	const [isHovered, setIsHovered] = React.useState(false);

	if (!job) {
		return null;
	}

	const primaryContact = job.contacts[0];

	return (
		<div
			style={{
				minHeight: 50,
				position: 'relative',
			}}
			onMouseEnter={() => {
				setIsHovered(true);
			}}
			onMouseLeave={() => {
				setIsHovered(false);
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
				<Typography variant="h2" style={{ fontWeight: 700 }}>
					<NavLink to={`/job/${job.id}`} className="color-inherit" target="_blank">
						{job.position_title || `Job #${job?.id}`}
					</NavLink>
				</Typography>

				<JobLogoLinks job={job} isSharable={job.is_sharable} onUpdate={onUpdate} />
				{job.status_id ? (
					<Chip
						size="small"
						label={Utility.getFromObj(job, 'listing_status', 'label')}
						className={`color-${Utility.getListingStatusColor(Utility.getFromObj(job, 'listing_status', 'name'))}`}
						style={{ margin: 0, marginLeft: '1.5rem' }}
					/>
				) : null}

				{graphMatch ? (
					<>
						<Typography
							variant="body1"
							color="primary"
							style={{ marginLeft: 20, fontSize: 13, fontWeight: 500, marginBottom: 0 }}
						>
							Score: {`${Math.round(graphMatch?.overall_match_score * 100)}%` || 'Unknown'}
						</Typography>
						<Typography
							variant="body1"
							style={{ marginLeft: 5, fontSize: 13, fontWeight: 500, marginBottom: 0, color: `#aaa` }}
						>
							{graphMatch?.completeness_score_overall
								? `(${Math.round(graphMatch?.completeness_score_overall * 100)}%)`
								: null}
						</Typography>
					</>
				) : null}
			</div>
			{primaryContact ? (
				<div>
					<Typography variant="body1" style={{ marginBottom: 0, paddingRight: 50 }}>
						{primaryContact?.first_name} {primaryContact?.last_name}, {primaryContact?.role} at{' '}
						{primaryContact?.company}
					</Typography>
				</div>
			) : null}
			{disableEdit ? null : (
				<Button
					variant="text"
					color="primary"
					size="small"
					onClick={
						disableEdit
							? null
							: () => {
									dispatch(
										SiteActions.showModal(<JobEditHeaderModal job={job} setJob={setJob} />, {
											className: 'modal-medium modal-no-padding modal-no-shadow',
										}),
									);
							  }
					}
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						opacity: isHovered ? 1 : 0,
						transition: 'opacity .2s ease',
					}}
				>
					EDIT
				</Button>
			)}
		</div>
	);
};

const MatchActionsMenu = ({ candidateId, listingId, currentCandidateMatches, currentJobMatches, refetchMatches }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const dispatch = useDispatch();

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const existingCandidateMatch = currentCandidateMatches?.filter(x => x.listing_id === listingId)[0];

	const candidateMatchExists = !!existingCandidateMatch;

	const existingListingMatch = currentJobMatches?.filter(x => x.candidate_id === candidateId)[0];
	const listingMatchExists = !!existingListingMatch;

	const [editingCandidateMatch, setEditingCandidateMatch] = React.useState(false);
	const [editingListingMatch, setEditingListingMatch] = React.useState(false);

	return (
		<>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				variant="outlined"
				color="primary"
				className="no-border-radius"
				endIcon={<ChevronDownIcon />}
				style={{
					padding: '5px 10px',
					background: '#fff',
				}}
			>
				Match Actions
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem
					disabled
					style={{
						fontSize: 14,
						fontWeight: 700,
						opacity: 0.35,
					}}
				>
					JOB
				</MenuItem>
				<MenuItem
					disabled={editingCandidateMatch}
					// disabled={editingCandidateMatch || !job?.is_sharable}
					onClick={async () => {
						setEditingCandidateMatch(true);
						try {
							if (!candidateMatchExists) {
								console.log('Adding to candidate...');
								const newMatch = await CandidatesAPI.addCandidateMatch(
									{
										candidateId,
										listingId,
									},
									results => {
										console.log(results);
									},
								);
							} else {
								await CandidatesAPI.deleteCandidateMatch({
									candidateId,
									matchId: existingCandidateMatch?.id,
								});
							}

							await refetchMatches();
						} catch (e) {
							console.log(e);
						} finally {
							setEditingCandidateMatch(false);
						}
					}}
				>
					<ListItemIcon>
						{candidateMatchExists ? <MinusIcon color="red" /> : <PlusIcon color="var(--teal-100)" />}
					</ListItemIcon>
					{candidateMatchExists ? 'Remove from Candidate' : 'Save to Candidate'}
				</MenuItem>
				<MenuItem
					disabled={!candidateMatchExists || editingCandidateMatch}
					onClick={async () => {
						dispatch(
							SiteActions.showModal(
								<ShareMatchModal
									match={existingCandidateMatch}
									onClose={() => dispatch(SiteActions.hideModal())}
									onSave={async matchData => {
										try {
											await CandidatesAPI.updateCandidateMatch({
												candidateId,
												matchId: existingCandidateMatch.id,
												match: {
													...matchData,
												},
											});

											await refetchMatches();
										} catch (e) {
											console.log(e);
										}
									}}
								/>,
								{
									className: 'modal-small',
								},
							),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Share color="#ddd" />
					</ListItemIcon>
					Share
				</MenuItem>
				<MenuItem
					disabled={!candidateMatchExists || editingCandidateMatch}
					onClick={() => {
						dispatch(
							SiteActions.showModal(
								<RateInterestModal
									match={existingCandidateMatch}
									onSave={async levelId => {
										setEditingCandidateMatch(true);
										try {
											await CandidatesAPI.updateCandidateMatch({
												candidateId,
												matchId: existingCandidateMatch?.id,
												match: {
													// ...existingCandidateMatch,
													interest_level_id: levelId,
												},
											});

											await refetchMatches();
										} catch (e) {
											console.log(e);
										} finally {
											setEditingCandidateMatch(false);
											handleClose();
										}
										console.log('Saving!');
									}}
								/>,
								{
									className: 'modal-small',
								},
							),
						);
						handleClose();
					}}
				>
					<ListItemIcon>
						<Icons.ThumbUpOutlined color="#ddd" />
					</ListItemIcon>
					Rate Candidate Interest
				</MenuItem>

				<MenuItem
					disabled
					style={{
						fontSize: 14,
						fontWeight: 700,
						opacity: 0.35,
						borderTop: '2px solid #ddd',
						marginTop: 8,
						paddingTop: 15,
					}}
				>
					CANDIDATE
				</MenuItem>
				<MenuItem
					disabled={editingListingMatch}
					// disabled={editingListingMatch || !candidate?.is_sharable}
					onClick={async () => {
						setEditingListingMatch(true);
						try {
							if (!listingMatchExists) {
								console.log('Adding to candidate...');
								const newMatch = await ListingsAPI.addListingMatch(
									{
										candidateId,
										listingId,
									},
									results => {
										console.log(results);
									},
								);
							} else {
								await ListingsAPI.deleteListingMatch({
									listingId,
									matchId: existingListingMatch?.id,
								});
							}

							await refetchMatches();
						} catch (e) {
							console.log(e);
						} finally {
							setEditingListingMatch(false);
							// handleClose();
						}
					}}
				>
					<ListItemIcon>
						{listingMatchExists ? <MinusIcon color="red" /> : <PlusIcon color="var(--teal-100)" />}
					</ListItemIcon>
					{listingMatchExists ? 'Remove from Job' : 'Save to Job'}
				</MenuItem>
				<MenuItem
					disabled={!listingMatchExists || editingListingMatch}
					onClick={async () => {
						dispatch(
							SiteActions.showModal(
								<ShareMatchModal
									match={existingListingMatch}
									onClose={() => dispatch(SiteActions.hideModal())}
									onSave={async matchData => {
										try {
											await ListingsAPI.updateListingMatch({
												listingId: existingListingMatch?.listing_id,
												matchId: existingListingMatch?.id,
												match: {
													...matchData,
												},
											});

											await refetchMatches();
										} catch (e) {
											console.log(e);
										}
									}}
								/>,
								{
									className: 'modal-small',
								},
							),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Share color="#ddd" />
					</ListItemIcon>
					Share
				</MenuItem>
				<MenuItem
					disabled={!listingMatchExists || editingListingMatch}
					onClick={() => {
						dispatch(
							SiteActions.showModal(
								<RateInterestModal
									match={existingListingMatch}
									onSave={async levelId => {
										setEditingListingMatch(true);
										try {
											await ListingsAPI.updateListingMatch({
												listingId,
												matchId: existingListingMatch?.id,
												match: {
													// ...existingCandidateMatch,
													interest_level_id: levelId,
												},
											});

											await refetchMatches();
										} catch (e) {
											console.log(e);
										} finally {
											setEditingListingMatch(false);
											handleClose();
										}
										console.log('Saving!');
									}}
								/>,
								{
									className: 'modal-small',
								},
							),
						);
						handleClose();
					}}
				>
					<ListItemIcon>
						<Icons.ThumbUpOutlined color="#ddd" />
					</ListItemIcon>
					Rate Client Interest
				</MenuItem>
			</Menu>
		</>
	);
};

export default DetailsModal;

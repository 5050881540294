import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import RequiredSkillsForm from './RequiredSkillsForm';
import HeroSkillsForm from './HeroSkillsForm';
import HeroSkillsExperienceForm from './HeroSkillsExperienceForm';
import PreferredSkillsForm from './PreferredSkillsForm';
import BonusSkillsForm from './BonusSkillsForm';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';

const SkillStoryForm = ({ setJobProfile, getJobDescriptions }) => {
	const pageNum = 4;
	const [isLoading, setIsLoading] = useState(false);
	const [storyStep, setStoryStep] = useState(1);
	const store = useStore();
	const { id: listingID } = useParams();
	const { listing } = useSelector(state => state.assessment, shallowEqual);

	const prevStep = () =>
		storyStep === 1 ? store.dispatch(AssessmentActions.setPage(pageNum - 1)) : setStoryStep(s => s - 1);

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setListing(response.data.data));
			setIsLoading(false);
		});
	}, []);

	if (isLoading || !listing)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	return (
		<>
			{storyStep === 1 && <RequiredSkillsForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 2 && <HeroSkillsForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 3 && <HeroSkillsExperienceForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 4 && <PreferredSkillsForm prevStep={prevStep} setStoryStep={setStoryStep} />}
			{storyStep === 5 && (
				<BonusSkillsForm prevStep={prevStep} setJobProfile={setJobProfile} getJobDescriptions={getJobDescriptions} />
			)}
		</>
	);
};

SkillStoryForm.propTypes = {
	setJobProfile: PropTypes.func,
	getJobDescriptions: PropTypes.func,
};

SkillStoryForm.defaultProps = {
	setJobProfile: () => null,
	getJobDescriptions: () => null,
};

export default SkillStoryForm;

import React from 'react';
import { Grid } from '@material-ui/core';
import { Panel } from '../../../components/Panel';
import ViewScreeningQuestionsBlock from './ViewScreeningQuestionsBlock';
import ViewClientFeedbackBlock from './ViewClientFeedbackBlock';
import * as Utility from '../../../scripts/utility';

const Block = ({ job, setJob }) => {
	const [questionsMinimized, setQuestionsMinimized] = Utility.useStickyState(
		false,
		'job-view-screening-questions-minimized',
	);
	const [feedbackMinimized, setFeedbackMinimized] = Utility.useStickyState(false, 'job-view-feedback-minimized');
	return (
		<Grid item xs={12}>
			<Panel topBarColor="blue">
				<ViewScreeningQuestionsBlock
					job={job}
					setJob={setJob}
					minimized={questionsMinimized}
					setMinimized={setQuestionsMinimized}
				/>
				<ViewClientFeedbackBlock
					job={job}
					setJob={setJob}
					minimized={feedbackMinimized}
					setMinimized={setFeedbackMinimized}
				/>
			</Panel>
		</Grid>
	);
};

export default Block;

import React, { useState, forwardRef } from 'react';
import { TextField, Box } from '@material-ui/core';

const TextFieldWithCounter = forwardRef((props, ref) => {
	const { onFocus, onBlur, helperText, ...other } = props;

	const [visible, setVisible] = useState(false);

	return (
		<TextField
			ref={ref}
			{...other}
			onFocus={event => {
				setVisible(true);
				if (onFocus) onFocus(event);
			}}
			onBlur={event => {
				setVisible(false);
				if (onBlur) onBlur(event);
			}}
			helperText={
				<Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<span>{helperText}</span>
					{visible && <span>{`${props.value?.length || 0} / ${props.inputProps?.maxLength}`}</span>}
				</Box>
			}
		/>
	);
});

export default TextFieldWithCounter;

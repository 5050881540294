import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Grid, TextField, Button, Typography, ButtonBase } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import WorkLocationDropdown from '../../../components/form/dropdowns/WorkLocationDropdown';
import OfferRelocationDropdown from '../../../components/form/dropdowns/OfferRelocationDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import VerticalSpacer from '../../../components/VerticalSpacer';
import PlusIcon from '../../../components/icons/PlusIcon';
import LocationIcon from '../../../components/icons/LocationIcon';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';

const EditLocationModal = ({ listing, setListing }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [updatedListing, setUpdatedListing] = useState(listing);
	const updateListing = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedListing({ ...updatedListing, ...newValue });
	};

	const saveListing = () => {
		setIsSaving(true);
		const forgedListing = ListingsAPI.getForgedListing(updatedListing);
		const forgedCurrentListing = ListingsAPI.getForgedListing(listing);
		const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentListing, forgedListing);

		ListingsAPI.updateListing(listing.id, differencesToSubmit, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const newListing = response.data.data;
			setListing(newListing);
			dispatch(SiteActions.hideModal());
		});
	};

	const workLocationPreferenceValue = useMemo(
		() => updatedListing?.work_location_preferences?.map(item => (item.id ? item.id : item)),
		[updatedListing.work_location_preferences],
	);

	const hasLocalSelection = useMemo(() => workLocationPreferenceValue?.some(pid => [2, 3, 4, 5].includes(pid)), [
		workLocationPreferenceValue,
	]);

	const hasRemoteSelection = useMemo(() => workLocationPreferenceValue?.includes(1), [workLocationPreferenceValue]);

	const setListingLocations = (index, name, value) => {
		const updatedOfficeLocations = [...updatedListing.office_locations];
		updatedOfficeLocations[index] = { ...(updatedListing.office_locations || [])[index], [name]: value };
		setUpdatedListing({
			...updatedListing,
			office_locations: updatedOfficeLocations,
		});
	};

	const addOfficeLocation = () => {
		const updatedOfficeLocations = [...(updatedListing.office_locations || []), { city: null, state_id: null }];

		setUpdatedListing({
			...updatedListing,
			office_locations: updatedOfficeLocations,
		});
	};

	const removeOfficeLocation = location => {
		const updatedOfficeLocations = updatedListing.office_locations.filter(
			l => !(l.city === location.city && l.state_id === location.state_id),
		);
		setUpdatedListing({
			...updatedListing,
			office_locations: updatedOfficeLocations,
		});
	};

	return (
		<>
			<Panel Icon={<LocationIcon size={32} className="teal-100" />}>
				<PanelHeader className="spread">
					<Typography variant="body2" component="h2" className="text-bold" style={{ paddingTop: 4 }}>
						Work Location Preferences
					</Typography>
				</PanelHeader>
				<PanelContent>
					<Grid container spacing={2}>
						<Grid item xs={5}>
							<LabeledField label="Work Locations" removeMargin required>
								<WorkLocationDropdown
									variant="outlined"
									name="work_location_preferences"
									value={workLocationPreferenceValue}
									placeholder={updatedListing?.work_location_preferences?.length > 0 ? '' : 'Select all that apply'}
									onChange={updateListing}
									fullWidth
									multiple
									renderOption={(option, { selected }) => {
										return (
											<div>
												<Typography bold className="text-bold grey-300">
													{option.label}
												</Typography>
												<Typography bold className="grey-300">
													{option.description}
												</Typography>
											</div>
										);
									}}
									error={!updatedListing.work_location_preferences.length}
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LabeledField label="Approved Remote Locations" removeMargin required={hasRemoteSelection}>
								<StatesDropdown
									variant="outlined"
									name="remote_states"
									placeholder={updatedListing?.remote_states?.length > 0 ? '' : 'Select all that apply'}
									value={
										hasRemoteSelection
											? updatedListing?.remote_states?.map(item => (item.state_id ? item.state_id : item))
											: null
									}
									onChange={updateListing}
									disabled={!hasRemoteSelection}
									fullWidth
									multiple
									useID
									error={
										hasRemoteSelection &&
										!updatedListing?.remote_states?.map(item => (item.state_id ? item.state_id : item)).length
									}
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={5}>
							<LabeledField label="Offer Candidate Relocation" required={hasLocalSelection}>
								<OfferRelocationDropdown
									variant="outlined"
									name="offer_relocation"
									value={hasLocalSelection ? updatedListing.offer_relocation : null}
									placeholder="Choose from our list"
									onChange={updateListing}
									disabled={!hasLocalSelection}
									error={hasLocalSelection && !updatedListing.offer_relocation}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LabeledField label="Eligible Office Sites" required={hasLocalSelection}>
								{(updatedListing.office_locations?.length
									? updatedListing.office_locations
									: [{ city: null, state_id: null }]
								).map((location, index) => (
									<Grid container spacing={2}>
										<Grid item xs={7}>
											<TextField
												variant="outlined"
												name="city"
												placeholder="City"
												value={updatedListing?.office_locations[index]?.city || ''}
												onChange={e => setListingLocations(index, e.target.name, e.target.value)}
												disabled={!hasLocalSelection}
												error={hasLocalSelection && !updatedListing?.office_locations[index]?.city}
												fullWidth
											/>
										</Grid>
										<Grid item xs={4}>
											<StatesDropdown
												variant="outlined"
												name="state_id"
												placeholder="State"
												value={updatedListing?.office_locations[index]?.state_id}
												onChange={e => setListingLocations(index, e.target.name, e.target.value)}
												disabled={!hasLocalSelection}
												error={hasLocalSelection && !updatedListing?.office_locations[index]?.state_id}
												fullWidth
												useAbbreviation
												useID
											/>
										</Grid>
										<Grid
											item
											xs={1}
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<ButtonBase className="button-cancel" onClick={() => removeOfficeLocation(location)}>
												<Icons.Cancel style={{ marginTop: -3 }} />
											</ButtonBase>
										</Grid>
									</Grid>
								))}
								<VerticalSpacer height={0.5} />
								<ButtonBase
									className="button-show-more"
									onClick={() => addOfficeLocation()}
									disabled={!hasLocalSelection}
								>
									<PlusIcon />
									ADD LOCATION
								</ButtonBase>
							</LabeledField>
						</Grid>

						<Grid item xs={12}>
							<div style={{ marginTop: -10 }} />
							<AddAdditionalInfo
								additionalInfo={updatedListing.additional_work_location_details}
								setAdditionalInfo={newAdditionalInfo => {
									updateListing({
										target: {
											name: 'additional_work_location_details',
											value: newAdditionalInfo,
										},
									});
								}}
							/>
						</Grid>
					</Grid>
					<div className="text-right">
						<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
							Cancel
						</Button>
						<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveListing}>
							Save
						</LoadingButton>
					</div>
				</PanelContent>
			</Panel>
		</>
	);
};

EditLocationModal.propTypes = {
	listing: PropTypes.shape({ id: PropTypes.string }),
	setListing: PropTypes.func,
};

EditLocationModal.defaultProps = {
	listing: { id: null },
	setListing: () => null,
};

export default EditLocationModal;

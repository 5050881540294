/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function RecruiterIcon({ style, color = 'currentColor', size = 28, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 28 28"
			style={style}
			className={className}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
				<g stroke={color} strokeWidth="1.25" transform="translate(-176 -1276)">
					<g transform="translate(177 1277)">
						<path d="M4.687 23.111h0a5.281 5.281 0 015.28-5.28h6.065a5.281 5.281 0 015.28 5.28h0" />
						<path d="M17.988 9.321a4.988 4.988 0 11-9.976 0 4.988 4.988 0 019.976 0z" />
						<path d="M15.889 23.111h7.222A2.888 2.888 0 0026 20.222V2.89A2.888 2.888 0 0023.111 0H2.89A2.888 2.888 0 000 2.889v17.333a2.888 2.888 0 002.889 2.89h7.222L13 26l2.889-2.889z" />
					</g>
				</g>
			</g>
		</svg>
	);
}

import React from 'react';
import { Tooltip, TableCell, TableRow, makeStyles } from '@material-ui/core';
import { formatDate, formatTime, formatFieldName, findNonJoinValue, FIELD_SPACING } from './utilities';

const useStyles = makeStyles(() => ({
	fieldName: {
		minHeight: '20px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	cellContent: {
		marginTop: props => (props.fieldValueIndex > 0 ? FIELD_SPACING : 0),
		minHeight: '20px',
	},
	row: {
		backgroundColor: props => (props.rowIndex % 2 === 0 ? '#f8f8f8' : 'white'),
	},
	verticalAlignTop: {
		verticalAlign: props => (props.verticalAlignTop ? 'top' : 'inherit'),
	},
}));

/**
 * Determines what content should be rendered within each cell. If the value is an array, it will join the array
 * with a comma and space, defaulting to concatenating the labels, then IDs, then just the value with a comma.
 *
 * If the field isn't an array (such as travel_willingness_type_id), it will find the label of the value from the
 * findNonJoinValue function.
 *
 * @param {Object} values - The old or new values of the log.
 * @param {Array} field - The unique fields of the log.
 * @param {Object} data - The store that contains all results from /all endpoint.
 * @returns {JSX.Element} - The content of the cell.
 */
function renderFieldValues(values, field, data) {
	return Array.isArray(values[field])
		? values[field]
				.map(v => {
					if (v.label) {
						return v.label;
					}
					if (v.id) {
						return v.id;
					}
					return v;
				})
				.join(', ')
		: findNonJoinValue(field, values[field], data);
}

/**
 * Renders the content of the cell for both the old and new values of a log item.
 */
function RenderCellContent({ values, uniqueFields, data, width }) {
	const outsideClasses = useStyles({ FIELD_SPACING });

	return (
		<TableCell style={{ borderRight: '1px solid #e3e3e3', width }} className={outsideClasses.verticalAlignTop}>
			{uniqueFields?.map((field, fieldValueIndex) => {
				const classes = useStyles({ FIELD_SPACING, fieldValueIndex });
				return (
					<div key={field} className={classes.cellContent}>
						{renderFieldValues(values, field, data)}
					</div>
				);
			})}
		</TableCell>
	);
}

/**
 * Renders a single data row in the audit log table (ie not the header row).
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.log - The log item to render.
 * @param {number} props.rowIndex - The index of the row in the table.
 * @param {Object} props.data - The store that contains all results from /all endpoint.
 * @param {string[]} props.selectedFieldFilter - The filtered fields to be shown
 * @returns {JSX.Element} - The table row.
 */
function AuditTableRow({ log, rowIndex, data, selectedFieldFilter, widths }) {
	const fields = Object.keys(log.old_values).concat(Object.keys(log.new_values));
	const uniqueFields = [...new Set(fields)].filter(
		field =>
			field !== 'last_profile_update' && (selectedFieldFilter.length === 0 || selectedFieldFilter.includes(field)),
	);

	const verticalAlignTop = uniqueFields.length > 1;
	const classes = useStyles({ rowIndex, verticalAlignTop });

	const { date: dateWidth, user: userWidth, field: fieldWidth, from: fromWidth, to: toWidth } = widths;

	const logUserName = `${log.user?.first_name || ''} ${log.user?.last_name || ''}`;

	const userNameToUse = logUserName === 'Chad Windnagle' ? 'Sync Update' : logUserName;

	return (
		<TableRow key={rowIndex} className={classes.row}>
			<TableCell
				className={classes.verticalAlignTop}
				style={{ width: dateWidth, paddingRight: 0, flexWrap: 'wrap', borderRight: '1px solid #e3e3e3' }}
			>
				<div style={{ justifyContent: 'space-between' }}>
					{formatDate(log.created_at)} {formatTime(log.created_at)}
				</div>
			</TableCell>
			<TableCell className={classes.verticalAlignTop} style={{ width: userWidth, borderRight: '1px solid #e3e3e3' }}>
				{userNameToUse}
			</TableCell>

			<TableCell className={classes.verticalAlignTop} style={{ borderRight: '1px solid #e3e3e3', width: fieldWidth }}>
				{uniqueFields?.map((field, fieldIndex) => (
					<Tooltip key={field} title={formatFieldName(field)} placement="top" arrow>
						<div
							className={classes.fieldName}
							style={{
								marginTop: fieldIndex > 0 ? FIELD_SPACING : 0,
							}}
						>
							{formatFieldName(field)}
						</div>
					</Tooltip>
				))}
			</TableCell>
			<RenderCellContent values={log.old_values} uniqueFields={uniqueFields} data={data} width={fromWidth} />
			<RenderCellContent values={log.new_values} uniqueFields={uniqueFields} data={data} width={toWidth} />
		</TableRow>
	);
}

export default AuditTableRow;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { Typography, TextField, Fab, CircularProgress, Box, Chip } from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import LoadingButton from '../form/LoadingButton';
import CheckboxInput from '../form/inputs/CheckboxInput';
import Dropdown from '../form/dropdowns/Dropdown';

import LaughReaction from '../icons/Reactions/Laugh';
import SmileReaction from '../icons/Reactions/Smile';
import NeutralReaction from '../icons/Reactions/Neutral';
import SadReaction from '../icons/Reactions/Sad';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import LabeledField from '../form/LabeledField';
import JobAssessmentSidebar from '../page/sidebar/JobAssessmentSidebar';

const AddMatchModal = ({ match, onAdd, onClose, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const sharedMatchAccessLevels = data.sharedMatchAccessLevels || [];

	const [jobOptions, setJobOptions] = useState([]);
	const [selectedJobId, setSelectedJobId] = useState(null);

	const store = useStore();
	const [isLoading, setLoading] = useState(false);
	const [query, setQuery] = useState(null);

	const [isSearching, setIsSearching] = useState(false);

	const search = async () => {
		if (!isSearching && query) {
			try {
				setIsSearching(true);

				const [nameResults, companyResults, vendorIdResults, idResults] = await Promise.all([
					// Get name results
					new Promise((resolve, reject) =>
						ListingsAPI.getListings(
							{
								position_title: query,
								orderBy: 'listings.vendor_job_id',
								direction: 'desc',
								per_page: 50,
							},
							async (response, err) => {
								if (err) {
									resolve([]);
								}

								const jobs = response?.data?.data;
								resolve(jobs);
							},
						),
					),

					// Get company results
					new Promise((resolve, reject) =>
						ListingsAPI.getListings(
							{
								company: query,
								orderBy: 'listings.vendor_job_id',
								direction: 'desc',
								per_page: 50,
							},
							async (response, err) => {
								if (err) {
									resolve([]);
								}

								const jobs = response?.data?.data;
								resolve(jobs);
							},
						),
					),

					// Get vendor ID results
					new Promise((resolve, reject) =>
						ListingsAPI.getListings(
							{
								vendor_job_id: query,
							},
							async (response, err) => {
								if (err) {
									resolve([]);
								}

								const jobs = response?.data?.data;
								resolve(jobs);
							},
						),
					),

					// Get ID results
					new Promise((resolve, reject) =>
						ListingsAPI.getListing(query, async (response, err) => {
							if (err) {
								resolve([]);
							}

							const job = response?.data?.data;
							resolve([job]);
						}),
					),
				]);

				const jobs = [...nameResults, ...companyResults, ...vendorIdResults, ...idResults]
					.filter(job => job)
					.filter((value, index, arr) => {
						return arr.findIndex(v => v.id === value.id) === index;
					})
					.sort((a, b) => {
						return b.vendor_job_id - a.vendor_job_id;
					});
				setJobOptions(jobs);
			} catch (e) {
				console.error(e);
			} finally {
				setIsSearching(false);
			}
		}
	};

	return (
		<div style={{ textAlign: 'center' }}>
			<Typography variant="h2" style={{ marginBottom: 5 }}>
				Add a Job Match to the Candidate
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 15 }}>
				Search and select a job to add to the candidate record.
			</Typography>

			<LabeledField label="Job ID, Title, or Company" required removeMargin>
				<Box display="flex" justifyContent="stretch" marginBottom="48px" alignItems="center">
					<TextField
						style={{ flex: 1 }}
						variant="outlined"
						name="first_name"
						value={query}
						onChange={({ target }) => setQuery(target.value)}
						error={!query}
						fullWidth
						placeholder="Job ID, Job Title, or Company"
					/>
					<div style={{ width: 20 }} />
					<Fab color="primary" onClick={search} aria-label="search">
						{isSearching ? <CircularProgress size={24.5} style={{ color: 'white' }} /> : <Icons.Search />}
					</Fab>
				</Box>
			</LabeledField>

			{jobOptions ? (
				<Box marginBottom="48px">
					<LabeledField label="Job to Add">
						<Dropdown
							// getOptionDisabled={option => !option?.is_sharable}
							noOptionsText="Search is limited to 50 options. You may need to refine your search."
							data={[...jobOptions]
								.sort((a, b) => {
									return b.vendor_candidate_id - a.vendor_candidate_id;
								})
								.map(c => ({
									id: c.id,
									label: c.position_title,
									vendor_id: c.vendor_job_id,
									company: c.company,
									is_sharable: c.is_sharable,
								}))}
							value={selectedJobId}
							name="candidate"
							onChange={ev => {
								// if (jobOptions?.find(x => x.id === ev.target.value)?.is_sharable) {
								setSelectedJobId(ev.target.value);
								// }
							}}
							renderOption={(option, state) => {
								console.log({ option });
								return (
									<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
										<Box flexGrow={1}>
											<Typography variant="caption" color="textSecondary" display="inline" style={{ marginRight: 5 }}>
												#{option.vendor_id}
											</Typography>
											<Typography display="inline">
												<b>{option.label}</b>
											</Typography>
											<Typography>
												{/* {option.status}
											{option.status && option.company ? ' - ' : ''} */}
												{option.company}
											</Typography>
										</Box>
										{option?.is_sharable ? (
											<Chip color="secondary" size="small" label="Share" style={{ margin: 0 }} />
										) : null}
									</Box>
								);
							}}
						/>
					</LabeledField>
				</Box>
			) : null}

			<div style={{ height: 25 }} />

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<LoadingButton
					disabled={isLoading}
					variant="outlined"
					color="primary"
					onClick={async () => {
						onClose();
					}}
				>
					Cancel
				</LoadingButton>
				<div style={{ width: 10 }} />
				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					disabled={!selectedJobId}
					onClick={async () => {
						setLoading(true);
						await onAdd(selectedJobId);
						setLoading(false);
					}}
				>
					Save
				</LoadingButton>
			</div>
		</div>
	);
};

export default AddMatchModal;

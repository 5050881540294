import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Card, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import { useParams } from 'react-router';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import CandidateDeleteModal from '../../components/modals/CandidateDeleteModal';
import CandidateCardList from '../../components/matching/CandidateCardList';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';

import { useContextStore } from '../../store/ContextStore';

const JobMatches = () => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);

	const [filter, setFilter] = Utility.useStickyState({ isOpen: true, filters: {} }, 'admin-job-matches-filter');
	const table = useRef();

	const { jobMatches: matches, setJobMatches: setMatches, refetchMatches } = useContextStore();

	const [graphMatches, setGraphMatches] = useState([]);

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const updateFilters = useCallback(
		(ev, name) => {
			if (typeof ev === 'object' && typeof name === 'string') {
				setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
			} else if (ev.target.type === 'checkbox') {
				setFilter({
					...filter,
					filters: {
						...filter.filters,
						[ev.target.name]: ev.target.checked ? ev.target.value : undefined,
					},
				});
			} else if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value !== '') {
				setFilter({
					...filter,
					filters: { ...filter.filters, [ev.target.name]: ev.target.value },
				});
			} else {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
			}
		},
		[filter, setFilter],
	);

	// const sendAssessment = ev => {
	// 	setMenu({ ...menu, anchor: null });

	// 	CandidatesAPI.sendAssessment(menu.candidate.id, response => {
	// 		if (!response) {
	// 			store.dispatch(SiteActions.showAlert('A portal invitation could not be sent. Please try again.', 'error'));
	// 			return;
	// 		}

	// 		store.dispatch(SiteActions.showAlert('A portal invitation was emailed to the job contact.', 'success'));
	// 	});
	// };

	const filteredGraphMatches = graphMatches;

	const params = useParams();
	const listingID = params.id;
	const [job, setJob] = useState(null);
	// const [candidates, setCandidates] = useState([]);
	const [loadingCandidates, setLoadingCandidates] = useState(true);
	// const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);
	const [menu, setMenu] = useState({ anchor: null, candidate: {} });

	useEffect(() => {
		// Get the selected listing
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'));
				return;
			}
			const newJob = response.data.data;
			setJob(newJob);
		});

		// Get mock candidates list
		// CandidatesAPI.getCandidates({}, response => {
		// 	setCandidates(response?.data?.data);
		// });

		ListingsAPI.getListingRandomGraphMatches({ listingId: listingID }).then(async response => {
			const listingRandomGraphMatches = response?.data?.data;

			setGraphMatches(listingRandomGraphMatches);
			setLoadingCandidates(false);
		});

		// Get matches
		setMatches(null);
		refetchMatches({ jobId: listingID });
	}, []);

	return (
		<Page filterPadding={false}>
			{/* <PageHeader.Left>
					<Typography variant="h1">Job Matches</Typography>
				</PageHeader.Left> */}

			{job && (
				<>
					<Card style={{ width: '100%' }}>
						<Typography variant="h3" style={{ marginBottom: 15 }}>
							<NavLink to={`/job/${job.id}`} className="color-inherit">
								Match Testing: {job?.position_title}
							</NavLink>
						</Typography>
					</Card>
				</>
			)}
			<PageHeader.Right>
				{/*
					<Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(
								SiteActions.showModal(
									<CandidateSyncModal category={menu.category} onSync={table.current.refreshData} />,
								),
							)
						}
						startIcon={<Icons.Sync />}
					>
						Sync Candidate
					</Button> */}
			</PageHeader.Right>
			<CandidateCardList
				jobId={listingID}
				// candidates={filteredCandidates}
				refetchMatches={() => refetchMatches({ jobId: listingID })}
				// matches={matches}
				graphMatches={filteredGraphMatches}
				loading={loadingCandidates}
				pageSize={40}
				disableLink
				hideAdd
				filterComponent={null}
				hideItemDetails
				autoSelectAll
				enableSelectAll
				enableRefresh
			/>

			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/candidate/${menu.candidate.id}/profile`} className="color-inherit" target="_blank">
					<MenuItem>
						<ListItemIcon>
							<Icons.Person color="primary" />
						</ListItemIcon>
						Candidate View
					</MenuItem>
				</NavLink>
				<NavLink to={`/candidate/${menu.candidate.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Candidate
					</MenuItem>
				</NavLink>
				<MenuItem onClick={() => {}}>
					<ListItemIcon>
						<Icons.Assignment color="primary" />
					</ListItemIcon>
					E-mail Profile Link
				</MenuItem>
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(
							SiteActions.showModal(
								<CandidateDeleteModal candidate={menu.candidate} onDelete={() => table.current.refreshData()} />,
							),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Candidate
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default JobMatches;

import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../form/LoadingButton';
import CheckboxInput from '../form/inputs/CheckboxInput';
import { useContextStore } from '../../store/ContextStore';

import * as Utility from '../../scripts/utility';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';

const getDaysInFuture = stringDate => {
	const expirationDate = new Date(stringDate);
	const daysInFuture = (expirationDate.getTime() - Date.now()) / (24 * 60 * 60 * 1000);
	return daysInFuture;
};

let copyTimeout = null;
let saveTimeout = null;

const ShareMatchModal = ({ match, model, onSave }) => {
	const { user } = useSelector(state => state.site, shallowEqual);
	const { role } = useContextStore();

	const [shareLink, setShareLink] = useState(null);
	const [isCopied, setIsCopied] = useState(null);
	const [isShareLinkGenerating, setIsShareLinkGenerating] = useState(false);
	const [isPublished, setIsPublished] = useState(match?.is_published);
	const [isCompanySharable, setIsCompanySharable] = useState(match?.reveal_company);
	const [isSalarySharable, setIsSalarySharable] = useState(match?.reveal_compensation);
	const [displaySaved, setDisplaySaved] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [displaySent, setDisplaySent] = useState(false);

	const isCandidateMatch = match?.destination_model.toLowerCase().includes('listing');
	const isFullAccess = isCandidateMatch
		? match?.reveal_compensation && match?.reveal_company
		: match?.reveal_compensation;

	const matchIsShareable = model
		? model.is_sharable
		: role === 'recruiter'
		? // match?.destination_model?.toLowerCase()?.includes('listing')
		  match?.listing?.is_sharable
		: match?.candidate?.is_sharable;

	const recordSave = () => {
		if (saveTimeout) {
			clearTimeout(saveTimeout);
		}
		setDisplaySaved(true);
		saveTimeout = setTimeout(() => {
			setDisplaySaved(false);
		}, 2000);
	};

	const fullShareLink = shareLink
		? `${window.location.origin}/${isCandidateMatch ? 'candidate' : 'contact'}/${
				isCandidateMatch ? match?.candidate_id : match?.listing?.contacts?.[0]?.id
		  }/profile?authUrl=${encodeURIComponent(shareLink)}&matchId=${match?.id}`
		: '';

	useEffect(() => {
		if (isPublished) {
			// Get any already-created share links.
			(isCandidateMatch
				? CandidatesAPI.getCandidateMatchShareLinks({
						candidateId: match?.candidate_id,
						matchId: match.id,
				  })
				: ListingsAPI.getListingMatchShareLinks({
						listingId: match?.listing_id,
						matchId: match.id,
				  })
			)
				.then(response => {
					const responseLinks = response?.data?.data || [];

					// Look for an existing share link.
					let existingLinkFound = false;
					if (responseLinks?.length) {
						responseLinks.forEach(_ => {
							if (!existingLinkFound) {
								// Only use the existing share link if it expires at least 7 days in the future.
								const days = getDaysInFuture(responseLinks[responseLinks?.length - 1]?.expires_at);

								if (days > 7) {
									// If we found a valid share link, use it and return.
									existingLinkFound = true;
									setShareLink(responseLinks[responseLinks?.length - 1]?.link_hash);
									console.log("Found existing share link that doesn't expire for at least 7 days.");
									return null;
								}
								console.log(`Share link ignored: it expires in ${days} days.`);
							}
							return null;
						});
					}

					// If we didn't find an existing share link, create a new one.
					if (!existingLinkFound) {
						console.log("Didn't find an existing share link. Creating a new one.");

						setIsShareLinkGenerating(true);
						return (isCandidateMatch
							? CandidatesAPI.createCandidateMatchShareLink({
									candidateId: match?.candidate_id,
									matchId: match.id,
							  })
							: ListingsAPI.createListingMatchShareLink({
									listingId: match?.listing_id,
									matchId: match.id,
							  })
						)
							.then(resp => setShareLink(resp?.data?.data?.link_hash))
							.catch(console.log)
							.finally(() => {
								setIsShareLinkGenerating(false);
							});
					}

					return null;
				})
				.catch(console.log);
		}
	}, [isPublished]);

	return (
		<div>
			<Typography variant="h2" style={{ marginBottom: 5 }}>
				Share Match
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 15 }}>
				Share a {isCandidateMatch ? 'job' : 'candidate'} profile directly with a{' '}
				{isCandidateMatch ? 'candidate' : 'client'} and let them review it from their NauMatch profile!
			</Typography>

			{(!isCandidateMatch && match.listing?.owner?.email !== user?.email) ||
			(isCandidateMatch && match.candidate?.owner?.email !== user?.email) ? (
				<Alert severity="error">
					You are not the owner of this {isCandidateMatch ? 'candidate' : 'listing'}.<br /> Are you sure you want to
					share the record anyway?
				</Alert>
			) : null}
			<CheckboxInput
				type="checkbox"
				name="is_published"
				value="true"
				checked={isPublished && matchIsShareable}
				disabled={!matchIsShareable}
				onChange={async e => {
					setIsPublished(e.target.checked);
					const newAccessLevelId = isFullAccess ? 3 : 1;
					await onSave({
						access_level_id: newAccessLevelId,
						is_published: e.target.checked,
					});

					recordSave();
				}}
				label={`Share this ${isCandidateMatch ? 'job' : 'candidate'} with the ${
					isCandidateMatch ? 'candidate' : 'client'
				}`}
			/>

			{isPublished && matchIsShareable ? (
				<>
					<div style={{ marginLeft: 50 }}>
						<CheckboxInput
							type="checkbox"
							name="public-sections"
							value="true"
							checked={isPublished && matchIsShareable}
							disabled={!isPublished && !matchIsShareable}
							onChange={async e => {
								setIsPublished(e.target.checked);
								const newAccessLevelId = isFullAccess ? 3 : 1;
								await onSave({
									access_level_id: newAccessLevelId,
									is_published: e.target.checked,
								});

								recordSave();
							}}
							label="Public Sections (default)"
						/>
						<CheckboxInput
							type="checkbox"
							name="salary-range"
							value="true"
							checked={isSalarySharable}
							onChange={async e => {
								const newIsSalarySharable = e.target.checked;
								const newAccessLevelId = newIsSalarySharable ? 3 : 1;
								setIsSalarySharable(newIsSalarySharable);
								await onSave({
									access_level_id: newAccessLevelId,
									is_published: isPublished,
									reveal_compensation: newIsSalarySharable,
								});

								recordSave();
							}}
							label="Salary Range & Other Compensation"
						/>
						{isCandidateMatch && (
							<CheckboxInput
								type="checkbox"
								name="company-name"
								value="true"
								checked={isCompanySharable}
								onChange={async e => {
									const newIsCompanySharable = e.target.checked;
									const newAccessLevelId = newIsCompanySharable ? 3 : 1;
									setIsCompanySharable(newIsCompanySharable);
									await onSave({
										access_level_id: newAccessLevelId,
										is_published: isPublished,
										reveal_company: newIsCompanySharable,
									});

									recordSave();
								}}
								label="Company Name"
							/>
						)}
					</div>
					<div style={{ height: 25 }} />
					{match?.last_email_shared_at && (
						<Typography variant="body2" style={{ marginBottom: 25 }}>
							Email Last Sent: {Utility.formatDate(match?.last_email_shared_at, true, 'M/DD/YY')}{' '}
							{match?.last_email_shared_by_user
								? `by ${match.last_email_shared_by_user.first_name} ${match.last_email_shared_by_user.last_name}`
								: ''}
						</Typography>
					)}
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
						{!shareLink ? (
							<LoadingButton
								loading={isShareLinkGenerating}
								color="primary"
								disabled={!isPublished}
								style={{ marginLeft: 0, flex: 1 }}
								onClick={async () => {
									// Create a share link.
									setIsShareLinkGenerating(true);
									CandidatesAPI.createCandidateMatchShareLink({
										candidateId: match?.candidate_id,
										matchId: match.id,
									})
										.then(response => {
											console.log({ response });
											setShareLink(response?.data?.data?.link_hash);
										})
										.catch(console.log)
										.finally(() => {
											setIsShareLinkGenerating(false);
										});
								}}
							>
								<Icons.Link /> <div style={{ width: 10 }} />
								Generate Link
							</LoadingButton>
						) : (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
									gap: 20,
								}}
							>
								<LoadingButton
									loading={false}
									variant="outlined"
									color="primary"
									disabled={!isPublished}
									style={{ margin: 0, flex: 1 }}
									onClick={async () => {
										// Create a share link.
										navigator.clipboard.writeText(fullShareLink).then(
											() => {
												/* clipboard successfully set */
												setIsCopied(true);

												if (copyTimeout) {
													clearTimeout(copyTimeout);
												}
												copyTimeout = setTimeout(() => {
													setIsCopied(false);
												}, 1000);
											},
											() => {
												/* clipboard write failed */
											},
										);
									}}
								>
									{isCopied ? (
										<>
											<Icons.Check />
											<div style={{ width: 10 }} />
											Copied
										</>
									) : (
										<>
											<Icons.FileCopy />
											<div style={{ width: 10 }} />
											Copy Link
										</>
									)}
								</LoadingButton>
								<LoadingButton
									color="primary"
									loading={isSending}
									disabled={!isPublished || displaySent}
									style={{ margin: 0, flex: 1 }}
									onClick={async () => {
										if (!isSending) {
											try {
												setIsSending(true);

												if (isCandidateMatch) {
													await CandidatesAPI.sendMatchEmail({
														matchId: match?.id,
														candidateId: match?.candidate_id,
													});
												} else {
													await ListingsAPI.sendMatchEmail({
														matchId: match?.id,
														listingId: match?.listing_id,
													});
												}

												setDisplaySent(true);

												setTimeout(() => {
													setDisplaySent(false);
												}, 2000);
											} catch (e) {
												console.log(e);
											} finally {
												setIsSending(false);
											}
										}
									}}
								>
									{displaySent ? <Icons.Check /> : <Icons.SendOutlined />}
									<div style={{ width: 10 }} />
									{displaySent ? 'Sent' : 'Send Email'}
								</LoadingButton>
							</div>
						)}
					</div>
				</>
			) : null}

			<Typography
				variant="body2"
				style={{
					textAlign: 'end',
					marginTop: 15,
					marginBottom: -20,
					fontStyle: 'italic',
					opacity: displaySaved ? 100 : 0,
					transition: '.5s opacity ease',
				}}
			>
				✓ Saved
			</Typography>
		</div>
	);
};

export default ShareMatchModal;

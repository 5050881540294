import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Typography } from '@material-ui/core';
import LoadingButton from '../form/LoadingButton';

import LaughReaction from '../icons/Reactions/Laugh';
import SmileReaction from '../icons/Reactions/Smile';
import NeutralReaction from '../icons/Reactions/Neutral';
import SadReaction from '../icons/Reactions/Sad';

export const InterestIcon = ({ level, interestLevelId, ...props }) => {
	// const levelNum = level || data.interestLevels?.findIndex(x => x?.id === interestLevelId);

	switch (interestLevelId) {
		case 4:
			return (
				<>
					<LaughReaction {...props} />
				</>
			);
		case 3:
			return (
				<>
					<SmileReaction {...props} />
				</>
			);
		case 2:
			return (
				<>
					<NeutralReaction {...props} />
				</>
			);
		case 1:
			return (
				<>
					<SadReaction {...props} />
				</>
			);
		default:
			return null;
	}
};

const RateInterestModal = ({ match, onSave, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const [selectedInteretLevelId, setSelectedInterestLevelId] = useState(match?.interest_level_id);
	const [isLoading, setLoading] = useState(false);

	const isCandidateMatch = match.destination_model.toLowerCase().includes('listing');

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Rate {isCandidateMatch ? 'Candidate' : 'Client'} Interest
			</Typography>
			<Typography variant="body2" style={{ marginBottom: '2.5rem' }}>
				Provide feedback from the {isCandidateMatch ? 'candidate' : 'client'} on how they felt towards this match.
			</Typography>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					paddingBottom: 40,
				}}
			>
				{data.interestLevels.slice(0, 4).map((level, index) => (
					// eslint-disable-next-line jsx-a11y/no-static-element-interactions
					<div
						className="interest-level"
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							cursor: 'pointer',
							padding: 15,
							opacity: selectedInteretLevelId === level.id ? 1 : 0.7,
						}}
						onClick={() => {
							setSelectedInterestLevelId(level.id);
						}}
					>
						<InterestIcon
							interestLevelId={level.id}
							size={50}
							level={index + 1}
							color={selectedInteretLevelId === level.id ? 'var(--primaryColor)' : 'var(--mutedTextColor)'}
						/>
						<Typography variant="body2" style={{ marginTop: '0.5rem' }}>
							{level?.label}
						</Typography>
					</div>
				))}
			</div>

			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="primary"
				onClick={async () => {
					setLoading(true);
					await onSave(selectedInteretLevelId);
					setLoading(false);
				}}
				style={{ minWidth: 185 }}
			>
				Save Interest
			</LoadingButton>
		</div>
	);
};

export default RateInterestModal;

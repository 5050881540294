import React, { useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Typography, Button, IconButton, Menu, MenuItem, ListItemIcon, Tabs, Tab, Chip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import Table from '../../../components/Table';
import { Filter, FilterItem } from '../../../components/page/Filter';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import DebouncedInput from '../../../components/form/inputs/DebouncedInput';
import SovrenSkillBulkStatusModal from '../../../components/modals/SovrenSkillBulkStatusModal';
import SkillDeleteModal from '../../../components/modals/SkillDeleteModal';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as SkillsAPI from '../../../scripts/skills';
import * as Utility from '../../../scripts/utility';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useGlobalSkills from '../../../hooks/useGlobalSkills';

const Skills = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	useDocumentTitle('Data Manager - Skills');
	useGlobalSkills();

	const { data, alert, reports, urlHistory } = useSelector(state => state.site, shallowEqual);
	const [menu, setMenu] = useState({ anchor: null, skill: {} });
	const table = useRef();
	const defaultFilters = { trashed: undefined };

	const getDefaultFilters = () => {
		// const lastPage = urlHistory[urlHistory.length - 1];
		// const whitelist = [/\/admin\/skill.*/];

		// if (!whitelist.some(i => i.test(lastPage))) {
		// 	dispatch(SiteActions.resetReport('skills'));
		// 	return defaultFilters;
		// }

		return Utility.getFromObj(reports, 'skills', 'filters') || defaultFilters;
	};

	const [filter, setFilter] = useState({ isOpen: false, filters: getDefaultFilters() });

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const updateFilters = (ev, name) => {
		if (typeof ev === 'object' && typeof name === 'string') {
			setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
		} else if (ev.target.value !== null && ev.target.value.length) {
			setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: ev.target.value } });
		} else {
			setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
		}
	};

	const updateViewFilter = (ev, name) => {
		if (ev.target.name === 'trashed' && ev.target.value === 'pending') {
			setFilter({ ...filter, filters: { ...filter.filters, trashed: 'pending', needs_review: 1 } });
		} else if (ev.target.name === 'trashed') {
			setFilter({
				...filter,
				filters: { ...filter.filters, trashed: ev.target.value, needs_review: undefined },
			});
		} else {
			updateFilters(ev, name);
		}
	};

	const onSkillBulkUpdate = () => {
		SiteActions.hideModal();
		table.current.resetCheckboxes();
		table.current.refreshData();
	};

	const updateStatuses = ev => {
		dispatch(SiteActions.hideAlert());

		const selectedRecords = table.current.getSelectedRecords();

		if (!selectedRecords.length) {
			dispatch(SiteActions.showAlert('No records selected. You must select skills to update.', 'warning'));
			return;
		}

		dispatch(SiteActions.showModal(<SovrenSkillBulkStatusModal skills={selectedRecords} onEdit={onSkillBulkUpdate} />));
	};

	const headers = [
		{ id: 'skills.label', value: 'Label' },
		{ id: 'skills.id', value: 'ID' },
		{ id: 'skills.source', value: 'Source' },
		// { id: 'skills.specialties', value: 'Specialties', noSort: true },
		// { id: 'skills.suggested', value: 'Suggested' },
		{ id: 'skills.needs_review', value: 'Status' },
		{ id: 'skills.created_at', value: 'Added' },
		{ id: 'options', value: '', noSort: true },
	];

	const formatData = rows => {
		return rows.map(skill => {
			return [
				<>
					<NavLink to={`/admin/skill/${skill.id}/edit`} className="color-inherit">
						{skill.label}
					</NavLink>
				</>,
				<>{skill.id}</>,
				<>
					<p>
						{(skill?.sovren_skills_count || 0) > 0 ? 'Sovren' : (skill.pdl_skills_count || 0) > 0 ? 'PDL' : 'Portal'}
					</p>
				</>,
				// <>
				// 	{(
				// 		Utility.getFromObj(
				// 			data.skills.find(s => s.id === skill.id),
				// 			'specialties',
				// 		) || []
				// 	)
				// 		.map(s => s.label)
				// 		.join(', ') || Utility.na}
				// </>,
				// <>{skill.suggested ? 'True' : 'False'}</>,
				<>
					<Chip
						size="small"
						label={SkillsAPI.calculateSkillStatus(skill)}
						className={`${SkillsAPI.calculateSkillColor(SkillsAPI.calculateSkillStatus(skill))} no-margins`}
					/>
				</>,
				<>{Utility.formatDateTime(skill.created_at) || Utility.na}</>,
				<IconButton onClick={ev => setMenu({ anchor: ev.target, skill })}>
					<Icons.MoreVert color="primary" />
				</IconButton>,
			];
		});
	};

	const [clearMenuAnchorEl, setClearMenuAnchorEl] = React.useState(null);

	const handleClearMenuClick = event => {
		setClearMenuAnchorEl(event.currentTarget);
	};

	const handleClearMenuClose = () => {
		setClearMenuAnchorEl(null);
	};

	const handleClearSort = () => {
		// window.localStorage.setItem('jobs-table', null);
		handleClearMenuClose();
	};
	const handleClearFilter = () => {
		handleClearMenuClose();
		setFilter({ ...filter, filters: {} });
	};
	const handleClearAll = () => {
		handleClearMenuClose();
		handleClearSort();
		setTimeout(() => {
			handleClearFilter();
		}, 500);
	};

	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Data Manager</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClearMenuClick}
						variant="outlined"
						color="primary"
						endIcon={<Icons.ArrowDropDown />}
					>
						Clear
					</Button>
					<Menu
						id="clear-menu"
						anchorEl={clearMenuAnchorEl}
						keepMounted
						open={Boolean(clearMenuAnchorEl)}
						onClose={handleClearMenuClose}
					>
						<MenuItem onClick={handleClearSort}>Clear Sort</MenuItem>
						<MenuItem onClick={handleClearFilter} disabled={!Object.keys(filter?.filters || {}).length}>
							Clear Filter
						</MenuItem>
						<MenuItem onClick={handleClearAll}>Clear All</MenuItem>
					</Menu>
					<Button
						variant="outlined"
						color="primary"
						onClick={filter.isOpen ? closeFilter : openFilter}
						startIcon={<Icons.FilterList />}
					>
						Filter
					</Button>

					<Button variant="contained" color="primary" onClick={updateStatuses}>
						Update Statuses
					</Button>

					<NavLink to="/admin/skill/add" className="color-inherit">
						<Button variant="contained" color="primary">
							Add New Skill
						</Button>
					</NavLink>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Filter
				open={filter.isOpen}
				onClose={closeFilter}
				onReset={() => setFilter({ ...filter, filters: defaultFilters })}
			>
				<FilterItem
					label="View"
					propName="trashed"
					setFilter={setFilter}
					active={Utility.getFromObj(filter, 'filters', 'trashed').length}
				>
					<Dropdown
						variant="outlined"
						name="trashed"
						value={Utility.getFromObj(filter, 'filters', 'trashed') || ''}
						data={[
							{ id: 'default', label: 'Active Only' },
							{ id: 'pending', label: 'Pending Only' },
							{ id: 'onlyTrashed', label: 'Inactive Only' },
							{ id: 'withTrashed', label: 'View All' },
						]}
						onChange={updateViewFilter}
						disableClearable={false}
						fullWidth
					/>
				</FilterItem>

				<FilterItem
					propName="keyword"
					label="Keyword"
					setFilter={setFilter}
					active={Utility.getFromObj(filter, 'filters', 'keyword').length}
				>
					<DebouncedInput
						variant="outlined"
						name="keyword"
						value={Utility.getFromObj(filter, 'filters', 'keyword')}
						onChange={updateFilters}
						fullWidth
					/>
				</FilterItem>
			</Filter>

			<Tabs value={0} textColor="primary" indicatorColor="primary">
				<Tab label="Skills" onClick={() => history.push('/admin/skills')} />
				<Tab label="Categories" onClick={() => history.push('/admin/categories')} />
				<Tab label="Specialties" onClick={() => history.push('/admin/specialties')} />
			</Tabs>

			<Table
				name="skills"
				onUpdate={SkillsAPI.getSkills}
				filters={filter.filters}
				format={formatData}
				defaultOrderBy="skills.created_at"
				defaultSortDirection="desc"
				headers={headers}
				ref={table}
				checkbox
			/>

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/admin/skill/${menu.skill.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Skill
					</MenuItem>
				</NavLink>
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						dispatch(
							SiteActions.showModal(<SkillDeleteModal skill={menu.skill} onDelete={table.current.refreshData} />),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Skill
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Skills;

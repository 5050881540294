import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const ValuePropositionsDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return <Dropdown data={data.valuePropositionTypes} {...props} />;
};

export default ValuePropositionsDropdown;

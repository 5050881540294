import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import LocationPinIcon from '../../../components/icons/SectionIcons/LocationPinIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditLocationModal from './EditLocationModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import ExpandableTypography from '../../../components/form/ExpandableTypography';

const Block = ({
	job,
	setJob,
	comparisonCandidate,
	minimized,
	setMinimized,
	data,
	disableEdit,
	displayMatchValidation,
}) => {
	const { states } = data;
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={LocationPinIcon}
				iconColor="var(--blue-500)"
				minimized={minimized}
				setMinimized={setMinimized}
				title="Location"
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditLocationModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<LabeledField label="Work Locations" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'work_location_preferences',
								})}
							>
								{job?.work_location_preferences?.length > 0 ? (
									<Typography variant="body2" style={{ marginRight: 6 }}>
										{/* {job?.work_location_preferences?.map(item => Utility.getFromObj(item, 'label')).join(', ')} */}
										{Utility.getConsolidatedLocationPreferences(job?.work_location_preferences)?.join(', ') || ''}
									</Typography>
								) : (
									Utility.nb
								)}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Approved Remote Locations" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{/* {job.remote_states?.length ? job.remote_states.map(s => s.name).join(', ') : Utility.nb} */}
								{job?.remote_states?.length
									? job?.remote_states?.length === states.length
										? 'All States'
										: job.remote_states.map(l => l.name).join(', ')
									: Utility.nb}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Eligible Office Sites" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{job?.office_locations?.length
									? job?.office_locations?.map(item => (
											<>
												{`${item?.city}, ${item?.state?.name}`}
												<br />
											</>
									  ))
									: Utility.nb}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Candidate Relocation" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'relocation',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.offerRelocation, job.offer_relocation) || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Additional Location Details" className="color-muted" close>
							<ExpandableTypography
								numCharacters={250}
								variant="body2"
								text={job?.additional_work_location_details || Utility.nb}
							/>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../../../form/LoadingButton';
import Fence from '../../../../../Fence';
import VerticalSpacer from '../../../../../VerticalSpacer';
import HeartIcon from '../../../../../icons/HeartIcon';
import { ActionCreators as SiteActions } from '../../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../../scripts/candidates';

const EnjoySkillsForm = ({ prevStep, setStoryStep }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: candidateID } = useParams();
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);
	const skillStoryName = 'enjoy_the_job';
	const enjoyTheJobSkills = useMemo(() => candidate?.skills?.filter(s => s[skillStoryName]), [candidate.skills]);

	const handleSelectSkill = skill => {
		const alreadySelectedSkill = candidate.skills.find(s => s.skill_id === skill.skill_id && s[skillStoryName]);
		if (enjoyTheJobSkills.length >= 3 && !alreadySelectedSkill) return;
		const updatedSkills = candidate.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...s, [skillStoryName]: !s[skillStoryName] } : s,
		);
		store.dispatch(AssessmentActions.updateCandidateSkills(updatedSkills));
		if (enjoyTheJobSkills.length > 0) {
			store.dispatch(AssessmentActions.setCanProceed(true));
		}
	};

	const nextStep = ev => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		CandidatesAPI.addCandidateSkills(candidateID, candidate.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateCandidateSkills(response.data.data));
			store.dispatch(AssessmentActions.setIsSaving(false));
			setStoryStep(s => s + 1);
		});
	};

	// Check if can proceed
	useEffect(() => {
		const canProceed = enjoyTheJobSkills?.length >= 1;
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [enjoyTheJobSkills]);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">2 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Select the skills that you would most <strong>Enjoy</strong> using on the job.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<HeartIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Enjoy the Job
						</Typography>
						<Typography>
							Select up to <span className="text-underline">three</span> (3) skills that you would most like to use
							regularly on the job. These can overlap with the skills that get you the job but they don’t have to.
						</Typography>
						<VerticalSpacer height={2} />
						{/* TODO: should be able to select multiple but for now, just filter out Get the Job Skills  */}
						{candidate?.skills
							?.filter(x => x.experience)
							?.filter(s => s.skill_story_id !== 1)
							.map(skill => (
								<Chip
									key={skill.skill_id}
									label={skill.skill.label}
									color={enjoyTheJobSkills.find(s => s.skill_id === skill.skill_id) ? 'primary' : 'default'}
									disabled={
										enjoyTheJobSkills.length >= 3 && !enjoyTheJobSkills.find(s => s.skill_id === skill.skill_id)
									}
									onClick={() => handleSelectSkill(skill)}
								/>
							))}
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={() => prevStep()}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

EnjoySkillsForm.propTypes = {
	prevStep: PropTypes.func,
	setStoryStep: PropTypes.func,
};

EnjoySkillsForm.defaultProps = {
	prevStep: () => null,
	setStoryStep: () => null,
};

export default EnjoySkillsForm;

import React from 'react';
import { Typography, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { PanelHeader, PanelContent } from '../../../components/Panel';

const ViewBlockWrapper = ({
	icon,
	title,
	secondaryTitle,
	onEditClick,
	children,
	preventMinimize,
	minimized,
	setMinimized,
	style,
	iconColor,
	defaultMinimized,
	rightHeaderContent,
	...props
}) => {
	const [isHovered, setIsHovered] = React.useState(false);
	const [_isMinimized, _setIsMinimized] = React.useState(defaultMinimized);

	const isMinimized = minimized !== undefined ? minimized : _isMinimized;
	const setIsMinimized = val => {
		if (setMinimized) {
			setMinimized(val);
		} else {
			_setIsMinimized(val);
		}
	};

	const IconComponent = icon;
	return (
		<div
			style={{
				position: 'relative',
				...style,
			}}
			onMouseEnter={() => {
				setIsHovered(true);
			}}
			onMouseLeave={() => {
				setIsHovered(false);
			}}
			{...props}
		>
			<PanelHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<IconComponent size={20} color={iconColor || 'var(--teal-100)'} />
					<Typography variant="h6" component="h2" style={{ paddingLeft: 10 }}>
						{title}{' '}
						<span style={{ paddingLeft: 10, fontSize: 14, fontWeight: 300, opacity: 0.7 }}>{secondaryTitle}</span>
					</Typography>
				</div>
				<div style={{ display: 'flex' }}>
					{rightHeaderContent}
					{!preventMinimize && (
						<div>
							<Button
								variant="text"
								size="small"
								onClick={() => {
									setIsMinimized(!isMinimized);
								}}
								style={{
									padding: '6px 6px',
									minWidth: 0,
									margin: -6,
								}}
							>
								<Icons.ChevronRight
									style={{
										fontSize: 24,
										color: 'var(--primaryTextColor)',
										marginRight: 0,
										transform: `rotate(${isMinimized ? 0 : 90}deg)`,
										transition: 'transform .2s ease',
									}}
								/>
							</Button>
						</div>
					)}
				</div>
			</PanelHeader>
			{onEditClick ? (
				<Button
					variant="text"
					color="primary"
					size="small"
					onClick={onEditClick}
					style={{
						position: 'absolute',
						top: 16,
						right: 55,
						opacity: isHovered ? 1 : 0,
						transition: 'opacity .2s ease',
					}}
				>
					EDIT
				</Button>
			) : null}
			{!isMinimized ? (
				// header padding (21) + icon (20) + title padding (10) = 51
				<PanelContent style={{ paddingInline: '51px' }}>{children}</PanelContent>
			) : (
				<div style={{ height: 20 }} />
			)}
		</div>
	);
};

export default ViewBlockWrapper;

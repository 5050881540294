import React from 'react';

const DashboardSVG = props => (
	<svg width={20} height={20} fill="none" {...props}>
		<path
			d="M8 9H1a1 1 0 01-1-1V1a1 1 0 011-1h7a1 1 0 011 1v7a1 
      1 0 01-1 1zM7 2H2v5h5V2zm12 18h-7a1 1 0 01-1-1v-7a1 1 0 
      011-1h7a1 1 0 011 1v7a1 1 0 01-1 1zm-1-7h-5v5h5v-5zm1-4h-7a1 
      1 0 01-1-1V1a1 1 0 011-1h7a1 1 0 011 1v7a1 1 0 01-1 
      1zm-1-7h-5v5h5V2zM8 20H1a1 1 0 01-1-1v-7a1 1 0 011-1h7a1 1 0 
      011 1v7a1 1 0 01-1 1zm-1-7H2v5h5v-5z"
			fill="#A5AEBC"
			fillOpacity={0.5}
		/>
	</svg>
);

export default DashboardSVG;

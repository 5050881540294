import React, { useRef, useState } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Typography, Button, IconButton, Menu, MenuItem, ListItemIcon, Tabs, Tab } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import Table from '../../../components/Table';
import CategoryDeleteModal from '../../../components/modals/CategoryDeleteModal';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CategoriesAPI from '../../../scripts/categories';
import * as Utility from '../../../scripts/utility';
import SyncCatSpecButton from '../../../components/admin/SyncCatSpecButton';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const Categories = () => {
	useDocumentTitle('Data Manager - Categories');
	const store = useStore();
	const history = useHistory();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const [menu, setMenu] = useState({ anchor: null, category: {} });
	const table = useRef();

	const headers = [
		{ id: 'categories.label', value: 'Label' },
		{ id: 'categories.id', value: 'ID' },
		{ id: 'categories.name', value: 'Slug' },
		{ id: 'categories.created_at', value: 'Added' },
		{ id: 'options', value: '', noSort: true },
	];

	const formatData = rows => {
		return rows.map(category => {
			return [
				<>
					<NavLink to={`/admin/category/${category.id}/edit`} className="color-inherit">
						{category.label}
					</NavLink>
				</>,
				<>{category.id}</>,
				<>{category.name}</>,
				<>{Utility.formatDateTime(category.created_at) || Utility.na}</>,
				<IconButton onClick={ev => setMenu({ anchor: ev.target, category })}>
					<Icons.MoreVert color="primary" />
				</IconButton>,
			];
		});
	};

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Data Manager</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					{/* <Button
						variant="text"
						color="primary"
						onClick={ev => Utility.notImplemented(store)}
						startIcon={<Icons.FilterList />}
					>
						Filter
					</Button> */}

					<SyncCatSpecButton />
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Tabs value={1} textColor="primary" indicatorColor="primary">
				<Tab label="Skills" onClick={() => history.push('/admin/skills')} />
				<Tab label="Categories" onClick={() => history.push('/admin/categories')} />
				<Tab label="Specialties" onClick={() => history.push('/admin/specialties')} />
			</Tabs>

			<Table onUpdate={CategoriesAPI.getCategories} format={formatData} headers={headers} ref={table} checkbox />

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/admin/category/${menu.category.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Category
					</MenuItem>
				</NavLink>
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(
							SiteActions.showModal(
								<CategoryDeleteModal category={menu.category} onDelete={() => table.current.refreshData()} />,
							),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Category
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Categories;

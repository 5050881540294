import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LabeledField from '../LabeledField';
import CheckboxInput from '../inputs/CheckboxInput';
import CategoryDropdown from './CategoryDropdown';
import SpecialtyDropdown, { sortSpecialties } from './SpecialtyDropdown';
import AddAnotherButton from '../AddAnotherButton';

const SpecialtyCategoryDropdown = ({
	onChange,
	setModel,
	model,
	vertical,
	columnSize,
	horizontalSpacer,
	specialtyLabel,
	specialtyTooltip,
	categoryLabel,
	categoryTooltip,
}) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const [minimized, setMinimized] = useState(false);
	const [overrideLeadershipLock, setOverrideLeadershipLock] = useState(true);

	const [numFields, setNumFields] = useState(model?.specialties?.length || 1);

	const leadershipSpecialtyLocked = false; // [3, 4].includes(model.leadership_allocation_type_id);

	const categoriesIds = model.specialties?.map(s => s?.category_id);
	const categories = categoriesIds?.map(id => data?.categories?.find(c => c.id === id));

	const removeField = index => {
		if (numFields === 1) {
			// If there's only one field, clear the values without reducing the number of fields.
			const clearedSpecialties = [...(model.specialties || [])];
			clearedSpecialties[0] = null; // Clear the value in the last field

			const clearedCategories = clearedSpecialties
				?.map(s => s?.category_id || s?.category?.id)
				?.map(id => data?.categories?.find(c => c.id === id));

			if (onChange) {
				onChange({
					target: {
						name: 'specialties',
						value: [],
					},
				});
				onChange({
					target: {
						name: 'categories',
						value: [],
					},
				});
			} else if (setModel) {
				setModel(currentModel => ({
					...currentModel,
					categories: clearedCategories,
					specialties: clearedSpecialties,
				}));
			}
		} else {
			// If there are more than one field, remove the field as usual.
			setNumFields(numFields - 1);
			const newSpecialties = [...(model.specialties || [])];
			newSpecialties.splice(index, 1);

			const newCategories = newSpecialties
				?.map(s => s?.category_id || s?.category?.id)
				?.map(id => data?.categories?.find(c => c.id === id));

			if (onChange) {
				onChange({
					target: {
						name: 'specialties',
						value: newSpecialties,
					},
				});
				onChange({
					target: {
						name: 'categories',
						value: newCategories,
					},
				});
			} else if (setModel) {
				setModel(currentModel => ({
					...currentModel,
					categories: newCategories,
					specialties: newSpecialties,
				}));
			}
		}
	};

	const handleSpecialtyChange = (specialtyId, i) => {
		// onChange(e);

		const matchingSpecialty = data?.specialties?.find(x => x.id === specialtyId);
		const specialty = {
			...matchingSpecialty,
			category_id: matchingSpecialty?.category?.id,
		};

		const newSpecialties = [...(model.specialties || [])];
		if (newSpecialties.length > i) {
			newSpecialties[i] = specialty;
		} else {
			newSpecialties.push(specialty);
		}
		const newCategories = newSpecialties
			?.map(s => s?.category_id || s?.category?.id)
			?.map(id => data?.categories?.find(c => c.id === id));

		if (onChange) {
			onChange({
				target: {
					name: 'specialties',
					value: newSpecialties,
				},
			});

			onChange({
				target: {
					name: 'categories',
					value: newCategories,
				},
			});
		} else if (setModel) {
			setModel(currentModel => ({
				...currentModel,
				specialties: newSpecialties,
				categories: newCategories,
			}));
		}
	};

	const handleCategoryChange = (categoryId, i) => {
		// onChange(e);

		const matchingCategory = data?.categories?.find(x => x?.id === categoryId);
		if (matchingCategory && matchingCategory?.specialties) {
			const sortedSpecialties = sortSpecialties(matchingCategory?.specialties);

			if (sortedSpecialties && sortedSpecialties.length > 0) {
				const firstSpecialty = sortedSpecialties[0];
				if (firstSpecialty) {
					handleSpecialtyChange(firstSpecialty.id, i);
				}
			}
		}
	};

	return (
		<>
			<Grid container spacing={3}>
				{/* <Grid item xs={12}>
					<LabeledField 
						label="Specialty"
						removeMargin
						labelAddendum={specialtyLabel}
						labelAddendumTooltip={specialtyTooltip}
					>
						{!leadershipSpecialtyLocked ? (
							<SpecialtyDropdown
								variant="outlined"
								name="specialty_id"
								value={leadershipSpecialtyLocked ? null : model.specialty_id}
								placeholder="Type to search specialties"
								// category={model.professional_category_id}
								onChange={e => {
									const { value: specialtyId } = e.target;

									const specialty = data.specialties.find(x => x.id === specialtyId);
									const categoryId = specialty?.category?.id;

									onChange({
										target: {
											name: 'specialty_id',
											value: specialtyId,
										},
									});
									onChange({
										target: {
											name: 'professional_category_id',
											value: categoryId,
										},
									});
								}}
								disabled={leadershipSpecialtyLocked}
								fullWidth
							/>
						) : null}
					</LabeledField>
				</Grid> */}
				{/* {!leadershipSpecialtyLocked ? (
					<Grid item xs={12}>
						<Typography
							onClick={() => {
								// setMinimized(!minimized);
							}}
							variant="body1"
							style={{ cursor: 'pointer', userSelect: 'none', marginTop: -10 }}
							className=""
							gutterBottom
						>
							 {minimized ? '▸' : '▾'} Category and specialty lists: 
						</Typography>
					</Grid>
				) : null} */}

				{!minimized || leadershipSpecialtyLocked ? (
					<Grid item xs={12}>
						<div>
							<Grid container spacing={3}>
								{[...Array(numFields).keys()].map(i => (
									<>
										<Grid item xs={vertical ? 12 : 6}>
											<div
												style={{
													marginTop: i === 0 ? 0 : -25,
												}}
											>
												<LabeledField label={i === 0 ? `Professional Category (select up to 3)` : null} removeMargin>
													<CategoryDropdown
														variant="outlined"
														name="categories"
														value={categories?.[i]?.id}
														onChange={e => handleCategoryChange(e.target.value, i)}
														// error={!Utility.getFromObj(model, 'professional_category_id')}
														fullWidth
														placeholder="Choose from our list"
													/>
												</LabeledField>
											</div>
										</Grid>
										<Grid item xs={vertical ? 12 : 6}>
											<div style={{ display: 'flex', alignItems: 'flex-end', marginTop: i === 0 ? 0 : -25 }}>
												<div style={{ flex: 1 }}>
													<LabeledField label={i === 0 ? `Specialty` : null} removeMargin>
														{/* TODO: if leadership allocation >= 75% then specialty should be "management" */}
														<SpecialtyDropdown
															variant="outlined"
															name="specialties"
															value={model.specialties?.[i]?.id}
															placeholder={leadershipSpecialtyLocked ? 'Leadership' : 'Choose from our list'}
															category={categories?.[i]?.id}
															onChange={e => handleSpecialtyChange(e.target.value, i)}
															disabled={!categories?.[i]}
															fullWidth
															includeLeadershipSpecialty
														/>
													</LabeledField>
												</div>
												<div style={{ width: 40, paddingLeft: 10, marginBottom: 7 }}>
													<Button
														variant="text"
														color="#000"
														size="large"
														style={{
															margin: 0,
															padding: 4,
															minWidth: 0,
															borderRadius: 4,
															fontWeight: 400,
														}}
														onClick={() => {
															removeField(i);
														}}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<Icons.Remove color="gray" size={20} />
														</div>
													</Button>
												</div>
											</div>
										</Grid>
									</>
								))}

								{/* Leaadership Override */}
								{/* <Grid item xs={vertical ? 12 : 6} /> */}
								<Grid item xs={vertical ? 12 : 6} style={{ marginTop: -15 }}>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 4 }}>
										{/* eslint-disable-next-line no-constant-condition */}
										{false && leadershipSpecialtyLocked ? (
											<CheckboxInput
												label="Override leadership specialty"
												color="primary"
												checked={overrideLeadershipLock}
												onChange={ev => setOverrideLeadershipLock(ev.target.checked)}
											/>
										) : (
											<div />
										)}
									</div>
								</Grid>
								<Grid item xs={12} style={{ marginTop: -15 }}>
									{numFields < 3 && (
										<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
											<AddAnotherButton text="Add Another" onClick={() => setNumFields(n => n + 1)} />
										</div>
									)}
								</Grid>
							</Grid>
						</div>
					</Grid>
				) : null}
			</Grid>
			<br />
		</>
	);
};

export default SpecialtyCategoryDropdown;

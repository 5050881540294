import React from 'react';
import Dropdown from './Dropdown';

const openings = Array.from(Array(20).keys()).map(open => {
	return { id: open + 1, label: `${open + 1} Openings` };
});

const JobOpeningsDropdown = props => {
	return <Dropdown data={openings} {...props} />;
};

export default JobOpeningsDropdown;

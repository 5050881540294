import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import * as ListingsAPI from '../../../../scripts/listings';
import LabeledField from '../../../form/LabeledField';
import VerticalSpacer from '../../../VerticalSpacer';

const AddAdditionalInfo = ({ additionalInfo, setAdditionalInfo }) => {
	const [minimized, setMinimized] = useState(!additionalInfo?.length);

	return (
		<>
			<Typography
				onClick={() => {
					setMinimized(!minimized);
				}}
				variant="body1"
				style={{ cursor: 'pointer', userSelect: 'none' }}
				className=""
				gutterBottom
			>
				{minimized ? '▸' : '▾'} Have more info we should know?
			</Typography>
			{!minimized && (
				<>
					<VerticalSpacer height={1} />
					<LabeledField label="Additional Info" removeMargin>
						<TextField
							variant="outlined"
							name="position_title"
							value={additionalInfo || ''}
							onChange={e => {
								setAdditionalInfo(e.target.value);
							}}
							error={null}
							fullWidth
							rows={3}
							multiline
							placeholder="Add some more info here."
						/>
					</LabeledField>
					<VerticalSpacer height={1} />
				</>
			)}
		</>
	);
};

AddAdditionalInfo.propTypes = {
	additionalInfo: PropTypes.string,
	setAdditionalInfo: PropTypes.func,
};

AddAdditionalInfo.defaultProps = {
	additionalInfo: '',
	setAdditionalInfo: () => null,
};

export default AddAdditionalInfo;

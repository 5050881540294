import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Typography } from '@material-ui/core';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import LabeledField from '../../../components/form/LabeledField';
import LeadershipRolesDropdown from '../../../components/form/dropdowns/LeadershipRolesDropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import * as Utility from '../../../scripts/utility';
import EditCandidateBlockModal from './EditCandidateBlockModal';
import LeadershipAllocationDropdown from '../../../components/form/dropdowns/LeadershipAllocationDropdown';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import EducationDropdown from '../../../components/form/dropdowns/EducationDropdown';
import AreasOfStudyDropdown from '../../../components/form/dropdowns/AreasOfStudyDropdown';
import { BusinessSizeExperienceDropdown } from '../../../components/form/dropdowns/BusinessSizeDropdown';
import VerticalSpacer from '../../../components/VerticalSpacer';
// eslint-disable-next-line max-len
import MultiInputIndustryExperienceDropdown from '../../../components/form/dropdowns/MultiInputIndustryExperienceDropdown';

const EditExperienceModal = ({ candidate, setCandidate }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return (
		<EditCandidateBlockModal
			candidate={candidate}
			setCandidate={setCandidate}
			title="Experience"
			icon={ExperienceIcon}
			renderContents={({ updateCandidate, updatedCandidate }) => {
				const isHighschool = updatedCandidate.education_type_id === 6;
				const isAssociateNonDegreeOrOther =
					updatedCandidate.education_type_id === 1 ||
					updatedCandidate.education_type_id === 9 ||
					updatedCandidate.education_type_id === 10;
				return (
					<>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Work Experience
						</Typography>
						<VerticalSpacer height={2} />
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Current Title" required>
									<TextField
										variant="outlined"
										name="current_title"
										value={updatedCandidate.current_title || ''}
										onChange={updateCandidate}
										error={!updatedCandidate.current_title}
										fullWidth
										placeholder="Enter job title"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Current Employer" required>
									<TextField
										variant="outlined"
										name="current_employer"
										value={updatedCandidate.current_employer || ''}
										onChange={updateCandidate}
										fullWidth
										placeholder="Enter current employer"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Professional Experience" required>
									<YearsDropdown
										variant="outlined"
										name="professional_experience_years"
										value={updatedCandidate.professional_experience_years}
										onChange={updateCandidate}
										error={!updatedCandidate.professional_experience_years}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Employment Type">
									<Dropdown
										variant="outlined"
										name="current_employment_type_id"
										value={Utility.getFromObj(updatedCandidate, 'current_employment_type_id')}
										data={data.currentEmploymentTypes}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Business Size Experiences">
									<BusinessSizeExperienceDropdown
										variant="outlined"
										name="business_size_experiences"
										value={
											(Utility.getFromObj(updatedCandidate, 'business_size_experiences') || []).length === 9
												? [0]
												: (Utility.getFromObj(updatedCandidate, 'business_size_experiences') || []).map(item =>
														item.id ? item.id : item,
												  )
										}
										onChange={updateCandidate}
										placeholder={
											Utility.getFromObj(updatedCandidate, 'business_size_experiences').length > 0
												? ''
												: 'Select all that apply'
										}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<MultiInputIndustryExperienceDropdown onChange={updateCandidate} model={updatedCandidate} />
							</Grid>
						</Grid>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Leadership
						</Typography>
						<VerticalSpacer height={2} />
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Leadership Level" required>
									<LeadershipRolesDropdown
										variant="outlined"
										name="recent_leadership_role_id"
										value={updatedCandidate.recent_leadership_role_id}
										onChange={updateCandidate}
										placeholder="Choose from our list"
										error={!updatedCandidate.recent_leadership_role_id}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField
									label="Current or Most Recent Leadership Title"
									required={
										updatedCandidate.recent_leadership_role_id && updatedCandidate.recent_leadership_role_id !== 1
									}
								>
									<TextField
										variant="outlined"
										name="leadership_title"
										value={
											// updatedCandidate.recent_leadership_role_id === 1 ||
											// updatedCandidate.recent_leadership_role_id === null
											// 	? ''
											// 	: updatedCandidate.leadership_title || ''

											updatedCandidate.leadership_title
										}
										onChange={updateCandidate}
										placeholder="Enter most recent leadership title"
										disabled={
											updatedCandidate.recent_leadership_role_id === 1 ||
											updatedCandidate.recent_leadership_role_id === null
										}
										error={
											updatedCandidate.recent_leadership_role_id &&
											updatedCandidate.recent_leadership_role_id !== 1 &&
											!updatedCandidate.leadership_title
										}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField
									label="Leadership Experience"
									required={
										updatedCandidate.recent_leadership_role_id && updatedCandidate.recent_leadership_role_id !== 1
									}
								>
									<YearsDropdown
										variant="outlined"
										name="total_leadership_years"
										value={
											updatedCandidate?.recent_leadership_role_id === 1 ||
											updatedCandidate?.recent_leadership_role_id === null
												? 0
												: updatedCandidate.total_leadership_years
										}
										onChange={updateCandidate}
										disabled={
											updatedCandidate?.recent_leadership_role_id === 1 ||
											updatedCandidate?.recent_leadership_role_id === null
										}
										error={
											!(
												updatedCandidate?.recent_leadership_role_id === 1 ||
												updatedCandidate?.recent_leadership_role_id === null
											) && !updatedCandidate.total_leadership_years
										}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership Time Allocation">
									<LeadershipAllocationDropdown
										variant="outlined"
										name="leadership_allocation_type_id"
										value={Utility.getFromObj(updatedCandidate, 'leadership_allocation_type_id')}
										placeholder="In your current (or most recent) role how much time do you 
									typically spend on leadership responsibilities?"
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Education
						</Typography>
						<VerticalSpacer height={2} />
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Highest Education Level Achieved" required>
									<EducationDropdown
										variant="outlined"
										name="education_type_id"
										value={updatedCandidate.education_type_id}
										onChange={updateCandidate}
										fullWidth
										placeholder="Choose from our list"
										error={!updatedCandidate.education_type_id}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Major(s) / Field of Study" required={!isHighschool}>
									<TextField
										variant="outlined"
										name="field_of_study"
										value={isHighschool ? '' : Utility.getFromObj(updatedCandidate, 'field_of_study')}
										onChange={updateCandidate}
										fullWidth
										disabled={isHighschool}
										placeholder="Enter major or field of study"
										error={isHighschool ? null : !updatedCandidate.field_of_study}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Area(s) of Study" required={!isHighschool && !isAssociateNonDegreeOrOther}>
									<AreasOfStudyDropdown
										variant="outlined"
										name="study_types"
										value={
											isHighschool || isAssociateNonDegreeOrOther
												? ''
												: updatedCandidate?.study_types?.map(x => (x?.id ? x.id : x)) || []
										}
										onChange={updateCandidate}
										multiple
										fullWidth
										placeholder="Select all that apply"
										disabled={isHighschool || isAssociateNonDegreeOrOther}
										error={
											isHighschool || isAssociateNonDegreeOrOther ? null : updatedCandidate?.study_types?.length < 1
										}
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditExperienceModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditExperienceModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditExperienceModal;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function CareerIcon({ style, color = "currentColor", size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size + 1}
			viewBox="0 0 21 22"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-335 -665)">
					<g transform="translate(336 667)">
						<g transform="translate(0 -1)">
							<path d="M9 3.5L9 6"/>
							<g>
								<path d="M6.5 9V7a1 1 0 011-1h3a1 1 0 011 1v2"/>
								<path d="M4 18v-8a1 1 0 011-1h6.5"/>
								<path d="M6.5 12L10 12"/>
								<path d="M6.5 15L9 15"/>
								<path d="M8 18H4a4 4 0 01-4-4V4a4 4 0 014-4h10a4 4 0 014 4v4"/>
								<path d="M18.5 20H12a.5.5 0 01-.5-.5v-.406A2.1 2.1 0 0113.594 17h3.312A2.1 2.1 0 0119 19.094v.406a.5.5 0 01-.5.5z"/>
								<path d="M15.25 14.5a2 2 0 11.008 0h-.008z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../form/LoadingButton';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import CheckmarkIcon from '../../../icons/CheckmarkIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';

const RequiredSkillsForm = ({ prevStep, setStoryStep }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, progress } = useSelector(state => state.assessment, shallowEqual);

	const skillStoryName = 'required_skill';
	const requiredSkills = useMemo(() => listing?.skills?.filter(s => s[skillStoryName]));

	const handleSelectSkill = skill => {
		const updatedSkills = listing.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...s, [skillStoryName]: !s[skillStoryName] } : s,
		);
		store.dispatch(AssessmentActions.updateListingSkills(updatedSkills));
		if (requiredSkills.length > 0) {
			store.dispatch(AssessmentActions.setCanProceed(true));
		}
	};

	const nextStep = () => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		ListingsAPI.addListingSkills(listingID, listing.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateListingSkills(response.data.data));
			store.dispatch(AssessmentActions.setIsSaving(false));
			setStoryStep(s => s + 1);
		});
	};

	useEffect(() => {
		const canProceed = requiredSkills?.length >= 1;
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [requiredSkills]);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">1 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Let&rsquo;s further refine the job skills into categories that will help generate the most precise candidate
				matches - <strong>Required</strong> skills.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<CheckmarkIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Required Skills
						</Typography>
						<Typography>
							From the skill pool you provided, please select the skills that a candidate MUST possess to be considered
							for this role.
						</Typography>
						<VerticalSpacer height={2} />
						{listing?.skills
							?.filter(x => x.experience)
							?.map(skill => (
								<Chip
									key={skill.skill_id}
									label={skill.skill.label}
									color={requiredSkills.find(s => s.skill_id === skill.skill_id) ? 'primary' : 'default'}
									onClick={() => handleSelectSkill(skill)}
								/>
							))}
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={() => prevStep()}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

RequiredSkillsForm.propTypes = {
	prevStep: PropTypes.func,
	setStoryStep: PropTypes.func,
};

RequiredSkillsForm.defaultProps = {
	prevStep: () => null,
	setStoryStep: () => null,
};

export default RequiredSkillsForm;

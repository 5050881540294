import React, { useRef, useState } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Button, Chip, IconButton, Menu, MenuItem, ListItemIcon, Tooltip, Grid } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import Page from '../page/Page';
import * as PageHeader from '../page/PageHeader';
import Table from '../Table';
import { Filter, FilterItem } from '../page/Filter';
import Dropdown from '../form/dropdowns/Dropdown';
import StatesDropdown from '../form/dropdowns/StatesDropdown';
import DateInput from '../form/inputs/DateInput';
import DebouncedInput from '../form/inputs/DebouncedInput';
import JobDeleteModal from '../modals/JobDeleteModal';
import WorkVisaDropdown from '../form/dropdowns/WorkVisaDropdown';
import IndustryExperienceDropdown from '../form/dropdowns/IndustryExperienceDropdown';
import CategoryDropdown from '../form/dropdowns/CategoryDropdown';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import JobSyncModal from '../modals/JobSyncModal';
import { palette } from '../../BobcatTheme';
import { SavedFiltersBlock } from './CandidateFilters';
import ElasticSkillDropdown from '../form/dropdowns/ElasticSkillDropdown';

const Jobs = ({
	displayFilters,
	savedFilters,
	enableSavedFilters,
	activeSaveFilterId,
	setActiveSaveFilterId,
	setSavedFilters,
	onClose,
	onReset,
	data,
	filter,
	updateFilters,
	onUpdate,
	setFilter,
}) => {
	const store = useStore();

	return (
		<Filter open={filter.isOpen} onClose={onClose} onReset={onReset}>
			{enableSavedFilters ? (
				<SavedFiltersBlock
					filter={filter}
					savedFilters={savedFilters}
					setSavedFilters={setSavedFilters}
					setFilter={setFilter}
					onUpdate={onUpdate}
					setActiveSaveFilterId={setActiveSaveFilterId}
					activeSaveFilterId={activeSaveFilterId}
				/>
			) : null}
			{displayFilters?.map(filterType => {
				switch (filterType) {
					case 'total_skill_score':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Skill Match Score"
								active={Boolean(Utility.getFromObj(filter, 'filters', 'total_skill_score'))}
								setFilter={setFilter}
								propName="total_skill_score"
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="total_skill_score"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'total_skill_score')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'completeness_score_overall':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Record Completeness"
								active={Boolean(Utility.getFromObj(filter, 'filters', 'completeness_score_overall'))}
								setFilter={setFilter}
								propName="completeness_score_overall"
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="completeness_score_overall"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'completeness_score_overall')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'match_percentage':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Total Match Score"
								active={Boolean(Utility.getFromObj(filter, 'filters', 'match_percentage_min'))}
								setFilter={setFilter}
								propName="match_percentage_min"
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="match_percentage_min"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'candidate_match_percentage_min')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'owner':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Owner"
								count={Utility.getFromObj(filter, 'filters', 'owner').length}
								setFilter={setFilter}
								propName="owner"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="owner"
									value={Utility.getFromObj(filter, 'filters', 'owner')}
									data={data.owners.map(o => ({ ...o, id: o.vendor_owner_id, label: o.name })) || {}}
									onChange={updateFilters}
									placeholder="Search owner..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'company':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Company"
								active={Utility.getFromObj(filter, 'filters', 'company').length}
								setFilter={setFilter}
								propName="company"
							>
								<DebouncedInput
									variant="outlined"
									name="company"
									value={Utility.getFromObj(filter, 'filters', 'company')}
									onChange={updateFilters}
									placeholder="Search company..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'contact':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Contact"
								active={Utility.getFromObj(filter, 'filters', 'contact').length}
								setFilter={setFilter}
								propName="contact"
							>
								<DebouncedInput
									variant="outlined"
									name="contact"
									value={Utility.getFromObj(filter, 'filters', 'contact')}
									onChange={updateFilters}
									placeholder="Search contact..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'position_title':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Job Title"
								active={Utility.getFromObj(filter, 'filters', 'position_title').length}
								setFilter={setFilter}
								propName="position_title"
							>
								<DebouncedInput
									variant="outlined"
									name="position_title"
									value={Utility.getFromObj(filter, 'filters', 'position_title')}
									onChange={updateFilters}
									placeholder="Search title..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'vendor_job_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Job Id"
								active={Utility.getFromObj(filter, 'filters', 'vendor_job_id').length}
								setFilter={setFilter}
								propName="vendor_job_id"
							>
								<DebouncedInput
									variant="outlined"
									name="vendor_job_id"
									value={Utility.getFromObj(filter, 'filters', 'vendor_job_id')}
									placeholder="Search job ID..."
									onChange={updateFilters}
									fullWidth
								/>
							</FilterItem>
						);
					case 'is_open':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Job Open/Closed"
								active={Utility.getFromObj(filter, 'filters', 'is_open').length}
								setFilter={setFilter}
								propName="is_open"
							>
								<Dropdown
									variant="outlined"
									name="is_open"
									value={Utility.getFromObj(filter, 'filters', 'is_open')}
									data={[
										{ id: '1', label: 'Open' },
										{ id: '0', label: 'Closed' },
									]}
									placeholder="Select status..."
									onChange={updateFilters}
									disableClearable={false}
									fullWidth
								/>
							</FilterItem>
						);
					case 'status_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Job Status"
								count={Utility.getFromObj(filter, 'filters', 'status_id').length}
								setFilter={setFilter}
								propName="status_id"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="status_id"
									value={Utility.getFromObj(filter, 'filters', 'status_id')}
									data={data.jobStatuses || {}}
									placeholder="Select status..."
									onChange={updateFilters}
									fullWidth
								/>
							</FilterItem>
						);
					case 'professional_experience_years_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Professional Experience"
								active={Boolean(
									Utility.getFromObj(filter, 'filters', 'professional_experience_years_min') ||
										Utility.getFromObj(filter, 'filters', 'professional_experience_years_max'),
								)}
								setFilter={setFilter}
								propName={['professional_experience_years_min', 'professional_experience_years_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="professional_experience_years_min"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'professional_experience_years_min')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="professional_experience_years_max"
											placeholder="Max"
											value={Utility.getFromObj(filter, 'filters', 'professional_experience_years_max')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'salary':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Salary"
								active={Boolean(
									Utility.getFromObj(filter, 'filters', 'salary_rate_min') ||
										Utility.getFromObj(filter, 'filters', 'salary_rate_max'),
								)}
								setFilter={setFilter}
								propName={['salary_rate_min', 'salary_rate_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="salary_rate_min"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'salary_rate_min')}
											onChange={updateFilters}
											fullWidth
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="salary_rate_max"
											placeholder="Max"
											value={Utility.getFromObj(filter, 'filters', 'salary_rate_max')}
											onChange={updateFilters}
											fullWidth
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'city':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="City"
								active={Utility.getFromObj(filter, 'filters', 'location_city').length}
								setFilter={setFilter}
								propName="location_city"
							>
								<DebouncedInput
									variant="outlined"
									name="location_city"
									value={Utility.getFromObj(filter, 'filters', 'location_city')}
									placeholder="Search city..."
									onChange={updateFilters}
									fullWidth
								/>
							</FilterItem>
						);
					case 'state':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="State"
								active={Utility.getFromObj(filter, 'filters', 'location_state')}
								setFilter={setFilter}
								propName="location_state"
							>
								<StatesDropdown
									variant="outlined"
									name="location_state"
									value={Utility.getFromObj(filter, 'filters', 'location_state')}
									onChange={updateFilters}
									placeholder="Select state..."
									disableClearable={false}
									fullWidth
								/>
							</FilterItem>
						);
					case 'approved_remote_locations':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Approved Remote Locations"
								active={Utility.getFromObj(filter, 'filters', 'approved_remote_locations')}
								setFilter={setFilter}
								propName="approved_remote_locations"
							>
								<StatesDropdown
									variant="outlined"
									name="approved_remote_locations"
									value={Utility.getFromObj(filter, 'filters', 'approved_remote_locations')}
									placeholder="Select state..."
									onChange={newVal => {
										console.log({ newVal: newVal.target.value });
										updateFilters(newVal);
									}}
									multiple
									useID
									disableClearable={false}
									fullWidth
								/>
							</FilterItem>
						);
					case 'description':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Job Description"
								active={Utility.getFromObj(filter, 'filters', 'description').length}
								setFilter={setFilter}
								propName="description"
							>
								<DebouncedInput
									variant="outlined"
									name="description"
									value={Utility.getFromObj(filter, 'filters', 'description')}
									placeholder="Search description..."
									onChange={updateFilters}
									fullWidth
								/>
							</FilterItem>
						);
					case 'professional_category_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Professional Category"
								active={Utility.getFromObj(filter, 'filters', 'professional_category_id').length}
								setFilter={setFilter}
								propName="professional_category_id"
							>
								<CategoryDropdown
									multiple
									variant="outlined"
									name="professional_category_id"
									value={Utility.getFromObj(filter, 'filters', 'professional_category_id')}
									onChange={updateFilters}
									groupBy={option => {
										const split = option?.label?.split('-');

										if (split.length > 1) {
											return split[0] || 'Uncategorized';
										}
										return 'Uncategorized';
									}}
									fullWidth
									placeholder="Select a category..."
								/>
							</FilterItem>
						);
					case 'specialty_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Specialty"
								count={Utility.getFromObj(filter, 'filters', 'specialty_id').length}
								setFilter={setFilter}
								propName="specialty_id"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="specialty_id"
									value={Utility.getFromObj(filter, 'filters', 'specialty_id')}
									data={data.specialties || {}}
									onChange={updateFilters}
									fullWidth
									groupBy={option => option?.category?.label}
									placeholder="Select a specialty..."
								/>
							</FilterItem>
						);
					case 'skills':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Skills"
								count={Utility.getFromObj(filter, 'filters', 'skills').length}
								setFilter={setFilter}
								propName="skills"
								key="job-skills-filter"
							>
								<ElasticSkillDropdown
									value={Utility.getFromObj(filter, 'filters', 'skills')}
									multiple
									onChange={e => updateFilters(e)}
									variant="outlined"
									name="skills"
									placeholder="Select skills..."
									fullWidth
									key="job-skills-filter-dropdown"
								/>
							</FilterItem>
						);
					case 'hero_skills':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Hero Skills"
								count={Utility.getFromObj(filter, 'filters', 'hero_skills').length}
								setFilter={setFilter}
								propName="hero_skills"
							>
								<ElasticSkillDropdown
									value={Utility.getFromObj(filter, 'filters', 'hero_skills')}
									multiple
									onChange={e => updateFilters(e)}
									variant="outlined"
									name="hero_skills"
									placeholder="Select skills..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'industries':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Industry Sector Experience"
								active={Utility.getFromObj(filter, 'filters', 'industries').length}
								setFilter={setFilter}
								propName="industries"
							>
								<IndustryExperienceDropdown
									variant="outlined"
									name="industries"
									value={(Utility.getFromObj(filter, 'filters', 'industries') || []).map(item =>
										item.id ? item.id : item,
									)}
									onChange={updateFilters}
									placeholder="Select industries..."
									fullWidth
									multiple
								/>
							</FilterItem>
						);
					case 'offer_relocation':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Offer Relocation"
								active={Utility.getFromObj(filter, 'filters', 'offer_relocation').length}
								setFilter={setFilter}
								propName="offer_location"
							>
								<Dropdown
									variant="outlined"
									name="offer_relocation"
									value={Utility.getFromObj(filter, 'filters', 'offer_relocation')}
									data={data.offerRelocation}
									onChange={updateFilters}
									disableClearable={false}
									placeholder="Select option..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'work_visa_type_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Visa Sponsorship"
								active={!!Utility.getFromObj(filter, 'filters', 'work_visa_type_id')}
								setFilter={setFilter}
								propName="work_visa_type_id"
							>
								<WorkVisaDropdown
									variant="outlined"
									name="work_visa_type_id"
									value={Utility.getFromObj(filter, 'filters', 'work_visa_type_id')}
									onChange={updateFilters}
									placeholder="Choose a Visa type..."
									// error={!candidate.work_visa_type_id}
									fullWidth
								/>
							</FilterItem>
						);
					case 'recent_leadership_role_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Recent Leadership Role"
								count={Utility.getFromObj(filter, 'filters', 'recent_leadership_role_id').length}
								setFilter={setFilter}
								propName="recent_leadership_role_id"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="recent_leadership_role_id"
									value={Utility.getFromObj(filter, 'filters', 'recent_leadership_role_id')}
									data={data.leadershipExperienceRoles || {}}
									placeholder="Select role..."
									onChange={updateFilters}
									fullWidth
								/>
							</FilterItem>
						);
					case 'recent_leadership_years_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Years at Recent Leadership Level"
								active={Boolean(
									Utility.getFromObj(filter, 'filters', 'recent_leadership_years_min') ||
										Utility.getFromObj(filter, 'filters', 'recent_leadership_years_max'),
								)}
								setFilter={setFilter}
								propName={['recent_leadership_years_min', 'recent_leadership_years_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="recent_leadership_years_min"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'recent_leadership_years_min')}
											onChange={updateFilters}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="recent_leadership_years_max"
											placeholder="Max"
											value={Utility.getFromObj(filter, 'filters', 'recent_leadership_years_max')}
											onChange={updateFilters}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'role_type':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Role Type"
								count={Utility.getFromObj(filter, 'filters', 'role_type').length}
								setFilter={setFilter}
								propName="role_type"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="role_type"
									value={Utility.getFromObj(filter, 'filters', 'role_type')}
									data={data.employmentPreferences}
									onChange={updateFilters}
									fullWidth
								/>
							</FilterItem>
						);
					case 'last_profile_update_range':
						return (
							<FilterItem
								label="Last Updated"
								active={
									Utility.getFromObj(filter, 'filters', 'last_profile_update_min').length ||
									Utility.getFromObj(filter, 'filters', 'last_profile_update_max').length
								}
								setFilter={setFilter}
								propName={['last_profile_update_min', 'last_profile_update_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="last_profile_update_min"
											value={Utility.getFromObj(filter, 'filters', 'last_profile_update_min')}
											onChange={updateFilters}
											placeholder="Start"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="last_profile_update_max"
											value={Utility.getFromObj(filter, 'filters', 'last_profile_update_max')}
											onChange={updateFilters}
											placeholder="End"
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'assessment_completed_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Intake Completed"
								active={
									Utility.getFromObj(filter, 'filters', 'assessment_completed_min').length ||
									Utility.getFromObj(filter, 'filters', 'assessment_completed_max').length
								}
								setFilter={setFilter}
								propName={['assessment_completed_min', 'assessment_completed_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="assessment_completed_min"
											value={Utility.getFromObj(filter, 'filters', 'assessment_completed_min')}
											onChange={updateFilters}
											placeholder="Start"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="assessment_completed_max"
											value={Utility.getFromObj(filter, 'filters', 'assessment_completed_max')}
											onChange={updateFilters}
											placeholder="End"
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);

					case 'match_saved':
						return (
							<FilterItem
								// onUpdate={onUpdate}
								label="Match Saved"
								count={[1, 2].includes(Utility.getFromObj(filter, 'filters', 'match_saved')) ? 1 : 0}
								setFilter={setFilter}
								propName="match_saved"
							>
								{/* {console.log(typeof Utility.getFromObj(filter, 'filters', 'match_saved'))} */}
								<Dropdown
									variant="outlined"
									name="match_saved"
									value={Utility.getFromObj(filter, 'filters', 'match_saved')}
									data={[
										{
											id: 1,
											label: 'Yes',
										},
										{
											id: 2,
											label: 'No',
										},
									]}
									onChange={updateFilters}
									placeholder="Select saved status..."
									fullWidth
								/>
							</FilterItem>
						);

					case 'match_shared':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Match Shared"
								count={[1, 2].includes(Utility.getFromObj(filter, 'filters', 'match_shared')) ? 1 : 0}
								setFilter={setFilter}
								propName="match_shared"
							>
								<Dropdown
									variant="outlined"
									name="match_shared"
									value={Utility.getFromObj(filter, 'filters', 'match_shared')}
									data={[
										{
											id: 1,
											label: 'Yes',
										},
										{
											id: 2,
											label: 'No',
										},
									]}
									onChange={updateFilters}
									placeholder="Select shared status..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'match_viewed':
						return (
							<FilterItem
								// onUpdate={onUpdate}
								label="Match Viewed"
								count={[1, 2].includes(Utility.getFromObj(filter, 'filters', 'match_viewed')) ? 1 : 0}
								setFilter={setFilter}
								propName="match_viewed"
							>
								<Dropdown
									variant="outlined"
									name="match_viewed"
									value={Utility.getFromObj(filter, 'filters', 'match_viewed')}
									data={[
										{
											id: 1,
											label: 'Yes',
										},
										{
											id: 2,
											label: 'No',
										},
									]}
									onChange={updateFilters}
									placeholder="Select viewed status..."
									fullWidth
								/>
							</FilterItem>
						);

					case 'fit_ranking':
						return (
							<FilterItem
								// onUpdate={onUpdate}
								label="Match Ranking"
								count={filter.fit_ranking?.length}
								setFilter={setFilter}
								propName="fit_ranking"
							>
								<Dropdown
									variant="outlined"
									name="fit_ranking"
									value={Utility.getFromObj(filter, 'filters', 'fit_ranking')}
									data={[
										{
											id: 1,
											label: 'Poor Match',
										},
										{
											id: 2,
											label: 'Inconclusive',
										},
										{
											id: 3,
											label: 'Good Match',
										},
										{
											id: 4,
											label: 'Excellent Match',
										},
										{
											id: 'none',
											label: 'Not Ranked',
										},
									]}
									onChange={updateFilters}
									placeholder="Select rankings..."
									fullWidth
									multiple
								/>
							</FilterItem>
						);
					default:
						return null;
				}
			})}
		</Filter>
	);
};

export default Jobs;

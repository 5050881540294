import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NumericFormat } from 'react-number-format';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import LeadershipRolesDropdown from '../../../components/form/dropdowns/LeadershipRolesDropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';
import EditJobBlockModal from './EditJobBlockModal';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import LeadershipAllocationDropdown from '../../../components/form/dropdowns/LeadershipAllocationDropdown';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import SpecialtyDropdown from '../../../components/form/dropdowns/SpecialtyDropdown';
import WorkVisaDropdown from '../../../components/form/dropdowns/WorkVisaDropdown';
import TravelWillingnessDropdown from '../../../components/form/dropdowns/TravelWillingnessDropdown';
import EmploymentPreferencesDropdown from '../../../components/form/dropdowns/EmploymentPreferencesDropdown';
import WorkLocationDropdown from '../../../components/form/dropdowns/WorkLocationDropdown';
import WillingToRelocateDropdown from '../../../components/form/dropdowns/WillingToRelocateDropdown';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import SalaryFormat from '../../../components/form/SalaryFormat';
import CompensationTypesDropdown from '../../../components/form/dropdowns/CompensationTypesDropdown';
import SalaryDropdown from '../../../components/form/dropdowns/SalaryDropdown';
import ValuePropositionsDropdown from '../../../components/form/dropdowns/ValuePropositionsDropdown';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';

const EditModal = ({ job, setJob }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="Details"
			icon={CaseIcon}
			renderContents={({ updateJob, updatedJob }) => {
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Title" required removeMargin>
									<TextField
										variant="outlined"
										name="position_title"
										value={updatedJob.position_title || ''}
										onChange={updateJob}
										error={!updatedJob.position_title}
										fullWidth
										placeholder="Enter job title"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Type" removeMargin>
									<Dropdown
										variant="outlined"
										name="role_type"
										value={updatedJob.role_type}
										data={data.employmentPreferences}
										onChange={updateJob}
										// error={!updatedJob.role_type}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Salary Range" removeMargin>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												variant="outlined"
												name="salary_rate_min"
												value={updatedJob.salary_rate_min}
												onChange={e => {
													updateJob(e);
													// const numberValue
													// updateJob({
													// 	target: {
													// 		name: e.target.name,
													// 		value: e.target.value,
													// 	}
													// })
												}}
												fullWidth
												placeholder="Minimum"
												InputProps={{
													inputComponent: SalaryFormat,
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												variant="outlined"
												name="salary_rate_max"
												value={updatedJob.salary_rate_max}
												onChange={updateJob}
												fullWidth
												placeholder="Maximum"
												InputProps={{
													inputComponent: SalaryFormat,
												}}
											/>
										</Grid>
									</Grid>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Other Compensation" removeMargin required>
									<CompensationTypesDropdown
										variant="outlined"
										name="other_compensation_types"
										value={updatedJob?.other_compensation_types?.map(t => (t.id ? t.id : t))}
										onChange={updateJob}
										placeholder={updatedJob?.other_compensation_types?.length > 0 ? '' : 'Select all that apply'}
										error={!updatedJob?.other_compensation_types?.length}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Value Propositions" removeMargin required>
									<ValuePropositionsDropdown
										variant="outlined"
										name="value_proposition_types"
										value={updatedJob?.value_proposition_types?.map(t =>
											t.value_proposition_type_id ? t.value_proposition_type_id : t,
										)}
										onChange={e => {
											updateJob({
												target: {
													name: 'value_proposition_types',
													value: e?.target?.value?.map((id, i) => ({
														priority: i,
														value_proposition_type_id: id,
													})),
												},
											});
										}}
										placeholder={updatedJob?.value_proposition_types?.length > 0 ? '' : 'Select all that apply'}
										// error={!updatedJob?.value_proposition_types?.length}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={6}>
								<LabeledField label="Work Visa Sponsorship" required>
									<WorkVisaDropdown
										variant="outlined"
										name="work_visa_type_id"
										value={Utility.getFromObj(updatedJob, 'work_visa_type_id')}
										onChange={updateJob}
										placeholder="Choose from our list"
										error={!updatedJob.work_visa_type_id}
										fullWidth
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={6}>
								<LabeledField label="Travel">
									<TravelWillingnessDropdown
										variant="outlined"
										name="travel_willingness_type_id"
										value={updatedJob.travel_willingness_type_id}
										onChange={updateJob}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Openings" removeMargin>
									<TextField
										variant="outlined"
										name="openings"
										value={updatedJob?.openings}
										type="number"
										onChange={updateJob}
										fullWidth
										placeholder="Number of openings"
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={12}>
								<LabeledField label="Job Abstract">
									<TextField
										variant="outlined"
										name="abstract"
										value={updatedJob.abstract || ''}
										onChange={updateJob}
										error={!updatedJob.abstract}
										fullWidth
										multiline
										rows={4}
										placeholder="Enter job abstract"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<div style={{ marginTop: -10 }} />
								<AddAdditionalInfo
									additionalInfo={updatedJob.additional_job_detail}
									setAdditionalInfo={newAdditionalInfo => {
										updateJob({
											target: {
												name: 'additional_job_detail',
												value: newAdditionalInfo,
											},
										});
									}}
								/>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function LearnIcon({ style, color = "currentColor", size = 19, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 19 19"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-176 -745)">
					<g transform="translate(177 746)">
						<path d="M6.08 8.483v2.325c0 .51.29.979.747 1.208l.522.26c.76.38 1.654.38 2.414 0l.522-.26c.457-.23.746-.697.746-1.208V8.483"/>
						<path d="M14.47975 0.553L12.64675 0.553 12.64675 2.386"/>
						<path d="M8.556 17.117a8.555 8.555 0 006.05-14.605 8.54 8.54 0 00-1.96-1.461"/>
						<path d="M4.46475 14.736L4.46475 16.569 2.63175 16.569"/>
						<path d="M4.465 16.071a8.553 8.553 0 01-1.96-1.461 8.555 8.555 0 010-12.099v-.005A8.528 8.528 0 018.556 0"/>
						<path d="M4.469 7.677l3.084 1.542a2.24 2.24 0 002.006 0l3.084-1.542a.747.747 0 000-1.337L9.559 4.798a2.24 2.24 0 00-2.006 0L4.469 6.34a.747.747 0 000 1.337z"/>
						<path d="M13.056 7.008L13.056 9.808"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
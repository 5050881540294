const storedSkills = JSON.parse(localStorage.getItem('assessment-skills')) || {};
const storedCompleted = JSON.parse(localStorage.getItem('completed-assessments')) || [];
const storedAuth = JSON.parse(localStorage.getItem('authentication')) || {};

export const InitialState = {
	progress: {
		active: null,
		completed: storedCompleted,
		canProceed: false,
		isSaving: false,
	},
	role: storedAuth.role || null,
	candidateID: storedAuth.candidate_id || null,
	listingID: storedAuth.listing_id || null,
	contactID: storedAuth.contact_id || null,
	candidate: {},
	listing: {},
	domains: 1,
	matchedSkills: storedSkills.priority_skills && storedSkills.expires > Date.now() ? storedSkills.priority_skills : [],
	suggestedSkills: storedSkills.more_skills && storedSkills.expires > Date.now() ? storedSkills.more_skills : [],
	missingSkills: [],
};

// Action Creators
export const ActionCreators = {
	resetAssessment: () => ({ type: 'RESET_ASSESSMENT' }),
	setPage: page => ({ type: 'SET_PAGE', page }),
	setCanProceed: proceed => ({ type: 'SET_CAN_PROCEED', proceed }),
	setIsSaving: saving => ({ type: 'SET_IS_SAVING', saving }),
	addCompletedPage: page => ({ type: 'ADD_COMPLETED_PAGE', page }),
	setCandidateID: id => ({ type: 'SET_CANDIDATE_ID', id }),
	setCandidate: candidate => ({ type: 'SET_CANDIDATE', candidate }),
	updateCandidate: (field, value) => ({ type: 'UPDATE_CANDIDATE', field, value }),
	updateCandidateSkills: skills => ({ type: 'UPDATE_CANDIDATE_SKILLS', skills }),
	updateCandidateSkill: (skill, level) => ({ type: 'UPDATE_CANDIDATE_SKILL', skill, level }),
	setContactID: id => ({ type: 'SET_CONTACT_ID', id }),
	setListingID: id => ({ type: 'SET_LISTING_ID', id }),
	setListing: listing => ({ type: 'SET_LISTING', listing }),
	updateListing: (field, value) => ({ type: 'UPDATE_LISTING', field, value }),
	updateListingSkills: skills => ({ type: 'UPDATE_LISTING_SKILLS', skills }),
	updateListingSkill: (skill, level) => ({ type: 'UPDATE_LISTING_SKILL', skill, level }),
	addDomain: () => ({ type: 'ADD_DOMAIN' }),
	setDomains: amount => ({ type: 'SET_DOMAINS', amount }),
	setMatchedSkills: skills => ({ type: 'SET_MATCHED_SKILLS', skills }),
	removeMatchedSkill: id => ({ type: 'REMOVE_MATCHED_SKILL', id }),
	setSuggestedSkills: skills => ({ type: 'SET_SUGGESTED_SKILLS', skills }),
	addSuggestedSkill: id => ({ type: 'ADD_SUGGESTED_SKILL', id }),
	addMissingSkills: skills => ({ type: 'ADD_MISSING_SKILLS', skills }),
	setMissingSkills: skills => ({ type: 'SET_MISSING_SKILLS', skills }),
};

// Reducer
export function reducer(state = InitialState, action) {
	switch (action.type) {
		case 'RESET_ASSESSMENT':
			localStorage.removeItem('assessment-skills');
			localStorage.removeItem('completed-assessments');
			return InitialState;
		case 'SET_PAGE':
			return { ...state, progress: { ...state.progress, active: action.page } };
		case 'SET_CAN_PROCEED':
			return { ...state, progress: { ...state.progress, canProceed: action.proceed } };
		case 'SET_IS_SAVING':
			return { ...state, progress: { ...state.progress, isSaving: action.saving } };
		case 'ADD_COMPLETED_PAGE':
			if (!state.progress.completed.includes(action.page)) {
				localStorage.setItem('completed-assessments', JSON.stringify([...state.progress.completed, action.page]));
				return {
					...state,
					progress: { ...state.progress, completed: [...state.progress.completed, action.page] },
				};
			}
			return { ...state };
		case 'SET_CANDIDATE_ID':
			localStorage.setItem(
				'authentication',
				JSON.stringify({
					...JSON.parse(localStorage.getItem('authentication')),
					candidate_id: action.id,
				}),
			);
			return { ...state, candidateID: action.id };
		case 'SET_CANDIDATE':
			return { ...state, candidate: action.candidate };
		case 'UPDATE_CANDIDATE':
			return { ...state, candidate: { ...state.candidate, [action.field]: action.value } };
		case 'UPDATE_CANDIDATE_SKILLS':
			return { ...state, candidate: { ...state.candidate, skills: action.skills } };
		case 'UPDATE_CANDIDATE_SKILL':
			return {
				...state,
				candidate: {
					...state.candidate,
					skills: state.candidate.skills.map(s =>
						s?.skill?.name === action.skill ? { ...s, skill_experience_id: action.level } : s,
					),
				},
			};
		case 'SET_CONTACT_ID':
			localStorage.setItem(
				'authentication',
				JSON.stringify({
					...JSON.parse(localStorage.getItem('authentication')),
					contact_id: action.id,
				}),
			);
			return { ...state, contactID: action.id };
		case 'SET_LISTING_ID':
			localStorage.setItem(
				'authentication',
				JSON.stringify({
					...JSON.parse(localStorage.getItem('authentication')),
					listing_id: action.id,
				}),
			);
			return { ...state, listingID: action.id };
		case 'SET_LISTING':
			return { ...state, listing: action.listing };
		case 'UPDATE_LISTING':
			return { ...state, listing: { ...state.listing, [action.field]: action.value } };
		case 'UPDATE_LISTING_SKILLS':
			return { ...state, listing: { ...state.listing, skills: action.skills } };
		case 'UPDATE_LISTING_SKILL':
			return {
				...state,
				listing: {
					...state.listing,
					skills: state.listing.skills.map(s =>
						s.skill.name === action.skill ? { ...s, skill_experience_id: action.level } : s,
					),
				},
			};
		case 'ADD_DOMAIN':
			return { ...state, domains: state.domains + 1 };
		case 'SET_DOMAINS':
			return { ...state, domains: action.amount };
		case 'SET_MATCHED_SKILLS':
			return { ...state, matchedSkills: action.skills };
		case 'REMOVE_MATCHED_SKILL':
			return {
				...state,
				matchedSkills: state.matchedSkills.filter(s => s.id !== action.id),
			};
		case 'SET_SUGGESTED_SKILLS':
			return { ...state, suggestedSkills: action.skills };
		case 'ADD_SUGGESTED_SKILL':
			return {
				...state,
				matchedSkills: [...state.matchedSkills, state.suggestedSkills.find(s => s.id === action.id)],
				suggestedSkills: state.suggestedSkills.filter(s => s.id !== action.id),
			};
		case 'ADD_MISSING_SKILLS':
			return {
				...state,
				matchedSkills: [...state.matchedSkills, ...action.skills],
				suggestedSkills: state.suggestedSkills.filter(s => s.id !== action.id),
			};
		case 'SET_MISSING_SKILLS':
			return { ...state, missingSkills: action.skills };
		default:
			return state;
	}
}

const createElasticQuery = ({ object = 'candidate', currentFilter, query, objectPrefix = '' }) => {
	if (object !== 'candidate') {
		return null;
	}
	if (currentFilter.filters?.name?.length) {
		// Split the name
		const firstName = currentFilter.filters?.name.split(' ')[0];
		const lastName = currentFilter.filters?.name.split(' ')[1];
		if (firstName?.length && lastName?.length) {
			query.bool.should = query.bool.should.concat([
				{
					wildcard: {
						[`${objectPrefix}first_name`]: `*${firstName}*`,
					},
				},
				{
					wildcard: {
						[`${objectPrefix}last_name`]: `*${lastName}*`,
					},
				},
			]);
			query.bool.minimum_should_match = 2;
		} else {
			query.bool.should = query.bool.should.concat([
				{
					wildcard: {
						[`${objectPrefix}first_name`]: `*${currentFilter.filters?.name}*`,
					},
				},
				{
					wildcard: {
						[`${objectPrefix}last_name`]: `*${currentFilter.filters?.name}*`,
					},
				},
			]);
			query.bool.minimum_should_match = 1;
		}
	}
	if (currentFilter.filters?.owner?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}owner`]: currentFilter.filters?.owner,
			},
		});
	}
	if (currentFilter.filters?.vendor_candidate_id?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}vendor_candidate_id`]: currentFilter.filters?.vendor_candidate_id,
			},
		});
	}
	if (
		currentFilter.filters?.professional_experience_years_min ||
		currentFilter.filters?.professional_experience_years_max
	) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}professional_experience_years`]: {
					gte: currentFilter.filters?.professional_experience_years_min
						? parseFloat(currentFilter.filters?.professional_experience_years_min)
						: 0,
					lte: currentFilter.filters?.professional_experience_years_max
						? parseFloat(currentFilter.filters?.professional_experience_years_max)
						: 99,
				},
			},
		});
	}

	if (currentFilter.filters?.salary_expectation_type_min || currentFilter.filters?.salary_expectation_type_max) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}salary_expectation_type_id`]: {
					gte: currentFilter.filters?.salary_expectation_type_min
						? parseFloat(currentFilter.filters?.salary_expectation_type_min)
						: 0,
					lte: currentFilter.filters?.salary_expectation_type_max
						? parseFloat(currentFilter.filters?.salary_expectation_type_max)
						: 99,
				},
			},
		});
	}

	if (currentFilter.filters?.location_city?.length) {
		query.bool.must.push({
			term: {
				[`${objectPrefix}city`]: currentFilter.filters?.location_city,
			},
		});
	}

	if (currentFilter.filters?.location_state?.length) {
		query.bool.must.push({
			bool: {
				should: currentFilter.filters?.location_state?.map(x => ({
					match: {
						[`${objectPrefix}state`]: x,
					},
				})),
			},
		});
	}

	if (currentFilter.filters?.work_location_preferences?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}work_location_preferences.id`]: currentFilter.filters?.work_location_preferences,
			},
		});
	}

	if (currentFilter.filters?.desired_locations?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}desired_locations.state.id`]: currentFilter.filters?.desired_locations,
			},
		});
	}

	if (currentFilter.filters?.resume?.length) {
		query.bool.must.push({
			match: {
				[`${objectPrefix}resume`]: currentFilter.filters?.resume,
			},
		});
	}

	if (currentFilter.filters?.professional_category_id?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}professional_category_id`]: currentFilter.filters?.professional_category_id,
			},
		});
	}

	if (currentFilter.filters?.specialty_id?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}specialties.id`]: currentFilter.filters?.specialty_id,
			},
		});
	}

	if (currentFilter.filters?.skills?.length) {
		const nestedQuery = {
			nested: {
				path: [`${objectPrefix}candidate_skills`],
				query: {
					bool: {
						must: [],
					},
				},
			},
		};
		currentFilter.filters?.skills?.forEach(x =>
			nestedQuery.nested.query.bool.must.push({
				match: { [`${objectPrefix}candidate_skills.skill.id`]: x },
			}),
		);
		query.bool.must.push(nestedQuery);
		// query.bool.must.push({
		// 	match: {
		// 		'candidates.first_name': currentFilter.filters?.name,
		// 	},
		// });
	}

	if (currentFilter.filters?.get_the_job_skills?.length) {
		const nestedQuery = {
			nested: {
				path: [`${objectPrefix}candidate_skills`],
				query: {
					bool: {
						must: [],
					},
				},
			},
		};

		currentFilter.filters?.get_the_job_skills?.forEach(x =>
			nestedQuery.nested.query.bool.must.push({
				bool: {
					must: [
						{
							match: { [`${objectPrefix}candidate_skills.skill.id`]: x },
						},
						{
							match: { [`${objectPrefix}candidate_skills.get_the_job`]: true },
						},
					],
				},
			}),
		);
		query.bool.must.push(nestedQuery);
	}

	if (currentFilter.filters?.industries?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}industries.id`]: currentFilter.filters?.industries,
			},
		});
	}

	if (currentFilter.filters?.recent_leadership_role_id?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}recent_leadership_role_id`]: currentFilter.filters?.recent_leadership_role_id,
			},
		});
	}

	if (currentFilter.filters?.total_leadership_years_min || currentFilter.filters?.total_leadership_years_max) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}total_leadership_years`]: {
					gte: currentFilter.filters?.total_leadership_years_min
						? parseFloat(currentFilter.filters?.total_leadership_years_min)
						: 0,
					lte: currentFilter.filters?.total_leadership_years_max
						? parseFloat(currentFilter.filters?.total_leadership_years_max)
						: 99,
				},
			},
		});
	}

	if (currentFilter.filters?.desired_position?.length) {
		query.bool.must.push({
			match: {
				[`${objectPrefix}desired_position`]: currentFilter.filters?.desired_position,
			},
		});
	}

	if (currentFilter.filters?.current_title?.length) {
		query.bool.must.push({
			match: {
				[`${objectPrefix}current_title`]: currentFilter.filters?.current_title,
			},
		});
	}

	if (currentFilter.filters?.current_employer?.length) {
		query.bool.must.push({
			match: {
				[`${objectPrefix}current_employer`]: currentFilter.filters?.current_employer,
			},
		});
	}

	if (currentFilter.filters?.work_visa_type_id) {
		query.bool.must.push({
			term: {
				[`${objectPrefix}work_visa_type_id`]: currentFilter.filters?.work_visa_type_id,
			},
		});
	}

	if (currentFilter.filters?.status?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}candidate_status`]: currentFilter.filters?.status,
			},
		});
	}

	if (currentFilter.filters?.source?.length) {
		query.bool.must.push({
			terms: {
				[`${objectPrefix}source.id`]: currentFilter.filters.source,
			},
		});
	}

	// if (currentFilter.filters?.assessment_completed_min || currentFilter.filters?.assessment_completed_max) {
	// 	query.bool.must.push({
	// 		range: {
	// 			assessment_completed_at: {
	// 				gte: currentFilter.filters?.assessment_completed_min
	// 					? currentFilter.filters?.assessment_completed_min
	// 					: '01/01/1970',
	// 				lte: currentFilter.filters?.assessment_completed_max
	// 					? currentFilter.filters?.assessment_completed_max
	// 					: '12/31/2099',
	// 			},
	// 		},
	// 	});
	// }

	// if (currentFilter.filters?.shared_match_count_min || currentFilter.filters?.shared_match_count_max) {
	// 	query.bool.must.push({
	// 		range: {
	// 			shared_match_count: {
	// 				gte: currentFilter.filters?.shared_match_count_min
	// 					? parseFloat(currentFilter.filters?.shared_match_count_min)
	// 					: 0,
	// 				lte: currentFilter.filters?.shared_match_count_max
	// 					? parseFloat(currentFilter.filters?.shared_match_count_max)
	// 					: 99,
	// 			},
	// 		},
	// 	});
	// }

	if (currentFilter.filters.last_profile_update_min) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}updated_at`]: {
					gte: currentFilter.filters.last_profile_update_min,
				},
			},
		});
	}

	if (currentFilter.filters.last_profile_update_max) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}updated_at`]: {
					lte: currentFilter.filters.last_profile_update_max,
				},
			},
		});
	}

	if (currentFilter.filters.assessment_completed_min) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}assessment_completed_at`]: {
					gte: currentFilter.filters.assessment_completed_min,
				},
			},
		});
	}

	if (currentFilter.filters.assessment_completed_max) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}assessment_completed_at`]: {
					lte: currentFilter.filters.assessment_completed_max,
				},
			},
		});
	}

	if (currentFilter.filters?.min_score || currentFilter.filters?.max_score) {
		query.bool.must.push({
			range: {
				[`${objectPrefix}score`]: {
					gte: currentFilter.filters?.min_score ? parseFloat(currentFilter.filters?.min_score) : 0,
					lte: currentFilter.filters?.max_score ? parseFloat(currentFilter.filters?.max_score) : 99,
				},
			},
		});
	}

	return null;
};

export default createElasticQuery;

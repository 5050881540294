import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const DateInput = ({ className, children, name, onChange, size, value, ...props }) => {
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				className={`date-input ${className}`}
				// disableToolbar
				format="M/dd/yyyy"
				value={value || null}
				onChange={date => onChange(date, name)}
				size={size}
				{...props}
			/>
		</MuiPickersUtilsProvider>
	);
};

DateInput.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	name: PropTypes.string,
	onChange: PropTypes.func,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value: PropTypes.string
};

DateInput.defaultProps = {
	className: "",
	children: null,
	name: null,
	onChange: () => null,
	size: null,
	value: null
};

export default DateInput;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function StarCheckmarkIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size + 1}
			height={size}
			viewBox="0 0 21 20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-176 -823)">
					<g transform="translate(177 824)">
						<g>
							<path d="M4.927 17.86c-.884.461-1.92-.283-1.75-1.262l.868-5.023L.367 8.018c-.718-.693-.322-1.9.668-2.043l5.082-.733L8.387.668c.44-.89 1.72-.89 2.164 0l2.27 4.574 5.082.733c.99.142 1.386 1.35.668 2.043l-3.678 3.557.868 5.023c.169.98-.866 1.723-1.75 1.262l-4.542-2.369-4.542 2.37z"/>
							<path d="M11.1357029 8.66675L9.05270291 10.74975 7.80170291 9.49975"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React from "react";
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const WillingToRelocateDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const willingToRelocateOptions = Object.entries(data.willingToRelocate).map(([key, value]) => {
		return { id: key, label: value, value: key }
	})
	return <Dropdown data={willingToRelocateOptions} {...props} />;
}

export default WillingToRelocateDropdown;
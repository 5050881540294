/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

import EditJobBlockModal from './EditJobBlockModal';
import ChatOutlinedIcon from '../../../components/icons/SectionIcons/ChatOutlinedIcon';
import LabeledField from '../../../components/form/LabeledField';
import VerticalSpacer from '../../../components/VerticalSpacer';

const ScreeningQuestionsEditBlock = ({ job, setJob }) => {
	const [clientFeedback, setClientFeedback] = useState(job?.client_feedback || []);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<LabeledField label="Client Feedback" className="color-muted" removeMargin close>
						<VerticalSpacer height={1} />
						<TextField
							variant="outlined"
							name="team_details"
							value={clientFeedback || ''}
							onChange={ev => {
								setClientFeedback(ev.target.value);
								setJob({
									...job,
									client_feedback: ev.target.value,
								});
							}}
							fullWidth
							multiline
							rows={4}
							placeholder="Enter any client feedback"
						/>
					</LabeledField>
				</Grid>
			</Grid>
			<br />
		</>
	);
};

const EditModal = ({ job, setJob }) => {
	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="Client Feedback"
			icon={ChatOutlinedIcon}
			renderContents={({ updateJob, updatedJob, setUpdatedJob }) => {
				return <ScreeningQuestionsEditBlock job={updatedJob} setJob={setUpdatedJob} />;
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;

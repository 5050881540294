import React from 'react';
import { Typography, Box } from '@material-ui/core';
import VerticalSpacer from '../../../../VerticalSpacer';
import TextFieldWithCounter from '../../../../TextFieldWithCounter';

const LetsGetStartedForm = ({ candidate, setCandidate }) => {
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Let&apos;s Get Started
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Briefly summarize what you are looking for in your next role (hint: you don&apos;t need to overthink your
				answer).
			</Typography>
			<VerticalSpacer height={2} />
			<Box display="flex" justifyContent="stretch">
				<TextFieldWithCounter
					variant="outlined"
					value={candidate?.reason_seeking_new_role || ''}
					placeholder="e.g. I am looking for a role that allows
		 				me to work with cutting-edge technology and a team that values collaboration."
					onChange={ev =>
						setCandidate({
							target: {
								name: 'reason_seeking_new_role',
								value: ev.target.value,
							},
						})
					}
					fullWidth
					minRows={3}
					multiline
					inputProps={{
						maxLength: 300,
					}}
				/>
			</Box>
		</>
	);
};

export default LetsGetStartedForm;

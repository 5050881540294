import React, { useEffect, useState } from 'react';
import { useStore, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Page from '../../components/page/Page';
import VerticalSpacer from '../../components/VerticalSpacer';

import { ActionCreators as SiteActions } from '../../store/Site';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import * as AuthenticationAPI from '../../scripts/authentication';
import * as UserAPI from '../../scripts/users';
import ExpiredLinkMessage from '../../components/ExpiredLinkMessage';

const ValidateAssessment = () => {
	const store = useStore();
	const history = useHistory();
	const location = useLocation();
	const params = useParams();
	const { type } = params;
	const { alert } = useSelector(state => state.site);
	const [displayExpiredLinkMessage, setDisplayExpiredLinkMessage] = useState(false);

	const onLogin = (response, err) => {
		if (!response) {
			let message = 'An error ocurred loading the profile. Please reload the page.';
			if (err.response?.data?.error === 'Link not exists') message = 'Sorry, we could not authenticate that link.';
			if (+err.response?.status === 404 || +err.response?.status === 401) {
				if (err.response?.data?.error === 'Issue count exceeded.' || err?.response?.data?.error === 'Link expired') {
					setDisplayExpiredLinkMessage(true);
				} else {
					message = (
						<>
							<AlertTitle>Access Failure</AlertTitle>
							<p>
								Oops, something went wrong.
								<br />
								Please try clicking the access link again. If you continue experiencing access issues, please try again
								later.
							</p>
						</>
					);
					store.dispatch(SiteActions.showAlert(message, 'error'));
				}
			}
			return;
		}
		store.dispatch(AssessmentActions.resetAssessment());
		AuthenticationAPI.storeAssessmentCredentials(response.data, location.search, store);
		if (type === 'candidate') {
			CandidatesAPI.getCandidate(response.data.candidate_id, resp => {
				const candidate = resp.data.data;
				store.dispatch(AssessmentActions.setCandidateID(candidate.id));
				history.push(`/candidate/${candidate.id}/profile`);
			});
		} else {
			ListingsAPI.getListing(response.data.listing_id, resp => {
				const listing = resp.data.data;
				const contactId = listing.contacts[0].id;
				store.dispatch(AssessmentActions.setListingID(listing.id));
				history.push(`/contact/${contactId}/profile`);
			});
		}
	};

	// Validate login JWT
	useEffect(() => {
		const urlParams = new URLSearchParams(location?.search);
		let url = urlParams.get('authUrl');

		// Attempt to manually parse url if unencoded
		// eslint-disable-next-line prefer-destructuring
		if (!url.includes('timestamp')) url = location?.href?.split('authUrl=')[1];
		UserAPI.jwtLogin(url, onLogin);
	}, []);

	if (displayExpiredLinkMessage) {
		return <ExpiredLinkMessage />;
	}

	return (
		<Page sidebar="none" centered skipAuth skipData>
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : (
				<>
					{/* <Typography variant="body2">Loading profile...</Typography> */}
					<VerticalSpacer height={1} />
					<CircularProgress size={50} />
				</>
			)}
		</Page>
	);
};

export default ValidateAssessment;

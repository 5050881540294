import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import useCurrentUser from './useCurrentUser';
import * as AuthenticationAPI from '../scripts/authentication';

const useVerifyAuthorization = ({ role, redirectTo, from } = { role: 'admin' }) => {
	const history = useHistory();
	const { loading, currentUser } = useCurrentUser();
	console.log({currentUser})
	const isLoggedIn = AuthenticationAPI.isLoggedIn();
	const isAuthorized = useMemo(() => {
		if (!isLoggedIn) {
			return false;
		}
		return !!currentUser?.roles?.find(x => x?.name === role);
	}, [isLoggedIn, currentUser]);

	useEffect(() => {
		if (redirectTo && !loading && !isAuthorized) {
			console.log('Redirecting from useVerifyAuthorization');
			history.push(redirectTo, { redirect: from });
		}
	}, [loading, isAuthorized]);

	return { loading, isAuthorized };
};

export default useVerifyAuthorization;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function LightningIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-254 -745)">
					<g transform="translate(255 746)">
						<path d="M9.923 7.3333L13 8.4443 7.462 14.0003 8.077 10.1113 5 9.0003 10.538 4.0003z"/>
						<path d="M13 18H5a5 5 0 01-5-5V5a5 5 0 015-5h8a5 5 0 015 5v8a5 5 0 01-5 5z"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
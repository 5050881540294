/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style = {}, color = 'currentColor', size = 24, className }) {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="5.98148" cy="5.98148" r="5.98148" fill="#FA5152" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.13785 5.5515L8.77573 2.91361L9.36193 3.49981L6.72404 6.1377L9.36193 8.77558L8.77573 9.36178L6.13785 6.72389L3.49996 9.36178L2.91376 8.77558L5.55165 6.1377L2.91376 3.49981L3.49996 2.91361L6.13785 5.5515Z"
				fill="white"
			/>
		</svg>
	);
}

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function SkillStoryIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size + 2}
			viewBox="0 0 20 22"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-722 -744)">
					<g transform="translate(723 746)">
						<g transform="translate(0 -1)">
							<path d="M16 20H2a2 2 0 01-2-2V2a2 2 0 012-2h14a2 2 0 012 2v16a2 2 0 01-2 2z"/>
							<path d="M13 13L5 13"/>
							<path d="M11 16L7 16"/>
							<path d="M9 8.663l1.504.79a.399.399 0 00.579-.42l-.287-1.675 1.216-1.185a.399.399 0 00-.22-.681l-1.682-.245-.752-1.524a.4.4 0 00-.716 0L7.89 5.247l-1.683.245a.399.399 0 00-.22.68l1.216 1.186-.286 1.675a.399.399 0 00.579.42L9 8.663"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function SkillsIcon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 15.552L8.43849 15.1692C12.1258 11.9495 14 9.13817 14 6.66699C14 3.16743 11.2686 0.666992 8 0.666992C4.73137 0.666992 2 3.16743 2 6.66699C2 9.13817 3.87421 11.9495 7.56151 15.1692L8 15.552ZM8 13.7759C4.87107 10.956 3.33333 8.56648 3.33333 6.66699C3.33333 3.93139 5.44274 2.00033 8 2.00033C10.5573 2.00033 12.6667 3.93139 12.6667 6.66699C12.6667 8.56648 11.1289 10.956 8 13.7759ZM8 3.33366C9.84095 3.33366 11.3333 4.82604 11.3333 6.66699C11.3333 8.50794 9.84095 10.0003 8 10.0003C6.15905 10.0003 4.66667 8.50794 4.66667 6.66699C4.66667 4.82604 6.15905 3.33366 8 3.33366ZM6 6.66699C6 5.56242 6.89543 4.66699 8 4.66699C9.10457 4.66699 10 5.56242 10 6.66699C10 7.77156 9.10457 8.66699 8 8.66699C6.89543 8.66699 6 7.77156 6 6.66699Z"
				fill={color}
			/>
		</svg>
	);
}

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useStore, useSelector, shallowEqual } from 'react-redux';

import * as Icons from '@material-ui/icons';
import * as Utility from '../../scripts/utility';

import Table from '../Table';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';

const CompanyJobsModal = ({ company }) => {
	const store = useStore();
	const { data } = useSelector(state => state.site, shallowEqual);
	const table = useRef();

	const headers = [
		// { id: '', value: '', noSort: true },
		{ id: 'vendor_id', value: 'ID' },
		{ id: 'listings.position_title', value: 'Job' },
		// { id: 'specialties.label', value: 'Specialty' },
		// { id: 'owner', value: 'Owner', noSort: true },
		{ id: 'contact', value: 'Contact', noSort: true },
		// { id: 'listings.company', value: 'Company' },
		{ id: 'listings.city', value: 'Work Location', noSort: true },
		{ id: 'hero_skills', value: 'Hero Skills', noSort: true },
		// { id: 'listings.last_profile_update', value: 'Last Updated' },
		// { id: 'listings.assessment_completed_at', value: 'Intake Completed' },
		{ id: 'job_statuses.name', value: 'Job Status', noSort: true },
	];

	const formatData = rows => {
		return rows.map(job => {
			const contactName = `${job?.contacts?.[0]?.user?.first_name || ''} ${job?.contacts?.[0]?.user?.last_name ||
				''}`.trim();
			return [
				// <IconButton onClick={() => {}}>
				// 	<Icons.MoreVert color="primary" />
				// </IconButton>,
				<>
					<span>{job?.vendor_job_id}</span>
				</>,
				<>
					<NavLink
						to={`/job/${job.id}`}
						onClick={() => {
							store.dispatch(SiteActions.hideModal());
						}}
						className="color-inherit"
					>
						{job.position_title}
					</NavLink>
				</>,
				// <>
				// 	{Utility.getItemLabel(data.specialties, job.specialty_id) || Utility.na}
				// 	<br />
				// 	<span className="color-muted">
				// 		{Utility.getItemLabel(data.categories, job.professional_category_id) || null}
				// 	</span>
				// </>,

				// <>
				// 	<span style={{ display: 'block', minWidth: 150 }}>{job?.owner?.name}</span>
				// </>,
				<>
					<span style={{ display: 'block', minWidth: 150 }}>{contactName || ''}</span>
					{job?.contacts?.[0]?.user?.claimed_at ? (
						<span className="color-muted">Account: {Utility.formatDate(job?.contacts?.[0]?.user?.claimed_at)}</span>
					) : null}
				</>,
				// <>
				// 	<span style={{ display: 'block', minWidth: 150 }}>
				// 		{job.company || Utility.na}
				// 		<br />
				// 		<span className="color-muted">
				// 			{Utility.getItemName(data.owners, job.owner, 'vendor_owner_id') || null}
				// 		</span>
				// 	</span>
				// </>,
				<>
					<span className="color-muted">
						{job.state
							? `${job.city}, ${Utility.getFromObj(
									Utility.getIDFromObj(data.states, job.state, 'name'),
									'abbreviation',
							  )}`
							: Utility.na}
					</span>
					{/* {job?.office_locations?.length ? (
						<span className="color-muted">
							Offices: {job?.office_locations?.map(x => `${x?.city}, ${x?.abbreviation}`)?.join(', ')}
						</span>
					) : null}
					*/}
					{job?.work_location_preferences?.length ? (
						<span>{job?.work_location_preferences?.map(x => x.label).join(', ')}</span>
					) : null}
				</>,
				<>
					{job?.skills
						?.filter(s => s.hero_skill)
						.map((s, i, a) => (
							<span key={s}>
								<Tooltip
									className="tooltip"
									title={`${s?.experience_years || 0} Year${s?.experience_years !== 1 ? 's' : ''}`}
									placement="top"
								>
									<span>{s?.skill?.label}</span>
								</Tooltip>
								{i < a.length - 1 ? ', ' : null}
							</span>
						))}
				</>,
				// <>
				// 	{job?.last_profile_update ? Utility.formatDate(moment(job?.last_profile_update)) : ''}
				// 	<br />
				// 	<span className="color-muted">{Utility.formatTime(job?.last_profile_update)}</span>
				// </>,

				// <>{job?.assessment_completed_at ? Utility.formatDate(moment(job?.assessment_completed_at)) : ''}</>,

				<Chip
					size="small"
					label={Utility.getFromObj(job, 'listing_status', 'label')}
					className={`color-${Utility.getListingStatusColor(Utility.getFromObj(job, 'listing_status', 'name'))}`}
				/>,
			];
		});
	};

	return (
		<div>
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				<Icons.Business color="primary" style={{ marginRight: 10 }} />
				{company?.name} Jobs
			</Typography>
			<Table
				onUpdate={ListingsAPI.getListings}
				filters={{
					company: company?.name,
				}}
				format={formatData}
				defaultOrderBy="listings.created_at"
				defaultSortDirection="desc"
				headers={headers}
				ref={table}
			/>
		</div>
	);
};

CompanyJobsModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	company: PropTypes.object,
};

CompanyJobsModal.defaultProps = {
	company: null,
};

export default CompanyJobsModal;

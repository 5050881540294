/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.31978 7.67957C7.12693 7.67957 6.12462 6.86372 5.84044 5.75957H1.27979V4.47957H5.84044C6.12462 3.37542 7.12693 2.55957 8.31978 2.55957C9.73363 2.55957 10.8798 3.70572 10.8798 5.11957C10.8798 6.53342 9.73363 7.67957 8.31978 7.67957ZM12.1598 4.47957H14.0798V5.75957H12.1598V4.47957ZM5.11979 14.0796C3.92693 14.0796 2.92462 13.2637 2.64044 12.1596H1.27979V10.8796H2.64044C2.92462 9.77543 3.92693 8.95957 5.11979 8.95957C6.53363 8.95957 7.67978 10.1057 7.67978 11.5196C7.67978 12.9334 6.53363 14.0796 5.11979 14.0796ZM8.95979 12.1596H14.0798V10.8796H8.95979V12.1596ZM6.39979 11.5196C6.39979 12.2265 5.82671 12.7996 5.11979 12.7996C4.41286 12.7996 3.83979 12.2265 3.83979 11.5196C3.83979 10.8126 4.41286 10.2396 5.11979 10.2396C5.82671 10.2396 6.39979 10.8126 6.39979 11.5196ZM9.59978 5.11957C9.59978 5.8265 9.02671 6.39957 8.31979 6.39957C7.61286 6.39957 7.03978 5.8265 7.03978 5.11957C7.03978 4.41265 7.61286 3.83957 8.31979 3.83957C9.02671 3.83957 9.59978 4.41265 9.59978 5.11957Z"
				fill={color}
			/>
		</svg>
	);
}

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const LeadershipAllocationDropdown = ({ isIntake, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	let allocationTypes = data.leadershipAllocationTypes || [];

	// return reordering of leadershipAllocationTypes if from preferences intake form
	if (isIntake) {
		const wantedOrder = [6, 1, 2, 3, 4, 5];
		allocationTypes = allocationTypes
			.filter(item => item.id !== 7)
			.sort((a, b) => wantedOrder.indexOf(a.id) - wantedOrder.indexOf(b.id));
	}

	return <Dropdown data={allocationTypes} {...props} />;
};

export default LeadershipAllocationDropdown;

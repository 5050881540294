/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function OpenResumeIcon({ style, color = '#687180', size = 20, className }) {
    return (
        <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.375 1.75H2.91667C2.27208 1.75 1.75 2.27208 1.75 2.91667V4.375" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.75 9.625V11.0833C1.75 11.7279 2.27208 12.25 2.91667 12.25H4.375" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.625 12.25H11.0833C11.7279 12.25 12.25 11.7279 12.25 11.0833V9.625" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.25 4.375V2.91667C12.25 2.27208 11.7279 1.75 11.0833 1.75H9.625" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
import React from 'react';
import { Alert as MaterialAlert } from '@material-ui/lab';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { ActionCreators as SiteActions } from '../store/Site';

const Alert = () => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);

	return alert.show ? (
		<MaterialAlert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
			{alert.message || 'An unknown error ocurred.'}
		</MaterialAlert>
	) : null;
};

export default Alert;

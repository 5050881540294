import React, { useState } from 'react';
import { useStore } from 'react-redux';
import { ActionCreators as SiteActions } from '../store/Site';
import * as AuthenticationAPI from '../scripts/authentication';

const useLogin = () => {
	const store = useStore();
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const login = callback => {
		setLoading(true);
		AuthenticationAPI.login(username, password, response => {
			setLoading(false);
			if (!response) {
				store.dispatch(SiteActions.showAlert('There was an error logging you in. Please try again.', 'error'));
				return;
			}
			callback(response);
		});
	};

	return {
		loading,
		login,
		username,
		setUsername,
		password,
		setPassword,
	};
};

export default useLogin;

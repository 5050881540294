import React, { useState, useEffect } from 'react';
import { Grid, TextField, Tooltip, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import * as Utility from '../../../scripts/utility';
import Dropdown from './Dropdown';

const LinkDropdown = ({ data, candidate, onDelete, onComplete, idx, ...props }) => {
	const [link, setLink] = useState({
		link_type_id: props.link?.link_type_id || '',
		url: props.link?.url || '',
	});

	useEffect(() => {
		if (link.link_type_id && link.url) {
			onComplete(link.link_type_id, link.url);
		}
	}, [link]);

	return (
		<Grid container spacing={2} key={idx}>
			<Grid item xs={5}>
				<Dropdown
					data={data.linkTypes}
					name="link_types"
					value={link.link_type_id}
					onChange={e => setLink(prevLink => ({ ...prevLink, link_type_id: e.target.value }))}
					disabled={false}
					placeholder={props?.placeholder || 'Type to search...'}
					{...props}
				/>
			</Grid>
			<Grid item xs={1} />
			<Grid item xs={6}>
				<div
					style={
						idx === 0
							? undefined
							: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }
					}
				>
					<div style={{ flex: 1 }}>
						<TextField
							disabled={!link.link_type_id}
							variant="outlined"
							name="links"
							value={link.url}
							onChange={e => setLink(prevLink => ({ ...prevLink, url: e.target.value }))}
							fullWidth
							placeholder={`Add${
								Utility.getItemLabel(data.linkTypes, link.link_type_id) ? ' ' : ''
							}${Utility.getItemLabel(data.linkTypes, link.link_type_id)} URL here`}
						/>
					</div>
					<div style={{ width: 80, paddingLeft: 30 }}>
						{idx > 0 && (
							<Tooltip arrow placement="top" title="Delete Link">
								<Button
									variant="text"
									color="#000"
									size="large"
									style={{
										margin: 0,
										padding: 4,
										minWidth: 0,
										borderRadius: 4,
										fontWeight: 400,
									}}
									onClick={onDelete}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Icons.Remove color="gray" size={20} />
									</div>
								</Button>
							</Tooltip>
						)}
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

export default LinkDropdown;

import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useStore } from 'react-redux';

import * as Icons from '@material-ui/icons';
import LoadingButton from '../form/LoadingButton';
import * as Utility from '../../scripts/utility';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import LabeledField from '../form/LabeledField';
import * as AuthenticationAPI from '../../scripts/authentication';

import BiUser from '../icons/BiUser';
import { isLocalAPI, isStagingAPI } from '../../scripts/api';

const CandidateContactModal = ({ candidate }) => {
	const store = useStore();

	const [isPortalInvitationLoading, setIsPortalInvitationLoading] = useState(false);
	const [displayInvitationSuccess, setDisplayInvitationSuccess] = useState(false);

	const [isPasswordResetLoading, setIsPasswordResetLoading] = useState(false);
	const [displayPasswordResetSuccess, setDisplayPasswordResetSuccess] = useState(false);

	const [isPortalCompletionResetLoading, setIsPortalCompletionResetLoading] = useState(false);
	const [displayPortalCompletionReset, setDisplayPortalCompletionReset] = useState(false);
	const [portalResetResultButtonMessage, setPortalResetResultButtonMessage] = useState('');

	const user = candidate?.user;

	const sendAssessment = ev => {
		setIsPortalInvitationLoading(true);
		CandidatesAPI.sendAssessment(candidate?.id, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('The skills assessment could not be sent. Please try again.', 'error'));
				setIsPortalInvitationLoading(false);
				return;
			}

			// store.dispatch(SiteActions.showAlert('A portal invitation was emailed.', 'success'));

			setDisplayInvitationSuccess(true);
			setTimeout(() => {
				setDisplayInvitationSuccess(false);
			}, 2000);
			setIsPortalInvitationLoading(false);
		});
	};

	function getColumnWidth() {
		if (user?.claimed_at) return 4;
		return 3;
	}

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="h3" style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 5 }}>
							{user?.claimed_at ? (
								<>
									<BiUser color="rgb(0, 187, 0)" size={24} />
									{/* <BiUserCheck color="rgb(0, 187, 0)" size={24} /> */}
								</>
							) : (
								<>
									<BiUser color="#FA5152" size={24} />
									{/* <BiUserX color="#FA5152" size={24} /> */}
								</>
							)}
						</div>
						{/* User: {user?.first_name} {user?.last_name} */}
						Candidate User Account
					</Typography>
				</div>
			</div>

			<Grid container spacing={2} style={{ marginTop: 10 }}>
				<Grid item xs={getColumnWidth()}>
					<LabeledField label="Name" className="color-muted" removeMargin close>
						<Typography variant="body2">
							{user?.first_name} {user?.last_name}
						</Typography>
					</LabeledField>
				</Grid>
				<Grid item xs={getColumnWidth()}>
					<LabeledField label="Username" className="color-muted" removeMargin close>
						{user?.email ? (
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`mailto:${user?.email}`}
								className="color-inherit text-underline text-normal"
							>
								{user?.email}
							</a>
						) : (
							Utility.nb
						)}{' '}
					</LabeledField>
				</Grid>
				<Grid item xs={getColumnWidth()}>
					<LabeledField label="Account Created" className="color-muted" removeMargin close>
						{user?.claimed_at ? Utility.formatDate(user?.claimed_at, true, 'M/DD/YY') : 'N/A'}
					</LabeledField>
				</Grid>
				{/* Only show if Account Created is null */}
				{!user?.claimed_at && (
					<Grid item xs={getColumnWidth()}>
						<LabeledField label="Invite Last Sent" className="color-muted" removeMargin close>
							{candidate?.assessment_last_sent_at
								? `${Utility.formatDate(candidate.assessment_last_sent_at, true, 'M/DD/YY')} by
							${candidate.assessment_last_sent_by_user.first_name} ${candidate.assessment_last_sent_by_user.last_name}`
								: 'N/A'}
						</LabeledField>
					</Grid>
				)}
				{/* <Grid item xs={3}>
					<LabeledField label="Phone" className="color-muted" removeMargin close>
						{user?.phone ? (
							<a href={`tel:${user?.phone}`} className="color-inherit text-underline text-normal">
								{user?.phone}
							</a>
						) : (
							<span style={{ fontStyle: 'italic' }}>no phone number</span>
						)}
					</LabeledField>
				</Grid> */}
				<div style={{ display: 'flex', marginTop: 20 }}>
					<LoadingButton
						loading={isPortalInvitationLoading}
						disabled={candidate?.user?.claimed_at}
						color="primary"
						style={{ marginRight: 20, marginLeft: 0 }}
						size="small"
						variant="outlined"
						onClick={() => {
							sendAssessment();
						}}
					>
						{displayInvitationSuccess ? (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Icons.Check style={{ width: 16, height: 16, marginRight: 5 }} />
								Sent
							</div>
						) : (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Icons.Send style={{ width: 16, height: 16, marginRight: 5 }} />
								Portal Invitation
							</div>
						)}
					</LoadingButton>
					<LoadingButton
						loading={isPasswordResetLoading}
						disabled={!user?.claimed_at}
						color="primary"
						style={{ marginLeft: 0 }}
						variant="outlined"
						size="small"
						onClick={() => {
							setIsPasswordResetLoading(true);
							AuthenticationAPI.forgotPassword(user?.email)
								.then(x => {
									console.log({ results: x });
									setDisplayPasswordResetSuccess(true);
									setTimeout(() => {
										setDisplayPasswordResetSuccess(false);
									}, 2000);
								})
								.catch(err => {
									console.log(err);
								})
								.finally(() => {
									setIsPasswordResetLoading(false);
								});
						}}
					>
						{displayPasswordResetSuccess ? (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Icons.Check style={{ width: 16, height: 16, marginRight: 5 }} />
								Sent
							</div>
						) : (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Icons.Send style={{ width: 16, height: 16, marginRight: 5 }} />
								Password Reset
							</div>
						)}
					</LoadingButton>
					{(isStagingAPI || isLocalAPI) && (
						<LoadingButton
							loading={isPortalCompletionResetLoading}
							disabled={!candidate?.user?.claimed_at || !!portalResetResultButtonMessage}
							color="primary"
							style={{ marginLeft: 10 }}
							variant="outlined"
							size="small"
							onClick={() => {
								setIsPortalCompletionResetLoading(true);
								CandidatesAPI.resetClaimedAt(candidate?.id)
									.then(_ => {
										setDisplayPortalCompletionReset(true);
										setPortalResetResultButtonMessage('Success');
										setIsPortalCompletionResetLoading(false);

										setTimeout(() => {
											setDisplayPortalCompletionReset(false);
										}, 2000);
									})
									.catch(err => {
										setPortalResetResultButtonMessage('Error');
										setDisplayPortalCompletionReset(true);
										setIsPortalCompletionResetLoading(false);

										console.log({ err });
									});
							}}
						>
							{displayPortalCompletionReset ? (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{portalResetResultButtonMessage === 'Success' ? (
										<>
											<Icons.Check style={{ width: 16, height: 16, marginRight: 5 }} />
											{portalResetResultButtonMessage}{' '}
										</>
									) : (
										<>
											<Icons.Error style={{ width: 16, height: 16, marginRight: 5 }} />
											{portalResetResultButtonMessage}
										</>
									)}
								</div>
							) : (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Icons.Send style={{ width: 16, height: 16, marginRight: 5 }} />
									Reset Portal Completion
								</div>
							)}
						</LoadingButton>
					)}
				</div>
			</Grid>

			<div style={{ height: 50 }} />
		</div>
	);
};

export default CandidateContactModal;

import Axios from 'axios';
// @todo - Resolve this dependency cycle
// eslint-disable-next-line import/no-cycle
import * as AuthenticationAPI from './authentication';
import * as Utility from './utility';

export const isStagingAPI = process.env.REACT_APP_API_ADR.indexOf('staging') !== -1;
export const isLocalAPI = process.env.REACT_APP_API_ADR.indexOf('localhost') !== -1;

const API = Axios.create({
	baseURL: `${process.env.REACT_APP_API_ADR}/api`,
	timeout: 60000,
	headers: {
		Accept: 'application/json',
	},
});

export const retryRequest = err => {
	const originalRequest = err.config;

	if (!originalRequest.retry) {
		originalRequest.retry = true;
		const auth = JSON.parse(localStorage.getItem('authentication'));
		API.defaults.headers.common.Authorization = `${auth.token_type} ${auth.access_token}`;
		return API.request(originalRequest);
	}

	return Promise.reject(err);
};

API.interceptors.request.use(
	async config => {
		if (!config.ignoreAuth && localStorage.getItem('authentication')) {
			// If this is not a re-authorization request... (to avoid an infinite loop)
			if (!config.url.includes('oauth/token')) {
				// If our token is expired, attempt to reauthorize.
				if (localStorage.getItem('authentication-expires') < Date.now()) {
					console.log('Attempting to pre-emptively reauthorize...');
					try {
						await AuthenticationAPI.reauthorize();
					} catch (err) {
						// If we can't reauthorize, we'll just run the request with the existing (expired) authentication.
						console.log('Re-authorization failed.');
					}
				}
			}

			const token = JSON.parse(localStorage.getItem('authentication'));

			window.currentAccessToken = token.access_token;

			return {
				...config,
				headers: {
					...config.headers,
					common: {
						...config.headers.common,
						Authorization: `${token.token_type} ${token.access_token}`,
					},
				},
			};
		}

		return config;
	},
	error => Promise.reject(error),
);

API.interceptors.response.use(
	response => response,
	err => {
		// ONLY HANDLE AUTHENTICATION ISSUES HERE
		// @todo - Make sure that multiple simultaneous calls get handled gracefully

		console.error('AJAX Error', err);
		// console.log('AJAX Error', Utility.getFromObj(err, 'response', 'data'));

		console.log('API error-handling interceptor running!');

		/*
		if (Utility.getFromObj(err, 'response', 'data', 'message') === 'Unauthenticated.') {
			AuthenticationAPI.reauthorize();
		}
		*/

		if (err.response.status === 401 && !err.config.retry) {
			console.log('Response status was 401.');

			// If we got a 401 from a reauthentication request, don't try to reauthenticate again. (Avoid infinite loop)
			if (!err?.response?.config?.url?.includes('oauth/token')) {
				console.log('Response interceptor request config: ', err.response.config);
				if (AuthenticationAPI.authTimeRemaining() <= 300000) {
					console.log("Auth time remaining was less than 300000, so we're reauthorizing.");
					AuthenticationAPI.reauthorize()
						.then(response => {
							console.log("Reauthorization succeeded, so we're retrying the request.");
							console.log({ response });
							retryRequest(err);
						})
						.catch(error => {
							console.log('Re-authorization failed.');
						});
				} else {
					console.log("Auth time remaining was greater than 3000000, so we didn't reauthorize.");
					retryRequest(err);
				}
			}
		}

		return Promise.reject(err);
	},
);

// @todo - Implement authorization refresh via axios-auth-refresh

export default API;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import { Box, Button, Chip, CircularProgress, Fab, TextField, Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import LabeledField from '../form/LabeledField';
import Dropdown from '../form/dropdowns/Dropdown';
import LoadingButton from '../form/LoadingButton';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as Utility from '../../scripts/utility';
import * as ListingsAPI from '../../scripts/listings';

const JobSyncModal = ({ onSync, ...props }) => {
	const dispatch = useDispatch();
	const store = useStore();
	const [isLoadingJob, setLoadingJob] = useState(false);
	const [search, setSearch] = useState('');
	const [jobs, setJobs] = useState();
	const [job, setJob] = useState();
	const [isLoading, setLoading] = useState(false);
	const { alert } = useSelector(state => state.site, shallowEqual);

	const findJob = () => {
		setLoadingJob(true);
		dispatch(SiteActions.hideAlert());

		ListingsAPI.searchForListing(search, response => {
			setLoadingJob(false);

			if (!response) {
				dispatch(SiteActions.showAlert('An error ocurred searching for a job. Please try again.', 'error'));
				return;
			}

			if (response && !response.data.data.length) {
				dispatch(SiteActions.showAlert('No matching jobs found.', 'info'));
				return;
			}

			setJobs(response.data.data);
		});
	};

	const getJobs = () => {
		return (
			[...jobs]
				// .sort((a, b) => Utility.alphaSort(a, b, 'title'))
				.sort((a, b) => b.vendor_job_id - a.vendor_job_id)
				.map(j => ({
					id: j.vendor_job_id,
					label: j.title,
					new: !j.existing_listing_id,
					status: j.status,
					company: j.clientCorporation?.name,
				}))
		);
	};

	const onJobSync = response => {
		setLoading(false);

		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred when syncing the job. Please try again.', 'error'));
			return;
		}

		store.dispatch(SiteActions.hideAlert());
		store.dispatch(SiteActions.hideModal());
		if (typeof onSync === 'function') onSync();
	};

	const syncJob = ev => {
		setLoading(true);
		ListingsAPI.syncFromBullhorn(job, {}, onJobSync);
	};

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Job Sync
			</Typography>

			<LabeledField label="Job Title or Bullhorn ID">
				<Box display="flex" justifyContent="stretch" marginBottom="48px">
					<TextField
						variant="outlined"
						value={search}
						onChange={ev => setSearch(ev.target.value)}
						style={{ flex: 1, marginRight: 15 }}
					/>
					<Fab color="primary" onClick={findJob} aria-label="search">
						{isLoadingJob ? <CircularProgress size={24.5} style={{ color: 'white' }} /> : <Icons.Search />}
					</Fab>
				</Box>
			</LabeledField>

			{alert.show ? (
				<Alert
					severity={alert.color}
					onClose={ev => store.dispatch(SiteActions.hideAlert())}
					style={{ marginBottom: 48 }}
				>
					{alert.message}
				</Alert>
			) : null}

			{jobs ? (
				<Box marginBottom="48px">
					<LabeledField label="Job to Sync">
						<Dropdown
							data={getJobs()}
							value={job}
							onChange={ev => setJob(ev.target.value)}
							renderOption={(option, state) => (
								<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
									<Box flexGrow={1}>
										<Typography variant="caption" color="textSecondary" display="inline" style={{ marginRight: 5 }}>
											#{option.id}
										</Typography>
										<Typography display="inline">
											<b>{option.label}</b>
										</Typography>
										<Typography>
											{/* {option.status}
											{option.status && option.company ? ' - ' : ''} */}
											{option.company}
										</Typography>
									</Box>
									{option.new ? null : <Chip color="primary" size="small" label="Existing" style={{ margin: 0 }} />}
								</Box>
							)}
						/>
					</LabeledField>
				</Box>
			) : null}

			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="primary"
				onClick={syncJob}
				style={{ minWidth: 185 }}
				disabled={!job}
			>
				Sync Job
			</LoadingButton>
		</div>
	);
};

JobSyncModal.propTypes = {
	onSync: PropTypes.func,
};

JobSyncModal.defaultProps = {
	onSync: () => {},
};

export default JobSyncModal;

import React, { useState } from 'react';
import { useStore, useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Grid, Typography, Button, Chip, IconButton, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import BackArrow from '../../../components/page/BackArrow';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as Utility from '../../../scripts/utility';
import BullhornIcon from '../../../components/icons/BullhornIcon';
import CandidateEditHeaderModal from './EditHeaderModal';
import CandidateLogoLinks from '../../../components/matching/CandidateLogoLinks';
import AuditLogModal from '../../../components/modals/AuditLog/AuditLogModal';

const Block = ({ candidate, setCandidate, data, displayBackArrow, isModalHeader = false, onUpdate }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const dispatch = useDispatch();
	const history = useHistory();

	const [moreMenu, setMoreMenu] = useState({ anchor: null, listing: {} });
	const candidateID = candidate?.id;

	function handleCloseAuditLogModal() {
		dispatch(SiteActions.hideModal());
	}

	function handleOpenAuditLogModal() {
		dispatch(
			SiteActions.showModal(<AuditLogModal model={candidate} closeModalCallback={() => handleCloseAuditLogModal()} />, {
				className: 'modal-full-screen',
			}),
		);
	}

	return (
		<>
			<header
				style={{
					padding: isModalHeader ? '2rem 1.5rem' : '2rem 4rem',
					backgroundColor: 'var(--white)',
					boxShadow: '0px 3px 6px #00000015',
				}}
			>
				<Grid
					container
					spacing={1}
					style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 5 }}
				>
					<Grid item style={{ marginLeft: isModalHeader ? 0 : -30, display: 'flex', alignItems: 'center' }}>
						{displayBackArrow && (
							<BackArrow
								to="/candidates"
								onClick={e => {
									e.preventDefault();
									history.goBack();
								}}
							/>
						)}
						<Typography variant="h2" style={{ fontWeight: 700, marginLeft: isModalHeader ? 0 : -5 }}>
							{`${Utility.getFromObj(candidate, 'first_name')} ${Utility.getFromObj(candidate, 'last_name')}`} |{' '}
							{candidate?.vendor_candidate_id}
						</Typography>
						{candidate.candidate_status ? (
							<Chip
								size="small"
								label={Utility.getItemLabel(data.candidateStatuses, candidate?.candidate_status)}
								className={`color-${Utility.getCandidateStatusColor(
									Utility.getItemName(data.candidateStatuses, candidate?.candidate_status, 'id'),
								)}`}
								style={{ margin: 0, marginLeft: '1.5rem' }}
							/>
						) : null}
						{candidate.source && (
							<Chip
								size="small"
								label={candidate.source.label}
								className={`color-grey-400`}
								style={{ margin: 0, marginLeft: '1.5rem' }}
							/>
						)}
						<CandidateLogoLinks candidate={candidate} onUpdate={onUpdate} isSharable={candidate?.is_sharable} />
						<Button
							variant="text"
							color="primary"
							size="small"
							onClick={() => {
								dispatch(
									SiteActions.showModal(
										<CandidateEditHeaderModal candidate={candidate} setCandidate={setCandidate} />,
										{
											className: 'modal-medium modal-no-padding modal-no-shadow',
										},
									),
								);
							}}
						>
							EDIT
						</Button>
					</Grid>

					<Grid item>
						<IconButton style={{ marginLeft: 20 }} onClick={ev => setMoreMenu({ anchor: ev.target, candidate })}>
							<Icons.MoreVert color="primary" />
						</IconButton>
						{moreMenu ? (
							<Menu
								anchorEl={moreMenu?.anchor}
								open={Boolean(moreMenu?.anchor)}
								onClose={() => setMoreMenu({ ...moreMenu, anchor: null })}
								keepMounted
								className="link-menu"
							>
								{/* <MenuItem style={{ minWidth: 250 }}>
								<NavLink
									to="#0"
									onClick={async () => {
										console.log({ resumes });
										
										console.log({ shareLink });
									}}
									style={{
										color: 'var(--grey-400)',
										fontWeight: 300,
									}}
								>
									Generate Document Link
								</NavLink>
							</MenuItem> */}
								{/* <MenuItem style={{ minWidth: 250 }}>
								<NavLink
									to="#0"
									onClick={sendAssessment}
									style={{
										color: 'var(--grey-400)',
										fontWeight: 300,
										fontSize: 16,
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<ListItemIcon>
										<Icons.Assignment color="primary" />
									</ListItemIcon>
									Send Access Email
								</NavLink>
							</MenuItem> */}

								{/* <MenuItem style={{ minWidth: 250 }}>
								<NavLink
									to={`/candidate/${candidate?.id}/profile`}
									target="_blank"
									style={{
										color: 'var(--grey-400)',
										fontWeight: 300,

										fontSize: 16,
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<ListItemIcon>
										<Icons.Person color="primary" />
									</ListItemIcon>
									Match Testing
								</NavLink>
							</MenuItem> */}
								<MenuItem>
									<NavLink
										to={`/candidate/${candidateID}/matches`}
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.Search color="primary" />
										</ListItemIcon>
										View Suggested Matches
									</NavLink>
								</MenuItem>
								{/* <MenuItem>
									<NavLink
										to={`/candidate/${candidateID}/match-testing`}
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.TrendingUp color="primary" />
										</ListItemIcon>
										Testing
									</NavLink>
								</MenuItem> */}
								<MenuItem style={{ minWidth: 250 }}>
									<NavLink
										to={`/candidate/${candidate?.id}/profile`}
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,
											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.Person color="primary" />
										</ListItemIcon>
										View Public Profile
									</NavLink>
								</MenuItem>
								<MenuItem style={{ minWidth: 250 }}>
									{candidate.vendor_candidate_id ? (
										<a
											// eslint-disable-next-line max-len
											href={`${process.env.REACT_APP_BULLHORN_CANDIDATE_ADR}${candidate.vendor_candidate_id}`}
											target="_blank"
											rel="noreferrer"
											style={{
												color: 'var(--grey-400)',
												fontWeight: 300,
												fontSize: 16,
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<ListItemIcon>
												<BullhornIcon color="#F2591A" width={20} height={20} />
											</ListItemIcon>
											View Bullhorn Profile
										</a>
									) : (
										<Typography variant="body2">{Utility.na}</Typography>
									)}
								</MenuItem>
								<MenuItem style={{ minWidth: 250 }}>
									<NavLink
										to={'#'}
										onClick={() => handleOpenAuditLogModal()}
										style={{
											color: 'var(--grey-400)',
											fontWeight: 300,

											fontSize: 16,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon>
											<Icons.ListAlt color="primary" />
										</ListItemIcon>
										View Audit Log
									</NavLink>
								</MenuItem>
							</Menu>
						) : null}
					</Grid>
				</Grid>

				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}

				<Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
					<Grid item xs={6}>
						<Typography variant="body1" style={{ marginBottom: 0 }}>
							{/* <Icons.Room color="primary" /> */}
							{candidate.state
								? `${candidate.city}, ${Utility.getFromObj(
										Utility.getIDFromObj(data.states, candidate.state, 'name'),
										'abbreviation',
								  )} `
								: ''}
							{candidate.state && candidate.owner && ' | '}
							{candidate.owner ? `Owner: ${candidate.owner.first_name} ${candidate.owner?.last_name}` : ''}
							{/* {Number(candidate.willing_to_relocate)
								? ` \u2014 ${data.willingToRelocate[candidate.willing_to_relocate]}`
								: ''} */}
						</Typography>
						<Typography variant="body1" style={{ marginBottom: 0 }}>
							{/* <Icons.Email color="primary" />{' '} */}
							{candidate.email ? (
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`mailto:${candidate.email}`}
									className="color-inherit text-underline text-normal"
								>
									{candidate.email}
								</a>
							) : (
								Utility.na
							)}{' '}
							<span style={{ marginLeft: 5, marginRight: 5 }}>|</span>{' '}
							{candidate.phone ? (
								<a href={`tel:${candidate.phone}`} className="color-inherit text-underline text-normal">
									{candidate.phone}
								</a>
							) : (
								<span style={{ fontStyle: 'italic' }}>no phone number</span>
							)}
						</Typography>
						{/* <Typography variant="body2" style={{ marginBottom: 8 }}>
							<Icons.Phone color="primary" />{' '}
						</Typography> */}
					</Grid>
					<Grid
						item
						xs={6}
						style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}
					>
						<Typography variant="caption" style={{ fontStyle: 'italic' }}>
							Last Updated: {Utility.formatDateTime(candidate.updated_at)} |{' '}
							{candidate.assessment_completed_at
								? `Intake Completed: ${Utility.formatDateTime(candidate.assessment_completed_at)}`
								: 'Intake Not Completed'}
						</Typography>
					</Grid>
				</Grid>
			</header>
		</>
	);
};

export default Block;

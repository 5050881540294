import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../../../form/LoadingButton';
import Fence from '../../../../../Fence';
import VerticalSpacer from '../../../../../VerticalSpacer';
import YearsDropdown from '../../../../../form/dropdowns/YearsDropdown';
import TargetIcon from '../../../../../icons/CheckmarkIcon';
import { ActionCreators as SiteActions } from '../../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../../scripts/candidates';

const GetTheJobSkillsExperienceForm = ({ prevStep, setStoryStep }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: candidateID } = useParams();
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);
	const skillStoryName = 'get_the_job';
	const getTheJobSkills = useMemo(() => candidate?.skills?.filter(s => s[skillStoryName]), [candidate.skills]);

	const setSkillExperience = (skill, experienceYears) => {
		const updatedSkills = candidate.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
		);
		store.dispatch(AssessmentActions.updateCandidateSkills(updatedSkills));
	};

	const nextStep = () => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		CandidatesAPI.addCandidateSkills(candidateID, candidate.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateCandidateSkills(response.data.data));
			store.dispatch(AssessmentActions.setIsSaving(false));
			setStoryStep(s => s + 1);
		});
	};

	// Check if can proceed
	useEffect(() => {
		const canProceed = !getTheJobSkills.find(s => !s.experience_years);
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [getTheJobSkills]);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">1 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Let’s further refine your skill story. Select the skills that will <strong>Land</strong> you the next job.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<TargetIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Land the Job
						</Typography>
						<Typography>Select your years of experience.</Typography>
						<VerticalSpacer height={2} />
						{getTheJobSkills.map(skill => (
							<Grid container spacing={2} key={skill.skill_id}>
								<Grid item xs={4}>
									<Chip label={skill.skill.label} color="primary" />
								</Grid>
								<Grid item xs={4}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Typography variant="label" style={{ marginRight: 10 }}>
											Experience
										</Typography>
										<YearsDropdown
											variant="outlined"
											name=""
											value={skill.experience_years}
											onChange={e => setSkillExperience(skill, e.target.value)}
											fullWidth
										/>
									</div>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={() => prevStep()}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

GetTheJobSkillsExperienceForm.propTypes = {
	prevStep: PropTypes.func,
	setStoryStep: PropTypes.func,
};

GetTheJobSkillsExperienceForm.defaultProps = {
	prevStep: () => null,
	setStoryStep: () => null,
};

export default GetTheJobSkillsExperienceForm;

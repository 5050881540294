import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const DropdownMenu = ({ label, icon, width, saveOpen, children }) => {
	const [isOpen, setOpen] = useState(false);
	// adjust opacity as sidebar collapses
	const opacity = width >= 180 ? 1 : width <= 150 ? 0 : (width - 150) / (180 - 150);

	return (
		<div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
			<button
				type="button"
				aria-haspopup="true"
				aria-expanded={isOpen ? 'true' : 'false'}
				onClick={() => {
					if (width === 80) {
						saveOpen();
						setOpen(true);
						return;
					}
					setOpen(!isOpen);
				}}
			>
				{icon}
				<span style={{ opacity, marginLeft: -8 }}>{label}</span>
				<ExpandMoreIcon style={{ opacity, transform: isOpen ? 'rotate(360deg)' : '' }} />
			</button>
			{width === 180 && <div className={`dropdown-toggle ${isOpen ? 'open' : ''}`}>{children}</div>}
		</div>
	);
};

DropdownMenu.propTypes = {
	label: PropTypes.string,
	icon: PropTypes.node,
	width: PropTypes.number,
	saveOpen: PropTypes.func.isRequired,
	children: PropTypes.node,
};

DropdownMenu.defaultProps = {
	label: null,
	icon: null,
	width: 0,
	children: null,
};

export default DropdownMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

const LoadingButton = ({ loading, width, variant, children, ...props }) => {
	const buttonWidth = width ? `${width}rem` : 'auto';
	const iconColor = variant === 'outlined' ? 'var(--primaryColor)' : 'var(--white)';

	return (
		<Button variant={variant} style={{ width: buttonWidth }} {...props}>
			{loading ? <CircularProgress size={24.5} style={{ color: iconColor }} /> : children}
		</Button>
	);
};

LoadingButton.propTypes = {
	loading: PropTypes.bool,
	width: PropTypes.number,
	variant: PropTypes.string,
	children: PropTypes.node.isRequired,
};

LoadingButton.defaultProps = {
	loading: false,
	width: null,
	variant: 'contained',
};

export default LoadingButton;

import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { TextField } from '@material-ui/core';

const DebouncedInput = ({ value, interval, onChange, ...props }) => {
	const debouncedOnChange = useCallback(
		debounce(interval || 1000, (ev, val) => onChange(ev)),
		[onChange],
	);

	const [_value, _setValue] = useState(value);

	useEffect(() => {
		_setValue(value);
	}, [value]);

	// useEffect(()=>{
	// 	_setValue(value)
	// },[value])

	const hangleChange = ev => {
		_setValue(ev.target.value);
		if (ev.target) {
			ev.persist();
			debouncedOnChange(ev, ev.target.value);
		}
	};

	return <TextField onChange={hangleChange} value={_value} {...props} />;
};

DebouncedInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
};

DebouncedInput.defaultProps = {
	value: '',
	onChange: () => {},
};

export default DebouncedInput;

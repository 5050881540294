import { useEffect } from 'react';
import { useStore, useSelector } from 'react-redux';
import * as SiteAPI from '../scripts/site';

const LoadData = ({ skip } = { skip: false }) => {
	const store = useStore();
	const { data } = useSelector(state => state.site);

	const isDataLoaded = () => {
		return Boolean(
			data.states &&
				// && data.skills.length
				data.categories.length &&
				data.specialties.length,
		);
	};

	useEffect(() => {
		if (!skip && !isDataLoaded()) {
			SiteAPI.loadSiteData(store);
		}
	}, []);

	return { isDataLoaded };
};

export default LoadData;

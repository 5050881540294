/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function LocationIcon({ style, color = 'currentColor', size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size + 1}
			viewBox="0 0 20 21"
			style={style}
			className={className}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
				<g stroke={color} strokeWidth="1.25" transform="translate(1 1)">
					<g>
						<path d="M6.116 12.187C2.562 12.655 0 13.96 0 15.5 0 17.433 4.03 19 9 19c4.971 0 9-1.567 9-3.5 0-1.54-2.561-2.845-6.115-3.313" />
						<path d="M15 5.833C15 2.611 12.314 0 9 0S3 2.611 3 5.833C3 10.208 9 15 9 15s6-4.792 6-9.167z" />
						<path d="M11 6a2 2 0 11-3.999 0 2 2 0 014 0z" />
					</g>
				</g>
			</g>
		</svg>
	);
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../scripts/candidates';

const WelcomeForm = () => {
	const pageNum = 0;
	const [isLoading, setIsLoading] = useState(false);
	const store = useStore();
	const { id: candidateID } = useParams();
	const { candidate } = useSelector(state => state.assessment, shallowEqual);

	const nextStep = () => {
		store.dispatch(AssessmentActions.addCompletedPage(pageNum));
		store.dispatch(AssessmentActions.setPage(pageNum + 1));
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setCandidate(response.data.data));
			setIsLoading(false);
		});
	}, []);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	return (
		<Fence centered width="44" className="text-center">
			<VerticalSpacer height={3} />
			<Typography variant="h1" className="font-lato-bold" gutterBottom>
				Welcome{candidate.first_name ? ` ${candidate.first_name}` : ''}!
			</Typography>
			<Typography variant="subtitle1" component="p" gutterBottom>
				Create your professional skill and experience profile in a few simple steps. We use your profile and job
				aspirations to more accurately match you with the right job opportunities.
			</Typography>
			<VerticalSpacer height={3.2} />
			<Button variant="contained" color="primary" onClick={nextStep}>
				Let&rsquo;s Get Started
			</Button>
			<VerticalSpacer height={6} />
		</Fence>
	);
};

WelcomeForm.propTypes = {};

WelcomeForm.defaultProps = {};

export default WelcomeForm;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function AvatarFilledIcon({ style, size = 18, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 18 18"
			style={style}
			className={className}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
				<path
					fill="#00D6C7"
					stroke="#00D6C7"
					strokeWidth="1"
					d="M17 9a8 8 0 01-8 8 8 8 0 01-8-8 8 8 0 018-8 8 8 0 018 8z"
				/>
				<path
					stroke="#34414E"
					strokeWidth="1"
					d="M13 13a2.105 2.105 0 00-.528-.769 2.108 2.108 0 00-1.434-.564H6.963A2.11 2.11 0 005 13"
				/>
				<path stroke="#34414E" strokeWidth="1" d="M11.25 7.25a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
			</g>
		</svg>
	);
}

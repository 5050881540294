import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../../../form/LoadingButton';
import Fence from '../../../../../Fence';
import VerticalSpacer from '../../../../../VerticalSpacer';
import TargetIcon from '../../../../../icons/TargetIcon';
import { ActionCreators as SiteActions } from '../../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../../scripts/candidates';
import InfoPopper from '../../../../../InfoPopper';

const GetTheJobSkillsForm = ({ prevStep, setStoryStep }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: candidateID } = useParams();
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);
	const skillStoryName = 'get_the_job';
	const getTheJobSkills = useMemo(() => candidate?.skills?.filter(s => s[skillStoryName]));

	const handleSelectSkill = skill => {
		const alreadySelectedSkill = candidate.skills.find(s => s.skill_id === skill.skill_id && s[skillStoryName]);
		if (getTheJobSkills.length >= 3 && !alreadySelectedSkill) return;
		const updatedSkills = candidate.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...s, [skillStoryName]: !s[skillStoryName] } : s,
		);
		store.dispatch(AssessmentActions.updateCandidateSkills(updatedSkills));
		if (getTheJobSkills.length > 0) {
			store.dispatch(AssessmentActions.setCanProceed(true));
		}
	};

	const nextStep = () => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		CandidatesAPI.addCandidateSkills(candidateID, candidate.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateCandidateSkills(response.data.data));
			store.dispatch(AssessmentActions.setIsSaving(false));
			setStoryStep(s => s + 1);
		});
	};

	useEffect(() => {
		const canProceed = getTheJobSkills?.length >= 1;
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [getTheJobSkills]);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">1 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Let’s further refine your skill story. Select the skills that will <strong>Land</strong> you the next job.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<TargetIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Land the Job
						</Typography>
						<Typography>
							Select up to <span className="text-underline">three</span> (3) high aptitude skills that are most critical
							to landing your next role.{' '}
							<InfoPopper
								info="
									The skills listed in the Land the Job section are skills that you
									ranked as your Advanced (3) and Best (4) skills.
								"
								size="small"
							/>{' '}
							These should be skills that will be the foundation of your daily work.
						</Typography>
						<VerticalSpacer height={2} />
						{candidate?.skills
							?.filter(x => x.experience)
							.map(skill => (
								<Chip
									key={skill.skill_id}
									label={skill.skill.label}
									color={getTheJobSkills.find(s => s.skill_id === skill.skill_id) ? 'primary' : 'default'}
									disabled={getTheJobSkills.length >= 3 && !getTheJobSkills.find(s => s.skill_id === skill.skill_id)}
									onClick={() => handleSelectSkill(skill)}
								/>
							))}
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={() => prevStep()}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

GetTheJobSkillsForm.propTypes = {
	prevStep: PropTypes.func,
	setStoryStep: PropTypes.func,
};

GetTheJobSkillsForm.defaultProps = {
	prevStep: () => null,
	setStoryStep: () => null,
};

export default GetTheJobSkillsForm;

import React from 'react';
import { Button, Grid, Typography, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Panel, PanelContent, PanelHeader } from '../../../components/Panel';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditExperienceModal from '../EditCandidateProfile/EditExperienceModal';
import * as Utility from '../../../scripts/utility';

const Block = ({ candidate, setCandidate, data, buttonRef, isComplete }) => {
	const dispatch = useDispatch();

	return (
		<Panel id="Experience">
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ExperienceIcon size={32} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Experience
					</Typography>
				</div>
				{!!candidate.assessment_completed_at && (
					<Button
						ref={buttonRef}
						variant={isComplete ? 'outlined' : 'containedTeal'}
						color="primary"
						size="small"
						onClick={() => {
							dispatch(
								SiteActions.showModal(<EditExperienceModal candidate={candidate} setCandidate={setCandidate} />, {
									className: 'modal-medium modal-no-padding modal-no-shadow',
								}),
							);
						}}
					>
						{isComplete ? 'Edit' : 'Complete'}
					</Button>
				)}
			</PanelHeader>
			<PanelContent style={{ paddingInline: '63px' }}>
				<VerticalSpacer height={1} />
				<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
					Work Experience
				</Typography>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={6}>
						{candidate?.current_title && (
							<>
								<Typography className="grey-250">Current Title</Typography>
								<Typography>{candidate.current_title}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.current_employer && (
							<>
								<Typography className="grey-250">Current Employer</Typography>
								<Typography>{candidate.current_employer}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.professional_experience_years != null && (
							<>
								<Typography className="grey-250">Professional Experience</Typography>
								<Typography>
									{`${candidate.professional_experience_years} Year${
										candidate.professional_experience_years !== 1 ? 's' : ''
									}`}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.current_employment_type && (
							<>
								<Typography className="grey-250">Employment Type</Typography>
								<Typography>{candidate.current_employment_type.label}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.business_size_experiences?.length > 0 && (
							<>
								<Typography className="grey-250">Business Size Experience</Typography>
								<Typography>
									{candidate?.business_size_experiences?.length === 9 &&
									candidate?.business_size_experiences
										?.map(s => s.id)
										.sort()
										.every((value, index) => value === [1, 2, 3, 4, 5, 6, 7, 8, 9][index])
										? 'All'
										: candidate.business_size_experiences
												.sort((a, b) => a.id - b.id)
												.map(t => t.label)
												.join(', ')}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.industries?.length > 0 && (
							<>
								<Typography className="grey-250" style={{ marginBottom: 5 }}>
									Industry Sector Experience
								</Typography>
								{candidate?.industries?.map(({ id }) => {
									return <Chip key={id} label={Utility.getItemLabel(data.industries, id)} color="gray" />;
								})}
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
				</Grid>
				<VerticalSpacer height={1} />
				<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
					Leadership
				</Typography>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={6}>
						{candidate?.recent_leadership_role && (
							<>
								<Typography className="grey-250">Leadership Level</Typography>
								<Typography>{candidate.recent_leadership_role.label}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.leadership_title && (
							<>
								<Typography className="grey-250">Current or Most Recent Leadership Title</Typography>
								<Typography>{candidate.leadership_title}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.total_leadership_years != null && (
							<>
								<Typography className="grey-250">Leadership Experience</Typography>
								<Typography>
									{`${candidate.total_leadership_years} Year${candidate.total_leadership_years !== 1 ? 's' : ''}`}
								</Typography>{' '}
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.leadership_allocation_type && (
							<>
								<Typography className="grey-250">Leadership Time Allocation</Typography>
								<Typography>{candidate.leadership_allocation_type.label}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
				</Grid>
				<VerticalSpacer height={1} />
				<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
					Education
				</Typography>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={6}>
						{candidate?.education_type_id && (
							<>
								<Typography className="grey-250">Highest Education Level Achieved</Typography>
								<Typography>{Utility.getItemLabel(data.educationTypes, candidate.education_type_id)}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.field_of_study && (
							<>
								<Typography className="grey-250">Major(s) / Field of Study</Typography>
								<Typography>{candidate.field_of_study}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.study_types?.length > 0 && (
							<>
								<Typography className="grey-250">Area(s) of Study</Typography>
								{candidate.study_types.map(studyType => (
									<Typography variant="body2" key={studyType.id}>
										{Utility.getItemLabel(data.studyTypes, studyType.id) || Utility.nb}
									</Typography>
								))}
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
				</Grid>
			</PanelContent>
		</Panel>
	);
};

export default Block;

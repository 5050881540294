import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useStore } from 'react-redux';
import * as SkillsAPI from '../scripts/skills';
import { ActionCreators as SiteActions } from '../store/Site';
import * as SiteAPI from '../scripts/site';

const useGlobalSkills = () => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const store = useStore();

	useEffect(() => {
		if (!data.skills?.length) {
			console.log('Triggering skills load');
			SiteAPI.updateSkills(store);
		}
	}, []);
};

export default useGlobalSkills;

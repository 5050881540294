import { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { isMobile } from 'react-device-detect';

const useCompatibility = () => {
	const history = useHistory();
	const location = useLocation();
	const compatibilityMatch = useRouteMatch('/compatibility');

	const redirectMobile = () => {
		history.push('/compatibility');
	};

	useEffect(() => {
		if (!compatibilityMatch && isMobile) redirectMobile();
	}, [location]);

	return null;
};

export default useCompatibility;

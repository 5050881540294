import React, { useEffect } from 'react';
import { Provider, shallowEqual, useSelector } from 'react-redux';
import { Switch, Route, useLocation, useParams } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import '@elastic/react-search-ui-views/lib/styles/styles.css';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import 'ag-grid-community/dist/styles/ag-theme-base.css'; // Optional theme CSS

import { history } from './store/configureStore';
import Logging from './components/behavior/Logging';
import store from './store';
import BobcatTheme from './BobcatTheme';
import Internal from './components/behavior/Internal';

import { ContextStoreWrapper } from './store/ContextStore';

// PAGES
import Login from './pages/Login';
import CandidateLogin from './pages/CandidateLogin';
import ContactLogin from './pages/ContactLogin';
import Incompatible from './pages/Incompatible';
import Home from './pages/Home';
import Candidates from './pages/candidates/Candidates';
import CandidatesAG from './pages/candidates/CandidatesAG';
import CandidateView from './pages/candidates/CandidateView';
import CandidateEdit from './pages/candidates/CandidateEdit';
import CandidateProfile from './pages/candidates/CandidateProfile';
import CandidateMatches from './pages/candidates/CandidateMatches';
import CandidateMatchTesting from './pages/candidates/CandidateMatchTesting';
import Jobs from './pages/jobs/Jobs';
import JobsAG from './pages/jobs/JobsAG';
import JobView from './pages/jobs/JobView';
import JobEdit from './pages/jobs/JobEdit';
import JobProfile from './pages/jobs/JobProfile';
import JobMatches from './pages/jobs/JobMatches';
import JobMatchTesting from './pages/jobs/JobMatchTesting';
import ContactProfile from './pages/contacts/ContactProfile';
import ValidateAssessment from './pages/assessments/ValidateAssessment';
import Users from './pages/admin/users/Users';
import UserView from './pages/admin/users/UserView';
import UserEdit from './pages/admin/users/UserEdit';
import Categories from './pages/admin/categories/Categories';
import CategoryAdd from './pages/admin/categories/CategoryAdd';
import CategoryEdit from './pages/admin/categories/CategoryEdit';
import Specialties from './pages/admin/specialties/Specialties';
import SpecialtyAdd from './pages/admin/specialties/SpecialtyAdd';
import SpecialtyEdit from './pages/admin/specialties/SpecialtyEdit';
import Skills from './pages/admin/skills/Skills';
import SkillAdd from './pages/admin/skills/SkillAdd';
import SkillEdit from './pages/admin/skills/SkillEdit';
import BullhornTest from './pages/admin/BullhornTest';
import Styleguide from './pages/admin/Styleguide';
import ForgotPassword from './pages/password/Forgot';
import ResetPassword from './pages/password/Reset';
import ResumeWindow from './pages/candidates/ResumeWindow';
import ResumeEmbedTesting from './pages/candidates/ResumeEmbedTesting';
import PDL from './pages/PDL';

const App = () => {
	return (
		<BobcatTheme>
			<ContextStoreWrapper>
				<Provider store={store}>
					<ConnectedRouter history={history}>
						<Logging>
							<Switch>
								<Route path="/compatibility" component={Incompatible} />
								<Route path="/assessment/:type/validate" component={ValidateAssessment} />
								<Route path="/candidate/:id/profile" component={CandidateProfile} />
								<Route path="/contact/:id/profile" component={ContactProfile} />
								<Route path="/job/:id/profile" component={JobProfile} />
								<Route path="/candidate-login" component={CandidateLogin} />
								<Route path="/contact-login" component={ContactLogin} />
								<Route path="/login" component={Login} />
								<Route path="/forgot-password" component={ForgotPassword} />
								<Route path="/reset-password" component={ResetPassword} />
								<Internal>
									<Switch>
										<Route exact path="/" component={Home} />
										<Route path="/pdl" component={PDL} />
										<Route path="/candidates" component={CandidatesAG} />
										<Route path="/candidates-og" component={Candidates} />
										<Route path="/candidate/:id/edit" component={CandidateEdit} />
										<Route path="/candidate/:id/matches" component={CandidateMatches} />
										<Route path="/candidate/:id/match-testing" component={CandidateMatchTesting} />
										<Route path="/candidate/:id/resume-embed-testing" component={ResumeEmbedTesting} />

										<Route path="/candidate/:id" component={CandidateView} />

										<Route path="/jobs" component={JobsAG} />
										<Route path="/jobs-og" component={Jobs} />
										<Route path="/job/:id/edit" component={JobEdit} />
										<Route path="/job/:id/matches" component={JobMatches} />
										<Route path="/view-resume" component={ResumeWindow} />
										<Route path="/job/:id/match-testing" component={JobMatchTesting} />
										<Route
											path="/job/:id"
											render={({ match }) => {
												return <JobView key={match.params.id} />;
											}}
										/>
										<Route path="/admin/users" component={Users} />
										<Route path="/admin/user/:id" exact component={UserView} />
										<Route path="/admin/user/:id/edit" component={UserEdit} />
										<Route path="/admin/categories" component={Categories} />
										<Route path="/admin/category/add" component={CategoryAdd} />
										<Route path="/admin/category/:id/edit" component={CategoryEdit} />
										<Route path="/admin/specialties" component={Specialties} />
										<Route path="/admin/specialty/add" component={SpecialtyAdd} />
										<Route path="/admin/specialty/:id/edit" component={SpecialtyEdit} />
										<Route path="/admin/manager" component={Skills} />
										<Route path="/admin/skills" component={Skills} />
										<Route path="/admin/skill/add" component={SkillAdd} />
										<Route
											path="/admin/skill/:id/edit"
											component={() => {
												const params = useParams();
												const skillID = params.id;
												return <SkillEdit key={skillID} />;
											}}
										/>
										<Route path="/bullhorn" component={BullhornTest} />
										<Route path="/styleguide" component={Styleguide} />
									</Switch>
								</Internal>
							</Switch>
						</Logging>
					</ConnectedRouter>
				</Provider>
			</ContextStoreWrapper>
		</BobcatTheme>
	);
};

export default App;

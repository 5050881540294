import Moment from 'moment';
// @todo - Resolve this dependency cycle
// eslint-disable-next-line import/no-cycle
import API from './api';
import { history } from '../store/configureStore';
import { ActionCreators as SiteActions } from '../store/Site';
import store from '../store';
import * as Utility from './utility';

export const storeCredentials = auth => {
	const credentials = auth;
	credentials.expires = Date.now() + credentials.expires_in * 1000;
	API.defaults.headers.common.Authorization = `${credentials.token_type} ${credentials.access_token}`;
	localStorage.setItem('authentication', JSON.stringify(credentials));
	localStorage.setItem('authentication-expires', credentials.expires);
	store.dispatch(SiteActions.updateAuthentication(credentials));

	// Compatibility with old system
	sessionStorage.setItem('user', JSON.stringify(auth));
};

export const storeAssessmentCredentials = (auth, query) => {
	const credentials = auth;
	const authURL = new URLSearchParams(query).get('authUrl');
	const role = new URLSearchParams(authURL.split('?')[1]).get('role');

	credentials.expires = Moment(credentials.token.expires_at).format('x');
	credentials.access_token = credentials.accessToken;
	credentials.token_type = 'Bearer';
	credentials.role = role;
	API.defaults.headers.common.Authorization = `${credentials.token_type} ${credentials.access_token}`;
	localStorage.setItem('authentication', JSON.stringify(credentials));
	localStorage.setItem('authentication-expires', credentials.expires);
	store.dispatch(SiteActions.updateAuthentication(credentials));
};

export const login = (username, password, callback) => {
	store.dispatch(SiteActions.hideAlert());

	API.post(`${process.env.REACT_APP_API_ADR}/oauth/token`, {
		grant_type: 'password',
		client_id: process.env.REACT_APP_CLIENT_ID,
		client_secret: process.env.REACT_APP_CLIENT_SECRET,
		username,
		password,
	})
		.then(response => {
			storeCredentials(response.data);
			if (typeof callback === 'function') callback(response);
		})
		.catch(err => {
			if (typeof callback === 'function') callback(false);
		});
};

export const logout = () => {
	const authentication = JSON.parse(localStorage.getItem('authentication')) || {};

	localStorage.removeItem('authentication');
	localStorage.removeItem('authentication-expires');
	API.defaults.headers.common.Authorization = '';

	if (authentication.role === 'candidate') window.location.href = 'https://nauwork.com';
	else if (authentication.role === 'contact') window.location.href = 'https://nauwork.com';
	else history.push('/login');
};

export const reauthorize = () => {
	const authentication = JSON.parse(localStorage.getItem('authentication'));

	if (!authentication) return false;

	return API.post(`${process.env.REACT_APP_API_ADR}/oauth/token`, {
		grant_type: 'refresh_token',
		refresh_token: JSON.parse(localStorage.getItem('authentication')).refresh_token,
		client_id: process.env.REACT_APP_CLIENT_ID,
		client_secret: process.env.REACT_APP_CLIENT_SECRET,
	}).then(response => storeCredentials(response.data));
};

export const forgotPassword = email => {
	store.dispatch(SiteActions.hideAlert());

	return API.post(
		`${process.env.REACT_APP_API_ADR}/api/password/forgot`,
		{
			email,
		},
		{
			ignoreAuth: true,
		},
	);
};

export const resetPassword = ({ email, token, password, passwordConfirmation }) => {
	store.dispatch(SiteActions.hideAlert());

	return API.post(`${process.env.REACT_APP_API_ADR}/api/password/reset`, {
		email,
		token,
		password,
		password_confirmation: passwordConfirmation,
	});
};

export const isLoggedIn = () => {
	const expiration = Number(localStorage.getItem('authentication-expires'));

	if (!expiration || Date.now() >= expiration) return false;

	return true;
};

/**
 * Returns the amount of time remaining on the auth token
 * @returns {Number} - The time remaining in milliseconds
 */
export const authTimeRemaining = () => {
	const expiration = Number(localStorage.getItem('authentication-expires'));

	return expiration - Date.now();
};

import React from 'react';

import Logo from '../../img/Logo';

const CenteredTopbar = props => {
	return (
		<div className="topbar centered">
			<div className="brand">
				<Logo />
			</div>
		</div>
	);
};

export default CenteredTopbar;

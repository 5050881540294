/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { TableCell, Select, MenuItem, FormControl, makeStyles, Typography } from '@material-ui/core';
import { Check as CheckIcon, ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { formatFieldName, FIELD_SPACING } from './utilities';

const styles = makeStyles(() => ({
	loading: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: FIELD_SPACING,
	},
	dropdownOption: {
		display: 'block',
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 14,
		fontWeight: 500,
	},
	select: {
		'& .MuiSelect-select': {
			padding: 0,
		},
	},
	placeholder: {
		fontSize: 14,
		fontWeight: 300,
		color: '#969696',
	},
	checkIcon: {
		color: 'green',
		marginLeft: '16px',
	},
	fieldNameCheckContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	cellHeader: {
		padding: 0,
		paddingLeft: 8,
	},
	fieldCell: {
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	userCell: {
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	dateCell: {
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	clearFilter: {
		fontWeight: 300,
		fontSize: '12px',
		color: '#969696',
		minHeight: '20px',
		marginTop: '5px',
	},
	sortAndClearContainer: {
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'space-between',
	},
	arrow: {
		height: 14,
	},
}));

/**
 * Renders the Sort and Clear Filter Button for the Audit Log Modal.
 * @param {Function} handleClearFilter - Function to clear the filter.
 * @returns {JSX.Element} - The Sort and Clear Filter Button.
 */
function SortAndClear({
	isFiltered,
	handleClearFilter,
	isSorted,
	sortDirection,
	handleSetSortDirection,
	isSortDisabled,
}) {
	const classes = styles();

	const handleFilterClearClick = () => {
		if (isFiltered) {
			handleClearFilter();
		}
	};

	const handleSort = () => {
		if (!isSortDisabled) {
			handleSetSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		}
	};

	return (
		<div className={classes.sortAndClearContainer}>
			{!!handleSetSortDirection && (
				<Typography className={classes.clearFilter} style={isSorted ? { color: '#000' } : {}} onClick={handleSort}>
					Sort{' '}
					{sortDirection === 'asc' ? (
						<ArrowUpward onClick={handleSort} className={classes.arrow} />
					) : (
						<ArrowDownward onClick={handleSort} className={classes.arrow} />
					)}
				</Typography>
			)}
			<Typography className={classes.clearFilter} onClick={handleFilterClearClick}>
				{isFiltered ? 'Clear' : ''}
			</Typography>
		</div>
	);
}

const ResizeHandle = ({ onDrag, currentWidth }) => (
	<div
		onMouseDown={e => {
			const startX = e.clientX;

			function handleMouseMove(ev) {
				const newWidth = Math.max(65, ev.clientX - startX + currentWidth);
				onDrag(newWidth);
			}

			function handleMouseUp() {
				document.removeEventListener('mousemove', handleMouseMove);
				document.removeEventListener('mouseup', handleMouseUp);
			}

			e.preventDefault();
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		}}
		style={{
			cursor: 'col-resize',
			userSelect: 'none',
			fontSize: 20,
			fontWeight: 200,
			color: 'transparent',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			borderRight: '1px solid #e3e3e3',
		}}
	>
		|||||
	</div>
);

/**
 * Renders the Date Dropdown for the Audit Log Modal.
 */
export function AuditDateDropdown({
	selectedFilterDate,
	setSelectedFilterDate,
	handleCallApi,
	handleSetSortDirection,
	sortDirection,
	isSorted,
	isSearching,
	handleResize,
	colWidth,
}) {
	const classes = styles();

	const handleClearFilter = () => {
		setSelectedFilterDate('');
		handleCallApi('');
	};

	return (
		<TableCell className={classes.cellHeader} style={{ padding: 0 }}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<FormControl className={classes.dateCell}>
					<Select
						id="date-dropdown-selector"
						value={selectedFilterDate}
						disableUnderline
						onChange={e => {
							setSelectedFilterDate(e.target.value === 'All' ? '' : e.target.value);
							handleCallApi(e.target.value);
						}}
						displayEmpty
						renderValue={
							selectedFilterDate !== '' ? undefined : () => <span className={classes.placeholder}>Date</span>
						}
					>
						<MenuItem value={'All'}>
							<span className={classes.dropdownOption}>All</span>
						</MenuItem>
						{[7, 14, 30, 60, 90, 180].map(day => (
							<MenuItem value={day}>
								<span className={classes.dropdownOption}>Last {day} days</span>
							</MenuItem>
						))}
					</Select>
					<SortAndClear
						handleClearFilter={handleClearFilter}
						isFiltered={selectedFilterDate !== ''}
						handleSetSortDirection={handleSetSortDirection}
						sortDirection={sortDirection}
						isSorted={isSorted}
						isSortDisabled={isSearching}
					/>
				</FormControl>
				<ResizeHandle onDrag={newWidth => handleResize('date', newWidth)} currentWidth={colWidth} />
			</div>
		</TableCell>
	);
}

/**
 * Renders the User Dropdown for the Audit Log Modal.
 */
export function AuditUserDropdown({
	usersWhoHaveEdited,
	selectedFilterUser,
	setSelectedFilterUser,
	handleCallApi,
	handleResize,
	colWidth,
}) {
	const classes = styles();

	const handleClearFilter = () => {
		setSelectedFilterUser('');
		handleCallApi('');
	};

	return (
		<TableCell className={classes.cellHeader}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<FormControl className={classes.userCell}>
					<Select
						id="user-dropdown-selector"
						value={selectedFilterUser}
						disableUnderline
						onChange={e => {
							setSelectedFilterUser(e.target.value === 'All' ? '' : e.target.value);
							handleCallApi(e.target.value);
						}}
						displayEmpty
						renderValue={
							selectedFilterUser !== '' ? undefined : () => <span className={classes.placeholder}>User</span>
						}
					>
						<MenuItem value="All">
							<span className={classes.dropdownOption}>All</span>
						</MenuItem>
						{usersWhoHaveEdited?.map(u => (
							<MenuItem key={u.id} value={u.id}>
								<span className={classes.dropdownOption}>
									{u.first_name}
									{` ${u.last_name !== null ? u.last_name : ''}`}
								</span>
							</MenuItem>
						))}
					</Select>
					<SortAndClear handleClearFilter={handleClearFilter} isFiltered={selectedFilterUser !== ''} />
				</FormControl>
				<ResizeHandle onDrag={newWidth => handleResize('user', newWidth)} currentWidth={colWidth} />
			</div>
		</TableCell>
	);
}

/**
 * Renders the Multiselect Fields Dropdown for the Audit Log Modal.
 */
export function AuditFieldsDropdown({
	selectedFieldFilter,
	setSelectedFieldFilter,
	handleCallApi,
	possibleFields,
	handleResize,
	colWidth,
}) {
	const classes = styles();

	const handleClearFilter = () => {
		setSelectedFieldFilter([]);
		handleCallApi([]);
	};

	return (
		<TableCell className={classes.cellHeader}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<FormControl className={classes.fieldCell}>
					<Select
						id="field-dropdown-selector"
						value={selectedFieldFilter}
						disableUnderline
						MenuProps={{
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left',
							},
							transformOrigin: {
								vertical: 'top',
								horizontal: 'left',
							},
							getContentAnchorEl: null, // Prevents content from affecting the initial positioning
						}}
						onChange={e => {
							setSelectedFieldFilter(() => {
								if (e.target.value.includes('All')) {
									handleCallApi([]);
									return [];
								}
								const updated = e.target.value;
								handleCallApi(updated);
								return updated;
							});
						}}
						multiple
						displayEmpty
						renderValue={selected => {
							if (selected.length === 0) {
								return <span className={classes.placeholder}>Field</span>;
							}
							const formattedFields = selected?.map(field => formatFieldName(field)).join(', ');
							return (
								<span className={classes.placeholder} style={{ color: '#000', fontWeight: 500 }}>
									{formattedFields}
								</span>
							);
						}}
					>
						<MenuItem key={'All'} value={'All'}>
							<div className={classes.fieldNameCheckContainer}>
								<span className={classes.dropdownOption}>All</span>
								{selectedFieldFilter.length === 0 && <CheckIcon className={classes.checkIcon} />}
							</div>
						</MenuItem>

						{Array.isArray(possibleFields) &&
							possibleFields.map(field => (
								<MenuItem key={field} value={field}>
									<div className={classes.fieldNameCheckContainer}>
										<span className={classes.dropdownOption}>{formatFieldName(field)}</span>
										{selectedFieldFilter?.includes(field) && <CheckIcon className={classes.checkIcon} />}
									</div>
								</MenuItem>
							))}
					</Select>
					<SortAndClear handleClearFilter={handleClearFilter} isFiltered={selectedFieldFilter.length > 0} />
				</FormControl>
				<ResizeHandle onDrag={newWidth => handleResize('field', newWidth)} currentWidth={colWidth} />
			</div>
		</TableCell>
	);
}

export function FromColumn({ colWidth, handleResize }) {
	const classes = styles();

	return (
		<TableCell className={classes.cellHeader}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					height: '58px',
				}}
			>
				<div style={{ paddingTop: 6 }}>From </div>
				<ResizeHandle onDrag={newWidth => handleResize('from', newWidth)} currentWidth={colWidth} />
			</div>
		</TableCell>
	);
}

export function ToColumn({ colWidth, handleResize }) {
	const classes = styles();

	return (
		<TableCell className={classes.cellHeader}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					height: '58px',
				}}
			>
				<div style={{ paddingTop: 6 }}>To </div>
				<ResizeHandle onDrag={newWidth => handleResize('to', newWidth)} currentWidth={colWidth} />
			</div>
		</TableCell>
	);
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Typography } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import * as Utility from '../../../scripts/utility';
import EditJobBlockModal from './EditJobBlockModal';
import SlidersIcon from '../../../components/icons/SectionIcons/SlidersIcon';
import LeadershipAllocationDropdown from '../../../components/form/dropdowns/LeadershipAllocationDropdown';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import EducationDropdown from '../../../components/form/dropdowns/EducationDropdown';
import AreasOfStudyDropdown from '../../../components/form/dropdowns/AreasOfStudyDropdown';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';
import SingleSpecialtyCategoryDropdown from '../../../components/form/dropdowns/SingleCategorySpecialtyDropdown';
import { BusinessSizeExperienceDropdown } from '../../../components/form/dropdowns/BusinessSizeDropdown';
// eslint-disable-next-line max-len
import MultiInputIndustryExperienceDropdown from '../../../components/form/dropdowns/MultiInputIndustryExperienceDropdown';

const EditModal = ({ job, setJob }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const isParentChildCategoryMatch = useMemo(() => {
		if (!job.professional_category_id || !job?.child_professional_category?.id) {
			return false;
		}

		return job.professional_category_id === job.child_professional_category.id;
	}, [job.professional_category_id, job.child_professional_category]);

	const isParentChildSpecialtyMatch = useMemo(() => {
		if (!job?.specialty_id || !job?.child_specialty?.id) {
			return false;
		}

		return job.specialty_id === job.child_specialty.id;
	}, [job.specialty_id, job.child_specialty]);

	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="Experience"
			icon={SlidersIcon}
			renderContents={({ updateJob, setUpdatedJob, updatedJob }) => {
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Title" required removeMargin>
									<TextField
										variant="outlined"
										name="position_title"
										value={updatedJob.position_title || ''}
										onChange={updatedJob}
										error={!updatedJob.position_title}
										fullWidth
										placeholder="Enter job title"
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={6}>
								<LabeledField label="Minimum Required Years of Professional Experience">
									<YearsDropdown
										variant="outlined"
										name="professional_experience_years"
										value={Utility.getFromObj(job, 'professional_experience_years')}
										placeholder="Choose from our list"
										onChange={updateJob}
										fullWidth
										// error={!Utility.getFromObj(listing, 'professional_experience_years')}
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={6}>
								<LabeledField label="Type">
									<Dropdown
										variant="outlined"
										name="role_type"
										value={updatedJob.role_type}
										data={data.employmentPreferences}
										onChange={updateJob}
										// error={!updatedJob.role_type}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							{/* <Grid item xs={6}>
								<LabeledField label="Category" required removeMargin>
									<CategoryDropdown
										variant="outlined"
										name="professional_category_id"
										value={updatedJob.professional_category_id}
										onChange={updateJob}
										fullWidth
										placeholder="Choose from our list"
										error={!updatedJob.professional_category_id}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Specialty" removeMargin>
									<SpecialtyDropdown
										variant="outlined"
										name="specialty_id"
										value={updatedJob.specialty_id}
										category={updatedJob.professional_category_id}
										onChange={updateJob}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid> */}

							<Grid item xs={12}>
								<div style={{ height: 30 }} />
								<SingleSpecialtyCategoryDropdown
									onChange={updateJob}
									// setModel={setUpdatedJob}
									model={updatedJob}
									specialtyLabel={
										!isParentChildSpecialtyMatch && job.child_specialty ? job.child_specialty.label : null
									}
									specialtyTooltip={"Client's specialty"}
									categoryLabel={
										!isParentChildCategoryMatch && job.child_professional_category
											? job.child_professional_category.label
											: null
									}
									categoryTooltip={"Client's professional category"}
								/>
								<div style={{ height: 10 }} />
							</Grid>

							{/* <Grid item xs={6}>
								<LabeledField label="Business Size Experience" removeMargin>
									<BusinessSizeExperienceDropdown
										variant="outlined"
										name="business_size_experiences"
										value={(Utility.getFromObj(updatedJob, 'business_size_experiences') || []).map(item =>
											item.id ? item.id : item,
										)}
										onChange={updatedJob}
										placeholder={
											Utility.getFromObj(updatedJob, 'business_size_experiences').length > 0
												? ''
												: 'Select all that apply'
										}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid> */}
							<Grid item xs={12}>
								<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
									Leadership & Education
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<MultiInputIndustryExperienceDropdown onChange={updateJob} model={updatedJob} />
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership Scope">
									<Dropdown
										variant="outlined"
										name="recent_leadership_role_id"
										value={updatedJob.recent_leadership_role_id}
										data={data.leadershipExperienceRoles}
										onChange={updateJob}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership Experience">
									<YearsDropdown
										variant="outlined"
										name="total_leadership_years"
										value={updatedJob.total_leadership_years}
										onChange={updateJob}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership Time Allocation">
									<LeadershipAllocationDropdown
										variant="outlined"
										name="leadership_allocation_type_id"
										value={Utility.getFromObj(updatedJob, 'leadership_allocation_type_id')}
										placeholder="Choose from our list"
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={6} />
							<Grid item xs={6}>
								<LabeledField label="Education" required>
									<EducationDropdown
										variant="outlined"
										name="education_type_id"
										value={updatedJob.education_type_id}
										onChange={updateJob}
										fullWidth
										placeholder="Choose from our list"
										error={!updatedJob.education_type_id}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Preferred Area(s) of Study" required>
									<AreasOfStudyDropdown
										variant="outlined"
										name="study_types"
										value={updatedJob?.study_types?.map(t => (t.id ? t.id : t))}
										onChange={updateJob}
										fullWidth
										placeholder={updatedJob?.study_types?.length > 0 ? '' : 'Select all that apply'}
										multiple
										error={!updatedJob?.study_types?.length > 0}
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={6}>
								<LabeledField label="Business Size (Edit on company)" removeMargin>
									<BusinessSizeExperienceDropdown
										disabled
										variant="outlined"
										name="business_sizes"
										value={(Utility.getFromObj(updatedJob?.client_corporation, 'business_sizes') || []).map(item =>
											item.id ? item.id : item,
										)}
										// onChange={updateJob}
										placeholder={
											Utility.getFromObj(updatedJob?.client_corporation, 'business_sizes').length > 0
												? ''
												: 'Select all that apply'
										}
										isJob
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={12}>
								<div style={{ marginTop: -10 }} />
								<AddAdditionalInfo
									additionalInfo={updatedJob.additional_experience_details}
									setAdditionalInfo={newAdditionalInfo => {
										updateJob({
											target: {
												name: 'additional_experience_details',
												value: newAdditionalInfo,
											},
										});
									}}
								/>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
			className={className}
		>
			<g id="24 / file / file-text">
				<path
					id="icon"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.33333 0.666992H10.2761L14 4.39085V14.0003C14 14.7367 13.403 15.3337 12.6667 15.3337H3.33333C2.59695 15.3337 2 14.7367 2 14.0003V2.00033C2 1.26395 2.59695 0.666992 3.33333 0.666992ZM8.66667 2.00033H3.33333V14.0003H12.6667V6.00033H10C9.26362 6.00033 8.66667 5.40337 8.66667 4.66699V2.00033ZM10 2.27647V4.66699H12.3905L10 2.27647ZM5.33333 11.3337V10.0003H9.33333V11.3337H5.33333ZM5.33333 7.33366V8.66699H10.6667V7.33366H5.33333Z"
					fill={color}
				/>
			</g>
		</svg>
	);
}

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const WorkLocationDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return <Dropdown data={data.workLocationPreferences} {...props} />;
};

export default WorkLocationDropdown;

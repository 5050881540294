import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, Grid, TextField } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

import { Filter, FilterItem } from '../page/Filter';
import Dropdown from '../form/dropdowns/Dropdown';
import StatesDropdown from '../form/dropdowns/StatesDropdown';
import CategoryDropdown from '../form/dropdowns/CategoryDropdown';
import DateInput from '../form/inputs/DateInput';
import DebouncedInput from '../form/inputs/DebouncedInput';
import WorkLocationDropdown from '../form/dropdowns/WorkLocationDropdown';
import IndustryExperienceDropdown from '../form/dropdowns/IndustryExperienceDropdown';
import WorkVisaDropdown from '../form/dropdowns/WorkVisaDropdown';
import SalaryDropdown from '../form/dropdowns/SalaryDropdown';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as Utility from '../../scripts/utility';
import ElasticSkillDropdown from '../form/dropdowns/ElasticSkillDropdown';

const SaveFilterModal = ({ onSave }) => {
	const [savedFilterName, setSavedFilterName] = useState('My Filter');

	return (
		<div>
			<Typography variant="h6" style={{ marginBottom: 10 }}>
				Save Filter
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 10 }}>
				Save your current filterset to easily access it again.
			</Typography>
			<TextField
				variant="outlined"
				name="name"
				value={savedFilterName}
				onChange={e => {
					setSavedFilterName(e.target.value);
				}}
				placeholder="Enter filter name..."
				fullWidth
			/>
			<div style={{ height: 10 }} />
			<Button
				color="primary"
				variant="contained"
				style={{ whiteSpace: 'nowrap', marginLeft: 0 }}
				disabled={!savedFilterName}
				onClick={() => {
					onSave(savedFilterName);
				}}
			>
				Save
			</Button>
		</div>
	);
};

const DeleteFilterModal = ({ onDelete, onClose }) => {
	return (
		<div>
			<Typography variant="h6" style={{ marginBottom: 10 }}>
				Delete Filter
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 10 }}>
				Are you sure you want to delete this filter?
			</Typography>
			<div style={{ height: 10 }} />
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					color="primary"
					variant="outlined"
					style={{ whiteSpace: 'nowrap', marginLeft: 0 }}
					onClick={() => {
						onClose();
					}}
				>
					Cancel
				</Button>
				<div style={{ width: 10 }} />
				<Button
					color="primary"
					variant="contained"
					style={{ whiteSpace: 'nowrap', marginLeft: 0 }}
					onClick={() => {
						onDelete();
					}}
				>
					Delete Filter
				</Button>
			</div>
		</div>
	);
};

export const SavedFiltersBlock = ({
	filter,
	savedFilters,
	setSavedFilters,
	setFilter,
	onUpdate,
	setActiveSaveFilterId,
	activeSaveFilterId,
}) => {
	const dispatch = useDispatch();
	return (
		<div
			style={{
				padding: 10,
			}}
		>
			<div style={{ display: 'flex' }}>
				<Dropdown
					data={[
						...savedFilters?.map(savedFilter => ({
							id: savedFilter.id,
							label: savedFilter.name,
						})),
						{
							id: 'custom',
							label: 'Custom Filter',
						},
					]}
					value={activeSaveFilterId || 'custom'}
					onChange={e => {
						const newValue = e.target.value;
						setActiveSaveFilterId(newValue);
						if (newValue === 'custom') {
							setFilter({ isOpen: true, filters: {} });
							onUpdate();
						} else {
							const savedFilter = savedFilters?.find(f => f.id === newValue);

							if (savedFilter) {
								setFilter({ isOpen: true, filters: savedFilter?.filters || {} });
							}
							onUpdate();
						}
					}}
					renderOption={(option, { selected }) => (
						<>
							{/* <span style={{ backgroundColor: option.color }} /> */}
							<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
								<div
									style={{
										maxWidth: 'calc(100% - 20px)',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{option.label}
								</div>
								{option.id !== 'custom' ? (
									<Icons.Delete
										onClick={e => {
											e.stopPropagation();

											dispatch(
												SiteActions.showModal(
													<DeleteFilterModal
														onDelete={() => {
															const newFilters = savedFilters?.filter(f => f.id !== option.id);
															setSavedFilters(newFilters);
															setActiveSaveFilterId(null);
															dispatch(SiteActions.hideModal());
														}}
														// match={menu?.match}
														onClose={() => dispatch(SiteActions.hideModal())}
													/>,
													{
														className: 'modal-small',
													},
												),
											);

											console.log('Deleting!', option);
										}}
									/>
								) : null}
							</div>
						</>
					)}
					style={{
						flex: 1,
					}}
				/>
				<Button
					variant="outlined"
					color="primary"
					size="small"
					style={{ whiteSpace: 'nowrap', marginLeft: 5, minWidth: 45 }}
					disabled={!Object.keys(filter.filters || {}).length}
					onClick={() => {
						dispatch(
							SiteActions.showModal(
								<SaveFilterModal
									onSave={newFilterName => {
										const newFilterId = Date.now();
										setSavedFilters([
											...savedFilters,
											{
												id: newFilterId,
												name: newFilterName,
												filters: filter.filters,
											},
										]);
										setActiveSaveFilterId(newFilterId);
										dispatch(SiteActions.hideModal());
									}}
									// match={menu?.match}
									onClose={() => dispatch(SiteActions.hideModal())}
								/>,
								{
									className: 'modal-small',
								},
							),
						);
					}}
				>
					<Icons.Save color="#fff" />
				</Button>
			</div>
		</div>
	);
};

const Candidates = ({
	displayFilters,
	savedFilters,
	enableSavedFilters,
	activeSaveFilterId,
	setActiveSaveFilterId,
	setSavedFilters,
	onClose,
	onReset,
	data,
	filter,
	updateFilters,
	onUpdate,
	setFilter,
}) => {
	return (
		<Filter open={filter.isOpen} onClose={onClose} onReset={onReset}>
			{enableSavedFilters ? (
				<SavedFiltersBlock
					filter={filter}
					savedFilters={savedFilters}
					setSavedFilters={setSavedFilters}
					setFilter={setFilter}
					onUpdate={onUpdate}
					setActiveSaveFilterId={setActiveSaveFilterId}
					activeSaveFilterId={activeSaveFilterId}
				/>
			) : null}
			{displayFilters?.map(filterType => {
				switch (filterType) {
					case 'total_skill_score':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Skill Match Score"
								active={Boolean(Utility.getFromObj(filter, 'filters', 'total_skill_score'))}
								setFilter={setFilter}
								propName="total_skill_score"
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="total_skill_score"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'total_skill_score')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'completeness_score_overall':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Record Completeness"
								active={Boolean(Utility.getFromObj(filter, 'filters', 'completeness_score_overall'))}
								setFilter={setFilter}
								propName="completeness_score_overall"
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="completeness_score_overall"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'completeness_score_overall')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'match_percentage':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Total Match Score"
								active={Boolean(Utility.getFromObj(filter, 'filters', 'match_percentage_min'))}
								setFilter={setFilter}
								propName="match_percentage_min"
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="match_percentage_min"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'candidate_match_percentage_min')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'owner':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Owner"
								count={Utility.getFromObj(filter, 'filters', 'owner').length}
								setFilter={setFilter}
								propName="owner"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="owner"
									value={Utility.getFromObj(filter, 'filters', 'owner')}
									data={data.owners.map(o => ({ ...o, id: o.vendor_owner_id, label: o.name })) || {}}
									onChange={updateFilters}
									placeholder="Select an owner..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'name':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Candidate Name"
								active={Utility.getFromObj(filter, 'filters', 'name').length}
								setFilter={setFilter}
								propName="name"
							>
								<DebouncedInput
									variant="outlined"
									name="name"
									value={Utility.getFromObj(filter, 'filters', 'name')}
									onChange={updateFilters}
									placeholder="Search candidate name..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'vendor_candidate_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Candidate ID"
								active={Utility.getFromObj(filter, 'filters', 'vendor_candidate_id').length}
								setFilter={setFilter}
								propName="vendor_candidate_id"
							>
								<DebouncedInput
									variant="outlined"
									name="vendor_candidate_id"
									value={Utility.getFromObj(filter, 'filters', 'vendor_candidate_id')}
									onChange={updateFilters}
									placeholder="Search by candidate ID..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'professional_experience_years_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Professional Experience"
								active={Boolean(
									(Utility.getFromObj(filter, 'filters', 'professional_experience_years_min') &&
										Utility.getFromObj(filter, 'filters', 'professional_experience_years_min') !== '0') ||
										(Utility.getFromObj(filter, 'filters', 'professional_experience_years_max') &&
											Utility.getFromObj(filter, 'filters', 'professional_experience_years_max') !== '0'),
								)}
								setFilter={setFilter}
								propName={['professional_experience_years_min', 'professional_experience_years_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="professional_experience_years_min"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'professional_experience_years_min')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="professional_experience_years_max"
											placeholder="Max"
											value={Utility.getFromObj(filter, 'filters', 'professional_experience_years_max')}
											onChange={updateFilters}
											fullWidth
											onKeyDown={e => {
												// prevent typing negative symbol
												if (e.which === 189) {
													e.preventDefault();
													return true;
												}
												return true;
											}}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'salary_expectation_type_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Salary Expectations"
								active={
									Utility.getFromObj(filter, 'filters', 'salary_expectation_type_min') ||
									Utility.getFromObj(filter, 'filters', 'salary_expectation_type_max')
								}
								setFilter={setFilter}
								propName={['salary_expectation_type_min', 'salary_expectation_type_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<SalaryDropdown
											variant="outlined"
											name="salary_expectation_type_min"
											value={Utility.getFromObj(filter, 'filters', 'salary_expectation_type_min')}
											placeholder="Select min..."
											onChange={updateFilters}
											// error={!candidate.salary_expectation_type_id}
											fullWidth
										/>
									</Grid>
									<Grid item xs={6}>
										<SalaryDropdown
											variant="outlined"
											name="salary_expectation_type_max"
											value={Utility.getFromObj(filter, 'filters', 'salary_expectation_type_max')}
											placeholder="Select max..."
											onChange={updateFilters}
											// error={!candidate.salary_expectation_type_id}
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'location_city':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="City"
								active={Utility.getFromObj(filter, 'filters', 'location_city').length}
								setFilter={setFilter}
								propName="location_city"
							>
								<DebouncedInput
									variant="outlined"
									name="location_city"
									value={Utility.getFromObj(filter, 'filters', 'location_city')}
									onChange={updateFilters}
									fullWidth
									placeholder="Search city..."
								/>
							</FilterItem>
						);
					case 'location_state':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="State"
								count={Utility.getFromObj(filter, 'filters', 'location_state').length}
								setFilter={setFilter}
								propName="location_state"
							>
								<StatesDropdown
									multiple
									variant="outlined"
									name="location_state"
									value={Utility.getFromObj(filter, 'filters', 'location_state')}
									onChange={updateFilters}
									fullWidth
									placeholder="Search state..."
								/>
							</FilterItem>
						);
					case 'work_location_preferences':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Work Location Preference"
								active={Utility.getFromObj(filter, 'filters', 'work_location_preferences').length}
								setFilter={setFilter}
								propName="work_location_preferences"
							>
								<WorkLocationDropdown
									variant="outlined"
									name="work_location_preferences"
									value={(Utility.getFromObj(filter, 'filters', 'work_location_preferences') || []).map(p =>
										p.id ? p.id : p,
									)}
									placeholder="Select options"
									onChange={updateFilters}
									fullWidth
									multiple
								/>
							</FilterItem>
						);
					case 'desired_locations':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Desired Work States"
								count={Utility.getFromObj(filter, 'filters', 'desired_locations').length}
								setFilter={setFilter}
								propName="desired_locations"
							>
								<StatesDropdown
									multiple
									useID
									variant="outlined"
									name="desired_locations"
									value={Utility.getFromObj(filter, 'filters', 'desired_locations')}
									onChange={updateFilters}
									fullWidth
									placeholder="Select state..."
								/>
							</FilterItem>
						);
					case 'resume':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Resume"
								active={Boolean(Utility.getFromObj(filter, 'filters', 'resume'))}
								setFilter={setFilter}
								propName="resume"
							>
								<DebouncedInput
									variant="outlined"
									name="resume"
									value={Utility.getFromObj(filter, 'filters', 'resume')}
									onChange={updateFilters}
									placeholder="Search resume..."
									fullWidth
								/>
								{/* <CheckboxInput
						type="checkbox"
						name="resume"
						value="true"
						checked={Utility.getFromObj(filter, 'filters', 'resume') === 'true'}
						onChange={updateFilters}
						label="Require Resume"
					/> */}
							</FilterItem>
						);
					case 'professional_category_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Professional Category"
								count={Utility.getFromObj(filter, 'filters', 'professional_category_id').length}
								setFilter={setFilter}
								propName="professional_category_id"
							>
								<CategoryDropdown
									multiple
									variant="outlined"
									name="professional_category_id"
									value={Utility.getFromObj(filter, 'filters', 'professional_category_id')}
									onChange={updateFilters}
									groupBy={option => {
										const split = option?.label?.split('-');

										if (split.length > 1) {
											return split[0] || 'Uncategorized';
										}
										return 'Uncategorized';
									}}
									fullWidth
									placeholder="Select a category..."
								/>
							</FilterItem>
						);
					case 'specialty_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Specialty"
								count={Utility.getFromObj(filter, 'filters', 'specialty_id').length}
								setFilter={setFilter}
								propName="specialty_id"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="specialty_id"
									value={Utility.getFromObj(filter, 'filters', 'specialty_id')}
									data={data.specialties || {}}
									onChange={updateFilters}
									fullWidth
									groupBy={option => option?.category?.label}
									placeholder="Select a specialty..."
								/>
							</FilterItem>
						);
					case 'skills':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Skills"
								count={Utility.getFromObj(filter, 'filters', 'skills').length}
								setFilter={setFilter}
								propName="skills"
							>
								<ElasticSkillDropdown
									value={Utility.getFromObj(filter, 'filters', 'skills')}
									multiple
									onChange={e => updateFilters(e)}
									variant="outlined"
									name="skills"
									placeholder="Select skills..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'get_the_job_skills':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Get the Job Skills"
								count={Utility.getFromObj(filter, 'filters', 'get_the_job_skills').length}
								setFilter={setFilter}
								propName="get_the_job_skills"
							>
								<ElasticSkillDropdown
									value={Utility.getFromObj(filter, 'filters', 'get_the_job_skills')}
									multiple
									onChange={e => updateFilters(e)}
									variant="outlined"
									name="get_the_job_skills"
									placeholder="Select skills..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'industries':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Industry Sector Experience"
								active={Utility.getFromObj(filter, 'filters', 'industries').length}
								setFilter={setFilter}
								propName="industries"
							>
								<IndustryExperienceDropdown
									variant="outlined"
									name="industries"
									value={(Utility.getFromObj(filter, 'filters', 'industries') || []).map(item =>
										item.id ? item.id : item,
									)}
									onChange={updateFilters}
									setFilter={setFilter}
									propName="industries"
									placeholder="Select industries..."
									fullWidth
									multiple
								/>
							</FilterItem>
						);
					case 'recent_leadership_role_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Recent Leadership Role"
								count={Utility.getFromObj(filter, 'filters', 'recent_leadership_role_id').length}
								setFilter={setFilter}
								propName="recent_leadership_role_id"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="recent_leadership_role_id"
									value={Utility.getFromObj(filter, 'filters', 'recent_leadership_role_id')}
									data={data.leadershipExperienceRoles || {}}
									onChange={updateFilters}
									placeholder="Select role..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'total_leadership_years_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Years of Leadership Experience"
								active={Boolean(
									(Utility.getFromObj(filter, 'filters', 'total_leadership_years_min') &&
										Utility.getFromObj(filter, 'filters', 'total_leadership_years_min') !== '0') ||
										(Utility.getFromObj(filter, 'filters', 'total_leadership_years_max') &&
											Utility.getFromObj(filter, 'filters', 'total_leadership_years_max') !== '0'),
								)}
								setFilter={setFilter}
								propName={['total_leadership_years_min', 'total_leadership_years_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="total_leadership_years_min"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'total_leadership_years_min')}
											onChange={updateFilters}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="total_leadership_years_max"
											placeholder="Max"
											value={Utility.getFromObj(filter, 'filters', 'total_leadership_years_max')}
											onChange={updateFilters}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'desired_position':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Desired Job"
								active={Utility.getFromObj(filter, 'filters', 'desired_position').length}
								setFilter={setFilter}
								propName="desired_position"
							>
								<DebouncedInput
									variant="outlined"
									name="desired_position"
									value={Utility.getFromObj(filter, 'filters', 'desired_position')}
									onChange={updateFilters}
									placeholder="Search job..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'current_title':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Current Job Title"
								active={Utility.getFromObj(filter, 'filters', 'current_title').length}
								setFilter={setFilter}
								propName="current_title"
							>
								<DebouncedInput
									variant="outlined"
									name="current_title"
									value={Utility.getFromObj(filter, 'filters', 'current_title')}
									onChange={updateFilters}
									placeholder="Search title..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'current_employer':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Current Employer"
								active={Utility.getFromObj(filter, 'filters', 'current_employer').length}
								setFilter={setFilter}
								propName="current_employer"
							>
								<DebouncedInput
									variant="outlined"
									name="current_employer"
									value={Utility.getFromObj(filter, 'filters', 'current_employer')}
									onChange={updateFilters}
									fullWidth
									placeholder="Search employers..."
								/>
							</FilterItem>
						);
					case 'work_visa_type_id':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Visa Sponsorship"
								active={Utility.getFromObj(filter, 'filters', 'work_visa_type_id')}
								setFilter={setFilter}
								propName="work_visa_type_id"
							>
								<WorkVisaDropdown
									variant="outlined"
									name="work_visa_type_id"
									value={Utility.getFromObj(filter, 'filters', 'work_visa_type_id')}
									onChange={updateFilters}
									placeholder="Choose a Visa type."
									// error={!candidate.work_visa_type_id}
									fullWidth
								/>
							</FilterItem>
						);
					case 'status':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Status"
								count={Utility.getFromObj(filter, 'filters', 'status').length}
								setFilter={setFilter}
								propName="status"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="status"
									value={Utility.getFromObj(filter, 'filters', 'status')}
									data={data.candidateStatuses || {}}
									onChange={updateFilters}
									placeholder="Select status..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'last_profile_update_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Profile Last Updated"
								active={
									Utility.getFromObj(filter, 'filters', 'last_profile_update_min').length ||
									Utility.getFromObj(filter, 'filters', 'last_profile_update_max').length
								}
								setFilter={setFilter}
								propName={['last_profile_update_min', 'last_profile_update_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="last_profile_update_min"
											value={Utility.getFromObj(filter, 'filters', 'last_profile_update_min')}
											onChange={updateFilters}
											placeholder="Start"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="last_profile_update_max"
											value={Utility.getFromObj(filter, 'filters', 'last_profile_update_max')}
											onChange={updateFilters}
											placeholder="End"
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'assessment_completed_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Intake Completed"
								active={
									Utility.getFromObj(filter, 'filters', 'assessment_completed_min').length ||
									Utility.getFromObj(filter, 'filters', 'assessment_completed_max').length
								}
								setFilter={setFilter}
								propName={['assessment_completed_min', 'assessment_completed_max']}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="assessment_completed_min"
											value={Utility.getFromObj(filter, 'filters', 'assessment_completed_min')}
											onChange={updateFilters}
											placeholder="Start"
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<DateInput
											variant="outlined"
											name="assessment_completed_max"
											value={Utility.getFromObj(filter, 'filters', 'assessment_completed_max')}
											onChange={updateFilters}
											placeholder="End"
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);
					case 'score_range':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Job Match Percentage Threshold"
								active={Boolean(
									Utility.getFromObj(filter, 'filters', 'min_score') ||
										Utility.getFromObj(filter, 'filters', 'max_score'),
								)}
								setFilter={setFilter}
								propName="min_score"
							>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="min_score"
											placeholder="Min"
											value={Utility.getFromObj(filter, 'filters', 'min_score')}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
											onChange={updateFilters}
											fullWidth
										/>
									</Grid>
									<Grid item xs={6}>
										<DebouncedInput
											variant="outlined"
											type="number"
											name="max_score"
											placeholder="Max"
											value={Utility.getFromObj(filter, 'filters', 'max_score')}
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
											onChange={updateFilters}
											fullWidth
										/>
									</Grid>
								</Grid>
							</FilterItem>
						);

					case 'match_saved':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Match Saved"
								count={[1, 2].includes(Utility.getFromObj(filter, 'filters', 'match_saved')) ? 1 : 0}
								setFilter={setFilter}
								propName="match_saved"
							>
								{console.log(typeof Utility.getFromObj(filter, 'filters', 'match_saved'))}
								<Dropdown
									variant="outlined"
									name="match_saved"
									value={Utility.getFromObj(filter, 'filters', 'match_saved')}
									data={[
										{
											id: 1,
											label: 'Yes',
										},
										{
											id: 2,
											label: 'No',
										},
									]}
									onChange={updateFilters}
									placeholder="Select saved status..."
									fullWidth
								/>
							</FilterItem>
						);

					case 'match_shared':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Match Shared"
								count={[1, 2].includes(Utility.getFromObj(filter, 'filters', 'match_shared')) ? 1 : 0}
								setFilter={setFilter}
								propName="match_shared"
							>
								<Dropdown
									variant="outlined"
									name="match_shared"
									value={Utility.getFromObj(filter, 'filters', 'match_shared')}
									data={[
										{
											id: 1,
											label: 'Yes',
										},
										{
											id: 2,
											label: 'No',
										},
									]}
									onChange={updateFilters}
									placeholder="Select shared status..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'match_viewed':
						return (
							<FilterItem
								// onUpdate={onUpdate}
								label="Match Viewed"
								count={[1, 2].includes(Utility.getFromObj(filter, 'filters', 'match_viewed')) ? 1 : 0}
								setFilter={setFilter}
								propName="match_viewed"
							>
								<Dropdown
									variant="outlined"
									name="match_viewed"
									value={Utility.getFromObj(filter, 'filters', 'match_viewed')}
									data={[
										{
											id: 1,
											label: 'Yes',
										},
										{
											id: 2,
											label: 'No',
										},
									]}
									onChange={updateFilters}
									placeholder="Select viewed status..."
									fullWidth
								/>
							</FilterItem>
						);
					case 'source':
						return (
							<FilterItem
								onUpdate={onUpdate}
								label="Source"
								count={Utility.getFromObj(filter, 'filters', 'source').length}
								setFilter={setFilter}
								isSelectAll
								filterOptions={data.sources || []}
								propName="source"
							>
								<Dropdown
									multiple
									variant="outlined"
									name="source"
									value={Utility.getFromObj(filter, 'filters', 'source')}
									data={data.sources.map(s => ({ ...s, id: s.id, label: s.label })) || {}}
									onChange={updateFilters}
									placeholder="Select source..."
									fullWidth
								/>
							</FilterItem>
						);

					case 'fit_ranking':
						return (
							<FilterItem
								// onUpdate={onUpdate}
								label="Match Ranking"
								count={filter.fit_ranking?.length}
								setFilter={setFilter}
								propName="fit_ranking"
							>
								<Dropdown
									variant="outlined"
									name="fit_ranking"
									value={Utility.getFromObj(filter, 'filters', 'fit_ranking')}
									data={[
										{
											id: 1,
											label: 'Poor Match',
										},
										{
											id: 2,
											label: 'Inconclusive',
										},
										{
											id: 3,
											label: 'Good Match',
										},
										{
											id: 4,
											label: 'Excellent Match',
										},
										{
											id: 'none',
											label: 'Not Ranked',
										},
									]}
									onChange={updateFilters}
									placeholder="Select rankings..."
									fullWidth
									multiple
								/>
							</FilterItem>
						);
					default:
						return null;
				}
			})}
		</Filter>
	);
};

export default Candidates;

import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Modal,
	Button,
	Grid,
	Typography,
	Tabs,
	Tab,
	Menu,
	MenuItem,
	Chip,
	ListItemIcon,
} from '@material-ui/core';
import { palette } from '../../BobcatTheme';

const CustomTabBar = ({ activeTab, setActiveTab, tabs, style }) => {
	return (
		<div
			style={{
				borderBottom: '1px solid #ddd',
				paddingTop: 15,
				paddingLeft: 40,
				...(style || {}),
			}}
		>
			{tabs?.map((tab, index) => {
				const isActive = tab?.id === activeTab;

				return (
					<div
						key={tab?.id}
						style={{
							borderBottom: isActive ? `2px solid ${palette?.primary?.main}` : `2px solid transparent`,
							marginLeft: 0, // index === 0 ? -28 : 0,
							display: 'inline-block',
							marginRight: 20,
						}}
					>
						<Button
							color="primary"
							className="no-border-radius"
							onClick={() => {
								setActiveTab(tab?.id);
							}}
							style={{
								marginLeft: 0,
								marginRight: 0,
								paddingLeft: 0,
								paddingRight: 0,
								background: 'transparent',
							}}
						>
							<Typography
								variant="body"
								style={{ marginRight: 0, color: isActive ? '' : '#34414E', fontWeight: isActive ? 700 : 300 }}
							>
								{tab?.label}
							</Typography>
						</Button>
					</div>
				);
			})}
		</div>
	);
};

export default CustomTabBar;

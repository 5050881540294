/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function CheckmarkCircleIcon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
				<g stroke={color} strokeWidth="1.25" transform="translate(1 1)">
					<g>
						<path d="M9 18h0a9 9 0 01-9-9h0a9 9 0 019-9h0a9 9 0 019 9h0a9 9 0 01-9 9z" />
						<path d="M12.5981 6.5L7.5981 11.5 5.4021 9.304" />
					</g>
				</g>
			</g>
		</svg>
	);
}

import React from 'react';
import { Typography } from '@material-ui/core';
import { useStore } from 'react-redux';
import { ActionCreators as AssessmentActions } from '../../../../../store/Assessment';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import USWorkAuthorizationDropdown from '../../../../form/dropdowns/USWorkAuthorizationDropdown';
import WorkVisaDropdown from '../../../../form/dropdowns/WorkVisaDropdown';

const USWorkAuthorizationForm = ({ candidate, setCandidate }) => {
	const store = useStore();

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				US Work Authorization
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Do you or will you require sponsorship for a US employment visa (e.g. H-1B)?
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<Fence width={25}>
					<LabeledField
						label="Legally authorized to work in the United States?"
						info="Are you currently legally authorized to work in the United States?"
						labelStyle={{ fontWeight: '500', fontSize: 14 }}
					>
						<USWorkAuthorizationDropdown
							name="us_work_authorization"
							variant="outlined"
							value={
								candidate.us_work_authorization === null || candidate.us_work_authorization === undefined
									? null
									: candidate.us_work_authorization === true
									? '1'
									: '2'
							}
							placeholder="Choose from our list"
							onChange={e =>
								store.dispatch(AssessmentActions.updateCandidate('us_work_authorization', e.target.value === '1'))
							}
						/>
					</LabeledField>
					<LabeledField
						label="Work Visa Sponsorship"
						info="Will you require employer sponsorship for a work visa in your next role?"
						labelStyle={{ fontWeight: '500', fontSize: 14 }}
					>
						<WorkVisaDropdown
							variant="outlined"
							name="work_visa_type_id"
							value={candidate?.work_visa_type_id}
							onChange={setCandidate}
							placeholder="Choose from our list"
							fullWidth
						/>
					</LabeledField>
				</Fence>
				<VerticalSpacer height={3} />
			</Fence>
		</>
	);
};

export default USWorkAuthorizationForm;

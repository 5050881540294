import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';

import { FIELD_SPACING } from './utilities';

const styles = makeStyles(() => ({
	noResultsText: {
		marginTop: FIELD_SPACING,
		width: '100%',
		justifyContent: 'center',
		display: 'flex',
	},
	resetFiltersText: {
		marginTop: FIELD_SPACING,
		width: '100%',
		justifyContent: 'center',
		display: 'flex',
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}));

function AuditEmptyView({ displayResetFilters, handleCallApi }) {
	const classes = styles();
	return (
		<>
			<Typography variant="h3" className={classes.noResultsText}>
				No audit logs found
			</Typography>
			{displayResetFilters && (
				<Typography
					onClick={() => {
						handleCallApi();
					}}
					variant="body2"
					className={classes.resetFiltersText}
				>
					Reset filters
				</Typography>
			)}
		</>
	);
}

export default AuditEmptyView;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Reaction({ style, color = '#D2D7DE', size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 38 38"
			style={style}
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 37.3337C8.87474 37.3337 0.666626 29.1255 0.666626 19.0003C0.666626 8.8751 8.87474 0.666992 19 0.666992C29.1252 0.666992 37.3333 8.8751 37.3333 19.0003C37.3333 29.1255 29.1252 37.3337 19 37.3337ZM19 34.0003C27.2842 34.0003 34 27.2846 34 19.0003C34 10.7161 27.2842 4.00033 19 4.00033C10.7157 4.00033 3.99996 10.7161 3.99996 19.0003C3.99996 27.2846 10.7157 34.0003 19 34.0003ZM27.1842 24.7477L24.4577 22.83C23.2155 24.5963 21.1991 25.667 19 25.667C16.8008 25.667 14.7845 24.5963 13.5422 22.83L10.8157 24.7477C12.6757 27.3923 15.704 29.0003 19 29.0003C22.2959 29.0003 25.3242 27.3923 27.1842 24.7477ZM22.3333 17.3337V14.0003H25.6666V17.3337H22.3333ZM12.3333 14.0003V17.3337H15.6666V14.0003H12.3333Z"
				fill={color}
			/>
		</svg>
	);
}

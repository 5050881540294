import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FolderOpen, Fullscreen, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import * as Utility from '../../../scripts/utility';
import ViewBlockWrapper from './ViewBlockWrapper';
import DocumentIcon from '../../../components/icons/SectionIcons/DocumentIcon';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import FormattedText from '../../../components/form/FormattedText';
import ExpandableTypography from '../../../components/form/ExpandableTypography';
import ViewDocumentModal, { DocumentEmbed } from './ViewDocumentModal';
import PlusIcon from '../../../components/icons/BasicPlusIcon';
import UploadDocumentModal from './UploadDocumentModal';
import CustomTabBar from '../../../components/matching/CustomTabBar';

const Block = ({ candidate, refreshCandidate, data, displayDocumentManagement }) => {
	const [currentDocumentTab, setCurrentDocumentTab] = useState('resume-embed');
	// const [currentViewTab, setCurrentViewTab] = useState('embed');
	const [documents, setDocuments] = useState([]);
	const [resumes, setResumes] = useState([]);
	const [shareLink, setShareLink] = useState(null);
	const [resumeShareLink, setResumeShareLink] = useState(null);

	const resume = resumes?.[0];
	const profileDoc = [...documents]?.sort((a, b) => {
		return +new Date(b.updated_at) - +new Date(a.updated_at);
	})[0];

	const refreshDocuments = () => {
		CandidatesAPI.getCandidateResumes(
			candidate?.id,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) {
					return;
				}
				setResumes(response.data.data);
			},
		);

		CandidatesAPI.getDocuments({ candidateId: candidate?.id }).then(results => {
			setDocuments(results?.data?.data);
		});
	};

	useEffect(() => {
		refreshDocuments();
	}, [candidate?.id]);

	useEffect(() => {
		// Create a share link for displaying the embedded resume.
		if (resume) {
			CandidatesAPI.createCandidateResumeShareLink({
				candidateId: candidate?.id,
				documentId: resume?.document_id,
			}).then(link => {
				setResumeShareLink(link?.data?.url);
			});

			if (resume?.file_name?.includes('.txt')) {
				setCurrentDocumentTab('resume-text');
			} else if (resume?.file_name?.includes('.doc')) {
				// setCurrentDocumentTab('resume-html');
				setCurrentDocumentTab('resume-embed');
			} else {
				setCurrentDocumentTab('resume-embed');
			}
		} else if (candidate?.resume) {
			setCurrentDocumentTab('resume-text');
		}
	}, [resume]);

	useEffect(() => {
		// Create a share link for displaying the embedded profile.
		if (profileDoc) {
			CandidatesAPI.createCandidateDocumentShareLink({
				candidateId: candidate?.id,
				documentId: profileDoc.id,
			}).then(link => {
				setShareLink(link?.data?.url);
			});
		}
	}, [profileDoc]);

	// eslint-disable-next-line no-shadow
	const openResume = (ev, resume) => {
		ev.preventDefault();
		CandidatesAPI.downloadCandidateResume(candidate?.id, resume.document_id, resume.file_name);
	};

	const height = 820;

	const candidateResume = candidate?.resume;

	return (
		<>
			<ViewBlockWrapper
				icon={DocumentIcon}
				title="Documents"
				// onEditClick={() => {
				// 	dispatch(
				// 		SiteActions.showModal(<EditPreferencesModal candidate={candidate} setCandidate={setCandidate} />, {
				// 			className: 'modal-medium modal-no-padding modal-no-shadow',
				// 		}),
				// 	);
				// }}
			>
				<CustomTabBar
					activeTab={currentDocumentTab}
					setActiveTab={setCurrentDocumentTab}
					tabs={[
						{
							label: 'Resume',
							id: 'resume-embed',
						},
						{
							label: 'Resume - HTML',
							id: 'resume-html',
						},
						{
							label: 'Resume - Text',
							id: 'resume-text',
						},
						{
							label: 'Profile',
							id: 'profile-embed',
						},
					]}
					style={{
						paddingLeft: 0,
						paddingTop: 0,
					}}
				/>

				<div style={{ paddingTop: 20 }}>
					{/* <Tabs
							value={currentD}
							onChange={(e, newValue) => {
								setCurrentViewTab(newValue);
							}}
							className="mini"
						>
							<Tab label="Text" value="text" />
							<Tab label="Embed" value="embed" disabled={!resumeShareLink} />
						</Tabs> */}
					<div style={{ height: 0 }} />
					{currentDocumentTab === 'resume-html' ? (
						<div
							style={{
								whiteSpace: 'pre-wrap',
								padding: 15,
								backgroundColor: '#f5f5f5',
								border: '1px solid #e0e0e0',
							}}
						>
							<FormattedText height={20} style={{ whiteSpace: 'normal', maxHeight: 'none' }}>
								{/* <Typography variant="body2">{job.description || Utility.nb}</Typography> */}
								<div
									className="resume_html"
									style={{
										minHeight: candidate?.resume_html ? 800 : 0,
									}}
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{
										__html: candidate?.resume_html,
									}}
								/>
							</FormattedText>
						</div>
					) : currentDocumentTab === 'resume-text' ? (
						<div
							style={{
								whiteSpace: 'pre-wrap',
								padding: 15,
								backgroundColor: '#f5f5f5',
								border: '1px solid #e0e0e0',
							}}
						>
							{candidateResume?.includes('<br') ? (
								<FormattedText height={20} style={{ whiteSpace: 'normal', maxHeight: 'none' }}>
									{/* <Typography variant="body2">{job.description || Utility.nb}</Typography> */}
									<div
										className="resume_html"
										style={{
											minHeight: candidateResume ? 800 : 0,
										}}
										// eslint-disable-next-line react/no-danger
										dangerouslySetInnerHTML={{
											__html: candidateResume,
										}}
									/>
								</FormattedText>
							) : (
								<ExpandableTypography numCharacters={1500} variant="body2" text={candidate.resume || Utility.nb} />
							)}
						</div>
					) : currentDocumentTab === 'resume-embed' && resumeShareLink ? (
						<DocumentEmbed
							key="resume-embed"
							shareLink={resumeShareLink}
							height={height}
							fileName={resume?.file_name}
						/>
					) : currentDocumentTab === 'profile-embed' && shareLink ? (
						<DocumentEmbed key="profile-embed" shareLink={shareLink} height={height} fileName={profileDoc?.file_name} />
					) : (
						<Typography style={{ opacity: 0.5, fontStyle: 'italic' }}> No document to display.</Typography>
					)}
					{/* {resume ? (
						<Grid container spacing={2} key={`resume-${resume.document_id}`}>
							<Grid item xs={6} key={resume.document_id}>
								<Typography className="grey-250">File Name</Typography>
								<a
									href="#0"
									style={{
										fontWeight: 300,
										textDecoration: 'underline',
									}}
									onClick={ev => openResume(ev, resume)}
								>
									{resume.file_name}
								</a>
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Date Provided</Typography>
								<Typography>
									<i>{Utility.formatDate(resume.updated_at, false, 'MMMM D, YYYY')}</i>
								</Typography>
							</Grid>
						</Grid>
					) : null} */}
				</div>
				{displayDocumentManagement && (
					<CandidateDocumentManager
						candidateId={candidate?.id}
						data={data}
						refreshCandidate={refreshCandidate}
						documents={documents}
						resume={resume}
						refreshDocuments={refreshDocuments}
						resumeShareLink={resumeShareLink}
						onProfileUpload={() => {
							console.log('onProfileUpload');
							setCurrentDocumentTab('profile-embed');
						}}
						onResumeUpload={() => {
							setCurrentDocumentTab('resume-embed');
						}}
						onTextResumeUpload={() => {
							setCurrentDocumentTab('resume-text');
						}}
					/>
				)}
			</ViewBlockWrapper>
		</>
	);
};

const CandidateDocumentManager = ({
	candidateId,
	data,
	refreshCandidate,
	documents,
	resume,
	resumeShareLink,
	refreshDocuments,
	onProfileUpload,
	onResumeUpload,
	onTextResumeUpload,
}) => {
	// const [shareLink, setShareLink] = useState(null);

	const [minimized, setMinimized] = useState(true);
	const dispatch = useDispatch();

	return (
		<div style={{ paddingTop: 20 }}>
			<Button
				onClick={() => {
					setMinimized(!minimized);
				}}
				variant="text"
				style={{ width: 'calc(100% + 40px)', marginBottom: 0, marginLeft: -20, marginRight: -20 }}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						paddingTop: 15,
						paddingBottom: 15,
						cursor: 'pointer',
						userSelect: 'none',
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FolderOpen size={20} style={{ color: 'var(--teal-100)' }} />
						<div style={{ width: 10 }} />
						<Typography variant="h3">File Cabinet</Typography>
					</div>
					{!minimized ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
				</div>
			</Button>
			{!minimized && (
				<>
					{/* <hr /> */}

					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							marginBottom: -10,
							marginTop: 15,
						}}
					>
						<Button
							onClick={async e => {
								dispatch(
									SiteActions.showModal(
										<UploadDocumentModal
											context="candidate"
											onUpload={async (file, type, isPlainText) => {
												if (file && type !== undefined) {
													if (type === 1) {
														// It's a profile type, upload as a document
														const result = await CandidatesAPI.postDocument({
															candidateId,
															file,
															type,
														});
														dispatch(SiteActions.hideModal());
														refreshDocuments();
														onProfileUpload();

														console.log({ result });
													} else if (type === 2) {
														if (isPlainText) {
															await new Promise((resolve, reject) => {
																CandidatesAPI.uploadCandidateTextResume(candidateId, file, (result, err) => {
																	dispatch(SiteActions.hideModal());
																	refreshDocuments();
																	refreshCandidate();
																	onTextResumeUpload();
																	resolve();
																});
															});
														} else {
															// It's a resume type, upload as a resume.
															await new Promise((resolve, reject) => {
																CandidatesAPI.uploadCandidateResume(candidateId, file, (result, err) => {
																	dispatch(SiteActions.hideModal());
																	refreshDocuments();
																	refreshCandidate();
																	onResumeUpload();
																	resolve();
																});
															});
														}
													}
												}
											}}
										/>,
										{
											className: 'modal-small',
										},
									),
								);
							}}
							variant="contained"
							color="primary"
							size="small"
							style={{ whiteSpace: 'nowrap' }}
						>
							<PlusIcon />
							<div style={{ width: 10 }} />
							Upload Document
						</Button>
					</div>
					<div style={{ height: 20 }} />
					<table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '5px 15px' }}>
						<thead>
							<tr style={{ textAlign: 'left' }}>
								<th>File Name</th>
								<th>Type</th>
								<th>Updated</th>
								{/* <th>Actions</th> */}
								<th />
							</tr>
						</thead>
						<tbody>
							{resume && (
								<tr key={resume.id} style={{ marginBottom: 10, marginTop: 10 }}>
									<td>
										<span style={{ marginRight: 10 }}>{resume.file_name}</span>
									</td>
									<td>Resume</td>
									<td>
										<p style={{ margin: 0 }}>{moment(resume.updated_at).format('MM/DD/YY')}</p>
										<p style={{ margin: 0 }}>{moment(resume.updated_at).format('h:mm a')}</p>
									</td>
									<td>
										{resumeShareLink && (
											<>
												<Button
													onClick={async e => {
														dispatch(
															SiteActions.showModal(
																<ViewDocumentModal document={resume} shareLink={resumeShareLink} />,
																{
																	className: 'modal-medium document-modal',
																},
															),
														);
													}}
													variant="outlined"
													color="primary"
													size="small"
													style={{ whiteSpace: 'nowrap', padding: 6, minWidth: 0 }}
												>
													<Fullscreen color="primary" />
													{/* View */}
												</Button>
												<Button
													onClick={async e => {
														e.preventDefault();
														CandidatesAPI.downloadCandidateResume(candidateId, resume.document_id, resume.file_name);
													}}
													variant="outlined"
													color="primary"
													size="small"
													style={{ whiteSpace: 'nowrap', padding: 6, minWidth: 0 }}
												>
													<Icons.GetApp color="primary" />
													{/* Download */}
												</Button>
											</>
										)}
										{/* <Button
									key={resume.id}
									onClick={async e => {

										e.preventDefault();
										CandidatesAPI.downloadCandidateResume(candidateId, resume.document_id, resume.file_name);
										// console.log({ shareLink });
									}}
									variant="outlined"
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap' }}
								>
									Download
								</Button> */}
									</td>
								</tr>
							)}
							{[...(documents || [])]
								?.sort((a, b) => {
									return +new Date(b.updated_at) - +new Date(a.updated_at);
								})
								?.slice(0, 1)
								.map(doc => (
									<tr key={doc?.id} style={{ marginBottom: 10, marginTop: 10 }}>
										<td>
											<span style={{ marginRight: 10 }}>{doc.file_name}</span>
										</td>
										<td>{doc.file_type}</td>
										<td>
											<p style={{ margin: 0 }}>{moment(doc.updated_at).format('MM/DD/YY')}</p>
											<p style={{ margin: 0 }}>{moment(doc.updated_at).format('h:mm a')}</p>
										</td>
										<td>
											<Button
												key={doc.id}
												onClick={async e => {
													const shareLink = await CandidatesAPI.createCandidateDocumentShareLink({
														candidateId,
														documentId: doc.id,
													});
													// setShareLink(shareLink?.data?.url);
													dispatch(
														SiteActions.showModal(
															<ViewDocumentModal document={doc} shareLink={shareLink?.data?.url} />,
															{
																className: 'modal-medium document-modal',
															},
														),
													);
													console.log({ shareLink });
												}}
												variant="outlined"
												color="primary"
												size="small"
												style={{ whiteSpace: 'nowrap', padding: 6, minWidth: 0 }}
											>
												<Fullscreen color="primary" />
												{/* View */}
											</Button>
											<Button
												key={doc.id}
												onClick={async e => {
													const shareLink = await CandidatesAPI.createCandidateDocumentShareLink({
														candidateId,
														documentId: doc.id,
													});
													if (shareLink?.data?.url) {
														const link = document.createElement('a');
														link.href = shareLink?.data?.url;
														link.download = doc.file_name;
														link.click();
													}
												}}
												variant="outlined"
												color="primary"
												size="small"
												style={{ whiteSpace: 'nowrap', padding: 6, minWidth: 0 }}
											>
												<Icons.GetApp color="primary" />
												{/* Download */}
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</>
			)}
		</div>
	);
};

export default Block;

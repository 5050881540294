import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ElasticSkillDropdown from '../../../../../form/dropdowns/ElasticSkillDropdown';
import LoadingButton from '../../../../../form/LoadingButton';
import Fence from '../../../../../Fence';
import LabeledField from '../../../../../form/LabeledField';
import VerticalSpacer from '../../../../../VerticalSpacer';
import SkillDropdown from '../../../../../form/dropdowns/SkillDropdown';
import LearnIcon from '../../../../../icons/LearnIcon';
import VerifyAccountModal from '../../../../VerifyAccountModal';
import { ActionCreators as SiteActions } from '../../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../../scripts/candidates';

const LearnSkillsForm = ({ prevStep, setCandidateProfile, getCandidateResumes }) => {
	const store = useStore();
	const { data, alert, user: authUser } = useSelector(state => state.site, shallowEqual);
	const { id: candidateID } = useParams();
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);
	const [validSkills, setValidSkills] = useState([]);
	const [preSelectedSkillIds, setPreselectedSkillIds] = useState([]);
	const [preSelectedSkills, setPreselectedSkills] = useState([]);
	const skillStoryName = 'learn_on_job';
	const learnOnTheJobSkills = useMemo(() => candidate?.skills?.filter(s => s[skillStoryName]), [candidate.skills]);

	const handleSetPreselectedSkills = (skillIds, skillCache) => {
		if (skillIds.length <= 3) {
			setPreselectedSkillIds(skillIds);
			setPreselectedSkills(skillIds.map(x => skillCache[x]));
		}
	};

	const addPreselectedSkills = () => {
		const newSkills = preSelectedSkills.map(skill => ({
			skill_id: skill.id,
			candidate_id: candidateID,
			skill,
			[skillStoryName]: true,
			skill_experience_id: 1,
			experience_years: 0,
		}));
		const updatedCandidateSkills = candidate.skills.concat(newSkills);
		store.dispatch(AssessmentActions.updateCandidateSkills(updatedCandidateSkills));
		setPreselectedSkillIds([]);
		if (learnOnTheJobSkills.length > 0) {
			store.dispatch(AssessmentActions.setCanProceed(true));
		}
	};

	const handleRemoveSkill = skill => {
		const updatedSkills = candidate.skills.filter(s => s.skill_id !== skill.skill_id);
		store.dispatch(AssessmentActions.updateCandidateSkills(updatedSkills));
		if (learnOnTheJobSkills.length > 0) {
			store.dispatch(AssessmentActions.setCanProceed(true));
		}
	};

	const nextStep = () => {
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));
		CandidatesAPI.addCandidateSkills(candidateID, candidate.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				store.dispatch(AssessmentActions.setIsSaving(false));
				return;
			}
			store.dispatch(AssessmentActions.updateCandidateSkills(response.data.data));
			// store.dispatch(AssessmentActions.setIsSaving(false));
			CandidatesAPI.markAssessmentComplete(candidateID, res => {
				// store.dispatch(AssessmentActions.setIsSaving(false));
				if (!res) {
					SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
					return;
				}
				setCandidateProfile({
					...(candidate || {}),
					...(res.data.data || {}),
				});
				getCandidateResumes();
				store.dispatch(AssessmentActions.resetAssessment());
				store.dispatch(AssessmentActions.setIsSaving(false));
				if (!authUser.claimed_at) {
					// open user verification
					store.dispatch(
						SiteActions.showModal(<VerifyAccountModal onAdd={() => null} />, {
							className: 'modal-small',
							disableCloseButton: true,
							disableBackdropClick: true,
							disableEscapeKey: true,
						}),
					);
				} else {
					store.dispatch(SiteActions.hideModal());
				}
			});
		});
	};

	useEffect(() => {
		if (!validSkills.length) {
			CandidatesAPI.getValidSkills(candidateID, response => {
				if (response) setValidSkills(response.data.data);
			});
		}
	}, []);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">4 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Select the skills that you&lsquo;d like to <strong>Learn</strong> on the job.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<LearnIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Learn on the Job
						</Typography>
						<Typography>
							Add up to <span className="text-underline">three</span> (3) skills you&rsquo;d most like to acquire on the
							job. These are skills you don&rsquo;t have but want to learn.
						</Typography>
						<VerticalSpacer height={1} />
						{learnOnTheJobSkills.map(skill => (
							<Chip
								key={skill.skill_id}
								label={skill.skill.label}
								color="primary"
								onDelete={() => handleRemoveSkill(skill)}
							/>
						))}
						<VerticalSpacer height={4} />
						<LabeledField label="Add Skills">
							<Grid container spacing={2}>
								<Grid item xs={7}>
									<ElasticSkillDropdown
										filterCandidateId={candidateID}
										tags
										variant="outlined"
										color="primary"
										name="skills_expert"
										value={preSelectedSkillIds}
										filter={skillId => {
											return !candidate.skills.find(s => s.skill_id === skillId);
										}}
										onChange={(e, skillCache) => handleSetPreselectedSkills(e.target.value, skillCache)}
										disabled={learnOnTheJobSkills.length >= 3}
										fullWidth
										placeholder="Choose from our list"
									/>
								</Grid>
								<Grid item xs={2}>
									<Button
										variant="contained"
										color="primary"
										className="no-margins"
										disabled={!preSelectedSkillIds.length}
										onClick={() => addPreselectedSkills()}
									>
										Add
									</Button>
								</Grid>
							</Grid>
						</LabeledField>
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={() => prevStep()}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Save Profile
				</LoadingButton>
			</Fence>
		</>
	);
};

LearnSkillsForm.propTypes = {
	prevStep: PropTypes.func,
	setCandidateProfile: PropTypes.func,
	getCandidateResumes: PropTypes.func,
};

LearnSkillsForm.defaultProps = {
	prevStep: () => null,
	setCandidateProfile: () => null,
	getCandidateResumes: () => null,
};

export default LearnSkillsForm;

import * as ListingsAPI from '../../../scripts/listings';
import * as CandidatesAPI from '../../../scripts/candidates';

export function getJob(jobId, setJob) {
	ListingsAPI.getListing(jobId, response => {
		if (!response) return;
		setJob(response.data.data);
	});
}

export function getJobs(jobIds, setJobs) {
	if (jobIds) {
		setJobs([]);
		jobIds.map(jobId => {
			ListingsAPI.getListing(jobId, response => {
				if (!response) return;
				setJobs(currentJobs =>
					[...currentJobs, response.data.data].sort((a, b) => {
						return jobIds.indexOf(a?.id?.toString()) - jobIds.indexOf(b?.id?.toString());
					}),
				);
			});
			return null;
		});
	}
}

export function getCandidate(candidateId, setCandidate) {
	CandidatesAPI.getCandidate(candidateId, response => {
		if (!response) return;
		setCandidate(response.data.data);
	});
}

export function getCandidates(candidateIds, setCandidates) {
	if (candidateIds) {
		console.log('calling getCandidates');
		candidateIds.map(candidateId => {
			return getCandidate(candidateId, newCandidate => {
				setCandidates(currentCandidates =>
					[...currentCandidates, newCandidate].sort((a, b) => {
						return candidateIds.indexOf(a?.id?.toString()) - candidateIds?.indexOf(b.id?.toString());
					}),
				);
			});
		});
	}
}

export function getCompany(corporationId, setCurrentCompany) {
	setCurrentCompany(null);
	ListingsAPI.getClientCorporation({ corporationId }).then(response => {
		if (!response) return;
		const newCompany = response.data.data;
		setCurrentCompany(newCompany);
	});
}

import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

export const sortSpecialties = specialties => {
	return specialties?.sort((el1, el2) => {
		// Put the "leadership" specialties at the end of the list
		const el1Label = el1?.label?.toUpperCase() === 'LEADERSHIP' ? 'ZZZZ' : el1?.label.toUpperCase();
		const el2Label = el1?.label?.toUpperCase() === 'LEADERSHIP' ? 'ZZZZ' : el2?.label.toUpperCase();
		if (el1Label < el2Label) return -1;
		if (el1Label > el2Label) return 1;
		return 0;
	});
};

const SpecialtyDropdown = ({ category, includeLeadershipSpecialty, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const getSpecialties = () => {
		let specialties = data.specialties || [];

		// Filter by category
		if (category) specialties = (data.categories.find(c => c.id === category) || {}).specialties || [];

		// Sort alphabetically
		specialties = sortSpecialties(specialties);

		// Add leadership specialty
		// if (includeLeadershipSpecialty) {
		// 	const defaultLeadershipSpecialty = data.specialties.find(x => x.name === 'leadership');
		// 	if (defaultLeadershipSpecialty && !specialties.some(s => s.name === 'leadership')) {
		// 		specialties = [...specialties, defaultLeadershipSpecialty];
		// 	}
		// }

		return specialties;
	};

	return <Dropdown data={getSpecialties()} {...props} />;
};

SpecialtyDropdown.propTypes = {
	category: PropTypes.number,
};

SpecialtyDropdown.defaultProps = {
	category: null,
};

export default SpecialtyDropdown;

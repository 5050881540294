import React from 'react';
import PropTypes from 'prop-types';

const WideSidebar = ({ width, padding, children, className, ...props }) => {
	return (
		<nav
			className={`sidebar wide ${className}`}
			style={{ minWidth: width, paddingLeft: padding, paddingRight: padding }}
		>
			{children}
		</nav>
	);
};

WideSidebar.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.node,
	className: PropTypes.string,
};

WideSidebar.defaultProps = {
	width: null,
	padding: null,
	children: null,
	className: null,
};

export default WideSidebar;

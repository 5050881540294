import React, { useState, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Card } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useParams } from 'react-router';

import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as Utility from '../../scripts/utility';
import * as CandidatesAPI from '../../scripts/candidates';
import JobCardList from '../../components/matching/JobCardList';
import { useContextStore } from '../../store/ContextStore';

const CandidateMatches = () => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const [filter, setFilter] = Utility.useStickyState({ isOpen: false, filters: {} }, 'admin-candidate-matches-filter');
	const [graphMatches, setGraphMatches] = useState([]);

	const { candidateMatches: matches, setCandidateMatches: setMatches, refetchMatches } = useContextStore();

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const updateFilters = (ev, name) => {
		if (typeof ev === 'object' && typeof name === 'string') {
			setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
		} else if (ev.target.value !== null && (ev.target.value.length || typeof ev.target.value === 'number')) {
			setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: ev.target.value } });
		} else {
			setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
		}
	};

	const params = useParams();
	const candidateID = params.id;
	const [candidate, setCandidate] = useState(null);
	const [listings, setListings] = useState([]);
	const [loadingListings, setLoadingListings] = useState(true);

	// Get the selected candidate
	useEffect(() => {
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'));
				return;
			}
			const newCandidate = response.data.data;
			setCandidate(newCandidate);
		});

		// Get mock listings
		// ListingsAPI.getListings({}, response => {
		// 	console.log({ response });
		// 	setListings(response?.data?.data);
		// 	setLoadingListings(false);
		// });

		CandidatesAPI.getCandidateRandomGraphMatches({ candidateId: candidateID }).then(async response => {
			console.log({ response });

			setGraphMatches(response?.data?.data);
			setLoadingListings(false);
		});

		// Get matches for the current candidate.
		refetchMatches({ candidateId: candidateID });
	}, []);

	const filteredGraphMatches = graphMatches;

	if (!candidate) return null;

	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				{candidate && (
					<Card style={{ width: '100%' }}>
						<Typography variant="h3" style={{ marginBottom: 15 }}>
							<NavLink to={`/candidate/${candidate.id}`} className="color-inherit">
								Match Testing: {candidate.first_name} {candidate.last_name}
							</NavLink>
						</Typography>
					</Card>
				)}
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<JobCardList
				refetchMatches={refetchMatches}
				matches={matches}
				candidateId={candidateID}
				loading={loadingListings}
				graphMatches={filteredGraphMatches}
				pageSize={40}
				disableLink
				filterComponent={null}
				hideAdd
				hideItemDetails
				autoSelectAll
				enableSelectAll
				enableRefresh
			/>
		</Page>
	);
};

export default CandidateMatches;

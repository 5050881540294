import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

export const palette = {
	primary: {
		main: '#0076ff',
		light: '#4c9fff',
		dark: '#0762cf',
		contrastText: '#ffffff',
	},
	secondary: {
		main: '#ff7071',
		// light: '#f0e1e2',
		dark: '#c94041',
		contrastText: '#ffffff',
	},
	gray: {
		lightMediumGray: '#EDEFF2',
	},
	error: {
		main: '#ff7071',
		light: '#f0e1e2',
		dark: '',
		contrastText: '',
	},
	warning: {
		main: '#9f713a',
		light: '#efeadd',
		dark: '',
		contrastText: '',
	},
	// info: {

	// },
	success: {
		main: '#2b9991',
		light: '#eaf5f4',
		dark: '',
		contrastText: '',
	},
	muted: {
		main: '#a5aebc',
		contrastText: '#ffffff',
	},
	typography: {
		main: '#465670',
		light: '#ffffff',
		heading: '#212E33',
	},
	form: {
		borderHover: '#17345e80',
	},
};

export const themeOverrides = createTheme({
	typography: {
		allVariants: {
			color: palette.typography.main,
		},
		h1: {
			fontSize: 34,
			color: palette.typography.heading,
		},
		h2: {
			fontSize: 20,
			color: palette.typography.heading,
		},
		h3: {
			fontSize: 17,
			fontWeight: 500,
			color: palette.typography.heading,
		},
		body1: {
			fontSize: 14,
			fontWeight: 400,
		},
		body2: {
			fontSize: 14,
			fontWeight: 400,
		},
		subtitle1: {
			fontSize: 18,
		},
		caption: {
			fontSize: 12,
		},
	},
	overrides: {
		MuiButton: {
			root: {
				borderRadius: '85px !important',
				marginLeft: '1.5rem',
				fontWeight: 'bold',
			},
			contained: {
				boxShadow: `0px 15px 25px #e0e0e026`,
				backgroundColor: palette.muted.main,
				color: palette.typography.light,
			},
			containedPrimary: {
				boxShadow: `0px 15px 25px ${palette.primary.main}26`,
				'&:hover': {
					backgroundColor: '#2975CF',
				},
			},
			containedSecondary: {
				boxShadow: `0px 15px 25px ${palette.secondary.main}26`,
			},
			containedTeal: {
				backgroundColor: '#00d6c7',
				'&:hover': {
					backgroundColor: '#66d8d0',
				},
			},
			outlined: {
				borderWidth: '2px !important',
				borderColor: palette.muted.main,
				color: palette.muted.main,
				// border: `solid 2px ${palette.primary.main} !important`,
			},
			outlinedPrimary: {
				borderColor: palette.primary.main,
			},
			outlinedSecondary: {
				borderColor: palette.secondary.main,
			},
			text: {},
			sizeLarge: {
				paddingTop: '1.5rem',
				paddingBottom: '1.5rem',
				paddingLeft: '6rem',
				paddingRight: '6rem',
			},
			fullWidth: {
				margin: 0,
			},
		},
		MuiIconButton: {
			root: {
				padding: 0,
			},
		},
		MuiFormControl: {
			root: {
				// background: palette.primary.contrastText,
			},
		},
		MuiFormControlLabel: {
			label: {
				fontSize: 16,
			},
		},
		MuiInputBase: {
			root: {
				fontSize: 16,
				background: '#ffffff',
				borderRadius: 4,
				color: palette.typography.main,
			},
			inputMultiline: {
				lineHeight: '24px',
			},
			error: {
				// border: `1px solid ${palette.typography.main}80`,
			},
		},
		MuiOutlinedInput: {
			root: {
				'&$error, .MuiAutocomplete-root.error > .MuiFormControl-root > & > .MuiOutlinedInput-notchedOutline': {
					borderColor: palette.error.main,
				},
			},
			notchedOutline: {
				border: `1px solid ${palette.typography.main}80`,
			},
		},
		MuiSwitch: {
			// track: {
			// 	background: `${palette.muted.main} !important`,
			// 	opacity: 1,
			// }
		},
		MuiAlert: {
			root: {
				fontSize: '16px',
				paddingTop: '15px',
				paddingBottom: '15px',
				borderRadius: '8px',
				'& .MuiAlertTitle-root': {
					fontSize: 18,
					color: 'inherit',
				},
			},
			standardInfo: {
				color: palette.primary.main,
			},
			standardError: {
				color: palette.error.main,
				backgroundColor: palette.error.light,
			},
			standardWarning: {
				color: palette.warning.main,
				backgroundColor: palette.warning.light,
			},
			standardSuccess: {
				color: palette.success.main,
				backgroundColor: palette.success.light,
			},
		},
		MuiSvgIcon: {
			root: {
				verticalAlign: 'middle',
			},
			colorPrimary: {
				color: `${palette.primary.main}80`,
			},
			colorSecondary: {
				color: `${palette.secondary.main}80`,
			},
		},
		MuiChip: {
			root: {
				marginRight: 10,
				marginBottom: 12,
				lineHeight: '32px',
				backgroundColor: palette.gray.lightMediumGray,
			},
			colorPrimary: {
				backgroundColor: `${palette.primary.main}1a`,
				color: palette.primary.main,
			},
			colorSecondary: {
				backgroundColor: `${palette.success.main}1a`,
				color: palette.success.main,
			},
			deleteIconColorPrimary: {
				color: `${palette.primary.main}80`,
			},
			deleteIconColorSecondary: {
				color: `${palette.secondary.main}80`,
			},
		},
		MuiPaper: {
			root: {
				padding: 20,
				marginBottom: 12,
			},
		},
		MuiTableBody: {
			root: {
				backgroundColor: palette.primary.contrastText,
			},
		},
		MuiTableCell: {
			root: {
				fontSize: 13,
				padding: 16,
			},
			head: {
				fontSize: 14,
			},
			body: {
				color: palette.typography.main,
			},
		},
		MuiMenuItem: {
			root: {
				fontSize: 16,
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: 35,
			},
		},
		MuiSlider: {
			root: {
				color: '#faca43',
			},
			rail: {
				height: 4,
				backgroundColor: palette.muted.main,
			},
			track: {
				height: 4,
				// opacity: 0,
			},
			thumb: {
				width: 16,
				height: 16,
				// borderRadius: 8,
				backgroundColor: '#faca43',
				// marginTop: 0,
			},
		},
		MuiStepper: {
			root: {
				padding: 0,
			},
		},
		MuiStepLabel: {
			root: {
				textAlign: 'left',
			},
			iconContainer: {
				paddingRight: 24,
			},
		},
		MuiStepConnector: {
			vertical: {
				padding: 0,
				height: 80,
			},
			line: {
				height: '100%',
			},
		},
		MuiTooltip: {
			tooltip: {
				backgroundColor: 'var(--popoverBackgroundColor)',
			},
		},
		MuiTab: {
			root: {},
			textColorInherit: {
				opacity: 1,
			},
		},
		MuiInputAdornment: {
			positionEnd: {
				marginRight: '1rem',
				color: `${palette.typography.main}80`,
			},
		},
		MuiAccordion: {
			expanded: {},
		},
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
	palette,
});

const BobcatTheme = ({ children }) => {
	return (
		<ThemeProvider theme={themeOverrides}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};

BobcatTheme.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default BobcatTheme;

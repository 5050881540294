import React, { useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import * as Utility from '../../../scripts/utility';
import CandidateDetailsBlock from '../CandidateDetailsBlock';
import CandidateHeaderButtonGroup from '../CandidateHeaderButtonGroup';

const PreferencesTab = ({ currentJob, currentCandidate, data, graphMatch, onUpdate }) => {
	const [isDetailsShown, setIsDetailsShown] = useState(false);
	const { states } = data;

	const mD = graphMatch?.all_match_data?.preference_match_data;

	const preferencesData = {
		title: {
			label: 'Title',
			job: currentJob.position_title,
			candidate: currentCandidate.desired_position,
			score: null,
		},
		level: {
			label: 'Level',
			job: currentJob.role_level_preferences,
			candidate: currentCandidate.role_level_preferences,
			score: mD?.preference_role_level,
		},
		type: {
			label: 'Type',
			job: Utility.getItemLabel(data.employmentPreferences, currentJob.role_type),
			candidate: currentCandidate.employment_preferences,
			score: mD?.preference_employment_type,
		},
		industries: {
			label: 'Industries',
			job: currentJob.industries,
			candidate: currentCandidate.industry_preferences,
			score: mD?.preference_industry,
		},
		businessSize: {
			label: 'Business Size',
			job: currentJob.client_corporation.business_sizes,
			candidate: currentCandidate.business_size_preferences,
			score: mD?.preference_business_size,
		},
		leadershipLevel: {
			label: 'Leadership Level',
			job: Utility.getItemLabel(data.leadershipExperienceRoles, currentJob.recent_leadership_role_id),
			candidate: currentCandidate.leadership_preferences,
			score: mD?.preference_leadership_level,
		},
		leadershipAllocation: {
			label: 'Leadership Allocation',
			job: Utility.getItemLabel(data.leadershipAllocationTypes, currentJob.leadership_allocation_type_id),
			candidate: Utility.getItemLabel(
				data.leadershipAllocationTypes,
				currentCandidate.preferred_leadership_allocation_id,
			),
			score: mD?.preference_leadership_allocation,
		},
		// ---- //
		salary: {
			label: 'Salary',
			job: `${Utility.formatMoney(currentJob.salary_rate_min) || Utility.formatMoney(0)}
                -
                ${Utility.formatMoney(currentJob.salary_rate_max) || Utility.formatMoney(0)}`,
			candidate: Utility.getItemLabel(data.salaryExpectationTypes, currentCandidate.salary_expectation_type_id),
			score: mD?.preference_salary,
		},
		jobPriorities: {
			label: 'Job Priorities',
			job: currentJob.value_proposition_types,
			candidate: currentCandidate.job_priority_types,
			score: mD?.preference_value_proposition,
		},
		travel: {
			label: 'Travel',
			job: Utility.getItemLabel(data.travelWillingnessTypes, currentJob.travel_willingness_type_id),
			candidate: Utility.getItemLabel(data.travelWillingnessTypes, currentCandidate.travel_willingness_type_id),
			score: mD?.preference_travel,
		},
	};

	const locationData = {
		workLocation: {
			label: 'Work Location',
			job: Utility.getConsolidatedLocationPreferences(currentJob?.work_location_preferences),
			candidate: Utility.getConsolidatedLocationPreferences(currentCandidate?.work_location_preferences),
			score: null,
		},
		timezones: {
			label: 'Time Zones',
			job: currentJob.timeszones,
			candidate: currentCandidate.timezones,
			score: null,
		},
		localAddresses: {
			label: 'Local Addresses',
			job: currentJob.office_locations,
			candidate: [currentCandidate.city, currentCandidate.state].filter(Boolean).join(', '),
			score: null,
		},
		willingToRelocate: {
			label: 'Willing to Relocate',
			job: Utility.getItemLabel(data.offerRelocation, currentJob.offer_relocation),
			candidate: Number(currentCandidate.willing_to_relocate)
				? data.willingToRelocate[currentCandidate.willing_to_relocate]
				: null,
		},
		desiredStates: {
			label: 'Desired States',
			// TODO: check this (job field only)
			job: currentJob?.remote_states?.length
				? currentJob.remote_states?.map(item => item.abbreviation).length === states.length
					? 'All States'
					: currentJob.remote_states
				: null,
			candidate: currentCandidate.desired_locations,
			score: null,
		},
	};

	const tableTopRowStyle = column => ({
		display: 'flex',
		minHeight: column !== 'right' ? '100%' : null,
		borderRadius: '3px 3px 0 0',
		paddingInline: column !== 'left' ? 20 : 0,
		borderTop:
			column === 'left'
				? '3px solid var(--matchRowBorderColor)'
				: column === 'center'
				? '3px solid var(--blue-500)'
				: '3px solid var(--teal-100)',
		backgroundColor: 'var(--matchRowLightBackgroundColor)',
		flexDirection: column === 'right' ? 'column' : 'row',
		justifyContent: column === 'right' ? 'space-between' : 'flex-start',
	});

	const tableRowStyle = (index, column) => ({
		display: 'flex',
		minHeight: '110%',
		paddingInline: 20,
		marginTop: -4,
		backgroundColor: index % 2 === 0 ? 'var(--matchRowDarkBackgroundColor)' : 'var(--matchRowLightBackgroundColor)',
		borderTop: '1px solid var(--matchRowBorderColor)',
		borderBottomStyle: index === 5 ? '1px solid var(--matchRowBorderColor)' : 'none',
		justifyContent: column === 'right' ? 'space-between' : 'flex-start',
	});

	return (
		<>
			<Grid container direction="row" spacing={1} alignItems="stretch">
				<Grid item xs={2}>
					<Box p={1} style={tableTopRowStyle('left')} />
				</Grid>
				<Grid item xs={5}>
					<Box p={1} style={tableTopRowStyle('center')}>
						<Typography variant="h3" style={{ marginTop: 6 }}>
							Job Preferences
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={5}>
					<Box p={1} style={tableTopRowStyle('right')}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography variant="h3" style={{ marginTop: 6 }}>
								{currentCandidate.first_name || ''} {currentCandidate.last_name || ''}
							</Typography>
							<CandidateHeaderButtonGroup
								isDetailsShown={isDetailsShown}
								setIsDetailsShown={setIsDetailsShown}
								graphMatch={graphMatch}
								currentCandidate={currentCandidate}
							/>
						</div>
						{isDetailsShown && (
							<CandidateDetailsBlock currentCandidate={currentCandidate} data={data} onUpdate={onUpdate} />
						)}
					</Box>
				</Grid>
			</Grid>
			{Object.entries(preferencesData).map(([key, { label, job, candidate, score }], index) => (
				<Grid
					container
					direction="row"
					spacing={1}
					alignItems="stretch"
					key={label}
					style={{ marginBottom: index === 6 ? 20 : 0 }}
				>
					<Grid item xs={2} style={{ minHeight: '100%' }}>
						<Box p={1} style={tableRowStyle(index, 'left')}>
							<Typography variant="caption" color="textSecondary">
								{label}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box p={1} style={tableRowStyle(index, 'center')}>
							{key === 'industries' ||
							key === 'businessSize' ||
							key === 'studyAreas' ||
							key === 'workAuthorization' ||
							key === 'level' ? (
								<div>
									{job && job.length
										? job.map(item => (
												<Typography variant="body1" key={item.label}>
													{item.label || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : key === 'experience' ? (
								<Typography variant="body1">{job ? `${job} years` : '-'}</Typography>
							) : key === 'jobPriorities' ? (
								<div>
									{/* TODO: check this! might need to be similar to candidate setup */}
									{job && job.length
										? job.map(item => (
												<Typography variant="body1" key={item.label}>
													{item.label || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : (
								<Typography variant="body1">{job || '-'}</Typography>
							)}
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box p={1} style={tableRowStyle(index, 'right')}>
							{key === 'industries' ||
							key === 'businessSize' ||
							key === 'level' ||
							key === 'type' ||
							key === 'leadershipLevel' ? (
								<div>
									{candidate.length
										? candidate.map(item => (
												<Typography variant="body1" key={item.label}>
													{item.label || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : key === 'experience' ? (
								<Typography variant="body1">{candidate ? `${candidate} years` : '-'}</Typography>
							) : key === 'jobPriorities' ? (
								<div>
									{candidate.length
										? candidate.map(item => (
												<Typography variant="body1" key={item.id}>
													{Utility.getItemLabel(data.valuePropositionTypes, item.job_priority_type_id) || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : (
								<Typography variant="body1">{candidate || '-'}</Typography>
							)}
							{key === 'title' ? null : (
								<Typography variant="body1" style={{ color: Utility.getScoreColor(score) }}>
									{score != null && score >= 0 ? `${Math.round(score * 100)}%` : '-'}
								</Typography>
							)}
						</Box>
					</Grid>
				</Grid>
			))}
			{mD?.location_preference_score != null && (
				<div style={{ display: 'flex', marginBlock: 15 }}>
					<Typography variant="h3">Location Preference Score:</Typography>
					<Typography
						variant="h3"
						style={{ marginLeft: 5, color: Utility.getScoreColor(mD?.location_preference_score) }}
					>
						{Math.round(mD?.location_preference_score * 100)}%
					</Typography>
				</div>
			)}
			{Object.entries(locationData).map(([key, { label, job, candidate }], index) => (
				<Grid
					container
					direction="row"
					spacing={1}
					alignItems="stretch"
					key={label}
					style={{ marginBottom: index === 5 ? 20 : 0 }}
				>
					<Grid item xs={2} style={{ minHeight: '100%' }}>
						<Box p={1} style={tableRowStyle(index, 'left')}>
							<Typography variant="caption" color="textSecondary">
								{label}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box p={1} style={tableRowStyle(index, 'center')}>
							{key === 'timezones' ? (
								<div>
									{job && job.length
										? job.map(item => (
												<Typography variant="body1" key={item.label}>
													{item.label || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : key === 'desiredStates' ? (
								<div>
									{typeof job === 'string' ? (
										<Typography variant="body1">{job || '-'}</Typography>
									) : job && job.length ? (
										job.map(item => (
											<Typography variant="body1" key={item.id}>
												{item.name || '-'}
											</Typography>
										))
									) : (
										'-'
									)}
								</div>
							) : key === 'localAddresses' ? (
								<div>
									{job && job.length
										? job.map(item => (
												<Typography variant="body1" key={item.id}>
													{item.city}, {item.state?.name}
												</Typography>
										  ))
										: '-'}
								</div>
							) : key === 'workLocation' ? (
								<div>
									{job && job.length
										? job.map(item => (
												<Typography variant="body1" key={item}>
													{item || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : (
								<Typography variant="body1">{job || '-'}</Typography>
							)}
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box p={1} style={tableRowStyle(index, 'right')}>
							{key === 'timezones' ? (
								<div>
									{candidate && candidate.length
										? candidate.map(item => (
												<Typography variant="body1" key={item.label}>
													{item.label || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : key === 'desiredStates' ? (
								<div>
									{candidate && candidate.length
										? candidate.map(item => (
												<Typography variant="body1" key={item.id}>
													{item.name || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : key === 'workLocation' ? (
								<div>
									{candidate && candidate.length
										? candidate.map(item => (
												<Typography variant="body1" key={item}>
													{item || '-'}
												</Typography>
										  ))
										: '-'}
								</div>
							) : (
								<Typography variant="body1">{candidate || '-'}</Typography>
							)}
						</Box>
					</Grid>
				</Grid>
			))}
		</>
	);
};

export default PreferencesTab;

import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from './LoadingButton';

const SubmitButton = ({ onClick, loading, label, children, className, ...props }) => {
	return (
		<LoadingButton
			type="submit"
			loading={loading}
			variant="contained"
			color="primary"
			onClick={e => onClick(e)}
			className={className}
			{...props}
		>
			{children || label}
		</LoadingButton>
	);
};

SubmitButton.propTypes = {
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	label: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
};

SubmitButton.defaultProps = {
	onClick: () => null,
	loading: false,
	label: 'Submit',
	children: null,
	className: null,
};

export default SubmitButton;

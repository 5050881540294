import React, { useEffect, useState } from 'react';
import { Typography, Button, Tabs, Tab } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FolderOpen, KeyboardArrowDown, KeyboardArrowRight, Fullscreen, GetApp } from '@material-ui/icons';
import DocumentIcon from '../../../components/icons/SectionIcons/DocumentIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import FormattedText from '../../../components/form/FormattedText';
import * as ListingsAPI from '../../../scripts/listings';
import PlusIcon from '../../../components/icons/BasicPlusIcon';
import UploadDocumentModal from '../../candidates/CandidateBlocks/UploadDocumentModal';
import ViewDocumentModal, { DocumentEmbed } from '../../candidates/CandidateBlocks/ViewDocumentModal';

const embedHeight = 820;

const Block = ({ job, refreshJob, displayDocumentManagement, data, minimized, setMinimized }) => {
	const [documents, setDocuments] = useState([]);
	const [descriptions, setDescriptions] = useState([]);
	const [currentDocumentTab, setCurrentDocumentTab] = useState(
		job?.public_description ? 'public-description' : 'description-embed',
	);

	const [descriptionShareLink, setDescriptionShareLink] = useState(null);

	const onDocumentUpload = () => {
		// When a new document is uploaded, refresh the list of documents.
		setCurrentDocumentTab('description-embed');
	};

	const openDescription = (ev, desc) => {
		ev.preventDefault();
		ListingsAPI.downloadListingDescription(job?.id, desc.document_id, desc.file_name);
	};

	const description = descriptions?.[0];

	const refreshDocuments = () => {
		ListingsAPI.getListingDescriptions(
			job?.id,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) return;
				setDescriptions(response.data.data);
			},
		);
		ListingsAPI.getListingDocuments({ listingId: job?.id }).then(results => {
			setDocuments(results?.data?.data);
		});
	};
	useEffect(() => {
		refreshDocuments();
	}, []);

	useEffect(() => {
		// Create a share link for displaying the embedded description.
		if (description) {
			ListingsAPI.createListingDescriptionShareLink({
				listingId: job?.id,
				documentId: description?.document_id,
			}).then(shareLink => {
				setDescriptionShareLink(shareLink?.data?.url);
			});
		}
	}, [description?.created_at]);

	const publicDescription = job?.public_description
		?.replace(new RegExp(`white-space:pre`, 'g'), `white-space:pre-wrap`)
		.replace(new RegExp(`font-size:10pt`, 'g'), `font-size:14px`)
		.replace(new RegExp(`color:#000000`, 'g'), `color:#465670`)
		.replace(new RegExp(`font-family:Arial;`, 'g'), `font-family:"Roboto", "Helvetica", "Arial", sans-serif`);
	// .replace(new RegExp(`<br />`, 'g'), ``);

	return (
		<>
			<ViewBlockWrapper
				icon={DocumentIcon}
				iconColor="var(--blue-500)"
				title="Description"
				minimized={minimized}
				setMinimized={setMinimized}
				// onEditClick={() => {
				// 	dispatch(
				// 		SiteActions.showModal(<EditDetailsModal job={job} setJob={setJob} />, {
				// 			className: 'modal-medium modal-no-padding modal-no-shadow',
				// 		}),
				// 	);
				// }}
			>
				<>
					{displayDocumentManagement && (
						<Tabs
							value={currentDocumentTab}
							onChange={(e, newValue) => {
								setCurrentDocumentTab(newValue);
							}}
							className="mini"
						>
							<Tab label="Public Description" value="public-description" disabled={!description} />

							<Tab label="Client Description" value="description-embed" disabled={!description} />

							<Tab label="Client Description - Text" value="description-text" disabled={!description} />
						</Tabs>
					)}
					<div style={{ height: 15 }} />
					{currentDocumentTab === 'description-embed' ? (
						<DocumentEmbed
							shareLink={descriptionShareLink}
							height={embedHeight}
							textFallback={publicDescription}
							fileName={description?.file_name}
						/>
					) : currentDocumentTab === 'description-text' ? (
						<FormattedText height={20} style={{ maxHeight: 'none', whiteSpace: 'pre-wrap' }}>
							{/* <Typography variant="body2">{job.description || Utility.nb}</Typography> */}
							{job?.description}
						</FormattedText>
					) : currentDocumentTab === 'public-description' ? (
						<FormattedText height={20} style={{ whiteSpace: 'normal', maxHeight: 'none' }}>
							{/* <Typography variant="body2">{job.description || Utility.nb}</Typography> */}
							{/* eslint-disable-next-line react/no-danger */}
							<div className="public_description" dangerouslySetInnerHTML={{ __html: publicDescription }} />
						</FormattedText>
					) : null}
					{/* {descriptions?.map(desc => (
						<Grid container spacing={2} key={`resume-${desc.document_id}`}>
							<Grid item xs={6}>
								<Typography className="grey-250">File Name</Typography>
								<a
									href="#0"
									style={{
										fontWeight: 300,
										textDecoration: 'underline',
									}}
									onClick={ev => openDescription(ev, desc)}
								>
									{desc.file_name}
								</a>
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Date Provided</Typography>
								<Typography>
									<i>{Utility.formatDate(desc.updated_at, false, 'MMMM D, YYYY')}</i>
								</Typography>
							</Grid>
						</Grid>
					))} */}
					{/* {descriptions?.length > 0 && <hr />}
					{descriptions?.map(desc => (
						<Grid container spacing={2} key={`resume-${desc.document_id}`}>
							<Grid item xs={6}>
								<Typography className="grey-250">File Name</Typography>
								<a
									href="#0"
									style={{
										fontWeight: 300,
										textDecoration: 'underline',
									}}
									onClick={ev => openDescription(ev, desc)}
								>
									{desc.file_name}
								</a>
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Date Provided</Typography>
								<Typography>
									<i>{Utility.formatDate(desc.updated_at, false, 'MMMM D, YYYY')}</i>
								</Typography>
							</Grid>
						</Grid>
					))} */}
				</>
				{displayDocumentManagement && (
					<JobDocumentManager
						listingId={job?.id}
						data={data}
						documents={documents}
						descriptions={descriptions}
						setDocuments={setDocuments}
						refreshJob={refreshJob}
						openDescription={openDescription}
						refreshDocuments={refreshDocuments}
						onUpload={onDocumentUpload}
					/>
				)}
			</ViewBlockWrapper>
		</>
	);
};

const JobDocumentManager = ({
	listingId,
	openDescription,
	refreshJob,
	refreshDocuments,
	documents,
	descriptions,
	setDocuments,
	onUpload,
}) => {
	const dispatch = useDispatch();
	const [minimized, setMinimized] = useState(true);

	return (
		<div style={{ paddingTop: 20 }}>
			<Button
				onClick={() => {
					setMinimized(!minimized);
				}}
				variant="text"
				style={{ width: 'calc(100% + 40px)', marginBottom: 0, marginLeft: -20, marginRight: -20 }}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						paddingTop: 15,
						paddingBottom: 15,
						cursor: 'pointer',
						userSelect: 'none',
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FolderOpen size={20} style={{ color: 'var(--teal-100)' }} />
						<div style={{ width: 10 }} />
						<Typography variant="h3">File Cabinet</Typography>
					</div>
					{!minimized ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
				</div>
			</Button>
			{!minimized && (
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							marginBottom: -10,
							marginTop: 15,
						}}
					>
						<Button
							onClick={async e => {
								dispatch(
									SiteActions.showModal(
										<UploadDocumentModal
											context="job"
											onUpload={async (file, type, isPlainText) => {
												if (file && type !== undefined) {
													// if (type === 1) {
													// 	// It's a profile type, upload as a document
													// 	const result = await ListingsAPI.postDocument({
													// 		listingId,
													// 		file,
													// 		type,
													// 	});
													// 	dispatch(SiteActions.hideModal());
													// 	refreshDocuments();

													// 	console.log({ result });
													// }
													if (type === 3) {
														if (isPlainText) {
															await new Promise((resolve, reject) => {
																ListingsAPI.uploadListingTextJobOrder(listingId, file, (result, err) => {
																	dispatch(SiteActions.hideModal());
																	refreshDocuments();
																	refreshJob();
																	resolve();
																});
															});
														} else {
															await new Promise((resolve, reject) => {
																ListingsAPI.uploadListingJobOrder(listingId, file, (result, err) => {
																	dispatch(SiteActions.hideModal());
																	refreshDocuments();
																	refreshJob();
																	resolve();
																});
															});
															onUpload();
														}
													}
												}
											}}
										/>,
										{
											className: 'modal-small',
										},
									),
								);
							}}
							variant="contained"
							color="primary"
							size="small"
							style={{ whiteSpace: 'nowrap' }}
						>
							<PlusIcon />
							<div style={{ width: 10 }} />
							Upload Document
						</Button>
					</div>
					<div style={{ height: 20 }} />
					<table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '5px 15px' }}>
						<thead>
							<tr style={{ textAlign: 'left' }}>
								<th>File Name</th>
								<th>Type</th>
								<th>Updated</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{[...(descriptions || [])]
								?.sort((a, b) => {
									return +new Date(b.updated_at) - +new Date(a.updated_at);
								})
								?.slice(0, 1)
								.map(description => (
									<tr key={description.document_id} style={{ marginBottom: 10, marginTop: 10 }}>
										<td>
											<span style={{ marginRight: 10 }}>{description.file_name}</span>
										</td>
										<td>Description</td>
										<td>
											<p style={{ margin: 0 }}>{moment(description.updated_at).format('MM/DD/YY')}</p>
											<p style={{ margin: 0 }}>{moment(description.updated_at).format('h:mm a')}</p>
										</td>
										<td>
											<Button
												key={description.document_id}
												onClick={async e => {
													const shareLink = await ListingsAPI.createListingDescriptionShareLink({
														listingId,
														documentId: description.document_id,
													});
													// setShareLink(shareLink?.data?.url);
													dispatch(
														SiteActions.showModal(
															<ViewDocumentModal document={description} shareLink={shareLink?.data?.url} />,
															{
																className: 'modal-medium document-modal',
															},
														),
													);
													console.log({ shareLink });
												}}
												variant="outlined"
												color="primary"
												size="small"
												style={{ whiteSpace: 'nowrap', padding: 6, minWidth: 0 }}
											>
												<Fullscreen color="primary" />
												{/* View */}
											</Button>
											<Button
												key={description.id}
												onClick={async e => {
													// const shareLink = await CandidatesAPI.createCandidateDocumentShareLink({
													// 	candidateId,
													// 	documentId: resume.id,
													// });
													// setShareLink(shareLink?.data?.url);
													// dispatch(
													// 	SiteActions.showModal(<ViewDocumentModal document={resume}
													// shareLink={shareLink?.data?.url} />, {
													// 		className: 'modal-medium document-modal',
													// 	}),
													// );

													e.preventDefault();
													openDescription(e, description);
													// CandidatesAPI.downloadCandidateResume(candidateId, resume.document_id, resume.file_name);
													// console.log({ shareLink });
												}}
												variant="outlined"
												color="primary"
												size="small"
												style={{ whiteSpace: 'nowrap', padding: 6, minWidth: 0 }}
											>
												<GetApp color="primary" />
												{/* Download */}
											</Button>
										</td>
									</tr>
								))}
							{[...(documents || [])]
								?.filter(x => x.file_type === 'Description')
								?.sort((a, b) => {
									return +new Date(b.updated_at) - +new Date(a.updated_at);
								})
								?.slice(0, 1)
								.map(doc => (
									<tr key={doc?.id} style={{ marginBottom: 10, marginTop: 10 }}>
										<td>
											<span style={{ marginRight: 10 }}>{doc.file_name}</span>
										</td>
										<td>{doc.file_type}</td>
										<td>
											<p style={{ margin: 0 }}>{moment(doc.updated_at).format('MM/DD/YY')}</p>
											<p style={{ margin: 0 }}>{moment(doc.updated_at).format('h:mm a')}</p>
										</td>
										<td>
											<Button
												key={doc.id}
												onClick={async e => {
													// const shareLink = await CandidatesAPI.createCandidateDocumentShareLink({
													// 	candidateId,
													// 	documentId: doc.id,
													// });
													// setShareLink(shareLink?.data?.url);
													// dispatch(
													// 	SiteActions.showModal(
													// <ViewDocumentModal document={doc} shareLink={shareLink?.data?.url} />, {
													// 		className: 'modal-medium document-modal',
													// 	}),
													// );
													// console.log({ shareLink });
												}}
												variant="outlined"
												color="primary"
												size="small"
												style={{ whiteSpace: 'nowrap' }}
											>
												View
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</>
			)}
		</div>
	);
};

export default Block;

import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const EducationTypesDropdown = ({ educationType, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const getEducationTypes = () => {
		let educationTypes = data.educationTypes || [];

		educationTypes = educationTypes.sort((a, b) => {
			if (a.name === 'high-school') return -1;
			if (b.name === 'high-school') return 1;
			return 0;
		});

		return educationTypes;
	};

	return <Dropdown data={getEducationTypes()} {...props} />;
};

EducationTypesDropdown.propTypes = {
	educationType: PropTypes.arrayOf({}),
};

EducationTypesDropdown.defaultProps = {
	educationType: [],
};

export default EducationTypesDropdown;

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { useStore } from 'react-redux';
import { Button, Typography, TextField, CircularProgress, Grid } from '@material-ui/core';
import Alert from '../Alert';
import CheckmarkCircleIcon from '../icons/CheckmarkCircleIcon';
import OpenEnvelopeIcon from '../icons/OpenEnvelopeIcon';
import LabeledField from '../form/LabeledField';
import SubmitButton from '../form/SubmitButton';
import VerticalSpacer from '../VerticalSpacer';
import {
	validatePasswords,
	PasswordRequirementsValidation,
	PasswordMatchingValidation,
} from '../form/PasswordValidation';
import { ActionCreators as SiteActions } from '../../store/Site';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ContactsAPI from '../../scripts/contacts';
import * as UsersAPI from '../../scripts/user';

const ChangeLoginModal = ({ onEmailSaved }) => {
	const store = useStore();

	const candidateProfileRouteMatch = useRouteMatch('/candidate/:id/profile');
	const contactProfileRouteMatch = useRouteMatch('/contact/:id/profile');

	const [isLoading, setIsLoading] = useState(false);
	const [profile, setProfile] = useState({
		email: '',
		email_confirmation: '',
		password: '',
		new_password: '',
		new_password_confirmation: '',
	});
	const [verificationToken, setVerificationToken] = useState('');
	const [showEmailForm, setShowEmailForm] = useState(false);
	const [showPasswordForm, setShowPasswordForm] = useState(false);
	const [showEmailSuccess, setShowEmailSuccess] = useState(false);
	const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);

	const setShowForm = type => {
		if (type === 'email') {
			setShowPasswordForm(false);
			setShowEmailForm(true);
		} else if (type === 'password') {
			setShowEmailForm(false);
			setShowPasswordForm(true);
		} else {
			setShowPasswordForm(false);
			setShowEmailForm(false);
		}
	};

	const updateProfile = ev => {
		setProfile({ ...profile, [ev.target.name]: ev.target.value });
	};

	const saveIsDisabled =
		!showEmailForm && !showPasswordForm
			? true
			: showEmailForm
			? !profile.email || !profile.email_confirmation || profile.email !== profile.email_confirmation
			: showPasswordForm
			? !profile.password && !validatePasswords(profile.password, profile.passwordConfirmation)?.isValid
			: false;

	const handleSubmit = () => {
		if (showEmailForm) {
			if (!profile.email || !profile.email_confirmation || profile.email !== profile.email_confirmation) return;
			UsersAPI.changeLogin(
				{
					email: profile.email,
					email_confirmation: profile.email_confirmation,
				},
				(response, err) => {
					if (!response) {
						store.dispatch(
							SiteActions.showAlert('An error ocurred saving your information. Please refresh the page.', 'error'),
						);
						setIsLoading(false);
						return;
					}
					if (showEmailForm) {
						setShowEmailSuccess(true);
					} else if (showPasswordForm) {
						setShowPasswordSuccess(true);
					}
				},
			);
		} else if (showPasswordForm) {
			if (
				!profile.password ||
				!profile.new_password ||
				!profile.new_password_confirmation ||
				profile.new_password !== profile.new_password_confirmation
			) {
				return;
			}
			UsersAPI.changePassword(
				{
					current_password: profile.password,
					password: profile.new_password,
					password_confirmation: profile.new_password_confirmation,
				},
				(response, err) => {
					if (!response) {
						store.dispatch(
							SiteActions.showAlert('An error ocurred saving your information. Please refresh the page.', 'error'),
						);
						setIsLoading(false);
						return;
					}
					if (showEmailForm) {
						setShowEmailSuccess(true);
					} else if (showPasswordForm) {
						setShowPasswordSuccess(true);
					}
					if (typeof onEmailSaved === 'function') {
						onEmailSaved(profile.email);
					}
				},
			);
		}
	};

	const handleVerifyTokenSubmit = () => {
		UsersAPI.verifyEmailToken(
			{
				token: verificationToken,
			},
			(response, err) => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('An error ocurred submitting your token. Please refresh the page.', 'error'),
					);
					setIsLoading(false);
					return;
				}
				store.dispatch(SiteActions.hideModal());
			},
		);
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		if (candidateProfileRouteMatch) {
			CandidatesAPI.getCandidate(candidateProfileRouteMatch.params.id, response => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
					);
					setIsLoading(false);
					return;
				}
				setProfile({
					email: response.data.data.email,
				});
				setIsLoading(false);
			});
		} else if (contactProfileRouteMatch) {
			ContactsAPI.getContact(contactProfileRouteMatch.params.id, response => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('An error ocurred when loading the contact. Please try again.', 'error'),
					);
					setIsLoading(false);
					return;
				}
				setProfile({
					email: response.data.data.email,
				});
				setIsLoading(false);
			});
		}
	}, []);

	if (isLoading) {
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	}

	if (showEmailSuccess) {
		return (
			<div className="text-center">
				<OpenEnvelopeIcon size={48} className="teal-100" />
				<Typography variant="h2" className="font-lato-bold" style={{ paddingTop: 10, paddingBottom: 5 }}>
					Check Your Email
				</Typography>
				<Typography variant="body1" className="grey-300" style={{ paddingBottom: 20 }}>
					A confirmation code was emailed to you. To accept and finalize the email
					change, please retrieve and enter the code below.
				</Typography>
				<LabeledField label="Verification Code">
					<TextField
						variant="outlined"
						name="verificationToken"
						value={verificationToken}
						onChange={e => setVerificationToken(e.target.value)}
						placeholder="Verification Code"
						fullWidth
					/>
				</LabeledField>
				<SubmitButton
					onClick={() => handleVerifyTokenSubmit()}
					loading={isLoading}
					label="Submit"
					style={{ width: '100%', marginLeft: 0, height: 44 }}
					disabled={!verificationToken}
				/>
			</div>
		);
	}

	if (showPasswordSuccess) {
		return (
			<div className="text-center">
				<CheckmarkCircleIcon size={48} className="teal-100" />
				<Typography variant="h2" className="font-lato-bold" style={{ paddingTop: 10, paddingBottom: 5 }}>
					Your Password Has Been Changed
				</Typography>
				<VerticalSpacer height={1} />
				<Button
					variant="contained"
					color="primary"
					onClick={() => store.dispatch(SiteActions.hideModal())}
					style={{ marginLeft: 0 }}
				>
					Close
				</Button>
			</div>
		);
	}

	return (
		<div>
			<Typography variant="h2" className="font-lato-bold" style={{ paddingBottom: 5 }}>
				Change Login
			</Typography>
			<VerticalSpacer height={3} />
			{showEmailForm ? (
				<>
					<LabeledField label="Email Address" removeMargin>
						<TextField
							variant="outlined"
							name="email"
							value={profile.email}
							onChange={updateProfile}
							placeholder="Email"
							fullWidth
							style={{ marginBottom: 20 }}
							error={!profile.email}
						/>
					</LabeledField>
					<LabeledField label="Confirm Email Address" removeMargin>
						<TextField
							variant="outlined"
							name="email_confirmation"
							value={profile.email_confirmation}
							onChange={updateProfile}
							placeholder="Email"
							fullWidth
							style={{ marginBottom: 20 }}
							error={profile.email !== profile.email_confirmation}
						/>
					</LabeledField>
				</>
			) : showPasswordForm ? (
				<>
					<LabeledField label="Current Password">
						<TextField
							type="password"
							variant="outlined"
							name="password"
							value={profile.password}
							onChange={updateProfile}
							fullWidth
							error={!profile.password}
						/>
					</LabeledField>
					<LabeledField label="New Password" removeMargin>
						<TextField
							type="password"
							variant="outlined"
							name="new_password"
							value={profile.new_password}
							onChange={updateProfile}
							error={!profile.new_password}
							fullWidth
							style={{ marginBottom: 10 }}
						/>
					</LabeledField>
					<PasswordRequirementsValidation password={profile.new_password} />
					<VerticalSpacer height={1} />
					<LabeledField label="Confirm New Password" removeMargin>
						<TextField
							type="password"
							variant="outlined"
							name="new_password_confirmation"
							value={profile.password_confirmation}
							onChange={updateProfile}
							error={!profile.new_password_confirmation || profile.new_password !== profile.new_password_confirmation}
							fullWidth
							style={{ marginBottom: 10 }}
						/>
					</LabeledField>
					<PasswordMatchingValidation
						password={profile.new_password}
						passwordConfirmation={profile.new_password_confirmation}
					/>
				</>
			) : (
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<LabeledField label="Email Address" removeMargin>
							{profile.email}
						</LabeledField>
					</Grid>
					<Grid item xs={6} className="text-right">
						<VerticalSpacer height={2} />
						<Button variant="outlined" color="primary" size="small" onClick={() => setShowForm('email')}>
							Edit
						</Button>
						<VerticalSpacer height={1} />
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Password">
							&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
						</LabeledField>
					</Grid>
					<Grid item xs={6} className="text-right">
						<VerticalSpacer height={1.5} />
						<Button variant="outlined" color="primary" size="small" onClick={() => setShowForm('password')}>
							Edit
						</Button>
					</Grid>
				</Grid>
			)}
			<VerticalSpacer height={3} />
			<Alert />
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => store.dispatch(SiteActions.hideModal())}
						style={{ width: '100%', marginLeft: 0 }}
					>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<SubmitButton
						onClick={() => handleSubmit()}
						loading={isLoading}
						label="Save"
						style={{ width: '100%', marginLeft: 0, height: 44 }}
						disabled={saveIsDisabled}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

ChangeLoginModal.propTypes = {
	onEmailSaved: PropTypes.func,
};

ChangeLoginModal.defaultProps = {
	onEmailSaved: null,
};

export default ChangeLoginModal;

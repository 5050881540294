import React, { useState, useCallback, useEffect } from 'react';
import { useStore } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { Typography, TextField, Grid } from '@material-ui/core';
import useLogin from '../../hooks/useLogin';
import Page from '../../components/page/Page';
import Alert from '../../components/Alert';
import LabeledField from '../../components/form/LabeledField';
import VerticalSpacer from '../../components/VerticalSpacer';
import SubmitButton from '../../components/form/SubmitButton';
import LoginImage from '../../img/culture.png';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as UserAPI from '../../scripts/user';
import '../../css/login.css';
import { palette } from '../../BobcatTheme';
import * as AuthenticationAPI from '../../scripts/authentication';
import CheckmarkCircleIcon from '../../components/icons/CheckmarkCircleIcon';

const ResetPassword = props => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [loading, setLoading] = useState('');
	const [urlError, setUrlError] = useState('');
	const [error, setError] = useState(null);
	const [token, setToken] = useState(null);
	const [reset, setReset] = useState(null);

	useEffect(() => {
		const { location } = props;
		const newToken = new URLSearchParams(location?.search).get('token');
		const newEmail = new URLSearchParams(location?.search).get('email');
		if (!newToken || !newEmail) {
			setUrlError('Your password reset token is no longer valid. Try again.');
		} else {
			setEmail(newEmail);
			setToken(newToken);
		}
	}, []);

	const submitReset = useCallback(
		e => {
			e.preventDefault();

			if (!loading && !reset) {
				setLoading(true);

				setError(null);

				if (!password?.length >= 8) {
					setError('Password must be at least 8 characters.');
					setLoading(false);
					return;
				}
				if (password !== passwordConfirmation) {
					setError('Your passwords do not match. Please try again.');
					setLoading(false);
					return;
				}

				AuthenticationAPI.resetPassword({
					email,
					token,
					password,
					passwordConfirmation,
				})
					.then(x => {
						console.log({ results: x });
						setReset(true);
					})
					.catch(err => {
						console.log(err);
						setError('Error resetting password. Try again.');
					})
					.finally(() => {
						setLoading(false);
					});
			}
		},
		[email, token, password, passwordConfirmation],
	);

	return (
		<Page className="login-page login-page-external" topbar="center" sidebar="none" skipAuth skipData>
			<Grid container spacing={0}>
				<Grid item xs={5}>
					{urlError ? (
						<Typography variant="body1" className="font-lato-bold">
							{urlError}
						</Typography>
					) : !email || !token ? (
						<Typography variant="body1" className="font-lato-bold">
							Loading...
						</Typography>
					) : reset ? (
						<div
							style={{
								textAlign: 'center',
								padding: 45,
								backgroundColor: '#fff',
								borderRadius: 6,
							}}
						>
							<CheckmarkCircleIcon size={48} className="teal-100" />
							<VerticalSpacer height={1} />

							<Typography variant="body2">Your password has been changed.</Typography>
							<div style={{ height: 50 }} />
							<NavLink to="/login" className="font-lato-bold">
								Return to Login page ›
							</NavLink>
						</div>
					) : (
						<form onSubmit={e => submitReset(e)}>
							<Typography variant="h2" className="font-lato-bold">
								Reset Password
							</Typography>
							<VerticalSpacer height={1} />
							<Typography variant="body1">Enter a new password for {email}.</Typography>
							<VerticalSpacer height={3} />
							<Alert />
							<LabeledField label="New Password">
								<TextField
									type="password"
									fullWidth
									variant="outlined"
									value={password}
									placeholder="Your new password"
									onChange={ev => setPassword(ev.target.value)}
								/>
							</LabeledField>
							<LabeledField label="Confirm Password">
								<TextField
									type="password"
									fullWidth
									variant="outlined"
									value={passwordConfirmation}
									placeholder="Confirm password"
									onChange={ev => setPasswordConfirmation(ev.target.value)}
								/>
							</LabeledField>

							<VerticalSpacer height={1} />
							<SubmitButton
								loading={loading}
								onClick={e => submitReset(e)}
								disabled={loading || !(password?.length >= 8) || password !== passwordConfirmation}
							>
								Set New Password
							</SubmitButton>

							{error && (
								<>
									<VerticalSpacer height={1} />

									<Typography variant="body1">{error}</Typography>
								</>
							)}
						</form>
					)}
				</Grid>
				<Grid item xs={7}>
					<img src={LoginImage} alt="" style={{ marginLeft: '3rem', maxWidth: '100%' }} />
				</Grid>
			</Grid>
		</Page>
	);
};

export default ResetPassword;

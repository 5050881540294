import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import ViewBlockWrapper from './ViewBlockWrapper';
import SlidersIcon from '../../../components/icons/SectionIcons/SlidersIcon';
import TrophyIcon from '../../../components/icons/SectionIcons/TrophyIcon';
import SkillsIcon from '../../../components/icons/SkillsIcon';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditSkillsModal from './EditSkillsModal';
import XCircleIcon from '../../../components/icons/MatchingXCircle';
import StarIcon from '../../../components/icons/StarIcon';

export const SkillChip = ({
	skill,
	isEmpty,
	baseColor,
	isLightMode,
	isDarkMode,
	validationError,
	activeSkillId,
	setActiveSkillId,
	style,
	colorOverride,
	classOverride,
}) => {
	const chipStyle =
		classOverride ||
		(isLightMode
			? 'light'
			: isEmpty
			? 'white'
			: skill.enjoy_the_job
			? 'enjoy_the_job'
			: skill.improve_on_job
			? 'improve_on_job'
			: skill.learn_on_job
			? 'learn_on_job'
			: skill.required_skill
			? 'required_skill'
			: skill.preferred_skill
			? 'preferred_skill'
			: skill.bonus_skill
			? 'bonus_skill'
			: 'unknown');

	const chipTextColors = {
		enjoy_the_job: '#E9FCFB',
		improve_on_job: '#105B56',
		learn_on_job: '#20867F',

		required_skill: '#EEF6FF',
		preferred_skill: '#135C94',
		bonus_skill: '#135C94',

		unknown: baseColor === 'blue' ? '#135C94' : '#105B56',
		white: baseColor === 'blue' ? '#135C94' : '#105B56',
		dark: '#EEF6FF',
	};

	const hasIcon = !!skill?.get_the_job || (!!skill?.hero_skill && !validationError);

	const experienceLabel =
		(skill?.hero_skill || skill?.get_the_job) && // Only display years if it's a hero skill or required skill
		skill?.experience_years ? (
			<span className="experience-label">
				{skill?.experience_years} year{skill?.experience_years > 1 ? 's' : ''}
			</span>
		) : null;

	return (
		<div
			className={`skill-chip base-${baseColor} ${chipStyle} ${hasIcon ? 'with-icon' : 'no-icon'} ${
				validationError ? 'validation-error' : ''
			}`}
			style={{
				opacity: activeSkillId && activeSkillId !== skill?.skill_id ? 0.3 : 1,
				transition: 'opacity .4s ease',
			}}
			onMouseEnter={() => {
				if (setActiveSkillId) {
					setActiveSkillId(skill?.skill_id);
				}
			}}
			onMouseLeave={() => {
				if (setActiveSkillId && activeSkillId === skill.skill_id) {
					setActiveSkillId(null);
				}
			}}
		>
			<Chip
				size="small"
				icon={
					hasIcon ? (
						<>
							<div style={{ display: 'inline-block', width: 10 }} />
							<StarIcon color={colorOverride || chipTextColors[chipStyle]} size={15} />
						</>
					) : null
				}
				key={skill.id}
				label={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{validationError ? (
							<div style={{ flexShrink: 0, width: 20, height: 12, display: 'flex', alignItems: 'center' }}>
								{' '}
								<XCircleIcon color="var(--red-100)" />
								<div style={{ width: 10 }} />
							</div>
						) : null}
						{Utility.getFromObj(skill, 'skill', 'label')}
						{experienceLabel}
					</div>
				}
				color={colorOverride || 'primary'}
				style={{
					// ...(colorOverride ? { color: colorOverride } : {}),
					...(style || {}),
				}}
			/>
		</div>
	);
};

const Block = ({
	candidate,
	setCandidate,
	minimized,
	setMinimized,
	data,
	activeSkillId,
	setActiveSkillId,
	groupByRanking,
	disableEdit,
	graphMatch,
	displaySkillsWithoutExperience,
}) => {
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={SkillsIcon}
				title="Skills"
				secondaryTitle={
					graphMatch?.total_skill_score
						? `Score: ${Math.round(graphMatch?.total_skill_score * 100)}% (${Math.round(
								graphMatch?.completeness_score_skills * 100,
						  )}%)`
						: null
				}
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditSkillsModal candidate={candidate} setCandidate={setCandidate} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				{groupByRanking ? (
					<div style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<SkillChip isEmpty skill={{ skill: { label: 'Land The Job' }, get_the_job: true }} />

						<div
							style={{
								width: 102,
								height: 10,
								borderRadius: 38,
								background: 'linear-gradient(-90deg, #F1F1F1 0%, #E9FCFB 32.60%, #73E9E1 66.15%, #20867F 100%)',
								boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
								marginBottom: 12,
								marginLeft: -10,
							}}
						/>

						{/* <SkillChip baseColor="blue" skill={{ skill: { label: 'Best' }, enjoy_the_job: true }} />
					<SkillChip baseColor="blue" skill={{ skill: { label: 'Advanced' }, improve_on_job: true }} />
					<SkillChip baseColor="blue" skill={{ skill: { label: 'Intermediate' }, learn_on_job: true }} />
					<SkillChip baseColor="blue" skill={{ skill: { label: 'Beginner & Uncategorized' }, learn_on_job: false }} /> 
					*/}
						<SkillChip isEmpty baseColor="green" skill={{ skill: { label: 'Beginner' }, learn_on_job: false }} />
					</div>
				) : (
					<div style={{ textAlign: 'right' }}>
						<SkillChip isEmpty skill={{ skill: { label: 'Land The Job' }, get_the_job: true }} />
						<SkillChip skill={{ skill: { label: 'Enjoy Using' }, enjoy_the_job: true }} />
						<SkillChip skill={{ skill: { label: 'Improve' }, improve_on_job: true }} />
						<SkillChip skill={{ skill: { label: 'Learn' }, learn_on_job: true }} />
					</div>
				)}

				{groupByRanking
					? [
							// {
							// 	label: 'Critical',
							// 	filter: skill => skill.hero_skill,
							// },
							{
								label: 'Land The Job',
								filter: skill => skill.get_the_job,
							},
							{
								label: 'Enjoy Using',
								filter: skill => skill.enjoy_the_job,
							},
							{
								label: 'Improve',
								filter: skill => skill.improve_on_job,
							},
							// {
							// 	label: 'Learn',
							// 	filter: skill => skill.learn_on_job,
							// },
							{
								label: 'Other Skills',
								filter: skill =>
									(displaySkillsWithoutExperience || skill.experience) &&
									!skill.get_the_job &&
									!skill.enjoy_the_job &&
									!skill.improve_on_job &&
									!skill.learn_on_job,
							},
					  ].map(ranking => (
							<>
								<LabeledField
									key={ranking.label}
									label={ranking.label}
									className="color-muted"
									removeMargin
									style={{ marginBottom: 8 }}
								>
									{(candidate.skills || [])
										.filter(ranking.filter)

										.sort((a, b) => {
											const aScore =
												a.experience?.name === 'best'
													? 4
													: a.experience?.name === 'advanced'
													? 3
													: a.experience?.name === 'intermediate'
													? 2
													: 1;
											const bScore =
												b.experience?.name === 'best'
													? 4
													: b.experience?.name === 'advanced'
													? 3
													: b.experience?.name === 'intermediate'
													? 2
													: 1;
											return bScore - aScore;
										})
										.map(skill => (
											<SkillChip
												isLightMode
												baseColor="blue"
												key={skill?.id}
												skill={skill}
												classOverride={
													skill.experience?.name === 'best'
														? 'enjoy_the_job'
														: skill.experience?.name === 'advanced'
														? 'improve_on_job'
														: skill.experience?.name === 'intermediate'
														? 'learn_on_job'
														: 'unknown'
												}
												activeSkillId={activeSkillId}
												setActiveSkillId={setActiveSkillId}
											/>
										))}
								</LabeledField>
								<div style={{ height: 7 }} />
							</>
					  ))
					: [...data.skillExperienceLevels].reverse().map(level => (
							<>
								<LabeledField
									key={level.name}
									label={level.label}
									className="color-muted"
									removeMargin
									style={{ marginBottom: 8 }}
								>
									{(candidate.skills || [])
										.filter(
											skill =>
												skill.experience?.name === level.name ||
												(displaySkillsWithoutExperience && !skill.experience && level.name === 'uncategorized'),
										)
										.sort((a, b) => {
											const aScore =
												(a.get_the_job ? 4 : a.enjoy_the_job ? 3 : a.improve_on_job ? 2 : 1) +
												(a.get_the_job ? 0.5 : 0);

											const bScore =
												(b.get_the_job ? 4 : b.enjoy_the_job ? 3 : b.improve_on_job ? 2 : 1) +
												(b.get_the_job ? 0.5 : 0);
											return bScore - aScore;
										})
										.map(skill => (
											<SkillChip
												key={skill?.id}
												skill={skill}
												activeSkillId={activeSkillId}
												setActiveSkillId={setActiveSkillId}
											/>
										))}
								</LabeledField>
								<div style={{ height: 7 }} />
							</>
					  ))}
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

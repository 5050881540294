import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Typography, CircularProgress, Box, TextField } from '@material-ui/core';
import { InterestIcon } from './RateJobClientInterestModal';
import MatchFitRankingReasonsDropdown from '../form/dropdowns/MatchFitRankingReasonsDropdown';
import LoadingButton from '../form/LoadingButton';
import * as ListingsAPI from '../../scripts/listings';
import { ActionCreators as SiteActions } from '../../store/Site';
import { useContextStore } from '../../store/ContextStore';
import LabeledField from '../form/LabeledField';
import * as Utility from '../../scripts/utility';

const MatchRatingReasonModal = ({ onCancel, onSave, newInterestLevelId }) => {
	const dispatch = useDispatch();
	const [selectedReasonIds, setSelectedReasonIds] = useState([]);
	const [matchRankingComment, setMatchRankingComment] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { data } = useSelector(state => state.site, shallowEqual);

	return (
		<div>
			<Typography variant="h2" style={{ marginBottom: 5, textAlign: 'center' }}>
				Ranking Feedback
			</Typography>
			<div style={{ height: 25 }} />
			<MatchFitRankingReasonsDropdown
				variant="outlined"
				name="study_type_id"
				value={selectedReasonIds}
				onChange={e => setSelectedReasonIds(e.target.value)}
				fullWidth
				placeholder="Select all that apply"
			/>
			<div style={{ height: 25 }} />
			{selectedReasonIds.includes(Utility.getIdFromLabel(data.matchFitRankingReasons, 'Other')) && (
				<LabeledField label="Please provide additional detail">
					<Box display="flex" justifyContent="stretch" marginBottom="48px">
						<TextField
							variant="outlined"
							value={matchRankingComment}
							placeholder="Optional comments"
							onChange={e => setMatchRankingComment(e.target.value)}
							fullWidth
							minRows={3}
							multiline
						/>
					</Box>
				</LabeledField>
			)}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<LoadingButton
					loading={false}
					variant="outlined"
					color="primary"
					onClick={async () => {
						onCancel();
						dispatch(SiteActions.hideModal());
					}}
				>
					Cancel
				</LoadingButton>
				<div style={{ width: 10 }} />
				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					disabled={selectedReasonIds?.length < 1}
					onClick={async () => {
						if (!isLoading) {
							try {
								setIsLoading(true);
								await onSave(newInterestLevelId, selectedReasonIds, matchRankingComment);
								dispatch(SiteActions.hideModal());
							} catch (e) {
								console.log(e);
							} finally {
								setIsLoading(false);
							}
						}
					}}
				>
					Save
				</LoadingButton>
			</div>
		</div>
	);
};

const Modal = ({ graphMatch }) => {
	const location = useLocation();
	const { data, user: authUser, hasSeenTip } = useSelector(state => state.site, shallowEqual);
	const [selectedInterestLevelId, _setSelectedInterestLevelId] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [loadingId, setLoadingId] = useState(null);
	const dispatch = useDispatch();

	const {
		graphMatches,
		setGraphMatches,
		candidateMatches,
		jobMatches,
		setCandidateMatches,
		setJobMatches,
	} = useContextStore();

	const isSuggestedMatch = location?.pathname?.includes('matches');
	const isSavedJobMatch = !location?.pathname?.includes('matches') && location?.pathname?.includes('/job/');
	const isSavedCandidateMatch = !location?.pathname?.includes('matches') && location?.pathname?.includes('/candidate/');

	const currentUserInterestLevelId = graphMatch?.fit_rankings?.find(ranking => ranking?.created_by === authUser?.id)
		?.match_fit_ranking?.id;
	const matchFitRankId = graphMatch?.fit_rankings?.find(ranking => ranking?.created_by === authUser?.id)?.id;

	const saveUserRankingFeedback = async (newInterestLevelId, selectedReasonIds, matchRankingComment) => {
		if (!isSaving) {
			try {
				setIsSaving(true);
				const result = await ListingsAPI.rankMatchFit({
					candidateId: graphMatch?.candidate_id,
					listingId: graphMatch?.listing_id,
					rankingId: newInterestLevelId,
					comment: matchRankingComment,
					reasonIds: selectedReasonIds,
				});
				if (isSuggestedMatch) {
					setGraphMatches(
						graphMatches?.map(match =>
							match.candidate_id === graphMatch.candidate_id && match.listing_id === graphMatch.listing_id
								? {
										...match,
										fit_rankings: [
											result.data.data,
											...(match.fit_rankings || []).filter(fitRanking => fitRanking.user.id !== authUser?.id),
										],
								  }
								: match,
						),
					);
				}
				if (isSavedJobMatch) {
					setJobMatches(
						jobMatches?.map(match =>
							match.candidate_id === graphMatch.candidate_id && match.listing_id === graphMatch.listing_id
								? {
										...match,
										match_ranking: {
											...match.match_ranking,
											fit_rankings: [
												result.data.data,
												...(match.match_ranking.fit_rankings || []).filter(
													fitRanking => fitRanking.user.id !== authUser?.id,
												),
											],
										},
								  }
								: match,
						),
					);
				}
				if (isSavedCandidateMatch) {
					setCandidateMatches(
						candidateMatches?.map(match =>
							match.candidate_id === graphMatch.candidate_id && match.listing_id === graphMatch.listing_id
								? {
										...match,
										match_ranking: {
											...match.match_ranking,
											fit_rankings: [
												result.data.data,
												...(match.match_ranking.fit_rankings || []).filter(
													fitRanking => fitRanking.user.id !== authUser?.id,
												),
											],
										},
								  }
								: match,
						),
					);
				}
			} catch (e) {
				console.log(e);
			} finally {
				setIsSaving(false);
				dispatch(SiteActions.hideModal());
			}
		}
	};

	const deleteUserRankingFeedback = async id => {
		try {
			setIsDeleting(true);
			await ListingsAPI.deleteMatchFit(id);
			if (isSuggestedMatch) {
				setGraphMatches(
					graphMatches?.map(match =>
						match.candidate_id === graphMatch.candidate_id && match.listing_id === graphMatch.listing_id
							? {
									...match,
									fit_rankings: [
										...(match.fit_rankings || []).filter(fitRanking => fitRanking.user.id !== authUser?.id),
									],
							  }
							: match,
					),
				);
			}
			if (isSavedJobMatch) {
				setJobMatches(
					jobMatches?.map(match =>
						match.candidate_id === graphMatch.candidate_id && match.listing_id === graphMatch.listing_id
							? {
									...match,
									match_ranking: {
										...match.match_ranking,
										fit_rankings: [
											...(match.match_ranking.fit_rankings || []).filter(
												fitRanking => fitRanking.user.id !== authUser?.id,
											),
										],
									},
							  }
							: match,
					),
				);
			}
			if (isSavedCandidateMatch) {
				setCandidateMatches(
					candidateMatches?.map(match =>
						match.candidate_id === graphMatch.candidate_id && match.listing_id === graphMatch.listing_id
							? {
									...match,
									match_ranking: {
										...match.match_ranking,
										fit_rankings: [
											...(match.match_ranking.fit_rankings || []).filter(
												fitRanking => fitRanking.user.id !== authUser?.id,
											),
										],
									},
							  }
							: match,
					),
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setIsDeleting(false);
			dispatch(SiteActions.hideModal());
		}
	};

	const setSelectedInterestLevelId = async newInterestLevelId => {
		if (!isSaving) {
			setIsSaving(true);
			try {
				dispatch(
					SiteActions.showModal(
						<MatchRatingReasonModal
							onCancel={() => {
								_setSelectedInterestLevelId(null);
							}}
							onSave={saveUserRankingFeedback}
							newInterestLevelId={newInterestLevelId}
						/>,
						{
							className: 'modal-small',
							disableCloseButton: true,
							disableBackdropClick: true,
							disableEscapeKey: true,
							isDraggable: true,
						},
					),
				);

				_setSelectedInterestLevelId(newInterestLevelId);
			} catch (e) {
				console.log(e);
			} finally {
				setIsSaving(false);
			}
		}
	};

	useEffect(() => {
		_setSelectedInterestLevelId(null);
	}, [graphMatch]);

	return (
		<div className="text-center" style={{ marginBottom: -35 }}>
			<Typography variant="h2" style={{ marginBottom: '2rem' }}>
				Rate Match Interest
			</Typography>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					marginBottom: 30,
				}}
			>
				{data?.interestLevels?.slice(0, 4).map((level, index) => (
					// eslint-disable-next-line jsx-a11y/no-static-element-interactions
					<div
						key={level?.id}
						className="interest-level"
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							cursor: 'pointer',
							padding: 15,
						}}
						onClick={async () => {
							dispatch(SiteActions.setSeenTip(true));
							if (isSaving || isDeleting) return;
							setLoadingId(level.id);
							if (currentUserInterestLevelId === level.id) {
								await deleteUserRankingFeedback(matchFitRankId);
								return;
							}
							if (currentUserInterestLevelId !== level.id && (level.id === 1 || level.id === 2)) {
								setSelectedInterestLevelId(level.id);
								return;
							}
							// note: not sending any interestlevelIds or a comment for good and excellent matches
							saveUserRankingFeedback(level.id, []);
						}}
					>
						{(isSaving && loadingId === level.id && loadingId !== 1 && loadingId !== 2) ||
						(isDeleting && loadingId === level.id) ? (
							<CircularProgress size={46} style={{ color: 'var(--primaryColor)', margin: '3px 0 1.5px 0' }} />
						) : (
							<InterestIcon
								interestLevelId={level.id}
								size={50}
								level={index + 1}
								color={
									selectedInterestLevelId === level.id || currentUserInterestLevelId === level.id
										? 'var(--primaryColor)'
										: 'var(--mutedTextColor)'
								}
							/>
						)}
						<Typography variant="body2" style={{ marginTop: '0.5rem', textAlign: 'center' }}>
							{['Poor Match', 'Inconclusive', 'Good Match', 'Excellent Match'][index]}
						</Typography>
					</div>
				))}
			</div>
			{!hasSeenTip && (
				<Typography variant="caption" style={{ display: 'flex', justifyContent: 'flex-end' }}>
					Tip: Try dragging the modal!
				</Typography>
			)}
		</div>
	);
};

export default Modal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popper, Paper } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import Fence from './Fence';

const InfoPopper = ({ info, size }) => {
	const [popper, setPopper] = useState({ open: false, anchor: null });

	if (!info) return null;
	return (
		<>
			<Icons.Info
				fontSize={size}
				color="disabled"
				onFocus={ev => setPopper({ open: true, anchor: ev.currentTarget })}
				onBlur={ev => setPopper({ open: false, anchor: ev.currentTarget })}
				onMouseOver={ev => setPopper({ open: true, anchor: ev.currentTarget })}
				onMouseOut={ev => setPopper({ open: false, anchor: ev.currentTarget })}
			/>
			<Popper open={popper.open} anchorEl={popper.anchor} placement="right-start" transition>
				<Paper color="primary">
					<Fence width={17}>{info}</Fence>
				</Paper>
			</Popper>
		</>
	);
};

InfoPopper.propTypes = {
	info: PropTypes.string,
	size: PropTypes.string,
};

InfoPopper.defaultProps = {
	info: null,
	size: 'medium',
};

export default InfoPopper;

import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import * as Utility from '../../../scripts/utility';
import EditCompanyBlockModal from './EditCompanyBlockModal';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import { BusinessSizeExperienceDropdown } from '../../../components/form/dropdowns/BusinessSizeDropdown';

const EditModal = ({ company, setCompany }) => {
	return (
		<EditCompanyBlockModal
			company={company}
			setCompany={setCompany}
			title="Company"
			icon={CaseIcon}
			renderContents={({ updateCompany, updatedCompany }) => {
				console.log({ updatedCompany });
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Year Founded" required removeMargin>
									<TextField
										variant="outlined"
										name="year_founded"
										value={updatedCompany.year_founded || ''}
										onChange={updateCompany}
										error={!updatedCompany.year_founded}
										fullWidth
										placeholder="Enter year founded"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Industry">
									<IndustryExperienceDropdown
										variant="outlined"
										name="industries"
										value={updatedCompany?.industries?.map(item => (item.id ? item.id : item))}
										onChange={updateCompany}
										placeholder={
											Utility.getFromObj(updateCompany, 'industries').length > 0 ? '' : 'Select all that apply'
										}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Business Size" removeMargin>
									<BusinessSizeExperienceDropdown
										variant="outlined"
										name="business_sizes"
										value={(Utility.getFromObj(updatedCompany, 'business_sizes') || []).map(item =>
											item.id ? item.id : item,
										)}
										onChange={updateCompany}
										placeholder={
											Utility.getFromObj(updatedCompany, 'business_sizes').length > 0 ? '' : 'Select all that apply'
										}
										isJob
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

export default EditModal;

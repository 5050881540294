import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

import WideSidebar from './WideSidebar';
import VerticalSpacer from '../../VerticalSpacer';
import Logo from '../../../img/Logo';

const CandidateAssessmentSidebar = ({ children, ...props }) => {
	const progress = useSelector(state => state.assessment.progress, shallowEqual);

	const getStepIcon = state => {
		if (state.completed || state.active)
			return <Icons.RadioButtonChecked style={{ color: 'var(--primaryColor)' }} />;

		return <Icons.RadioButtonUnchecked style={{ color: 'var(--mutedTextColor)' }} />;
	};

	return (
		<WideSidebar width="30rem" padding="5rem" className="text-center">
			<Logo height="2.5rem" width="10rem" style={{ margin: '0 auto' }} />
			<VerticalSpacer height={8} />

			<Stepper activeStep={progress.active - 1} orientation="vertical">
				<Step completed={progress.completed.includes(1)}>
					<StepLabel StepIconComponent={getStepIcon}>Professional Experience</StepLabel>
				</Step>
				<Step completed={progress.completed.includes(2)}>
					<StepLabel StepIconComponent={getStepIcon}>Skills</StepLabel>
				</Step>
				<Step completed={progress.completed.includes(3)}>
					<StepLabel StepIconComponent={getStepIcon}>Skill Ranking</StepLabel>
				</Step>
				<Step completed={progress.completed.includes(4)}>
					<StepLabel StepIconComponent={getStepIcon}>Domain Skills</StepLabel>
				</Step>
			</Stepper>
		</WideSidebar>
	);
};

CandidateAssessmentSidebar.propTypes = {
	children: PropTypes.node,
};

CandidateAssessmentSidebar.defaultProps = {
	children: null,
};

export default CandidateAssessmentSidebar;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import LoadingButton from '../../../components/form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';

const EditBlockModal = ({ job, setJob, title, icon, renderContents, overrideSaveJob, disabled, ...props }) => {
	const IconComponent = icon;

	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [updatedJob, setUpdatedJob] = useState(job);
	const updateJob = ev => {
		const { name, value } = ev?.target || {};
		if (name && value !== undefined) {
			const newValue = { [name]: value };
			setUpdatedJob(currentJob => ({ ...currentJob, ...newValue }));
		} else {
			console.warn('Event target name or value is undefined.');
		}
	};
	const saveJob = () => {
		if (overrideSaveJob) {
			overrideSaveJob({ setIsSaving, store, updatedJob, dispatch });
		} else {
			setIsSaving(true);
			try {
				const forgedListing = ListingsAPI.getForgedListing(updatedJob);
				const forgedCurrentListing = ListingsAPI.getForgedListing(job);
				const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentListing, forgedListing);

				differencesToSubmit.screening_questions = forgedListing.screening_questions;

				ListingsAPI.updateListing(job.id, differencesToSubmit, response => {
					if (!response) {
						setIsSaving(false);
						store.dispatch(
							SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'),
						);
						return;
					}

					const newJob = response.data.data;
					setJob(newJob);
					dispatch(SiteActions.hideModal());
				});
			} catch (e) {
				console.log(e);
				dispatch(SiteActions.hideModal());
			}
		}
	};

	const Contents = renderContents;

	return (
		<Panel>
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<IconComponent size={26} className="teal-100" />
					<Typography variant="h3" style={{ paddingLeft: 10 }}>
						{title}
					</Typography>
				</div>
			</PanelHeader>
			<PanelContent style={{ paddingBottom: 0, marginBottom: -8 }}>
				<Contents updateJob={updateJob} updatedJob={updatedJob} setUpdatedJob={setUpdatedJob} />
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}
				<div className="text-right">
					<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveJob} disabled={!!disabled}>
						Save
					</LoadingButton>
				</div>
			</PanelContent>
		</Panel>
	);
};

EditBlockModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
	icon: PropTypes.element,
	title: PropTypes.string,
	children: PropTypes.node,
};

EditBlockModal.defaultProps = {
	job: {},
	setJob: () => null,
	icon: null,
	title: 'Edit Candidate',
	children: null,
};

export default EditBlockModal;

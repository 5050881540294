import React from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel, Button, TextField, Checkbox, Radio, Switch, Chip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';

import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import Topbar from '../../components/page/Topbar';
import CenteredTopbar from '../../components/page/CenteredTopbar';

const Styleguide = props => {
	return (
		<Page>
			<PageHeader.Header>
				<h1>Styleguide</h1>
			</PageHeader.Header>

			<h2>Typography</h2>
			<h3>Headers</h3>
			<ComponentContainer>
				<Typography variant="h1">H1 Header</Typography>
				<Typography variant="h2">H2 Header</Typography>
				<Typography variant="h3">H3 Header</Typography>
				<Typography variant="h4">H4 Header</Typography>
				<Typography variant="h5">H5 Header</Typography>
				<Typography variant="h6">H6 Header</Typography>
				<HorizontalSpacer />
				<Typography variant="subtitle1" gutterBottom>
					Subtitle 1
				</Typography>
				<HorizontalSpacer />
				<Typography variant="subtitle2">Subtitle 2</Typography>
				<HorizontalSpacer />
				<Typography variant="body1">
					Body1. Yeah, but John, if The Pirates of the Caribbean breaks down, the pirates don’t eat the tourists. Do you
					have any idea how long it takes those cups to decompose. Yeah, but John, if The Pirates of the Caribbean
					breaks down, the pirates don’t eat the tourists.
				</Typography>
				<HorizontalSpacer />
				<Typography variant="body2">
					Body2. Do you have any idea how long it takes those cups to decompose. Did he just throw my cat out of the
					window? You&apos;re a very talented young man, with your own clever thoughts and ideas. Do you need a manager?
					Must go faster. This thing comes fully loaded. AM/FM radio, reclining bucket seats, and... power windows.
				</Typography>
				<HorizontalSpacer />
				<Typography variant="button" display="block">
					button text
				</Typography>
				<HorizontalSpacer />
				<Typography variant="caption" display="block">
					caption text
				</Typography>
				<HorizontalSpacer />
				<Typography variant="overline" display="block">
					overline text
				</Typography>
			</ComponentContainer>

			<h3>Paragraph</h3>
			<ComponentContainer>
				<p>
					Do you have any idea how long it takes those cups to decompose. Did he just throw my cat out of the window?
					You&apos;re a very talented young man, with your own clever thoughts and ideas. Do you need a manager? Must go
					faster. This thing comes fully loaded. AM/FM radio, reclining bucket seats, and... power windows.
				</p>
			</ComponentContainer>

			<h2>Buttons</h2>
			<h3>Contained</h3>
			<ComponentContainer>
				<Button variant="contained">Default</Button>
				<HorizontalSpacer />
				<Button variant="contained" color="primary">
					Primary
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" color="secondary">
					Secondary
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" disabled>
					Disabled
				</Button>
			</ComponentContainer>

			<h3>Contained Small</h3>
			<ComponentContainer>
				<Button variant="contained" size="small">
					Default
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" color="primary" size="small">
					Primary
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" color="secondary" size="small">
					Secondary
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" disabled size="small">
					Disabled
				</Button>
			</ComponentContainer>

			<h3>Contained - Elevation Disabled</h3>
			<ComponentContainer>
				<Button variant="contained" disableElevation>
					Default
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" color="primary" disableElevation>
					Primary
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" color="secondary" disableElevation>
					Secondary
				</Button>
				<HorizontalSpacer />
				<Button variant="contained" disabled disableElevation>
					Disabled
				</Button>
			</ComponentContainer>

			<h3>Outlined</h3>
			<ComponentContainer>
				<Button variant="outlined">Default</Button>
				<HorizontalSpacer />
				<Button variant="outlined" color="primary">
					Primary
				</Button>
				<HorizontalSpacer />
				<Button variant="outlined" color="secondary">
					Secondary
				</Button>
				<HorizontalSpacer />
				<Button variant="outlined" disabled>
					Disabled
				</Button>
			</ComponentContainer>

			<h3>Outlined Small</h3>
			<ComponentContainer>
				<Button variant="outlined" size="small">
					Default
				</Button>
				<HorizontalSpacer />
				<Button variant="outlined" color="primary" size="small">
					Primary
				</Button>
				<HorizontalSpacer />
				<Button variant="outlined" color="secondary" size="small">
					Secondary
				</Button>
				<HorizontalSpacer />
				<Button variant="outlined" disabled size="small">
					Disabled
				</Button>
			</ComponentContainer>

			<h3>Text</h3>
			<ComponentContainer>
				<Button>Default</Button>
				<HorizontalSpacer />
				<Button color="primary">Primary</Button>
				<HorizontalSpacer />
				<Button color="secondary">Secondary</Button>
				<HorizontalSpacer />
				<Button disabled>Disabled</Button>
			</ComponentContainer>

			<h2>Text fields</h2>
			<ComponentContainer>
				<TextField variant="outlined" placeholder="Outlined Text Field" />
				<VerticalSpacer />
				<TextField variant="outlined" placeholder="Disabled Text Field" />
				<VerticalSpacer />
				<TextField variant="outlined" placeholder="Read Only Text Field" />
			</ComponentContainer>

			<h2>Selection Control</h2>
			<h3>Checkbox</h3>
			<ComponentContainer>
				<FormControlLabel control={<Checkbox checked />} label="Default" />
				<HorizontalSpacer checked />
				<FormControlLabel control={<Checkbox checked color="primary" />} label="Primary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Checkbox checked color="secondary" />} label="Secondary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Checkbox checked disabled />} label="Disabled" />
				<VerticalSpacer />
				<FormControlLabel control={<Checkbox />} label="Default" />
				<HorizontalSpacer />
				<FormControlLabel control={<Checkbox color="primary" />} label="Primary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Checkbox color="secondary" />} label="Secondary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Checkbox disabled />} label="Disabled" />
			</ComponentContainer>

			<h3>Radio Button</h3>
			<ComponentContainer>
				<FormControlLabel control={<Radio checked />} label="Default" />
				<HorizontalSpacer checked />
				<FormControlLabel control={<Radio checked color="primary" />} label="Primary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Radio checked color="secondary" />} label="Secondary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Radio checked disabled />} label="Disabled" />
				<VerticalSpacer />
				<FormControlLabel control={<Radio />} label="Default" />
				<HorizontalSpacer />
				<FormControlLabel control={<Radio color="primary" />} label="Primary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Radio color="secondary" />} label="Secondary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Radio disabled />} label="Disabled" />
			</ComponentContainer>

			<h3>Switch</h3>
			<ComponentContainer>
				<FormControlLabel control={<Switch checked />} label="Default" />
				<HorizontalSpacer checked />
				<FormControlLabel control={<Switch checked color="primary" />} label="Primary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Switch checked color="secondary" />} label="Secondary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Switch checked disabled />} label="Disabled" />
				<VerticalSpacer />
				<FormControlLabel control={<Switch />} label="Default" />
				<HorizontalSpacer />
				<FormControlLabel control={<Switch color="primary" />} label="Primary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Switch color="secondary" />} label="Secondary" />
				<HorizontalSpacer />
				<FormControlLabel control={<Switch disabled />} label="Disabled" />
			</ComponentContainer>

			<h2>
				Alerts
				<Icons.Check color="primary" />
			</h2>
			<ComponentContainer>
				<Alert severity="info"> This is an info alert</Alert>
				<VerticalSpacer />
				<Alert severity="error"> This is an error alert</Alert>
				<VerticalSpacer />
				<Alert severity="warning"> This is an warning alert</Alert>
				<VerticalSpacer />
				<Alert severity="success" icon={<Icons.Check />}>
					{' '}
					This is an success alert
				</Alert>
			</ComponentContainer>

			<h2>
				Icons
				<Icons.Check color="primary" />
			</h2>
			<ComponentContainer>
				<Icons.ArrowBack color="primary" />
				<HorizontalSpacer />
				<Icons.ArrowForward color="primary" />
				<HorizontalSpacer />
				<Icons.Check color="primary" />
				<HorizontalSpacer />
				<Icons.Close color="primary" />
				<HorizontalSpacer />
				<Icons.MoreVert color="primary" />
				<HorizontalSpacer />
				<Icons.Menu color="primary" />
				<HorizontalSpacer />
				<Icons.Create color="primary" />
			</ComponentContainer>

			<h2>Chip / Tag</h2>
			<ComponentContainer>
				<Chip label="Default" />
				<HorizontalSpacer />
				<Chip label="primary" color="primary" />
				<HorizontalSpacer />
				<Chip label="Secondary" color="secondary" />
				<HorizontalSpacer />
				<Chip label="Disabled" disabled />
				<VerticalSpacer />
				<Chip label="Default" variant="outlined" />
				<HorizontalSpacer />
				<Chip label="primary" variant="outlined" color="primary" />
				<HorizontalSpacer />
				<Chip label="Secondary" variant="outlined" color="secondary" />
				<HorizontalSpacer />
				<Chip label="Disabled" variant="outlined" disabled />
			</ComponentContainer>

			<h2>High Order Components</h2>

			<h3>Topbar</h3>
			<ComponentContainer>
				<Topbar />
				<VerticalSpacer />
				<CenteredTopbar />
			</ComponentContainer>

			<h3>Page Title</h3>
			<ComponentContainer>
				<PageHeader.Header>
					<PageHeader.Left>
						<h1>Page Title</h1>
					</PageHeader.Left>
					<PageHeader.Middle>Center</PageHeader.Middle>
					<PageHeader.Right>
						<Button variant="contained" color="primary" disableElevation>
							Right
						</Button>
					</PageHeader.Right>
				</PageHeader.Header>
			</ComponentContainer>
		</Page>
	);
};

const ComponentContainer = ({ title, children, style }) => {
	return (
		<div style={{ marginBottom: 40 }}>
			<h2 style={{ marginBottom: 20 }}>{title}</h2>
			<div style={{ border: '1px solid var(--mediumGray)', padding: 20, ...style }}>{children}</div>
		</div>
	);
};

ComponentContainer.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
};

ComponentContainer.defaultProps = {
	title: '',
	children: null,
	style: {},
};

const HorizontalSpacer = props => {
	return <div style={{ display: 'inline-block', width: 20 }} />;
};

const VerticalSpacer = props => {
	return <div style={{ display: 'block', height: 20 }} />;
};

export default Styleguide;

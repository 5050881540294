import React, { useEffect, useState } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import Page from '../../components/page/Page';
import StickyHeader from '../../components/StickyHeader';
import { Panel } from '../../components/Panel';
import JobCardList from '../../components/matching/JobCardList';
import * as CandidatesAPI from '../../scripts/candidates';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as Utility from '../../scripts/utility';
import ViewSummaryBlock from './CandidateBlocks/ViewSummaryBlock';
import ViewExperienceBlock from './CandidateBlocks/ViewExperienceBlock';
import ViewResumeBlock from './CandidateBlocks/ViewResumeBlock';
import ViewPreferencesBlock from './CandidateBlocks/ViewPreferencesBlock';
import ViewAchievementsBlock from './CandidateBlocks/ViewAchievementsBlock';
import ViewSkillsBlock from './CandidateBlocks/ViewSkillsBlock';
import ViewWorkAuthorizationBlock from './CandidateBlocks/ViewWorkAuthorizationBlock';
import ViewLocationPreferencesBlock from './CandidateBlocks/ViewLocationPreferencesBlock';
import ViewHeaderBlock from './CandidateBlocks/ViewHeaderBlock';
import { useContextStore } from '../../store/ContextStore';
import CandidateLogoLinks from '../../components/matching/CandidateLogoLinks';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const CandidateView = () => {
	const store = useStore();
	const params = useParams();
	const { data, sidebarWidth } = useSelector(state => state.site, shallowEqual);
	const { candidateMatches, setCandidateMatches, refetchMatches, loadingCandidateMatches } = useContextStore();

	const candidateId = params.id;
	const [candidate, _setCandidate] = useState({});
	const setCandidate = newCandidate => {
		_setCandidate(newCandidate);
	};

	useDocumentTitle(
		candidate && candidate.first_name
			? `${candidate.first_name} ${candidate.last_name} 
		- ${candidate.current_title || 'N/A'} - ${candidate.current_employer || 'N/A'} |  NauMatch`
			: 'Candidate | NauMatch',
	);

	const refreshCandidate = () => {
		CandidatesAPI.getCandidate(candidateId, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'));
				return;
			}
			setCandidate(response.data.data);
		});
	};

	// Get page data on load
	useEffect(() => {
		refreshCandidate();
		setCandidateMatches(null);
		refetchMatches({ candidateId });
	}, []);

	if (Object.keys(candidate).length === 0) return <Page />;

	return (
		<Page
			header={
				<ViewHeaderBlock
					candidate={candidate}
					setCandidate={setCandidate}
					data={data}
					displayBackArrow
					onUpdate={refreshCandidate}
				/>
			}
		>
			<div>
				<StickyHeader
					style={{
						width: `calc(100% - ${sidebarWidth}px)`,
						justifyContent: 'flex-start',
					}}
				>
					<Typography
						variant="body2"
						style={{
							color: '#fff',
							marginLeft: -5,
							fontWeight: 700,
							fontSize: 14,
						}}
					>
						{candidate.first_name} {candidate.last_name}
						{candidate.desired_position
							? ` | ${candidate.desired_position}`
							: candidate.specialty_id
							? ` | ${Utility.getItemLabel(data.specialties, candidate.specialty_id)}`
							: null}
					</Typography>
					<div style={{ marginLeft: 10 }}>
						<CandidateLogoLinks candidate={candidate} onUpdate={refreshCandidate} isSharable={candidate?.is_sharable} />
					</div>
				</StickyHeader>
			</div>
			<Grid container spacing={4}>
				<Grid item md={12} lg={12} style={{ marginBottom: -16, paddingBottom: 0 }}>
					<Panel topBarColor="teal">
						<ViewSummaryBlock candidate={candidate} setCandidate={setCandidate} defaultMinimized />
					</Panel>
				</Grid>
				<Grid item md={12} lg={6}>
					<Panel topBarColor="teal">
						<ViewPreferencesBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewLocationPreferencesBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewExperienceBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewAchievementsBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewWorkAuthorizationBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
				</Grid>
				<Grid item md={12} lg={6}>
					<JobCardList
						roleOverride="recruiter"
						refetchMatches={refetchMatches}
						candidateId={candidateId}
						loading={loadingCandidateMatches}
						matches={candidateMatches}
						title="Saved Matches"
						small
					/>
					<Panel topBarColor="teal">
						<ViewSkillsBlock
							candidate={candidate}
							setCandidate={setCandidate}
							data={data}
							displaySkillsWithoutExperience
						/>
					</Panel>
					<Panel topBarColor="teal">
						<ViewResumeBlock
							displayDocumentManagement
							candidate={candidate}
							refreshCandidate={refreshCandidate}
							data={data}
						/>
					</Panel>
				</Grid>
			</Grid>
		</Page>
	);
};

export default CandidateView;

import React, { useRef, useState } from 'react';
import { useStore, useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Typography, Button, IconButton, Menu, MenuItem, ListItemIcon, Tabs, Tab } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import Table from '../../../components/Table';
import SpecialtyDeleteModal from '../../../components/modals/SpecialtyDeleteModal';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as SpecialtiesAPI from '../../../scripts/specialties';
import * as Utility from '../../../scripts/utility';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SyncCatSpecButton from '../../../components/admin/SyncCatSpecButton';

const Specialties = () => {
	useDocumentTitle('Data Manager - Specialties');
	const dispatch = useDispatch();
	const history = useHistory();

	const store = useStore();
	const { data, alert, reports, urlHistory } = useSelector(state => state.site, shallowEqual);
	const [menu, setMenu] = useState({ anchor: null, specialty: {} });
	const table = useRef();

	const getDefaultFilters = () => {
		const lastPage = urlHistory[urlHistory.length - 1];
		const whitelist = [/\/admin\/specialt.*/];
		const defaultFilters = {};

		if (!whitelist.some(i => i.test(lastPage))) {
			dispatch(SiteActions.resetReport('specialties'));
			return defaultFilters;
		}

		return Utility.getFromObj(reports, 'specialties', 'filters') || defaultFilters;
	};

	const [filter, setFilter] = useState({ isOpen: false, filters: getDefaultFilters() });

	const headers = [
		{ id: 'specialties.label', value: 'Label' },
		{ id: 'specialties.id', value: 'ID' },
		{ id: 'specialties.name', value: 'Slug' },
		{ id: 'categories.label', value: 'Category', noSort: true },
		{ id: 'specialties.created_at', value: 'Added' },
		{ id: 'options', value: '', noSort: true },
	];

	const formatData = rows => {
		return rows.map(specialty => {
			return [
				<>
					<NavLink to={`/admin/specialty/${specialty.id}/edit`} className="color-inherit">
						{specialty.label}
					</NavLink>
				</>,
				<>{specialty.id}</>,
				<>{specialty.name}</>,
				<>
					{Utility.getFromObj(
						data.specialties.find(s => s.id === specialty.id),
						'category',
						'label',
					) || Utility.na}
				</>,
				<>{Utility.formatDateTime(specialty.created_at) || Utility.na}</>,
				<IconButton onClick={ev => setMenu({ anchor: ev.target, specialty })}>
					<Icons.MoreVert color="primary" />
				</IconButton>,
			];
		});
	};

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Data Manager</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					{/* <Button
						variant="text"
						color="primary"
						onClick={ev => Utility.notImplemented(store)}
						startIcon={<Icons.FilterList />}
					>
						Filter
					</Button> */}

					<SyncCatSpecButton />
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Tabs value={2} textColor="primary" indicatorColor="primary">
				<Tab label="Skills" onClick={() => history.push('/admin/skills')} />
				<Tab label="Categories" onClick={() => history.push('/admin/categories')} />
				<Tab label="Specialties" onClick={() => history.push('/admin/specialties')} />
			</Tabs>

			<Table
				name="specialties"
				onUpdate={SpecialtiesAPI.getSpecialties}
				filters={filter.filters}
				format={formatData}
				headers={headers}
				ref={table}
				checkbox
			/>

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/admin/specialty/${menu.specialty.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Specialty
					</MenuItem>
				</NavLink>
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(
							SiteActions.showModal(
								<SpecialtyDeleteModal specialty={menu.specialty} onDelete={() => table.current.refreshData()} />,
							),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Specialty
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Specialties;

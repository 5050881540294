import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Panel, PanelContent, PanelHeader } from '../../../components/Panel';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditWorkAuthorizationModal from '../EditCandidateProfile/EditWorkAuthorizationModal';
import CheckmarkIcon from '../../../components/icons/CheckmarkIcon';
import * as Utility from '../../../scripts/utility';

const Block = ({ candidate, setCandidate, buttonRef, isComplete }) => {
	const dispatch = useDispatch();

	return (
		<Panel id="WorkAuthorization">
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<CheckmarkIcon size={32} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						US Work Authorization
					</Typography>
				</div>
				{!!candidate.assessment_completed_at && (
					<Button
						ref={buttonRef}
						variant={isComplete ? 'outlined' : 'containedTeal'}
						color="primary"
						size="small"
						onClick={() => {
							dispatch(
								SiteActions.showModal(
									<EditWorkAuthorizationModal candidate={candidate} setCandidate={setCandidate} />,
									{
										className: 'modal-medium modal-no-padding modal-no-shadow',
									},
								),
							);
						}}
					>
						{isComplete ? 'Edit' : 'Complete'}
					</Button>
				)}
			</PanelHeader>
			<PanelContent style={{ paddingInline: '63px' }}>
				<VerticalSpacer height={1} />
				<Grid container spacing={0}>
					<Grid item xs={6}>
						{candidate?.work_visa_type && (
							<>
								<Typography className="grey-250">Work Visa Sponsorship</Typography>
								<Typography>{candidate.work_visa_type.label}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.us_work_authorization != null && (
							<>
								<Typography className="grey-250">Legally authorized for work in the United States</Typography>
								<Typography>
									{candidate?.us_work_authorization === true
										? 'Yes'
										: candidate?.us_work_authorization === false
										? 'No'
										: Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
				</Grid>
			</PanelContent>
		</Panel>
	);
};

export default Block;

import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from 'history';
import LogRocket from 'logrocket';

const notLocalhost = !window?.location?.origin?.includes('localhost');
const appInsightsEnabled = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING && notLocalhost;

console.log(`AppInsights Enabled: ${appInsightsEnabled}`);

const browserHistory = createBrowserHistory({ basename: '' });

let appInsights;
let reactPlugin;
if (notLocalhost) {
	// Initialize LogRocket
	LogRocket.init('iievcl/nauwork-portal');

	if (appInsightsEnabled) {
		reactPlugin = new ReactPlugin();
		const clickPluginInstance = new ClickAnalyticsPlugin();
		const clickPluginConfig = {
			autoCapture: true,
		};

		appInsights = new ApplicationInsights({
			config: {
				connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
				// If you're adding the Click Analytics plug-in, delete the next line.
				// extensions: [reactPlugin],
				// Add the Click Analytics plug-in.

				extensions: [reactPlugin, clickPluginInstance],
				extensionConfig: {
					[reactPlugin.identifier]: {},
					[reactPlugin.identifier]: { history: browserHistory },
					// Add the Click Analytics plug-in.
					[clickPluginInstance.identifier]: clickPluginConfig,
				},

				enableAutoRouteTracking: true,
				disableAjaxTracking: false,
				autoTrackPageVisitTime: true,
				enableCorsCorrelation: false,
				enableRequestHeaderTracking: true,
				enableResponseHeaderTracking: true,
			},
		});
		appInsights.loadAppInsights();
	}
}

const Logging = ({ children }) => {
	const [userID, setUserID] = useState(0);
	const { user } = useSelector(state => state.site, shallowEqual);

	useEffect(() => {
		if (user?.id) {
			if (user.id && user.id !== userID) {
				LogRocket.identify(user.id, {
					name: `${user.first_name} ${user.last_name}`,
					email: user.email,
				});
			}
		}
		if (appInsightsEnabled) {
			if (user?.id) {
				if (user.id && user.id !== userID) {
					try {
						appInsights.setAuthenticatedUserContext(user.id);
					} catch (ex) {
						console.warn('Application insights Error [setAuthenticatedUserContext]: ', ex);
					}
					setUserID(user.id);
				}
			} else {
				try {
					appInsights.clearAuthenticatedUserContext();
				} catch (e) {
					console.warn('Application insights Error [clearAuthenticatedUserContext]: ', e);
				}
			}
		}
	}, [user?.id]);

	if (appInsightsEnabled) {
		return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
	}
	return children;
};

export default Logging;

import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import * as Utility from '../../../../../scripts/utility';
import EducationDropdown from '../../../../form/dropdowns/EducationDropdown';
import AreasOfStudyDropdown from '../../../../form/dropdowns/AreasOfStudyDropdown';

const EducationForm = ({ candidate, setCandidate }) => {
	const isHighschool = candidate.education_type_id === 6;
	const isAssociateNonDegreeOrOther =
		candidate.education_type_id === 1 || candidate.education_type_id === 9 || candidate.education_type_id === 10;

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Education
			</Typography>
			<VerticalSpacer height={1} />
			<Fence width={25}>
				<LabeledField label="Highest Education Level Achieved" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<EducationDropdown
						variant="outlined"
						name="education_type_id"
						value={candidate.education_type_id}
						onChange={setCandidate}
						placeholder="Choose from our list"
						fullWidth
					/>
				</LabeledField>
				<LabeledField label="Major(s) / Field of Study" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<TextField
						variant="outlined"
						name="field_of_study"
						value={isHighschool ? '' : Utility.getFromObj(candidate, 'field_of_study')}
						onChange={setCandidate}
						disabled={isHighschool}
						placeholder="Enter major or field of study"
						fullWidth
					/>
				</LabeledField>
				<LabeledField label="Area(s) of Study" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<AreasOfStudyDropdown
						variant="outlined"
						name="study_types"
						value={
							isHighschool || isAssociateNonDegreeOrOther
								? ''
								: candidate?.study_types?.map(x => (x?.id ? x.id : x)) || []
						}
						onChange={setCandidate}
						placeholder="Select all that apply"
						disabled={isHighschool || isAssociateNonDegreeOrOther}
						multiple
						fullWidth
					/>
				</LabeledField>
			</Fence>
		</>
	);
};

export default EducationForm;

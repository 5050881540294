import React from 'react';

const AdminSVG = ({ props, color = '#A5AEBC' }) => (
	<svg width={16} height={20} fill={color} {...props}>
		<path
			d="M12 14.5a1 1 0 110 2 1 1 0 010-2zM8 0c4 0 8 1.37 
      8 4v12c0 2.63-4 4-8 4s-8-1.37-8-4V4c0-2.63 4-4 8-4zM2 
      16c0 .71 2.28 2 6 2s6-1.29 6-2v-3.27A13.16 13.16 0 018 
      14a13.16 13.16 0 01-6-1.27V16zm0-6c0 .71 2.28 2 6 2s6-1.29 
      6-2V6.73A13.16 13.16 0 018 8a13.16 13.16 0 
      01-6-1.27V10zm6-4c3.72 0 6-1.29 6-2s-2.28-2-6-2-6 1.29-6 
      2 2.28 2 6 2zm4 2.5a1 1 0 110 2 1 1 0 010-2z"
			fill={color}
		/>
	</svg>
);

export default AdminSVG;

import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import MeterIcon from '../../../components/icons/SectionIcons/MeterIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditSkillsModal from './EditSkillsModal';
import { SkillChip } from '../../candidates/CandidateBlocks/ViewSkillsBlock';

const Block = ({
	job,
	setJob,
	data,
	minimized,
	setMinimized,
	comparisonCandidate,
	activeSkillId,
	setActiveSkillId,
	groupByRanking,
	disableEdit,
	graphMatch,
	displaySkillsWithoutExperience,
}) => {
	const { states } = data;
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={MeterIcon}
				iconColor="var(--blue-500)"
				title="Skills"
				secondaryTitle={
					graphMatch?.total_skill_score
						? `Score: ${Math.round(graphMatch?.total_skill_score * 100)}% (${Math.round(
								graphMatch?.completeness_score_skills * 100,
						  )}%)`
						: null
				}
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditSkillsModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				{groupByRanking ? (
					<div style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<SkillChip baseColor="blue" isEmpty skill={{ skill: { label: 'Hero' }, hero_skill: true }} />

						{/* <SkillChip baseColor="blue" skill={{ skill: { label: 'Best' }, required_skill: true }} />
						<SkillChip baseColor="blue" skill={{ skill: { label: 'Advanced' }, preferred_skill: true }} />
						<SkillChip baseColor="blue" skill={{ skill: { label: 'Intermediate' }, bonus_skill: true }} />
						<SkillChip baseColor="blue" skill={{ skill: { label: 'Beginner & Uncategorized' }, bonus_skill: false }} /> 
						*/}
						<div
							style={{
								width: 102,
								height: 10,
								borderRadius: 38,
								background: 'linear-gradient(-90deg, #F1F1F1 0%, #EEF6FF 32.60%, #8EC2FF 66.15%, #135C94 100%)',
								boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.15)',
								marginBottom: 12,
								marginLeft: -10,
							}}
						/>
						<SkillChip baseColor="blue" isEmpty skill={{ skill: { label: 'Beginner' }, bonus_skill: false }} />
					</div>
				) : (
					<div style={{ textAlign: 'right' }}>
						<SkillChip baseColor="blue" isEmpty skill={{ skill: { label: 'Hero' }, hero_skill: true }} />
						<SkillChip baseColor="blue" skill={{ skill: { label: 'Required' }, required_skill: true }} />
						<SkillChip baseColor="blue" skill={{ skill: { label: 'Preferred' }, preferred_skill: true }} />
						<SkillChip baseColor="blue" skill={{ skill: { label: 'Bonus' }, bonus_skill: true }} />
					</div>
				)}
				{groupByRanking
					? [
							// {
							// 	label: 'Critical',
							// 	filter: skill => skill.hero_skill,
							// },
							{
								label: 'Required',
								filter: skill => skill.required_skill,
							},
							{
								label: 'Preferred',
								filter: skill => skill.preferred_skill,
							},
							{
								label: 'Bonus',
								filter: skill => skill.bonus_skill,
							},
							{
								label: 'Other Skills',
								filter: skill =>
									(displaySkillsWithoutExperience || skill.experience) &&
									!skill.hero_skill &&
									!skill.required_skill &&
									!skill.preferred_skill &&
									!skill.bonus_skill,
							},
					  ].map(ranking => (
							<>
								<LabeledField
									key={ranking.label}
									label={ranking.label}
									className="color-muted"
									removeMargin
									style={{ marginBottom: 8 }}
								>
									{(job.skills || [])
										.sort((a, b) => {
											const aScore =
												a.experience?.name === 'best'
													? 4
													: a.experience?.name === 'advanced'
													? 3
													: a.experience?.name === 'intermediate'
													? 2
													: 1;
											const bScore =
												b.experience?.name === 'best'
													? 4
													: b.experience?.name === 'advanced'
													? 3
													: b.experience?.name === 'intermediate'
													? 2
													: 1;
											return bScore - aScore;
										})
										.filter(ranking.filter)
										.map(skill => (
											<SkillChip
												isLightMode
												baseColor="blue"
												key={skill?.id}
												skill={skill}
												validationError={
													comparisonCandidate && !comparisonCandidate?.skills?.map(x => x.skill_id)?.includes(skill?.id)
												}
												classOverride={
													skill.experience?.name === 'best'
														? 'required_skill'
														: skill.experience?.name === 'advanced'
														? 'preferred_skill'
														: skill.experience?.name === 'intermediate'
														? 'bonus_skill'
														: 'unknown'
												}
												activeSkillId={activeSkillId}
												setActiveSkillId={setActiveSkillId}
											/>
										))}
								</LabeledField>
								<div style={{ height: 7 }} />
							</>
					  ))
					: [...data.skillExperienceLevels].reverse().map(level => (
							<>
								<LabeledField
									key={level?.name}
									label={level.label}
									className="color-muted"
									removeMargin
									style={{ marginBottom: 8 }}
								>
									{[
										...(job.skills || []).filter(
											skill =>
												skill.experience?.name === level?.name ||
												(displaySkillsWithoutExperience && !skill.experience && level?.name === 'uncategorized'),
										),
									]
										.sort((a, b) => {
											const aScore =
												(a.required_skill ? 4 : a.preferred_skill ? 3 : a.bonus_skill ? 2 : 1) +
												(a.hero_skill ? 0.5 : 0);

											const bScore =
												(b.required_skill ? 4 : b.preferred_skill ? 3 : b.bonus_skill ? 2 : 1) +
												(b.hero_skill ? 0.5 : 0);
											return bScore - aScore;
										})
										.map(skill => (
											<SkillChip
												baseColor="blue"
												key={skill?.id}
												skill={skill}
												validationError={
													comparisonCandidate && !comparisonCandidate?.skills?.map(x => x.skill_id)?.includes(skill?.id)
												}
												activeSkillId={activeSkillId}
												setActiveSkillId={setActiveSkillId}
											/>
										))}
								</LabeledField>
								<div style={{ height: 7 }} />
							</>
					  ))}
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ButtonBase, Typography, Chip, CircularProgress, Grid, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SignupImage from '../../img/assessment-signup.png';
import Page from '../../components/page/Page';
import PageLoading from '../../components/page/PageLoading';
import Fence from '../../components/Fence';
import { Panel, PanelHeader, PanelContent } from '../../components/Panel';
import Section from '../../components/Section';
import VerticalSpacer from '../../components/VerticalSpacer';
import ChangeLoginModal from '../../components/modals/ChangeLoginModal';
import EmailCircleIcon from '../../components/icons/EmailCircleIcon';
import MinusIcon from '../../components/icons/MinusIcon';
import PlusIcon from '../../components/icons/PlusIcon';
import PhoneCircleIcon from '../../components/icons/PhoneCircleIcon';
import EditContactInfoModal from './EditContactInfoModal';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as ContactsAPI from '../../scripts/contacts';
import * as Utility from '../../scripts/utility';
import VerifyAccountModal from '../../components/modals/VerifyAccountModal';
import * as UserAPI from '../../scripts/users';
import * as ListingsAPI from '../../scripts/listings';
import useCurrentUser from '../../hooks/useCurrentUser';
import * as AuthenticationAPI from '../../scripts/authentication';
import ExpiredLinkMessage from '../../components/ExpiredLinkMessage';

const ListingPanel = ({ listing, closed, matches }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const hasUnviewedMatches = matches?.filter(x => x?.listing_id === listing?.id && !x?.user_viewed_at).length > 0;

	const editListing = l => {
		dispatch(AssessmentActions.resetAssessment());
		setTimeout(() => history.push(`/job/${l.id}/profile`), 500);
	};

	const numDisplaySkills = 4;
	const numSkills = listing.skills?.length;
	const displaySkills = (listing.skills || [])
		.sort((a, b) => b.experience_years - a.experience_years)
		.slice(0, numDisplaySkills);

	return (
		<Panel
			key={listing.id}
			className="cursor-pointer panel-clickable"
			onClick={() => editListing(listing)}
			data-id={listing.id}
			style={{ position: 'relative' }}
		>
			<PanelContent
				className="spread"
				style={{
					paddingTop: 25,
					paddingBottom: 25,
				}}
			>
				<div>
					<Typography variant="h2" className="text-medium">
						{listing.position_title}
					</Typography>
					<Typography variant="body2">
						{displaySkills.map(skill => Utility.getFromObj(skill, 'skill', 'label')).join(', ')}
						{numSkills > numDisplaySkills ? `, +${numSkills - numDisplaySkills} more` : ''}
					</Typography>
				</div>
				<div className="spread">
					<Typography variant="body2" className="text-medium text-right" style={{ marginRight: 35 }}>
						Full time
					</Typography>
					{closed ? (
						<Chip
							variant="outlined"
							label="CLOSED"
							color="primary"
							className="chip-text-small text-bold cursor-pointer no-margins"
							style={{ width: 132 }}
						/>
					) : (
						<Chip
							variant="outlined"
							label={listing.assessment_completed_at ? 'PROFILE COMPLETE' : 'PROFILE INCOMPLETE'}
							className={`chip-tinted color-${
								listing.assessment_completed_at ? 'success' : 'error'
							} chip-text-small text-bold cursor-pointer no-margins`}
							style={{ width: 132 }}
						/>
					)}
				</div>
			</PanelContent>
			{hasUnviewedMatches && (
				<div
					style={{
						position: 'absolute',
						left: -1,
						top: 4,
						backgroundColor: '#B3F3EF',
						padding: '3px 6px',
						fontWeight: 500,
						borderTopRightRadius: 4,
						borderBottomRightRadius: 4,
						color: '#105B56',
					}}
				>
					NEW CANDIDATES
				</div>
			)}
		</Panel>
	);
};

ListingPanel.propTypes = {
	listing: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		assessment_completed_at: PropTypes.string,
		position_title: PropTypes.string,
		skills: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	closed: PropTypes.bool,
};

ListingPanel.defaultProps = {
	listing: {},
	closed: false,
};

const ContactProfile = () => {
	const store = useStore();
	const dispatch = useDispatch();
	const location = useLocation();
	const params = useParams();
	const contactID = params.id;
	const { alert, user: authUser } = useSelector(state => state.site, shallowEqual);
	const [state, setState] = useState({ isLoading: false });
	const [isLoading, setIsLoading] = useState(false);
	const [listings, setListings] = useState({ open: [], closed: [] });
	const [contact, setContact] = useState({});
	const [displayClosedListings, setDisplayClosedListings] = useState(false);
	const [displayExpiredLinkMessage, setDisplayExpiredLinkMessage] = useState(false);

	// const { user: authUser } = useSelector(s => s.site, shallowEqual);

	const { loading: loadingUser, currentUser, refetch: refetchUser } = useCurrentUser();

	const openUserVerification = () => {
		store.dispatch(
			SiteActions.showModal(<VerifyAccountModal onAdd={() => null} />, {
				className: 'modal-small',
				disableCloseButton: true,
				disableBackdropClick: true,
				disableEscapeKey: true,
			}),
		);
	};

	const history = useHistory();
	useEffect(() => {
		const urlParams = new URLSearchParams(location?.search);
		const url = urlParams.get('authUrl');
		const matchId = urlParams.get('matchId');

		console.log({ url });

		// If we have an authUrl, authenticate with it.
		if (url) {
			// Attempt to manually parse url if unencoded
			// eslint-disable-next-line prefer-destructuring
			// if (!url.includes('timestamp')) url = location?.href?.split('authUrl=')[1];
			UserAPI.jwtLogin(url, (result, err) => {
				console.log({ result });
				if (result && !result?.error) {
					// The user already has a claimed account: redirect them to login.
					if (result?.data?.user?.claimed_at) {
						const from = history.location.pathname.includes('/login')
							? Utility.getFromObj(history, 'location', 'state', 'redirect')
							: history.location.pathname;
						history.push('/contact-login', { redirect: from });
					} else {
						// If the user has not completed the assessment, defer it.
						// if (!result?.data?.user?.assessment_completed_at) {
						// 	setAssessmentDeffered(true);
						// }

						// The user does not have a claimed account: log them in.
						console.log('Storing the authentication credentials.');
						AuthenticationAPI.storeAssessmentCredentials(result.data, location.search, store);

						console.log('refetching user');
						refetchUser();
					}
				} else if (
					err?.response?.data?.error === 'Issue count exceeded.' ||
					err?.response?.data?.error === 'Link expired'
				) {
					setDisplayExpiredLinkMessage(true);
				} else {
					console.log('Showing an alert.');
					store.dispatch(
						SiteActions.showAlert(
							'Could not authenticate with the provided link. Please try again or contact support.',
							'error',
						),
					);
				}
			});
		}
	}, []);

	useEffect(() => {
		if (
			Object.keys(contact || {})?.length > 0 && // The contact has loaded
			Object.keys(authUser || {})?.length > 0 && // The user has been fetched
			!authUser?.claimed_at && // The user has not claimed the account
			(authUser?.contact_id === contact?.id || authUser?.contact?.id === contact?.id) // The user is the contact
		) {
			openUserVerification();
		}
	}, [contact, authUser]);

	console.log({ contact });

	const openChangeLogin = () => {
		store.dispatch(
			SiteActions.showModal(
				<ChangeLoginModal
					onEmailSaved={email =>
						setContact(x => {
							return { ...x, email };
						})
					}
				/>,
				{
					className: 'modal-small',
				},
			),
		);
	};

	const openListings = useMemo(() => {
		return listings?.open
			.sort((a, b) => {
				if (a.id < b.id) return -1;
				if (a.id > b.id) return 1;
				return 0;
			})
			.reverse();
	}, [listings]);

	const [matches, setMatches] = useState([]);

	// const getClosedListings = ev => {
	// 	if (listings.closed.length) {
	// 		setListings({ ...listings, closed: [] });
	// 		return;
	// 	}
	// 	setState({ ...state, isLoading: true });
	// 	ContactsAPI.getListings(contactID, { is_open: 0 }, response => {
	// 		setState({ ...state, isLoading: false });
	// 		if (!response) {
	// 			dispatch(SiteActions.showAlert('An error ocurred when loading closed jobs. Please try again.', 'error'));
	// 			return;
	// 		}
	// 		setListings({ ...listings, closed: response.data.data });
	// 	});
	// };

	// Get page data on load
	useEffect(() => {
		if (authUser) {
			setIsLoading(true);
			// Load contact profile
			ContactsAPI.getContact(contactID, async (response, err) => {
				if (!response) {
					console.log(err);
					// dispatch(SiteActions.showAlert('An error ocurred when loading the contact. Please try again.', 'error'));
					setIsLoading(false);
					return;
				}
				const newContact = response.data.data;

				setContact(newContact);

				// Fetch the matches for each listing for this contact.
				await Promise.all(
					newContact?.listings?.map(listing =>
						ListingsAPI.getListingMatches({ listingId: listing?.id }).then(matchArray => {
							setMatches(match => [...match, ...matchArray?.filter(x => x?.is_published)]);
						}),
					),
				);
			});
			// // Load open listings
			// ContactsAPI.getListings(contactID, { is_open: 1 }, response => {
			// 	if (!response) {
			// 		dispatch(SiteActions.showAlert('An error ocurred when loading open jobs. Please try again.', 'error'));
			// 		setIsLoading(false);
			// 		return;
			// 	}
			// 	setListings({ ...listings, open: response.data.data });
			// });
			setIsLoading(false);
		}
	}, [!!authUser]);

	if (displayExpiredLinkMessage) {
		return <ExpiredLinkMessage />;
	}

	if (isLoading || !contact || Object.keys(contact).length === 0) {
		return (
			<Page sidebar="none">
				<PageLoading />
			</Page>
		);
	}

	return (
		<Page sidebar="none" topbarContext="contact" centered>
			<Fence width={71.429} minWidth={71.429}>
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Panel>
							<PanelHeader className="spread">
								<div>
									<Typography variant="h2" className="font-lato-bold">
										{`${contact.first_name || ''} ${contact.last_name || ''}`}
									</Typography>
									<Typography>{contact.company || ''}</Typography>
								</div>
								<Button
									variant="outlined"
									color="primary"
									size="small"
									value={1}
									onClick={() => {
										dispatch(
											SiteActions.showModal(<EditContactInfoModal contact={contact} setContact={setContact} />, {
												className: 'modal-medium modal-no-shadow',
											}),
										);
									}}
								>
									Edit
								</Button>
							</PanelHeader>
							<PanelContent>
								<Section>
									<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
										<EmailCircleIcon style={{ marginRight: '0.5rem' }} /> {contact.email || Utility.nb}
									</Typography>
									<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
										<PhoneCircleIcon style={{ marginRight: '0.5rem' }} /> {contact.phone2 || Utility.nb}
									</Typography>
								</Section>
								<Grid container>
									<Grid item xs={6}>
										<ButtonBase className="button-text" onClick={() => openChangeLogin()}>
											Change Login
										</ButtonBase>
									</Grid>
									<Grid item xs={6}>
										<VerticalSpacer height={0.75} />
										<Typography component="p" variant="caption" className="text-right grey-200 text-italic">
											Last updated {Utility.formatDate(contact.user?.updated_at)}
										</Typography>
									</Grid>
								</Grid>
							</PanelContent>
						</Panel>
					</Grid>
					<Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<img src={SignupImage} alt="" style={{ maxWidth: '95%', marginBottom: '-10px' }} />
					</Grid>
				</Grid>
				<VerticalSpacer height={1.5} />
				<Typography variant="h2" className="font-lato-bold">
					Active Jobs
				</Typography>
				<VerticalSpacer height={1.5} />

				<>
					{contact?.listings?.filter(listing => listing.is_open).length > 0 ? (
						contact?.listings
							.filter(listing => listing.is_open)
							.sort((a, b) => {
								return new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime();
							})
							.map(l => <ListingPanel matches={matches} contactID={contactID} listing={l} key={l.id} />)
					) : (
						<>
							<Typography>There are currently no open jobs assigned to you.</Typography>
							<VerticalSpacer height={1} />
						</>
					)}
					{displayClosedListings
						? contact?.listings
								.filter(listing => !listing.is_open)
								.sort((a, b) => {
									return new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime();
								})
								.map(l => <ListingPanel matches={matches} contactID={contactID} listing={l} key={l.id} />)
						: null}
				</>

				{contact?.listings?.filter(x => !x.is_open).length ? (
					<div className="horizontal-center-align">
						<ButtonBase
							className="button-show-more"
							onClick={() => {
								setDisplayClosedListings(!displayClosedListings);
							}}
						>
							{displayClosedListings ? <MinusIcon /> : <PlusIcon />}
							{displayClosedListings ? 'HIDE' : 'VIEW'} CLOSED JOBS
						</ButtonBase>
					</div>
				) : null}
				{state.isLoading && (
					<div className="text-center">
						<CircularProgress />
					</div>
				)}
				{listings.closed.length ? (
					<>
						<VerticalSpacer height={2} />
						<Typography variant="h2" className="font-lato-bold">
							Closed Jobs
						</Typography>
						<VerticalSpacer height={1.5} />
					</>
				) : null}
				{/* {listings.closed
					.sort((a, b) => {
						if (a.id < b.id) return -1;
						if (a.id > b.id) return 1;
						return 0;
					})
					.reverse()
					.map(l => (
						<ListingPanel contactID={contactID} listing={l} closed key={l.id} />
					))} */}
			</Fence>
		</Page>
	);
};

export default ContactProfile;

import React from 'react';
import PropTypes from 'prop-types';

export const Header = ({ children, ...props }) => {
	return (
		<header className="page-header" {...props}>
			{children}
		</header>
	);
};

Header.propTypes = {
	children: PropTypes.node,
};

Header.defaultProps = {
	children: null,
};

export const Left = ({ children }) => {
	return <div className="header-left">{children}</div>;
};

Left.propTypes = {
	children: PropTypes.node,
};

Left.defaultProps = {
	children: null,
};

export const Middle = ({ children }) => {
	return <div className="header-middle">{children}</div>;
};

Middle.propTypes = {
	children: PropTypes.node,
};

Middle.defaultProps = {
	children: null,
};

export const Right = ({ children }) => {
	return <div className="header-right">{children}</div>;
};

Right.propTypes = {
	children: PropTypes.node,
};

Right.defaultProps = {
	children: null,
};

export default Header;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Button, Typography, ButtonBase } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import LeadershipRolesDropdown from '../../../components/form/dropdowns/LeadershipRolesDropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';
import EditJobBlockModal from './EditJobBlockModal';
import LocationPinIcon from '../../../components/icons/SectionIcons/LocationPinIcon';
import LeadershipAllocationDropdown from '../../../components/form/dropdowns/LeadershipAllocationDropdown';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import SpecialtyDropdown from '../../../components/form/dropdowns/SpecialtyDropdown';
import WorkVisaDropdown from '../../../components/form/dropdowns/WorkVisaDropdown';
import TravelWillingnessDropdown from '../../../components/form/dropdowns/TravelWillingnessDropdown';
import EmploymentPreferencesDropdown from '../../../components/form/dropdowns/EmploymentPreferencesDropdown';
import WorkLocationDropdown from '../../../components/form/dropdowns/WorkLocationDropdown';
import OfferRelocationDropdown from '../../../components/form/dropdowns/OfferRelocationDropdown';
import WillingToRelocateDropdown from '../../../components/form/dropdowns/WillingToRelocateDropdown';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import EducationDropdown from '../../../components/form/dropdowns/EducationDropdown';
import AreasOfStudyDropdown from '../../../components/form/dropdowns/AreasOfStudyDropdown';
import SalaryDropdown from '../../../components/form/dropdowns/SalaryDropdown';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';
import VerticalSpacer from '../../../components/VerticalSpacer';
import PlusIcon from '../../../components/icons/PlusIcon';

const EditModal = ({ job, setJob }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="Location"
			icon={LocationPinIcon}
			renderContents={({ updateJob, updatedJob, setUpdatedJob }) => {
				const workLocationPreferenceValue = useMemo(
					() => updatedJob?.work_location_preferences?.map(item => (item.id ? item.id : item)),
					[updatedJob.work_location_preferences],
				);

				const hasLocalSelection = useMemo(() => workLocationPreferenceValue?.some(pid => [2, 3, 4, 5].includes(pid)), [
					workLocationPreferenceValue,
				]);

				const hasRemoteSelection = useMemo(() => workLocationPreferenceValue?.includes(1), [
					workLocationPreferenceValue,
				]);

				const setListingLocations = (index, name, value) => {
					const updatedOfficeLocations = [...updatedJob.office_locations];
					updatedOfficeLocations[index] = { ...(updatedJob.office_locations || [])[index], [name]: value };
					setUpdatedJob({
						...updatedJob,
						office_locations: updatedOfficeLocations,
					});
				};

				const addOfficeLocation = () => {
					const updatedOfficeLocations = [...(updatedJob.office_locations || []), { city: null, state_id: null }];

					setUpdatedJob({
						...updatedJob,
						office_locations: updatedOfficeLocations,
					});
				};

				const removeOfficeLocation = location => {
					const updatedOfficeLocations = updatedJob.office_locations.filter(
						l => !(l.city === location.city && l.state_id === location.state_id),
					);
					setUpdatedJob({
						...updatedJob,
						office_locations: updatedOfficeLocations,
					});
				};

				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Work Location Preference">
									<WorkLocationDropdown
										variant="outlined"
										name="work_location_preferences"
										value={(Utility.getFromObj(updatedJob, 'work_location_preferences') || []).map(p =>
											p.id ? p.id : p,
										)}
										placeholder={updatedJob?.work_location_preferences?.length > 0 ? '' : 'Select all that apply'}
										onChange={updateJob}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Approved Remote Locations" removeMargin required={hasRemoteSelection}>
									<StatesDropdown
										variant="outlined"
										name="remote_states"
										placeholder={updatedJob?.remote_states?.length > 0 ? '' : 'Select all that apply'}
										value={
											hasRemoteSelection
												? updatedJob?.remote_states?.map(item => (item.state_id ? item.state_id : item))
												: null
										}
										onChange={updateJob}
										disabled={!hasRemoteSelection}
										fullWidth
										multiple
										useID
										error={
											hasRemoteSelection &&
											!updatedJob?.remote_states?.map(item => (item.state_id ? item.state_id : item)).length
										}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Eligible Office Sites" required={hasLocalSelection}>
									{(updatedJob.office_locations?.length
										? updatedJob.office_locations
										: [{ city: null, state_id: null }]
									).map((location, index) => (
										<Grid container spacing={2}>
											<Grid item xs={7}>
												<TextField
													variant="outlined"
													name="city"
													placeholder="City"
													value={updatedJob?.office_locations[index]?.city || ''}
													onChange={e => setListingLocations(index, e.target.name, e.target.value)}
													disabled={!hasLocalSelection}
													error={hasLocalSelection && !updatedJob?.office_locations[index]?.city}
													fullWidth
												/>
											</Grid>
											<Grid item xs={4}>
												<StatesDropdown
													variant="outlined"
													name="state_id"
													placeholder="State"
													value={updatedJob?.office_locations[index]?.state_id}
													onChange={e => setListingLocations(index, e.target.name, e.target.value)}
													disabled={!hasLocalSelection}
													error={hasLocalSelection && !updatedJob?.office_locations[index]?.state_id}
													fullWidth
													useAbbreviation
													useID
												/>
											</Grid>
											<Grid
												item
												xs={1}
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<ButtonBase className="button-cancel" onClick={() => removeOfficeLocation(location)}>
													<Icons.Cancel style={{ marginTop: -3 }} />
												</ButtonBase>
											</Grid>
										</Grid>
									))}
									<VerticalSpacer height={0.5} />
									<ButtonBase
										className="button-show-more"
										onClick={() => addOfficeLocation()}
										disabled={!hasLocalSelection}
									>
										<PlusIcon />
										ADD LOCATION
									</ButtonBase>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Offer Candidate Relocation" required={hasLocalSelection}>
									<OfferRelocationDropdown
										variant="outlined"
										name="offer_relocation"
										value={hasLocalSelection ? updatedJob.offer_relocation : null}
										placeholder="Choose from our list"
										onChange={updateJob}
										disabled={!hasLocalSelection}
										error={hasLocalSelection && !updatedJob.offer_relocation}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								{/* <div style={{ marginTop: -10 }} /> */}
								<AddAdditionalInfo
									additionalInfo={updatedJob.additional_work_location_details}
									setAdditionalInfo={newAdditionalInfo => {
										updateJob({
											target: {
												name: 'additional_work_location_details',
												value: newAdditionalInfo,
											},
										});
									}}
								/>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;

import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as Icons from '@material-ui/icons';
import { Tooltip, Button, Box } from '@material-ui/core';
import { ActionCreators as SiteActions } from '../../store/Site';

import SmoothStarIcon from '../icons/SmoothStarIcon';
import OpenResumeIcon from '../icons/OpenResumeIcon';
import { InterestIcon } from '../modals/RateJobClientInterestModal';
import RateInterestModal from '../modals/RateMatchInterestModal';

const CustomButton = ({
	title,
	isActive,
	setActive,
	url,
	graphMatch,
	buttonVariant, // ranking | resume | details
	...props
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const { user: authUser, modal } = useSelector(state => state.site, shallowEqual);
	const dispatch = useDispatch();
	const isModalShowing = modal?.show && modal?.isDraggable;

	const handleClick = () => {
		if (buttonVariant === 'ranking') {
			dispatch(
				SiteActions.showModal(<RateInterestModal graphMatch={graphMatch} />, {
					className: 'modal-small',
					isDraggable: true,
				}),
			);
		}
		if (buttonVariant === 'resume') {
			window.open(url, '_blank', 'width=600,height=800');
		}
		if (buttonVariant === 'details') {
			setActive(!isActive);
		}
	};

	const currentUserInterestLevelId = graphMatch?.fit_rankings?.find(ranking => ranking?.created_by === authUser?.id)
		?.match_fit_ranking?.id;

	const getIcon = () => {
		if (buttonVariant === 'ranking') {
			return (
				<>
					{currentUserInterestLevelId ? (
						<InterestIcon
							interestLevelId={currentUserInterestLevelId}
							color={isHovered || isModalShowing ? '#0076FF' : '#687180'}
						/>
					) : (
						<SmoothStarIcon color={isHovered || isModalShowing ? '#0076FF' : '#687180'} />
					)}
				</>
			);
		}
		if (buttonVariant === 'resume') {
			return <OpenResumeIcon color={isActive || isHovered ? '#0076FF' : '#687180'} />;
		}
		return (
			<Icons.ChevronRight
				style={{
					fontSize: 26,
					transform: `rotate(${buttonVariant === 'details' && isActive ? 90 : 0}deg)`,
					transition: 'transform .2s ease',
					color: isActive || isHovered ? '#0076FF' : '#687180',
					margin: -6,
				}}
			/>
		);
	};

	return (
		<Tooltip title={title} placement="top" arrow>
			<Button
				variant="text"
				color="primary"
				type="button"
				onClick={handleClick}
				onMouseOver={() => setIsHovered(true)}
				onMouseOut={() => setIsHovered(false)}
				style={{
					margin: '0px 5px',
					padding: 6,
					minWidth: '32px',
					height: '100%',
					borderRadius: 4,
					backgroundColor: isActive || isHovered ? '#EBF3FE' : '#F3F6F9',
				}}
				{...props}
			>
				{getIcon()}
			</Button>
		</Tooltip>
	);
};

const CandidateHeaderButtonGroup = ({ isDetailsShown, setIsDetailsShown, graphMatch, currentCandidate }) => {
	const resumeUrl = `/view-resume?id=${encodeURIComponent(currentCandidate?.id)}`;

	return (
		<Box>
			{graphMatch && <CustomButton title="Rate Interest" graphMatch={graphMatch} buttonVariant="ranking" />}
			<CustomButton title="Resume" url={resumeUrl} buttonVariant="resume" />
			<CustomButton title="Details" isActive={isDetailsShown} setActive={setIsDetailsShown} buttonVariant="details" />
		</Box>
	);
};

export default CandidateHeaderButtonGroup;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function StarIcon({ style, color = 'currentColor', size = 19, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			style={style}
			className={className}
			fill={color}
		>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26zm0-2.292l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275l-2.038 4.42-4.833.572 3.573 3.305-.949 4.773L12 15.968z" />
		</svg>
	);
}

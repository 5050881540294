import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import * as ContactsAPI from '../scripts/contacts';
import { ActionCreators as SiteActions } from '../store/Site';

const useDocumentTitle = (title = 'Nauwork Portal') => {
	useEffect(() => {
		if (title) {
			document.title = title;
		}
	}, [title]);
};

export default useDocumentTitle;

import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

// variants = primary, textSecondary
const AddAnotherButton = ({ onClick, text = 'Add Another', variant = 'primary' }) => (
	<Button
		variant="text"
		color="#000"
		size="small"
		style={{
			margin: 0,
			padding: 4,
			minWidth: 0,
			borderRadius: 8,
		}}
		onClick={onClick}
	>
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<AddCircleOutline size={14} style={{ marginRight: 6, color: variant === 'primary' ? '#0076ff' : '#aaa' }} />
			<Typography variant="button" color={variant} style={{ marginTop: 1, fontWeight: '700' }}>
				{text}
			</Typography>
		</div>
	</Button>
);

export default AddAnotherButton;

import Axios from 'axios';
import API from './api';
// import * as Utility from './utility';

export const getCurrentUser = callback => {
	API.get('/user')
		.then(callback)
		.catch(err => callback(false, err));
};

export const signup = (parameters, callback) => {
	Axios.post(`${process.env.REACT_APP_API_ADR}/signup`, parameters)
		.then(callback)
		.catch(err => callback(false, err));
};

export const contactSignup = (parameters, callback) => {
	Axios.post(`${process.env.REACT_APP_API_ADR}/contact-signup`, parameters)
		.then(callback)
		.catch(err => callback(false, err));
};

export const jwtLogin = (url, callback) => {
	Axios.get(url)
		.then(callback)
		.catch(err => callback(false, err));
};

export const verifyUser = (parameters, callback) => {
	API.post('/user-account/verify', parameters)
		.then(callback)
		.catch(err => callback(false, err));
};

export const changeLogin = (parameters, callback) => {
	API.post('/user-account/change-login', parameters)
		.then(callback)
		.catch(err => callback(false, err));
};

export const changePassword = (parameters, callback) => {
	API.post('/user-account/change-password', parameters)
		.then(callback)
		.catch(err => callback(false, err));
};

export const verifyEmailToken = (parameters, callback) => {
	API.post('/user-account/verify-email-token', parameters)
		.then(callback)
		.catch(err => callback(false, err));
};

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import EditCandidateBlockModal from './EditCandidateBlockModal';
import ClipboardIcon from '../../../components/icons/SectionIcons/ClipboardIcon';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import OwnersDropdown from '../../../components/form/dropdowns/OwnersDropdown';
import CandidateStatusDropdown from '../../../components/form/dropdowns/CandidateStatusDropdown';
import CandidateStatusTypesDropdown from '../../../components/form/dropdowns/CandidateStatusTypesDropdown';

const EditModal = ({ candidate, setCandidate }) => {
	return (
		<EditCandidateBlockModal
			candidate={candidate}
			setCandidate={setCandidate}
			title="Candidate Info"
			icon={ClipboardIcon}
			renderContents={({ updateCandidate, updatedCandidate }) => {
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="First Name">
									<TextField
										variant="outlined"
										name="first_name"
										value={Utility.getFromObj(updatedCandidate, 'first_name')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Last Name">
									<TextField
										variant="outlined"
										name="last_name"
										value={Utility.getFromObj(updatedCandidate, 'last_name')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Primary E-mail">
									<TextField
										variant="outlined"
										name="email"
										value={Utility.getFromObj(updatedCandidate, 'email')}
										onChange={updateCandidate}
										fullWidth
										disabled
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="LinkedIn">
									<TextField
										variant="outlined"
										name="linkedin_url"
										value={Utility.getFromObj(updatedCandidate, 'linkedin_url')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Primary Phone">
									<TextField
										variant="outlined"
										name="phone"
										value={updatedCandidate.phone}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="City">
									<TextField
										variant="outlined"
										name="city"
										value={updatedCandidate.city || ''}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="State">
									<StatesDropdown
										variant="outlined"
										name="state"
										value={Utility.getFromObj(updatedCandidate, 'state')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Owner">
									<OwnersDropdown
										variant="outlined"
										name="owner_id"
										value={updatedCandidate?.owner_id}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Status">
									<CandidateStatusDropdown
										variant="outlined"
										name="candidate_status"
										value={updatedCandidate?.candidate_status}
										onChange={updateCandidate}
										fullWidth
										disabled
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Status Type">
									<CandidateStatusTypesDropdown
										variant="outlined"
										name="status_type_id"
										value={updatedCandidate?.status_type_id}
										onChange={updateCandidate}
										fullWidth
										disabled
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditModal;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function StarBadgeIcon({ style, color = "currentColor", size = 19, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 19 19"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-803 -745)">
					<g transform="translate(804 746)">
						<g>
							<path d="M3.126 10.7998L-4.4408921e-16 13.9998 2.5 15.4998 4 17.9998 7.899 13.9048"/>
							<path d="M10.1003 13.905L13.9993 18 15.4993 15.5 17.9993 14 14.8733 10.8"/>
							<g transform="translate(2)">
								<path d="M14 7A7 7 0 110 7a7 7 0 0114 0z"/>
								<path d="M7 9.584l1.796.944a.477.477 0 00.693-.503l-.344-2L10.6 6.609a.477.477 0 00-.264-.814l-2.01-.292-.899-1.821a.476.476 0 00-.854 0l-.899 1.821-2.01.292a.477.477 0 00-.264.814l1.454 1.416-.343 2a.477.477 0 00.692.503L7 9.584h0z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React from 'react';
import { Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

import Page from '../components/page/Page';
import Fence from '../components/Fence';
import VerticalSpacer from '../components/VerticalSpacer';
import HorizontalSpacer from '../components/HorizontalSpacer';

const Incompatible = () => {
	return (
		<Page topbar="center" sidebar="none" centered skipData>
			<Fence width="44">
				<Typography variant="h2" className="font-lato-bold" align="center">
					NauMatch Portal Access
				</Typography>
				<VerticalSpacer height="3" />
				<Typography align="center" style={{ fontSize: '3rem' }}>
					<Icons.PhoneAndroid fontSize="inherit" className="blue-500" />
					<HorizontalSpacer width={1} />
					<Icons.ArrowForward fontSize="inherit" className="blue-500" />
					<HorizontalSpacer width={1} />
					<Icons.Laptop fontSize="inherit" className="blue-500" />
				</Typography>
				<VerticalSpacer height="3" />
				<Typography variant="subtitle1" component="p" align="center">
					The NauMatch Portal is not compatible with mobile devices. Please open the NauMatch link on a PC
					to continue.
				</Typography>
			</Fence>
		</Page>
	);
};

export default Incompatible;

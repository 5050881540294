import React, { useMemo } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import useListing from '../../hooks/useListing';
import useContact from '../../hooks/useContact';

const ContextualMenu = () => {
	const listingMatch = useRouteMatch('/job/:id/profile');
	const contactMatch = useRouteMatch('/contact/:id/profile');

	const { listing, loading: listingLoading, error: listingError } = useListing({ id: listingMatch?.params?.id });

	const listingContactId = useMemo(() => {
		return listing?.contacts[0]?.id;
	}, [listing]);

	const { contact, loading: contactLoading, error: contactError } = useContact({
		id: contactMatch?.params?.id || listingContactId,
	});

	return (contactMatch || listingMatch) && contact?.id ? (
		<div className="topbar-contextual-menu">
			<NavLink
				to={`/contact/${contact.id}/profile`}
				className={isActive => `topbar-contextual-menu-link ${isActive ? 'topbar-contextual-menu-link-active' : ''}`}
			>
				Jobs Dashboard
			</NavLink>
		</div>
	) : null;
};

export default ContextualMenu;

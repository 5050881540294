const createDynamicElasticQuery = ({ object = 'candidate', currentFilter, query, objectPrefix = '' }) => {
	if (object !== 'candidate') {
		return null;
	}

	if (currentFilter.filters?.total_leadership_years_range) {
		currentFilter.filters.total_leadership_years_range.values.forEach(filterValue => {
			console.log({ filterValue });
			query.bool.must.push({
				range: {
					[`${objectPrefix}total_leadership_years`]: {
						gte: filterValue?.min ? parseFloat(filterValue?.min) : 0,
						lte: filterValue?.max ? parseFloat(filterValue?.max) : 99,
					},
				},
			});
		});
	}

	if (currentFilter.filters?.professional_experience_years_range) {
		currentFilter.filters.professional_experience_years_range.values.forEach(filterValue => {
			console.log({ filterValue });
			query.bool.must.push({
				range: {
					[`${objectPrefix}professional_experience_years`]: {
						gte: filterValue?.min ? parseFloat(filterValue?.min) : 0,
						lte: filterValue?.max ? parseFloat(filterValue?.max) : 99,
					},
				},
			});
		});
	}

	if (currentFilter.filters?.status) {
		currentFilter.filters.status.values.forEach(filterValue => {
			if (filterValue?.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}candidate_status`]: filterValue?.value,
					},
				});
			}
		});
	}

	if (currentFilter.filters?.owner) {
		currentFilter.filters.owner?.values?.forEach(filterValue => {
			if (filterValue?.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}owner`]: filterValue?.value,
					},
				});
			}
		});
	}
	if (currentFilter.filters.name) {
		currentFilter.filters.name.values.forEach(filterValue => {
			const nameQuery = filterValue?.value;

			if (!nameQuery?.length) {
				return null;
			}

			const filterQuery = {
				bool: {
					should: [],
					minimum_should_match: 1,
				},
			};
			// Split the name
			const firstName = nameQuery?.split(' ')[0];
			const lastName = nameQuery?.split(' ')[1];

			console.log({ firstName, lastName });
			if (firstName?.length && lastName?.length) {
				filterQuery.bool.should = filterQuery.bool.should.concat([
					{
						wildcard: {
							[`${objectPrefix}first_name`]: {
								value: `*${firstName}*`,
								case_insensitive: true,
							},
						},
					},
					{
						wildcard: {
							[`${objectPrefix}last_name`]: {
								value: `*${lastName}*`,
								case_insensitive: true,
							},
						},
					},
				]);
				filterQuery.bool.minimum_should_match = 2;
			} else {
				filterQuery.bool.should = filterQuery.bool.should.concat([
					{
						wildcard: {
							[`${objectPrefix}first_name`]: {
								value: `*${nameQuery}*`,

								case_insensitive: true,
							},
						},
					},
					{
						wildcard: {
							[`${objectPrefix}last_name`]: {
								value: `*${nameQuery}*`,

								case_insensitive: true,
							},
						},
					},
					{
						wildcard: {
							[`${objectPrefix}id`]: `*${nameQuery}*`,
						},
					},
					{
						wildcard: {
							[`${objectPrefix}vendor_candidate_id`]: `*${nameQuery}*`,
						},
					},
				]);
				filterQuery.bool.minimum_should_match = 1;
			}

			query.bool.must.push(filterQuery);
			return null;
		});
	}

	if (currentFilter.filters?.skill_story) {
		// Loop through each skill block filter
		currentFilter.filters?.skill_story?.values?.forEach(skillFilter => {
			const { type, secondaryType } = skillFilter;

			// If the filter has skills selected...
			if (skillFilter?.value?.length) {
				skillFilter?.value?.forEach(x => {
					const nestedQuery = {
						nested: {
							path: [`${objectPrefix}candidate_skills`],
							query: {
								bool: {
									must: [],
								},
							},
						},
					};

					const targetArray = nestedQuery.nested.query.bool.must;

					// Filter for the skill
					targetArray.push({
						term: { [`${objectPrefix}candidate_skills.skill_id`]: x },
					});

					// Filter for the skill experience level
					if (secondaryType === 'hero') {
						targetArray.push({
							term: { 'candidate_skills.get_the_job': true },
						});
					} else if (secondaryType === 'enjoy') {
						targetArray.push({
							term: { 'candidate_skills.enjoy_the_job': true },
						});
					} else if (secondaryType === 'learn') {
						targetArray.push({
							term: { 'candidate_skills.learn_on_job': true },
						});
					} else if (secondaryType === 'improve') {
						targetArray.push({
							term: { 'candidate_skills.improve_on_job': true },
						});
					}

					// Handle the type of filter
					query.bool[
						type === 'require-all'
							? 'must'
							: type === 'include-any'
							? 'should'
							: type === 'exclude'
							? 'must_not'
							: 'must'
					].push(nestedQuery);
				});
			}
		});
	}

	if (currentFilter.filters?.skills) {
		// Loop through each skill block filter
		currentFilter.filters?.skills?.values?.forEach(skillFilter => {
			const { type, secondaryType } = skillFilter;

			// If the filter has skills selected...
			if (skillFilter?.value?.length) {
				skillFilter?.value?.forEach(x => {
					const nestedQuery = {
						nested: {
							path: [`${objectPrefix}candidate_skills`],
							query: {
								bool: {
									must: [],
								},
							},
						},
					};

					const targetArray = nestedQuery.nested.query.bool.must;

					// Filter for the skill
					targetArray.push({
						term: { [`${objectPrefix}candidate_skills.skill_id`]: x },
					});

					// Filter for the skill experience level
					if (['best', 'advanced', 'intermediate', 'basic'].includes(secondaryType)) {
						targetArray.push({ term: { 'candidate_skills.experience_level.name': secondaryType } });
					}

					// Handle the type of filter
					query.bool[
						type === 'require-all'
							? 'must'
							: type === 'include-any'
							? 'should'
							: type === 'exclude'
							? 'must_not'
							: 'must'
					].push(nestedQuery);
				});
			}
		});
	}

	return null;
};

export default createDynamicElasticQuery;

import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';
import TagDropdown from './TagDropdown';
import * as Utility from '../../../scripts/utility';

const SkillDropdown = ({ override, experience, author, tags, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const [skillList, setSkillList] = React.useState([]);

	// const getCategories = () => {
	React.useEffect(() => {
		let skills = override || [];

		// Filter by experience
		if (experience) skills = skills.filter(s => [4, 5].includes(s.skill_experience_id));

		// Get only the skill data
		skills = skills.map(s => (s.skill ? s.skill : s));

		// Remove duplicates
		skills = Utility.uniqueIDs(skills);

		// Filter out skills under review
		if (!override) skills = skills.filter(s => !s.needs_review);

		// Sort alphabetically
		skills = skills
			.sort((el1, el2) => {
				if (el1.label?.toUpperCase() < el2.label?.toUpperCase()) return -1;
				if (el1.label?.toUpperCase() > el2.label?.toUpperCase()) return 1;
				return 0;
			})
			.sort((el1, el2) => {
				if (el1.category_label?.toUpperCase() < el2.category_label?.toUpperCase()) return 1;
				if (el1.category_label?.toUpperCase() > el2.category_label?.toUpperCase()) return -1;
				return 0;
			});

		// return skills;
		// };
		setSkillList(skills);
	}, [data?.skills?.length, override?.length, experience]);

	// Disable if there are supposed to be override skills, but they haven't loaded yet.
	const isDisabled = override && !override?.length;

	return tags ? (
		<TagDropdown
			data={skillList}
			disabled={isDisabled}
			groupBy={s => s.category_label}
			placeholder={isDisabled ? 'Loading...' : props?.placeholder || 'Type to search...'}
			{...props}
		/>
	) : (
		<Dropdown
			data={skillList}
			disabled={override && !override?.length}
			groupBy={s => s.category_label}
			placeholder={isDisabled ? 'Loading...' : props?.placeholder || 'Type to search...'}
			{...props}
		/>
	);
};

SkillDropdown.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	override: PropTypes.arrayOf(PropTypes.object),
	experience: PropTypes.arrayOf(PropTypes.number),
	author: PropTypes.number,
	tags: PropTypes.bool,
};

SkillDropdown.defaultProps = {
	override: null,
	experience: null,
	author: null,
	tags: false,
};

export default SkillDropdown;

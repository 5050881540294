import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import * as Utility from '../../../scripts/utility';

const PhoneInput = ({ value, ...props }) => {
	return <TextField value={Utility.formatPhone(value)} {...props} />;
};

PhoneInput.propTypes = {
	value: PropTypes.string,
};

PhoneInput.defaultProps = {
	value: '',
};

export default PhoneInput;

import React from 'react';
import PropTypes from 'prop-types';

const Fence = ({ height, width, minWidth, children, centered, className, style, ...props }) => {
	return (
		<div
			className={`fence ${centered ? 'centered' : ''} ${className}`}
			style={{
				maxHeight: height ? `${height}rem` : 'auto',
				maxWidth: width ? `${width}rem` : 'auto',
				minWidth: minWidth ? `${minWidth}rem` : 'auto',
				...style,
			}}
		>
			{children}
		</div>
	);
};

Fence.propTypes = {
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	children: PropTypes.node,
	centered: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.shape({}),
};

Fence.defaultProps = {
	height: null,
	width: null,
	minWidth: null,
	children: null,
	centered: false,
	className: '',
	style: {},
};

export default Fence;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useRouteMatch } from 'react-router';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { Button, Typography, TextField, CircularProgress, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CheckmarkCircleIcon from '../icons/CheckmarkCircleIcon';
import LabeledField from '../form/LabeledField';
import PhoneInput from '../form/inputs/PhoneInput';
import LoadingButton from '../form/LoadingButton';
import StatesDropdown from '../form/dropdowns/StatesDropdown';
import { ActionCreators as SiteActions } from '../../store/Site';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import * as UsersAPI from '../../scripts/user';
import * as ContactsAPI from '../../scripts/contacts';
import {
	validatePasswords,
	PasswordRequirementsValidation,
	PasswordMatchingValidation,
} from '../form/PasswordValidation';

const VerifyAccountModal = () => {
	const store = useStore();
	const params = useParams();
	const profileID = params.id;
	const { alert } = useSelector(state => state.site, shallowEqual);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllFields, setShowAllFields] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [profile, setProfile] = useState({});
	const [showSuccess, setShowSuccess] = useState(false);
	const candidateMatch = useRouteMatch('/candidate/:id/profile');
	const contactProfileMatch = useRouteMatch('/contact/:id/profile');
	const updateProfile = ev => {
		setProfile({ ...profile, [ev.target.name]: ev.target.value });
	};

	const handleSubmit = () => {
		if (!isSubmitting) {
			setIsSubmitting(true);
			UsersAPI.verifyUser(
				{
					first_name: profile.first_name || undefined,
					last_name: profile.last_name || undefined,
					email: profile.email,
					phone: profile.phone || undefined,
					state_id: profile.state_id || undefined,
					city: profile.city || undefined,
					password,
					password_confirmation: passwordConfirmation,
				},
				(response, err) => {
					setIsSubmitting(false);
					if (!response) {
						store.dispatch(
							SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
						);
						setIsLoading(false);
						return;
					}
					store.dispatch(SiteActions.updateUser({ ...response.data.data }));
					store.dispatch(SiteActions.hideModal());
				},
			);
		}
	};

	const { data, user: authUser } = useSelector(state => state.site, shallowEqual);

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		if (candidateMatch) {
			CandidatesAPI.getCandidate(profileID, response => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
					);
					setIsLoading(false);
					return;
				}
				setProfile(response.data.data);
				setIsLoading(false);
			});
		} else if (contactProfileMatch) {
			ContactsAPI.getContact(contactProfileMatch.params.id, response => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('An error ocurred when loading the contact. Please try again.', 'error'),
					);
					setIsLoading(false);
					return;
				}
				const contact = response?.data?.data;

				console.log({ contact });

				setProfile({
					city: contact?.city,
					state: contact?.state,
					first_name: contact?.first_name,
					last_name: contact?.last_name,
					phone: contact?.phone2,
					email: contact?.email,
				});
				setIsLoading(false);
			});
		}
	}, []);

	if (isLoading) {
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	}

	if (showSuccess) {
		return (
			<div className="text-center">
				<CheckmarkCircleIcon size={48} className="teal-100" />
				<Typography variant="h2" className="font-lato-bold" style={{ paddingTop: 10, paddingBottom: 5 }}>
					Your Account Has Been Verified
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={() => store.dispatch(SiteActions.hideModal())}
					style={{ marginLeft: 0 }}
				>
					Close
				</Button>
			</div>
		);
	}

	return (
		<div>
			<Typography variant="h2" className="font-lato-bold" style={{ paddingBottom: 5 }}>
				Verify Your Account
			</Typography>
			<Typography variant="body1" className="grey-300" style={{ paddingBottom: 20 }}>
				Please confirm that your contact info is correct and create a password to secure your account.
			</Typography>
			{showAllFields ? (
				<>
					<TextField
						variant="outlined"
						name="first_name"
						value={profile.first_name}
						placeholder="First Name"
						onChange={updateProfile}
						fullWidth
						style={{ marginBottom: 20 }}
					/>
					<TextField
						variant="outlined"
						name="last_name"
						value={profile.last_name}
						placeholder="Last Name"
						onChange={updateProfile}
						fullWidth
						style={{ marginBottom: 20 }}
					/>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<TextField
								variant="outlined"
								name="city"
								value={profile.city}
								onChange={updateProfile}
								placeholder="City"
								fullWidth
								style={{ marginBottom: 20 }}
							/>
						</Grid>
						<Grid item xs={4}>
							<StatesDropdown
								variant="outlined"
								name="state"
								placeholder="State"
								value={profile.state}
								onChange={updateProfile}
								style={{ marginBottom: 19 }}
								autoComplete="off"
								fullWidth
								useID
								useAbbreviation
							/>
						</Grid>
					</Grid>
					<TextField
						variant="outlined"
						name="email"
						value={profile.email}
						onChange={updateProfile}
						placeholder="Email"
						fullWidth
						style={{ marginBottom: 20 }}
						disabled
					/>
					<PhoneInput
						variant="outlined"
						name="phone"
						value={profile.phone}
						onChange={updateProfile}
						fullWidth
						placeholder="Phone number"
						style={{ marginBottom: 30 }}
					/>
				</>
			) : (
				<>
					<Typography variant="body1" className="text-bold" style={{ paddingBottom: 15 }}>
						{profile.first_name} {profile.last_name}
						<br />
						{profile?.city || profile?.state ? (
							<>
								{profile.city ? `${profile.city}, ` : null}
								{profile.state}
								<br />
							</>
						) : null}
						{profile.email}
						<br />
					</Typography>

					<Button
						variant="outlined"
						color="primary"
						size="small"
						onClick={() => setShowAllFields(true)}
						style={{ marginLeft: 0, marginBottom: 50 }}
					>
						Edit
					</Button>
				</>
			)}
			<LabeledField label="Create Password" removeMargin>
				<p>The password must contain at least eight characters.</p>
				<TextField
					type="password"
					variant="outlined"
					name="password"
					value={password}
					onChange={ev => setPassword(ev.target.value)}
					fullWidth
					style={{ marginBottom: 20 }}
				/>
			</LabeledField>
			<PasswordRequirementsValidation password={password} />
			<div style={{ height: 20 }} />
			<LabeledField label="Confirm Password" removeMargin>
				<TextField
					type="password"
					variant="outlined"
					name="password_confirmation"
					value={passwordConfirmation}
					onChange={ev => setPasswordConfirmation(ev.target.value)}
					fullWidth
					error={password !== passwordConfirmation}
				/>
			</LabeledField>

			<div style={{ height: 20 }} />
			<PasswordMatchingValidation password={password} passwordConfirmation={passwordConfirmation} />
			<div style={{ height: 20 }} />

			{alert.show ? (
				<Alert
					severity={alert.color}
					onClose={ev => store.dispatch(SiteActions.hideAlert())}
					style={{ marginBottom: 32 }}
				>
					{alert.message}
				</Alert>
			) : null}
			<LoadingButton
				disabled={!validatePasswords(password, passwordConfirmation)?.isValid}
				loading={isSubmitting || isLoading}
				variant="contained"
				color="primary"
				onClick={() => handleSubmit()}
				style={{ width: '100%', marginLeft: 0 }}
			>
				Submit
			</LoadingButton>
		</div>
	);
};

VerifyAccountModal.propTypes = {};

VerifyAccountModal.defaultProps = {};

export default VerifyAccountModal;

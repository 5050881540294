import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import * as Utility from '../../../../../scripts/utility';
import YearsDropdown from '../../../../form/dropdowns/YearsDropdown';
import Dropdown from '../../../../form/dropdowns/Dropdown';
import LeadershipAllocationDropdown from '../../../../form/dropdowns/LeadershipAllocationDropdown';

const LeadershipForm = ({ candidate, setCandidate, data }) => {
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Leadership Experience
			</Typography>
			<VerticalSpacer height={1} />
			<Fence width={25}>
				<LabeledField label="Leadership Experience Level" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<Dropdown
						variant="outlined"
						name="recent_leadership_role_id"
						value={Utility.getFromObj(candidate, 'recent_leadership_role_id')}
						onChange={setCandidate}
						placeholder="Choose from our list"
						data={data.leadershipExperienceRoles}
						fullWidth
					/>
				</LabeledField>
				<LabeledField
					label="Total Years of Leadership Experience"
					info="Add up all of your years in leadership and enter the total."
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<YearsDropdown
						variant="outlined"
						name="total_leadership_years"
						value={Utility.getFromObj(candidate, 'total_leadership_years')}
						onChange={setCandidate}
						placeholder="Choose from our list"
						disabled={candidate.recent_leadership_role_id === 1 || candidate.recent_leadership_role_id === null}
						fullWidth
					/>
				</LabeledField>
				<LabeledField label="Current or Most Recent Leadership Title" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<TextField
						variant="outlined"
						name="leadership_title"
						value={Utility.getFromObj(candidate, 'leadership_title')}
						onChange={setCandidate}
						placeholder="Enter leadership title"
						disabled={candidate.recent_leadership_role_id === 1 || candidate.recent_leadership_role_id === null}
						fullWidth
					/>
				</LabeledField>
				<LabeledField
					label="Leadership Allocation"
					info="In your current (or most recent) leadership
					 role how much of your time do you typically spend on leadership responsibilities?"
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<LeadershipAllocationDropdown
						variant="outlined"
						name="leadership_allocation_type_id"
						value={
							candidate.recent_leadership_role_id === 1
								? null
								: Utility.getFromObj(candidate, 'leadership_allocation_type_id')
						}
						onChange={setCandidate}
						placeholder="Choose from our list"
						disabled={candidate.recent_leadership_role_id === 1 || candidate.recent_leadership_role_id === null}
						fullWidth
						isIntake
					/>
				</LabeledField>
			</Fence>
		</>
	);
};

export default LeadershipForm;

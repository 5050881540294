import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Button, Typography, RadioGroup, Radio, FormControlLabel, Chip, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import Fence from '../../../components/Fence';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import ResumeIcon from '../../../components/icons/ResumeIcon';
import UploadIcon from '../../../components/icons/UploadIcon';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';

const EditDescriptionModal = ({ listing, setListing, setDescriptions }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [uploadOrPasteDescription, setUploadOrPasteDescription] = useState('1');
	const [updatedListing, setUpdatedListing] = useState(listing);

	const updateListing = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedListing({ ...updatedListing, ...newValue });
	};

	const onJobOrderUpload = (response, err) => {
		if (!response) {
			setIsSaving(false);
			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'file')) {
				store.dispatch(
					SiteActions.showAlert(
						`An error ocurred saving the job description.
							${Utility.getFromObj(err, 'response', 'data', 'errors', 'file', 0)}`,
						'error',
					),
				);
			} else {
				store.dispatch(
					SiteActions.showAlert('An error ocurred saving the job description. Please try again.', 'error'),
				);
			}
			return;
		}

		ListingsAPI.getListingDescriptions(
			updatedListing.id,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			res => {
				setDescriptions(res.data.data);
				setListing(updatedListing);
				dispatch(SiteActions.hideModal());
			},
		);
	};

	const saveListing = () => {
		setIsSaving(true);
		if (updatedListing.descriptionFile) {
			ListingsAPI.uploadListingJobOrder(updatedListing.id, updatedListing.descriptionFile, onJobOrderUpload);
		} else if (updatedListing.description) {
			ListingsAPI.uploadListingTextJobOrder(updatedListing.id, updatedListing.description, onJobOrderUpload);
		}
	};

	return (
		<>
			<Panel Icon={<ResumeIcon size={32} className="teal-100" />}>
				<PanelHeader className="spread">
					<Typography variant="body2" component="h2" className="text-bold" style={{ paddingTop: 4 }}>
						Job Description
					</Typography>
				</PanelHeader>
				<PanelContent>
					<Fence width={55}>
						<RadioGroup
							row
							aria-label="Upload or paste text?"
							name="upload_or_paste_job_description"
							onChange={e => setUploadOrPasteDescription(e.currentTarget.value)}
							value={uploadOrPasteDescription}
						>
							<FormControlLabel value="1" control={<Radio color="primary" />} label="Upload Job Description" />
							<FormControlLabel
								value="2"
								control={<Radio color="primary" />}
								label="Copy &amp; Paste Job Description"
							/>
						</RadioGroup>
						<VerticalSpacer height={1} />
						{uploadOrPasteDescription === '1' ? (
							<>
								<LabeledField label="Upload Job Description">
									<DropzoneAreaBase
										name="descriptionFile"
										dropzoneText="UPLOAD FILE"
										Icon={UploadIcon}
										dropzoneClass="file-upload-dropzone"
										onAdd={files => setUpdatedListing(c => ({ ...c, descriptionFile: files[0].file, description: '' }))}
										filesLimit={1}
										showAlerts={false}
										showPreviewsInDropzone={false}
									/>
									{updatedListing.descriptionFile && (
										<Chip
											label={updatedListing.descriptionFile.name}
											onDelete={() => setListing({ target: { name: 'descriptionFile', value: null } })}
										/>
									)}
								</LabeledField>
							</>
						) : (
							<LabeledField label="Paste Job Description">
								<TextField
									variant="outlined"
									name="description"
									value={Utility.getFromObj(updatedListing, 'description')}
									onChange={updateListing}
									rows={10}
									multiline
									fullWidth
								/>
							</LabeledField>
						)}
					</Fence>
					<VerticalSpacer height={3} />
					{alert.show ? (
						<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
							{alert.message}
						</Alert>
					) : null}
					<div className="text-right">
						<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
							Cancel
						</Button>
						<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveListing}>
							Save
						</LoadingButton>
					</div>
				</PanelContent>
			</Panel>
		</>
	);
};

EditDescriptionModal.propTypes = {
	listing: PropTypes.shape({ id: PropTypes.string }),
	setListing: PropTypes.func,
	setDescriptions: PropTypes.func,
};

EditDescriptionModal.defaultProps = {
	listing: { id: null },
	setListing: () => null,
	setDescriptions: () => null,
};

export default EditDescriptionModal;

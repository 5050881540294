/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import Dropdown from '../../form/dropdowns/Dropdown';

export default function SkillStorySecondaryFilters({ filter, onUpdate, valueIndex }) {
	const filterValue = filter?.values?.[valueIndex] || {};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 5,
				flex: 1,
			}}
		>
			<Dropdown
				style={{}}
				inputProps={{
					style: {
						height: 30,
						padding: 9,
					},
				}}
				multiple={false}
				variant="outlined"
				name="source"
				value={filterValue.type || 'require-all'}
				data={[
					{
						id: 'require-all',
						label: 'Require All',
					},
					{
						id: 'include-any',
						label: 'Include Any',
					},

					{
						id: 'exclude',
						label: 'Exclude',
					},
				]}
				onChange={e => {
					console.log({ newValue: e.target.value });
					onUpdate({
						...filter,
						values: filter.values.map((v, i) => {
							if (i === valueIndex) {
								return {
									...v,
									type: e.target.value,
								};
							}
							return v;
						}),
					});
				}}
				placeholder="Require"
				fullWidth
			/>
			<Dropdown
				multiple={false}
				variant="outlined"
				name="source"
				value={filterValue.secondaryType || 'any'}
				data={[
					{
						id: 'hero',
						label: 'Hero',
					},
					{
						id: 'enjoy',
						label: 'Enjoy',
					},
					{
						id: 'learn',
						label: 'Learn',
					},
					{
						id: 'improve',
						label: 'Improve',
					},
					{
						id: 'any',
						label: 'Any',
					},
				]}
				onChange={e => {
					onUpdate({
						...filter,
						values: filter.values.map((v, i) => {
							if (i === valueIndex) {
								return {
									...v,
									secondaryType: e.target.value,
								};
							}
							return v;
						}),
					});
				}}
				placeholder="Type"
				fullWidth
			/>
		</div>
	);
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Grid, TextField, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LabeledField from '../../../components/form/LabeledField';
import PhoneInput from '../../../components/form/inputs/PhoneInput';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import LoadingButton from '../../../components/form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';

const EditContactInfoModal = ({ candidate, setCandidate }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

	const updateCandidate = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedCandidate({ ...updatedCandidate, ...newValue });
	};

	const saveCandidate = () => {
		setIsSaving(true);
		const forgedCandidate = CandidatesAPI.getForgedCandidate(updatedCandidate);
		const forgedCurrentCandidate = CandidatesAPI.getForgedCandidate(candidate);

		const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentCandidate, forgedCandidate);

		CandidatesAPI.updateCandidate(candidate.id, differencesToSubmit, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const newCandidate = response.data.data;
			setCandidate(newCandidate);
			dispatch(SiteActions.hideModal());
		});
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={5}>
					<LabeledField label="First Name" required removeMargin>
						<TextField
							variant="outlined"
							name="first_name"
							value={updatedCandidate.first_name}
							onChange={updateCandidate}
							error={!updatedCandidate.first_name}
							fullWidth
							placeholder="Enter first name"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={5}>
					<LabeledField label="Last Name" required removeMargin>
						<TextField
							variant="outlined"
							name="last_name"
							value={updatedCandidate.last_name}
							onChange={updateCandidate}
							error={!updatedCandidate.last_name}
							fullWidth
							placeholder="Enter last name"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={5}>
					<LabeledField label="Primary E-mail" required removeMargin>
						<TextField
							variant="outlined"
							name="email"
							value={updatedCandidate.email}
							onChange={updateCandidate}
							error={!updatedCandidate.email}
							fullWidth
							placeholder="Enter email address"
							disabled
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={5}>
					<LabeledField label="Primary Phone Number" required removeMargin>
						<PhoneInput
							variant="outlined"
							name="phone"
							value={updatedCandidate.phone}
							onChange={updateCandidate}
							error={!updatedCandidate.phone}
							fullWidth
							placeholder="Enter phone number"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={5}>
					<LabeledField label="City">
						<TextField
							variant="outlined"
							name="city"
							value={updatedCandidate.city}
							onChange={updateCandidate}
							fullWidth
							placeholder="Enter city"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={5}>
					<LabeledField label="State">
						<StatesDropdown
							variant="outlined"
							name="state"
							placeholder="State"
							value={updatedCandidate.state}
							onChange={updateCandidate}
							fullWidth
						/>
					</LabeledField>
				</Grid>
			</Grid>
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<div className="text-right">
				<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
					Cancel
				</Button>
				<LoadingButton loading={isSaving} variant="contained" color="primary" value={2} onClick={saveCandidate}>
					Save
				</LoadingButton>
			</div>
		</>
	);
};

EditContactInfoModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditContactInfoModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditContactInfoModal;

/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

export const sortSpecialties = specialties => {
	return specialties?.sort((el1, el2) => {
		const el1Label = el1?.label?.toUpperCase() === 'LEADERSHIP' ? 'ZZZZ' : el1?.label.toUpperCase();
		const el2Label = el2?.label?.toUpperCase() === 'LEADERSHIP' ? 'ZZZZ' : el2?.label.toUpperCase();
		if (el1Label < el2Label) return -1;
		if (el1Label > el2Label) return 1;
		return 0;
	});
};

const SpecialtyDropdown = ({ category, value, onChange, firstEntry, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const getCategories = () => {
		const findCategory = (industries, categoryId) => {
			for (const industry of industries) {
				if (industry.id === categoryId) {
					return industry;
				}
				if (industry.children) {
					const foundCategory = findCategory(industry.children, categoryId);
					if (foundCategory) {
						return foundCategory;
					}
				}
			}
			return null;
		};

		const filterMatchingCategories = (industries, selectedCategory) => {
			let matchingCategories = [];

			for (const industry of industries) {
				const [firstPart] = industry.label.split(': ');
				if (firstPart === selectedCategory.label.split(': ')[0]) {
					matchingCategories.push(industry);
				}
				if (industry.children) {
					matchingCategories = matchingCategories.concat(filterMatchingCategories(industry.children, selectedCategory));
				}
			}

			return matchingCategories;
		};

		if (category) {
			const selectedCategory = findCategory(data.industries, category);
			if (selectedCategory) {
				let matchingCategories = filterMatchingCategories(data.industries, selectedCategory);

				matchingCategories = matchingCategories
					.map(c => {
						const [, secondHalf] = c.label.split(': ');
						return secondHalf ? { ...c, label: secondHalf.trim() } : null;
					})
					.filter(c => c !== null);

				matchingCategories = matchingCategories.filter(
					(cat, index, self) => index === self.findIndex(c => c.label.toLowerCase() === cat.label.toLowerCase()),
				);

				matchingCategories = matchingCategories.sort((a, b) =>
					a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase()),
				);

				return matchingCategories;
			}
		}

		return [];
	};

	return <Dropdown data={getCategories()} value={value} onChange={onChange} {...props} />;
};

SpecialtyDropdown.propTypes = {
	category: PropTypes.number,
};

SpecialtyDropdown.defaultProps = {
	category: null,
};

export default SpecialtyDropdown;

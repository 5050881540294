import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const CategoryDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const getCategories = () => {
		let categories = data.categories || [];

		// Sort alphabetically
		categories = categories.sort((el1, el2) => {
			if (el1.label.toUpperCase() < el2.label.toUpperCase()) return -1;
			if (el1.label.toUpperCase() > el2.label.toUpperCase()) return 1;
			return 0;
		});

		return categories;
	};

	return <Dropdown data={getCategories()} {...props} />;
};

export default CategoryDropdown;

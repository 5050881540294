/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="24 / basic / case">
				<path
					id="icon"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.66659 2H9.33325C10.0696 2 10.6666 2.59695 10.6666 3.33333V4H13.3333C14.0696 4 14.6666 4.59695 14.6666 5.33333V12.6667C14.6666 13.403 14.0696 14 13.3333 14H2.66659C1.93021 14 1.33325 13.403 1.33325 12.6667V5.33333C1.33325 4.59695 1.93021 4 2.66659 4H5.33325V3.33333C5.33325 2.59695 5.93021 2 6.66659 2ZM2.66659 5.33333L5.33325 5.33333H10.6666L13.3333 5.33333V8.66667H8.66659H7.33325H2.66659V5.33333ZM2.66659 12.6667V10H7.33325V10.6667H8.66659V10H13.3333V12.6667H2.66659ZM9.33325 3.33333V4H6.66659V3.33333H9.33325Z"
					fill={color}
				/>
			</g>
		</svg>
	);
}

/* eslint-disable import/prefer-default-export */

export const FIELD_SPACING = 36;

export function formatFieldName(fieldName) {
	// Remove trailing "_id" if present and split the field name by underscores
	const partsWithoutId = fieldName.split('_id')[0].split('_');

	// If there were no underscores, split by CamelCase
	const parts =
		partsWithoutId.length === 1
			? fieldName
					.split('_id')[0]
					.replace(/([A-Z])/g, ' $1')
					.trim()
					.split(' ')
			: partsWithoutId;

	// Map over each part to capitalize the first letter and join them back with a space
	return parts?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function formatDate(dateString) {
	// returns a date in the format of "Jan 1, 2020"
	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	};
	const date = new Date(dateString);
	return date.toLocaleString('en-US', options);
}

export function formatTime(dateString) {
	// returns a time in the format of "1:00 PM"
	const options = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	};
	const date = new Date(dateString);
	return date.toLocaleString('en-US', options);
}

export function findNonJoinValue(label, value, data) {
	switch (label) {
		case 'status_type_id':
			return data.candidateStatusTypes.find(type => type.id === value)?.label;
		case 'recent_leadership_role_id':
			return data.leadershipExperienceRoles.find(role => role.id === value)?.label;
		case 'professional_category_id':
			return data.categories.find(category => category.id === value)?.label;
		case 'specialty_id':
			return data.specialties.find(specialty => specialty.id === value)?.label;
		case 'education_type_id':
			return data.educationTypes.find(type => type.id === value)?.label;
		case 'current_employment_type_id':
			return data.currentEmploymentTypes.find(type => type.id === value)?.label;
		case 'leadership_allocation_type_id':
			return data.leadershipAllocationTypes.find(type => type.id === value)?.label;
		case 'owner_id':
			return data.owners.find(owner => owner.id === value)?.label;
		case 'work_visa_type_id':
			return data.workVisaTypes.find(type => type.id === value)?.label;
		case 'travel_willingness_type_id':
			return data.travelWillingnessTypes.find(type => type.id === value)?.label;
		case 'salary_expectation_type_id':
			return data.salaryExpectationTypes.find(type => type.id === value)?.label;
		case 'pronoun_id':
			return data.pronouns.find(pronoun => pronoun.id === value)?.label;
		case 'gender_id':
			return data.genders.find(gender => gender.id === value)?.label;
		case 'race_id':
			return data.races.find(race => race.id === value)?.label;
		case 'preferred_leadership_allocation_id':
			return data.leadershipAllocationTypes.find(type => type.id === value)?.label;
		case 'job_search_stage_id':
			return data.jobSearchStages.find(status => status.id === value)?.label;
		case 'willing_to_relocate':
			return value === '1' ? 'Yes' : 'No';
		case 'last_update_source':
			return value === 'bobcat' ? 'NauMatch Portal' : 'Bullhorn ATS';
		default:
			return value;
	}
}

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function HeartIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-725 -666)">
					<g transform="translate(726 667)">
						<path d="M10.555 12.856A2.2 2.2 0 019 13.5h0a2.198 2.198 0 01-1.554-.644L4.81 10.221A2.765 2.765 0 118.72 6.31l.28.279.28-.279a2.764 2.764 0 113.91 3.911l-2.635 2.635z"/>
						<path d="M18 9a9 9 0 01-9 9 9 9 0 01-9-9 9 9 0 019-9 9 9 0 019 9z"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
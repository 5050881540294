/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function EmailCircleIcon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g stroke={color} strokeWidth="1" transform="translate(1 1)">
					<g>
						<path d="M0 6.802v9.297a2 2 0 002 2h13.999a2 2 0 002-2V6.802" />
						<path d="M6.118 11.98L0.586 17.512" />
						<path d="M17.411 17.509L11.882 11.98" />
						<path d="M3 3.72L.886 5.143a2 2 0 000 3.32l5.875 3.95a4.01 4.01 0 004.478 0l5.875-3.95a2 2 0 000-3.32L15 3.72" />
						<path d="M15 9.883V1a1 1 0 00-1-1H4a1 1 0 00-1 1v8.883" />
						<path d="M6 3.598L12 3.598" />
						<path d="M6 7.098L12 7.098" />
					</g>
				</g>
			</g>
		</svg>
	);
}

import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid } from '@material-ui/core';
import Icon from '../../../components/icons/SkillsIcon';
import LabeledField from '../../../components/form/LabeledField';
import EditCompanyBlockModal from './EditCompanyBlockModal';
import ElasticSkillDropdown from '../../../components/form/dropdowns/ElasticSkillDropdown';

const EditModal = ({ company, setCompany }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	return (
		<EditCompanyBlockModal
			company={company}
			setCompany={setCompany}
			title="Company Skills"
			icon={Icon}
			renderContents={({ updateCompany, updatedCompany }) => {
				const updateSkills = skillIds => {
					const removedSkillIds = updatedCompany?.skills
						?.filter(s => !skillIds.includes(s.skill_id))
						.map(s => s.skill_id);

					const newSkills = skillIds
						.filter(skillId => !updatedCompany?.skills?.find(s => s.skill_id === skillId))
						.map(skillId => ({
							skill_id: skillId,
							skill: data?.skills?.find(vs => vs.id === skillId),
							// skill_experience_id: levelId,
							experience_years: 0,
						}));

					const updatedSkills = (updatedCompany?.skills || [])
						?.filter(s => !removedSkillIds.includes(s.skill_id)) // remove skills
						?.map(s => (skillIds.includes(s.skill_id) ? { ...s } : s)) // update xp id
						?.concat(newSkills);

					updateCompany({ target: { name: 'skills', value: updatedSkills } });
				};

				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<LabeledField label="Skills" removeMargin>
									<ElasticSkillDropdown
										tags
										variant="outlined"
										color="primary"
										name="skills"
										value={updatedCompany.skills?.map(s => s.skill_id)}
										// override={validSkills}
										onChange={e => updateSkills(e.target.value)}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

export default EditModal;

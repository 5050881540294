import React, { useState, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, TextField, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import BackArrow from '../../../components/page/BackArrow';
import LabeledField from '../../../components/form/LabeledField';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import LoadingButton from '../../../components/form/LoadingButton';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as SpecialtiesAPI from '../../../scripts/specialties';
import * as SiteAPI from '../../../scripts/site';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const SpecialtyEdit = () => {
	useDocumentTitle('Data Manager - Specialties');
	const store = useStore();
	const history = useHistory();
	const params = useParams();
	const specialtyID = params.id;
	const [isLoading, setIsLoading] = useState(false);
	const [specialty, setSpecialty] = useState({});
	const { alert } = useSelector(state => state.site, shallowEqual);

	const updateSpecialty = ev => {
		setSpecialty({ ...specialty, [ev.target.name]: ev.target.value });
	};

	const onSpecialtyUpdate = (response, id) => {
		setIsLoading(false);

		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred when saving the specialty. Please try again.', 'error'));
			return;
		}

		SiteAPI.updateSpecialties(store);
		history.push(`/admin/specialties`);
	};

	const saveSpecialty = ev => {
		setIsLoading(true);
		SpecialtiesAPI.updateSpecialty(specialtyID, { ...specialty, name: '' }, onSpecialtyUpdate);
	};

	// Get page data on load
	useEffect(() => {
		SpecialtiesAPI.getSpecialty(specialtyID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred when loading the specialty data. Please refresh the page.', 'error'),
				);
				return;
			}

			const resp = response.data.data;

			if (resp.category) resp.category_id = resp.category.id;

			setSpecialty(resp);
		});
	}, []);

	if (Object.keys(specialty).length === 0) return <Page />;

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to="/admin/specialties" />
					<Typography variant="h1">Edit Specialty</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<LoadingButton loading={isLoading} width={9} variant="contained" color="primary" onClick={saveSpecialty}>
						Save
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Grid container spacing={8}>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom />
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<LabeledField label="Specialty Name">
								<TextField
									variant="outlined"
									name="label"
									value={specialty.label}
									onChange={updateSpecialty}
									fullWidth
								/>
							</LabeledField>

							<LabeledField label="Category">
								<CategoryDropdown
									variant="outlined"
									name="category_id"
									value={specialty.category_id}
									onChange={updateSpecialty}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="ID" className="color-muted">
								<TextField variant="outlined" value={specialty.id} fullWidth disabled />
							</LabeledField>

							<LabeledField label="Slug" className="color-muted">
								<TextField variant="outlined" value={specialty.name} fullWidth disabled />
							</LabeledField>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Page>
	);
};

export default SpecialtyEdit;

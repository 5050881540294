import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import NewspaperIcon from '../../../components/icons/SectionIcons/NewspaperIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import LeadershipRolesDropdown from '../../../components/form/dropdowns/LeadershipRolesDropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';
import EditCompanyBlockModal from './EditCompanyBlockModal';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import BusinessSizeDropdown from '../../../components/form/dropdowns/BusinessSizeDropdown';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import SpecialtyDropdown from '../../../components/form/dropdowns/SpecialtyDropdown';
import WorkVisaDropdown from '../../../components/form/dropdowns/WorkVisaDropdown';
import TravelWillingnessDropdown from '../../../components/form/dropdowns/TravelWillingnessDropdown';
import EmploymentPreferencesDropdown from '../../../components/form/dropdowns/EmploymentPreferencesDropdown';
import WorkLocationDropdown from '../../../components/form/dropdowns/WorkLocationDropdown';
import WillingToRelocateDropdown from '../../../components/form/dropdowns/WillingToRelocateDropdown';
import StatesDropdown from '../../../components/form/dropdowns/StatesDropdown';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import CompensationTypesDropdown from '../../../components/form/dropdowns/CompensationTypesDropdown';
import SalaryDropdown from '../../../components/form/dropdowns/SalaryDropdown';
import ValuePropositionsDropdown from '../../../components/form/dropdowns/ValuePropositionsDropdown';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';

const EditModal = ({ company, setCompany }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return (
		<EditCompanyBlockModal
			company={company}
			setCompany={setCompany}
			title="Company Summary"
			icon={NewspaperIcon}
			renderContents={({ updateCompany, updatedCompany }) => {
				console.log({ updatedCompany });
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<LabeledField label="Company Summary (Public)" removeMargin>
									<TextField
										variant="outlined"
										name="company_summary_external"
										value={updatedCompany?.company_summary_external || ''}
										onChange={updateCompany}
										error={null}
										fullWidth
										rows={3}
										multiline
										placeholder="Add some more info here."
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Company Summary (Private)" removeMargin>
									<TextField
										variant="outlined"
										name="company_summary_internal"
										value={updatedCompany?.company_summary_internal || ''}
										onChange={updateCompany}
										error={null}
										fullWidth
										rows={3}
										multiline
										placeholder="Add some more info here."
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Client Docs Url" removeMargin>
									<TextField
										variant="outlined"
										name="client_docs_url"
										value={updatedCompany?.client_docs_url || ''}
										onChange={updateCompany}
										error={null}
										fullWidth
										// rows={3}
										// multiline
										placeholder="Add some more info here."
									/>
								</LabeledField>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

export default EditModal;

import React, { useState, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import BackArrow from '../../../components/page/BackArrow';
import Fence from '../../../components/Fence';
import { Panel, PanelContent } from '../../../components/Panel';
import VerticalSpacer from '../../../components/VerticalSpacer';
import LabeledField from '../../../components/form/LabeledField';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as UsersAPI from '../../../scripts/users';
import * as Utility from '../../../scripts/utility';

const SpecialtyEdit = () => {
	const store = useStore();
	const params = useParams();
	const userID = params.id;
	const [user, setUser] = useState({});
	const { alert } = useSelector(state => state.site, shallowEqual);

	// Get page data on load
	useEffect(() => {
		UsersAPI.getUser(userID, response => {
			if (!response) return;

			setUser(response.data.data);
		});
	}, []);

	if (Object.keys(user).length === 0) return <Page />;

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to="/admin/users" />
					<Typography variant="h1">Users</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<NavLink to={`/admin/user/${userID}/edit`} className="color-inherit">
						<Button variant="contained" color="primary">
							Edit User
						</Button>
					</NavLink>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Fence width={32} centered>
				<Panel>
					<PanelContent>
						<center>
							<Typography variant="h3" component="h2">
								{`${user.first_name || ''} ${user.last_name || ''}` || ''}
							</Typography>
							<Typography variant="body2">{Utility.getFromObj(user, 'roles', 0, 'label')}</Typography>
						</center>
						<VerticalSpacer height={2.5} />

						<LabeledField label="E-mail Address" className="color-muted" close>
							<Typography variant="body2">
								<Icons.Email color="primary" /> {user.email || Utility.na}
							</Typography>
						</LabeledField>

						<LabeledField label="Phone Number" className="color-muted" close>
							<Typography variant="body2">
								<Icons.Phone color="primary" /> {Utility.formatPhone(user.phone) || Utility.na}
							</Typography>
						</LabeledField>

						<LabeledField label="Birthday" className="color-muted" close>
							<Typography variant="body2">
								<Icons.Cake color="primary" /> {Utility.formatDate(user.birth_date, true) || Utility.na}
							</Typography>
						</LabeledField>

						{/* <hr />

						<LabeledField label="Access" className="color-muted" close>
							<FormControlLabel
								control={<Checkbox checked={false} disabled />}
								label="Add and edit candidates"
							/>
							<br />
							<FormControlLabel
								control={<Checkbox checked={false} disabled />}
								label="Add and edit jobs"
							/>
							<br />
							<FormControlLabel
								control={<Checkbox checked={false} disabled />}
								label="Add and edit assessments"
							/>
							<br />
							<FormControlLabel
								control={<Checkbox checked={false} disabled />}
								label="Admin section"
							/>
						</LabeledField> */}
					</PanelContent>
				</Panel>
			</Fence>
		</Page>
	);
};

export default SpecialtyEdit;

import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { useStore, useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import {
	Grid,
	Typography,
	Button,
	Chip,
	ListItemIcon,
	MenuItem,
	Menu,
	IconButton,
	FormControlLabel,
	Switch,
} from '@material-ui/core';

const StickyHeader = ({ children, style, scrollElement, offset  }) => {
	const [showStickyHeader, setShowStickyHeader] = useState(false);

	useEffect(() => {
		const handleScroll = e => {
			const scrollPosition =
				(scrollElement || document.documentElement)?.scrollTop 
			
                setShowStickyHeader(scrollPosition > (offset === undefined ? 125 : offset));
		};
		(scrollElement || window)?.addEventListener('scroll', handleScroll);
		return () => {
			(scrollElement || window)?.removeEventListener('scroll', handleScroll);
		};
	});

	return (
		<div
			style={{
				backgroundColor: '#34414E',
				height: 70,
				width: '100%',

				padding: '15px 40px',
				position: 'fixed',
				top: 0,
				right: 0,
				zIndex: 2,
				opacity: showStickyHeader ? 1 : 0,
				transition: 'all .2s ease',
				display: 'flex',
				alignItems: 'center',
				transform: showStickyHeader ? 'translateY(0px)' : 'translateY(-70px)',
				justifyContent: 'space-between',
				...(style || {}),
			}}
		>
			{children}
		</div>
	);
};

export default StickyHeader;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function TargetIcon({ style, color = "currentColor", size = 19, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 19 19"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-254 -823)">
					<g transform="translate(255 824)">
						<path d="M17.053 9.474A8.527 8.527 0 118.527.948"/>
						<path d="M13.264 9.474a4.737 4.737 0 11-4.737-4.737"/>
						<path d="M15.1583 0L12.3163 2.842 12.3163 5.684 15.1583 5.684 18.0003 2.842 16.1053 1.895z"/>
						<path d="M12.316 5.684L8.527 9.473"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
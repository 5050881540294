import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import XCircleIcon from '../icons/MatchingXCircle';

const ValidationFieldWrapper = ({ validation, hide, children, column }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: column ? 'start' : 'center',
				flexDirection: column ? 'column' : 'row',
				flexWrap: 'wrap',
			}}
		>
			{!hide && (!validation?.valid || validation?.missing) && (
				<div style={{ flexShrink: 0, width: 14, height: 7 }}>
					{' '}
					<div
						style={{
							width: 7,
							height: 7,
							backgroundColor: validation?.missing
								? '#bbb'
								: validation?.partial
								? 'var(--yellow-50)'
								: 'var(--red-100)',
							borderRadius: '50%',
						}}
					/>
					<div style={{ width: 10 }} />
				</div>
			)}
			{children}
		</div>
	);
};

export default ValidationFieldWrapper;

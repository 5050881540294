import API from './api';
import * as Utility from './utility';

// API ENDPOINTS

/**
 * Gets a paginated list of categories
 * @param {Object} parameters - Filters passed as query parameters
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const getCategories = (parameters, callback, store) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/categories${query}`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Gets a full list of categories
 * @param {Object} parameters - Filters passed as query parameters
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const getAllCategories = (parameters, callback, store) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/categories/tree${query}`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Gets the data for a specific category
 * @param {Number} id - The ID of the category
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const getCategory = (id, callback, store) => {
	API.get(`/categories/${id}/tree`)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Updates a category record
 * @param {Number} id - The ID of the category
 * @param {Object} parameters - The parameters for the category
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const updateCategory = (id, parameters, callback, store) => {
	const category = parameters;

	if (category.label && !category.name) category.name = category.label;
	if (!category.specialty) category.specialty = category.id;

	API.patch(`/categories/${id}`, category)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Creates a new category record
 * @param {Object} parameters - The parameters for the category
 * @param {Function} callback - The function called once AJAX call is finished
 * @param {Object} store - The Redux data store
 */
export const addCategory = (parameters, callback, store) => {
	const category = parameters;

	if (category.label && !category.name) category.name = category.label;

	API.post(`/categories`, category)
		.then(callback)
		.catch(err => callback(false));
};

/**
 * Deletes a category record
 * @param {Number} id - The ID of the category
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const deleteCategory = (id, callback) => {
	API.delete(`/categories/${id}`)
		.then(callback)
		.catch(err => callback(false, err));
};

import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Button } from '@material-ui/core';

const getColor = color => {
	if (color === 'primary') return 'var(--primaryColor)';
	if (color === 'secondary') return 'var(--secondaryColor)';
	if (color === 'disabled') return 'var(--mutedTextColor)';
	if (color === 'error') return 'var(--errorColor)';
	if (color === 'warning') return 'var(--warningColor)';
	if (color === 'success') return 'var(--successColor)';
	if (color === 'teal') return 'var(--teal-100)';
	if (color === 'blue') return 'var(--blue-500)';

	return '';
};

export const Panel = ({ topBarColor = null, color, children, className, Icon, style, ...props }) => {
	return (
		<Paper
			className={`panel ${className}`}
			style={{
				position: 'relative',
				backgroundColor: color ? getColor(color) : undefined,
				color: color ? 'var(--white)' : undefined,
				...style,
			}}
			{...props}
			elevation={0}
		>
			{topBarColor ? (
				<div
					style={{
						height: 3,
						backgroundColor: '#ddd', // getColor(topBarColor),
						margin: -1,
						borderTopRightRadius: 3,
						borderTopLeftRadius: 3,
					}}
				/>
			) : null}
			{Icon ? (
				<div className="panel-icon-container">
					<div className="panel-icon">{Icon}</div>
					<div className="panel-icon-content">{children}</div>
				</div>
			) : (
				children
			)}
		</Paper>
	);
};

Panel.propTypes = {
	color: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	Icon: PropTypes.node,
	style: PropTypes.shape({}),
};

Panel.defaultProps = {
	color: '',
	children: null,
	className: '',
	Icon: null,
	style: {},
};

export const PanelHeader = ({ children, className, ...props }) => {
	return (
		<div className={`panel-header ${className}`} {...props}>
			{children}
		</div>
	);
};

PanelHeader.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

PanelHeader.defaultProps = {
	children: null,
	className: '',
};

export const PanelContent = ({ children, className, ...props }) => {
	return (
		<div className={`panel-content ${className}`} {...props}>
			{children}
		</div>
	);
};

PanelContent.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

PanelContent.defaultProps = {
	children: null,
	className: '',
};

export default Panel;

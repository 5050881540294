import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import ViewBlockWrapper from './ViewBlockWrapper';
import StarCheckmarkIcon from '../../../components/icons/StarCheckmarkIcon';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditAchievementsModal from '../EditCandidateProfile/EditAchievementsModal';
import VerticalSpacer from '../../../components/VerticalSpacer';

const Block = ({ candidate, setCandidate, minimized, setMinimized, disableEdit }) => {
	const dispatch = useDispatch();

	return (
		<ViewBlockWrapper
			icon={StarCheckmarkIcon}
			minimized={minimized}
			setMinimized={setMinimized}
			title="Achievements and Interests"
			onEditClick={
				disableEdit
					? null
					: () => {
							dispatch(
								SiteActions.showModal(<EditAchievementsModal candidate={candidate} setCandidate={setCandidate} />, {
									className: 'modal-medium modal-no-padding modal-no-shadow',
								}),
							);
					  }
			}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
						Achievements
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<LabeledField label="Achievements & Accomplishments" className="color-muted" removeMargin close>
						<Typography>{candidate?.achievements}</Typography>
					</LabeledField>
				</Grid>
				<Grid item xs={12}>
					<VerticalSpacer height={1} />
					<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
						Q&A Questions
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<>
						{candidate.qa_questions.map((q, i) => {
							return (
								<div key={q.id}>
									<LabeledField label={`Question ${i + 1}`} className="color-muted" removeMargin close>
										<Typography style={{ fontWeight: 'bold' }}>{q.qaQuestion.label}</Typography>
										<Typography>{q.answer}</Typography>
									</LabeledField>
									<VerticalSpacer height={0.5} />
								</div>
							);
						})}
					</>
				</Grid>
				<Grid item xs={12}>
					<VerticalSpacer height={1} />
					<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
						Links
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{candidate.linkedin_url && <Typography style={{ fontWeight: 'bold' }}>LinkedIn</Typography>}
					<Link href={candidate.linkedin_url} target="_blank">
						<Typography>{candidate.linkedin_url}</Typography>
					</Link>
					<VerticalSpacer height={0.5} />
					{candidate?.link_types?.map(l => {
						return (
							<div key={l.id}>
								<Typography style={{ fontWeight: 'bold' }}>{l.link_type.label}</Typography>
								<Link
									href={l.url.startsWith('http://') || l.url.startsWith('https://') ? l.url : `https://${l.url}`}
									target="_blank"
								>
									<Typography>{l.url}</Typography>
								</Link>
								<VerticalSpacer height={0.5} />
							</div>
						);
					})}
				</Grid>
			</Grid>
		</ViewBlockWrapper>
	);
};

export default Block;

import React from 'react';

const JobsSVG = props => (
	<svg width={20} height={20} fill="none" {...props}>
		<path
			d="M3 4.5h3v-1a3 3 0 013-3h2a3 3 0 013 3v1h3a3 3 0 
      013 3v9a3 3 0 01-3 3H3a3 3 0 01-3-3v-9a3 3 0 013-3zm9-1a1 
      1 0 00-1-1H9a1 1 0 00-1 1v1h4v-1zm-10 13a1 1 0 001 1h14a1 
      1 0 001-1V11a21.71 21.71 0 01-8 1.53A21.75 21.75 0 012 
      11v5.5zm0-7.69a19.89 19.89 0 0016 0V7.5a1 1 0 00-1-1H3a1 1 
      0 00-1 1v1.31z"
			fill="#A5AEBC"
		/>
	</svg>
);

export default JobsSVG;

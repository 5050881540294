import React from 'react';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Panel, PanelContent, PanelHeader } from '../../../components/Panel';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditAchievementsModal from '../EditCandidateProfile/EditAchievementsModal';
import StarCheckmarkIcon from '../../../components/icons/StarCheckmarkIcon';

const Block = ({ candidate, setCandidate, buttonRef, isComplete }) => {
	const dispatch = useDispatch();
	return (
		<Panel id="Achievements">
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<StarCheckmarkIcon size={32} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Achievements and Interests
					</Typography>
				</div>
				{!!candidate.assessment_completed_at && (
					<Button
						ref={buttonRef}
						variant={isComplete ? 'outlined' : 'containedTeal'}
						color="primary"
						size="small"
						onClick={() => {
							dispatch(
								SiteActions.showModal(<EditAchievementsModal candidate={candidate} setCandidate={setCandidate} />, {
									className: 'modal-medium modal-no-padding modal-no-shadow',
								}),
							);
						}}
					>
						{isComplete ? 'Edit' : 'Complete'}
					</Button>
				)}
			</PanelHeader>
			<PanelContent style={{ paddingInline: '63px' }}>
				<VerticalSpacer height={1} />
				<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
					Achievements
				</Typography>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{candidate?.achievements && (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Typography className="grey-250">Achievements & Accomplishments</Typography>
								<Typography>{candidate.achievements}</Typography>
								<VerticalSpacer height={2} />
							</div>
						)}
					</Grid>
				</Grid>
				<VerticalSpacer height={1} />
				<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
					Q&A Questions
				</Typography>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<>
							{candidate.qa_questions.map((q, i) => {
								return (
									<div key={q.id}>
										<Typography className="grey-250">Question {i + 1}</Typography>
										<Typography style={{ fontWeight: 'bold' }}>{q.qaQuestion.label}</Typography>
										<Typography>{q.answer}</Typography>
										<VerticalSpacer height={0.5} />
									</div>
								);
							})}
						</>
					</Grid>
				</Grid>
				<VerticalSpacer height={1} />
				<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
					Links
				</Typography>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{candidate.linkedin_url && <Typography className="grey-250">LinkedIn</Typography>}
						<Link href={candidate.linkedin_url} target="_blank">
							<Typography>{candidate.linkedin_url}</Typography>
						</Link>
						<VerticalSpacer height={0.5} />
						{candidate?.link_types?.map(l => {
							return (
								<div key={l.id}>
									<Typography className="grey-250">{l.link_type.label}</Typography>
									<Link
										href={l.url.startsWith('http://') || l.url.startsWith('https://') ? l.url : `https://${l.url}`}
										target="_blank"
									>
										<Typography>{l.url}</Typography>
									</Link>
									<VerticalSpacer height={0.5} />
								</div>
							);
						})}
					</Grid>
				</Grid>
			</PanelContent>
		</Panel>
	);
};

export default Block;

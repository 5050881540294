import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import * as ContactsAPI from '../scripts/contacts';
import { ActionCreators as SiteActions } from '../store/Site';

const useContact = ({ id, lazy, onComplete } = { onComplete: () => null }) => {
	const store = useStore();
	const [contact, setContact] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const fetch = lazyId => {
		setLoading(true);
		ContactsAPI.getContact(id, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the contact. Refresh the page to try again.', 'error'));
				setLoading(false);
				setError(true);
				return;
			}
			setContact(response.data.data);
			setLoading(false);
			if (onComplete) onComplete(response);
		});
	};

	useEffect(() => {
		if (id && !lazy) fetch(id);
	}, [id, lazy]);

	return { loading, contact, error, fetch: id && lazy ? fetch : () => null };
};

useContact.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onComplete: PropTypes.func,
	lazy: PropTypes.bool,
};

export default useContact;

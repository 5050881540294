import React, { useEffect } from 'react';
import * as CandidatesAPI from '../scripts/candidates';
import * as ListingsAPI from '../scripts/listings';
import { useStickyState } from '../scripts/utility';
import * as AuthenticationAPI from '../scripts/authentication';

const ContextStore = React.createContext({});
export const useContextStore = () => React.useContext(ContextStore);

export const ContextStoreWrapper = ({ children }) => {
	const [_currentAccessToken, setCurrentAccessToken] = React.useState(null);

	const currentAccessToken = _currentAccessToken || window.currentAccessToken;

	const getCurrentAccessToken = async () => {
		let token = JSON.parse(localStorage.getItem('authentication'));
		const expiration = localStorage.getItem('authentication-expires');
		if (expiration < Date.now() + 10000) {
			console.log('[ContextStore] Attempting to pre-emptively reauthorize...');
			try {
				await AuthenticationAPI.reauthorize();

				// Update the access token in state
				token = JSON.parse(localStorage.getItem('authentication'));
				setCurrentAccessToken(token.access_token);
			} catch (err) {
				// If we can't reauthorize, we'll just run the request with the existing (expired) authentication.
				console.log('[ContextStore] Re-authorization failed.');
			}
		} else {
			console.log('Token is not expired: ', expiration);
		}
		setCurrentAccessToken(token.access_token);
		return token.access_token;
	};

	// Check every 10s whether we need to reauthorize the access token
	useEffect(() => {
		const interval = setInterval(async () => {
			await getCurrentAccessToken();
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	// const [matches, setMatches] = React.useState(null);
	const [candidateMatches, setCandidateMatches] = React.useState(null);
	const [jobMatches, setJobMatches] = React.useState(null);

	const [currentCandidateId, setCurrentCandidateId] = React.useState(null);
	const [currentJobId, setCurrentJobId] = React.useState(null);
	const [loadingJobMatches, setLoadingJobMatches] = React.useState(false);
	const [loadingCandidateMatches, setLoadingCandidateMatches] = React.useState(false);

	const [graphMatches, setGraphMatches] = React.useState(null);

	const [currentGraphMatch, setCurrentGraphMatch] = React.useState(null);

	const [role, setRole] = useStickyState('recruiter', 'user-role');
	const [enableDynamicFilters, setEnableDynamicFilters] = React.useState(false);

	const refetchCurrentGraphMatch = ({ candidateId, listingId }) => {
		setCurrentGraphMatch(null);
		if (candidateId && listingId) {
			CandidatesAPI.getCandidateGraphMatch({
				candidateId: currentCandidateId,
				listingId: currentJobId,
			}).then(res => {
				setCurrentGraphMatch(res?.data?.data);
			});
		}
	};

	useEffect(() => {
		console.log({
			currentCandidateId,
			currentJobId,
		});
		// Whenever we have a new candidate/job pair, load the graph-match data for it.
		if (currentCandidateId && currentJobId) {
			refetchCurrentGraphMatch({
				candidateId: currentCandidateId,
				listingId: currentJobId,
			});
		}
	}, [currentCandidateId, currentJobId]);

	const refetchMatches = async ({ candidateId, jobId }) => {
		const promiseList = [];
		if (candidateId) {
			if (candidateId !== currentCandidateId) {
				setCurrentCandidateId(candidateId);
				setCandidateMatches(null);
			}
			setLoadingCandidateMatches(true);
			promiseList.push(
				CandidatesAPI.getCandidateMatches({ candidateId }).then(matches => {
					setCandidateMatches(matches);
					setLoadingCandidateMatches(false);
					return matches;
				}),
			);
		}
		if (jobId) {
			if (jobId !== currentJobId) {
				console.log({
					jobId,
					currentJobId,
				});
				setCurrentJobId(jobId);
				setJobMatches(null);
			}
			setLoadingJobMatches(true);
			promiseList.push(
				ListingsAPI.getListingMatches({ listingId: jobId }).then(matches => {
					setJobMatches(matches);
					setLoadingJobMatches(false);
					return matches;
				}),
			);
		}
		return Promise.all(promiseList);
	};

	return (
		<ContextStore.Provider
			value={{
				// matches,
				// setMatches,

				getCurrentAccessToken,
				currentAccessToken,

				role,
				setRole,

				candidateMatches,
				setCandidateMatches,
				loadingCandidateMatches,

				jobMatches,
				setJobMatches,
				loadingJobMatches,

				refetchMatches,

				graphMatches,
				setGraphMatches,

				currentGraphMatch,
				refetchCurrentGraphMatch,

				enableDynamicFilters,
				setEnableDynamicFilters,
			}}
		>
			{children}
		</ContextStore.Provider>
	);
};

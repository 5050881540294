/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function SkillsIcon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
			className={className}
		>
			<g clipPath="url(#clip0_324_7759)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.59874 15.2124C1.76681 13.836 0.666504 11.6818 0.666504 9.34603C0.666504 5.29594 3.94975 2.0127 7.99984 2.0127C12.0499 2.0127 15.3332 5.29594 15.3332 9.34603C15.3332 11.6818 14.2329 13.836 12.4009 15.2124L12.223 15.346H3.77665L3.59874 15.2124ZM11.7715 14.0127C13.1685 12.8827 13.9998 11.184 13.9998 9.34603C13.9998 6.03232 11.3135 3.34603 7.99984 3.34603C4.68613 3.34603 1.99984 6.03232 1.99984 9.34603C1.99984 11.184 2.83116 12.8827 4.22819 14.0127H11.7715ZM11.546 5.72834L10.4537 4.96372L8.69832 7.47138C8.48087 7.39033 8.24553 7.34603 7.99984 7.34603C6.89527 7.34603 5.99984 8.24146 5.99984 9.34603C5.99984 10.4506 6.89527 11.346 7.99984 11.346C9.10441 11.346 9.99984 10.4506 9.99984 9.34603C9.99984 8.97573 9.8992 8.62893 9.72378 8.33149L11.546 5.72834ZM8.6665 9.34603C8.6665 9.71422 8.36803 10.0127 7.99984 10.0127C7.63165 10.0127 7.33317 9.71422 7.33317 9.34603C7.33317 8.97784 7.63165 8.67936 7.99984 8.67936C8.36803 8.67936 8.6665 8.97784 8.6665 9.34603Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_324_7759">
					<rect width="16" height="16.64" fill="white" transform="translate(0 0.679688)" />
				</clipPath>
			</defs>
		</svg>
	);
}

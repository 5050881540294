import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Typography, TextField, Button, Checkbox } from '@material-ui/core';

import WideSidebar from './WideSidebar';
import VerticalSpacer from '../../VerticalSpacer';
import LabeledField from '../../form/LabeledField';
import LoadingButton from '../../form/LoadingButton';
import Logo from '../../../img/Logo';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as UserAPI from '../../../scripts/users';
import * as Utility from '../../../scripts/utility';

const SignupSidebar = ({ children, ...props }) => {
	const store = useStore();
	const match = useRouteMatch();
	const history = useHistory();
	const [state, setState] = useState({
		isLoading: false,
		email: '',
		firstName: '',
		lastName: '',
		acceptTerms: false,
		canSignup: false,
	});

	const onSignup = response => {
		if (!response) {
			SiteActions.showAlert('An error ocurred saving the candidate. Please try again.', 'error');
			return;
		}

		if (match.params.type === 'candidate') history.push('/assessment/candidate/verification');
		else history.push('/assessment/job/verification');
	};

	const signup = ev => {
		setState({ ...state, isLoading: true });

		const parameters = {
			email: state.email,
			name: state.firstName,
			surname: state.lastName,
			terms_and_conditions: Number(state.acceptTerms),
		};

		if (match.params.type === 'candidate') UserAPI.signup(parameters, onSignup);
		else UserAPI.contactSignup(parameters, onSignup);
	};

	const updateField = ev => {
		if (ev.target.name === 'acceptTerms') setState({ ...state, acceptTerms: ev.target.checked });
		else setState({ ...state, [ev.target.name]: ev.target.value });
	};

	useEffect(() => {
		const canSignup = Boolean(state.email && state.firstName && state.lastName && state.acceptTerms);

		if (canSignup !== state.canSignup) setState({ ...state, canSignup });
	}, [state]);

	return (
		<WideSidebar className="text-center">
			<Logo height="2.5rem" width="10rem" style={{ margin: '0 auto' }} />
			<VerticalSpacer height={5} />
			<Typography variant="h1">Welcome to NauWork</Typography>
			<VerticalSpacer height={5} />
			<LabeledField label="E-mail">
				<TextField variant="outlined" name="email" value={state.email} onChange={updateField} fullWidth />
			</LabeledField>
			<LabeledField label="Name">
				<TextField variant="outlined" name="firstName" value={state.firstName} onChange={updateField} fullWidth />
			</LabeledField>
			<LabeledField label="Surname">
				<TextField variant="outlined" name="lastName" value={state.lastName} onChange={updateField} fullWidth />
			</LabeledField>
			<Typography variant="body2" className="text-left">
				<Checkbox name="acceptTerms" checked={state.acceptTerms} value={state.acceptTerms} onChange={updateField} />
				Accept{' '}
				<Typography variant="body2" component="span" color="primary" className="text-bold cursor-pointer">
					Terms and Conditions
				</Typography>
			</Typography>
			<VerticalSpacer height={5} />
			<LoadingButton
				loading={state.isLoading}
				variant="contained"
				color={state.canSignup ? 'primary' : 'default'}
				size="large"
				onClick={state.canSignup ? signup : () => {}}
				fullWidth
			>
				Create New Account
			</LoadingButton>
			<VerticalSpacer height={2.5} />
			<Typography variant="body2" className="text-divider color-muted">
				Or
			</Typography>
			<VerticalSpacer height={2.5} />
			<Button variant="outlined" color="primary" size="large" fullWidth>
				Sign Up With Google
			</Button>
			<Typography variant="body2" className="sidebar-bottom text-center">
				Already have an account?{' '}
				<Typography
					variant="body2"
					component="span"
					color="primary"
					className="text-bold cursor-pointer"
					onClick={ev => Utility.notImplemented(store)}
				>
					Sign in.
				</Typography>
			</Typography>
		</WideSidebar>
	);
};

SignupSidebar.propTypes = {
	children: PropTypes.node,
};

SignupSidebar.defaultProps = {
	children: null,
};

export default SignupSidebar;

import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import SlidersIcon from '../../../components/icons/SectionIcons/SlidersIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditExperienceModal from './EditExperienceModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import ExpandableTypography from '../../../components/form/ExpandableTypography';
import VerticalSpacer from '../../../components/VerticalSpacer';

const Block = ({
	job,
	setJob,
	data,
	minimized,
	setMinimized,
	comparisonCandidate,
	disableEdit,
	displayMatchValidation,
	graphMatch,
}) => {
	const { states } = data;
	const dispatch = useDispatch();

	const isParentChildCategoryMatch = useMemo(() => {
		if (!job.professional_category_id || !job?.child_professional_category?.id) {
			return false;
		}

		return job.professional_category_id === job.child_professional_category.id;
	}, [job.professional_category_id, job.child_professional_category]);

	const isParentChildSpecialtyMatch = useMemo(() => {
		if (!job?.specialty_id || !job?.child_specialty?.id) {
			return false;
		}

		return job.specialty_id === job.child_specialty.id;
	}, [job.specialty_id, job.child_specialty]);

	return (
		<>
			<ViewBlockWrapper
				icon={SlidersIcon}
				iconColor="var(--blue-500)"
				title="Experience"
				secondaryTitle={
					graphMatch?.total_experience_score
						? `Score: ${Math.round(graphMatch?.total_experience_score * 100)}% (${Math.round(
								graphMatch?.completeness_score_experience * 100,
						  )}%)`
						: null
				}
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditExperienceModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<LabeledField label="Title" className="color-muted" removeMargin close>
							<Typography variant="body2">{job.position_title || Utility.nb}</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Professional Experience" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'professional_experience_years',
								})}
							>
								<Typography variant="body2">
									{job.professional_experience_years ? `${job.professional_experience_years} Years` : Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Type" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'role_type',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.employmentPreferences, job.role_type) || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField
							label="Category"
							className="color-muted"
							removeMargin
							close
							labelAddendum={
								!isParentChildCategoryMatch && job.child_professional_category
									? job.child_professional_category.label
									: null
							}
							labelAddendumTooltip="Client's professional category"
						>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'category',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.categories, job.professional_category_id) || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField
							label="Specialty"
							className="color-muted"
							removeMargin
							close
							labelAddendum={!isParentChildSpecialtyMatch && job.child_specialty ? job.child_specialty.label : null}
							labelAddendumTooltip="Client's specialty"
						>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'specialty',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.specialties, job.specialty_id) || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Industries" className="color-muted" removeMargin close>
							<VerticalSpacer height={0.5} />
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'industries',
								})}
							>
								{job.industries?.length &&
									job.industries.map(l => {
										return <Chip key={l.id} label={l.label} />;
									})}
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Leadership & Education
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Business Size" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									job,
									candidate: comparisonCandidate,
									propertyName: 'business_sizes',
								})}
							>
								<Typography variant="body2">
									{job?.client_corporation?.business_sizes?.length
										? job?.client_corporation?.business_sizes.map(l => l.label).join(', ')
										: Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Leadership Experience" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'leadership_role_id',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.leadershipExperienceRoles, job.recent_leadership_role_id) || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>

					<Grid item xs={6}>
						<LabeledField label="Leadership Years" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'total_leadership_years',
								})}
							>
								<Typography variant="body2">
									{job.total_leadership_years ? `${job.total_leadership_years} Years` : Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Leadership Time Allocation" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'leadership_allocation_type',
								})}
							>
								<Typography variant="body2">{job.leadership_allocation_type?.label || Utility.nb}</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Education" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'education_type',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.educationTypes, job.education_type_id) || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Preferred Areas of Study" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'area_of_study',
								})}
							>
								<Typography variant="body2">
									{job.study_types?.length ? job.study_types.map(s => s.label).join(', ') : Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Additional Experience Details" className="color-muted" close>
							<ExpandableTypography
								numCharacters={250}
								variant="body2"
								text={job?.additional_experience_details || Utility.nb}
							/>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */

import React from 'react';
import { Typography } from '@material-ui/core';
import SmallCheckCircleIcon from '../icons/SmallCheckCircle';
import SmallXCircleIcon from '../icons/SmallXCircle';

const MatchItem = ({ match, text }) => {
	return (
		<div
			style={{
				width: '50%',
				display: 'flex',
				alignItems: 'center',
				marginBottom: 10,
			}}
		>
			{match ? (
				<SmallCheckCircleIcon size={20} className="teal-100" style={{ flexShrink: 0 }} />
			) : (
				<SmallXCircleIcon size={20} className="grey-250" style={{ flexShrink: 0 }} />
			)}
			<Typography variant="body2" style={{ marginLeft: 10 }}>
				{text}
			</Typography>
		</div>
	);
};

export const validatePasswords = (password, confirmPassword) => {
	const matches = password && confirmPassword && password === confirmPassword;
	const characterCount = password?.length >= 8;
	// const includesNumber = password.match(/\d/);
	// const upperAndLowerCase = password.match(/[a-z]/) && password.match(/[A-Z]/);
	// const includesSpecialCharacter = password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);

	const isValid = password && confirmPassword && matches && characterCount;

	return {
		isValid,
		matches,
		characterCount,
	};
};

export const PasswordRequirementsValidation = ({ password, style }) => {
	const { characterCount, includesNumber, upperAndLowerCase, includesSpecialCharacter } = validatePasswords(password);
	return (
		<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', ...style }}>
			<MatchItem match={characterCount} text="8+ characters" />
		</div>
	);
};

export const PasswordMatchingValidation = ({ password, passwordConfirmation, style }) => {
	const match = password && passwordConfirmation && password === passwordConfirmation;
	return (
		<div style={{ ...style }}>
			<MatchItem match={match} text="Passwords match" />
		</div>
	);
};

/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import EditJobBlockModal from './EditJobBlockModal';
import LightbulbIcon from '../../../components/icons/SectionIcons/LightbulbIcon';
import ChevronDownIcon from '../../../components/icons/ChevronDownIcon';
import BasicPlusIcon from '../../../components/icons/BasicPlusIcon';
import MenuIcon from '../../../components/icons/MenuIcon';
import XIcon from '../../../components/icons/XIcon';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};
const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: grid * 3,
	margin: `0 0 ${grid}px 0`,

	// change background colour if dragging
	background: isDragging ? '#fff' : '#fff',

	// styles we need to apply on draggables
	...draggableStyle,
});
const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? '#f1f1f1' : '#f1f1f1',
	padding: grid * 4,
	borderRadius: 4,
	// width: 250,
});

const ScreeningQuestionsEditBlock = ({ job, setJob }) => {
	const [screeningQuestions, setScreeningQuestions] = useState(job?.screening_questions || []);

	const setState = useCallback(
		newState => {
			setScreeningQuestions(newState);
			setJob({
				...job,
				screening_questions: newState,
			});
		},
		[setJob],
	);

	const onDragEnd = useCallback(
		result => {
			const { source, destination } = result;

			if (!destination) return;
			const sInd = +source.droppableId;
			const dInd = +destination.droppableId;

			if (sInd === dInd) {
				const items = reorder(screeningQuestions[sInd].group_questions, source.index, destination.index);
				const newState = [...screeningQuestions];
				newState[sInd].group_questions = items;
				setState(newState);
			} else {
				const newResult = move(
					screeningQuestions[sInd].group_questions,
					screeningQuestions[dInd].group_questions,
					source,
					destination,
				);
				const newState = [...screeningQuestions];
				newState[sInd].group_questions = newResult[sInd];
				newState[dInd].group_questions = newResult[dInd];
				setState(newState);
			}
		},
		[screeningQuestions, setState],
	);

	const handleGroupLabelChange = useCallback(
		(index, value) => {
			setScreeningQuestions(prevState => {
				const newState = [...prevState];
				newState[index].group_label = value;
				setJob(prevJob => ({
					...prevJob,
					screening_questions: newState,
				}));
				return newState;
			});
		},
		[setJob],
	);

	const handleQuestionLabelChange = useCallback(
		(groupIndex, questionIndex, value) => {
			setScreeningQuestions(prevState => {
				const newState = [...prevState];
				newState[groupIndex].group_questions[questionIndex].label = value;
				setJob(prevJob => ({
					...prevJob,
					screening_questions: newState,
				}));
				return newState;
			});
		},
		[setJob],
	);

	return (
		<Grid container spacing={2} style={{ marginBottom: '10px' }}>
			<Grid item xs={12}>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
					<div
						role="button"
						tabIndex={0}
						style={{ display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
						onClick={() => {
							setState([
								...screeningQuestions,
								{
									group_label: '',
									order: screeningQuestions.length + 1,
									group_questions: [
										{
											label: '',
											description: '',
											order: 1,
										},
									],
								},
							]);
						}}
					>
						<BasicPlusIcon />
						<div style={{ width: 10 }} /> New Question Group
					</div>
				</div>

				<DragDropContext onDragEnd={onDragEnd}>
					{screeningQuestions.map((question, index) => (
						<Droppable key={index} droppableId={`${index}`}>
							{(provided, snapshot) => (
								<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<LightbulbIcon />
											<p style={{ marginLeft: 10 }}>
												<TextField
													error={!question.group_label}
													variant="outlined"
													name="group_label"
													placeholder="Type group name..."
													value={question.group_label}
													onChange={ev => handleGroupLabelChange(index, ev.target.value)}
													fullWidth
												/>
											</p>
											<div style={{ width: 20 }} />
											<div
												role="button"
												tabIndex={0}
												style={{ cursor: 'pointer', marginTop: 5, padding: 3, opacity: 0.5 }}
												onClick={() => {
													if (index !== screeningQuestions.length - 1) {
														const newState = [...screeningQuestions];
														const [removed] = newState.splice(index, 1);
														newState.splice(index + 1, 0, removed);
														setState(newState);
													}
												}}
											>
												<ChevronDownIcon />
											</div>
											<div style={{ width: 10 }} />
											<div
												role="button"
												tabIndex={0}
												style={{ cursor: 'pointer', transform: 'rotate(180deg)', padding: 3, opacity: 0.5 }}
												onClick={() => {
													if (index !== 0) {
														const newState = [...screeningQuestions];
														const [removed] = newState.splice(index, 1);
														newState.splice(index - 1, 0, removed);
														setState(newState);
													}
												}}
											>
												<ChevronDownIcon />
											</div>
										</div>
										<div
											role="button"
											tabIndex={0}
											style={{ cursor: 'pointer' }}
											onClick={() => {
												const newState = [...screeningQuestions];
												newState.splice(index, 1);
												setState(newState);
											}}
										>
											<XIcon />
										</div>
									</div>
									{question.group_questions?.map((item, idx) => (
										<Draggable key={`${index}-${idx}`} draggableId={`${index}-${idx}`} index={idx}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
												>
													<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
														<div {...provided.dragHandleProps}>
															<MenuIcon size={16} color="#aaa" />
														</div>
														<div style={{ width: 20 }} />
														<div style={{ flex: 1 }}>
															<TextField
																error={!item.label}
																variant="outlined"
																name="label"
																placeholder="Type question..."
																value={item.label}
																onChange={ev => handleQuestionLabelChange(index, idx, ev.target.value)}
																fullWidth
															/>
														</div>
														<div style={{ width: 20 }} />
														<div
															role="button"
															tabIndex={0}
															style={{ cursor: 'pointer' }}
															onClick={() => {
																setState(prevState => {
																	const newState = [...prevState];
																	newState[index].group_questions.splice(idx, 1);
																	return newState;
																});
															}}
														>
															<XIcon size={16} color="#aaa" />
														</div>
													</div>
												</div>
											)}
										</Draggable>
									))}
									<div
										role="button"
										tabIndex={0}
										style={{
											cursor: 'pointer',
											display: 'flex',
											alignItems: 'center',
											width: '100%',
											border: '1px dashed #ccc',
											padding: '10px 10px',
											marginTop: 5,
											color: '#555',
										}}
										onClick={() => {
											setState(prevState => {
												const newState = [...prevState];
												newState[index].group_questions.push({
													label: '',
													description: '',
													order: newState[index].group_questions.length + 1,
												});
												return newState;
											});
										}}
									>
										<BasicPlusIcon size={16} />
										<p style={{ marginLeft: 10, marginTop: 0, marginBottom: 0 }}>Add New Question</p>
									</div>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					))}
				</DragDropContext>
			</Grid>
		</Grid>
	);
};

const EditModal = ({ job, setJob }) => (
	<EditJobBlockModal
		job={job}
		setJob={setJob}
		title="Screening Questions"
		icon={LightbulbIcon}
		renderContents={({ updateJob, updatedJob, setUpdatedJob }) => (
			<ScreeningQuestionsEditBlock job={updatedJob} setJob={setUpdatedJob} />
		)}
	/>
);

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;

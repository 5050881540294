import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import * as Utility from '../../scripts/utility';
import StarIcon from '../icons/StarIcon';

const jobChipColor = {
	hero: {
		text: '#EEF6FF',
		background: '#104A76',
	},
	required: {
		text: '#EEF6FF',
		background: '#185B8F',
	},
	preferred: {
		text: '#212E33',
		background: '#8DC1FE',
	},
	all: {
		text: '#104A76',
		background: '#F0F6FE',
	},
};

const SkillChip = ({ skill, data, activeSkillId, setActiveSkillId, candidate, all }) => {
	const jobChipStyle = all ? 'all' : skill?.hero_skill ? 'hero' : skill?.required_skill ? 'required' : 'preferred';

	// similarity score range from 0.7 to 1.0
	const candidateChipColor =
		skill?.similarity_score >= 0.95
			? '#64D7CC'
			: skill?.similarity_score >= 0.9
			? '#6CE8DB'
			: skill?.similarity_score >= 0.85
			? '#84F6ED'
			: skill?.similarity_score >= 0.8
			? '#98F8F1'
			: skill?.similarity_score >= 0.75
			? '#B2FAF5'
			: '#D0FCF9';

	const hasIcon = (candidate && !!skill?.skill?.get_the_job) || !!skill?.hero_skill;

	// Only display years if it's a hero skill
	const jobYEOLabel =
		skill?.hero_skill && skill?.hero_skill_experience
			? `${skill?.hero_skill_experience} year${skill?.hero_skill_experience > 1 ? 's' : ''}`
			: null;

	// Only display years if it's a hero (get_the_job) skill
	const candidateYEOLabel =
		candidate && skill?.skill?.get_the_job
			? `${skill?.skill?.experience_years} year${skill?.skill?.experience_years > 1 ? 's' : ''}`
			: null;

	const candidateSkillAptitude = Utility.getItemLabel(
		data.skillExperienceLevels,
		skill?.skill?.skill_experience_id,
		'id',
	);
	const candidateSkillStory = Utility.getSkillStoryTagLabel(data.skillStoryTypes, skill?.skill);
	const jobSkillAptitude = Utility.getItemLabel(
		data.skillExperienceLevels,
		skill?.aptitude || skill?.skill_experience_id,
		'id',
	);
	const jobSkillStory = Utility.getSkillStoryTagLabel(data.skillStoryTypes, skill);
	const similarityScore = skill?.similarity_score;
	const candidateTooltip = () => {
		const skillStoryLength = candidateSkillStory?.length;
		return (
			<>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p style={{ margin: 0, marginRight: 2 }}>Aptitude:</p>
					<p style={{ fontStyle: candidateSkillAptitude ? 'normal' : 'italic', margin: 0 }}>
						{candidateSkillAptitude || 'Uncategorized'}
					</p>
				</div>
				{skillStoryLength >= 1 && (
					<div>
						Skill Stor{skillStoryLength === 1 ? 'y' : 'ies'}: {candidateSkillStory.join(', ')}
					</div>
				)}
				<div>Similarity Score: {similarityScore * 100}%</div>
			</>
		);
	};

	const jobTooltip = () => {
		const jobStoryLength = jobSkillStory?.length;
		return (
			<div>
				{jobSkillAptitude && <div>Aptitude: {jobSkillAptitude}</div>}
				{jobStoryLength >= 1 && (
					<div>
						Skill Stor{jobStoryLength === 1 ? 'y' : 'ies'}: {jobSkillStory.join(', ')}
					</div>
				)}
			</div>
		);
	};

	return (
		<div
			style={{
				opacity: activeSkillId && activeSkillId !== skill?.skill_id ? 0.3 : 1,
				transition: 'opacity .4s ease',
			}}
			onMouseEnter={() => setActiveSkillId(skill?.skill_id)}
			onMouseLeave={() => setActiveSkillId(null)}
		>
			<Tooltip arrow placement="top" title={candidate ? candidateTooltip() : jobTooltip()}>
				<Chip
					size="small"
					icon={
						hasIcon ? (
							<StarIcon color={candidate ? 'var(--grey-600)' : jobChipColor[jobChipStyle].text} size={15} />
						) : null
					}
					key={skill?.skill_id}
					label={
						<div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
							{Utility.getItemLabel(data?.skills, skill?.skill_id, 'id') ||
								skill?.skill?.skill?.label ||
								skill?.skill?.label ||
								skill?.label}
							{jobYEOLabel && <span style={{ fontWeight: 300, marginLeft: 4 }}>{jobYEOLabel}</span>}
							{candidateYEOLabel && <span style={{ fontWeight: 300, marginLeft: 4 }}>{candidateYEOLabel}</span>}
						</div>
					}
					style={{
						backgroundColor: candidate ? candidateChipColor : jobChipColor[jobChipStyle].background,
						color: candidate ? 'var(--grey-600)' : jobChipColor[jobChipStyle].text,
						margin: 0,
						paddingInline: 8,
					}}
				/>
			</Tooltip>
		</div>
	);
};

export default SkillChip;

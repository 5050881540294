import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Typography, Button, Menu, MenuItem, ListItemIcon, Grid, Divider } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';

import MinusIcon from '../icons/MinusIcon';

import { ActionCreators as SiteActions } from '../../store/Site';
import { palette } from '../../BobcatTheme';
import * as CandidatesAPI from '../../scripts/candidates';
import JobMatchCardItem from './JobMatchCardItem';
import { Panel, PanelHeader, PanelContent } from '../Panel';
import CombineIcon from '../icons/SectionIcons/CombineIcon';
import ShareMatchModal from '../modals/ShareMatchModal';
import AddJobMatchModal from '../modals/AddJobMatchModal';
import SharedJobModal from '../modals/SharedJobModal';
import { useContextStore } from '../../store/ContextStore';

const JobCardList = ({
	candidateId,
	loading,
	matches,
	graphMatches,
	refetchMatches,
	disableLink,
	title,
	small,
	pageSize: _pageSize,
	filterComponent,
	enableRefresh,
	hideAdd,
	hideItemDetails,
	autoSelectAll,
	enableSelectAll,
	numFilters,
	clearFilters,
	roleOverride,
}) => {
	const [activePageNum, setActivePageNum] = useState(1);

	const { role } = useContextStore();

	const numItems = graphMatches?.filter(x => x.listing)?.length || matches?.length;
	const pageSize = _pageSize || 4;
	const numPages = Math.ceil(numItems / pageSize);

	const tableListings =
		graphMatches?.map(x => x.listing) || [...(matches || [])]?.sort((a, b) => b.id - a.id)?.map(x => x?.listing) || [];

	const filteredListings = tableListings.slice((activePageNum - 1) * pageSize, activePageNum * pageSize);

	useEffect(() => {
		setActivePageNum(1);
	}, [tableListings?.length]);

	const [selectedListingIds, setSelectedListingIds] = useState([]);
	const [menu, setMenu] = useState({ anchor: null, listing: {} });
	const location = useLocation();
	const dispatch = useDispatch();
	const [reloading, setReloading] = useState(false);

	useEffect(() => {
		// Whenever the filtered candidates change, select all of them
		if (autoSelectAll) {
			setSelectedListingIds(filteredListings?.map(x => x?.id));
		}
	}, [filteredListings?.map(x => `${x?.id}`)?.join(',')]);

	const filteredSelectedListingIds = selectedListingIds?.filter(x => filteredListings?.map(y => y?.id)?.includes(x));

	return (
		<>
			<Panel topBarColor="teal">
				<PanelHeader>
					<Grid container spacing={0} style={{ alignItems: 'flex-start', marginTop: 0 }}>
						<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
							<CombineIcon size={20} color="var(--teal-100)" />
							<Typography variant="h6" component="h2" style={{ paddingLeft: 10 }}>
								{title || 'Matches'}
							</Typography>
							{filterComponent || null}
							{(numFilters || 0) > 0 ? (
								<Button
									variant="outlined"
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap', marginBottom: 10, marginLeft: 10 }}
									onClick={clearFilters}
								>
									Clear
								</Button>
							) : null}
							{enableRefresh && (
								// <Button
								// 	variant="outlined"
								// 	color="primary"
								// 	size="small"
								// 	disabled={reloading}
								// 	style={{ whiteSpace: 'nowrap', marginBottom: 10, minWidth: 0, paddingLeft: 10, paddingRight: 10 }}
								// 	onClick={async () => {
								// 		if (!reloading) {
								// 			setReloading(true);
								// 			try {
								// 				await refetchMatches({ candidateId });
								// 			} catch (e) {
								// 				console.log(e);
								// 			} finally {
								// 				setReloading(false);
								// 			}
								// 		}
								// 	}}
								// >
								// 	{reloading ? (
								// 		<span
								// 			style={{
								// 				marginLeft: 5,
								// 				marginRight: 5,
								// 			}}
								// 		>
								// 			Loading...
								// 		</span>
								// 	) : (
								// 		<Icons.Refresh style={{ color: palette.primary.main }} />
								// 	)}
								// </Button>
								<Icons.Refresh
									onClick={async () => {
										if (!reloading) {
											setReloading(true);
											try {
												await refetchMatches({ candidateId });
											} catch (e) {
												console.log(e);
											} finally {
												setReloading(false);
											}
										}
									}}
									style={{
										color: '#aaa',
										marginLeft: 10,
										marginTop: -8,
										cursor: reloading ? 'not-allowed' : 'pointer',
									}}
								/>
							)}
						</Grid>

						<Grid
							item
							xs={8}
							style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
						>
							{enableSelectAll && (
								<Button
									variant="outlined"
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap' }}
									onClick={() => {
										if (selectedListingIds?.length) {
											setSelectedListingIds([]);
										} else {
											setSelectedListingIds(filteredListings?.filter(x => x).map(x => x?.id));
										}
									}}
									startIcon={
										selectedListingIds?.length > 0 ? (
											<Icons.Remove style={{ color: palette.primary.main }} />
										) : (
											<Icons.Add style={{ color: palette.primary.main }} />
										)
									}
								>
									{selectedListingIds?.length > 0 ? 'Deselect All' : 'Select All'}
								</Button>
							)}
							{!hideAdd && (
								<Button
									variant="outlined"
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap' }}
									onClick={() => {
										dispatch(
											SiteActions.showModal(
												<AddJobMatchModal
													onClose={() => dispatch(SiteActions.hideModal())}
													onAdd={async listingId => {
														try {
															await CandidatesAPI.addCandidateMatch({ candidateId, listingId });
														} catch (e) {
															console.log(e);
														} finally {
															await refetchMatches({ candidateId });
															dispatch(SiteActions.hideModal());
														}
													}}
												/>,
												{
													className: 'modal-medium modal-add-match',
												},
											),
										);
									}}
								>
									Add Job
								</Button>
							)}
							<NavLink to={`${location?.pathname}/details?jobs=${filteredSelectedListingIds?.join(',')}`}>
								<Button
									disabled={!filteredSelectedListingIds?.length}
									variant={filteredSelectedListingIds?.length ? 'contained' : 'outlined'}
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap' }}
								>
									View Selected {filteredSelectedListingIds?.length ? `(${filteredSelectedListingIds?.length})` : ''}
								</Button>
							</NavLink>
						</Grid>
					</Grid>
				</PanelHeader>
				<PanelContent>
					{loading || reloading ? (
						<Typography variant="body1">Loading...</Typography>
					) : !filteredListings?.length ? (
						<Typography variant="body1">No listings available.</Typography>
					) : (
						<>
							{filteredListings
								?.filter(x => x)
								.map((listing, i) => {
									const selected = selectedListingIds?.includes(listing?.id);
									const graphMatch = graphMatches?.find(x => x.listing_id === listing?.id);
									// const match = matches?.find(x => x.listing?.id === listing?.id);

									const match =
										matches?.find(x => x.listing?.id === listing?.id) ||
										graphMatch?.saved_matches?.find(x =>
											x.origin_model
												?.toLowerCase()
												.includes((roleOverride || role) === 'recruiter' ? 'candidate' : 'listing'),
										);

									return (
										<>
											<JobMatchCardItem
												hideItemDetails={hideItemDetails}
												small={small}
												selected={selected}
												toggleSelected={() => {
													if (selected) {
														setSelectedListingIds(selectedListingIds.filter(x => x !== listing?.id));
													} else {
														setSelectedListingIds([...selectedListingIds, listing?.id]);
													}
												}}
												job={listing}
												setMenu={setMenu}
												key={listing?.id || match?.id || i}
												match={match}
												graphMatch={graphMatch}
												disableLink={disableLink}
												onRefetchMatches={() => refetchMatches({ candidateId })}
												isSharable={
													listing
														? listing.is_sharable
														: role === 'recruiter'
														? match?.listing?.is_sharable
														: match?.candidate?.is_sharable
												}
											/>
											<Divider style={{ marginTop: 0, marginBottom: 20 }} />
										</>
									);
								})}
							<div style={{ height: 50 }} />
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<Typography style={{ paddingRight: 10 }}>
									{activePageNum * pageSize - pageSize + 1}-{Math.min(numItems, activePageNum * pageSize)} of {numItems}
								</Typography>
								<Pagination
									key={tableListings?.length}
									color="primary"
									shape="rounded"
									count={numPages}
									// page={activePageNum}
									onChange={(event, number) => {
										setActivePageNum(number);
									}}
								/>
							</div>
						</>
					)}

					{menu ? (
						<Menu
							anchorEl={menu?.anchor}
							open={Boolean(menu?.anchor)}
							onClose={() => setMenu({ ...menu, anchor: null })}
							keepMounted
						>
							<NavLink
								to={`/candidate/${candidateId}/matches/details?jobs=${menu.listing.id}`}
								className="color-inherit"
							>
								<MenuItem
									onClick={() => {
										setMenu({ ...menu, anchor: null });
									}}
								>
									<ListItemIcon>
										<CombineIcon size={20} />
									</ListItemIcon>
									Match Overview
								</MenuItem>
							</NavLink>
							{menu?.match && (
								<MenuItem
									onClick={() => {
										setMenu(null);
										dispatch(
											SiteActions.showModal(
												<ShareMatchModal
													match={menu?.match}
													onClose={() => dispatch(SiteActions.hideModal())}
													onSave={async matchData => {
														try {
															await CandidatesAPI.updateCandidateMatch({
																candidateId,
																matchId: menu?.match?.id,
																match: {
																	...matchData,
																},
															});

															await refetchMatches({ candidateId });
														} catch (e) {
															console.log(e);
														}
													}}
												/>,
												{ className: 'modal-small' },
											),
										);
									}}
								>
									<ListItemIcon>
										<Icons.Share color="#ddd" />
									</ListItemIcon>
									Share to {(roleOverride || role) === 'recruiter' ? 'Candidate' : 'Job'}
								</MenuItem>
							)}
							{menu?.match && (
								<MenuItem
									onClick={() => {
										setMenu(null);
										dispatch(
											SiteActions.showModal(
												<SharedJobModal
													match={menu?.match}
													candidateId={candidateId}
													overrideListing={null}
													refetchMatches={refetchMatches}
													hideRating
												/>,
												{
													className: 'modal-medium match-share-modal',
													// disableCloseButton: true,
													// disableBackdropClick: true,
													// disableEscapeKey: true,
												},
											),
										);
									}}
								>
									<ListItemIcon>
										<Icons.Visibility style={{ width: 20 }} />
									</ListItemIcon>
									Preview Share
								</MenuItem>
							)}
							<MenuItem
								onClick={async () => {
									setMenu({ ...menu, anchor: null });
									try {
										await CandidatesAPI.deleteCandidateMatch({
											candidateId,
											matchId: menu?.match?.id,
										});
										await refetchMatches({ candidateId });
										dispatch(SiteActions.hideModal());
									} catch (e) {
										console.log(e);
									}
								}}
							>
								<ListItemIcon>
									<MinusIcon color="red" />
								</ListItemIcon>
								Remove Match
							</MenuItem>
						</Menu>
					) : null}
				</PanelContent>
			</Panel>
		</>
	);
};

export default JobCardList;

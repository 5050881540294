import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import ExperienceIcon from '../../../components/icons/ExperienceIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import LeadershipRolesDropdown from '../../../components/form/dropdowns/LeadershipRolesDropdown';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';

const EditBlockModal = ({
	job,
	setJob,
	company,
	setCompany,
	title,
	icon,
	renderContents,
	overrideSaveCompany,
	...props
}) => {
	const IconComponent = icon;

	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);

	const [updatedCompany, setUpdatedCompany] = useState(company);
	const updateCompany = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedCompany(current => ({ ...current, ...newValue }));
	};
	const saveCompany = () => {
		if (overrideSaveCompany) {
			overrideSaveCompany({ setIsSaving, store, updatedCompany, dispatch });
		} else {
			setIsSaving(true);
			try {
				// const forgedJob = ListingsAPI.getForgedListing(updatedJob);

				ListingsAPI.updateClientCorporation(company.id, updatedCompany).then(response => {
					const newCompany = response.data.data;
					setCompany(newCompany);
					dispatch(SiteActions.hideModal());
				});
			} catch (e) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));

				console.log(e);
				dispatch(SiteActions.hideModal());
			}
		}
	};

	// const [updatedJob, setUpdatedJob] = useState(job);
	// const updateJob = ev => {
	// 	const newValue = { [ev.target.name]: ev.target.value };
	// 	setUpdatedJob(currentJob => ({ ...currentJob, ...newValue }));
	// };
	// const saveJob = () => {
	// 	if (overrideSaveJob) {
	// 		overrideSaveJob({ setIsSaving, store, updatedJob, dispatch });
	// 	} else {
	// 		setIsSaving(true);
	// 		try {
	// 			const forgedJob = ListingsAPI.getForgedListing(updatedJob);

	// 			ListingsAPI.updateListing(job.id, forgedJob, response => {
	// 				if (!response) {
	// 					setIsSaving(false);
	// 					store.dispatch(
	// 						SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'),
	// 					);
	// 					return;
	// 				}

	// 				const newJob = response.data.data;
	// 				setJob(newJob);
	// 				dispatch(SiteActions.hideModal());
	// 			});
	// 		} catch (e) {
	// 			console.log(e);
	// 			dispatch(SiteActions.hideModal());
	// 		}
	// 	}
	// };

	const Contents = renderContents;

	return (
		<Panel>
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<IconComponent size={26} className="teal-100" />
					<Typography variant="h3" style={{ paddingLeft: 10 }}>
						{title}
					</Typography>
				</div>
			</PanelHeader>
			<PanelContent style={{ paddingBottom: 0, marginBottom: -8 }}>
				<Contents updateCompany={updateCompany} updatedCompany={updatedCompany} setUpdatedCompany={setUpdatedCompany} />
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}
				<div className="text-right">
					<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveCompany}>
						Save
					</LoadingButton>
				</div>
			</PanelContent>
		</Panel>
	);
};

EditBlockModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
	icon: PropTypes.element,
	title: PropTypes.string,
	children: PropTypes.node,
};

EditBlockModal.defaultProps = {
	job: {},
	setJob: () => null,
	icon: null,
	title: 'Edit Candidate',
	children: null,
};

export default EditBlockModal;

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import ViewBlockWrapper from './ViewBlockWrapper';
import LocationIcon from '../../../components/icons/LocationIcon';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditLocationPreferencesModal from './EditLocationPreferencesModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';

const Block = ({
	candidate,
	setCandidate,
	comparisonJob,
	minimized,
	setMinimized,
	data,
	disableEdit,
	displayMatchValidation,
}) => {
	const { states } = data;
	const dispatch = useDispatch();
	return (
		<>
			<ViewBlockWrapper
				icon={LocationIcon}
				minimized={minimized}
				setMinimized={setMinimized}
				title="Work Location Preferences"
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(
										<EditLocationPreferencesModal candidate={candidate} setCandidate={setCandidate} />,
										{
											className: 'modal-medium modal-no-padding modal-no-shadow',
										},
									),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<LabeledField label="Current Location" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{candidate?.city != null && candidate?.state != null
									? `${candidate.city}, ${candidate.state}`
									: `${candidate.city || ''}${candidate.state || ''}`}{' '}
								{candidate?.zip}
							</Typography>
						</LabeledField>
					</Grid>

					<Grid item xs={6}>
						<LabeledField label="Work Location Preferences" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'work_location_preferences',
								})}
							>
								<Typography variant="body2">
									{Utility.getConsolidatedLocationPreferences(candidate?.work_location_preferences)?.join(', ') || ''}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="US Timezone (remote only)" className="color-muted" removeMargin close>
							{candidate?.timezones?.length === 6 &&
							candidate?.timezones
								?.map(t => t.id)
								.sort()
								.every((value, index) => value === [1, 2, 3, 4, 5, 6][index]) ? (
								<Typography>Any</Typography>
							) : (
								candidate?.timezones?.map(
									({ id }) => <Typography>{Utility.getItemLabel(data.timeZones, id)}</Typography> || Utility.nb,
								)
							)}
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Willing to Relocate" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									// TODO: Compare to job field?
									propertyName: 'relocation',
								})}
							>
								<Typography variant="body2">
									{Number(candidate.willing_to_relocate)
										? data.willingToRelocate[candidate.willing_to_relocate]
										: Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
					<Grid item xs={6}>
						<LabeledField label="Desired Work States" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{candidate?.desired_locations?.length
									? candidate?.desired_locations?.length === states.length
										? 'All States'
										: candidate.desired_locations.map(l => l.abbreviation).join(', ')
									: Utility.nb}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Travel" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate,
									job: comparisonJob,
									propertyName: 'travel',
								})}
							>
								<Typography variant="body2">{candidate.travel_willingness_type?.label || Utility.nb}</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

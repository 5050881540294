import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import Dropdown from './Dropdown';

/** Display a dropdown with a list of states */
const OwnersDropdown = ({ useID, useAbbreviation, value, onChange, ...props }) => {
	const data = useSelector(state => state.site.data, shallowEqual);

	return (
		<Dropdown
			data={data.owners?.map(x => ({
				id: x.vendor_owner_id,
				label: `${x.first_name} ${x.last_name}`,
			}))}
			value={value}
			multiple={false}
			onChange={onChange}
			{...props}
		/>
	);
};

OwnersDropdown.propTypes = {
	// If the id field should be used instead of the abbreviation
	useID: PropTypes.bool,
};

OwnersDropdown.defaultProps = {
	useID: false,
};

export default OwnersDropdown;

import React from 'react';
import Dropdown from './Dropdown';

const weeks = Array.from(Array(53).keys()).map(week => {
	return { id: week, label: `${week} weeks` };
});

const WeeksDropdown = props => {
	return <Dropdown data={weeks} {...props} />;
};

export default WeeksDropdown;

import PropTypes from 'prop-types';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import React from 'react';

const CheckboxInput = ({ label, ...rest }) => {
	return (
		<FormGroup>
			<FormControlLabel control={<Checkbox {...rest} />} label={label} />
		</FormGroup>
	);
};

CheckboxInput.propTypes = {
	label: PropTypes.string.isRequired,
};

export default CheckboxInput;

import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Panel, PanelContent, PanelHeader } from '../../../components/Panel';
import LocationIcon from '../../../components/icons/LocationIcon';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditLocationPreferencesModal from './EditLocationPreferencesModal';
import * as Utility from '../../../scripts/utility';

const Block = ({ candidate, setCandidate, data, buttonRef, isComplete }) => {
	const dispatch = useDispatch();
	const states = useSelector(state => state.site.data.states, shallowEqual);

	return (
		<Panel id="LocationPreferences">
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<LocationIcon size={32} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Work Location Preferences
					</Typography>
				</div>
				{!!candidate.assessment_completed_at && (
					<Button
						ref={buttonRef}
						variant={isComplete ? 'outlined' : 'containedTeal'}
						color="primary"
						size="small"
						value={2}
						onClick={() => {
							dispatch(
								SiteActions.showModal(
									<EditLocationPreferencesModal candidate={candidate} setCandidate={setCandidate} />,
									{
										className: 'modal-medium modal-no-padding modal-no-shadow',
									},
								),
							);
						}}
					>
						{isComplete ? 'Edit' : 'Complete'}
					</Button>
				)}
			</PanelHeader>
			<PanelContent style={{ paddingInline: '63px' }}>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{(candidate?.city || candidate?.state || candidate?.zip) && (
							<>
								<Typography className="grey-250">Current Location</Typography>
								<Typography>
									{candidate?.city != null && candidate?.state != null
										? `${candidate.city}, ${candidate.state}`
										: `${candidate.city || ''}${candidate.state || ''}`}{' '}
									{candidate?.zip}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.work_location_preferences?.length > 0 && (
							<>
								<Typography className="grey-250">Work Location Preferences</Typography>
								<Typography>
									{Utility.getConsolidatedLocationPreferences(candidate.work_location_preferences).join(', ')}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.timezones?.length > 0 && (
							<>
								<Typography className="grey-250">US Timezone (remote only)</Typography>
								<Typography>
									{candidate.timezones.length === 6 &&
									candidate.timezones
										.map(t => t.id)
										.sort()
										.every((value, index) => value === [1, 2, 3, 4, 5, 6][index])
										? 'Any'
										: candidate.timezones.map(t => t.label).join(', ')}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.willing_to_relocate && (
							<>
								<Typography className="grey-250">Willing to Relocate</Typography>
								<Typography>{data.willingToRelocate[candidate.willing_to_relocate]}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.desired_locations?.length > 0 && (
							<>
								<Typography className="grey-250">Desired Work States</Typography>
								<Typography>
									{candidate.desired_locations.length === states.length
										? 'All States'
										: candidate.desired_locations.map(l => l.abbreviation).join(', ')}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.travel_willingness_type && (
							<>
								<Typography className="grey-250">Travel</Typography>
								<Typography>{candidate.travel_willingness_type.label}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
				</Grid>
			</PanelContent>
		</Panel>
	);
};

export default Block;

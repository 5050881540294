import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Typography,
	TextField,
	Fab,
	CircularProgress,
	Box,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import LoadingButton from '../form/LoadingButton';
import Dropdown from '../form/dropdowns/Dropdown';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import LabeledField from '../form/LabeledField';

const jobSections = [
	{
		id: 'job-details',
		label: 'Job Details',
		fields: [
			// 'position_title',
			'role_type',
			'salary_rate_min',
			'salary_rate_max',
			'other_compensation_types',
			'value_proposition_types',
			'work_visa_type_id',
			'travel_willingness_type_id',
			'openings',
			'abstract',
			'additional_job_detail',
		],
	},
	{
		id: 'experience',
		label: 'Experience',
		fields: [
			'professional_experience_years',
			'professional_category_id',
			'specialty_id',
			'business_sizes',
			'industries',
			'recent_leadership_role_id',
			'total_leadership_years',
			'leadership_allocation_type_id',
			'education_type_id',
			'study_types',
			'additional_experience_details',
		],
	},
	{
		id: 'skills',
		label: 'Skills',
		fields: [],
	},
	{
		id: 'location',
		label: 'Location',
		fields: [
			'work_location_preferences',
			'remote_states',
			'office_locations',
			'offer_relocation',
			'additional_work_location_details',
		],
	},
	// {
	// 	id: 'job-description',
	// 	label: 'Job Description',
	// },
	{
		id: 'screening-questions',
		label: 'Screening Questions',
		fields: ['screening_questions'],
	},
	{
		id: 'candidate-preparation',
		label: 'Candidate Preparation',
		fields: ['team_details', 'submission_process', 'interview_process', 'interview_preparation'],
	},
];

const JobCopyModal = ({ job, setJob, match, onAdd, onClose, ...props }) => {
	const dispatch = useDispatch();

	const [jobOptions, setJobOptions] = useState([]);
	const [selectedJobId, setSelectedJobId] = useState(null);
	const [selectedSections, setSelectedSections] = useState([]);

	const [isLoading, setLoading] = useState(false);
	const [query, setQuery] = useState(null);

	const [isSearching, setIsSearching] = useState(false);

	const copyJobData = async () => {
		return new Promise((resolve, reject) => {
			// Load the selected job.
			ListingsAPI.getListing(selectedJobId, async (response, err) => {
				if (err) {
					console.log(err);
					reject(err);
					return;
				}

				const sourceJob = response?.data?.data;
				const forgedSourceJob = ListingsAPI.getForgedListing(sourceJob);
				const forgedDestinationJob = {
					...ListingsAPI.getForgedListing(job),
				};

				jobSections.forEach(section => {
					if (selectedSections.includes(section.id) && section.fields) {
						section.fields.forEach(field => {
							forgedDestinationJob[field] = forgedSourceJob[field];
						});
					}
				});

				// Copy skills
				if (selectedSections.includes('skills')) {
					forgedDestinationJob.skills = forgedSourceJob.skills;
					await new Promise((resolveSkills, rejectSkills) => {
						ListingsAPI.syncListingSkills(job.id, forgedSourceJob.skills, skillResponse => {
							if (!skillResponse) {
								rejectSkills('Failed to sync skills.');
							} else {
								resolveSkills();
							}
						});
					});
				}

				// Copy the selected job's data into the current job.
				ListingsAPI.updateListing(
					job.id,
					{
						...forgedDestinationJob,
					},
					async (_, error) => {
						if (error) {
							console.log(error);
							reject(error);
						} else {
							setJob({ ...forgedDestinationJob });
							resolve();
						}
					},
				);
			});
		});
	};

	const search = async () => {
		if (!isSearching && query) {
			try {
				setIsSearching(true);

				const [nameResults, companyResults, vendorIdResults, idResults] = await Promise.all([
					// Get name results
					new Promise((resolve, reject) =>
						ListingsAPI.getListings(
							{
								position_title: query,
								orderBy: 'listings.vendor_job_id',
								direction: 'desc',
								per_page: 50,
							},
							async (response, err) => {
								if (err) {
									resolve([]);
								}

								const jobs = response?.data?.data;
								resolve(jobs);
							},
						),
					),

					// Get company results
					new Promise((resolve, reject) =>
						ListingsAPI.getListings(
							{
								company: query,
								orderBy: 'listings.vendor_job_id',
								direction: 'desc',
								per_page: 50,
							},
							async (response, err) => {
								if (err) {
									resolve([]);
								}

								const jobs = response?.data?.data;
								resolve(jobs);
							},
						),
					),

					// Get vendor ID results
					new Promise((resolve, reject) =>
						ListingsAPI.getListings(
							{
								vendor_job_id: query,
							},
							async (response, err) => {
								if (err) {
									resolve([]);
								}

								const jobs = response?.data?.data;
								resolve(jobs);
							},
						),
					),

					// Get ID results
					new Promise((resolve, reject) =>
						ListingsAPI.getListing(query, async (response, err) => {
							if (err) {
								resolve([]);
							}

							const listing = response?.data?.data;
							resolve([listing]);
						}),
					),
				]);

				const jobs = [...nameResults, ...companyResults, ...vendorIdResults, ...idResults]
					.filter(j => j)
					.filter((value, index, arr) => {
						return arr.findIndex(v => v.id === value.id) === index;
					})

					.sort((a, b) => {
						return b.vendor_job_id - a.vendor_job_id;
					});
				setJobOptions(jobs);
			} catch (e) {
				console.error(e);
			} finally {
				setIsSearching(false);
			}
		}
	};

	return (
		<div style={{ textAlign: 'center' }}>
			<Typography variant="h2" style={{ marginBottom: 5 }}>
				Select a job to copy data from.
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 15 }}>
				Search and select a job to copy data from into this job.
			</Typography>

			<LabeledField label="Job ID, Title, or Company" required removeMargin>
				<Box display="flex" justifyContent="stretch" marginBottom="48px" alignItems="center">
					<TextField
						style={{ flex: 1 }}
						variant="outlined"
						name="first_name"
						value={query}
						onChange={({ target }) => setQuery(target.value)}
						error={!query}
						fullWidth
						placeholder="Job ID, Job Title, or Company"
					/>
					<div style={{ width: 20 }} />
					<Fab color="primary" onClick={search} aria-label="search">
						{isSearching ? <CircularProgress size={24.5} style={{ color: 'white' }} /> : <Icons.Search />}
					</Fab>
				</Box>
			</LabeledField>

			{jobOptions ? (
				<Box marginBottom="48px">
					<LabeledField label="Job to Copy From">
						<Dropdown
							noOptionsText="Search is limited to 50 options. You may need to refine your search."
							data={[...jobOptions]
								.sort((a, b) => {
									return b.vendor_candidate_id - a.vendor_candidate_id;
								})
								.map(c => ({
									id: c.id,
									label: c.position_title,
									vendor_id: c.vendor_job_id,
									company: c.company,
								}))}
							value={selectedJobId}
							name="candidate"
							onChange={ev => setSelectedJobId(ev.target.value)}
							renderOption={(option, state) => (
								<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
									<Box flexGrow={1}>
										<Typography variant="caption" color="textSecondary" display="inline" style={{ marginRight: 5 }}>
											#{option.vendor_id}
										</Typography>
										<Typography display="inline">
											<b>{option.label}</b>
										</Typography>
										<Typography>
											{/* {option.status}
											{option.status && option.company ? ' - ' : ''} */}
											{option.company}
										</Typography>
									</Box>
								</Box>
							)}
						/>
					</LabeledField>
				</Box>
			) : null}

			{selectedJobId ? (
				<Box marginBottom="48px">
					<LabeledField label="Sections to Copy">
						<FormGroup>
							{jobSections.map(section => (
								<FormControlLabel
									control={
										<Checkbox
											checked={selectedSections.includes(section.id)}
											onChange={e => {
												if (e.target.checked) {
													setSelectedSections([...selectedSections, section.id]);
												} else {
													setSelectedSections(selectedSections.filter(s => s !== section.id));
												}
											}}
											name={section.id}
										/>
									}
									label={section.label}
								/>
							))}
						</FormGroup>
					</LabeledField>
				</Box>
			) : null}

			<div style={{ height: 25 }} />

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<LoadingButton
					loading={isLoading}
					variant="outlined"
					color="primary"
					onClick={async () => {
						onClose();
					}}
				>
					Cancel
				</LoadingButton>
				<div style={{ width: 10 }} />
				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					disabled={!selectedJobId}
					onClick={async () => {
						if (
							window.confirm(
								'Copying the selected sections from [Job Title - ID] will overwrite ' +
									'any existing entries in this job and cannot be undone. ' +
									'Please confirm you wish to proceed.',
							)
						) {
							setLoading(true);
							try {
								await copyJobData();
								dispatch(SiteActions.hideModal());
							} catch (e) {
								console.error(e);
							} finally {
								setLoading(false);
							}
						}
					}}
				>
					Copy Data
				</LoadingButton>
			</div>
		</div>
	);
};

export default JobCopyModal;

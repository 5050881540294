/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

export default function Icon({ style, color = 'currentColor', size = 20, className }) {
	return (
		<svg width={size} height={size} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4818 13.1253L18.6603 11.9468L16.5474 9.83381L21.6066 9.83381L21.6066 8.16715L16.5474 8.16715L18.6603 6.0542L17.4818 4.87569L13.357 9.00048L17.4818 13.1253ZM11 3.10793L8.05372 6.0542L6.87521 4.87569L11 0.750903L15.1248 4.87569L13.9463 6.0542L11 3.10793ZM13.9463 11.9468L11 14.893L8.05372 11.9468L6.87521 13.1253L11 17.2501L15.1248 13.1253L13.9463 11.9468ZM0.393399 9.83381H5.45262L3.33968 11.9468L4.51819 13.1253L8.64298 9.00048L4.51819 4.87569L3.33968 6.0542L5.45262 8.16715L0.393398 8.16715L0.393399 9.83381Z"
				fill={color}
			/>
		</svg>
	);
}

import React, { useState } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import BackArrow from '../../../components/page/BackArrow';
import Section from '../../../components/Section';
import LabeledField from '../../../components/form/LabeledField';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import LoadingButton from '../../../components/form/LoadingButton';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as SkillsAPI from '../../../scripts/skills';
import * as SiteAPI from '../../../scripts/site';
import * as Utility from '../../../scripts/utility';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const SkillAdd = () => {
	useDocumentTitle('Data Manager - Skills');

	const store = useStore();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [skill, setSkill] = useState({ suggested: false });
	const { data, alert } = useSelector(state => state.site, shallowEqual);

	const updateSkill = ev => {
		setSkill({ ...skill, [ev.target.name]: ev.target.value });
	};

	const onSkillAdd = (response, id) => {
		setIsLoading(false);

		if (!response) {
			store.dispatch(
				SiteActions.showAlert('An error ocurred attaching the specialties to the skill. Please try again.', 'error'),
			);
			return;
		}

		SiteAPI.updateSkills(store);
		history.push(`/admin/skills`);
	};

	const saveSpecialties = (response, err) => {
		if (!response) {
			setIsLoading(false);

			let message = 'An error ocurred creating the skill. Please try again.';

			if (Utility.getFromObj(err, 'response', 'data', 'errors'))
				message = Utility.getFromObj(err, 'response', 'data', 'errors', Object.keys(err.response.data.errors)[0], 0);

			store.dispatch(SiteActions.showAlert(message, 'error'));
			return;
		}

		if (!skill.specialties || !skill.specialties.length) onSkillAdd({});
		else SkillsAPI.syncSkillSpecialties(response.data.data.id, skill.specialties, onSkillAdd);
	};

	const addSkill = ev => {
		setIsLoading(true);
		SkillsAPI.addSkill(skill, saveSpecialties);
	};

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to="/admin/skills" />
					<Typography variant="h1">Add New Skill</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<LoadingButton loading={isLoading} width={9} variant="contained" color="primary" onClick={addSkill}>
						Save Skill
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Grid container spacing={8}>
				<Grid item xs={6}>
					<Typography variant="h3" component="h2" gutterBottom />
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Skill Name">
									<TextField variant="outlined" name="label" value={skill.label} onChange={updateSkill} fullWidth />
								</LabeledField>

								<LabeledField label="Specialties">
									<Dropdown
										multiple
										variant="outlined"
										name="specialties"
										value={skill.specialties || []}
										data={data.specialties}
										onChange={updateSkill}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Section>
				</Grid>
			</Grid>
		</Page>
	);
};

export default SkillAdd;

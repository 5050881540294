import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, TextField, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import BackArrow from '../../../components/page/BackArrow';
import LabeledField from '../../../components/form/LabeledField';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import PhoneInput from '../../../components/form/inputs/PhoneInput';
import LoadingButton from '../../../components/form/LoadingButton';
import VerticalSpacer from '../../../components/VerticalSpacer';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as UsersAPI from '../../../scripts/users';
import * as Utility from '../../../scripts/utility';

const UserEdit = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams();
	const userID = params.id;
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({});
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const roleOptions = data?.userRoles?.filter(r => r.id !== 6 && r.id !== 7); // hide contact and candidate

	const updateUser = ev => {
		setUser({ ...user, [ev.target.name]: ev.target.value });
	};

	const onUserUpdate = (response, err) => {
		setIsLoading(false);
		if (!response) {
			let message = 'An error ocurred when saving the user. Please try again.';
			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'password'))
				message = Utility.getFromObj(err, 'response', 'data', 'errors', 'password', '0');
			dispatch(SiteActions.showAlert(message, 'error'));
			return;
		}
		history.push(`/admin/user/${userID}`);
	};

	const saveUser = ev => {
		if (user.password && !user.password_confirmation) {
			dispatch(SiteActions.showAlert('You must confirm your password.', 'error'));
			return;
		}
		setIsLoading(true);
		UsersAPI.updateUser(userID, { ...user }, onUserUpdate);
	};

	// Get page data on load
	useEffect(() => {
		UsersAPI.getUser(userID, response => {
			if (!response) {
				dispatch(
					SiteActions.showAlert('An error ocurred when loading the user data. Please refresh the page.', 'error'),
				);
				return;
			}
			const resp = response.data.data;
			resp.role = Utility.getFromObj(resp, 'roles', 0, 'id');
			setUser(response.data.data);
		});
	}, []);

	if (Object.keys(user).length === 0) return <Page />;

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to="/admin/users" />
					<Typography variant="h1">Edit User</Typography>
				</PageHeader.Left>
				<PageHeader.Right>
					<LoadingButton loading={isLoading} variant="contained" color="primary" onClick={saveUser}>
						Save
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Grid container spacing={8}>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2">
						User Information
					</Typography>
					<VerticalSpacer />
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<LabeledField label="First Name">
								<TextField
									variant="outlined"
									name="first_name"
									value={user.first_name}
									onChange={updateUser}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Last Name">
								<TextField variant="outlined" name="last_name" value={user.last_name} onChange={updateUser} fullWidth />
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Primary E-mail" className="color-muted">
								<TextField variant="outlined" value={user.email} fullWidth disabled />
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Phone Number">
								<PhoneInput variant="outlined" name="phone" value={user.phone} onChange={updateUser} fullWidth />
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="LinkedIn Address">
								<TextField
									disabled
									variant="outlined"
									name="linkedin"
									value={user.linkedin}
									onChange={updateUser}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Calendar Address">
								<PhoneInput
									disabled
									variant="outlined"
									name="calendar"
									value={user.calendar}
									onChange={updateUser}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="New Password">
								<TextField
									type="password"
									variant="outlined"
									name="password"
									value={user.password}
									onChange={updateUser}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Confirm Password">
								<TextField
									type="password"
									variant="outlined"
									name="password_confirmation"
									value={user.password_confirmation}
									onChange={updateUser}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Role">
								<Dropdown
									variant="outlined"
									name="role"
									value={user.role}
									data={roleOptions}
									onChange={updateUser}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={6}>
							<LabeledField label="Status">
								<Dropdown
									variant="outlined"
									name="user_status"
									value={+user.user_status}
									data={data.userStatuses}
									onChange={updateUser}
									fullWidth
								/>
							</LabeledField>
						</Grid>
					</Grid>
					<VerticalSpacer height={2} />
				</Grid>
				<Grid item md={12} lg={6} />
			</Grid>
		</Page>
	);
};

export default UserEdit;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import LabeledField from '../../components/form/LabeledField';
import PhoneInput from '../../components/form/inputs/PhoneInput';
import LoadingButton from '../../components/form/LoadingButton';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as ContactsAPI from '../../scripts/contacts';
import * as UsersAPI from '../../scripts/users';

const EditContactInfoModal = ({ contact, setContact }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [updatedContact, setUpdatedContact] = useState({});

	const updateContact = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedContact({ ...updatedContact, ...newValue });
	};

	const saveListing = () => {
		setIsSaving(true);
		const updatedUser = {
			...updatedContact.user,
			first_name: updatedContact.first_name,
			last_name: updatedContact.last_name,
			phone2: updatedContact.phone2,
		};
		UsersAPI.updateUser(updatedContact.user.id, updatedUser, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			setContact(updatedContact);
			dispatch(SiteActions.hideModal());
		});
	};

	// Get page data on load
	useEffect(() => {
		if (contact.id) {
			setIsLoading(true);
			ContactsAPI.getContact(contact.id, (response, err) => {
				if (!response) {
					dispatch(SiteActions.showAlert('An error ocurred when loading the contact. Please try again.', 'error'));
					setIsLoading(false);
					return;
				}
				setUpdatedContact(response.data.data);
				setIsLoading(false);
			});
		}
	}, [contact.id]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={5}>
					<LabeledField label="First Name" removeMargin>
						<TextField
							variant="outlined"
							name="first_name"
							value={updatedContact?.first_name || ''}
							onChange={updateContact}
							error={!updatedContact.first_name}
							fullWidth
							placeholder="Enter first name"
							disabled
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={5}>
					<LabeledField label="Last Name" removeMargin>
						<TextField
							variant="outlined"
							name="last_name"
							value={updatedContact?.last_name || ''}
							onChange={updateContact}
							error={!updatedContact.last_name}
							fullWidth
							placeholder="Enter last name"
							disabled
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={5}>
					<LabeledField label="Primary E-mail" removeMargin>
						<TextField
							variant="outlined"
							name="email"
							value={updatedContact?.email || ''}
							onChange={updateContact}
							error={!updatedContact.email}
							disabled
							fullWidth
							placeholder="Enter email address"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={2} />
				<Grid item xs={5}>
					<LabeledField label="Primary Phone Number" removeMargin>
						<PhoneInput
							variant="outlined"
							name="phone2"
							value={updatedContact?.phone2}
							onChange={updateContact}
							error={!updatedContact.phone2}
							fullWidth
							placeholder="Enter phone number"
						/>
					</LabeledField>
				</Grid>
				<Grid item xs={5}>
					<LabeledField label="Company">
						<TextField
							variant="outlined"
							name="company"
							value={updatedContact?.company || ''}
							onChange={contact}
							// error={!updatedListing.company}
							fullWidth
							placeholder="Enter company"
							disabled
						/>
					</LabeledField>
				</Grid>
			</Grid>
			<div className="text-right">
				<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
					Cancel
				</Button>
				<LoadingButton
					loading={isSaving || isLoading}
					variant="contained"
					color="primary"
					value={2}
					onClick={saveListing}
				>
					Save
				</LoadingButton>
			</div>
		</>
	);
};

EditContactInfoModal.propTypes = {
	contact: PropTypes.shape({
		id: PropTypes.string,
		contacts: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	setContact: PropTypes.func,
};

EditContactInfoModal.defaultProps = {
	contact: { id: null },
	setContact: () => null,
};

export default EditContactInfoModal;

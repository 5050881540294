import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import ChatOutlinedIcon from '../../../components/icons/SectionIcons/ChatOutlinedIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditFeedbackModal from './EditClientFeedbackModal';

const Block = ({ job, setJob, minimized, setMinimized }) => {
	const dispatch = useDispatch();
	return (
		<>
			<ViewBlockWrapper
				icon={ChatOutlinedIcon}
				iconColor="var(--blue-500)"
				title="Client Feedback"
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={() => {
					dispatch(
						SiteActions.showModal(<EditFeedbackModal job={job} setJob={setJob} />, {
							className: 'modal-medium modal-no-padding modal-no-shadow',
						}),
					);
				}}
			>
				<>
					<Grid item xs={12}>
						<Typography>{job.client_feedback}</Typography>
					</Grid>
				</>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as UserAPI from '../scripts/user';
import { ActionCreators as SiteActions } from '../store/Site';

const useCurrentUser = () => {
	const [loading, setLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState(null);
	const [refetchCount, setRefetchCount] = useState(0);

	const dispatch = useDispatch();

	const refetch = () => {
		setRefetchCount(c => c + 1);
	};

	useEffect(() => {
		UserAPI.getCurrentUser(response => {

			// Update this hook's user state.
			setCurrentUser(response.data);

			// Update the Redux store user.
			dispatch(SiteActions.updateUser(response.data));

			setLoading(false);
		});
	}, [refetchCount]);

	return {
		loading,
		currentUser,
		refetch,
	};
};

export default useCurrentUser;

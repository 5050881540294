import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const LeadershipRolesDropdown = ({ isIntake, ...props }) => {
	const { value } = props;
	const { data } = useSelector(state => state.site, shallowEqual);
	let roles = data.leadershipExperienceRoles;

	// hide other values if 'none' is selected on intake form
	if (isIntake && value.length && value.includes(1)) {
		roles = roles.filter(role => role.id === 1);
	}
	// hide 'none' if other values are selected on intake form
	if (isIntake && value.length && [2, 3, 4, 5].some(num => value.includes(num))) {
		roles = roles.filter(role => role.id !== 1);
	}

	return <Dropdown data={roles} {...props} />;
};

export default LeadershipRolesDropdown;

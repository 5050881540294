import { applyMiddleware, compose, createStore } from 'redux';
// import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers';

// { basename: document.getElementsByTagName('base')[0].getAttribute('href') }
export const history = createBrowserHistory();

export default function configureStore(initialState) {
	const middleware = [
		// thunk,
	];

	const enhancers = [];

	const store = createStore(
		createRootReducer(history),
		initialState,
		compose(applyMiddleware(routerMiddleware(history), ...middleware), ...enhancers),
	);

	return store;
}

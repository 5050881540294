import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import SalaryDropdown from '../../../components/form/dropdowns/SalaryDropdown';
import IndustryExperienceDropdown from '../../../components/form/dropdowns/IndustryExperienceDropdown';
import TravelWillingnessDropdown from '../../../components/form/dropdowns/TravelWillingnessDropdown';
import SpecialtyDropdown from '../../../components/form/dropdowns/SpecialtyDropdown';
import CategoryDropdown from '../../../components/form/dropdowns/CategoryDropdown';
import CompensationTypesDropdown from '../../../components/form/dropdowns/CompensationTypesDropdown';
import JobOpeningsDropdown from '../../../components/form/dropdowns/JobOpeningsDropdown';
import WorkVisaDropdown from '../../../components/form/dropdowns/WorkVisaDropdown';
import DateInput from '../../../components/form/inputs/DateInput';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import CareerIcon from '../../../components/icons/CareerIcon';
import SalaryFormat from '../../../components/form/SalaryFormat';
import AddAdditionalInfo from '../../../components/modals/assessments/jobs/AddAdditionalInfo';
import SingleSpecialtyCategoryDropdown from '../../../components/form/dropdowns/SingleCategorySpecialtyDropdown';
// eslint-disable-next-line max-len
import MultiInputIndustryExperienceDropdown from '../../../components/form/dropdowns/MultiInputIndustryExperienceDropdown';

const EditDetailsModal = ({ listing, setListing }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const { data } = useSelector(state => state.site, shallowEqual);
	const [isSaving, setIsSaving] = useState(false);
	const [updatedListing, setUpdatedListing] = useState(listing);
	const updateListing = ev => {
		const newValue = { [ev.target.name]: ev.target.value };
		setUpdatedListing({ ...updatedListing, ...newValue });
	};
	const updateListingDate = (date, name) => {
		setUpdatedListing({ ...updatedListing, [name]: date });
	};

	const saveListing = () => {
		setIsSaving(true);
		try {
			const forgedListing = ListingsAPI.getForgedListing(updatedListing);
			const forgedCurrentListing = ListingsAPI.getForgedListing(listing);

			const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentListing, forgedListing);

			ListingsAPI.updateListing(listing.id, differencesToSubmit, response => {
				if (!response) {
					setIsSaving(false);
					store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
					return;
				}

				const newListing = response.data.data;
				setListing(newListing);
				dispatch(SiteActions.hideModal());
			});
		} catch (e) {
			console.log(e);
			dispatch(SiteActions.hideModal());
		}
	};

	return (
		<>
			<Panel Icon={<CareerIcon size={32} className="teal-100" />}>
				<PanelHeader className="spread">
					<Typography variant="body2" component="h2" className="text-bold" style={{ paddingTop: 4 }}>
						Job Details
					</Typography>
				</PanelHeader>
				<PanelContent>
					<Grid container spacing={2}>
						<Grid item xs={5}>
							<LabeledField label="Title" required removeMargin>
								<TextField
									variant="outlined"
									name="position_title"
									value={updatedListing.position_title || ''}
									onChange={updateListing}
									error={!updatedListing.position_title}
									fullWidth
									placeholder="Enter job title"
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LabeledField label="Type" removeMargin>
								<Dropdown
									variant="outlined"
									name="role_type"
									value={updatedListing.role_type}
									data={data.employmentPreferences}
									onChange={updateListing}
									// error={!updatedListing.role_type}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={12}>
							<div style={{ height: 10 }} />
							<SingleSpecialtyCategoryDropdown
								onChange={updateListing}
								model={updatedListing}
								columnSize={5}
								horizontalSpacer={<Grid item xs={2} />}
							/>
						</Grid>
						{/* <Grid item xs={5}>
							<LabeledField label="Category" required removeMargin>
								<CategoryDropdown
									variant="outlined"
									name="professional_category_id"
									value={updatedListing.professional_category_id}
									onChange={updateListing}
									fullWidth
									placeholder="Choose from our list"
									error={!updatedListing.professional_category_id}
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LabeledField label="Specialty" removeMargin>
								<SpecialtyDropdown
									variant="outlined"
									name="specialty_id"
									value={updatedListing.specialty_id}
									category={updatedListing.professional_category_id}
									onChange={updateListing}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField>
						</Grid> */}
						<Grid item xs={5}>
							<LabeledField label="Openings" required removeMargin>
								<JobOpeningsDropdown
									variant="outlined"
									name="openings"
									value={updatedListing.openings || ''}
									onChange={updateListing}
									error={!updatedListing.openings}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={7} />
						<Grid item xs={12}>
							{/* <LabeledField label="Desired Start Date" removeMargin>
								<DateInput
									variant="outlined"
									name="start_date"
									value={
										typeof updatedListing.start_date === 'object'
											? updatedListing.start_date
											: Utility.formatDate(updatedListing.start_date, true)
									}
									onChange={updateListingDate}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField> */}
							<MultiInputIndustryExperienceDropdown onChange={updateListing} model={updatedListing} />
						</Grid>

						<Grid item xs={5}>
							<LabeledField label="Salary Range" removeMargin>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<TextField
											variant="outlined"
											name="salary_rate_min"
											value={updatedListing.salary_rate_min}
											onChange={updateListing}
											fullWidth
											placeholder="Minimum"
											InputProps={{
												inputComponent: SalaryFormat,
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											variant="outlined"
											name="salary_rate_max"
											value={updatedListing.salary_rate_max}
											onChange={updateListing}
											fullWidth
											placeholder="Maximum"
											InputProps={{
												inputComponent: SalaryFormat,
											}}
										/>
									</Grid>
								</Grid>
							</LabeledField>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LabeledField label="Other Compensation" removeMargin required>
								<CompensationTypesDropdown
									variant="outlined"
									name="other_compensation_types"
									value={updatedListing?.other_compensation_types?.map(t => (t.id ? t.id : t))}
									onChange={updateListing}
									placeholder={updatedListing?.other_compensation_types?.length > 0 ? '' : 'Select all that apply'}
									error={!updatedListing?.other_compensation_types?.length}
									fullWidth
									multiple
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={5}>
							<LabeledField label="Travel">
								<TravelWillingnessDropdown
									variant="outlined"
									name="travel_willingness_type_id"
									value={updatedListing.travel_willingness_type_id}
									onChange={updateListing}
									fullWidth
									placeholder="Choose from our list"
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LabeledField label="Work Visa Sponsorship" required>
								<WorkVisaDropdown
									variant="outlined"
									name="work_visa_type_id"
									value={Utility.getFromObj(updatedListing, 'work_visa_type_id')}
									onChange={updateListing}
									placeholder="Choose from our list"
									error={!updatedListing.work_visa_type_id}
									fullWidth
								/>
							</LabeledField>
						</Grid>
						<Grid item xs={12}>
							<div style={{ marginTop: -10 }} />
							<AddAdditionalInfo
								additionalInfo={updatedListing.additional_job_detail}
								setAdditionalInfo={newAdditionalInfo => {
									updateListing({
										target: {
											name: 'additional_job_detail',
											value: newAdditionalInfo,
										},
									});
								}}
							/>
						</Grid>
					</Grid>
					<div className="text-right">
						<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
							Cancel
						</Button>
						<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveListing}>
							Save
						</LoadingButton>
					</div>
				</PanelContent>
			</Panel>
		</>
	);
};

EditDetailsModal.propTypes = {
	listing: PropTypes.shape({ id: PropTypes.string }),
	setListing: PropTypes.func,
};

EditDetailsModal.defaultProps = {
	listing: { id: null },
	setListing: () => null,
};

export default EditDetailsModal;

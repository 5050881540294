import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import Dropdown from './Dropdown';

/** Display a dropdown with a list of states */
const StatesDropdown = ({ useID, useAbbreviation, multiple, value, onChange, ...props }) => {
	const states = useSelector(state => state.site.data.states, shallowEqual);

	const totalNumberOfStates = states?.length;
	const allStatesSelected = value?.length === totalNumberOfStates;
	const formattedStates =
		states?.map(state => ({
			id: useID ? state.id : state.name,
			label: useAbbreviation ? state.abbreviation : state.name,
		})) || [];

	// If all the states are selected, we override to only display the "All States" option.
	const stateData = allStatesSelected ? [] : formattedStates;
	const formattedValue = allStatesSelected ? ['all'] : value;

	// If a user can select multiple states, add a selectable "All States" option.
	if (multiple) {
		stateData.unshift({
			id: 'all',
			label: 'All States',
		});
	}

	const handleOnChange = event => {
		// If the user selects "All States", then replace their selection
		// with an array of every state.
		if (multiple && event.target.value.includes('all')) {
			const newEvent = {
				...event,
				target: {
					...event.target,
					value: formattedStates.filter(x => x.id !== 'all').map(state => state.id),
				},
			};
			onChange(newEvent);
		} else {
			onChange(event);
		}
	};

	return <Dropdown data={stateData} value={formattedValue} multiple={multiple} onChange={handleOnChange} {...props} />;
};

StatesDropdown.propTypes = {
	// If the id field should be used instead of the abbreviation
	useID: PropTypes.bool,
	useAbbreviation: PropTypes.bool,
};

StatesDropdown.defaultProps = {
	useID: false,
	useAbbreviation: false,
};

export default StatesDropdown;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function UploadIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-336 -519)">
					<g transform="translate(336 519)">
						<g transform="translate(1 1)">
							<path d="M8 10.135L8 0"/>
							<g>
								<path d="M15.981 9.136L16 13.305a2.145 2.145 0 01-2.134 2.167H2.135A2.146 2.146 0 010 13.315V9"/>
								<path d="M12.0003 4L8.0003 0 4.0003 4"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
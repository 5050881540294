import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, CircularProgress, ButtonBase, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';
import Fence from '../../../../../Fence';
import VerticalSpacer from '../../../../../VerticalSpacer';
import { RadioButton, RadioButtonGroup } from '../../../../../form/RadioButton';
import LoadingButton from '../../../../../form/LoadingButton';
import ElasticSkillDropdown from '../../../../../form/dropdowns/ElasticSkillDropdown';
import PlusIcon from '../../../../../icons/PlusIcon';
import { ActionCreators as SiteActions } from '../../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../../scripts/candidates';
import * as Utility from '../../../../../../scripts/utility';
import SkillDropdown from '../../../../../form/dropdowns/SkillDropdown';

const SkillRankingsForm = () => {
	const pageNum = 3.5;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const [showTopAddSkillsForm, setShowTopAddSkillsForm] = useState(false);
	const [showBottomAddSkillsForm, setShowBottomAddSkillsForm] = useState(false);
	const [selectedMissingSkills, setSelectedMissingSkills] = useState([]);
	const [latestSkillData, setLatestSkillData] = useState(null);
	// const [validSkills, setValidSkills] = useState([]);
	const [currentSkillPage, setCurrentSkillPage] = useState(0);
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const { id: candidateID } = useParams();
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);

	const [originalCandidate, setOriginalCandidate] = useState(null);

	// const skillOptions = validSkills.filter(option => {
	// 	return !candidate.skills.find(s => s.skill_id === option.id);
	// });

	const paginatedSkills = [];
	const chunkSize = 20;
	const experienceLevelSelectedSkills = candidate.skills?.filter(x => x.experience || x.skill_experience_id);
	for (let i = 0; i < experienceLevelSelectedSkills?.length; i += chunkSize) {
		const chunk = experienceLevelSelectedSkills?.slice(i, i + chunkSize);
		paginatedSkills.push(chunk);
	}

	const addMissingSkills = () => {
		// const newSkills = validSkills
		// 	.filter(s => selectedMissingSkills.includes(s.id))
		// 	.map(s => ({ skill: s, skill_id: s.id, skill_experience_id: 1, experience_years: 0 }));
		const newSkills = selectedMissingSkills.map(skill => ({
			skill_id: skill.id,
			skill_experience_id: 1,
			experience_years: 0,
			label: skill.label,
			skill: {
				id: skill.id,
				name: skill.label,
			},
		}));
		const updatedSkills = [...candidate.skills, ...newSkills];
		store.dispatch(AssessmentActions.updateCandidateSkills(updatedSkills));
		setSelectedMissingSkills([]);
	};

	const removeSkill = skill => {
		const updatedSkills = candidate.skills.filter(s => s.skill_id !== skill.skill_id);
		store.dispatch(AssessmentActions.updateCandidateSkills(updatedSkills));
	};

	const updateSkill = ev => {
		store.dispatch(AssessmentActions.updateCandidateSkill(ev.target.name, ev.target.value));
	};

	const onCandidateUpdate = response => {
		store.dispatch(AssessmentActions.setIsSaving(false));

		if (!response) {
			SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
			return;
		}
		store.dispatch(AssessmentActions.addCompletedPage(pageNum));
	};

	const nextStep = ev => {
		store.dispatch(SiteActions.hideAlert());
		const valid = candidate.skills.every(s => s.skill_experience_id !== 1);
		if (!valid) return;
		store.dispatch(AssessmentActions.setIsSaving(true));

		const removedSkills = Utility.uniqueIDs(
			originalCandidate.skills.filter(s => !candidate?.skills?.find(cs => cs.skill_id === s.skill_id)),
		).map(s => ({
			skill_id: s.skill_id,
			skill_experience_id: null,
			experience_years: 0,
		}));

		CandidatesAPI.addCandidateSkills(candidateID, [...(candidate.skills || []), ...(removedSkills || [])], response => {
			if (!response) {
				store.dispatch(AssessmentActions.setIsSaving(false));
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				return;
			}
			store.dispatch(AssessmentActions.addCompletedPage(pageNum));
			store.dispatch(AssessmentActions.setPage(pageNum + 0.5));
		});
	};

	// Init page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setCandidate(response.data.data));
			setOriginalCandidate(response.data.data);
			setIsLoading(false);
		});
		// if (!validSkills.length) {
		// 	CandidatesAPI.getValidSkills(candidateID, response => {
		// 		if (response) setValidSkills(response.data.data);
		// 	});
		// }
	}, []);

	useEffect(() => {
		const canProceed = (candidate.skills || []).every(s => s.skill_experience_id !== 1);
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [candidate.skills]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Skill Rankings
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Please assign each skill an aptitude ranking.
			</Typography>
			<VerticalSpacer height={1} />
			<Typography>
				<strong>1 - Basic:</strong> Your novice skills.
				<br />
				<strong>2 - Intermediate:</strong> Your skills that exceed basic but have not yet progressed to high-level
				aptitude.
				<br />
				<strong>3 - Advanced:</strong> Your advanced skills.
				<br />
				<strong>4 - Best:</strong> Your highest aptitude skills.
				<br />
			</Typography>
			<VerticalSpacer height={3} />
			<ButtonBase className="button-show-more" onClick={() => setShowTopAddSkillsForm(s => !s)}>
				<PlusIcon />
				ADD MISSING SKILLS
			</ButtonBase>
			{showTopAddSkillsForm && (
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<ElasticSkillDropdown
							filterCandidateId={candidateID}
							tags
							variant="outlined"
							color="primary"
							name="skills_expert"
							filter={skillId => {
								return !candidate.skills.find(s => s.skill_id === skillId);
							}}
							value={selectedMissingSkills?.map(x => x.id || x)}
							onChange={(ev, skillData) => {
								if (skillData) {
									setLatestSkillData(skillData);
								}
								const newSkills = ev.target.value?.map(x => skillData[x])?.filter(x => x);

								setSelectedMissingSkills(newSkills);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							color="primary"
							className="no-margins"
							disabled={!selectedMissingSkills.length}
							onClick={() => addMissingSkills()}
						>
							Add
						</Button>
					</Grid>
				</Grid>
			)}
			<VerticalSpacer height={2} />
			<Grid container spacing={1}>
				{paginatedSkills[currentSkillPage]?.map(skill => (
					<Grid item xs={6}>
						<Typography className="text-medium grey-600">
							{Utility.getFromObj(skill, 'skill', 'label') || skill?.label}{' '}
							<ButtonBase className="button-cancel" onClick={() => removeSkill(skill)}>
								<Icons.Cancel style={{ marginTop: -3 }} />
							</ButtonBase>
						</Typography>
						<VerticalSpacer height={0.75} />
						<RadioButtonGroup
							name={skill.label || skill.skill?.name}
							value={skill.skill_experience_id}
							// error={validate && skill.skill_experience_id === 1}
							onChange={updateSkill}
						>
							{data.skillExperienceLevels
								.filter(level => level.name !== 'uncategorized')
								.map((level, i) => (
									<RadioButton key={level.id} title={i + 1} value={level.id}>
										{level.label}
									</RadioButton>
								))}
						</RadioButtonGroup>
						<VerticalSpacer height={2} />
					</Grid>
				))}
			</Grid>
			<VerticalSpacer height={1} />
			<ButtonBase className="button-show-more" onClick={() => setShowBottomAddSkillsForm(s => !s)}>
				<PlusIcon />
				ADD MISSING SKILLS
			</ButtonBase>
			{showBottomAddSkillsForm && (
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<ElasticSkillDropdown
							filterCandidateId={candidateID}
							tags
							variant="outlined"
							color="primary"
							name="skills_expert"
							filter={skillId => {
								return !candidate?.skills?.find(s => s.skill_id === skillId);
							}}
							value={selectedMissingSkills?.map(x => x.id || x)}
							onChange={(ev, skillData) => {
								if (skillData) {
									setLatestSkillData(skillData);
								}
								const newSkills = ev.target.value?.map(x => (skillData || latestSkillData)[x])?.filter(x => x);

								setSelectedMissingSkills(newSkills);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							color="primary"
							className="no-margins"
							disabled={!selectedMissingSkills.length}
							onClick={() => addMissingSkills()}
						>
							Add
						</Button>
					</Grid>
				</Grid>
			)}
			<VerticalSpacer height={3} />
			<div className="text-center">
				{paginatedSkills?.map((page, i) => (
					<Button
						// eslint-disable-next-line react/no-array-index-key
						key={`skill-pagination-btn-${i}`}
						variant={currentSkillPage === i ? 'contained' : 'outlined'}
						color="primary"
						style={{ width: 44, minWidth: 44, height: 44, paddingTop: 13, margin: '0 5px' }}
						onClick={() => setCurrentSkillPage(i)}
					>
						{i + 1}
					</Button>
				))}
			</div>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				{!progress.canProceed && (
					<Typography paragraph variant="caption" className="grey-300 text-italic">
						Please rank all skills to continue
					</Typography>
				)}
				<Button
					variant="outlined"
					color="primary"
					size="large"
					onClick={() => store.dispatch(AssessmentActions.setPage(pageNum - 0.5))}
				>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

SkillRankingsForm.propTypes = {};

SkillRankingsForm.defaultProps = {};

export default SkillRankingsForm;

import React from 'react';
import { useLocation } from 'react-router-dom';
import ViewResumeWindow from './CandidateBlocks/ViewResumeWindow';

const ResumeWindow = () => {
    // takes in candidateId from URL to display resume in a separate window

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    return <ViewResumeWindow candidateId={id} />

};

export default ResumeWindow;
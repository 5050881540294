import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import * as CandidatesAPI from '../../../scripts/candidates';
import { DocumentEmbed } from './ViewDocumentModal';

const ViewResumeWindow = ({ candidateId, overrideUrl, overrideFilename }) => {
	const [candidateResume, setCandidateResume] = useState([]);
	const [resumeShareLink, setResumeShareLink] = useState(overrideUrl || null);

	const refreshDocuments = () => {
		if (candidateId) {
			CandidatesAPI.getCandidateResumes(
				candidateId,
				{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
				response => {
					if (!response) {
						return;
					}
					const resume = response.data.data[0];
					setCandidateResume(resume);
					if (resume) {
						CandidatesAPI.createCandidateResumeShareLink({
							candidateId,
							documentId: resume?.document_id,
						}).then(shareLink => {
							setResumeShareLink(shareLink?.data?.url);
						});
					}
				},
			);
		}
	};

	useEffect(() => {
		refreshDocuments();
	}, []);

	const height = 820;

	return (
		<div style={{ paddingTop: 20 }}>
			{resumeShareLink ? (
				<DocumentEmbed
					key="resume-embed"
					shareLink={resumeShareLink}
					height={height}
					fileName={overrideFilename || candidateResume?.file_name}
				/>
			) : (
				<Typography style={{ opacity: 0.5, fontStyle: 'italic' }}> No resume to display.</Typography>
			)}
		</div>
	);
};

export default ViewResumeWindow;

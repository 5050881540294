import React from 'react';
import PropTypes from 'prop-types';

export const RadioButton = ({
	title,
	active,
	onClick,
	value,
	name,
	className,
	children,
	...props
}) => {
	const sendClick = (ev, callback) => {
		const event = ev;

		event.target.value = value;
		event.target.name = name;

		callback(event);
	};

	const sendPress = (ev, callback) => {
		// Only change value on Enter (13)
		if (ev.keyCode !== 13) return;

		const event = ev;

		event.target.value = value;
		event.target.name = name;

		callback(event);
	};

	return (
		<div
			className={`radio-button cursor-pointer ${active ? 'active' : ''} ${className}`}
			onClick={ev => sendClick(ev, onClick)}
			onKeyUp={ev => sendPress(ev, onClick)}
			value={value}
			aria-checked={active ? 'true' : 'false'}
			role="radio"
			tabIndex={0}
			{...props}
		>
			<div className="radio-button-title">{title}</div>
			{children}
		</div>
	);
};

export const RadioButtonGroup = ({
	name,
	value,
	onChange,
	className,
	error,
	children,
	...props
}) => {
	return (
		<div className={`radio-button-group ${error ? 'error' : ''}`}>
			{(children || []).map(btn => ({
				...btn,
				props: { ...btn.props, active: value === btn.props.value, onClick: onChange, name },
			}))}
		</div>
	);
};

RadioButton.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	active: PropTypes.bool,
	onClick: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	name: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.string,
};

RadioButton.defaultProps = {
	className: '',
	active: false,
	onClick: () => {},
	name: '',
	children: [],
};

RadioButtonGroup.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	error: PropTypes.bool,
	children: PropTypes.element.isRequired,
};

RadioButtonGroup.defaultProps = {
	className: '',
	error: false,
};

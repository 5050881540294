import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import * as Site from './Site';
import * as Assessment from './Assessment';

const reducers = {
	site: Site.reducer,
	assessment: Assessment.reducer,
};

const createRootReducer = history =>
	combineReducers({
		...reducers,
		router: connectRouter(history),
	});

export default createRootReducer;

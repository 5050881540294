/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function Icon({ style, color = "currentColor", size = 21, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="211"
			height="195"
			viewBox="0 0 211 195"
			style={style}
			className={className}
			fill="none"
		>
				<rect x="27" y="9" width="157" height="157" fill="#E4E8EC" />
				<rect x="44" y="37" width="57" height="35" fill="white" />
				<rect x="50" y="43" width="39" height="7" fill="#E4E8EC" />
				<rect x="50" y="54" width="22" height="7" fill="#E4E8EC" />
				<rect x="44" y="80" width="124" height="35" fill="white" />
				<rect x="44" y="123" width="124" height="27" fill="white" />
				<rect x="111" y="37" width="57" height="35" fill="white" />
				<rect x="27" y="9" width="157" height="14" fill="#34414E" />
				<rect x="120" y="43" width="39" height="7" fill="#E4E8EC" />
				<rect x="120" y="54" width="22" height="7" fill="#E4E8EC" />
				<rect x="50" y="87" width="39" height="7" fill="#E4E8EC" />
				<rect x="50" y="130" width="39" height="7" fill="#E4E8EC" />
				<circle cx="163" cy="148" r="33" fill="white" />
				<path
					d="M163.5 187.083C141.639 187.083 123.917 169.361 123.917 147.5C123.917 125.639 141.639 107.917 163.5 107.917C185.361 107.917 203.083 125.639 203.083 147.5C203.083 169.361 185.361 187.083 163.5 187.083ZM163.5 179.167C180.989 179.167 195.167 164.989 195.167 147.5C195.167 130.011 180.989 115.833 163.5 115.833C146.011 115.833 131.833 130.011 131.833 147.5C131.833 164.989 146.011 179.167 163.5 179.167ZM163.5 141.902L174.696 130.706L180.294 136.304L169.098 147.5L180.294 158.696L174.696 164.294L163.5 153.098L152.304 164.294L146.706 158.696L157.902 147.5L146.706 136.304L152.304 130.706L163.5 141.902Z"
					fill="#D4303A"
				/>
		</svg>
	);
}
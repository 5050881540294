import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LoadingButton from '../form/LoadingButton';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as SovrenAPI from '../../scripts/sovren';

const SovrenSkillDeleteModal = ({ skill, onDelete, ...props }) => {
	const store = useStore();
	const [isLoading, setLoading] = useState(false);

	const onSkillDelete = response => {
		setLoading(false);
		store.dispatch(SiteActions.hideModal());

		if (!response) {
			store.dispatch(
				SiteActions.showAlert('An error ocurred when deleting the sovren skill. Please try again.', 'error'),
			);
			return;
		}

		if (typeof onDelete === 'function') onDelete();
	};

	const deleteSkill = ev => {
		setLoading(true);
		SovrenAPI.deleteSovrenSkills(skill.id, onSkillDelete);
	};

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Delete Record?
			</Typography>
			<Icons.Delete
				color="secondary"
				style={{
					color: 'var(--secondaryColor)',
					background: 'var(--secondaryLightColor)',
					padding: 15,
					borderRadius: 100,
					fontSize: 80,
					marginBottom: '2.5rem',
				}}
			/>
			<Typography variant="body2" style={{ marginBottom: 32 }}>
				Please confirm that you want to permanently delete the sovren skill record
				<br />
				<strong>{skill.label}</strong>
			</Typography>
			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="secondary"
				onClick={deleteSkill}
				style={{ minWidth: 185 }}
			>
				Delete Sovren Skill
			</LoadingButton>
		</div>
	);
};

SovrenSkillDeleteModal.propTypes = {
	skill: PropTypes.shape({
		id: PropTypes.number,
		label: PropTypes.string,
	}).isRequired,
	onDelete: PropTypes.func,
};

SovrenSkillDeleteModal.defaultProps = {
	onDelete: () => {},
};

export default SovrenSkillDeleteModal;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const MenuLink = ({ children, icon, to, exact, width, ...props }) => {
	// adjust opacity as sidebar collapses
	const opacity = width >= 180 ? 1 : width <= 150 ? 0 : (width - 150) / (180 - 150);
	return (
		<NavLink to={to} exact={exact}>
			{icon}
			<span style={{ opacity: `${opacity}`, marginLeft: -8 }}>{children}</span>
		</NavLink>
	);
};

MenuLink.propTypes = {
	children: PropTypes.node,
	icon: PropTypes.node,
	to: PropTypes.string,
	exact: PropTypes.bool,
	width: PropTypes.number,
};

MenuLink.defaultProps = {
	children: null,
	icon: null,
	to: null,
	exact: null,
	width: 0,
};

export default MenuLink;

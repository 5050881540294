/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function PreferencesIcon({ style, color = "currentColor", size = 20, className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			style={style}
			className={className}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke={color} strokeWidth="1.25" transform="translate(-332 -745)">
					<g transform="translate(333 746)">
						<path d="M10 13.49c0 .932.435 1.872 1.127 2.688a7.215 7.215 0 001.914 1.573 1.963 1.963 0 002.012-.06 7.83 7.83 0 001.751-1.527c.7-.826 1.196-1.782 1.196-2.674 0-.637-.241-1.2-.623-1.608a2.074 2.074 0 00-1.512-.672c-.802 0-1.45.272-1.851.854l-.014.02-.014-.02c-.401-.582-1.049-.854-1.707-.854-.645 0-1.215.247-1.623.656A2.273 2.273 0 0010 13.49z"/>
						<path d="M10 7L14 7"/>
						<path d="M4 6.726L4.919 7.554 6.769 5.888"/>
						<path d="M7 18H5a5 5 0 01-5-5V5a5 5 0 015-5h8a5 5 0 015 5v3"/>
						<path d="M4 11.726L4.919 12.554 6.769 10.888"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';
import useVerifyAuthorization from '../../../hooks/useVerifyAuthorization';

const SalaryDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const { includeUnknown } = props;

	const { isAuthorized: isAuthorizedAdmin } = useVerifyAuthorization({ role: 'admin' });

	const salaryList = [...data.salaryExpectationTypes];
	if (isAuthorizedAdmin) {
		salaryList.unshift({
			id: null,
			label: 'Unknown',
		});
	}
	return <Dropdown data={salaryList} {...props} />;
};

export default SalaryDropdown;

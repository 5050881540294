import React from 'react';
import { Grid } from '@material-ui/core';
import LabeledField from '../LabeledField';
import LeadershipRolesDropdown from './LeadershipRolesDropdown';
import * as Utility from '../../../scripts/utility';
import LeadershipAllocationDropdown from './LeadershipAllocationDropdown';

const LeadershipRolesAllocationDropdown = ({ onChange, model }) => {
	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<div style={{ marginTop: -20, borderRadius: 8, backgroundColor: 'transparent' }}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<LabeledField
									label="Leadership Preference(s)"
									info="What is your expected leadership level in your next role?"
									removeMargin
									labelStyle={{ fontWeight: '500', fontSize: 14 }}
								>
									<LeadershipRolesDropdown
										variant="outlined"
										name="leadership_preferences"
										value={(Utility.getFromObj(model, 'leadership_preferences') || []).map(p => (p.id ? p.id : p))}
										onChange={onChange}
										placeholder={model?.leadership_preferences?.length > 0 ? '' : 'Select all that apply'}
										fullWidth
										multiple
										isIntake
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField
									label="Preferred Leadership Allocation"
									info="How much time do you prefer to spend on leadership responsibilities?"
									removeMargin
									required={(model?.leadership_preferences || []).map(p => (p.id ? p.id : p)).some(val => val !== 1)}
									labelStyle={{ fontWeight: '500', fontSize: 14 }}
								>
									<LeadershipAllocationDropdown
										variant="outlined"
										name="preferred_leadership_allocation_id"
										value={
											(model?.leadership_preferences || []).map(p => (p.id ? p.id : p)).includes(1) &&
											model?.leadership_preferences.length === 1
												? null
												: Utility.getFromObj(model, 'preferred_leadership_allocation_id')
										}
										onChange={onChange}
										disabled={
											(model?.leadership_preferences || []).map(p => (p.id ? p.id : p)).includes(1) &&
											model?.leadership_preferences.length === 1
										}
										placeholder="Choose from our list"
										fullWidth
										isIntake
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
			<br />
		</>
	);
};

export default LeadershipRolesAllocationDropdown;

/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";

export default function PhoneCircleIcon({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			style={style}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g transform="translate(-336 -216)">
					<g transform="translate(336 216)">
						<circle cx="12" cy="12" r="12" fill="#52A2FF" />
						<g
							stroke="#FFF"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.25"
							transform="translate(6 6)"
						>
							<g>
								<path d="M4.754 7.27a8.423 8.423 0 01-1.758-2.54.5.5 0 01.11-.55l.546-.546c.448-.448.447-1.08.057-1.47l-.783-.784a1.333 1.333 0 00-1.885 0l-.435.435c-.494.494-.7 1.207-.566 1.913C.369 5.47 1.38 7.378 3.014 9.01c1.632 1.633 3.54 2.645 5.282 2.974.706.134 1.419-.072 1.913-.566l.435-.435c.52-.52.52-1.365 0-1.885l-.783-.783a1 1 0 00-1.414 0l-.603.603a.498.498 0 01-.55.11 8.423 8.423 0 01-2.54-1.758z" />
								<path d="M9.34 5.357a2.63 2.63 0 00-2.673-2.672" />
								<path d="M12.024 5.357c0-1.37-.523-2.742-1.57-3.788A5.34 5.34 0 006.668 0" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}